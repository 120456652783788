import type { FC } from 'react';

interface LogoProps {
  height?: number | string;
  width?: number | string;
}

const BlackLogo: FC<LogoProps> = ({ width, height }: LogoProps) => (
  <img
    width={width}
    height={height}
    src="/static/it22-website-black-logo.svg"
    alt="IT22 Logo"
  />
);

export default BlackLogo;
