/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import { Autocomplete, TextField } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { omsApi } from 'src/api/omsApis';

const CustomAutoComplete = (props: any) => {
    const { id, value, onSelect, error, helperText, apiUrl } = props
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const searchData = async (searchText) => {
        setLoading(true);
        try {
            let argument = "";
            if (searchText.trim() !== "") {
                argument = `?search=${searchText}`
            }

            const response = await omsApi.getOmsProductsAutoComplete(`${apiUrl}${argument}`);
            if (response.status === 200) {
                setLoading(false);
                setOptions(response.data)
                return response.data
            }
        } catch (failure) {
            setLoading(false);
        }
    }
    useEffect(() => {
        searchData('');
    }, []);
    return (
        <>
            <Autocomplete
                id={id}
                options={options || []}
                value={value}
                // disabled={!editForm}
                getOptionLabel={(option) => option.name}
                onChange={(e: any, newValue: any) => {
                    onSelect(e, newValue);
                }}
                loading={loading}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Product"
                        label="Product"
                        onChange={(event) => searchData(event.target.value)}

                        error={error}
                        helperText={
                            helperText
                        }
                    />
                )}
            />
        </>
    );
};

export default CustomAutoComplete;
