import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Typography,
  IconButton,
  MenuItem,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast'; // For notifications
import { Autocomplete } from '@mui/material';
import { leavesApi } from 'src/api/leavesApi'; // Assuming this is the API call for fetching leave types
import { userApi } from 'src/api/userApi';
import moment from 'moment';

interface EditLeaveDialogProps {
  open: boolean;
  onClose: () => void;
  leaveData: any; // The leave data to be edited
  fetchLeaveData: () => void; // Function to fetch leave data after editing
}

// Validation Schema for Edit
const validationSchema = Yup.object({
  leaveType: Yup.string().required('Leave type is required'),
  employee: Yup.object().nullable().required('Employee is required'),
  // Removed the required constraint from the description field
  description: Yup.string(),
});

const EditLeaveDialog: React.FC<EditLeaveDialogProps> = ({
  open,
  onClose,
  leaveData,
  fetchLeaveData,
}) => {
  const [employees, setEmployees] = useState([]);
  const [leaveTypes, setLeaveTypes] = useState([]);

  // Fetch employees and leave types when the component mounts
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await userApi.getUsersByOrganizationId(
          null,
          null,
          25000,
          null
        );
        setEmployees(response?.data?.data); // Ensure the correct path to employee data
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

    const fetchLeaveTypes = async () => {
      try {
        const response = await leavesApi.getLeaveTypes(); // Assuming this API fetches leave types
        setLeaveTypes(response.data);
      } catch (error) {
        console.error('Error fetching leave type data:', error);
      }
    };

    fetchEmployees();
    fetchLeaveTypes();
  }, []);

  // Function to handle form submission for editing
  const handleSubmit = async (values: any) => {
    const payload = {
      user: {
        id: values.employee.id, // Employee ID from Autocomplete selection
        name: values.employee.name, // Employee Name from Autocomplete
      },
      leaveType: values.leaveType, // Selected leave type
      endDate: values.endDate, // End date from form
    };

    try {
      // Call the API to update the leave
      const response = await leavesApi.updateLeave(leaveData.id, payload); // Assuming updateLeave API exists

      // Check if the response status is 200 (Success)
      if (response.status === 200) {
        // Show success toast
        toast.success('Leave successfully updated!');
        fetchLeaveData(); // Fetch updated leave data after successful update
        onClose(); // Close the dialog after success
      } else {
        // Show error toast if the response is not successful
        toast.error('Failed to update leave. Please try again.');
      }
    } catch (error) {
      // Catch any errors during the API call and show error toast
      console.error('Error updating leave:', error);
      toast.error('Error updating leave. Please try again.');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Formik
        initialValues={{
          leaveType: leaveData?.leaveType || '',
          employee: leaveData?.user || null,
          description: leaveData?.description || '',
          loggedDate: moment(leaveData?.loggedDate).format('YYYY-MM-DD'), // Format the logged date to display it
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}>
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <DialogContent>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center">
                <Typography variant="h6">Edit Leave</Typography>
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Box>

              <Grid container spacing={2} marginTop="16px">
                {/* Logged Date - Read-only Field */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Logged Date"
                    name="loggedDate"
                    value={values.loggedDate}
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    fullWidth
                    label="Leave type"
                    name="leaveType"
                    select
                    variant="outlined"
                    error={touched.leaveType && Boolean(errors.leaveType)}
                    helperText={touched.leaveType && errors.leaveType}>
                    {leaveTypes.map((type) => (
                      <MenuItem key={type.id} value={type.name}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>

                {/* Autocomplete for Employee */}
                <Grid item xs={12}>
                  <Autocomplete
                    options={employees}
                    getOptionLabel={(option) => option.name || 'No name'}
                    value={values.employee}
                    onChange={(event, value) =>
                      setFieldValue('employee', value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Employee"
                        variant="outlined"
                        error={touched.employee && Boolean(errors.employee)}
                        helperText={touched.employee && errors.employee}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    fullWidth
                    label="Description"
                    name="description"
                    variant="outlined"
                    multiline
                    rows={3}
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary" variant="outlined">
                Cancel
              </Button>
              <Button color="secondary" variant="contained" type="submit">
                Update Leave
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditLeaveDialog;
