import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  IconButton,
  InputAdornment,
  Link,
  TextField
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { FC, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import EyeIcon from 'src/icons/Eye';
import EyeOffIcon from 'src/icons/EyeOff';
import * as Yup from 'yup';
import useAuth from '../../../hooks/useAuth';
import useMounted from '../../../hooks/useMounted';

const LoginJWT: FC = (props) => {
  const mounted = useMounted();
  const { login } = useAuth() as any;
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const useStyles = makeStyles<Theme>(() => createStyles({
    formLogin: {
      '& .MuiOutlinedInput-root': {
        borderRadius: '9px',
      },
      '& button': {
        width: '10rem',
        margin: '30px 0',
        display: 'block',
        borderRadius: '9px',
        height: '49px',
      },
      '& .MuiIconButton-root': {
        width: 'fit-content',
      },
    },
    checkboxRoot: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    checkboxLabel: {
      cursor: 'pointer',
      fontSize: '16px',
      fontWeight: '400px',
    }
  }));
  const classes = useStyles();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .required('Email required')
          .email('Invalid Email'),
        password: Yup.string()
          .required('Password required')
          .min(2, 'Minimum 2 characters allowed')
          .max(255, 'Maximum 255 characters allowed'),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ): Promise<void> => {
        try {
          await login(values.email, values.password);
          // const payload={
          //   email:values.email,
          //   password:values.password
          // }
          // const response=await authApi.login(payload);

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }): JSX.Element => (
        <form
          noValidate
          onSubmit={handleSubmit}
          {...props}
          className={classes.formLogin}
        >
          <TextField
            autoFocus
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email Address"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            // type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Password"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type={showPassword ? 'text' : 'password'}
            value={values.password}
            variant="outlined"
            InputProps={{
              classes: {
                root: classes.readOnly,
              },
              readOnly: false,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <EyeIcon /> : <EyeOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <div className={classes.checkboxRoot}>
              <div>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className={classes.checkboxLabel}>
                  <Checkbox
                    color="default"
                    sx={{ paddingLeft: 0 }}
                  />
                  Keep me logged in
                </label>
                {/* </Link> */}
              </div>

              <Link
                color="textSecondary"
                component={RouterLink}
                sx={{ mt: 1 }}
                to="/erp/forgot-password"
                variant="body2"
              >
                Forgot password
              </Link>
            </div>
            <Button
              color="secondary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Log In
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginJWT;
