import { Table } from '@material-ui/core';
import Head from './CustomTableHead';
import Body from './CustomTableBody';

export type Column = {
  id: string;
  label?: string;
  isSort?: boolean;
  Cell?: (val: any, row?: any) => JSX.Element | string;
};

const CustomTable = ({
  columns,
  rows,
  onRowClick,
  editBaseRoute,
  selectedSort,
  setSelectedSort,
}: any) => (
  <Table>
    <Head
      columns={columns}
      selectedSort={selectedSort}
      setSelectedSort={setSelectedSort}
    />
    <Body rows={rows} columns={columns} editBaseRoute={editBaseRoute} onRowClick={onRowClick} />
  </Table>
);

export default CustomTable;
