import { isUndefined } from 'lodash';
import { getRequest, patchRequest, postRequest, updateRequest, deleteRequest } from 'src/utils/axios';
import parse from 'src/utils/parse';
import logger from 'src/utils/logger';
// import axios from 'axios';
// import toast from 'react-hot-toast';

class PermissionsApi {
  async getAll(
    page: number,
    limit: number,
    search: string,
    isActive: boolean | null,
    columns?: any,
    sortVal?: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const payloadParams = {
        page,
        pageSize: limit,
        search,
        isActive,
        column: columns,
        sort: sortVal === 'asc' ? '1' : '-1'
      };
      if (search === '') {
        delete payloadParams.search;
      }
      if (columns === '' || columns === undefined) {
        delete payloadParams.column;
        delete payloadParams.sort;
      }
      if (sortVal === '') {
        delete payloadParams.sort;
      }
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_PERMISSIONS}/permission`,
        successFunction,
        errorFunction,
        payloadParams
      };
      getRequest(data);
    });
  }

  async create(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_PERMISSIONS}/permission`,
        payload,
        successFunction,
        errorFunction,
      };
      postRequest(data);
    });
  }

  async edit(payload: any, permissionId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_PERMISSIONS}/permission/${permissionId}`,
        payload,
        successFunction,
        errorFunction,
      };
      updateRequest(data);
    });
  }

  async getById(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_PERMISSIONS}/permission/${id}`,
        successFunction,
        errorFunction,
      };
      getRequest(data);
    });
  }

  async getPermissionsGroupedByModules(): Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_PERMISSIONS}/permission/groupbymodule`,
        successFunction,
        errorFunction,
      };
      getRequest(data);
    });
  }

  async getPermissionsByModuleId(moduleId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_PERMISSIONS}/permission/module/${moduleId}`,
        successFunction,
        errorFunction,
      };
      getRequest(data);
    });
  }

  async getActivePermissions(): Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_PERMISSIONS}/permission/active`,
        successFunction,
        errorFunction,
      };
      getRequest(data);
    });
  }

  async getPermissionsByRoleId(roleIdsString: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_PERMISSIONS}/permission/role`,
        successFunction,
        errorFunction,
        payloadParams: {
          id: roleIdsString
        }
      };
      getRequest(data);
    });
  }

  async toggleStatusById(permissionId): Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_PERMISSIONS}/permission/${permissionId}/toggle`,
        successFunction,
        errorFunction,
      };
      patchRequest(data);
    });
  }

  async deletePermissionById(permissionId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_PERMISSIONS}/permission/${permissionId}`,
        successFunction,
        errorFunction,
      };
      deleteRequest(data);
    }
    )
  }
}

export const permissionsApi = new PermissionsApi();
