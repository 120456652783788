import Modal from 'react-modal';
import CancelIcon from '@mui/icons-material/Cancel';
import { projectApi } from 'src/api/projectApi';
import React, { useCallback, useEffect, useState } from 'react';
import { styled, Box, Typography, TextField } from '@mui/material';
import { height, width } from '@material-ui/system';
import { useNavigate } from 'react-router';

interface AllProjectsProps {
  documentUrl?: string;
  isOpen: boolean;
  onClose: () => void;
}


const CustomTypography = styled(Typography)(({ theme }) => ({
  background: '#E4ECF7',
  width: '43px',
  height: '26px',
  textAlign: 'center',
  borderRadius: '36px'
}));
const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.divider}`,
  cursor: 'pointer'
}));

const AllProjects = ({ documentUrl, isOpen, onClose }: AllProjectsProps) => {
  const navigate = useNavigate();
  const modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      width: '50vw',
      height: '500px',
      zIndex: 1222,
    },
  };

  const [projects, setProjects] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const getProjects = useCallback(async () => {
    try {
      const res = await projectApi.getAllProjects();
      if (res.status === 200) {
        console.log('Response:', res);
        setProjects(res?.data?.data);
      } else if (res.status === 204) {
        setProjects([]);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      getProjects();
    }
  }, [isOpen, getProjects]);
  const filteredProjects = projects.filter(project =>
    project.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStyle}
    >
      <Box sx={{ position: 'relative', maxHeight: '75vh' }}>
        <CancelIcon
          onClick={onClose}
          sx={{ position: 'absolute', top: '-16px', right: '-16px', cursor: 'pointer', color: '#C22027', zIndex: 122 }}
        />
        <Box sx={{ padding: '30px 0px 0px 0px' }}>
          <TextField
            label="Search Projects"
            variant="outlined"
            fullWidth
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          {filteredProjects.length > 0 ? (
            filteredProjects.map(project => (
              <StyledBox key={project.id}
                onClick={(e) => navigate(`bulk-change/${project.name}/${project.id}`)}
              >
                <Typography variant="body1">{project.name}</Typography>
                <CustomTypography variant="body2">{project.totalCases}</CustomTypography>
              </StyledBox>
            ))
          ) : (
            <Typography>No projects available</Typography>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default AllProjects;
