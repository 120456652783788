import React, { useEffect, useRef, useState } from 'react';
import ReportingTable from './reporting-table';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import { reportingApi } from 'src/api/reporting';
import moment from 'moment';
import { uniqBy } from 'lodash';
import toast from 'react-hot-toast';

const columns = [
  {
    id: 'billableAccount',
    label: 'Account',
    width: '8%',
    maxWidth: '8%',
  },
  { id: 'project', label: 'Project', width: '5.5%', maxWidth: '5.5%' },
  { id: 'caseKey', label: 'Case Key', width: '6.3%', maxWidth: '6.3%' },
  { id: 'summary', label: 'Case Title', width: '45.3%', maxWidth: '45.3%' },
  { id: 'timeEstimate', label: 'Estimate', width: '6.3%', maxWidth: '6.3%' },
  { id: 'remainingTime', label: 'Remaining', width: '6.8%', maxWidth: '6.8%' },
  { id: 'loggedTime', label: 'Logged', width: '5.3%', maxWidth: '5.3%' },
  { id: 'category', label: 'Category', width: '6.1%', maxWidth: '6.1%' },
  { id: 'sprint', label: 'Sprint', width: '5.1%', maxWidth: '5.1%' },
  { id: 'releaseVersion', label: 'Version', width: '5.3%', maxWidth: '5.3%' },
];

const Reporting = () => {
  const [reportingData, setReportingData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [organizations, setOrganizations] = useState([]); // Store the autocomplete options
  const [billingAccounts, setBillingAccounts] = useState([]);
  const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);
  const [sprints, setSprints] = useState([]); // Store fetched sprints
  const [selectedSprint, setSelectedSprint] = useState(null);
  const [loadingSprints, setLoadingSprints] = useState(false);
  const [selectedRowsData, setSelectedRowsData] = useState([]);

  // Refs for focus state
  const orgFocusRef = useRef(false);
  const sprintFocusRef = useRef(false);

  useEffect(() => {
    if (!selectedOrganization?.id && selectedSprint?.id) return; // Only fetch if an organization is selected

    const fetchData = async () => {
      try {
        // Simulating an API call
        const response = await reportingApi.getAllReportings({
          page,
          pageSize,
          billingAccountId:
            billingAccounts
              ?.map((billingAccount) => billingAccount?.id)
              .join(',') ?? '',
          from: moment(selectedSprint?.startDate).format('YYYY-MM-DD'),
          to: moment(selectedSprint?.endDate).format('YYYY-MM-DD'),
          organizationId: selectedOrganization?.id,
        });
        setReportingData(response?.data?.data);
        setTotalRecords(response?.data?.totalRecords);
        setTotalPages(response?.data?.totalPages);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, [page, pageSize, selectedOrganization, selectedSprint]);

  // Fetch billing accounts for the autocomplete based on search term
  const fetchOrganizations = async () => {
    setLoadingAutocomplete(true);
    try {
      const response = await reportingApi.getOrganizations();

      setOrganizations(response?.data || []);
      const billingResponse = await reportingApi.getBillingAccounts({
        orgId: selectedOrganization
          ? selectedOrganization?.id
          : '64e4d2c228dea22ee9d5245f',
      });

      setBillingAccounts(billingResponse?.data || []);
    } catch (error) {
      console.error('Failed to fetch billing accounts:', error);
    }
    setLoadingAutocomplete(false);
  };

  // Fetch sprints for the selected organization based on sprint search term
  const fetchOrganizationSprints = async (organization) => {
    setLoadingSprints(true);
    try {
      const response = await reportingApi.getOrganizationsSprints({
        orgId: organization?.id,
      });
      const uniqueSprintNames = uniqBy(
        response.data,
        (item: any) => item?.name
      );

      setSprints(uniqueSprintNames || []);
      // Set the first sprint as default if available
      if (response?.data?.length === 0) {
        setSelectedSprint(null);
      }
      if (response?.data?.length > 0) {
        setSelectedSprint(response.data[0]); // Automatically set the first sprint as selected
      }
    } catch (error) {
      console.error('Failed to fetch organization sprints:', error);
    }
    setLoadingSprints(false);
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  // Fetch sprints when the selected organization changes
  useEffect(() => {
    if (selectedOrganization) {
      fetchOrganizationSprints(selectedOrganization); // Fetch sprints based on selected organization
    }
  }, [selectedOrganization]);

  // Set the default value on the first load
  useEffect(() => {
    const defaultOrg = organizations.find(
      (org) => org.id === '64e4d2c228dea22ee9d5245f'
    );

    setSelectedOrganization(defaultOrg);
  }, [organizations]);

  const handleSelectedRows = (selectedData) => {
    setSelectedRowsData(selectedData);
  };

  const exportHandler = async () => {
    try {
      const blob = await reportingApi.billableStatisticsExport({
        billingAccountIds: selectedRowsData?.length
          ? selectedRowsData?.join(',')
          : billingAccounts
              ?.map((billingAccount) => billingAccount?.id)
              .join(',') ?? '',
        from: moment(selectedSprint?.startDate).format('YYYY-MM-DD'),
        to: moment(selectedSprint?.endDate).format('YYYY-MM-DD'),
      });

      // Create a link to download the blob
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${selectedSprint?.name} ${moment(new Date()).format(
          'YYYY-MM-DD'
        )}.xlsx`
      ); // Adjust the filename and extension if needed
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      link.parentNode?.removeChild(link);
      toast.success('Reporting Sprints Export Successfully');
      setSelectedRowsData([]);
    } catch (error) {
      toast.error('Something went wrong');
      console.error('Failed to export', error);
    }
  };

  return (
    <Box p={2}>
      <Typography color="textPrimary" variant="h3">
        Reporting
      </Typography>
      <Box
        display="flex"
        width="100%"
        mb={2}
        mt={2}
        justifyContent="space-between"
        alignItems="center">
        <Box width="50%" display="flex" gap={2}>
          <Autocomplete
            id="organization-autocomplete"
            options={organizations}
            getOptionLabel={(option) => option.name || ''}
            loading={loadingAutocomplete}
            value={selectedOrganization} // Controlled by state
            isOptionEqualToValue={(option, value) => option.id === value?.id} // Fix the comparison issue
            clearIcon={null}
            sx={{
              width: '30%',
            }}
            onChange={(event, newValue) => {
              setSelectedOrganization(newValue); // Update the selected organization
            }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Organization"
                variant="outlined"
                InputLabelProps={{
                  sx: {
                    top: (() => {
                      if (selectedOrganization) {
                        return '0px';
                      }
                      if (!orgFocusRef.current) {
                        return '-7px';
                      }
                      return '0px';
                    })(),
                  },
                }}
                onFocus={() => {
                  orgFocusRef.current = true;
                }}
                onBlur={() => {
                  orgFocusRef.current = false;
                }}
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    height: '40px',
                  },
                  endAdornment: (
                    <>
                      {loadingAutocomplete ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />

          {/* Autocomplete for selecting sprint */}
          {selectedOrganization && (
            <Autocomplete
              id="sprint-autocomplete"
              options={sprints}
              getOptionLabel={(option) => option.name || ''}
              loading={loadingSprints}
              value={selectedSprint} // Bind the selected sprint
              defaultValue={sprints?.[0]}
              sx={{
                width: '30%',
              }}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              onChange={(event, newValue) => {
                setSelectedSprint(newValue); // Set the selected sprint
              }}
              clearIcon={null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sprint"
                  variant="outlined"
                  onFocus={() => {
                    sprintFocusRef.current = true;
                  }}
                  onBlur={() => {
                    sprintFocusRef.current = false;
                  }}
                  InputLabelProps={{
                    sx: {
                      top: (() => {
                        if (selectedSprint) {
                          return '0px';
                        }
                        if (!sprintFocusRef.current) {
                          return '-7px';
                        }
                        return '0px';
                      })(),
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      height: '40px',
                    },
                    endAdornment: (
                      <>
                        {loadingSprints ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          )}
        </Box>
        <Button variant="contained" color="secondary" onClick={exportHandler}>
          Export
        </Button>
      </Box>

      <ReportingTable
        columns={columns}
        rows={reportingData}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalRecords={totalRecords}
        totalPages={totalPages}
        onSelectionChange={handleSelectedRows}
        selectedRows={selectedRowsData}
        setSelectedRows={setSelectedRowsData}
      />
    </Box>
  );
};

export default Reporting;
