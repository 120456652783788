import React, { FC, useEffect, useState } from 'react';
import { Box, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CasesViewByProjects from './bulkCases';
import SelectOperation from './SelectOperation';
import TransitionCases from './TransitionCases';
import MoveCases from './MoveCases';
import { useParams } from 'react-router-dom';
import { projectApi } from 'src/api/projectApi';
import logger from 'src/utils/logger';
import DeleteOperation from './deleteOperation';
import EditPreview from './EditPreview';
import EditOperation from './EditOperation';
import FinalStep from './FinalStep';
import CommentCase from './commentOperation';
import { useNavigate } from 'react-router';
import ArrowIcon from './ArrowIcon';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    root: {
      backgroundColor: 'background.default',
      padding: '24px',
    },
    arrow: {
      color: '#000',
      cursor: 'pointer',
      transform: 'rotate(180deg)',
      marginLeft: '27px',
    }
  })
);

const BulkOperation: FC = () => {
  const navigate = useNavigate();


  // 
  const [moveCase, setMoveCase] = useState<any>({});
  const [status, setStatus] = useState<any>({});
  const [commentPayload, setCommentPayload] = useState<any>({});
  const [editOperationData, setEditOperationData] = useState<any>([]);
  const [afrIds, setAfrIds] = useState<any>([]);
  // 



  const { projectID } = useParams();
  const classes = useStyles();
  const [step, setStep] = useState<string>('1');
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [actionType, setActionType] = useState<string>('');
  const [projectName, setProjectName] = useState<string>('');

  const handleBulkIds = (ids: string[]) => {
    setSelectedIds(ids);
  };

  const fetchProjectById = async () => {
    const res = await projectApi.getProjectDetailsById(projectID);
    logger(res)

    setProjectName(res?.data?.name);
  }

  useEffect(() => {
    fetchProjectById()
  }, []);

  useEffect(() => {
    if (selectedIds.length > 0) {
      setStep('2');
    }
  }, [selectedIds, actionType]);

  useEffect(() => {
    if (actionType === 'transition' || actionType === 'delete' || actionType === 'edit' || actionType === 'move' || actionType === 'comment') {
      setStep('3');
    }
    if (actionType === 'delete') {
      setStep('4');
    }
  }, [actionType]);

  const handleAction = (data) => {
    setActionType(data)
  }



  const handleMoveCase = (data) => {
    setStep('4')

    setMoveCase(data)
  }



  const handleStatus = (value) => {
    delete value.rank
    setStatus(value)
    setStep('4')
  }
  const handleDelete = () => {
    setStep('4')
  }

  const handleEditPayload = (data) => {

    setEditOperationData(data)
    setStep('4')
  }
  const handleFinalStep = () => {
    setStep('5')
  }
  const handleAfrIds = (data) => {
    setAfrIds(data)
  }
  // handleComment
  const handleComment = (data) => {
    setCommentPayload(data)
    setStep('4')
  }
  // just simply add this style dnt chewck any thing
  useEffect(() => {
    if (step === "5") {
      const loaderRoot = document.getElementById("loader-root");
      loaderRoot.style.display = "none";
    }
  }, []);

  return (
    <Box className={classes.root}>
      {/* {step > '1' &&
        <ArrowIcon className={classes.arrow} />
      } */}
      {step === '1' && <CasesViewByProjects sendBulkIds={handleBulkIds} />}
      {step === '2' && <SelectOperation sendActionType={handleAction} selectedIds={selectedIds} />}
      {step === '3' && actionType === 'transition' && <TransitionCases statusType={handleStatus} />}
      {step === '3' && actionType === 'delete' && <DeleteOperation deleteAction={handleDelete} />}
      {step === '3' && actionType === 'move' && <MoveCases projectName={projectName} selectedIds={selectedIds} moveCases={handleMoveCase} />}
      {step === '3' && actionType === 'comment' && <CommentCase selectedIds={selectedIds} commentCase={handleComment} />}
      {step === '3' && actionType === 'edit' && <EditOperation editPayload={handleEditPayload} selectedIds={selectedIds} />}
      {/* preview Component actionType === 'edit' */}
      {step === '4' &&
        <EditPreview
          performAction={handleFinalStep}
          actionType={actionType}
          selectedIds={selectedIds}
          editPayload={editOperationData}
          moveCase={moveCase}
          status={status}
          commentPayload={commentPayload}
          sendAfrIds={handleAfrIds}
        />}

      {step === '5' &&
        <FinalStep
          actionType={actionType}
          selectedIds={selectedIds}
          commentPayload={commentPayload}
          editPayload={editOperationData}
          moveCase={moveCase}
          status={status}
          afrIds={afrIds}
        />}
    </Box>
  );
};

export default BulkOperation;
