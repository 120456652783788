import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import React, { useEffect } from 'react';

type SingleDeleteConfirmationProps = {
  isOpen : boolean,
  setDeleteDialogueVisible?: (param: boolean) => void;
  handleDelete: (param: string) => void;
  selectedItem: string,
  text: string
};

const SingleDeleteConfirmation = ({
  isOpen,
  setDeleteDialogueVisible,
  handleDelete,
  selectedItem,
  text
}: SingleDeleteConfirmationProps) => {
  const [open, setOpen] = React.useState(isOpen);
  const handleNo = () => {
    setDeleteDialogueVisible(false);
  };

  const handleYes = () => {
    handleDelete(selectedItem);
    setDeleteDialogueVisible(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleNo}
            color="primary"
          >
            No
          </Button>
          <Button
            onClick={handleYes}
            color="primary"
            variant="outlined"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SingleDeleteConfirmation;
