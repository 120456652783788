import { Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import SimpleBreadCrumb from 'src/components/widgets/BreadCrumb/SimpleBreadCrumb';
import TableList from 'src/components/widgets/tables/TableList';
import logger from 'src/utils/logger';

const ConfigurationListView = () => {
  const baseUrl = '/erp/settings';
  const [allPermissions, setAllPermissions] = useState<Array<any>>([]);
  const [count, setCount] = useState<number>(0);
  const [isActivated, setIsActivated] = useState<boolean | null>(null);
  const [page, setPage] = useState<any>(JSON.parse(localStorage.getItem('configurationsPagePg'))?.configurationsPageNo || 0);
  const [limit, setLimit] = useState<any>(JSON.parse(localStorage.getItem('configurationsPagePg'))?.configurationsPageLimit || 25);
  const [isDataRendered, setIsDataRendered] = useState<boolean>(false);
  logger(setIsDataRendered);

  const BreadCrumbsData = {
    title: '',
    links: [

      {
        name: 'configuration',
        to: ''
      },
    ]
  };

  const columns: Array<any> = [
    {
      name: 'Identifier',
      value: 'identifier',
    },
    {
      name: 'Name',
      value: 'name',
    },
    {
      name: 'Module',
      value: 'module',
    },
    {
      name: 'Sub-Module',
      value: 'subModule',
    },
    {
      name: 'Status',
      value: 'status',
    },
  ];

  const handlePage = (temp: number) => {
    setPage(temp);
  };
  const handleLimit = (temp: number) => {
    setLimit(temp);
  };

  const handleBulkDeactivate = async (checkedIds: Array<number>) => {
    try {
      //   const response = await employeesApi.bulkDeactivate(checkedIds);
      //   if (response?.status === 200) {
      //     toast.success('updated successfully');
      //     setIsDataRendered(!isDataRendered);
      //   }
      //   if (response?.status === 204) {
      //     toast.error('try again later');
    //   }
      logger(checkedIds, '', 'handle-bulk-deactivate: checkedIds: ');
      toast.success('Successfuly Deactivated');
    } catch (error) {
      logger(error, 'error');
      toast.error('try again later');
    }
  };

  const handleStatusToggle = async (id: number, _isActivated: boolean) => {
    try {
      //   const response: any = await employeesApi.updateStatusById(
      //     id,
      //     _isActivated
      //   );
      //   if (response?.status === 200) {
      //     toast.success('updated successfully');
      //     setIsDataRendered(!isDataRendered);
      //   }
      //   if (response?.status === 204) {
      //     toast.error('try again later');
      //   }
      //   logger(response);
      logger(id, '', 'handle-status-toggle: id: isActivated');
      logger(_isActivated, '', 'handle-status-toggle: id: isActivated');
      toast.success('Status Toggled');
    } catch (error) {
      logger(error, 'error');
      toast.error('try again later');
    }
  };

  const AllPermissions = [
    {
      id: '123',
      identifier: 'case-read',
      name: 'Dashbaord Permissions',
      module: 'Dashboard',
      subModule: 'Case',
      status: true,
    },
    {
      id: '123',
      identifier: 'case-read',
      name: 'XYZ',
      module: 'Cases',
      subModule: 'Cases',
      status: false,
    },
    {
      id: '123',
      identifier: 'case-read',
      name: 'XYZ',
      module: 'Cases',
      subModule: 'Cases',
      status: true,
    },
    {
      id: '123',
      identifier: 'case-read',
      name: 'XYZ',
      module: 'Cases',
      subModule: 'Cases',
      status: true,
    },
  ];
  useEffect(() => {
    setAllPermissions(AllPermissions);
    setCount(10);
    const configurationsPagePg = { configurationsPageLimit: limit, configurationsPageNo: page };
    localStorage.setItem('configurationsPagePg', JSON.stringify(configurationsPagePg));
  }, [isActivated, page, limit, isDataRendered]);
  return (
    <>
      <SimpleBreadCrumb data={BreadCrumbsData} />
      <Typography
        variant="h3"
        style={{ marginTop: '16px', marginBottom: '10px', fontSize: '20px', fontWeight: '700', color: '#000', }}
      >
        Configurations List
      </Typography>
      <TableList
        pageName="configurationsPagePg"
        allowedColumns={columns}
        data={allPermissions}
        redirectUrl={`${baseUrl}/configuration/add`}
        editRouteUrl={`${baseUrl}/configuration/edit`}
        setIsActivated={setIsActivated}
        count={count}
        page={page}
        limit={limit}
        handleLimit={handleLimit}
        handlePage={handlePage}
        handleBulkDeactivate={handleBulkDeactivate}
        handleStatusToggle={handleStatusToggle}
      />
    </>
  );
};

export default ConfigurationListView;
