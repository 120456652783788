import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactFlow, {
  Background,
  Controls,
  MarkerType,
  MiniMap,
  ReactFlowProvider,
  addEdge,
  useEdgesState,
  useNodesState,
} from 'reactflow';
import 'reactflow/dist/style.css';
import StartNode from '../../../../components/flow/nodes/StartNode';
import ToDoNode from '../../../../components/flow/nodes/ToDoNode';
import { initialEdges, initialNodes } from './data';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { itsmFlowApi } from 'src/api/itsmFlowApi';
import { requestApi } from 'src/api/requestApi';
import AllItsmNodes from 'src/components/flow/AllItsmNodes';
import * as Yup from 'yup';
import ButtonEdge from '../../../../components/flow/edges/ButtonEdge';
import CloseNode from '../../../../components/flow/nodes/CloseNode';
import logger from 'src/utils/logger';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    nodesRoot: {
      width: '100%',
      height: '150px',
      backgroundColor: '#fff',
      border: '1px solid black',
      borderRadius: '5px',
      padding: '10px 15px',
    },
    flowRoot: {
      marginTop: '10px',
      width: '100%',
      // height: 'calc(100% - 150px - 10px)',
      height: '100%',
      backgroundColor: '#ffffffcf',
      border: '2px solid rgba(0,0,0,0.2)',
      borderRadius: '5px',
    },
    btnContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '10px',
      marginTop: '10px',
    },
    btn: {
      padding: '10px 15px',
      height: '38px',
      minWidth: '129px',
      borderRadius: '4px',
      border: '2px solid #000',
      textTransform: 'capitalize',
      transition: 'all 0.2s linear',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(35,31,32,0.04)',
      },
    },
    btnRed: {
      borderColor: '#c22027',
      backgroundColor: '#c22027',
      color: '#fff',
      '&:hover': {
        backgroundColor: 'transparent',
        color: '#c22027',
      },
    },
  })
);

const nodeTypes = {
  start: StartNode,
  todo: ToDoNode,
  // readyforqa: ReadyForQaNode,
  // readyforpo: ReadyForPoNode,
  // qatesting: QaTestingNode,
  // poreview: PoReviewNode,
  // internalblocker: InternalBlockerNode,
  // inprogress: InProgressNode,
  // devtesting: DevTestingNode,
  close: CloseNode,
};
const edgeTypes = {
  custom: ButtonEdge,
};

const ItsmFlowAdd = () => {
  const navigate = useNavigate();
  const [allSubCategories, setSubCategories] = useState([]);
  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const classes = useStyles();
  const [nodes, setNodes, onNodesChange] = useNodesState<any>(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const handleConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            type: 'custom',
            label: 'label',
            markerEnd: { type: MarkerType.ArrowClosed },
          },
          eds
        )
      ),
    [setEdges]
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      let nodeData = event.dataTransfer.getData('application/reactflow');
      nodeData = JSON.parse(nodeData);

      // check if the dropped element is valid
      if (typeof nodeData === 'undefined' || !nodeData) {
        return;
      }
      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      const newNode = {
        id: nodeData?.id,
        type: 'todo',
        position,
        data: { label: 'label', isAny: false, name: nodeData?.name },
      };

      // setNodes((nds) => nds.concat(newNode));
      setNodes((nds) => [...nds, newNode]);
    },
    [reactFlowInstance]
  );

  const formik = useFormik({
    initialValues: {
      identifier: '',
      name: '',
      categoryId: '',
      categoryName: '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      identifier: Yup.string().required('Identifier required'),
      name: Yup.string()
        .required('Name required')
        .min(2, 'Minimum 2 characters allowed')
        .max(255, 'Maximum 255 characters allowed'),
      categoryId: Yup.string().required('Case type required'),
    }),
    onSubmit: async (values) => {
      let flowStructure = nodes?.map((nod) => ({
        id: nod?.id,
        allowAny: nod?.data?.isAny || false,
        to: [],
      }));
      edges?.forEach((edg) => {
        /* eslint-disable-next-line array-callback-return */
        flowStructure = flowStructure?.map((itm) => {
          if (itm?.id === edg?.source) {
            return { ...itm, to: [...itm.to, edg?.target] };
          }
          return itm;
        });
      });
      const data = {
        identifier: values?.identifier,
        categoryId: values?.categoryId,
        categoryName: values?.categoryName,
        name: values?.name,
        edges,
        nodes,
        flowStructure,
      };

      try {
        const res = await itsmFlowApi.createFlow({ data });
        if (res?.status === 201) {
          toast.success(res?.data || 'Flow Added Successfully');
          navigate('/erp/settings/itsm-flow');
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    },
  });

  const getAllSubCategories = async () => {
    try {
      // const res = await casesApi.getCaseTypes();
      const res = await requestApi.getAllSubCategories();
      let result = [];
      Object.keys(res?.data).map(
        (key) => (result = [...result, ...res?.data[key]])
      );
      setSubCategories(result || []);
    } catch (error) {
      logger(error);
      setSubCategories([]);
    }
  };

  useEffect(() => {
    getAllSubCategories();
  }, []);
  return (
    <>
      <ReactFlowProvider>
        <Grid container sx={{ height: '100%' }} spacing={2}>
          <Grid item xs={8} className={classes.flowRoot} ref={reactFlowWrapper}>
            <ReactFlow
              nodes={nodes}
              onNodesChange={onNodesChange}
              edges={edges}
              onEdgesChange={onEdgesChange}
              onConnect={handleConnect}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              onInit={setReactFlowInstance}
              onDrop={onDrop}
              onDragOver={onDragOver}>
              <Controls />
              <MiniMap />
              <Background gap={12} size={1} />
            </ReactFlow>
          </Grid>
          <Grid item xs={4}>
            <AllItsmNodes />
            <TextField
              label="Identifier"
              placeholder="Enter identifier"
              name="identifier"
              value={formik?.values?.identifier}
              onChange={formik?.handleChange}
              fullWidth
              sx={{ mt: 2 }}
              error={
                Boolean(formik?.touched?.identifier) &&
                Boolean(formik?.errors?.identifier)
              }
              helperText={
                formik?.touched?.identifier && formik?.errors?.identifier
              }
            />
            <TextField
              label="Name"
              placeholder="Enter name"
              name="name"
              value={formik?.values?.name}
              onChange={formik?.handleChange}
              fullWidth
              sx={{ mt: 2 }}
              error={
                Boolean(formik?.touched?.name) && Boolean(formik?.errors?.name)
              }
              helperText={formik?.touched?.name && formik?.errors?.name}
            />
            <TextField
              id="outlined-select-currency"
              select
              label="Select Category"
              value={formik?.values?.categoryId}
              onChange={(e) => {
                formik?.setFieldValue('categoryId', e?.target?.value);
              }}
              variant="outlined"
              fullWidth
              sx={{ mt: 2 }}
              error={
                Boolean(formik?.touched?.categoryId) &&
                Boolean(formik?.errors?.categoryId)
              }
              helperText={
                formik?.touched?.categoryId && formik?.errors?.categoryId
              }>
              {allSubCategories.map((option) => (
                <MenuItem key={option?.id} value={option?.id}>
                  {option?.name}
                </MenuItem>
              ))}
            </TextField>
            <div className={classes.btnContainer}>
              <button
                type="button"
                className={classes.btn}
                onClick={() => navigate('/erp/settings/status-flow')}>
                Cancel
              </button>
              <button
                type="button"
                className={`${classes.btn} ${classes.btnRed}`}
                onClick={() => formik.handleSubmit()}>
                Save
              </button>
            </div>
          </Grid>
        </Grid>
      </ReactFlowProvider>
    </>
  );
};

export default ItsmFlowAdd;
