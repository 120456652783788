import { Autocomplete, Box, Button, InputLabel, TextField, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { moduleApi } from 'src/api/modulesApi';
import { permissionsApi } from 'src/api/permissions';
import SimpleBreadCrumb from 'src/components/widgets/BreadCrumb/SimpleBreadCrumb';
import logger from 'src/utils/logger';
import * as Yup from 'yup';
import useAuth from 'src/hooks/useAuth';
import setTitle from 'src/utils/setTitle';

const useStyles = makeStyles<Theme>(() => createStyles({
  paper: {
    backgroundColor: '#fff',
    padding: '10px 80px 30px 80px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
  },
  fieldsRoot: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '238px',
    gridRowGap: '16px',

    '& .item': {
      '& .MuiInputLabel-root': {
        fontSize: '14px',
        fontWeight: 600,
        color: '#231f20',
      },
      '& .Mui-disabled': {
        backgroundColor: '#F3F3F3',
        cursor: 'not-allowed',
      }
    },
    '& .itemDescription': {
      gridColumn: '1/3',
    }
  },
  checkboxRoot: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    rowGap: '16px',

    '& .MuiFormControlLabel-root': {
      minWidth: '240px',
    },
    '& .MuiTypography-body1': {
      fontSize: '14px',
      fontWeight: 400,
      color: '231f20',
    },
    '& .MuiSvgIcon-root': {
      width: '16px',
      height: '16px',
    },
    '& .flexGrow': {
      flexGrow: 1,
    }
  },
  btnRoot: {
    display: 'flex',
    marginTop: '30px',
    gap: '9px',
    // flexDirection: 'row-reverse',
  }
}));
const PermissionEdit = () => {
  setTitle('ERP | Permissions - Edit');
  const baseUrl = '/erp/settings';
  const { user } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();
  const classes = useStyles();
  // const [moduleData, setModuleData] = useState<any>({});
  const BreadCrumbsData = {
    title: '',
    links: [
      {
        name: 'permissions',
        to: `${baseUrl}/permissions`
      },
      {
        name: 'edit',
        to: ''
      }
    ]
  };

  type PermissionDataType = {
    createdAt: string | null;
    description: string;
    id: string;
    identifier: string;
    isActivated: string;
    moduleId: string;
    subModuleId: string;
    module:{
      id: string;
      name: string;
    };
    name: string;
    subModule:{
      id: string;
      name: string;
    };
    updatedAt: string | null;
  };
  useEffect(() => {
    if (
      !user?.role?.some((roleObj) =>  roleObj.identifier === 'it22_admin')
    ) {
      window.location.href = '/admin/not-found';
    }
  }, []);
  const [permissionData, setPermissionData] = useState<PermissionDataType | null>(null);
  const schema = Yup.object({
    identifier: Yup.string().trim().required('Identifier required'),
    name: Yup.string().trim().required('Permission name required'),
    moduleId: Yup.string().trim().required('Module required'),
    subModuleId: Yup.string().trim().required('Sub Module required'),
  });

  type ModuleValueType = {
    id: string;
    name: string;
  };
  const [moduleValue, setModuleValue] = useState<ModuleValueType>({ id: '', name: 'Select Module' });
  const [moduleInputValue, setModuleInputValue] = useState('');
  const [allModules, setAllModules] = useState<any>([]);

  type SubModuleValueType = {
    id: string;
    name: string;
  };
  const [subModuleValue, setSubModuleValue] = useState<SubModuleValueType>({ id: '', name: 'Select Sub-Module' });
  const [subModuleInputValue, setSubModuleInputValue] = useState('');

  const getAllModules = async (search: string = '') => {
    try {
      const res = await moduleApi.autoComplete(search);
      setAllModules(res?.data);
    } catch (error) {
      if (error?.response?.status !== 401) {
        setAllModules([]);
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const moduleGetById = async () => {
    try {
      const res = await permissionsApi.getById(id);
      if (res?.status === 200) {
        setPermissionData(res?.data);
        if (res?.data?.module?.id) {
          setModuleValue({ id: res?.data?.module?.id || '', name: res?.data?.module?.name || '' });
          setModuleInputValue(res?.data?.module?.name || '');
        }
        if (res?.data?.subModule?.id) {
          setSubModuleValue({ id: res?.data?.subModule?.id || '', name: res?.data?.subModule?.name || '' });
          setSubModuleInputValue(res?.data?.subModule?.name || '');
        }
      } else {
        setPermissionData(null);
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        setPermissionData(null);
        toast.error(error?.response?.data?.message);
      }
    }
  };
  useEffect(() => {
    moduleGetById();
    getAllModules();
  }, []);
  return (
    <>
      <SimpleBreadCrumb data={BreadCrumbsData} />
      <Formik
        enableReinitialize
        initialValues={{
          identifier: permissionData?.identifier || '',
          name: permissionData?.name || '',
          moduleId: permissionData?.moduleId || '',
          subModuleId: permissionData?.subModuleId || '',
          description: permissionData?.description || '',
        }}
        validationSchema={schema}
        onSubmit={async (values) => {
          try {
            logger(values);
            const payload = {
              identifier: values?.identifier,
              name: values?.name,
              moduleId: values?.moduleId ? values?.moduleId : '',
              subModuleId: values?.subModuleId ? values?.subModuleId : '',
              description: values?.description
            };

            const res = await permissionsApi.edit(payload, permissionData?.id);
            if (res?.status === 200) {
              toast.success('Permission Edited Successfully');
              navigate(`${baseUrl}/permissions`);
            } else {
              toast.error('Internal Server Error');
            }
          } catch (error) {
            logger(error, 'error');
            if (error?.response?.status === 401) {
              toast.error('Unauthorized User');
            } else {
              toast.error('Internal Server Error');
            }
          }
        }}
      >
        {({ values, handleChange, handleBlur, setFieldValue, handleSubmit, touched, errors }) => (
          <form onSubmit={handleSubmit}>
            <Typography
              variant="h3"
              style={{ marginTop: '16px', marginBottom: '10px', fontSize: '20px', fontWeight: '700', color: '#000', }}
            >
              Edit Permission
            </Typography>
            <Box className={`${classes.paper} ${classes.fieldsRoot}`}>
              <Box className="item">
                <InputLabel htmlFor="identifier-simple">Identifier*</InputLabel>
                <TextField
                  id="identifier-simple"
                  fullWidth
                  placeholder="Identifier"
                  size="small"
                  name="identifier"
                  value={values?.identifier}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched?.identifier && errors?.identifier)}
                  helperText={touched?.identifier && errors?.identifier}
                  disabled
                />
              </Box>
              <Box className="item">
                <InputLabel htmlFor="permission-name-simple">Permission Name*</InputLabel>
                <TextField
                  id="permission-name-simple"
                  fullWidth
                  placeholder="Permission Name"
                  size="small"
                  name="name"
                  value={values?.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched?.name && errors?.name)}
                  helperText={touched?.name && errors?.name}
                />
              </Box>
              <Box className="item">
                <InputLabel htmlFor="module-simple">Module*</InputLabel>
                <Autocomplete
                  id="module-simple"
                  fullWidth
                  placeholder="Module"
                  size="small"
                  options={allModules}
                  getOptionLabel={(option:any) => option?.name}
                  inputValue={moduleInputValue}
                  onInputChange={(event, newInputValue) => {
                    setModuleInputValue(newInputValue);
                  }}
                  value={moduleValue}
                  onChange={(event, newValue) => {
                    setFieldValue('moduleId', newValue?.id ? newValue?.id : '');
                    setModuleValue(newValue);
                  }}
                  onBlur={handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      name="moduleId"
                      error={Boolean(touched?.moduleId && errors?.moduleId)}
                      helperText={touched?.moduleId && errors?.moduleId}
                    />
                  )}
                />
              </Box>
              <Box className="item">
                <InputLabel htmlFor="sub-module-simple">Sub-Module</InputLabel>
                <Autocomplete
                  id="sub-module-simple"
                  fullWidth
                  placeholder="Sub-Module"
                  size="small"
                  options={allModules}
                  getOptionLabel={(option:any) => option?.name}
                  inputValue={subModuleInputValue}
                  onInputChange={(event, newInputValue) => {
                    setSubModuleInputValue(newInputValue);
                  }}
                  value={subModuleValue}
                  onChange={(event, newValue) => {
                    setFieldValue('subModuleId', newValue?.id ? newValue?.id : '');
                    setSubModuleValue(newValue);
                  }}
                  onBlur={handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      name="subModuleId"
                      error={Boolean(touched?.subModuleId && errors?.subModuleId)}
                      helperText={touched?.subModuleId && errors?.subModuleId}
                    />
                  )}
                />
              </Box>
              <Box className="item itemDescription">
                <InputLabel htmlFor="description-simple">Description</InputLabel>
                <TextField
                  id="description-simple"
                  fullWidth
                  multiline
                  rows={4}
                  placeholder="Description"
                  size="small"
                  type="text"
                  name="description"
                  onChange={handleChange}
                  value={values?.description}
                />
              </Box>
            </Box>
            <Box className={classes.btnRoot}>
              <Link
                to={`${baseUrl}/permissions`}
                style={{ marginLeft: 'auto', }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ height: '38px' }}
                >
                  Cancel
                </Button>
              </Link>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                sx={{ height: '38px' }}
              >
                Save
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default PermissionEdit;
