import React, { FC, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { statusFlowApi } from 'src/api/statusFlowApi';
import toast from 'react-hot-toast';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderRadius: theme.shape.borderRadius,
        margin: "30px"
    },
    formControl: {
        width: '100%',
    },
    radioGroup: {
        marginBottom: theme.spacing(3),
    },
    button: {
        backgroundColor: '#c22027',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#d43f40',
        },
        alignSelf: 'flex-end',
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    formLabel: {
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(3),
        marginTop: theme.spacing(3),
        fontWeight: 600,
        fontSize: 14
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    subtitle: {
        marginLeft: theme.spacing(3),
    },
    controlLabel: {
        marginLeft: theme.spacing(3),
    },
    btnStyle: {
        height: '24px',
        padding: '20px',
        gap: '10px',
        borderRadius: '6px 0px 0px 0px',
        opacity: 1,
        margin: '10px'
    },
    Done: {
        background: '#DEFFEE',
        color: '#66CB9F'
    },
    Todo: {
        background: '#FFEDE3',
        color: '#F7936F'
    },
    Testing: {
        background: '#FFE6E4',
        color: '#F16063'
    },
    Inprogress: {
        background: '#E5FDFF',
        color: '#09A0BF'
    },
    flexBtn: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        '@media (min-width:900px)': {
            marginLeft: '15%',
        },
    },
    tansitionSec: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexWrap: 'wrap',
        '& .MuiFormControlLabel-label': {
            paddingLeft: '25px',
        }
    },
    BoxCenter: {
        textAlign: 'center'
    },
    confirmBG: {
        display: 'flex',
        justifyContent: 'center',
        margin: '10px',
    },
    subtitleCon: {
        background: '#ff000066',
        maxWidth: '333px',
        color: 'white !important',
        padding: '10px',
        borderRadius: '5px'

    }
}));

const DeleteOperation: FC<any> = ({ deleteAction }) => {
    const classes = useStyles();
    const [value, setValue] = React.useState('');
    const [statusObj, setStatusObj] = React.useState({});
    const [allStatus, setAllStatus] = React.useState([]);

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const getAllStatus = async () => {
        try {
            const response = await statusFlowApi.getAllStatus();
            setAllStatus(response.data.data);
        } catch (error) {
            if (error?.response?.status !== 401) {
                toast.error(error?.response?.data?.message);
            }
        }
    };

    useEffect(() => {
        getAllStatus();
    }, []);

    const handleClick = (status) => {
        setStatusObj(status);
    };

    const changeStatus = () => {
        deleteAction('statusObj');
    };

    return (
        <div className={classes.root}>
            <FormControl component="fieldset" className={classes.formControl}>
                <Typography variant="h6" className={classes.formLabel}>
                    Step 3 of 4: Delete Cases
                </Typography>
                <Divider className={classes.divider} />
                <Box className={classes.BoxCenter}>
                    <Typography variant="body2" color="textSecondary" className={classes.subtitle}>
                        Are you sure you want to delete these items?
                    </Typography>
                    <div className={classes.confirmBG}>
                        <Typography variant="body2" color="textSecondary" className={classes.subtitleCon}>
                            This action cannot be undone.
                        </Typography>
                    </div>
                </Box>
            </FormControl>
            <Button variant="contained" className={classes.button} onClick={changeStatus}>
                Next
            </Button>
        </div>
    );
};

export default DeleteOperation;
