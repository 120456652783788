import { Box, Container } from '@material-ui/core';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import useSettings from 'src/hooks/useSettings';
import TableList from 'src/components/widgets/tables/TableList';

import toast from 'react-hot-toast';
import { useOutletContext, useParams } from 'react-router-dom';
import { contactsApi } from 'src/api/contactsApi';
import { Columns } from 'src/types/components/tableList.types';
import setTitle from 'src/utils/setTitle';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import useAuth from 'src/hooks/useAuth';

const useStyles = makeStyles<Theme>(() => createStyles({
  compactSize: {
    paddingLeft: '0px',
    paddingRight: '0px',

    '& .MuiBreadcrumbs-root': {
      marginTop: '0px',
    }
  },
  formRoot: (props) => ({
    '& .MuiPaper-root': {
      marginTop: props.isCompactSize === true ? '10px' : '24px',
    }
  })

}));

const ContactsSection: FC = () => {
  setTitle('CRM | Contacts');
  const { user } = useAuth();
  let { isCompactSize }:any = useOutletContext();
  isCompactSize = isCompactSize || false;
  const classes = useStyles({ isCompactSize });
  const { organizationID } = useParams();
  const baseUrl = '/erp/crm/organization/details';
  const { settings } = useSettings();
  const [contacts, setContacts] = useState<Array<any>>([]);
  const [count, setCount] = useState<number>(0);
  const [isActivated, setIsActivated] = useState<boolean | null>(null);
  const [page, setPage] = useState<number>(JSON.parse(localStorage.getItem('contactsByOrgPagePg'))?.contactsByOrgPageNo || 0);
  const [limit, setLimit] = useState<number>(JSON.parse(localStorage.getItem('contactsByOrgPagePg'))?.contactsByOrgPageLimit || 25);
  const [search, setSearch] = useState<string>(JSON.parse(localStorage.getItem('contactsByOrgPagePg'))?.contactsByOrgSearch || '');
  const [isDataRendered, setIsDataRendered] = useState<boolean>(false);
  const handleSearch = (val:string) => {
    setSearch(val);
  };

  const columns: Array<Columns> = [
    {
      name: 'Full Name',
      value: 'name',
    },
    {
      name: 'Email',
      value: 'email',
    },

    {
      name: 'Phone Number',
      value: 'contactNumber',
    },
    {
      name: 'Status',
      value: 'isActive',
    },
  ];

  const getContacts = async () => {
    try {
      const res = await contactsApi.getAll(page, limit, search, isActivated, organizationID);
      if (res.status === 200) {
        setContacts(res?.data?.data);
        setCount(res?.data?.totalRecords);
      } else if (res.status === 204) {
        setContacts([]);
        setCount(0);
      }
    } catch (error) {
      toast.error('Internal Server Error');
      setContacts([]);
      setCount(0);
    }
  };

  const handlePage = (temp: number) => {
    setPage(temp);
  };
  const handleLimit = (temp: number) => {
    setLimit(temp);
  };

  const handleBulkDeactivate = async (checkedIds: Array<number>) => {
    try {
      const response = await contactsApi.bulkDeactivate(checkedIds);
      if (response?.status === 200) {
        toast.success('updated successfully');
        setIsDataRendered(!isDataRendered);
      }
      if (response?.status === 204) {
        toast.error('try again later');
      }
    } catch (error) {
      toast.error('try again later');
    }
  };

  const handleStatusToggle = async (id: number, _isActivated: boolean) => {
    try {
      const response: any = await contactsApi.updateStatusById(
        id,
        _isActivated
      );
      if (response?.status === 200) {
        toast.success('updated successfully');
        setIsDataRendered(!isDataRendered);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (!user?.permissions?.includes('organization-client-contact-get-all')
    && !user?.permissions?.includes('organization-client-contact-filter-tabs-read')
    && !user?.permissions?.includes('organization-client-contact-create')
    && !user?.permissions?.includes('organization-client-contact-toggle-status')
    && !user?.permissions?.includes('organization-client-contact-delete')
    && !user?.permissions?.includes('organization-client-contact-update')
    && !user?.permissions?.includes('organization-client-contact-get-by-id')
    && !user?.permissions?.includes('organization-client-contact-bulk-deactivate')) {
      window.location.href = '/admin/not-found';
    }
  }, []);

  const permissions = {
    getAll: user?.permissions?.includes('organization-client-contact-get-all'),
    filterTabs: user?.permissions?.includes('organization-client-contact-filter-tabs-read'),
    create: user?.permissions?.includes('organization-client-contact-create'),
    toggleStatus: user?.permissions?.includes('organization-client-contact-toggle-status'),
    delete: user?.permissions?.includes('organization-client-contact-delete'),
    update: user?.permissions?.includes('organization-client-contact-update'),
    getById: user?.permissions?.includes('organization-client-contact-get-by-id'),
    bulkDeactivate: user?.permissions?.includes('organization-client-contact-bulk-deactivate'),
  };

  useEffect(() => {
    getContacts();
    const contactsByOrgPagePg = { contactsByOrgPageLimit: limit, contactsByOrgPageNo: page, contactsByOrgSearch: search };
    localStorage.setItem('contactsByOrgPagePg', JSON.stringify(contactsByOrgPagePg));
  }, [isActivated, page, limit, isDataRendered, search]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8,
        }}
      >
        <Container
          maxWidth={settings.compact ? 'xl' : false}
          className={`${isCompactSize ? classes.compactSize : ''}`}
        >
          <Box className={classes.formRoot}>
            <TableList
              pageName="contactsByOrgPagePg"
              allowedColumns={columns}
              setIsActivated={setIsActivated}
              count={count}
              page={page}
              limit={limit}
              search={search}
              handleSearch={handleSearch}
              handleLimit={handleLimit}
              handlePage={handlePage}
              handleBulkDeactivate={handleBulkDeactivate}
              handleStatusToggle={handleStatusToggle}
              data={contacts}
              redirectUrl={`${baseUrl}/${organizationID}/contacts/new`}
              editRouteUrl={`${baseUrl}/${organizationID}/contacts/edit`}
              permissions={permissions}
              searchPlaceholder="Search by Email, Phone No."
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ContactsSection;
