import React, { useState, useEffect, useRef } from 'react';
import { TextField, Box, IconButton, Link } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface Input {
  id: number;
  value: string;
  label: string;
}

interface MultiFileLinkInputProps {
  inputs: Input[];
  onAddInput: () => void;
  onRemoveInput: (id: number) => void;
  onInputChange: (
    id: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onLabelChange: (
    id: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  placeholder?: string;
  labelPlaceholder?: string;
  mainClassName?: string;
  inputClassName?: string;
  labelClassName?: string;
  disabled?: boolean;
}

const MultiFileLinkInput: React.FC<MultiFileLinkInputProps> = ({
  inputs,
  onAddInput,
  onRemoveInput,
  onInputChange,
  onLabelChange,
  placeholder,
  labelPlaceholder,
  mainClassName,
  inputClassName,
  labelClassName,
  disabled,
}) => {
  const [isValid, setIsValid] = useState<{ [key: number]: boolean }>({});
  const containerRef = useRef<HTMLDivElement>(null);
  const [isStacked, setIsStacked] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setIsStacked(containerRef.current.offsetWidth < 600);
      }
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleInputChange = (
    id: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.target;
    const isEntryValid = value.startsWith('https://');

    setIsValid((prevIsValid) => ({
      ...prevIsValid,
      [id]: isEntryValid,
    }));

    onInputChange(id, event);
  };

  return (
    <Box className={mainClassName} ref={containerRef}>
      {inputs.map((input) => (
        <Box
          key={input.id}
          display="flex"
          flexDirection={isStacked ? 'column' : 'row'}
          alignItems={isStacked ? 'flex-start' : 'center'}
          marginBottom={2}
        >
          {!disabled && (
            <TextField
              className={labelClassName}
              variant="outlined"
              value={input.label}
              onChange={(e) => onLabelChange(input.id, e)}
              style={{ marginRight: isStacked ? 0 : 10, marginBottom: isStacked ? 10 : 0, flex: 1 }}
              placeholder={labelPlaceholder}
              disabled={disabled}
              fullWidth={isStacked}
            />
          )}
          {disabled ? (
            <Link
              href={input.value}
              underline="none"
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: 10, flex: 1, fontWeight: 600, fontSize: '14px' }}
            >
              {input.label ? `${input.label}: ${input.value}` : input.value}
            </Link>
          ) : (
            <TextField
              className={inputClassName}
              variant="outlined"
              value={input.value}
              onChange={(e) => handleInputChange(input.id, e)}
              style={{ marginRight: isStacked ? 0 : 10, marginBottom: isStacked ? 10 : 0, flex: 1 }}
              placeholder={placeholder}
              error={isValid[input.id] === false && input.value !== ''}
              helperText={isValid[input.id] === false && input.value !== '' ? 'Invalid URL' : ''}
              disabled={disabled}
              fullWidth={isStacked}
            />
          )}
          {!disabled && (
            <Box display="flex" flexDirection={isStacked ? 'row' : 'column'}>
              <IconButton
                color="primary"
                onClick={onAddInput}
                disabled={!isValid[input.id]}
                style={{ marginRight: isStacked ? 10 : 0, marginBottom: isStacked ? 0 : 10 }}
              >
                <AddIcon />
              </IconButton>
              {inputs.length > 1 && (
                <IconButton
                  color="secondary"
                  onClick={() => onRemoveInput(input.id)}
                >
                  <RemoveIcon />
                </IconButton>
              )}
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default MultiFileLinkInput;
