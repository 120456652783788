import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = (props: any) => {
  const { data } = props;
  // const data = {
  //   labels: ['yes', 'No'],
  //   datasets: [{
  //     label: 'Poll',
  //     data: [3, 6],
  //     backgroundColor: ['black', 'red'],
  //     borderColor: ''
  //   }]

  // };

  const options = {
    // tooltips:{
    //   segment: true,
    //   position: 'outside',
    // },
    plugins: {
      legend: {
        labels: {
          generateLabels(chart) {
            // Get the default label list
            const original =
              ChartJS.overrides.pie.plugins.legend.labels.generateLabels;
            const labelsOriginal = original.call(this, chart);

            // Build an array of colors used in the datasets of the chart
            let datasetColors = chart.data.datasets.map(
              (e) => e.backgroundColor
            );
            datasetColors = datasetColors.flat();

            // Modify the color and hide state of each label
            labelsOriginal.forEach((label) => {
              label.datasetIndex = label.index % 4; // Calculate correct dataset index
              label.hidden = !chart.isDatasetVisible(label.datasetIndex);
              label.fillStyle = datasetColors[label.index];
            });

            return labelsOriginal;
          },
        },
        onClick(mouseEvent, legendItem, legend) {
          // toggle the visibility of the dataset from what it currently is
          legend.chart.getDatasetMeta(legendItem.datasetIndex).hidden =
            legend.chart.isDatasetVisible(legendItem.datasetIndex);
          legend.chart.update();
        },
      },
      tooltips: {
        enabled: false, // Hide tooltips
      },
      showLine: true,
      title: {
        display: false,
      },
    },
  };
  const getCircumference = (dd) => (dd.dataset.data[0] / 390) * 360;
  const tempData = {
    labels: ['Total Assigned', 'Completed', 'In Progress', 'Deprioritize'],
    datasets: [
      {
        label: 'Total Assigned',
        data: [390],
        backgroundColor: '#4285f4',
        circumference: (ctx) => getCircumference(ctx),
      },
      {
        label: 'Completed',
        data: [360],
        backgroundColor: '#34a853',
        circumference: (ctx) => getCircumference(ctx),
      },
      {
        label: 'In Progress',
        data: [25],
        backgroundColor: '#fbbc04',
        circumference: (ctx) => getCircumference(ctx),
      },
      {
        label: 'Deprioritize',
        data: [5],
        backgroundColor: '#ea4335',
        circumference: (ctx) => getCircumference(ctx),
      },
    ],
  };
  return (
    <div>
      <Doughnut data={data || tempData} options={options} />
    </div>
  );
};

export default DoughnutChart;
