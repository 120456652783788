/* eslint-disable no-nested-ternary */
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Container, Grid, Tab, Tabs } from '@material-ui/core';
import { useState } from 'react';
import { ProjectGrid } from './ProjectGrid';
import ProjectGraph from './ProjectGraph';

const tabs = [
  {
    label: 'Graph',
    value: 'graph',
  },
  {
    label: 'Summary',
    value: 'summary',
  },
];
const useStyles = makeStyles<Theme>(
  () =>
    createStyles({
      btn: {
        padding: 0,
      },
      tabs: {
        '& .MuiTab-root': {
          backgroundColor: 'transparent',
          minWidth: '120px',
        },
        '& .Mui-selected': {
          backgroundColor: '#231F20',
          color: '#fff',
        },
      },
    }),
  {
    name: 'ProjectSummary',
  }
);
function ProjectSummary(props: any) {
  const classes = useStyles();
  const [value, setValue] = useState('graph');
  console.log(props, classes);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 4,
      }}>
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={12} md={6}>
            <Tabs
              indicatorColor="primary"
              scrollButtons="auto"
              textColor="primary"
              value={value}
              variant="scrollable"
              className={classes.tabs}>
              {tabs.map((tab) => (
                <Tab
                  key={tab?.value}
                  label={tab?.label}
                  value={tab?.value}
                  onClick={() => {
                    setValue(tab?.value);
                  }}
                />
              ))}
            </Tabs>
          </Grid>
        </Grid>
        <Box sx={{ mt: 1 }}>
          {value === 'graph' ? <ProjectGraph /> : <ProjectGrid /> }
        </Box>
      </Container>
    </Box>
  );
}

export default ProjectSummary;
