// import { useEffect, useRef, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function BarChart(props: any) {
  const { data, showLegend = false } = props;
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: showLegend,
        position: 'top' as const,
        // labels: {
        //   generateLabels(chart) {
        //     const labels = chart.data.datasets[0].label;
        //     const colors = chart.data.datasets[0].backgroundColor;
        //     const legendItems = [];
        //     labels.forEach((label, index) => {
        //       const listItem = {
        //         text: label,
        //         fillStyle: colors[index],
        //         hidden: false,
        //         index
        //       };
        //       legendItems.push(listItem);
        //     });
        //     return legendItems;
        //   }
        // },
        // onClick: (e, legendItem) => {
        //   const index = legendItem.datasetIndex;
        //   const ci = e.target.chart;
        //   const meta = ci.getDatasetMeta(index);
        //   meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
        //   ci.update();
        // }
      },
      title: {
        display: false,
        text: 'Chart.js Bar Chart',
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize: 1,
            },
          },
        ],
      },
    },
  };
  // data set 1 for simple bar charts
  // const finalData = {
  //   labels : data.map(row => row.label),
  //   datasets:  data.map((item   ) => ({
  //     label: item.label,
  //     data: [item.value],
  //     backgroundColor: item.color
  //   })),
  // };
  // data set 2 for group charts

  const finalData = {
    labels: data.labels,
    datasets: data.datasets.map((item) => ({
      label: item.label,
      data: item.data,
      backgroundColor: item.backgroundColor,
      maxBarThickness: 25,
    })),
  };
  //   const tempData = {
  //     "labels": [
  //         "Total",
  //         "Done",
  //         "In Progress",
  //         "Pending"
  //     ],
  //     "datasets": [
  //         {
  //             "label": "Total",
  //             "data": [
  //                 115,49 , 3,3
  //             ],
  //             "backgroundColor": ["#4285f4",  "#34a853" , "#fbbc04", "#ea4335"]
  //         },
  //     ]
  // }

  // console.log(data, tempData)
  return (
    <>
      <Bar options={options} data={finalData} />
    </>
  );
}

export default BarChart;

// {
//   "labels": [
//       "Total Assigned",
//       "Completed",
//       "In Progress",
//       "Deprioritize"
//   ],
//   "datasets": [
//       {
//           "label": "Total Assigned",
//           "data": [
//               304
//           ],
//           "backgroundColor": "#4285f4"
//       },
//       {
//           "label": "Completed",
//           "data": [
//               224
//           ],
//           "backgroundColor": "#34a853"
//       },
//       {
//           "label": "In Progress",
//           "data": [
//               7
//           ],
//           "backgroundColor": "#fbbc04"
//       },
//       {
//           "label": "Deprioritize",
//           "data": [
//               0
//           ],
//           "backgroundColor": "#ea4335"
//       }
//   ]
// }
