/* eslint-disable no-underscore-dangle */
import {
  Box,
  Button,
  makeStyles,
  createStyles,
  Theme,
  Autocomplete,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import CustomAutoComplete from 'src/components/autoComplete/autoComplete';

type CreateProductFormProps = {
  closeDrawer: any;
  formData?: any;
  onSubmit: any;
  editForm?: boolean;
  schema: any;
};

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    formRoot: {
      '& .MuiInputLabel-root': {
        top: '-8px',
      },
      '& .MuiInputLabel-shrink': {
        top: '0px',
      },
      '& label': {
        fontWeight: '550'
      }
    }

  }))

const OrderForm = ({
  closeDrawer,
  onSubmit,
  schema,
  formData,
  editForm,
}: CreateProductFormProps) => {
  const classes = useStyles();

  const clients = useSelector((state: any) => state.oms.clientAutoComplete);


  const formik = useFormik({
    initialValues: {
      product: formData?.product || null,
      client: formData?.client || null,
      date: formData?.date.substring(0, 10) || '',
      note: formData?.description || '',
      distributorDiscount: formData?.distributorDiscount || null,
      clientDiscount: formData?.clientDiscount || null,
    },
    onSubmit,
    validationSchema: schema,
  });

  return (
    <form noValidate onSubmit={formik.handleSubmit} className={classes.formRoot}>
      <div className="ticketBody">
        <div className="ticketDropDown">
          <CustomAutoComplete
            id='order-productId'
            value={formik?.values?.product}
            onSelect={(e: any, newValue: any) => {
              formik.setFieldValue('product', newValue);
            }}
            placeholder="Product"
            label="Product"
            error={Boolean(
              formik?.touched?.product && formik?.errors?.product
            )}
            helperText={
              formik?.touched?.product && formik?.errors?.product
            }
            apiUrl={`${process.env.REACT_APP_OMS_URL}/autoComplete/product`}
          />
        </div>

        <div className="ticketDropDown">
          <Autocomplete
            id="order-client"
            options={clients}
            value={formik?.values?.client}
            getOptionLabel={(option) => option.name}
            onChange={(e: any, newValue: any) => {
              formik.setFieldValue('client', newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Client"
                label="Client"
                error={Boolean(
                  formik?.touched?.client && formik?.errors?.client
                )}
                helperText={
                  formik?.touched?.client && formik?.errors?.client
                }
              />
            )}
          />
        </div>
        <div className="ticketDropDown">
          <TextField
            id="quote-distributorDiscount"
            type="number"
            name="distributorDiscount"
            label='Distributor Discount'
            value={formik?.values?.distributorDiscount}
            placeholder="Distributor Discount"
            InputProps={{
              inputProps: { min: 0, max: 100 },
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            onChange={formik?.handleChange}
            fullWidth
            error={Boolean(formik.touched?.distributorDiscount && formik?.errors?.distributorDiscount)}
            helperText={formik.touched?.distributorDiscount && formik?.errors?.distributorDiscount}
          />
        </div>
        <div className="ticketDropDown">
          <TextField
            id="quote-clientDiscount"
            type="number"
            name="clientDiscount"
            label='Client Discount'
            value={formik?.values?.clientDiscount}
            placeholder="Client Discount"
            InputProps={{
              inputProps: { min: 0, max: 100 },
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            onChange={formik?.handleChange}
            fullWidth
            error={Boolean(formik.touched?.clientDiscount && formik?.errors?.clientDiscount)}
            helperText={formik.touched?.clientDiscount && formik?.errors?.clientDiscount}
          />
        </div>
        <div className="ticketDropDown">
          <TextField
            id="quote-date"
            label="Date"
            type="date"
            name="date"
            onChange={(e) => {
              formik.handleChange(e);
            }}
            fullWidth
            value={formik.values.date}
            InputLabelProps={{
              shrink: true,
            }}
            error={
              Boolean(formik.touched.date) &&
              Boolean(formik.errors.date)
            }
            helperText={formik.touched.date && formik.errors.date}
          />
        </div>
        <div className="ticketDropDown">
          <TextField
            id="quote-note"
            type="text"
            name="note"
            label='Description'
            multiline
            rows={4}
            value={formik?.values?.note}
            placeholder="Description"
            onChange={formik?.handleChange}
            fullWidth
            error={Boolean(formik.touched?.note && formik?.errors?.note)}
            helperText={formik.touched?.note && formik?.errors?.note}
          />
        </div>
        <div className="submitTicket">
          <Box display="flex" sx={{ marginLeft: 'auto' }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => closeDrawer()}
              sx={{ marginLeft: 'auto', height: '38px' }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              sx={{ height: '38px' }}>
              {editForm ? 'Save' : 'Create'}
            </Button>
          </Box>
        </div>
      </div>
    </form>
  );
};

export default OrderForm;
