import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { okrApi } from 'src/api/okrApi';
import toast from 'react-hot-toast';
import BarChart from 'src/components/barChart/Bar';

const useStyles = makeStyles<Theme>(
  () =>
    createStyles({
      btn: {
        padding: 0,
      },
      graphBox: {
        padding: 16,
        marginTop: '10px',
        background: 'white',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
      },
    }),
  {
    name: 'DepartmentGroup',
  }
);
function DepartmentGroup() {
  const classes = useStyles();
  const [departmentData, setDepartmentData] = useState({});

   // 4285f4 blue
  // 34a853 green
  // ea4335 red
  // fbbc04 yellow


// Total Assigned == blue
// completed == greeen
// inprogress == orange
// depriorites == red
  const keys = [
    { key: 'totalCount', title: 'Total', color: '#4285f4' },
    { key: 'Done', title: 'Done', color: '#34a853' },
    { key: 'In Progress', title: 'In Progress', color: '#fbbc04' },
    { key: 'Pending', title: 'Pending', color: '#ea4335' },
  ];

  function groupDataByDepartment(data: any[]) {
    const groupedData = {};
    data.forEach((item) => {
      const departmentName = item.name;
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const key in item) {
        if (key === 'subDepartmentName') {
          const subDepartment = item[key];
          const values = keys.map((i,index) => ({
            label: i.title,
            data: groupedData[departmentName] ? [...groupedData[departmentName]?.datasets[index].data , item[i.key]] : [item[i.key]],
            backgroundColor: i.color,
          }));
          const labels = groupedData[departmentName] ? [...groupedData[departmentName].labels ,subDepartment ]: [subDepartment];
          groupedData[departmentName] = { labels, datasets : values };
        }
      }
    });
    return groupedData;
  }

  const getDepartmentData = async () => {
    try {
      const response = await okrApi.getOverallTaskSummary();
      const groupedData = groupDataByDepartment(response.data);
      setDepartmentData(groupedData);
    } catch (error) {
      toast.error(error.message);
    }
  };
  useEffect(() => {
    getDepartmentData();
  }, []);
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
      }}
      className={classes.table}>
      <Grid container spacing={2} className={classes.root}>
        {departmentData &&
          Object.keys(departmentData).map((item) => (
            <Grid item md={6} lg={4}>
              <Box className={classes.graphBox}>
                <h1
                  style={{
                    color: '#000',
                    margin: 0,
                    fontSize: 20,
                    fontWeight: 700,
                    lineHeight: '116.7%',
                  }}>
                  {item}
                </h1>
                <BarChart data={departmentData[item]} showLegend/>
              </Box>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}

export default DepartmentGroup;
