/* eslint-disable no-underscore-dangle */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { omsApi } from 'src/api/omsApis';
import toast from 'react-hot-toast';

const initialState: any = {
    allOrders: [],
    allQuotes: [],
    clientAutoComplete: [],
    productAutoComplete: [],

}
export const getAllClients = createAsyncThunk(
    "getAutoCompleteClients",
    async () => {
        try {
            const response = await omsApi.getOmsClient();
            if (response.status === 200) {
                return response.data
            }
            return []
        } catch (error) {
            return [];
        }
    }
);
export const getAllProducts = createAsyncThunk(
    "getAutoCompleteProducts",
    async () => {
        try {
            const response = await omsApi.getOmsProducts();
            if (response.status === 200) {
                return response.data
            }
            return []
        } catch (error) {
            return [];
        }
    }
);

export const fetchAllOrders = createAsyncThunk(
    "getAllOrders",
    async (data: any) => {
        try {
            const response = await omsApi.getAllOrders(data);
            if (response.status === 200) {
                return response.data
            }
            return []
        } catch (error) {
            return [];
        }
    }
);
export const fetchAllQuotes = createAsyncThunk(
    "getAllQuotes",
    async (data: any) => {
        try {
            const response = await omsApi.getAllQuotes(data);
            if (response.status === 200) {
                return response.data
            }
            return []
        } catch (error) {
            return [];
        }
    }
);
export const createOrder = createAsyncThunk(
    "createOrder",
    async (data: any) => {
        try {
            const response = await omsApi.CreateOrder(data);
            if (response.status === 201) {
                toast.success('Order created')
                return response.data
            }
            return []
        } catch (error) {
            return [];
        }
    }
);
export const createQuote = createAsyncThunk(
    "createQuote",
    async (data: any) => {
        try {
            const response = await omsApi.CreateQuote(data);
            if (response.status === 201) {
                toast.success('Quote created')
                return response.data
            }
            return []
        } catch (error) {
            return [];
        }
    }
);

export const editOrder = createAsyncThunk(
    "editOrder",
    async (payload: any) => {
        try {
            const response = await omsApi.EditOrder(payload.id, payload.data);
            // console.log("edit resp", response)
            if (response?.status === 200) {
                toast.success('Order Updated')
                return response
            }
            return null
        } catch (error) {
            return null

        }
    }
);
export const editQuote = createAsyncThunk(
    "editQuote",
    async (payload: any) => {
        try {
            const response = await omsApi.EditQuote(payload.id, payload.data);
            // console.log("edit resp", response)
            if (response?.status === 200) {
                toast.success('Quote Updated')
                return response
            }
            return null
        } catch (error) {
            return null

        }
    }
);

const pimModuleSlice = createSlice({
    name: "osmSlice",
    initialState,
    reducers: {},
    extraReducers: builder => {

        builder.addCase(fetchAllOrders.fulfilled, (state, action) => {
            state.allOrders = action.payload
        })
        builder.addCase(fetchAllQuotes.fulfilled, (state, action) => {
            state.allQuotes = action.payload
        })
        builder.addCase(getAllClients.fulfilled, (state, action) => {
            state.clientAutoComplete = action.payload
        })
        builder.addCase(getAllProducts.fulfilled, (state, action) => {
            state.productAutoComplete = action.payload
        })
    }

});

export default pimModuleSlice.reducer;
