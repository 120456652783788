import { SvgIcon } from '@material-ui/core';

const ArrowIcon = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.37266e-05 4.99953C8.37208e-05 5.13214 0.0527609 5.25932 0.14653 5.35309C0.240299 5.44685 0.367475 5.49953 0.500084 5.49953L24.2931 5.49953L21.1461 8.64553C21.0522 8.73942 20.9995 8.86676 20.9995 8.99953C20.9995 9.13231 21.0522 9.25965 21.1461 9.35353C21.24 9.44742 21.3673 9.50016 21.5001 9.50016C21.6329 9.50016 21.7602 9.44742 21.8541 9.35353L25.8541 5.35353C25.9006 5.30709 25.9376 5.25191 25.9628 5.19117C25.988 5.13042 26.001 5.0653 26.001 4.99953C26.001 4.93377 25.988 4.86864 25.9628 4.8079C25.9376 4.74715 25.9006 4.69198 25.8541 4.64553L21.8541 0.645533C21.8076 0.599045 21.7524 0.562169 21.6917 0.53701C21.6309 0.51185 21.5658 0.498901 21.5001 0.498901C21.3673 0.498901 21.24 0.551646 21.1461 0.645533C21.0522 0.739419 20.9995 0.866757 20.9995 0.999533C20.9995 1.13231 21.0522 1.25965 21.1461 1.35353L24.2931 4.49953L0.500084 4.49953C0.367475 4.49953 0.240299 4.55221 0.14653 4.64598C0.0527609 4.73975 8.37324e-05 4.86692 8.37266e-05 4.99953Z"
      fill="#8492A6"
    />
  </SvgIcon>
);

export default ArrowIcon;
