import { FC } from 'react';
import Modal from 'react-modal';
import CancelIcon from '@material-ui/icons/Cancel';

Modal.setAppElement('#root'); // Set the root element for accessibility
interface SimpleModalProps {
  isOpen: boolean;
  onClose: () => void;
  imageUrl: string; // Adjust the type accordingly
}
const SImpleModal:FC<SimpleModalProps> = ({ isOpen, onClose, imageUrl }) => {
  const modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
    },
  };
  const closeButtonStyle:any = {
    position: 'absolute',
    top: '-16px',
    right: '-16px',
    cursor: 'pointer',
    color: '#C22027'
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStyle}
    >
      <div style={{ position: 'relative', maxHeight: '75vh' }}>
        <CancelIcon
          onClick={onClose}
          style={closeButtonStyle}
        />
        <img
          src={imageUrl}
          alt="Preview"
          style={{ maxWidth: '100%', maxHeight: '100%', marginBottom: '10px' }}
        />
      </div>
    </Modal>
  );
};

export default SImpleModal;
