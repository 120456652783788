import { isUndefined } from 'lodash';
import axios from 'src/utils/axios';
import logger from 'src/utils/logger';
import parse from 'src/utils/parse';

class OkrApi {
    async getAllDepartments(page, pageSize): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_OKR_URL}/department/all`, {
                    params: {
                        page,
                        pageSize
                    }
                })
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    logger(error, 'errors');
                    reject(error);
                });
        });
    }

    async getDepartmentsHourly(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_OKR_URL}/hourlyGraph/department`)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    logger(error, 'errors');
                    reject(error);
                });
        });
    }

    async getAllProjects(page, pageSize): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_OKR_URL}/project/all`, {
                    params: {
                        page,
                        pageSize
                    }
                })
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    logger(error, 'errors');
                    reject(error);
                });
        });
    }

    async getEmployeeWiseTaskSummary(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_OKR_URL}/issue/employeeTaskSummary`
                )
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    logger(error, 'errors');
                    reject(error);
                });
        });
    }

    async getOverallTaskSummary(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_OKR_URL}/issue/overAllSummary`)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    logger(error, 'errors');
                    reject(error);
                });
        });
    }

    async getEmployeeSummary(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_OKR_URL}/issue/employeeSummary`
                )
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    logger(error, 'errors');
                    reject(error);
                });
        });
    }

    async getEmployeeHourlySummary(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_OKR_URL}/hourlyGraph/employee`
                )
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    logger(error, 'errors');
                    reject(error);
                });
        });
    }

    async getDevDepartmentSummary(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_OKR_URL}/issue/devDepartmentSummary`
                )
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    logger(error, 'errors');
                    reject(error);
                });
        });
    }

    async getOpsDepartmentSummary(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_OKR_URL}/issue/opsDepartment`
                )
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    logger(error, 'errors');
                    reject(error);
                });
        });
    }

    async getProjectWiseSummary(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_OKR_URL}/issue/projectSummary`
                )
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    logger(error, 'errors');
                    reject(error);
                });
        });
    }

    async getOverallSummary(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_OKR_URL}/issue/grandTotal`
                )
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    logger(error, 'errors');
                    reject(error);
                });
        });
    }

    async getOverallHourlySummary(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_OKR_URL}/hourlyGraph/overAllAggregate`
                )
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    logger(error, 'errors');
                    reject(error);
                });
        });
    }

}

export const okrApi = new OkrApi();

