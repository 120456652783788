/* eslint-disable no-underscore-dangle */
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import React, { useCallback, useEffect, useState } from 'react';

import toast from 'react-hot-toast';
// import { useSearchParams } from 'react-router-dom';

import { consultingApi } from 'src/api/consultingApi';
// import CreateCase from 'src/components/cases/CreateCase';
import ConsultingUnitsListView from 'src/components/widgets/tables/ConsultingUnitsListView';
import useSettings from 'src/hooks/useSettings';
import setTitle from 'src/utils/setTitle';
import { useParams } from 'react-router';
import CreateFormSideBar from 'src/components/widgets/CreateFormSideBar/CreateFormSideBar';
// import EditWorkLogForm from './EditWorkLogForm';
import WorkLogForm from './WorkLogForm';
import * as Yup from 'yup';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    btn: {
      height: '30px',
      padding: '0 5px',
      borderRadius: '4px',
      color: '#fff',
      '& .MuiButton-iconSizeMedium': {
        margin: '0',
      },
    },
    textFieldSelect: {
      width: '230px',
      marginLeft: '13px',
    },
    textFieldSelectRoot: {
      borderRadius: '4px',
      height: '38px',
    },
    tabButton: {
      width: '181px',
    },
    cardRoot: {
      borderRadius: '4px',
    },
    tabs: {
      '& .MuiTab-root': {
        backgroundColor: 'transparent',
        minWidth: '120px',
      },
      '& .Mui-selected': {
        backgroundColor: '#231F20',
        color: '#fff',
      },
    },
    tableHead: {
      '& .MuiTableCell-root': {
        textTransform: 'capitalize',
      },
    },
    tableRow: {
      '& :hover': {
        cursor: 'pointer',
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    ticketMain: {
      position: 'relative',
      background: 'transparent',
      '& .customCss': {
        padding: 0,
      },
      '& .filterArea': {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'relative',
        '& .rdrCalendarWrapper': {
          position: 'absolute',
          zIndex: '999',
          right: '0',
          top: -20,
        },
      },
      '&  .MuiTableCell-head': {
        padding: ' 10px 8px',
      },
      '& .dropDowns': {
        display: 'flex',
        '& .buttonParents': {
          position: 'relative',
        },
        '&  .buttonParents .dropDownInner': {
          overflowY: 'auto',
          position: 'absolute',
          padding: '13px',
          height: '220px',
          width: '350px',
          background: '#fff',
          zIndex: '22',
          boxShadow: ' 0px 0px 8px 3px rgba(0, 0, 0, 0.25)',
          borderRadius: '2px',
          top: '100%',
          left: 0,
          '& .dropDownInnerRow': {
            '& span': {
              fontSize: '12px',
            },
            '& p': {
              margin: '0',
            },
            '& .status': {
              alignItems: 'center',
            },
            display: 'flex',
            alignItems: 'center',
          },
        },
        '& .buttonParents+.buttonParents': {
          marginLeft: '10px',
        },
        '& button img': {
          marginLeft: '20px',
        },
      },
      '& .MuiAutocomplete-root': {
        background: '#fff',
      },
    },
    addButton: {
      height: 40,
      width: '47px',
      borderRadius: '5px',
      minWidth: 'initial',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: '10px',
      '& .MuiButton-startIcon': {
        margin: 0,
      },
    },
  })
);

function ConsultingLogs(props: any) {
  setTitle('Consulting | Unit logs');
  const { hiddenColumns, searchQuery, updateReset, updateFlag, triggerUpdate } =
    props;
  const { organizationID } = useParams();
  const classes = useStyles();
  const { settings } = useSettings();
  const [isOpenCreateWorkLogDrawer, setIsOpenCreateWorkLogDrawer] =
    useState<boolean>(false);
  const [isOpenEditWorkLogDrawer, setIsOpenEditWorkLogDrawer] = useState(false);
  const [rowData, setRowData] = useState({});
  const [allData, setAllData] = useState<any>([]);
  const [page, setPage] = useState<any>(
    JSON.parse(localStorage.getItem('consultingUnitsPg'))?.PageNo || 0
  );
  const [pageSize, setPageSize] = useState<any>(
    JSON.parse(localStorage.getItem('consultingUnitsPg'))?.PageLimit || 25
  );
  const handlePage = (temp: number) => {
    setPage(temp);
  };
  const [totalCount, setTotalCount] = useState<number>(0);
  const handlePageSize = (temp: number) => {
    setPageSize(temp);
  };
  const handleCloseCreateWlogDrawer = () => {
    setIsOpenCreateWorkLogDrawer(false);
  };

  const handleCloseEditWlogDrawer = () => {
    setIsOpenEditWorkLogDrawer(false);
  };
  const addCases = useCallback(
    async (open: boolean, event: any) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setIsOpenCreateWorkLogDrawer(open);
    },
    [isOpenCreateWorkLogDrawer]
  );

  /** sort data */
  type SortDataTypes = {
    id: 'asc' | 'desc';
  };
  const [selectedSort, setSelectedSort] = useState({
    key: JSON.parse(localStorage.getItem('consultingUnitsPg'))?.key || '',
    value: JSON.parse(localStorage.getItem('consultingUnitsPg'))?.value || '',
  });
  const [sortData, setSortData] = useState<SortDataTypes>({
    id: JSON.parse(localStorage.getItem('consultingUnitsPg'))?.id || 'asc',
  });

  const resetTable = () => {
    localStorage.removeItem('consultingUnitsPg');
    handlePageSize(10);
    handlePage(0);
    setSortData({
      id: 'asc',
    });
    setSelectedSort({ key: '', value: '' });
  };

  const handleOpenEditWLogDrawer = (drawerState: boolean, row?: any) => {
    setRowData(row);
    setIsOpenEditWorkLogDrawer(drawerState);
  };

  const toggleSortData = (fieldName: string) => {
    type SortStatusType = 'asc' | 'desc';
    let sortStatus: SortStatusType = 'asc';
    const initial: SortDataTypes = {
      id: 'asc',
    };
    // eslint-disable-next-line default-case
    switch (fieldName) {
      case 'id':
        if (sortData.id === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          id: sortStatus,
        });
        break;
    }
  };

  const getAllData = async () => {
    try {
      const fieldToColumnMap = {
        id: '',
      };

      const columnValue = fieldToColumnMap[selectedSort?.key] || 'defaultSort';
      const varr = {
        page,
        pageSize,
        search: searchQuery,
        column: columnValue,
        sort: selectedSort?.value === 'asc' ? '1' : '-1',
        orgId: organizationID,
        eventType: 'cuWorkLog',
      };
      if (selectedSort?.key === '' || selectedSort?.value === undefined) {
        delete varr.column;
        delete varr.sort;
      }
      const keys = Object.keys(varr);
      const values = Object.values(varr);

      // Create an array to store the non-empty key-value pairs
      const queryParams = [];
      for (let i = 0; i < values.length; i++) {
        if (values[i] !== '' && values[i] !== null && values[i] !== undefined) {
          queryParams.push(`${encodeURIComponent(keys[i])}=${values[i]}`);
        }
      }
      const queryString = queryParams.join('&');
      let response = await consultingApi.getAllCunsulting(queryString);
      // let response = await consultingApi.getAllCunsultingUnitLogs();
      response = response?.data;
      setAllData(response?.data);
      setTotalCount(response?.totalRecords);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setAllData([]);
        setTotalCount(0);
      }
    }
  };

  const createWorkLog = async (values, actions) => {
    try {
      const payload = {
        orgId: organizationID,
        // orderNumber: values?.orderNumber?.orderNumber,
        // clientId: values?.client?.id,               Client Id removed for now but might be added later
        data: {
          cuWType: values?.cuWtype,
          employeeId: values?.employee?.id,
          cuWDescription: values?.cuWDescription,
          cuWReport: values?.cuWReport,
          cuWhours: values?.hours,
          project: values?.project?.project || null,
        },
        // cuOrderDate: values.date
      };

      const response = await consultingApi.createWorkLog(payload);
      if (response?.status === 201) {
        toast.success('Created Successfully');
        localStorage.removeItem('formikValues');
        setIsOpenCreateWorkLogDrawer(false);
        actions.resetForm();
        triggerUpdate();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const EditWorkLog = async (values, actions) => {
    try {
      const payload = {
        // orgId: organizationID,
        // orderNumber: values?.orderNumber?.orderNumber,
        // clientId: values?.client?.id,               Client Id removed for now but might be added later
        data: {
          cuWType: values?.cuWtype,
          employeeId: values?.employee?.id,
          cuWDescription: values?.cuWDescription,
          cuWReport: values?.cuWReport,
          cuWhours: values?.hours,
          project: values?.project?.project || null,
        },
        // cuOrderDate: values.date
      };
      const wLogId = values.id;
      const response = await consultingApi.editWorkLog(payload, wLogId);
      if (response?.status === 200) {
        toast.success('Updated Successfully');
        localStorage.removeItem('formikValues');
        setIsOpenEditWorkLogDrawer(false);
        actions.resetForm();
        triggerUpdate();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const createWorkLogSchema = Yup.object().shape({
    employee: Yup.object()
      .shape({
        name: Yup.string(),
        id: Yup.string(),
      })
      .nullable()
      .required('Employee required'),
    hours: Yup.number()
      .nullable()
      .min(0.1, 'The minimum amount is 0.1')
      .required('Hours required'),
    cuWtype: Yup.object()
      .shape({
        role: Yup.string(),
        cu: Yup.number(),
      })
      .nullable()
      .required('Role Required'),
    cuWReport: Yup.string().trim()
    .matches(
      /((https?:\/\/)?[^\s.]+\.[\w][^\s]+)/,
      'Please enter a valid link to a document'
    ),
  });

  const EditWorkLogSchema = Yup.object().shape({
    employee: Yup.object()
      .shape({
        name: Yup.string(),
        id: Yup.string(),
      })
      .nullable()
      .required('Employee required'),
    hours: Yup.number()
      .nullable()
      .min(0.1, 'The minimum amount is 0.1')
      .required('Hours required'),
    cuWtype: Yup.object()
      .shape({
        role: Yup.string(),
        cunit: Yup.number(),
      })
      .nullable()
      .required('Role Required'),
    cuWReport: Yup.string().trim()
      .matches(
        /((https?:\/\/)?[^\s.]+\.[\w][^\s]+)/,
        'Please enter a valid link to a document'
      ),
  });

  const columns = [
    { id: 'cuwid', label: 'Id', isSort: true, toggleSortData },
    { id: 'cuwType', label: 'Type', isSort: true, toggleSortData },
    { id: 'createdAt', label: 'Date', isSort: true, toggleSortData },
    { id: 'employee', label: 'Employee', isSort: true, toggleSortData },
    { id: 'project', label: 'Project', isSort: true, toggleSortData },
    { id: 'hours', label: 'Hrs', isSort: true, toggleSortData },
    {
      id: 'consumeHours',
      label: 'Consumed Hrs',
      isSort: true,
      toggleSortData,
    },
    { id: 'auditDate', label: 'Audit Date', isSort: true, toggleSortData },
    { id: 'auditUser', label: 'Audit User', isSort: true, toggleSortData },
    { id: 'cuwReport', label: 'Report', isSort: true, toggleSortData },
    // {
    //   id: 'cuwDescription',
    //   label: 'Description',
    //   isSort: true,
    //   toggleSortData,
    // },
  ];
  const visibleColumns = [
    'data.cuwid',
    // 'data.orderNumber',
    'data.cuWType.role',
    'data.date',
    'data.employeeId',
    'data.project',
    'data.cuWhours',
    'data.cuConsumed',
    'data.CUWAuditDate',
    'data.cuWAuditUser',
    'data.cuWReport',
    // 'data.cuWDescription',
  ];

  useEffect(() => {
    getAllData();
    const consultingUnitsPg = {
      consultingPageLimit: pageSize,
      consultingPageNo: page,
      consultingSearch: searchQuery,
      consultingSortId: sortData?.id,
      consultingSelectedSortKey: selectedSort?.key,
      consultingSelectedSortValue: selectedSort?.value,
    };
    localStorage.setItem(
      'consultingUnitsPg',
      JSON.stringify(consultingUnitsPg)
    );
  }, [
    page,
    pageSize,
    searchQuery,
    sortData?.id,
    selectedSort?.key,
    selectedSort?.value,
    updateFlag,
  ]);

  useEffect(() => {
    if (updateReset !== undefined) resetTable();
  }, [updateReset]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 4,
        }}
        className={classes.ticketMain}>
        <Container
          maxWidth={settings.compact ? 'xl' : false}
          className={
            hiddenColumns?.customCss === 'customCss' ? 'customCss' : ''
          }>
          <Box sx={{ mt: 1 }}>
            <Grid container alignItems="flex-center" sx={{ mb: 2 }}>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                {hiddenColumns?.heading === false ? (
                  ''
                ) : (
                  <Typography color="textPrimary" variant="h4">
                    Worklogs
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}>
                <Button
                  variant="contained"
                  color="secondary"
                  endIcon={<AddCircleIcon />}
                  className={classes.btn}
                  sx={{ height: 30 }}
                  onClick={(e) => addCases(true, e)}>
                  <span style={{ margin: '0 10px' }}>Create Log</span>
                </Button>
              </Grid>
            </Grid>
            <ConsultingUnitsListView
              data={allData}
              column={columns}
              sortData={sortData}
              visibleColumns={visibleColumns}
              toggleSortData={toggleSortData}
              selectedSort={selectedSort}
              setSelectedSort={setSelectedSort}
              page={page}
              limit={pageSize}
              handleLimit={handlePageSize}
              handlePage={handlePage}
              count={totalCount}
              handleClick={handleOpenEditWLogDrawer}
            />
          </Box>
        </Container>
      </Box>

      {isOpenCreateWorkLogDrawer && (
        <CreateFormSideBar
          isOpen={isOpenCreateWorkLogDrawer}
          closeDrawer={handleCloseCreateWlogDrawer}
          title="Create Work Log">
          <WorkLogForm
            closeDrawer={handleCloseCreateWlogDrawer}
            onSubmit={createWorkLog}
            organizationId={organizationID}
            schema={createWorkLogSchema}
          />
        </CreateFormSideBar>
      )}

      {isOpenEditWorkLogDrawer && (
        <CreateFormSideBar
          isOpen={isOpenEditWorkLogDrawer}
          closeDrawer={handleCloseEditWlogDrawer}
          title="Edit Work Log">
          <WorkLogForm
            closeDrawer={handleCloseEditWlogDrawer}
            onSubmit={EditWorkLog}
            schema={EditWorkLogSchema}
            formData={rowData}
            organizationId={organizationID}
            editForm
          />
        </CreateFormSideBar>
      )}
    </>
  );
}

export default ConsultingLogs;
