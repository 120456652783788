/* eslint-disable no-underscore-dangle */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Backdrop, Box, TextField } from '@material-ui/core';
import CustomButton from 'src/components/widgets/buttons/CustomButton';
import { useFormik } from 'formik';
// import { useFormik } from 'formik';

type PopUpProps = {
  isOpen: boolean;
  data: any;
  setEditPopUp: any;
  handleSubmit: any;
};

const useStyles = makeStyles({
  paper: {
    backgroundColor: 'white',
    padding: '20px',
    zIndex: 1,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: '5px',
  },
  fields: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
    marginBottom: '10px',
  },
});

const EditRolePopUp = ({
  isOpen,
  data,
  setEditPopUp,
  handleSubmit,
}: PopUpProps) => {
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      id: data?._id || '',
      roleName: data?.role || '',
      cunit: data?.cunit || 0,
    },
    onSubmit: handleSubmit,
  });

  const handleCancel = () => {
    setEditPopUp(false);
  };

  const handleSave = () => {
    handleSubmit({
      _id: formik.values.id,
      role: formik.values.roleName,
      cunit: formik.values.cunit,
    });
    setEditPopUp(false);
  };

  const handleClose = () => {
    setEditPopUp(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500, // Set the transition duration in milliseconds
        style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
      }}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <div className={classes.paper}>
        <Box className={classes.fields}>
          <TextField
            name="roleName"
            variant="outlined"
            value={formik.values.roleName}
            onChange={formik.handleChange}
            fullWidth
            label="Role"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            name="cunit"
            type="number"
            value={formik.values.cunit}
            onChange={formik.handleChange}
            autoComplete='off'
            variant="outlined"
            fullWidth
            label="Cu Units"
            inputProps={{ step: 0.1, min: 0 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        <Box className={classes.buttons}>
          <CustomButton value="Cancel" onClick={handleCancel} />
          <CustomButton value="Save" color="secondary" onClick={handleSave} />
        </Box>
      </div>
    </Modal>
  );
};

export default EditRolePopUp;
