/* eslint-disable no-underscore-dangle */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from 'react-hot-toast';
import { pimApi } from 'src/api/pimApis';

const initialState: any = {
    totalProducts: [],
    allCategories: [],
    allSuppliers: [],
    allMeasurement: [],
    allDistributors: [],
    pimCategories: []
}
export const fetchAllProducts = createAsyncThunk(
    "getAllProducts",
    async (data: any) => {
        try {
            const response = await pimApi.getAllProducts(data);
            if (response.status === 200) {
                return response.data
            }
            return []
        } catch (error) {
            return [];
        }
    }
);
export const fetchAllPimCategories = createAsyncThunk(
    "getAllCategries",
    async (data: any) => {
        try {
            const response = await pimApi.getPimCategories(data);
            if (response.status === 200) {
                return response.data
            }
            return []
        } catch (error) {
            return [];
        }
    }
);
export const createNewProduct = createAsyncThunk(
    "createProduct",
    async (formikValues: any) => {
        try {
            const priceCalc = (duration, price) => {
                const obj = {
                    monthly: {
                        productPrice1M: price,
                        productPrice1Y: 0,
                        productPrice3Y: 0,
                    },
                    yearly: {
                        productPrice1M: 0,
                        productPrice1Y: price,
                        productPrice3Y: 0,
                    },
                    threeYearly: {
                        productPrice1M: 0,
                        productPrice1Y: 0,
                        productPrice3Y: price,
                    }
                }

                return obj[duration];
            }
            const pricesObj = priceCalc(formikValues.priceDuration, formikValues.price);

            const payload = {
                name: formikValues.name,
                description: "string",
                productSKU: formikValues.sku,
                productCurrency: formikValues.currency,
                productCategory: formikValues.category?._id,
                productSockets: formikValues.sockets,
                productCores: formikValues.cores,
                productNodes: formikValues.nodes,
                productSupportType: formikValues.supportType,
                productVirtualGuest: formikValues.vGuests,
                productMeasurement: formikValues.unitOfMeasure?.name,
                distributorId: formikValues.distributor?._id,
                quarter: "1st",
                isActive: formikValues.status === 'active',
                ...pricesObj
            }
            const response = await pimApi.createProduct(payload);
            if (response?.status === 201) {
                toast.success('Product Created')
                return response
            }
            return null
        } catch (error) {
            return null

        }
    }
);

export const editProduct = createAsyncThunk(
    "editProduct",
    async (payload: any) => {
        try {
            const response = await pimApi.EditProduct(payload.id, payload.data);
            // console.log("edit resp", response)
            if (response?.status === 200) {
                toast.success('Product Updated')
                return response
            }
            return null
        } catch (error) {
            return null

        }
    }
);

export const editCategory = createAsyncThunk(
    "editCategory",
    async (payload: any) => {
        try {
            const response = await pimApi.EditCategory(payload.id, payload.data);
            // console.log("edit resp", response)
            if (response?.status === 200) {
                toast.success('Category Updated')
                return response
            }
            return null
        } catch (error) {
            return null

        }
    }
);


export const getAllCategories = createAsyncThunk(
    "getAutoCompleteCategories",
    async () => {
        try {
            const response = await pimApi.getAllCategories();
            if (response.status === 200) {
                return response.data
            }
            return []
        } catch (error) {
            return [];
        }
    }
);

export const getAllSuppliers = createAsyncThunk(
    "getAutoCompleteSuppliers",
    async () => {
        try {
            const response = await pimApi.getAllSuppliers();
            if (response.status === 200) {
                return response.data
            }
            return []
        } catch (error) {
            return [];
        }
    }
);
export const getAllMeasurement = createAsyncThunk(
    "getAutoCompleteMeasurement",
    async () => {
        try {
            const response = await pimApi.getAllMeasurement();
            if (response.status === 200) {
                return response.data
            }
            return []
        } catch (error) {
            return [];
        }
    }
);
export const getAllDistributors = createAsyncThunk(
    "getAutoCompleteDistributors",
    async () => {
        try {
            const response = await pimApi.getAllDistributors();
            if (response.status === 200) {
                return response.data
            }
            return []
        } catch (error) {
            return [];
        }
    }
);
export const uploadCsvData = createAsyncThunk(
    "csvdataUploadfile",
    async (formData: any) => {
        try {
            const response = await pimApi.UploadCsvFile(formData)
            if (response.status === 200 || response.status === 202) {
                return response
            }
            return response.message
        } catch (error) {
            return error;
        }
    }
);
const pimModuleSlice = createSlice({
    name: "pimModule",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchAllPimCategories.fulfilled, (state, action) => {
            state.pimCategories = action.payload
        })
        builder.addCase(fetchAllProducts.fulfilled, (state, action) => {
            state.totalProducts = action.payload
        })
        builder.addCase(getAllCategories.fulfilled, (state, action) => {
            state.allCategories = action.payload
        })
        builder.addCase(getAllSuppliers.fulfilled, (state, action) => {
            state.allSuppliers = action.payload
        })
        builder.addCase(getAllMeasurement.fulfilled, (state, action) => {
            state.allMeasurement = action.payload
        })
        builder.addCase(getAllDistributors.fulfilled, (state, action) => {
            state.allDistributors = action.payload
        })
        builder.addCase(createNewProduct.fulfilled, (state, action) => {
            console.log('createNewProduct payload', action.payload);
        })
        builder.addCase(editProduct.fulfilled, (state, action) => {
            const updatedProduct = action.payload.data; // Assuming your response payload is wrapped in an object with a `data` property
            const index = state.totalProducts.data.findIndex(product => product._id === updatedProduct._id);
            if (index !== -1) {
                state.totalProducts.data[index] = updatedProduct;
            }
        });
        builder.addCase(editCategory.fulfilled, (state, action) => {
            const updatedCategory = action.payload.data; // Assuming your response payload is wrapped in an object with a `data` property
            const index = state.pimCategories.data.findIndex(product => product._id === updatedCategory._id);
            if (index !== -1) {
                state.pimCategories.data[index] = { ...state.pimCategories.data[index], ...updatedCategory };
            }
        });
    }

});

export default pimModuleSlice.reducer;
