import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import React, { useEffect } from 'react';

const ToggleStatusDialogue = ({
  isOpen,
  setToggleStatusAlert,
  handleStatusToggle,
  clickedItem,
  clickedItemStatus,
}: any) => {
  const [open, setOpen] = React.useState(isOpen);
  const handleClose = () => {
    setToggleStatusAlert(false);
  };

  const handleYes = () => {
    handleStatusToggle(clickedItem, !clickedItemStatus);
    console.log('CLicked item ', clickedItem);
    setToggleStatusAlert(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Do you want to change the status to ${
              clickedItemStatus ? 'Inactive' : 'Active'
            } ?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button
            onClick={() => handleYes()}
            color="primary"
            variant="outlined"
            autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ToggleStatusDialogue;
