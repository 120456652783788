import { isUndefined } from 'lodash';
import axios from 'src/utils/axios';
import parse from 'src/utils/parse';


class PimApi {

    async getAllProducts(args: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_PIM_URL}/product/getProductByCategoryId${args}`)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async getPimCategories(args: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_PIM_URL}/category?${args}`)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async createProduct(payload: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .post(`${process.env.REACT_APP_PIM_URL}/product`, payload)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async EditProduct(id: any, payload: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${process.env.REACT_APP_PIM_URL}/product/${id}`, payload)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async EditCategory(id: any, payload: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${process.env.REACT_APP_PIM_URL}/category/toggleStatus/${id}?isActive=${payload.isActive}`)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async getProductLogsById(productId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_PIM_URL}/product/logs/${productId}`)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async getAllCategories(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_PIM_URL}/autoComplete/category`)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async getAllSuppliers(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_PIM_URL}/autoComplete/supplier`)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async getAllMeasurement(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_PIM_URL}/autoComplete/productMeasurement`)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async getAllDistributors(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_PIM_URL}/autoComplete/distributor`)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async UploadCsvFile(formData: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .post(`${process.env.REACT_APP_PIM_URL}/product/uploadFile`, formData)
                .then((response) => {
                    response = parse(response);
                    console.log("RESPONSE FILE UPLAD", response)
                    if (!isUndefined(response?.data)) {
                        const data = response?.data;
                        resolve(data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}

export const pimApi = new PimApi();