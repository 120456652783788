import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import SimpleBreadCrumb from 'src/components/widgets/BreadCrumb/SimpleBreadCrumb';
import CreateCase from 'src/components/cases/CreateCase';
import { Outlet, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { projectApi } from 'src/api/projectApi';
import { projectDataByOrg } from 'src/store/slices/organizationDetailsSlice';
import useAuth from 'src/hooks/useAuth';
import logger from 'src/utils/logger';

const useStyles = makeStyles<Theme>(() => createStyles({
  tabsAreaProject: {
    margin: '16px 10px 0',
    '& .MuiTabs-flexContainer': {
      borderBottom: '1px solid #231F20',
    },
    '& .tabsArea': {
      margin: '16px 0 0',
      '& .MuiTab-root': {
        height: '32px',
        minHeight: 'inherit',
        margin: '0',
        minWidth: '105px',
      },
      '& hr': {
        border: 0,
        borderTop: '0.8px solid #E0E0E0',
        margin: '21px 0 0',
        borderWidth: '0.8px',
      },
      '& .MuiTabs-indicator ': {
        display: 'none'
      },
      "& .Mui-selected[aria-selected='true']": {
        background: '#FFF  !important',
        color: '#231F20 !important',
        border: '1px solid #231F20',
        borderBottom: '1px solid #fff',
        position: 'relative',
        top: 1
      }
    },
  },

}));
const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

const ProjectsSubTabs = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [drawer, setDrawer] = useState<boolean>(false);
  const [createUserDrawer, setCreateUserDrawer] = useState<boolean>(false);
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const getProjDetails = useSelector((state: any) => state.orgInfo?.projInfo);
  const { organizationID, projectID, caseID, userID } = useParams();
  const baseUrl = '/erp/crm/organization/details';
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    switch (newValue) {
      case 0:
        navigate(`${baseUrl}/${organizationID}/project/${projectID}/case`);
        break;
      case 1:
        navigate(`${baseUrl}/${organizationID}/project/${projectID}/user`);
        break;
      case 2:
        navigate(`${baseUrl}/${organizationID}/project/${projectID}/meetings`);
        break;
      case 3:
        navigate(`${baseUrl}/${organizationID}/project/${projectID}/notes`);
        break;
      case 4:
        navigate(`${baseUrl}/${organizationID}/project/${projectID}/sprints`);
        break;
      case 5:
        navigate(`${baseUrl}/${organizationID}/project/${projectID}/release-version`);
        break;
      default:
        break;
    }
  };

  const tabs = [
    {
      path: `${baseUrl}/${organizationID}/project/${projectID}/case`,
      label: 'Cases',
      caseEdit: { path: `${baseUrl}/${organizationID}/project/${projectID}/case/${caseID}`, label: 'CasesEdit' }
    },

    {
      path: `${baseUrl}/${organizationID}/project/${projectID}/user`,
      label: 'Users',
      usersEdit: {
        path: `${baseUrl}/${organizationID}/project/${projectID}/user/${userID}`, label: 'UsersEdit',
      }
    },

    { path: `${baseUrl}/${organizationID}/project/${projectID}/meetings`, label: 'Meetings' },
    { path: `${baseUrl}/${organizationID}/project/${projectID}/notes`, label: 'Notes' },
    { path: `${baseUrl}/${organizationID}/project/${projectID}/sprints`, label: 'Sprints' },
    { path: `${baseUrl}/${organizationID}/project/${projectID}/release-version`, label: 'Release Version' }
  ];

  const BreadCrumbsData = {
    title: '',
    links: [
      {
        name: 'Projects',
        to: `${baseUrl}/${organizationID}/project`
      },
      {
        name: `${getProjDetails?.name}`,
        to: '',
      }
    ]
  };

  const addCases = (open: boolean, event: any) => {
    if (
      event.type === 'keydown'
      && ((event as React.KeyboardEvent).key === 'Tab'
        || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawer(open);
  };

  const addUser = (open: boolean, event: any) => {
    if (
      event.type === 'keydown'
      && ((event as React.KeyboardEvent).key === 'Tab'
        || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setCreateUserDrawer(open);
  };

  const handleCloseDrawer = () => {
    setDrawer(!drawer);
  };

  const handleCloseCreateUserDrawer = () => {
    setCreateUserDrawer(!createUserDrawer);
  };
  const getProjDetailsById = async () => {
    try {
      const response = await projectApi.getProjectDetailsById(projectID);
      dispatch(projectDataByOrg(response?.data));
    } catch (error) {
      logger(error);
    }
  };

  useEffect(() => {
    const selectedIndex = tabs.findIndex((tab: any) => {
      if (tab?.caseEdit?.path === location?.pathname) {
        return 1;
      }
      if (tab?.usersEdit?.path === location?.pathname) {
        return 2;
      }
      return location.pathname === tab.path;
    });
    setSelectedTab(selectedIndex);
    getProjDetailsById();
  }, [location.pathname]);

  return (
    <div className={classes.tabsAreaProject}>
      <SimpleBreadCrumb data={BreadCrumbsData} />
      <div className="tabsArea">
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="projectTabs"

        >
          {user?.permissions?.includes('organization-case-tab-read') && (
            <Tab label="Cases" />
          )}

          <Tab
            sx={{ display: 'none' }}
            label="Users"
          />

          {user?.permissions?.includes('organization-meeting-tab-read') && (
            <Tab label="Meetings" />
          )}

          {user?.permissions?.includes('organization-note-tab-read') && (
            <Tab label="Notes" />
          )}

          <Tab
            label="Sprints"
          />

          <Tab
            label="Release Version"
          />
        </Tabs>
        <TabPanel
          value={selectedTab}
          index={0}
        >
          <Outlet context={{
            addCases,
            hideItems: {
              heading: false,
              customClass: 'orgEditCase',
              link: `${baseUrl}/${organizationID}/project/${projectID}/case`
            }
          }}
          />
          {user?.permissions?.includes('case-create') && (
            <CreateCase
              isOpen={drawer}
              addCases={addCases}
              closeDrawer={handleCloseDrawer}
              organizationID={organizationID}
              projectID={projectID}
              nested
            />
          )}
        </TabPanel>
        <TabPanel
          value={selectedTab}
          index={1}
        >
          <Outlet context={{ addUser, handleCloseCreateUserDrawer, createUserDrawer }} />
        </TabPanel>

        {user?.permissions?.includes('organization-meeting-tab-read') && (
          <TabPanel
            value={selectedTab}
            index={2}
          >
            <Outlet />
          </TabPanel>
        )}

        {user?.permissions?.includes('organization-note-tab-read') && (
          <TabPanel
            value={selectedTab}
            index={3}
          >
            <Outlet />
          </TabPanel>
        )}

        <TabPanel
          value={selectedTab}
          index={4}
        >
          <Outlet />
        </TabPanel>

        <TabPanel
          value={selectedTab}
          index={5}
        >
          <Outlet />
        </TabPanel>
      </div>
    </div>
  );
};

export default ProjectsSubTabs;
