import {
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import { TableProps } from 'src/types/components/tableList.types';
// import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
// import DragHandleIcon from '@material-ui/icons/DragHandle';
// import ExpandLessIcon from '@material-ui/icons/ExpandLess';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
// import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import moment from 'moment';
import Scrollbar from '../../Scrollbar';
import { casesApi } from 'src/api/casesApi';
import { sprintsApi } from 'src/api/sprintsApi';
import toast from 'react-hot-toast';
// import logger from 'src/utils/logger';
// import AttachFileIcon from '@material-ui/icons/AttachFile';
import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';



const useStyles = makeStyles<Theme>(() => createStyles({
    statusActive: {
        color: 'green',
        fontWeight: 'bold',
    },
    statusInactive: {
        color: 'red',
        fontWeight: 'bold',
    },
    btn: {
        width: '47px',
        height: '40px',
        padding: 0,
        minWidth: 'initial',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        color: '#fff',
        marginLeft: '13px',
        '& .MuiButton-iconSizeMedium': {
            margin: '0',
        },
    },
    clip: {
        display: 'inline-block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '10ch'
    },
    textFieldSelect: {
        width: '230px',
        marginLeft: '13px'
    },
    textFieldSelectRoot: {
        borderRadius: '4px',
        height: '38px',
    },
    tabButton: {
        width: '181px',
    },
    cardRoot: {
        borderRadius: '4px',
    },
    tabs: {
        '& .MuiTab-root': {
            backgroundColor: 'transparent',
            minWidth: '120px',
        },
        '& .Mui-selected': {
            backgroundColor: '#231F20',
            color: '#fff',
        },
    },
    tableHead: {
        '& .MuiTableCell-root': {
            textTransform: 'capitalize',
        },
    },
    tableRow: {
        '& :hover': {
            cursor: 'pointer',
        },
        '& .ticketStatusTrue': {
            backgroundColor: 'rgba(7, 83, 0, 0.16)',
            color: '#1D7A15',
            padding: '5px',
            fontWeight: 'Bold',
        },
        '& .ticketStatusFalse': {
            background: '#DEEBFF',
            color: '#3375D7',
            padding: '5px',
            fontWeight: 'Bold',
        },
    },
    priorityComponent: {
        height: '30px',
        background: 'rgba(189,189,189,0.1)',
        color: '#000000aa',
        width: '100px',
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        textTransform: 'capitalize',
        justifyContent: 'center'
    },
    loaderTable: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '2222'
    },
}));


const TableHeadComponent: FC<any> = ({ data, setSelectedSort, selectedSort }: any) => (
    < TableHead sx={{ marginBottom: '1rem' }}>
        <TableRow>
            {data?.map((col: any) => (
                <TableCell
                    align="center"
                    className="textBold"
                    sortDirection={selectedSort?.key === col?.id ? selectedSort?.value : false}
                    title={col?.toolTip ? col?.summary : null}
                >
                    {
                        col.isSort
                            ? (
                                <>
                                    <TableSortLabel
                                        active={selectedSort?.key === col?.id}
                                        direction={selectedSort?.key === col?.id ? selectedSort?.value : 'asc'}
                                        // active={col.isSort}
                                        // direction={col.direction}
                                        onClick={() => {
                                            col.toggleSortData(`${col.id}`);
                                            // getAllSortedData(`${col.id}`, col.direction === 'asc' ? '1' : '-1');
                                            // logger(getAllSortedData);
                                            setSelectedSort({ key: `${col?.id}`, value: col.direction === 'asc' ? 'asc' : 'desc' });
                                            // setSort('', '');
                                        }}
                                    // hideSortIcon={col.isSort || true}
                                    >
                                        {col?.label}
                                    </TableSortLabel>
                                </>
                            )
                            : col?.label
                    }
                </TableCell>
            ))}
        </TableRow>
    </TableHead >
);

// const PriorityComponent: FC<any> = ({ label }: any) => {
//   const classes = useStyles();
//   const styling = {
//     icon: <RadioButtonUncheckedIcon />
//   };

//   switch (label?.toLowerCase()) {
//     case 'highest':
//       styling.icon = <DoubleArrowIcon sx={{ fontSize: '16px', color: '#f55033', transform: 'rotate(270deg)' }} />;
//       break;
//     case 'medium':
//       styling.icon = <DragHandleIcon sx={{ fontSize: '24px', width: '18px', color: '#f79d28' }} />;
//       break;
//     case 'minor':
//       styling.icon = <RadioButtonUncheckedIcon sx={{ fontSize: '14px' }} />;
//       break;
//     case 'blocker':
//       styling.icon = <RemoveCircleIcon sx={{ fontSize: '16px', color: '#f55033' }} />;
//       break;
//     case 'high':
//       styling.icon = <ExpandLessIcon sx={{ fontSize: '24px', color: '#f55033' }} />;
//       break;
//     case 'low':
//       styling.icon = <ExpandMoreIcon sx={{ fontSize: '24px', color: '#3384f5' }} />;
//       break;
//     case 'lowest':
//       styling.icon = <DoubleArrowIcon sx={{ fontSize: '16px', color: '#3384f5', transform: 'rotate(90deg)' }} />;
//       break;
//     default:
//       break;
//   }
//   return (
//     <>
//       <Box
//         className={classes.priorityComponent}
//       >
//         {styling.icon}
//         {label?.toLowerCase()}
//       </Box>
//     </>
//   );
// };
// editRouteUrl permissions

const TableBodyComponent: FC<any> = ({ data, internalUsers, setSelectedRow, refreshTable }: any) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [rowId, setRowId] = useState('')
    const open = Boolean(anchorEl);
    const handleClick = (id: string, e: React.MouseEvent<HTMLElement>): void => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
        setRowId(id)
    };

    const handleClose = (e: React.MouseEvent<HTMLElement>): void => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    const changeStatus = async (status, e: React.MouseEvent<HTMLElement>): Promise<void> => {
        e.stopPropagation();
        try {
            handleClose(new MouseEvent('click') as unknown as React.MouseEvent<HTMLButtonElement, MouseEvent>);
            const response = await sprintsApi.updateSprintStatus(rowId, status);

            if (response?.status === 200) {
                toast.success('Updated Successfully');
                refreshTable()
                setRowId('')
            }
        } catch (error) {
            toast.error(error?.response?.data?.message);
            // toast.error('something went wrong');
            //   setAllData([]);
            setRowId('')
        }
    };
    const getStatusColor = (status) => {
        switch (status) {
            case 'CLOSED':
                return 'green';
            case 'IN_PROGRESS':
                return '#d9502c';
            default:
                return 'red';
        }
    };

    const getBackgroundColor = (status) => {
        switch (status) {
            case 'CLOSED':
                return '#9bce9ba8';
            case 'IN_PROGRESS':
                return 'rgb(242 147 57 / 50%)';
            default:
                return 'rgb(255 182 182 / 66%)';
        }
    };

    // const navigate = useNavigate();
    // const handleRedirectNext = (id: string) => {
    //   navigate(`${editRouteUrl}/${id}`);
    // };
    // logger(handleRedirectNext);
    return (
        <>
            <TableBody style={{ position: 'relative' }}>
                {data?.length !== 0 ? (data?.map((row: any) => (
                    <TableRow
                        onClick={() => setSelectedRow(row)}
                        // component={Link}
                        // to={(permissions?.update || permissions?.getById) ? `${editRouteUrl}/${row?.caseKey}` : ''}
                        hover
                        // onClick={() => { if ((permissions?.update || permissions?.getById)) { handleRedirectNext(row?.caseKey); } }}
                        className={classes.tableRow}
                        key={row?.id}
                    // style={{ border: newTicketIds.includes(row.id) ? '2px solid #87c387' : 'transparent', textDecoration: 'none' }}
                    >
                        {/* <TableCell
                  align="left"
                  size="small"
                  sx={{ padding: '8px 0' }}
                >
                  {row?.attachmentId?.length ?
                    <AttachFileIcon sx={{ fontSize: '18px' }} /> : null
                  }
                </TableCell> */}
                        {/* <TableCell
                  align="center"
                >
                  <span
                    className={classes.clip}
                  >
                    {row?.caseKey}
                  </span>
                </TableCell> */}
                        <TableCell
                            align="center"
                            // title={row?.key === null ? 'N/A' : row?.key}
                            sx={{ width: '200px', maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            <span>
                                {row?.name === null ? 'N/A' : row?.name}
                            </span>
                        </TableCell>
                        <TableCell
                            align="center"
                            // title={row?.name === null ? 'N/A' : row?.name}
                            sx={{ width: '200px', maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            <span>
                                {row?.startDate === null ? 'N/A' : moment(row?.startDate).format('MMM DD,YYYY')}
                            </span>
                        </TableCell>
                        <TableCell
                            align="center"
                            // title={row?.isBillable === null ? 'N/A' : row?.isBillable}
                            sx={{ width: '200px', maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            <span style={{
                                fontWeight: 700
                            }}>
                                {row?.endDate === null ? 'N/A' : moment(row?.endDate).format('MMM DD,YYYY')}
                            </span>
                        </TableCell>
                        <TableCell
                            align="center"
                            // title={row?.isBillable === null ? 'N/A' : row?.isBillable}
                            sx={{ width: '200px', maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            <span>
                                {row?.duration === null ? 'N/A' : row?.duration}
                            </span>
                        </TableCell>
                        <TableCell
                            align="center"
                            // title={row?.isBillable === null ? 'N/A' : row?.isBillable}
                            sx={{ width: '200px', maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            <span style={{
                                fontWeight: 700
                            }}>
                                {row?.project.name === null ? 'N/A' : row?.project.name}
                            </span>
                        </TableCell>
                        <TableCell
                            align="center"
                            // title={row?.isBillable === null ? 'N/A' : row?.isBillable}
                            sx={{ width: '200px', maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            <span
                                style={{
                                    color: getStatusColor(row.status),
                                    fontWeight: 'bold',
                                    textTransform: 'capitalize',
                                    background: getBackgroundColor(row.status),
                                    padding: '6px',
                                    borderRadius: '5px',

                                }}
                            >
                                {/* {row.status || 'N/A'} */}
                                {(row.status || 'N/A').replace(/_/g, ' ')}
                            </span>
                        </TableCell>
                        <TableCell
                            align="center"
                            // title={row?.isBillable === null ? 'N/A' : row?.isBillable}
                            sx={{ width: '200px', maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            <span>
                                {row.goal || 'N/A'}
                            </span>
                        </TableCell>
                        <TableCell
                            align="center"
                            // title={row?.isBillable === null ? 'N/A' : row?.isBillable}
                            sx={{ width: '200px', maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            <span>
                                <Button
                                    id="demo-positioned-button"
                                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={(e) => handleClick(row.id, e)}
                                >
                                    <MoreVertIcon />
                                </Button>
                                <Menu
                                    id="demo-positioned-menu"
                                    aria-labelledby="demo-positioned-button"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    <MenuItem onClick={(e) => changeStatus('NOT_STARTED', e)}>NOT STARTED</MenuItem>
                                    <MenuItem onClick={(e) => changeStatus('IN_PROGRESS', e)}>IN PROGRESS</MenuItem>
                                    <MenuItem onClick={(e) => changeStatus('CLOSED', e)}>CLOSED</MenuItem>
                                </Menu>
                            </span>
                        </TableCell>
                    </TableRow>
                ))) : (
                    <TableRow selected>
                        <TableCell
                            colSpan={12}
                            sx={{ textAlign: 'center' }}
                        >
                            No Data Found
                        </TableCell>
                    </TableRow>
                )}
            </TableBody >
        </>
    );
};

interface CaseListViewTypes extends TableProps {
    sortData?: any;
    toggleSortData?: any;
    setSelectedSort?: any;
    selectedSort?: any;
    isOrganization?: boolean;
    isProject?: boolean;
    setSelectedRow: any;
    refreshTable: any


}
const SprintsListView: FC<CaseListViewTypes> = ({
    data,
    editRouteUrl,
    count,
    page,
    limit,
    handlePage,
    bulkIds,
    handleLimit,
    handleAddBulkIds,
    handleRemoveBulkIds,
    // sortData,
    // toggleSortData,
    selectedSort,
    setSelectedSort,
    permissions,
    isOrganization = true,
    isProject = true,
    newTicketIds,
    setSelectedRow,
    refreshTable
}: any) => {
    const classes = useStyles();
    const [allUsersInternal, setAllUsersInternal] = useState<any>([]);

    let Columns = [

        {
            id: 'name',
            label: 'Name',
            // isSort: false,
        },
        {
            id: 'Start Date',
            label: 'Start Date',
            // isSort: false,
        },
        {
            id: 'End Date ',
            label: 'End Date',
            // isSort: false,
        },
        {
            id: 'Duration',
            label: 'Duration',
            // isSort: false,
        },
        {
            id: 'Project',
            label: 'Project',
            // isSort: false,
        },
        {
            id: 'Status',
            label: 'Status',
            // isSort: false,
        },
        {
            id: 'Goal',
            label: 'Goal',
            // isSort: false,
        },
        {
            id: 'Status Action',
            label: 'Status Action',
            // isSort: false,
        },

    ];
    if (!isOrganization) {
        Columns = Columns.filter((col) => col.id !== 'organization');
    }
    if (!isProject) {
        Columns = Columns.filter((col) => col.id !== 'project');
    }
    const handleChangePage = useCallback(
        (event: any, _page: number) => {
            handlePage(_page);
        },
        [page, data]
    );
    const handleChangeLimit = useCallback(
        (event: any) => {
            handleLimit(parseInt(event.target.value, 10));
            handlePage(0);
        },
        [limit, data]
    );



    const getAllUsersInternal = async () => {
        try {
            const response = await casesApi.getUserById(false);
            if (response?.status === 200) {
                setAllUsersInternal(response?.data);
            }
        } catch (error) {
            toast.error(error.message)
            setAllUsersInternal([]);
        }
    };

    useEffect(() => {
        getAllUsersInternal();
    }, [])
    return (
        <Box
            sx={{
                backgroundColor: 'background.default',
                py: 1,
            }}
        >
            {' '}
            <Card className={classes.cardRoot}>
                <Scrollbar>
                    <Box sx={{ minWidth: 700, px: 1, }}>
                        <Table>
                            <TableHeadComponent
                                data={Columns}
                                selectedSort={selectedSort}
                                setSelectedSort={setSelectedSort}
                            />
                            <TableBodyComponent
                                internalUsers={allUsersInternal}

                                data={data}
                                editRouteUrl={editRouteUrl}

                                handleAddBulkIds={handleAddBulkIds}
                                bulkIds={bulkIds}
                                handleRemoveBulkIds={handleRemoveBulkIds}
                                permissions={permissions}
                                isProject={isProject}
                                isOrganization={isOrganization}
                                newTicketIds={newTicketIds}
                                setSelectedRow={setSelectedRow}
                                refreshTable={refreshTable}
                            />
                        </Table>
                    </Box>
                </Scrollbar>
                <TablePagination
                    component="div"
                    count={count}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeLimit}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[5, 10, 25, 100]}
                />
            </Card>
        </Box>
    );
};

export default SprintsListView;
