import { FC, useState, useEffect } from 'react';
import Modal from 'react-modal';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { Button } from '@mui/material';

Modal.setAppElement('#root'); // Set the root element for accessibility

interface AttachmentViewModalProps {
  isOpen: boolean;
  onClose: () => void;
  imageUrl: string; // Adjust the type accordingly
}

const AttachmentViewModal: FC<AttachmentViewModalProps> = ({
  isOpen,
  onClose,
  imageUrl,
}) => {
  const [zoom, setZoom] = useState(1); // State to track the zoom level
  const [origin, setOrigin] = useState({ x: 50, y: 50 }); // Track transform origin

  const modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      width: '60%',
    },
  };

  const closeButtonStyle: any = {
    position: 'absolute',
    top: '-16px',
    right: '-16px',
    cursor: 'pointer',
    color: '#C22027',
  };

  // Set zoom level and adjust transform-origin based on mouse position
  const zoomImage = (deltaZoom: number, clientX: number, clientY: number) => {
    const imageElement = document.getElementById('zoomable-image');
    if (!imageElement) return;

    const rect = imageElement.getBoundingClientRect();

    // Calculate the new transform origin based on mouse position relative to the image
    const originX = ((clientX - rect.left) / rect.width) * 100;
    const originY = ((clientY - rect.top) / rect.height) * 100;

    setOrigin({ x: originX, y: originY });
    setZoom((prevZoom) => Math.min(Math.max(prevZoom + deltaZoom, 0.5), 3)); // Constrain zoom between 0.5x and 3x
  };

  const handleZoomIn = (event: React.MouseEvent<HTMLButtonElement>) => {
    zoomImage(0.2, window.innerWidth / 2, window.innerHeight / 2); // Zoom in centered
  };

  const handleZoomOut = (event: React.MouseEvent<HTMLButtonElement>) => {
    zoomImage(-0.2, window.innerWidth / 2, window.innerHeight / 2); // Zoom out centered
  };

  const handleWheelZoom = (event: WheelEvent) => {
    if (isOpen) {
      event.preventDefault();
    } // Prevent default scroll behavior

    // Zoom based on mouse position
    const deltaZoom = event.deltaY < 0 ? 0.2 : -0.2;
    zoomImage(deltaZoom, event.clientX, event.clientY);
  };

  // Add event listener for mouse wheel zoom
  useEffect(() => {
    window.addEventListener('wheel', handleWheelZoom, { passive: false });

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('wheel', handleWheelZoom);
    };
  }, []);

  const handleClose = () => {
    setOrigin({ x: 50, y: 50 }); // Reset the origin state to default (center) when closing the modal
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose} style={modalStyle}>
      <div
        id="modal-content"
        style={{ position: 'relative', maxHeight: '75vh', padding: '5px' }}>
        <CancelIcon onClick={handleClose} style={closeButtonStyle} />
        {/* Image container */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '400px',
            overflow: 'hidden', // Ensure the image doesn't overflow the container
          }}>
          <img
            id="zoomable-image"
            src={imageUrl}
            alt="Preview"
            style={{
              width: '100%',
              height: '100%',
              transform: `scale(${zoom})`,
              transformOrigin: `${origin.x}% ${origin.y}%`, // Apply the transform origin only during zoom actions
              transition: 'transform 0.2s ease', // Smooth zoom transitions
              marginBottom: '10px',
              objectFit: 'contain',
            }}
          />
        </div>
        {/* Zoom control buttons */}
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          <Button
            size="small"
            type="button"
            onClick={handleZoomIn}
            style={{ marginRight: '10px', minWidth: '30px' }}>
            <AddIcon />
          </Button>
          <Button
            size="small"
            type="button"
            onClick={handleZoomOut}
            sx={{ minWidth: '30px' }}>
            <RemoveIcon />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AttachmentViewModal;
