import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, TextField, Typography } from '@material-ui/core';
import type { FC } from 'react';
import { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { notesApi } from 'src/api/notesApi';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

type AddNotesProps = {
  setAddNotes: any;
};

const AddNotes: FC<any> = ({ setAddNotes }: AddNotesProps) => {
  const [noteTitle, setNoteTitle] = useState<string>('');
  const [noteDescription, setNoteDescription] = useState<string>('');
  const { organizationID, projectID } = useParams();
  const handleCreateNotes = async () => {
    const payload = {
      title: noteTitle,
      text: noteDescription,
      organizationId: organizationID,
      projectId: projectID
    };

    try {
      const response = await notesApi.createNote(payload);
      if (response?.status === 201) {
        setAddNotes(false);
        toast.success('Note Created');
      }
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  return (
    <div className="notesInner">
      <Accordion
        defaultExpanded
        className="addMeetingCard"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className="accordionSummary"
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontWeight: 'bold' }}>Add Note</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={12}
            >
              <Typography>Title</Typography>
              <TextField
                placeholder="Enter Title"
                fullWidth
                inputProps={{ maxLength: 225 }}
                value={noteTitle}
                onChange={(e) => setNoteTitle(e.target.value)}
              />
            </Grid>
            <Grid
              item
              md={12}
            >
              <div className="ckEditor">
                <Typography>
                  Description
                </Typography>
                <CKEditor
                  editor={ClassicEditor}
                  data={noteDescription}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setNoteDescription(data);
                  }}
                />
              </div>
            </Grid>
          </Grid>

          <Button
            className="createButton"
            variant="contained"
            color="secondary"
            onClick={handleCreateNotes}
          >
            Create
          </Button>

        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AddNotes;
