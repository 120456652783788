import { Box, Container, Grid } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { employeesApi } from 'src/api/employeesApi';
import SimpleBreadCrumb from 'src/components/widgets/BreadCrumb/SimpleBreadCrumb';
import Form from 'src/components/widgets/forms/Form';
import logger from 'src/utils/logger';
import setTitle from 'src/utils/setTitle';
import * as Yup from 'yup';
import useSettings from '../../../hooks/useSettings';
import { rolesApi } from 'src/api/rolesApi';
import useAuth from 'src/hooks/useAuth';
import { REGEX } from 'src/utils/constants';
import { RoleTypes } from 'src/types/globalTypes';
import { leavesApi } from 'src/api/leavesApi';

const EditEmployees: FC = () => {
  setTitle('CRM | Employees - Edit');
  const { user } = useAuth();
  const { settings } = useSettings();
  const returnUrl = '/erp/crm/employees';
  const { id } = useParams();
  const navigate = useNavigate();
  type Role = {
    id?: number;
  };
  type EmployeeData = {
    firstName: string;
    lastName: string;
    designation: string;
    roles?: RoleTypes[];
    email: string;
    contactNumber: string;
    avatar: string;
    info: string;
    oldPassword?: string;
    newPassword?: string;
    confirmNewPassword?: string;
    roleId?: Role[];
    allocatedLeaves?: {
      paid: number;
      medical: number;
      unpaid: number;
      holidays: number;
    };
    paid: number;
    medical: number;
    unpaid: number;
    holidays: number;
  };

  const [roles, setRoles] = useState<RoleTypes[]>([]);
  const [employeeData, setEmployeeData] = useState<EmployeeData>(null);
  const InitialValues: EmployeeData = {
    firstName: employeeData?.firstName || '',
    lastName: employeeData?.lastName || '',
    designation: employeeData?.designation || '',
    roles: employeeData?.roles || [],
    email: employeeData?.email || '',
    contactNumber: employeeData?.contactNumber
      ? employeeData?.contactNumber.toString()
      : '',
    avatar: employeeData?.avatar || '',
    info: employeeData?.info || '',
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    paid: employeeData?.allocatedLeaves?.paid,
    medical: employeeData?.allocatedLeaves?.medical,
    unpaid: employeeData?.allocatedLeaves?.unpaid,
    holidays: employeeData?.allocatedLeaves?.holidays,
  };

  const previewData = [
    {
      name: 'employee details',
      fields: [
        {
          id: '1',
          name: 'firstName',
          label: 'First Name',
          placeholder: 'First Name',
          type: 'text',
          required: true,
          fullWidth: true,
        },
        {
          id: '2',
          name: 'lastName',
          label: 'Last Name',
          placeholder: 'Last Name',
          type: 'text',
          required: true,
          fullWidth: true,
        },
        {
          id: '4',
          name: 'designation',
          label: 'Designation',
          placeholder: 'Designation',
          type: 'text',
          required: true,
          fullWidth: true,
        },
        {
          id: '5',
          name: 'roles',
          label: 'Role',
          placeholder: 'Role',
          type: 'muiAutoComplete',
          required: true,
          columnSize: 6,
          array: roles?.filter(
            (item) => !item.identifier.includes('client_contact')
          ),
        },
        {
          id: '6',
          name: 'email',
          label: 'Email',
          placeholder: 'Email',
          type: 'email',
          required: true,
          fullWidth: true,
        },
        {
          id: '7',
          name: 'contactNumber',
          label: 'Phone Number',
          placeholder: 'Phone Number',
          type: 'text',
          required: true,
          fullWidth: true,
        },
        {
          id: '81',
          name: 'avatar',
          label: 'Image',
          placeholder: 'Image',
          type: 'file',
          required: true,
          fullWidth: true,
        },
      ],
    },
    {
      name: 'Security',
      fields: [
        {
          id: '8',
          name: 'oldPassword',
          label: 'Old Password',
          placeholder: 'Old Password',
          type: 'password',
          required: true,
          fullWidth: true,
        },
        {
          id: '9',
          name: 'newPassword',
          label: 'New Password',
          placeholder: 'New Password',
          type: 'password',
          required: true,
          fullWidth: true,
        },
        {
          id: '10',
          name: 'confirmNewPassword',
          label: 'Confirm New Password',
          placeholder: 'Confirm New Password',
          type: 'password',
          required: true,
          fullWidth: true,
        },
      ]
    },
    {
      name: 'Description',
      fields: [
        {
          id: '11',
          name: 'info',
          label: 'Description',
          placeholder: 'Description',
          type: 'textarea',
          fullWidth: true,
        },
      ],
    },
    {
      name: 'Leaves Quota',
      fields: [
        {
          id: '12',
          name: 'paid',
          label: 'Paid Leaves (days)',
          placeholder: 'Enter Paid Leaves in Days',
          type: 'number',
          required: true,
          fullWidth: true,
          InputLabelProps: { shrink: true }, // Ensure the label shrinks above the field
        },
        {
          id: '13',
          name: 'medical',
          label: 'Medical Leaves (days)',
          placeholder: 'Enter Medical Leaves in Days',
          type: 'number',
          required: true,
          fullWidth: true,
          InputLabelProps: { shrink: true }, // Ensure the label shrinks above the field
        },
        {
          id: '14',
          name: 'unpaid',
          label: 'Unpaid Leaves (days)',
          placeholder: 'Enter Unpaid Leaves in Days',
          type: 'number',
          required: true,
          fullWidth: true,
          InputLabelProps: { shrink: true }, // Ensure the label shrinks above the field
        },
        {
          id: '15',
          name: 'holidays',
          label: 'Holidays (days)',
          placeholder: 'Enter Holidays in Days',
          type: 'number',
          required: true,
          fullWidth: true,
          InputLabelProps: { shrink: true }, // Ensure the label shrinks above the field
        },
      ],
    },
  ];

  const Schema = Yup.object({
    firstName: Yup.string()
      .required('First name required')
      .min(3, 'Minimum 3 characters allowed')
      .max(50, 'Maximum 50 characters allowed')
      .test(
        'Field must not contain only spaces',
        (value) => value && value.length > 0
      ),
    lastName: Yup.string()
      .required('Last name required')
      .min(3, 'Minimum 3 characters allowed')
      .max(50, 'Maximum 50 characters allowed')
      .test(
        'Field must not contain only spaces',
        (value) => value && value.length > 0
      ),
    email: Yup.string().email().required('Email required'),
    contactNumber: Yup.string()
      .required('Phone number required')
      .matches(
        /^.{10,20}$/,
        'Phone number must be between 10 and 20 characters in length'
      ),
    designation: Yup.string()
      .required('Designation required')
      .min(3, 'Minimum 3 characters required')
      .max(100, 'Maximum 100 characters allowed')
      .test(
        'Field must not contain only spaces',
        (value) => value && value.length > 0
      ),
    roles: Yup.array()
      .nullable()
      .test(
        'is-nonempty',
        'Role required',
        (value) => value && value?.length > 0
      ),
    info: Yup.string().max(2000, 'Maximum 2000 characters allowed'),
    oldPassword: Yup.string().max(255, 'Maximum 255 characters allowed').trim(),
    newPassword: Yup.string()
      .trim()
      .min(8, 'Minium 8 characters required')
      .max(20, 'Maximum 20 characters allowed')
      .matches(
        REGEX.password,
        'At least one uppercase, one lowercase, one number & one special character allowed'
      ),
    confirmNewPassword: Yup.string()
      .trim()
      .test(
        'Password match',
        'Password does not match',
        (value, context) => value === context.parent.newPassword
      ),
    paid: Yup.number().required('Paid leaves required').min(0),
    medical: Yup.number().required('Medical leaves required').min(0),
    unpaid: Yup.number().required('Unpaid leaves required').min(0),
    holidays: Yup.number().required('Holidays required').min(0),
  });

  const handleOnSubmit = async (values: EmployeeData) => {
    try {
      const payload = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        designation: values?.designation.trim(),
        roleId: values?.roles?.map((role: RoleTypes) => role?.id),
        email: values?.email,
        contactNumber: values?.contactNumber,
        avatar: values?.avatar,
        info: values?.info,
        oldPassword: values?.oldPassword.trim(),
        password: values?.newPassword.trim(),
      };

      // Send allocatedLeaves data to another API
      const leavesPayload = {
        paid: values?.paid * 8, // Convert days to hours
        medical: values?.medical * 8, // Convert days to hours
        unpaid: values?.unpaid * 8, // Convert days to hours
        holidays: values?.holidays * 8, // Convert days to hours
      };

      if (
        values?.oldPassword.trim() === '' ||
        values?.newPassword.trim() === ''
      ) {
        delete payload.oldPassword;
        delete payload.password;
      }

      const leavesResponse = await leavesApi.updateEmployeeLeaveQuota(
        `${id}`,
        leavesPayload
      );
      const response = await employeesApi.updateById(`${id}`, payload);
      if (
        (leavesResponse?.status === 200 || leavesResponse?.status === 201) &&
        (response?.status === 200 || response?.status === 201)
      ) {
        toast.success('Updated Successfully');
        navigate(returnUrl);
      } else {
        throw new Error('Failed to update some data');
      }
    } catch (error) {
      if (error?.response?.status === 500) {
        toast.error('Internal Server Error');
      } else {
        toast.error(`${error?.response?.data?.message}`);
      }
    }
  };

  const BreadCrumbsData = {
    title: 'Edit Employee',
    links: [
      {
        name: 'Employees',
        to: returnUrl,
      },
      {
        name: 'Edit Employee',
        to: `${returnUrl}/edit/${id}`,
      },
    ],
  };

  const getEmployeeData = async () => {
    try {
      const response = await employeesApi.getById(`${id}`);
      if (response?.status === 200) {
        const fetchedData = response?.data;

        setEmployeeData({
          ...fetchedData,
          allocatedLeaves: {
            paid: fetchedData?.leaves?.allocatedLeaves?.paid / 8 || 0, // Convert hours to days
            medical: fetchedData?.leaves?.allocatedLeaves?.medical / 8 || 0, // Convert hours to days
            unpaid: fetchedData?.leaves?.allocatedLeaves?.unpaid / 8 || 0, // Convert hours to days
            holidays: fetchedData?.leaves?.allocatedLeaves?.holidays / 8 || 0, // Convert hours to days
          },
        });
      } else {
        setEmployeeData(null);
      }
    } catch (error) {
      logger(error, 'error');
      setEmployeeData(null);
    }
  };

  const getAllRoles = async () => {
    try {
      const response = await rolesApi.getAllActiveRoles();
      if (response?.status === 200) {
        setRoles(response?.data);
      } else {
        setRoles([]);
      }
    } catch (error) {
      setRoles([]);
    }
  };

  useEffect(() => {
    if (
      user?.permissions?.includes('employee-get-by-id') ||
      user?.permissions?.includes('employee-update')
    ) {
      getEmployeeData();
      getAllRoles();
    } else {
      window.location.href = '/admin/not-found';
    }
  }, []);
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8,
        }}>
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <SimpleBreadCrumb data={BreadCrumbsData} />
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Form
              previewer={previewData}
              schema={Schema}
              handleOnSubmit={handleOnSubmit}
              initialValues={InitialValues}
              returnUrl={returnUrl}
              showButtons={user?.permissions?.includes('employee-update')}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default EditEmployees;
