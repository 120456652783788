import {
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Checkbox,
    Button
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import moment from 'moment';
// import type { FC } from 'react';
// import React, { FC } from 'react';
// import { useCallback, useState, useEffect, useRef } from 'react';
import React, { FC, useCallback, useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { TableProps } from 'src/types/components/tableList.types';
import Scrollbar from '../../Scrollbar';
// import logger from 'src/utils/logger';
import AttachFileIcon from '@material-ui/icons/AttachFile';



const useStyles = makeStyles<Theme>(() => createStyles({
    btn: {
        width: '47px',
        height: '40px',
        padding: 0,
        minWidth: 'initial',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        color: '#fff',
        marginLeft: '13px',
        '& .MuiButton-iconSizeMedium': {
            margin: '0',
        },
    },
    clip: {
        display: 'inline-block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '10ch'
    },
    textFieldSelect: {
        width: '230px',
        marginLeft: '13px',
    },
    textFieldSelectRoot: {
        borderRadius: '4px',
        height: '38px',
    },
    tabButton: {
        width: '181px',
    },
    cardRoot: {
        borderRadius: '4px',
        '& td': {
            cursor: 'pointer'
        }
    },
    tabs: {
        '& .MuiTab-root': {
            backgroundColor: 'transparent',
            minWidth: '120px',
        },
        '& .Mui-selected': {
            backgroundColor: '#231F20',
            color: '#fff',
        },
    },
    tableHead: {
        '& .MuiTableCell-root': {
            textTransform: 'capitalize',
        },
    },
    tableRow: {
        '& :hover': {
            cursor: 'pointer',
        },
        '& .ticketStatusTrue': {
            backgroundColor: 'rgba(7, 83, 0, 0.16)',
            color: '#1D7A15',
            padding: '5px',
            fontWeight: 'Bold',
        },
        '& .ticketStatusFalse': {
            background: '#DEEBFF',
            color: '#3375D7',
            padding: '5px',
            fontWeight: 'Bold',
        },
    },
    priorityComponent: {
        height: '30px',
        background: 'rgba(189,189,189,0.1)',
        color: '#000000aa',
        width: '100px',
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        textTransform: 'capitalize',
        justifyContent: 'center'
    },
    buttonNext: {
        backgroundColor: '#c22027',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#d43f40',
        },
        alignSelf: 'flex-end',

    },
    loaderTable: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '2222'
    },
    fixedRow: {
        position: 'fixed',
        bottom: 0,
        width: '84%',
        // width: 'calc(100% - 45px)',
        backgroundColor: 'white',
        zIndex: 1,
        padding: '0px',
        // Optional: Add box-shadow for better visibility
        // boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.1)',
    }
}));

const TableHeadComponent: FC<any> = ({ data,
    selectedSort,
    setSelectedSort,
    handleSelectAllClick,
    numSelected,
    rowCount }: any) => (
    <TableHead sx={{ marginBottom: '1rem' }}>
        <TableRow>
            <TableCell padding="checkbox">
                <Checkbox
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={handleSelectAllClick}
                />
            </TableCell>
            {data?.map((col: any) => (
                <TableCell
                    align="center"
                    className="textBold"
                    sortDirection={selectedSort?.key === col?._id ? selectedSort?.value : false}
                    title={col?.toolTip ? col?.summary : null}
                >
                    {
                        col.isSort
                            ? (
                                <>
                                    <TableSortLabel
                                        active={selectedSort?.key === col?._id}
                                        direction={selectedSort?.key === col?._id ? selectedSort?.value : 'asc'}
                                        // active={col.isSort}
                                        // direction={col.direction}
                                        onClick={() => {
                                            col.toggleSortData(`${col._id}`);
                                            // getAllSortedData(`${col._id}`, col.direction === 'asc' ? '1' : '-1');
                                            // logger(getAllSortedData);
                                            setSelectedSort({ key: `${col?._id}`, value: col.direction === 'asc' ? 'asc' : 'desc' });
                                            // setSort('', '');
                                        }}
                                    // hideSortIcon={col.isSort || true}
                                    >
                                        {col?.label}
                                    </TableSortLabel>
                                </>
                            )
                            : col?.label
                    }
                </TableCell>
            ))}
        </TableRow>
    </TableHead>
);

const PriorityComponent: FC<any> = ({ label }: any) => {
    const classes = useStyles();
    const styling: any = {
        icon: <RadioButtonUncheckedIcon />
    };

    switch (label?.toLowerCase()) {
        case 'highest':
            styling.icon = <DoubleArrowIcon sx={{ fontSize: '16px', color: '#f55033', transform: 'rotate(270deg)' }} />;
            break;
        case 'medium':
            styling.icon = <DragHandleIcon sx={{ fontSize: '24px', width: '18px', color: '#f79d28' }} />;
            break;
        case 'minor':
            styling.icon = <RadioButtonUncheckedIcon sx={{ fontSize: '14px' }} />;
            break;
        case 'blocker':
            styling.icon = <RemoveCircleIcon sx={{ fontSize: '16px', color: '#f55033' }} />;
            break;
        case 'high':
            styling.icon = <ExpandLessIcon sx={{ fontSize: '24px', color: '#f55033' }} />;
            break;
        case 'low':
            styling.icon = <ExpandMoreIcon sx={{ fontSize: '24px', color: '#3384f5' }} />;
            break;
        case 'lowest':
            styling.icon = <DoubleArrowIcon sx={{ fontSize: '16px', color: '#3384f5', transform: 'rotate(90deg)' }} />;
            break;
        default:
            styling.icon = label?.toLowerCase()?.indexOf('back') === -1 ? 'N/A' : '';
            break;
    }
    return (
        <>
            <Box
                className={classes.priorityComponent}
            >
                {styling.icon}
                {label?.toLowerCase()}
            </Box>
        </>
    );
};
const TableBodyComponent: FC<any> = ({
    data,
    selected,
    handleClick,
    permissions,
    isProject,
    isOrganization,
    newTicketIds,
    editRouteUrl,
    routeLink,
    sendSelectedIds

}) => {
    const classes = useStyles();
    const dateSecRef = useRef(null);
    const dateSpanRef = useRef(null);
    const [dateSecWidth, setDateSecWidth] = useState(0);
    const [dateSpanWidth, setDateSpanWidth] = useState(0);

    useEffect(() => {
        if (dateSecRef.current) {
            setDateSecWidth(dateSecRef.current.offsetWidth);
        }
    }, [data]);
    useEffect(() => {
        if (dateSecRef.current) {
            setDateSpanWidth(dateSpanRef.current.offsetWidth);
        }
    }, [data]);


    return (
        <TableBody style={{ position: 'relative' }}>
            {data.length > 0 ? (
                data.map((row) => {
                    const isItemSelected = selected.includes(row._id);
                    return (
                        <TableRow

                            component={Link}
                            to={(permissions?.update || permissions?.getById) ? `/erp/cases/${row?.caseKey}` : ''}
                            hover
                            className={classes.tableRow}
                            key={row?.id}
                            style={{
                                border: newTicketIds.includes(row.id) ? '2px solid #87c387' : 'transparent',
                                textDecoration: 'none',
                                cursor: 'pointer'
                            }}

                        >
                            <TableCell padding="checkbox" onClick={(event) => handleClick(event, row._id)}>
                                <Checkbox
                                    checked={isItemSelected}
                                    inputProps={{ 'aria-labelledby': row._id }}
                                />
                            </TableCell>
                            <TableCell align="left" size="small" style={{ padding: '8px 0' }}>
                                {row?.attachmentId?.length ? <AttachFileIcon style={{ fontSize: '18px' }} /> : null}
                            </TableCell>
                            <TableCell align="center">
                                <span className={classes.clip}>
                                    {row.caseKey}
                                </span>
                            </TableCell>
                            <TableCell
                                align="center"
                                title={row.summary || 'N/A'}
                                style={{ width: '22vw', maxWidth: '22vw', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                            >
                                <span>
                                    {row.summary || 'N/A'}
                                </span>
                            </TableCell>
                            <TableCell align="center">
                                <PriorityComponent label={row.priority?.name || 'N/A'} />
                            </TableCell>
                            <TableCell align="center">
                                {row.status?.name || 'N/A'}
                            </TableCell>
                            {isProject && (
                                <TableCell
                                    align="center"
                                    title={isOrganization ? `${row.organization?.name} - ${row.project?.name}` : row.project?.name}
                                >
                                    {row.project?.name || 'N/A'}
                                </TableCell>
                            )}
                            <TableCell align="center">
                                <strong>{row.createdBy?.name || 'N/A'}</strong>
                            </TableCell>
                            <TableCell align="center">
                                {row.assignee?.name ? <strong>{row.assignee?.name}</strong> : 'N/A'}
                            </TableCell>
                            <TableCell align="center" title={row.caseType?.name}>
                                {row.caseKey?.substring(0, 3)}
                            </TableCell>
                            <TableCell align="center" className="dateSec" ref={dateSecRef}>
                                <span ref={dateSpanRef}>
                                    {moment(row.createdAt).format('DD MMM, YYYY HH:mm:ss')}
                                </span>
                            </TableCell>
                        </TableRow>
                    );
                })
            ) : (
                <TableRow>
                    <TableCell colSpan={12} style={{ textAlign: 'center' }}>
                        No Data Found
                    </TableCell>
                </TableRow>
            )}
            {selected.length > 0 && (
                <TableRow
                    className={data.length > 14 ? classes.fixedRow : ''}
                    sx={data.length > 14 ? { display: 'flex', justifyContent: 'flex-end', padding: '0px' } : {}}
                >
                    <TableCell colSpan={10} style={{ textAlign: 'right' }} sx={{ padding: '10px' }}>
                        <Button
                            variant="contained"
                            className={classes.buttonNext}
                            onClick={() => sendSelectedIds(selected)}
                            sx={{ marginRight: `${(dateSecWidth - dateSpanWidth) - 15}px` }}
                        >
                            Next
                        </Button>
                    </TableCell>
                </TableRow>
            )}
        </TableBody>

    );
};



interface CaseListViewTypes extends TableProps {
    sortData?: any;
    toggleSortData?: any;
    setSelectedSort?: any;
    selectedSort?: any;
    isOrganization?: boolean;
    isProject?: boolean;
    sendSelectedIds: (data: any) => void;
}
const CaseListViewBulk: FC<CaseListViewTypes> = ({
    data,
    editRouteUrl,
    count,
    page,
    limit,
    handlePage,
    bulkIds,
    handleLimit,
    handleAddBulkIds,
    handleRemoveBulkIds,
    sortData,
    toggleSortData,
    selectedSort,
    setSelectedSort,
    permissions,
    isOrganization = true,
    isProject = true,
    newTicketIds,
    sendSelectedIds,
}: any) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const [selected, setSelected] = useState<string[]>([]);
    const tableRef = useRef(null);
    const [tableWidth, setTableWidth] = useState(0);

    let Columns = [
        {
            id: 'attachment',
            label: '',
            isSort: false,
        },
        {
            id: 'id',
            label: 'Case #',
            isSort: true,
            toggleSortData,
            direction: sortData?._id
        },
        {
            id: 'title',
            label: 'Title',
            isSort: true,
            toggleSortData,
            direction: sortData?.caseName
        },
        {
            id: 'priority',
            label: 'Priority',
            isSort: true,
            toggleSortData,
            direction: sortData.priority
        },
        {
            id: 'status',
            label: 'Status',
            isSort: true,
            toggleSortData,
            direction: sortData.status
        },
        {
            id: 'project',
            label: 'Project',
            isSort: true,
            toggleSortData,
            direction: sortData.project
        },
        {
            id: 'reporter',
            label: 'Reporter',
            isSort: true,
            toggleSortData,
            direction: sortData?.createdBy
        },
        {
            id: 'assignee',
            label: 'Assignee',
            isSort: true,
            toggleSortData,
            direction: sortData?.assignee
        },
        {
            id: 'type',
            label: 'Type',
            isSort: true,
            toggleSortData,
            direction: sortData.caseType
        },
        {
            id: 'createdAt',
            label: 'Created At',
            isSort: true,
            toggleSortData,
            direction: moment(sortData?.createdAt?.replace('T', ' '))?.format('DD MMM, YYYY - HH:mm:ss')
        }
    ];
    if (!isOrganization) {
        Columns = Columns.filter((col) => col.id !== 'organization');
    }
    if (!isProject) {
        Columns = Columns.filter((col) => col.id !== 'project');
    }
    const handleChangePage = useCallback(
        (event: any, _page: number) => {
            handlePage(_page);
        },
        [page, data]
    );
    const handleChangeLimit = useCallback(
        (event: any) => {
            handleLimit(parseInt(event.target.value, 10));
            handlePage(0);
        },
        [limit, data]
    );

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n: any) => n._id);
            setSelected(newSelecteds);
        } else {
            setSelected([]);
        }


    };

    const handleClick = (event: any, id: string) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };
    const routeLink = (caseKey) => {
        navigate(`/erp/cases/${caseKey}`)
    }

    useEffect(() => {
        if (tableRef.current) {
            setTableWidth(tableRef.current.offsetWidth);
        }
    }, [data.length]);



    return (
        <Box
            sx={{
                backgroundColor: 'background.default',
                py: 1,
            }}
        >
            {' '}
            {/* move button to the right end of the table */}
            <Card className={classes.cardRoot}>
                <Box sx={{ minWidth: 700, }}>
                    <Table ref={tableRef}>
                        <TableHeadComponent
                            // data={Columns}
                            // selectedSort={selectedSort}
                            // setSelectedSort={setSelectedSort}
                            data={Columns}
                            selectedSort={selectedSort}
                            setSelectedSort={setSelectedSort}
                            handleSelectAllClick={handleSelectAllClick}
                            numSelected={selected.length}
                            rowCount={data.length}
                        />
                        <TableBodyComponent
                            data={data}
                            editRouteUrl={editRouteUrl}
                            handleAddBulkIds={handleAddBulkIds}
                            bulkIds={bulkIds}
                            handleRemoveBulkIds={handleRemoveBulkIds}
                            permissions={permissions}
                            isProject={isProject}
                            isOrganization={isOrganization}
                            newTicketIds={newTicketIds}
                            selected={selected}
                            handleClick={handleClick}
                            routeLink={routeLink}
                            sendSelectedIds={sendSelectedIds}
                        />

                    </Table>
                </Box>
                {/* <TablePagination
            component="div"
            count={count}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeLimit}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[25, 50, 100, 500, 1000]}
          /> */}
            </Card>
        </Box>
    );
};

export default CaseListViewBulk;
