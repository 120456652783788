import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { useState } from 'react';
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getSmoothStepPath,
  useReactFlow
} from 'reactflow';

const useStyles = makeStyles<Theme>(() => createStyles({
  label: {
    maxWidth: '200px',
    width: 'max-content',
    backgroundColor: '#fff',
    border: '1px solid #e1e1e1',
    borderRadius: '10px',
    fontSize: '7px',
    lineHeight: '9px',
    padding: '1px 5px',
    cursor: 'default',
    position: 'relative',
    zIndex: 2,

    '& div': {
      backgroundColor: '#B80000',
      height: '9px',
      width: '9px',
      fontSize: '7px',
      border: '0px',
      borderRadius: '50%',
      cursor: 'pointer',

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      position: 'absolute',
      top: '-5px',
      right: '-5px',
      zIndex: 1,
    }
  },
  icon: {
    width: '5px',
    height: '7px',
    color: '#fff',
  }
}));

const ButtonEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  label,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
}: EdgeProps) => {
  const classes = useStyles();
  const { setEdges, getEdges } = useReactFlow();
  const [val, setVal] = useState(label || '');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalClose = () => setIsModalOpen(false);
  const handleModalOpen = () => setIsModalOpen(true);
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const onEdgeClick = () => {
    const edges = getEdges();
    const tempEdges = edges?.map((edg) => {
      if (edg.id === id && val !== '' && val !== null) {
        edg.label = val;
      }
      return edg;
    });
    setEdges(tempEdges);
    handleModalClose();
  };
  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <Box
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: 'all',
          }}
          className={classes.label}
        >
          {label}
          <Box onClick={handleModalOpen}><EditIcon className={classes.icon} /></Box>
        </Box>
      </EdgeLabelRenderer>
      <Dialog
        open={isModalOpen}
        onClose={handleModalClose}
      >
        <DialogTitle>Edit Arrow</DialogTitle>
        <DialogContent>
          <TextField
            variant="outlined"
            value={val}
            onChange={(e) => setVal(e.target.value)}
            label="Enter Label"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose}>Close</Button>
          <Button onClick={onEdgeClick}>Update</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ButtonEdge;
