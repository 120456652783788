import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { FC, useState } from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import { meetingsApi } from 'src/api/meetingsApi';

const Durations = [
  {
    label: 'Select Duration',
    value: 'Select Duration',
  },
  { label: '30 Minutes', value: '30M' },
  {
    label: '1 Hour',
    value: '1H'
  },
  {
    label: '1 Hour 30 Minutes',
    value: '1H30M'
  },
  {
    label: '2 Hours',
    value: '2H'
  },
  {
    label: '2 Hours 30 Minutes',
    value: '2H30M'
  }
];
const attendeesArr = [
  { label: 'No of Attendees', value: 0 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
];

type AddMeetingCardProps = {
  setAddMeeting: any
};

const AddMeetingCard: FC<any> = ({ setAddMeeting }: AddMeetingCardProps) => {
  const [title, setTitle] = useState<string>('');
  const [attendees, setAttendees] = useState<number>(0);
  const [duration, setDuration] = useState<string>('Select Duration');
  const [isExternal, setIsExternal] = useState<string>('false');
  const [startTime, setStartTime] = useState<string>('');
  const [meetingDescription, setMeetingDescription] = useState<string>('');

  const { organizationID, projectID } = useParams();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  // const [endTime, setEndTime] = useState<string>('');

  const customValidation = () => {
    if (title.trim() === '') {
      toast.error('Title cannot be empty');
      return false;
    } if (attendees === 0) {
      toast.error('Please select no of attendees');
      return false;
    } if (duration === 'Select Duration') {
      toast.error('Please select Duration');
      return false;
    } if (startTime === '') {
      toast.error('Please select start time of Meeting');
      return false;
    } if (meetingDescription.trim() === '') {
      toast.error('Please enter Meeting description');
      return false;
    }
    return true;
  };

  const handleChange = (e) => {
    setDuration(e.target.value);
  };

  const handleCreateMeeting = async () => {
    setIsSubmitting(true);
    const payload = {
      description: meetingDescription,
      startTime,
      // endTime,
      duration,
      numberOfAttendees: attendees,
      title,
      isExternal: isExternal === 'true',
      organizationId: organizationID,
      projectId: projectID,
    };
    try {
      const response = await meetingsApi.createMeeting(payload);
      if (response?.status === 201) {
        setIsSubmitting(false);
        toast.success('Meeting Created');
        setAddMeeting(false);
      }
    } catch (error) {
      setIsSubmitting(false);
      setAddMeeting(false);
      toast.error(error?.data?.message);
    }
  };
  return (
    <>
      <Accordion
        defaultExpanded
        className="addMeetingCard"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className="accordionSummary"
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontWeight: 'bold' }}>Create Meeting</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
            >
              <Typography>Title</Typography>
              <TextField
                placeholder="Enter Title"
                fullWidth
                required
                inputProps={{ maxLength: 225 }}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Grid>
            <Grid
              className="infoCard
              "
              item
              md={6}
            >
              <InputLabel id="attendeesLabel">Attendees</InputLabel>
              <Select
                labelId="attendeesLabel"
                id="select"
                fullWidth
                value={attendees}
                onChange={(e) => setAttendees(e.target.value)}
              >
                {attendeesArr.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid
              item
              md={6}
              className="infoCard"
            >
              <InputLabel id="durationLabel">Duration</InputLabel>
              <Select
                labelId="durationLabel"
                id="select"
                fullWidth
                value={duration}
                onChange={(e) => handleChange(e)}
                defaultValue={Durations[0].value}
              >
                {Durations?.map((dur) => (
                  <MenuItem value={dur?.value}>{dur?.label}</MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid
              item
              md={6}
              className="infoCard"
            >
              <InputLabel id="isExternalLabel">Is External</InputLabel>
              <Select
                labelId="isExternalLabel"
                id="select"
                fullWidth
                value={isExternal}
                onChange={(e) => setIsExternal(e.target.value)}
              >
                <MenuItem value="true">True</MenuItem>
                <MenuItem value="false">False</MenuItem>
              </Select>
            </Grid>
            <Grid
              item
              md={6}
              className="infoCard"
            >
              <Typography>Start Time</Typography>
              <TextField
                id="date"
                label=""
                type="datetime-local"
                defaultValue=""
                fullWidth
                onChange={(e) => setStartTime(e.target.value)}
                className="infoCardTextField"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid
              item
              md={12}
              className="ckEditor"
            >
              <Typography>
                Description
              </Typography>
              <CKEditor
                editor={ClassicEditor}
                data={meetingDescription}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setMeetingDescription(data);
                }}
              />
            </Grid>
          </Grid>

          {isSubmitting ? (
            <div className="loadingCircle">
              <CircularProgress />
            </div>
          ) : (
            <Button
              className="createButton"
              variant="contained"
              color="secondary"
              onClick={() => {
                if (customValidation()) {
                  handleCreateMeeting();
                }
              }}
            >
              Create
            </Button>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default AddMeetingCard;
