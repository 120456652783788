import { combineReducers } from 'redux';
import hideMenuSlice from './slices/hideMenuSlice';
import userInfoSlice from './slices/userInfoSlice';
import organizationDetailsSlice from './slices/organizationDetailsSlice';
import userPermissionsSlice from './slices/permissions';
import snackBarSlice from './slices/snackBarSlice';
import pimModuleSlice from './slices/pimModuleSlice';
import osmSlice from './slices/omsSlice';

const rootReducer = combineReducers({
  hideMenu: hideMenuSlice,
  userInfo: userInfoSlice,
  orgInfo: organizationDetailsSlice,
  userPermissions: userPermissionsSlice,
  snackbar: snackBarSlice,
  pim: pimModuleSlice,
  oms: osmSlice
});

export default rootReducer;
