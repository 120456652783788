/* eslint-disable no-underscore-dangle */
import { Autocomplete, TextField, Box, Button } from '@material-ui/core';
import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { consultingApi } from 'src/api/consultingApi';

type WorkLogFormProps = {
  closeDrawer: any;
  formData?: any;
  onSubmit: any;
  editForm?: boolean;
  schema: any;
  organizationId: string;
};

const WorkLogForm = ({
  closeDrawer,
  onSubmit,
  schema,
  formData,
  editForm,
  organizationId,
}: WorkLogFormProps) => {
  const [allCuEmployees, setAllCuEmployees] = useState([]);
  const [projects, setProjects] = useState([]);
  const [cuRoles, setCuRoles] = useState([]);
  // const [orderNumbersList, setOrderNumbersList] = useState([]);

  const formik = useFormik({
    initialValues: {
      id: formData?._id || '',
      // orderNumber: formData?.data?.orderNumber || null,
      // client: formData?.clientId || null,
      employee: formData?.data?.employeeId || null,
      project: formData?.data?.project || null,
      // date: formData?.data?.date || '',
      hours: formData?.data?.cuWhours || null,
      cuWtype: formData?.data?.cuWType || null,
      cuWReport: formData?.data?.cuWReport || '',
      cuWDescription: formData?.data?.cuWDescription || '',
    },
    onSubmit,
    validationSchema: schema,
  });

  const getCuProjects = async (orgId = null) => {
    try {
      const response = await consultingApi.getCuProjectsAutoComplete(orgId);
      if (response?.status === 200) {
        setProjects(response?.data);
        if (editForm) {
          if( formData?.data?.project === null){
            formik?.setFieldValue('project', {
              project: null
            });
          }else{
            const projectInitialValue = response?.data?.filter(
              (obj) => obj.project === formData?.data?.project
            );
            formik?.setFieldValue('project', projectInitialValue[0]);
          }
        }
      }
    } catch (error) {
      toast?.error(error?.response?.data?.message);
    }
  };
  const getCuEmployees = async () => {
    try {
      const response = await consultingApi.getCUEmployees();
      if (response?.status === 200) {
        setAllCuEmployees(response?.data);
        if (editForm) {
          const employeeInitialValue = response?.data?.filter(
            (obj) => obj?.name === formData?.data?.employeeId
          );
          formik?.setFieldValue('employee', employeeInitialValue[0]);
        }
      }
    } catch (error) {
      toast?.error(error?.response?.data?.message);
    }
  };
  const getAllCURoles = async () => {
    try {
      const response = await consultingApi.getCURolesAutoComplete(
        organizationId
      );
      if (response?.status === 200) {
        setCuRoles(response?.data);
      }
    } catch (error) {
      toast?.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    // if (editForm) {
    //   const inputDate = new Date(formData?.data?.date);
    //   const formattedDate = inputDate.toISOString().slice(0, 16);
    //   formik?.setFieldValue('date', formattedDate);
    // }
    getCuEmployees();
    getAllCURoles();
    // getOrderNumbers();
    getCuProjects(organizationId);
  }, []);
  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <div className="ticketBody">
        <div className="ticketDropDown labelsDataCss">
          <Autocomplete
            id="worklog-project"
            options={projects || []}
            value={formik?.values?.project}
            getOptionLabel={(option) => option.project || 'Global'}
            // disabled={editForm}
            onChange={(e: any, newValue: any) => {
              formik.setFieldValue('project', newValue);
              // formik.setFieldValue('orderNumber', null);
              // getOrderNumbers(newValue === null ? null : newValue.project);
              //   addDataToLocalStorage('clientId', newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Project (Optional)"
                error={Boolean(
                  formik?.touched?.project && formik?.errors?.project
                )}
                helperText={formik?.touched?.project && formik?.errors?.project}
              />
            )}
          />
        </div>
        <div className="ticketDropDown labelsDataCss">
          <Autocomplete
            id="worklog-employee"
            options={allCuEmployees || []}
            value={formik?.values?.employee}
            getOptionLabel={(option) => option.name}
            onChange={(e: any, newValue: any) => {
              formik?.setFieldValue('employee', newValue);
              // addDataToLocalStorage('clientId', newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Employee"
                error={Boolean(
                  formik?.touched?.employee && formik?.errors?.employee
                )}
                helperText={
                  formik?.touched?.employee && formik?.errors?.employee
                }
              />
            )}
          />
        </div>
        <div className="ticketDropDown labelsDataCss">
          <Autocomplete
            id="worklog-CUWType"
            options={cuRoles || []}
            value={formik?.values?.cuWtype}
            getOptionLabel={(option) => option.role}
            onChange={(e: any, newValue: any) => {
              formik?.setFieldValue('cuWtype', newValue);
              //   addDataToLocalStorage('clientId', newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Role"
                error={Boolean(
                  formik?.touched?.cuWtype && formik?.errors?.cuWtype
                )}
                helperText={formik?.touched?.cuWtype && formik?.errors?.cuWtype}
              />
            )}
          />
        </div>
        <div
          className="ticketDropDown labelsDataCss"
          style={{
            display: 'flex',
            alignItems: 'center',
          }}>
          <TextField
            id="worklog-hours"
            type="number"
            name="hours"
            value={formik?.values?.hours}
            onChange={formik?.handleChange}
            placeholder="Hours"
            // disabled={formik.values.cuWtype === null}
            InputProps={{
              inputProps: { min: 0, step: 0.1 },
            }}
            error={Boolean(formik.touched?.hours && formik?.errors?.hours)}
            helperText={formik.touched?.hours && formik?.errors?.hours}
          />
          <div
            style={{
              fontStyle: 'italic',
              color: '#696969',
              marginLeft: '10px',
            }}>
            {` × ${formik.values.cuWtype?.cu || 'role cu'} = ${
              formik.values.cuWtype?.cu
                ? (formik.values.cuWtype?.cu * formik?.values?.hours).toFixed(2)
                : formik?.values?.hours || 'consumed hours'
            }`}
          </div>
        </div>
        <div className="ticketDropDown labelsDataCss">
          <TextField
            id="worklog-CUWReport"
            type="text"
            name="cuWReport"
            value={formik?.values?.cuWReport}
            placeholder="Report Link"
            onChange={formik?.handleChange}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            error={Boolean(formik.touched?.cuWReport && formik?.errors?.cuWReport)}
            helperText={formik.touched?.cuWReport && formik?.errors?.cuWReport}
          />
        </div>
        <div className="ticketDropDown labelsDataCss">
          <TextField
            id="worklog-cuWDescription"
            type="text"
            name="cuWDescription"
            value={formik?.values?.cuWDescription}
            multiline
            onChange={formik?.handleChange}
            placeholder="Description"
            fullWidth
          />
        </div>
        <div className="submitTicket">
          {/* <Box display="flex" alignItems="center">
            <Button
              color="primary"
              // onClick={() => handleReset()}
              sx={{ height: '38px' }}>
              Reset
            </Button>
          </Box> */}
          <Box display="flex" sx={{ marginLeft: 'auto' }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => closeDrawer()}
              sx={{ marginLeft: 'auto', height: '38px' }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              sx={{ height: '38px' }}
              // disabled={isSubmitting}
            >
              {editForm ? 'Save' : 'Create'}
            </Button>
          </Box>
        </div>
      </div>
    </form>
  );
};

export default WorkLogForm;
