/* eslint-disable no-underscore-dangle */
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@material-ui/core';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import toast from 'react-hot-toast';
import Modal from 'react-modal';
import { logTimeApi } from 'src/api/logTimeApi';
import * as Yup from 'yup';

Modal.setAppElement('#root'); // Set the root element for accessibility
interface LogTimeModalProps {
  isOpen: boolean;
  onClose: (state) => void;
  billingAccounts: Array<any>;
  caseId?: string;
  caseKey?: string;
  caseSummary?: string;
  loggedTimeEditData?: any;
  convertSecondsToTimeString?: any;

  reloadData?: () => void;
}
const LogTimeModal = ({
  isOpen,
  onClose,
  billingAccounts,
  caseId,
  caseKey,
  caseSummary,
  loggedTimeEditData,
  convertSecondsToTimeString,
  reloadData,
}: LogTimeModalProps) => {
  const [loading, setloading] = useState(false);
  const modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      width: '40%',
      borderRadius: '10px',
    },
  };

  const convertToSeconds = (timeString) => {
    const timeUnits = timeString?.match(/\d+\s*[hm]/g);
    let totalSeconds = 0;

    timeUnits?.forEach((unit) => {
      const number = parseInt(unit, 10);
      const timeUnit = unit?.replace(/\d+\s*/g, ''); // Remove digits and spaces to get the unit

      if (timeUnit === 'h') {
        totalSeconds += number * 3600; // 1 hour = 3600 seconds
      } else if (timeUnit === 'm') {
        totalSeconds += number * 60; // 1 minute = 60 seconds
      }
    });

    return totalSeconds || 0;
  };
  console.log('loggedTimeEditData', loggedTimeEditData)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      loggedHoursInSeconds: convertSecondsToTimeString(loggedTimeEditData?.loggedHoursInSeconds) ||
        null,
      date:
        moment(loggedTimeEditData?.date).format('YYYY-MM-DD') ||
        moment().format('YYYY-MM-DD'),
      description: loggedTimeEditData?.description || '',
      billableAccount: loggedTimeEditData?.billableAccount || null,
    },
    onSubmit: async (values) => {
      setloading(true);
      const hoursInSeconds = convertToSeconds(values.loggedHoursInSeconds);
      console.log('valuesvalues', values);
      const updatedValues = {
        ...values,
        loggedHoursInSeconds: hoursInSeconds,
        date: `${moment(values?.date).format('YYYY-MM-DD')}T00:00:00`,
        billableAccount: {
          id: values.billableAccount?._id,
          name: values.billableAccount?.name,
        },
      };

      try {
        let response: any = {};
        if (isEmpty(loggedTimeEditData)) {
          response = await logTimeApi?.logTime(caseId, updatedValues);
        } else {
          response = await logTimeApi?.editLogTime(
            caseId,
            loggedTimeEditData?.id,
            updatedValues
          );
        }
        setloading(false);
        toast.success('Time logged successfully');
        if (reloadData !== undefined) {
          reloadData();
        }
        formik.resetForm();
        onClose(false);
      } catch (error) {
        if (error?.response?.status !== 401) {
          setloading(false);
          toast.error(error?.response?.data?.message);
        }
      }
    },
    validationSchema: Yup.object().shape({
      description: Yup.string().trim().required('Description is required'),
      loggedHoursInSeconds: Yup.string()
        .matches(
          /^(?:\d+\s*[hm]\s*)+$/,
          'Allocated hours must be in the format "number h" or "number m" or a combination like "2h 20m"'
        )
        .test('non-zero-duration', 'Duration must be greater than 0', (value) => {
          const totalSeconds = convertToSeconds(value);
          return totalSeconds > 0;
        })
        .required('Time is required'),
      billableAccount: Yup.object()
        .required('Billable Account is required')
        .nullable(),
    }),
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onClose(false)}
      style={modalStyle}>
      <Box
        sx={{
          padding: 3,
        }}>
        <Stack spacing={2}>
          <Typography
            sx={{ display: 'flex', fontSize: 16 }}
            variant="h4"
            fontWeight={500}>
            <AvTimerIcon sx={{ marginRight: '10px' }} />
            Log time
          </Typography>
          <div>
            <Typography
              sx={{ display: 'flex', fontSize: 16, marginBottom: '10px' }}
              variant="h5"
              fontWeight={500}>
              Issue
            </Typography>
            <TextField
              value={`${caseKey} - ${caseSummary}`}
              onChange={formik.handleChange}
              variant="outlined"
              disabled
              type="text"
              fullWidth
              style={{ color: 'black' }}
              placeholder="Title"
            />
          </div>
          <Grid display="flex" justifyContent="space-between">
            <Grid item md={5}>
              <Typography
                sx={{ display: 'flex', fontSize: 16, marginBottom: '10px' }}
                variant="h5"
                fontWeight={500}>
                Date
              </Typography>
              <TextField
                value={formik.values.date}
                onChange={formik.handleChange}
                variant="outlined"
                name="date"
                type="date"
                fullWidth
                placeholder="Date"
                error={Boolean(formik?.touched?.date && formik?.errors?.date)}
                helperText={formik?.touched?.date && formik?.errors?.date}
              />
            </Grid>
            <Grid item md={5}>
              <Typography
                sx={{ display: 'flex', fontSize: 16, marginBottom: '10px' }}
                variant="h5"
                fontWeight={500}>
                Duration
              </Typography>
              <TextField
                value={formik.values.loggedHoursInSeconds}
                onChange={formik.handleChange}
                variant="outlined"
                name="loggedHoursInSeconds"
                type="text"
                fullWidth
                placeholder="0h"
                error={Boolean(
                  formik?.touched?.loggedHoursInSeconds &&
                  formik?.errors?.loggedHoursInSeconds
                )}
                helperText={
                  formik?.touched?.loggedHoursInSeconds &&
                  formik?.errors?.loggedHoursInSeconds
                }
              />
            </Grid>
          </Grid>
          <div>
            <Typography
              sx={{ display: 'flex', fontSize: 16, marginBottom: '10px' }}
              variant="h5"
              fontWeight={500}>
              Description
            </Typography>
            <TextField
              value={formik.values.description}
              onChange={formik.handleChange}
              variant="outlined"
              name="description"
              rows={4}
              multiline
              fullWidth
              placeholder="Description"
              error={Boolean(
                formik?.touched?.description && formik?.errors?.description
              )}
              helperText={
                formik?.touched?.description && formik?.errors?.description
              }
            />
          </div>
          <div>
            <Typography
              sx={{ display: 'flex', fontSize: 16, marginBottom: '10px' }}
              variant="h5"
              fontWeight={500}>
              Account
            </Typography>
            <Autocomplete
              options={billingAccounts}
              value={formik.values.billableAccount}
              // eslint-disable-next-line consistent-return
              onChange={(event: any, value: any) => {
                formik.setFieldValue('billableAccount', value);
              }}
              getOptionLabel={(option) => option.name}
              style={{ minWidth: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  name="billableAccount"
                  placeholder="Account"
                  error={Boolean(
                    formik?.touched?.billableAccount &&
                    formik?.errors?.billableAccount
                  )}
                  helperText={
                    formik?.touched?.billableAccount &&
                    formik?.errors?.billableAccount
                  }
                />
              )}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}>
            <Button
              variant="outlined"
              color="primary"
              sx={{ marginLeft: 'auto', height: '38px' }}
              onClick={() => onClose(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              //   type="submit"
              sx={{ height: '38px', marginLeft: '12px' }}
              onClick={() => !loading && formik.handleSubmit()}>
              {loading ? (
                <CircularProgress
                  size="20px"
                  sx={{
                    color: '#FFFFFF',
                  }}
                />
              ) : (
                'Log Time'
              )}
            </Button>
          </div>
        </Stack>
      </Box>
    </Modal>
  );
};

export default LogTimeModal;
