/* eslint-disable no-underscore-dangle */
import {
  Box,
  Button,
  makeStyles,
  createStyles,
  Theme,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { useFormik } from 'formik';
import CustomAutoComplete from 'src/components/autoComplete/autoComplete';
import { useSelector } from 'react-redux';

type CreateProductFormProps = {
  closeDrawer: any;
  formData?: any;
  onSubmit: any;
  editForm?: boolean;
  schema: any;
};

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    formRoot: {
      '& .MuiInputLabel-root': {
        top: '-8px',
      },
      '& .MuiInputLabel-shrink': {
        top: '0px',
      },
      '& label': {
        fontWeight: '550'
      }
    }

  }))

const OrderForm = ({
  closeDrawer,
  onSubmit,
  schema,
  formData,
  editForm,
}: CreateProductFormProps) => {
  const classes = useStyles();
  const clients = useSelector((state: any) => state.oms.clientAutoComplete);

  const formik = useFormik({
    initialValues: {
      startDate: formData?.startDate.substring(0, 10) || '',
      endDate: formData?.endDate.substring(0, 10) || '',
      client: formData?.client || null,
      product: formData?.product || null,
      status: formData?.status || 'Quote'
    },
    onSubmit,
    validationSchema: schema,
  });

  return (
    <form noValidate onSubmit={formik.handleSubmit} className={classes.formRoot}>
      <div className="ticketBody">
        <div className="ticketDropDown">
          <CustomAutoComplete
            id='order-productId'
            value={formik?.values?.product}
            onSelect={(e: any, newValue: any) => {
              formik.setFieldValue('product', newValue);
            }}
            placeholder="Product"
            label="Product"
            error={Boolean(
              formik?.touched?.product && formik?.errors?.product
            )}
            helperText={
              formik?.touched?.product && formik?.errors?.product
            }
            apiUrl={`${process.env.REACT_APP_OMS_URL}/autoComplete/product`}
          />
        </div>

        <div className="ticketDropDown">
          <Autocomplete
            id="order-client"
            options={clients}
            value={formik?.values?.client}
            getOptionLabel={(option) => option.name}
            onChange={(e: any, newValue: any) => {
              formik.setFieldValue('client', newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Client"
                label="Client"
                error={Boolean(
                  formik?.touched?.client && formik?.errors?.client
                )}
                helperText={
                  formik?.touched?.client && formik?.errors?.client
                }
              />
            )}
          />
        </div>
        <div className="ticketDropDown">
          <FormControl
            fullWidth
          >
            <InputLabel id="statusLabel">
              Status
            </InputLabel>
            <Select
              labelId="statusLabel"
              label='Status'
              id="order-status"
              value={formik.values.status}
              variant="outlined"
              placeholder='Status'
              name='status'
              onChange={formik.handleChange}
              error={Boolean(
                formik?.touched?.status && formik?.errors?.status
              )}

            >
              <MenuItem value="Quote">Quote</MenuItem>
              <MenuItem value="Invoice">Invoice</MenuItem>
              <MenuItem value="Active">Active</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="ticketDropDown">
          <Box
            sx={{
              display: "flex",
              gap: "20px",
            }}
          >
            <TextField
              id="startDate"
              label="Start Date"
              type="date"
              name="startDate"
              onChange={(e) => {
                formik.handleChange(e);
              }}
              fullWidth
              value={formik.values.startDate}
              InputLabelProps={{
                shrink: true,
              }}
              error={
                Boolean(formik.touched.startDate) &&
                Boolean(formik.errors.startDate)
              }
              helperText={formik.touched.startDate && formik.errors.startDate}
            />
            <TextField
              id="endDate"
              label="End Date"
              type="date"
              name="endDate"
              onChange={formik.handleChange}
              fullWidth
              value={formik.values.endDate}
              inputProps={{ min: formik.values.startDate }}
              InputLabelProps={{
                shrink: true,
              }}
              error={
                Boolean(formik.touched.endDate) &&
                Boolean(formik.errors.endDate)
              }
              helperText={formik.touched.endDate && formik.errors.endDate}
            />
          </Box>
        </div>
        <div className="submitTicket">
          <Box display="flex" sx={{ marginLeft: 'auto' }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => closeDrawer()}
              sx={{ marginLeft: 'auto', height: '38px' }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              sx={{ height: '38px' }}>
              {editForm ? 'Save' : 'Create'}
            </Button>
          </Box>
        </div>
      </div>
    </form>
  );
};

export default OrderForm;
