import { isUndefined } from 'lodash';
import axios from 'src/utils/axios';
import parse from 'src/utils/parse';

class LogTime {
    async logTime(caseId: any, payload: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .post(`${process.env.REACT_APP_CASES}/logTime/${caseId}`, payload)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async editLogTime(caseId: any, loggedTimeID: any, payload: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${process.env.REACT_APP_CASES}/logTime/${loggedTimeID}/case/${caseId}`, payload)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }


    async deleteLogTime(caseId: any, loggedTimeID: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .delete(`${process.env.REACT_APP_CASES}/logTime/${loggedTimeID}/case/${caseId}`)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}

export const logTimeApi = new LogTime();
