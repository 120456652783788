/* eslint-disable no-nested-ternary */
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Container, Grid, Tab, Tabs } from '@material-ui/core';
import SimpleBreadCrumb from 'src/components/widgets/BreadCrumb/SimpleBreadCrumb';
import { useState } from 'react';
import DepartmentSummary from './department';
import ProjectSummary from './project';
import EmployeeSummary from './employee';
import Progress from './progress/Progress';
import OverallGraph from './overall';

const tabs = [
  {
    label: 'Aggregate Summary',
    value: 'overall',
  },
  {
    label: 'Department Summary',
    value: 'department',
  },
  // {
  //   label: 'Project Summary',
  //   value: 'project',
  // },
  {
    label: 'Employee Summary',
    value: 'employe',
  },
  {
    label: 'Performance Summary',
    value: 'performance',
  },
];
const useStyles = makeStyles<Theme>(
  () =>
    createStyles({
      btn: {
        padding: 0,
      },
      tabs: {
        '& .MuiTab-root': {
          backgroundColor: 'transparent',
          minWidth: '120px',
        },
        '& .Mui-selected': {
          backgroundColor: '#231F20',
          color: '#fff',
        },
      },
    }),
  {
    name: 'Okrs',
  }
);
function Okrs(props: any) {
  const classes = useStyles();
  const [value, setValue] = useState('department');
  console.log(props, classes);
  const BreadCrumbsData = {
    title: 'OKR',
  };
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 4,
      }}>
      <Container maxWidth="xl">
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <SimpleBreadCrumb data={BreadCrumbsData} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Tabs
              indicatorColor="primary"
              scrollButtons="auto"
              textColor="primary"
              value={value}
              variant="scrollable"
              className={classes.tabs}>
              {tabs.map((tab) => (
                <Tab
                  key={tab?.value}
                  label={tab?.label}
                  value={tab?.value}
                  onClick={() => {
                    setValue(tab?.value);
                  }}
                />
              ))}
            </Tabs>
          </Grid>
        </Grid>
        <Box sx={{ mt: 1 }}>
          {value === 'department' ? (
            <DepartmentSummary />
          ) : value === 'project' ? (
            <ProjectSummary />
          ) : value === 'employe' ? (
            <EmployeeSummary />
          ) : value === 'performance' ? (
            <Progress />
          ) : value === 'overall' ? (
            <OverallGraph />
          ) : null}
        </Box>
      </Container>
    </Box>
  );
}

export default Okrs;
