/* eslint-disable prefer-rest-params */
import i18next from 'i18next';
import { isUndefined } from 'lodash';
import axios from 'src/utils/axios';
import logger from 'src/utils/logger';
import parse from 'src/utils/parse';

class LeavesApi {
  async getLeaveTypes(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/leaveType`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async updateLeaveTypes(isOverall: boolean, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${process.env.REACT_APP_CASES}/leaveType/bulk-update?isOverAll=${isOverall}`,
          payload
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async updateEmployeeLeaveQuota(employeeId, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${process.env.REACT_APP_CASES}/user/leaves/${employeeId}`,
          payload
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async postLeave(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_CASES}/userLeave`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async getLeavesByCaseId(caseKey: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.REACT_APP_CASES}/userLeave/loggedTime/caseKey?caseKey=${caseKey}`
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async getLeavesReportingOverview(
    startDate = '2024-01-01',
    endDate = '2024-12-30',
    users,
    leaveTypes
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/userLeave/report/date_range`, {
          params: {
            endDate,
            startDate,
            users,
            leaveType: leaveTypes,
          },
        })
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async getMonthlyLeaveData(users, leaveTypes): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.REACT_APP_CASES}/userLeave/monthly/report?users=${users}&leaveTypes=${leaveTypes}`
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async updateLeave(leaveId, payload): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${process.env.REACT_APP_CASES}/userLeave/${leaveId}`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async deleteLeave(leaveId): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${process.env.REACT_APP_CASES}/userLeave/${leaveId}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async leaveReportingExport({
    endDate,
    startDate,
    leaveTypes,
    users,
  }: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/userLeave/monthlyExport`, {
          params: {
            endDate,
            startDate,
            leaveTypes,
            users,
          },
          responseType: 'blob',
        })
        .then((response) => {
          if (!isUndefined(response?.data)) {
            resolve(response?.data);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error(i18next.t('internal_server_error')));
        });
    });
  }
}

export const leavesApi = new LeavesApi();
