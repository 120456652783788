import { Box, Button, Card, CircularProgress, Grid, Typography } from '@material-ui/core';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { isEmpty, isUndefined } from 'lodash';
import { ColorPicker, useColor } from 'react-color-palette';
import "react-color-palette/css";
import toast from 'react-hot-toast';
import { useOutletContext, useParams } from 'react-router-dom';
import { organizationApi } from 'src/api/organizationApi';
import { userApi } from 'src/api/userApi';
import useAuth from 'src/hooks/useAuth';
import logger from 'src/utils/logger';
import setTitle from 'src/utils/setTitle';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center'
    },
  },
  root: {
    borderRadius: '4px !important',
    paddingTop: '2.5rem',
    paddingBottom: '2.25rem',
    '& .rcp-dark[style]': {
      width: '90% !important',
      [theme.breakpoints.down('md')]: {
        width: '100% !important',
      },
    },
    '& .MuiTableCell-root': {
      paddingTop: 8.5,
      paddingBottom: 8.5,
    },
    '& .MuiToolbar-root': {
      minHeight: 60,
    },
    '& .css-15fev7r-MuiGrid-root': {
      display: 'block'
    },
    '& .MuiNativeSelect-select': {
      padding: '9.5px 14px',
      color: '#263238',
      fontWeight: 500,
      '& option': {
        color: '#263238',
        fontWeight: 400,
      },
    },
    '& .MuiTableRow-root': {
      cursor: 'pointer',
    },
  },
  card: {
    borderRadius: 5,
  },
  profileWrapper: {
    width: 215,
    margin: 'auto',
    backgroundColor: 'white',
    position: 'relative',
    '&:hover': {
      cursor: 'pointer',
      '& $editButton': {
        display: 'flex',
        width: '100%',
        height: '100%',
        color: '#121037',
        borderRadius: 0,
        backgroundColor: 'rgba(255, 255, 255, .75)',
        [theme.breakpoints.down('sm')]: {
          width: '50% !important',
        },
      },
      '& $deleteButton': {
        display: 'flex',
        color: '#DD0000',
        width: '100%',
        borderRadius: 0,
        backgroundColor: 'rgba(255, 255, 255, .75)',
        [theme.breakpoints.down('sm')]: {
          width: '50% !important',
        },
      },
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  clientLogo: {
    width: 220,
    height: 120,
    margin: '0 auto',
    display: 'flex',
    border: '1px solid #f9f8f8',
    objectFit: 'contain',
    padding: '10px',
    [theme.breakpoints.down('md')]: {
      width: 253,
      height: 253,
    },
  },
  profileButtonWrapper: {
    position: 'absolute',
    top: 0,
    left: '0%',
    right: '50%',
    bottom: 0,
    height: 220,
    width: 220,
    opacity: 0,
    transition: '.5s ease',
    backgroundColor: 'rgba(255, 255, 255, .75)',
    '&:hover': {
      opacity: 0.5,
    },
  },
  editButton: {
    display: 'none',
  },
  deleteButton: {
    display: 'none',
  },
  buttonText: {
    marginLeft: 10,
    textTransform: 'capitalize',
    fontSize: '.8rem',
  },
  deleteText: {
    color: '#DD0000',
    fontSize: '.8rem',
  },
  logoText: {
    marginTop: '1rem',
    marginLeft: '4.1rem',
    marginBottom: 10,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
  colorText: {
    marginTop: '1rem',
    marginBottom: 10,
    textAlign: 'left',
  },
  loadingWrapper: {
    marginTop: 20,
    textAlign: 'center',
  },
  loadingCircle: {
    color: theme.palette.primary.main,
    width: '20px !important',
    height: '20px !important',
    marginLeft: 5,
    marginRight: 5
  },
  switchWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginRight: 26,
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  button: {
    width: 251,
    height: 45,
  },
  submitButton: {
    borderRadius: 4,
    textTransform: 'unset',
  },
}));

const WhiteLabelSection: FC = () => {
  setTitle('CRM | White Label');
  let { isCompactSize }: any = useOutletContext();
  isCompactSize = isCompactSize || false;
  const { organizationID } = useParams();
  const baseUrl = '/erp/crm/organization/details';
  const [organization, setOrganization] = useState<any>({});
  const classes = useStyles();
  const { user } = useAuth();
  const locale = localStorage.getItem('locale');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isColorSchemaSubmitting, setColorSchemaIsSubmitting] = useState<boolean>(false);
  const [clientLogo, setClientLogo] = useState<string>('dummy_image.png');

  const ref = useRef(null);


  const getOrganizationById = async () => {
    try {
      let res = await organizationApi.getById(organizationID);
      if (res.status === 200) {
        res = res?.data;
        setOrganization(res);
        setClientLogo(!isUndefined(res?.logo) ? res?.logo : 'dummy_image.png');
      } else if (res.status === 204) {
        setOrganization({});
      }
    } catch (error) {
      toast.error('Internal Server Error');
      setOrganization({});
    }
  };

  useEffect(() => {
    if (isEmpty(organization)) {
      getOrganizationById();
    }
  }, []);

  const handleFileWindow = () => {
    const element: HTMLElement = document.getElementById('clientLogo') as HTMLElement;
    element.click();
  };

  const [primaryColor, setPrimaryColor] = useColor(!isUndefined(organization?.primaryColor) ? organization?.primaryColor : '#008C3C');
  const [secondaryColor, setSecondaryColor] = useColor(!isUndefined(organization?.secondaryColor) ? organization?.secondaryColor : '#006699');
  const handlePicture = async (e) => {
    try {
      setIsSubmitting(true);
      const image = await userApi.uploadFile(e.target.files[0], 'client', clientLogo);
      setClientLogo(image?.blob_id);
      await organizationApi.updateWhiteLabelInformation(organizationID, {
        logo: image?.blob_url,
        primaryColor: primaryColor?.hex,
        secondaryColor: secondaryColor?.hex
      });
      setIsSubmitting(false);
      const msg = 'Organization Logo Updated Successfully';
      toast.dismiss();
      toast.success(msg, {
        duration: 10000,
      });
    } catch (err) {
      setIsSubmitting(false);
      logger(err, 'error');
      const errorMsg = err?.response?.status === 400 ? 'Only JPEG and PNG files are allowed.' : 'File size should be less than 5 MB.';
      toast.dismiss();
      toast.error(errorMsg, {
        duration: 10000,
      });
    }
  };

  const handleColorSchemaUpdation = async () => {
    try {
      setColorSchemaIsSubmitting(true);
      await organizationApi.updateWhiteLabelInformation(organizationID, {
        logo: clientLogo,
        primaryColor: primaryColor?.hex,
        secondaryColor: secondaryColor?.hex
      });
      setColorSchemaIsSubmitting(false);
      const msg = 'White-label is updated successfully.';
      toast.dismiss();
      toast.success(msg, {
        duration: 10000,
      });
    } catch (err) {
      setColorSchemaIsSubmitting(false);
      logger(err, 'error');
      const errorMsg = 'Internal Server Error';
      toast.dismiss();
      toast.error(errorMsg, {
        duration: 10000,
      });
    }
  };

  console.log(primaryColor);
  console.log(secondaryColor);

  return (
    <Card
      className={classes.root}
    >
      <Grid
        className={classes.wrapper}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
        >
          <Typography
            color="textPrimary"
            variant="h5"
            className={classes.logoText}
          >
            Logo
          </Typography>
          <form>
            <Box
              className={classes.profileWrapper}
              component="div"
            >
              {isSubmitting ? (
                <div
                  className={classes.loadingWrapper}
                >
                  Uploading
                  &nbsp;&nbsp;
                  <CircularProgress className={classes.loadingCircle} />
                </div>
              ) : (
                <>
                  <img
                    className={classes.clientLogo}
                    src={clientLogo === 'dummy_image.png' || !clientLogo ? 'https://assets.it22.nl/erp/admin-panel/dummy_image.png' : `${clientLogo}`}
                    alt="Default Avatar"
                  />
                  <Box
                    className={classes.profileButtonWrapper}
                    component="div"
                    onClick={handleFileWindow}
                  >
                    <Button className={classes.editButton}>
                      <>
                        <input
                          ref={ref}
                          accept="image/jpg,image/jpeg,image/png,.pdf,.doc,.docx"
                          type="file"
                          id="clientLogo"
                          name="file"
                          onChange={handlePicture}
                          hidden
                        />
                        <div style={{ display: 'flex' }}>
                          <svg
                            style={{
                              position: 'relative',
                              top: 2,
                              width: 13,
                            }}
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.2968 8.65963C13.9005 8.65963 13.5801 8.98074 13.5801 9.37626V15.1096C13.5801 15.5045 13.259 15.8262 12.8635 15.8262H2.83017C2.43453 15.8262 2.11355 15.5045 2.11355 15.1096V5.07626C2.11355 4.68141 2.43453 4.35964 2.83017 4.35964H8.56354C8.95984 4.35964 9.28016 4.03854 9.28016 3.64302C9.28016 3.24738 8.95984 2.92627 8.56354 2.92627H2.83017C1.64481 2.92627 0.680176 3.89091 0.680176 5.07626V15.1096C0.680176 16.295 1.64481 17.2596 2.83017 17.2596H12.8635C14.0489 17.2596 15.0135 16.295 15.0135 15.1096V9.37626C15.0135 8.97996 14.6931 8.65963 14.2968 8.65963Z"
                              fill="#000"
                            />
                            <path
                              d="M7.39915 8.00667C7.34902 8.0568 7.31529 8.12058 7.30099 8.18934L6.79433 10.7236C6.77071 10.841 6.80798 10.9621 6.89249 11.0474C6.96059 11.1155 7.05232 11.152 7.14628 11.152C7.16911 11.152 7.19286 11.1499 7.21648 11.1449L9.74992 10.6383C9.82012 10.6238 9.8839 10.5902 9.93337 10.54L15.6036 4.86973L13.0702 2.33643L7.39915 8.00667Z"
                              fill="#000"
                            />
                            <path
                              d="M17.3556 0.58414C16.6569 -0.114635 15.5202 -0.114635 14.8221 0.58414L13.8303 1.57594L16.3638 4.10938L17.3556 3.11744C17.6939 2.77993 17.8802 2.32983 17.8802 1.85112C17.8802 1.37241 17.6939 0.922307 17.3556 0.58414Z"
                              fill="#000"
                            />
                          </svg>
                        </div>
                      </>
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </form>
        </Grid>
        <>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
          >
            <Typography
              color="textPrimary"
              variant="h5"
              className={classes.colorText}
            >
              Primary Colour
            </Typography>
            <ColorPicker
              // width={320}
              // height={110}
              color={primaryColor}
              onChange={setPrimaryColor}
              // onChangeComplete={handleColorSchemaUpdation}
              hideInput={["rgb", "hsv"]}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
          >
            <Typography
              color="textPrimary"
              variant="h5"
              className={classes.colorText}
            >
              Secondary Color
            </Typography>
            <ColorPicker
              // width={320}
              // height={110}
              color={secondaryColor}
              onChange={setSecondaryColor}
              // onChangeComplete={handleColorSchemaUpdation}
              hideInput={["rgb", "hsv"]}
            />
          </Grid>
        </>
      </Grid>
      <Grid
        item
        md={12}
        xs={12}
        className={classes.switchWrapper}
        sx={{ mt: 3, mb: 2, pl: 3, pr: 0 }}
      >
        <Box>
          <Button
            onClick={handleColorSchemaUpdation}
            color="secondary"
            disabled={isColorSchemaSubmitting}
            type="submit"
            variant="contained"
            name="submit"
            className={clsx(classes.button, classes.submitButton)}
          >
            Update Colours
            {isColorSchemaSubmitting && (
              <CircularProgress className={classes.loadingCircle} />
            )}
          </Button>
        </Box>
      </Grid>
    </Card>
  );
};

export default WhiteLabelSection;
