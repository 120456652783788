import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tabs,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import React, { FC, useCallback, useState } from 'react';
import RestartAltIcon from '@material-ui/icons/RestartAlt';
import { useNavigate } from 'react-router-dom';
import { Columns, TableProps } from 'src/types/components/tableList.types';
import Scrollbar from '../../Scrollbar';
import BulkDeactivateDialogue from '../Dialog/BulkDeActivate';
import ToggleStatusDialogue from '../Dialog/ToggleStatus';
import moment from 'moment';
import { isUndefined } from 'lodash';
import SearchBar from '../searchBar/SearchBar';

const tabs = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'inActive',
    value: 'inActive',
  },
];

const useStyles = makeStyles<Theme>(() => createStyles({
  btn: {
    width: '47px',
    height: '30px',
    padding: 0,
    minWidth: 'initial',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    color: '#fff',
    marginLeft: '13px',
    '& .MuiButton-iconSizeMedium': {
      margin: '0',
    },
  },
  textFieldSelect: {
    width: '230px',
    marginLeft: '13px',
  },
  textFieldSelectRoot: {
    borderRadius: '4px',
    height: '30px',
  },
  tabButton: {
    width: '181px',
  },
  cardRoot: {
    borderRadius: '4px',
  },
  tabs: {
    '& .MuiTab-root': {
      backgroundColor: 'transparent',
      minWidth: '120px',
      height: '30px'
    },
    '& .Mui-selected': {
      backgroundColor: '#231F20',
      color: '#fff',
    },
  },
  tableHead: {
    '& .MuiTableCell-root': {
      textTransform: 'capitalize',
    },
  },
  tableRow: {
    '& :hover': {
      cursor: 'pointer',
    },
    '& tabelCheckBox': {
      zIndex: '999',
    },
  },
  activeButton: {
    background: 'rgba(7, 83, 0, 0.1)',
    color: 'rgba(29, 122, 21, 1)',
    width: '100px',
  },
  inActiveButton: {
    background: 'rgba(255, 161, 161, 0.3)',
    color: 'red',
    width: '100px',
  },
  table: {
    '&  .MuiTableCell-head': {
      padding: ' 10px 8px'
    }
  }
}));

const TableHeadComponent: FC<any> = ({ data, getAllSortedData, column, sortVal }: any) => (
  <TableHead sx={{ marginBottom: '1rem' }}>
    <TableRow>
      <TableCell padding="checkbox" />
      {data?.map((col: any) => (
        <TableCell
          align="center"
          className="textBold"
          sortDirection={column === col?.sortName ? sortVal : false}
        >
          {
            col.isSort
              ? (
                <>
                  <TableSortLabel
                    active={column === col?.sortName}
                    direction={column === col?.sortName ? sortVal : 'asc'}
                    onClick={() => {
                      col.toggleSortData(`${col?.id}`);
                      getAllSortedData(`${col?.id}`, col.direction === 'asc' ? 'asc' : 'desc');
                    }}
                  >
                    {col?.name}
                  </TableSortLabel>
                </>
              )
              : col?.name
          }
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

const TableBodyComponent: FC<any> = ({
  permissions,
  bulkIds,
  data,
  editRouteUrl,
  allowedColumns,
  handleAddBulkIds,
  handleRemoveBulkIds,
  handleStatusToggle,
}: any) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [toggleStatusAlert, setToggleStatusAlert] = useState(false);
  const [clickedItem, setClickedItem] = useState(null);
  const [clickedItemStatus, setClickedItemStatus] = useState(null);

  const handleRedirectNext = (id: string) => {
    navigate(`${editRouteUrl}/${id}`);
  };

  const checkValue = (value: any, id: number, status: boolean, columnName?: any, objectKey?: string, wholeObject?: any) => {
    if (value === null || isUndefined(value)) {
      return 'N/A';
    }
    if (columnName === 'name' && !isUndefined(wholeObject.firstName) && !isUndefined(wholeObject.lastName)) {
      return `${wholeObject?.firstName} ${wholeObject?.lastName}`;
    }
    if (columnName === 'createdAt' && !isUndefined(value)) {
      return moment(value).format("DD/MMM/YYYY HH:mm:ss a")
      // const year = moment().format('YYYY');
      // const dutchTimeZoneHours = moment().isBetween(`${year}-03-26`, `${year}-10-29`) ? 2 : 1;

      // return moment(
      //   value
      //     ?.replace('T', ' ')
      //     ?.replace('.000Z', '')
      // )?.utcOffset(dutchTimeZoneHours)?.format('DD MMM, YYYY HH:mm:ss') !== 'Invalid date' ? moment(
      //   value
      //     ?.replace('T', ' ')
      //     ?.replace('.000Z', '')
      // )?.utcOffset(dutchTimeZoneHours)?.format('DD MMM, YYYY HH:mm:ss') : 'N/A';
    }
    if (columnName === 'contactNumber' && !isUndefined(value)) {
      return value[0] ? value[0] : 'N/A';
    }
    if (typeof value === 'boolean') {
      return (
        <Button
          className={value ? classes.activeButton : classes.inActiveButton}
          onClick={(e) => {
            if ((isUndefined(permissions?.toggleStatus) || permissions?.toggleStatus)) {
              e.stopPropagation();
              setClickedItem(id);
              setClickedItemStatus(status);
              setToggleStatusAlert(true);
            }
          }}
        >
          {value ? 'Active' : 'Inactive'}
        </Button>
      );
    }
    if (typeof value === 'object') {
      return value[objectKey] || 'N/A';
    }

    return value;
  };

  return data?.length ? (
    <TableBody>
      {data?.map((row) => {
        const rowValue = Object.keys(row);
        return (
          <React.Fragment key={row.id}>

            <TableRow
              hover
              onClick={() => { if ((isUndefined(permissions?.update) || permissions?.update || isUndefined(permissions?.getById) || permissions?.getById)) { handleRedirectNext(row.id); } }}
              className={classes.tableRow}
            >
              <TableCell
                padding="checkbox"
              >
                <Checkbox
                  checked={bulkIds.includes(row.id)}
                  color="primary"
                  className={classes.tableCheckBox}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      handleAddBulkIds(row.id);
                    } else {
                      handleRemoveBulkIds(row.id);
                    }
                  }}
                />
              </TableCell>
              {allowedColumns.map((column: Columns) => rowValue.includes(column.value) && (
                <TableCell
                  align="center"
                  key={column.name}
                >
                  {checkValue(row[column.value], row.id, row.isActive, column?.value, column?.objectKey, row)}
                </TableCell>
              ))}
            </TableRow>
          </React.Fragment>
        );
      })}
      <TableRow sx={{ display: 'none' }}>
        <TableCell>
          <ToggleStatusDialogue
            isOpen={toggleStatusAlert}
            setToggleStatusAlert={setToggleStatusAlert}
            handleStatusToggle={handleStatusToggle}
            clickedItem={clickedItem}
            clickedItemStatus={clickedItemStatus}
          />
        </TableCell>
      </TableRow>
    </TableBody>
  )
    : (
      <TableBody>
        <TableRow selected>
          <TableCell
            align="center"
            sx={{ textTransform: 'capitalize', textAlign: 'center' }}
            colSpan={Number(allowedColumns?.length + 1)}
          >
            No data found
          </TableCell>
        </TableRow>
      </TableBody>
    );
};

const TableList: FC<TableProps> = ({
  data,
  pageName,
  redirectUrl,
  editRouteUrl,
  setIsActivated,
  count,
  page,
  limit,
  handleLimit,
  handlePage,
  handleBulkDeactivate,
  handleStatusToggle,
  permissions,
  search,
  handleSearch,
  getAllSortedData,
  allowedColumns,
  searchPlaceholder = 'Search',
  setColumn,
  column,
  sortVal,
  setSortVal,
  setSortData
}: TableProps) => {
  const classes = useStyles();
  const [deactivateBulkAlert, setDeactivateBulkAlert] = useState(false);
  const [value, setValue] = useState('all');
  const [bulkIds, setBulkIds] = useState<Array<number>>([]);
  const handleBulkIds = (_data: any) => {
    setBulkIds(_data);
  };
  const navigate = useNavigate();
  const handleRedirectBack = () => {
    navigate(`${redirectUrl}`);
  };

  const handleChangePage = useCallback(
    (event: any, _page: number) => {
      handlePage(_page);
    },
    [page, data]
  );
  const handleChangeLimit = useCallback(
    (event: any) => {
      handleLimit(parseInt(event.target.value, 10));
      handlePage(0);
    },
    [limit, data]
  );
  const handleAddBulkIds = (id: number) => {
    setBulkIds([...bulkIds, id]);
  };
  const handleRemoveBulkIds = (idNum: number) => {
    setBulkIds((current) => current.filter((id) => id !== idNum));
  };

  const paginationLocalStorage = {
    employees: 'employeePagePg',
    contacts: 'contactPagePg',
    contactsByProj: 'contactsByProjPagePg',
    permissions: 'permissionsPagePg',
    roles: 'rolesPagePg',
    modules: 'modulesPagePg'
  };

  const resetDataLocal = {
    contacts: {
      name: 'asc',
      email: 'asc',
      phoneNo: 'asc',
      status: 'asc',
    },
    permissions: {
      identifier: 'asc',
      name: 'asc',
      module: 'asc',
      subModule: 'asc',
      status: 'asc',
    },
    roles: {
      identifier: 'asc',
      name: 'asc',
      userCount: 'asc',
      createdAt: 'asc',
      status: 'asc',
    },
    modules: {
      identifier: 'asc',
      name: 'asc',
      description: 'asc',
      status: 'asc',
    },
  };

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
      }}
      className={classes.table}
    >
      {' '}
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
        >
          {(isUndefined(permissions?.filterTabs) || permissions?.filterTabs) && (
            <Tabs
              indicatorColor="primary"
              scrollButtons="auto"
              textColor="primary"
              value={value}
              variant="scrollable"
              className={classes.tabs}
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab?.value}
                  label={tab?.label}
                  value={tab?.value}
                  onClick={() => {
                    setValue(tab?.value);
                    switch (tab?.value) {
                      case 'active':
                        setIsActivated(true);
                        handlePage(0);
                        break;
                      case 'inActive':
                        setIsActivated(false);
                        handlePage(0);
                        break;
                      case 'all':
                        setIsActivated(null);
                        handlePage(0);
                        break;
                      default:
                    }
                  }}
                />
              ))}
            </Tabs>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <SearchBar
            value={search}
            onSearch={(val) => { handleSearch(val); handlePage(0); }}
            placeholderData={searchPlaceholder}
          />
          {(isUndefined(permissions?.create) || permissions?.create) && (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddCircleIcon />}
              className={classes.btn}
              onClick={handleRedirectBack}
            />
          )}
          <Button
            variant="contained"
            color="secondary"
            startIcon={<RestartAltIcon />}
            className={classes.btn}
            onClick={() => {
              localStorage.removeItem(paginationLocalStorage[pageName]);
              handleLimit(25);
              handlePage(0);
              handleSearch('');
              if (sortVal) {
                setSortVal('');
              }
              if (column) {
                setColumn('');
              }
              if (setSortData) {
                setSortData(resetDataLocal[pageName]);
              }
            }}

          />
        </Grid>
      </Grid>
      <Card className={classes.cardRoot}>
        <Scrollbar>
          <Box sx={{ minWidth: 700 }}>
            <Table>
              <TableHeadComponent
                data={allowedColumns}
                column={column}
                sortVal={sortVal}
                getAllSortedData={getAllSortedData}
              />

              <TableBodyComponent
                permissions={permissions}
                bulkIds={bulkIds}
                data={data}
                editRouteUrl={editRouteUrl}
                allowedColumns={allowedColumns}
                handleAddBulkIds={handleAddBulkIds}
                handleRemoveBulkIds={handleRemoveBulkIds}
                handleStatusToggle={handleStatusToggle}
              />

            </Table>
          </Box>
        </Scrollbar>
        <TablePagination
          component="div"
          count={count}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeLimit}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 100, 500, 1000]}
        />
      </Card>
      <BulkDeactivateDialogue
        setBulkIds={handleBulkIds}
        bulkIds={bulkIds}
        isOpen={deactivateBulkAlert}
        setDeactivateBulkAlert={setDeactivateBulkAlert}
        handleBulkDeactivate={handleBulkDeactivate}
      />
    </Box>
  );
};

export default TableList;
