import { Box } from '@material-ui/core';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import type { FC } from 'react';

const PriorityComponent: FC<any> = ({ label }: any) => {
  const styling = {
    icon: <RadioButtonUncheckedIcon />
  };

  switch (label?.toLowerCase()) {
    case 'highest':
      styling.icon = <DoubleArrowIcon sx={{ fontSize: '16px', color: '#f55033', transform: 'rotate(270deg)' }} />;
      break;
    case 'medium':
      styling.icon = <DragHandleIcon sx={{ fontSize: '24px', width: '18px', color: '#f79d28' }} />;
      break;
    case 'minor':
      styling.icon = <RadioButtonUncheckedIcon sx={{ fontSize: '14px' }} />;
      break;
    case 'blocker':
      styling.icon = <RemoveCircleIcon sx={{ fontSize: '16px', color: '#f55033' }} />;
      break;
    case 'high':
      styling.icon = <ExpandLessIcon sx={{ fontSize: '24px', color: '#f55033' }} />;
      break;
    case 'low':
      styling.icon = <ExpandMoreIcon sx={{ fontSize: '24px', color: '#3384f5' }} />;
      break;
    case 'lowest':
      styling.icon = <DoubleArrowIcon sx={{ fontSize: '16px', color: '#3384f5', transform: 'rotate(90deg)' }} />;
      break;
    default:
      break;
  }
  return (
    <>
      <Box
        sx={{
          height: '30px',
          background: 'rgba(189,189,189,0.1)',
          color: '#000000aa',
          width: '100px',
          display: 'flex',
          alignItems: 'center',
          gap: '6px',
          textTransform: 'capitalize',
          justifyContent: 'center'
        }}
      >
        {styling.icon}
        {label?.toLowerCase()}
      </Box>
    </>
  );
};

export default PriorityComponent;
