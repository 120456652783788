/* eslint-disable no-underscore-dangle */
import CustomListView from 'src/components/customTable/CustomListView';
// import myData from '../../__fakeApi__/tempdata';
import CreateFormSideBar from 'src/components/widgets/CreateFormSideBar/CreateFormSideBar';
import { useEffect, useState } from 'react';
import CreateProductForm from './CreateProductForm';
import * as Yup from 'yup';
import {
  Grid,
  Button,
  Container,
  Dialog,
  Box,
  Tab,
  Tabs,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import UploadIcon from '@material-ui/icons/Upload';
import useSettings from 'src/hooks/useSettings';
import CsvDropzone from './CsvDropzone';
import HistoryIcon from '@material-ui/icons/History';
import { useDispatch, useSelector } from 'react-redux';
import {
  createNewProduct,
  editProduct,
  fetchAllProducts,
  getAllCategories,
  getAllDistributors,
  getAllMeasurement,
  getAllSuppliers,
} from 'src/store/slices/pimModuleSlice';
import { AppDispatch } from 'src/store/store';
import ToggleStatusDialogue from './ToggleStatus';
import AuditLogComponent from './auditLog';
import SearchBar from 'src/components/widgets/searchBar/SearchBar';
import setTitle from 'src/utils/setTitle';
import RestartAltIcon from '@material-ui/icons/RestartAlt';
import { useParams } from 'react-router';
import SimpleBreadCrumb from 'src/components/widgets/BreadCrumb/SimpleBreadCrumb';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    btn: {
      width: '47px',
      height: '30px',
      padding: 0,
      minWidth: 'initial',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '4px',
      color: '#fff',
      marginLeft: '13px',
      '& .MuiButton-iconSizeMedium': {
        margin: '0',
      },
    },
    activeButton: {
      background: 'rgba(7, 83, 0, 0.1)',
      color: 'rgba(29, 122, 21, 1)',
      width: '100px',
    },
    inActiveButton: {
      background: 'rgba(255, 161, 161, 0.3)',
      color: 'red',
      width: '100px',
    },
    tabs: {
      '& .MuiTab-root': {
        backgroundColor: 'transparent',
        minWidth: '120px',
      },
      '& .Mui-selected': {
        backgroundColor: '#231F20',
        color: '#fff',
      },
    },
  })
);
const ProductInformationManagement = () => {
  interface Product {
    _id: string;
    productSKU: string;
    name: string;
    productCurrency: string;
    productPrice1M: number;
    productPrice1Y: number;
    productPrice3Y: number;
    productCategory: string;
    productSockets: number;
    productCores: number;
    productNodes: number;
    productSupportType: string;
    productVirtualGuest: string;
    productMeasurement: string;
    quarter: string;
    isActive: boolean;
    createdBy: {
      _id: string | null;
    };
    updatedBy: Record<string, unknown>; // Assuming updatedBy can be any object
    createdAt: string;
    updatedAt: string;
    distributorId: string | null;
  }
  setTitle('ERP | Product Information Management');

  const classes = useStyles();
  const [createFormOpened, setCreateFormOpened] = useState(false);
  const [editFormOpened, setEditFormOpened] = useState(false);
  const [auditLogOpened, setAuditLogOpened] = useState(false);
  const [tabValue, setTabValue] = useState('');
  const [rowData, setRowData] = useState<Product>({
    _id: '',
    productSKU: '',
    name: '',
    productCurrency: '',
    productPrice1M: 0,
    productPrice1Y: 0,
    productPrice3Y: 0,
    productCategory: '',
    productSockets: 0,
    productCores: 0,
    productNodes: 0,
    productSupportType: '',
    productVirtualGuest: '',
    productMeasurement: '',
    quarter: '',
    isActive: false,
    createdBy: {
      _id: null,
    },
    updatedBy: {},
    createdAt: '',
    updatedAt: '',
    distributorId: null,
  });
  const [selectedProductId, setSelectedProductId] = useState('');


  const { catalogueName, categoryID } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const allProducts = useSelector((state: any) => state.pim.totalProducts);
  const allCategories = useSelector((state: any) => state.pim.allCategories);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [page, setPage] = useState<any>(
    JSON.parse(localStorage.getItem('pimPagePg'))?.pageNo || 0
  );
  const [pageSize, setPageSize] = useState<any>(
    JSON.parse(localStorage.getItem('pimPagePg'))?.pageLimit || 25
  );
  const [search, setSearch] = useState<string>(
    JSON.parse(localStorage.getItem('pimPagePg'))?.search || ''
  );
  const [selectedSort, setSelectedSort] = useState<any>(
    JSON.parse(localStorage.getItem('pimPagePg'))?.sortColumn || {
      id: '',
      value: 'asc',
    });
  const [open, setOpen] = useState(false);

  const [confirmStatus, setConfirmStatus] = useState(false);
  const [updateItem, setUpdateItem] = useState(null);

  const tabs = [
    {
      label: 'ALL',
      value: '',
    },
    {
      label: 'ACTIVE',
      value: 'active',
    },
    {
      label: 'INACTIVE',
      value: 'inActive',
    },
  ];

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseCreateDrawer = () => {
    setCreateFormOpened(false);
  };

  const handleOpenCreateDrawer = () => {
    setCreateFormOpened(true);
  };

  const handleOpenAuditLogs = (e, data) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedProductId(data._id);
    setAuditLogOpened(true);
  };
  const handleCloseAuditLogs = () => {
    setAuditLogOpened(false);
  };

  const handleCloseEditDrawer = () => {
    setEditFormOpened(false);
  };

  const handleOpenEditDrawer = (data) => {
    setRowData(data);
    setEditFormOpened(true);
  };

  const confirmStatusChange = (e, item) => {
    e.stopPropagation();
    e.preventDefault();
    setConfirmStatus(true);
    setUpdateItem(item);
  };

  const handleUpdateStatus = (data) => {
    const apiPayload = { isActive: !data.isActive };
    const payload = {
      id: data._id,
      data: apiPayload,
    };
    dispatch(editProduct(payload));
  };

  const handleReset = () => {
    localStorage.removeItem('pimPagePg');
    setSearch('');
    setPageSize(25);
    setPage(0);
    setSelectedSort({
      id: '',
      value: 'asc',
    });
  };
  const { settings } = useSettings();
  const catagoryCellRenderer = (id, row) => {
    const catagory = allCategories?.find((x) => x._id === row.productCategory);
    return catagory ? catagory.name : '-';
  };

  const logButtonRendered = (id, row) => (
    <Button
      onClick={(e) => {
        handleOpenAuditLogs(e, row);
      }}>
      <HistoryIcon />
    </Button>
  );

  const statusCellRenderer = (id, row) => (
    <Button
      className={row.isActive ? classes.activeButton : classes.inActiveButton}
      onClick={(e) => {
        confirmStatusChange(e, row);
      }}>
      {row.isActive ? 'Active' : 'In-Active'}
    </Button>
  );
  const columns = [
    { id: 'productSKU', label: 'SKU' },
    { id: 'name', label: 'Product Name', isSort: true },
    { id: 'productCurrency', label: 'Currency' },
    { id: 'productPrice1M', label: 'Monthly MSRP' },
    { id: 'productPrice1Y', label: '1 Year MSRP' },
    { id: 'productPrice3Y', label: '3 Year MSRP' },
    {
      id: 'productCategory',
      label: 'Categories',
      Cell: (val, row) => catagoryCellRenderer(val, row),
    },
    { id: 'productSockets', label: 'Sockets' },
    { id: 'productCores', label: 'Cores' },
    { id: 'productNodes', label: 'Nodes' },
    { id: 'productSupportType', label: 'Support-Type' },
    { id: 'productVirtualGuest', label: 'Virtual Guests' },
    { id: 'productMeasurement', label: 'Unit' },
    {
      id: 'status',
      label: 'Status',
      Cell: (val, row) => statusCellRenderer(val, row),
    },
    {
      id: 'logButton',
      label: 'History',
      Cell: (val, row) => logButtonRendered(val, row),
    },
  ];

  const createProductFormSchema = Yup.object().shape({
    name: Yup.string()
      .nullable()
      .max(255, 'Exceed Limit of 255')
      .required('Name is required'),
    category: Yup.object().nullable().required('Category is required'),
    // distributor: Yup.object().nullable().required('Distributor is required'),
    sku: Yup.string()
      .nullable()
      .required('SKU is required')
      .max(13, 'Exceed Limit of 13 characters')
      .test(
        'no-spaces',
        'SKU cannot contain spaces',
        (value) => !/\s/.test(value)
      ),
    cores: Yup.number()
      .nullable()
      .min(0, 'The minimum amount is 0')
      .required('No. of Cores is required'),
    nodes: Yup.number()
      .nullable()
      .min(0, 'The minimum amount is 0')
      .required('No. of Nodes is required'),
    sockets: Yup.number()
      .nullable()
      .min(0, 'The minimum amount is 0')
      .required('No. of Sockets is required'),
    // vGuests: Yup.number()
    //   .nullable()
    //   .min(1, 'The minimum amount is 1')
    //   .required('Virtual Guests is required'),
    price: Yup.number()
      .nullable()
      .min(1, 'The minimum amount is 1')
      .required('Price is required'),
    supportType: Yup.string().nullable().required('Support type is required'),
    unitOfMeasure: Yup.object()
      .nullable()
      .required('Unit of Measure is required'),
  });

  const getAllProducts = () => {
    const isActive = (tabVal) => {
      switch (tabVal) {
        case '':
          return null;
        case 'active':
          return true;
        case 'inActive':
          return false;
        default:
          return null;
      }
    };

    const apiPayload = {
      // categoryId: categoryID,
      page,
      pageSize,
      search,
      isActive: isActive(tabValue),
      column: selectedSort?.id || 'defaultSort',
      sort: selectedSort?.value === 'asc' ? '1' : '-1',
    };
    const pimPagePg = {
      pageLimit: pageSize,
      pageNo: page,
      search,
      sortColumn: selectedSort,
    };
    localStorage.setItem('pimPagePg', JSON.stringify(pimPagePg));
    const keys = Object.keys(apiPayload);
    const values = Object.values(apiPayload);

    // Create an array to store the non-empty key-value pairs
    const queryParams = [];
    for (let i = 0; i < values.length; i++) {
      if (values[i] !== '' && values[i] !== null && values[i] !== undefined) {
        queryParams.push(`${encodeURIComponent(keys[i])}=${values[i]}`);
      }
    }
    const queryString = queryParams.join('&');
    dispatch(fetchAllProducts(`/${categoryID}?${queryString}`)).then((res) => {
      // console.log(res, allProducts);
      setTotalCount(res?.payload?.totalRecords || 0);
    });
  };

  const createProduct = (values: unknown) => {
    dispatch(createNewProduct(values)).then(() => {
      getAllProducts();
      setCreateFormOpened(false);
    });
  };
  const EditProductFunc = (values: any) => {
    const priceCalc = (duration, price) => {
      const obj = {
        monthly: {
          productPrice1M: price,
          productPrice1Y: 0,
          productPrice3Y: 0,
        },
        yearly: {
          productPrice1M: 0,
          productPrice1Y: price,
          productPrice3Y: 0,
        },
        threeYearly: {
          productPrice1M: 0,
          productPrice1Y: 0,
          productPrice3Y: price,
        },
      };

      return obj[duration];
    };

    const pricesObj = priceCalc(values.priceDuration, values.price);

    const myData = {
      name: values.name,
      description: 'string',
      productSKU: values.sku,
      productCurrency: values.currency,
      productSockets: values.sockets,
      productCategory: values.category?._id,
      productCores: values.cores,
      productNodes: values.nodes,
      productSupportType: values.supportType,
      productMeasurement: values.unitOfMeasure?.name,
      distributorId: values.distributor?._id,
      productVirtualGuest: values.vGuests,
      quarter: '1st',
      isActive: values.status === 'active',
      ...pricesObj,
    };
    const changedFields = {};
    Object.keys(myData).forEach(key => {
      if (myData[key] !== rowData[key]) {
        changedFields[key] = myData[key];
      }
    });
    const payload = {
      id: rowData._id,
      data: {
        ...changedFields,
      },
    };
    dispatch(editProduct(payload)).then(() => {
      getAllProducts()
    });
    handleCloseEditDrawer();
  };

  const handleSearch = (val) => {
    setSearch(val);
  };
  useEffect(() => {
    dispatch(getAllCategories());
    dispatch(getAllSuppliers());
    dispatch(getAllMeasurement());
    dispatch(getAllDistributors());
  }, []);
  useEffect(() => {
    getAllProducts();
  }, [page, pageSize, search, tabValue, selectedSort]);
  const categoryName = allCategories?.find((x) => x._id === categoryID)
  const BreadCrumbsData = {
    title: 'PIM',
    links: [
      {
        name: 'catalogues',
        to: '/pim/catalogues',
      },
      {
        name: `${catalogueName}`,
        to: `/pim/catalogues/${catalogueName}`,
      },
      {
        name: `${categoryName?.name}`,
        to: `/pim/catalogues/${catalogueName}`,
      },
    ],
  };
  return (
    <Container
      maxWidth={settings.compact ? 'xl' : false}
      sx={{
        py: 4,
      }}>
      <Grid container justifyContent="space-between" spacing={3}>
        <Grid
          item
          sx={{
            mb: 1,
          }}>
          <Grid item md={12}>
            <SimpleBreadCrumb data={BreadCrumbsData} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <Tabs
            indicatorColor="primary"
            scrollButtons="auto"
            textColor="primary"
            value={tabValue}
            variant="scrollable"
            className={classes.tabs}>
            {tabs.map((tab) => (
              <Tab
                // key={tab?.value}
                label={tab?.label}
                value={tab?.value}
                onClick={() => {
                  setTabValue(tab?.value);
                }}
              />
            ))}
          </Tabs>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: '10px',
          }}>
          <SearchBar onSearch={handleSearch} value={search} placeholderData="Search by SKU or Name" />

          {' '}
          <Button
            variant="contained"
            color="secondary"
            title="Reset"
            startIcon={<RestartAltIcon />}
            className={classes.btn}
            onClick={() => {
              handleReset();
            }}
          />
          <Button
            variant="contained"
            color="secondary"
            endIcon={<UploadIcon />}
            // className={classes.btn}
            onClick={handleOpen}
            sx={{ height: 30 }}>
            Upload
          </Button>
          <Button
            variant="contained"
            color="secondary"
            endIcon={<AddCircleIcon />}
            // className={classes.btn}
            sx={{ height: 30 }}
            onClick={handleOpenCreateDrawer}>
            Create Product
          </Button>
        </Grid>
      </Grid>
      <CustomListView
        onRowClick={handleOpenEditDrawer}
        columns={columns}
        data={allProducts.data || []}
        page={page}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setPage={setPage}
        count={totalCount}
        selectedSort={selectedSort}
        setSelectedSort={setSelectedSort}
      />
      {auditLogOpened && (
        <CreateFormSideBar
          isOpen={auditLogOpened}
          closeDrawer={handleCloseAuditLogs}
          title="History">
          <AuditLogComponent productId={selectedProductId} />
        </CreateFormSideBar>
      )}

      {createFormOpened && (
        <CreateFormSideBar
          isOpen={createFormOpened}
          closeDrawer={handleCloseCreateDrawer}
          title="Create Product">
          <CreateProductForm
            closeDrawer={handleCloseCreateDrawer}
            onSubmit={createProduct}
            schema={createProductFormSchema}

          />
        </CreateFormSideBar>
      )}

      {editFormOpened && (
        <CreateFormSideBar
          isOpen={editFormOpened}
          closeDrawer={handleCloseEditDrawer}
          title="Edit Product">
          <CreateProductForm
            closeDrawer={handleCloseEditDrawer}
            onSubmit={EditProductFunc}
            schema={createProductFormSchema}
            formData={rowData}
            editForm
          />
        </CreateFormSideBar>
      )}

      {confirmStatus && (
        <ToggleStatusDialogue
          isOpen={confirmStatus}
          data={updateItem}
          close={() => setConfirmStatus(false)}
          handleConfrim={handleUpdateStatus}
        />
      )}
      {open && (
        <Dialog onClose={handleClose} open={open} fullWidth>
          <Box style={{ padding: '0px 20px 20px 20px' }}>
            <p style={{ fontWeight: '700', fontSize: '20px' }}>Upload File</p>

            <CsvDropzone closeDialog={handleClose} />
          </Box>
        </Dialog>
      )}
    </Container>
  );
};

export default ProductInformationManagement;
