import { Button, Typography } from '@material-ui/core';
import { FC, useEffect, useState, useRef } from 'react';
import { DateRange } from 'react-date-range';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddNotes from './AddNotes';
import { notesApi } from 'src/api/notesApi';
import toast from 'react-hot-toast';
import FilterAltIcon from '@material-ui/icons/FilterAlt';
import CloseIcon from '@material-ui/icons/Close';
import NoteCard from './NoteCard';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles<Theme>(() => createStyles({
  notes: {
    '& .filterArea': {
      display: 'flex',
      justifyContent: 'flex-end',
      position: 'relative',
      '& .rdrCalendarWrapper': {
        position: 'absolute',
        zIndex: '999',
        right: '83px'

      },

    },
    '& .noteCardActions': {
      '& .MuiSelect-select': {
        marginLeft: '80px',
      }
    },
    padding: '0 10px',
    '& .addNotes': {
      height: 30,
      width: '30px',
      borderRadius: '5px',
      minWidth: 'initial',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: '10px',
      marginBottom: '10px',
      '& .MuiButton-startIcon': {
        margin: 0
      }
    },
    '& .notesInner+.notesInner': {
      marginTop: '20px'
    },
    '& .notesInner': {
      margin: '0 0px 10px 0',
      background: '#FFFFFF',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
      '& .MuiAccordion-root': {
        borderTop: 0,
        margin: '0 10px 0 0'
      },
      '& .Mui-expanded': {
        margin: '12px 0px',
        '& .MuiAccordionSummary-expandIconWrapper': {
          margin: '0 10px 0 0'
        }
      },
      '& .accordionSummary': {
        margin: '5px 0 5px 0',

      },
      '& .MuiAccordionSummary-content': {
        margin: '12px 0'
      },
    },
    '& .createButton': {
      display: 'flex',
      marginLeft: 'auto',
      width: '30px',
      marginTop: '10px',
    },
  },

  addButton: {
    float: 'right',
    width: '30px',
    height: 30,
    borderRadius: '5px',
    minWidth: 'initial',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiButton-startIcon': {
      margin: 0
    }
  }
}));
const NoteSection: FC<any> = () => {
  const { organizationID, projectID } = useParams();
  const [addNotes, setAddNotes] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [notes, setNotes] = useState<Array<any>>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(new Date().setDate(new Date().getDate() + 30)),
      key: 'selection'
    }
  ]);
  const ref = useRef(null);
  const classes = useStyles();
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  const getbNotesByOrganizationId = async () => {
    try {
      const res = await notesApi.getNotesByOrganizationId(
        organizationID,
        projectID
      );
      if (res?.data?.data) {
        setNotes(res?.data?.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Internal Server Error');
    }
  };
  useEffect(() => {
    getbNotesByOrganizationId();
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [addNotes, isUpdate, state[0].startDate, state[0].endDate]);

  return (
    <div className={classes.notes}>
      <div className="filterArea">
        {isOpen && (
          <div ref={ref}>
            <DateRange
              ranges={state}
              onChange={(item) => setState([item.selection])}
              editableDateInputs
            />
          </div>
        )}
        <Button
          variant="contained"
          color="secondary"
          className={classes.addButton}
          onClick={handleToggle}
        >
          {!isOpen ? <FilterAltIcon /> : <CloseIcon />}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<AddCircleIcon />}
          className="addNotes"
          onClick={() => setAddNotes(!addNotes)}
        />
      </div>
      {addNotes && <AddNotes setAddNotes={setAddNotes} />}
      { Object.keys(notes).length === 0 ? (
        <Typography
          color="textPrimary"
          variant="h4"
          sx={{ textAlign: 'center' }}
        >
          No Records Found
        </Typography>
      ) : (
        notes?.map((notesData) => (
          <div className="notesInner">
            <NoteCard
              notesData={notesData}
              setIsUpdate={setIsUpdate}
              isUpdate={isUpdate}
            />
          </div>
        )))}

    </div>
  );
};

export default NoteSection;
