import { Box, Grid } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SimpleBreadCrumb from 'src/components/widgets/BreadCrumb/SimpleBreadCrumb';
import setTitle from 'src/utils/setTitle';
import * as Yup from 'yup';
import { userApi } from 'src/api/userApi';
import logger from 'src/utils/logger';
import { User } from 'src/types/user';
import toast from 'react-hot-toast';
import EditUserForm from 'src/components/users/EditUserForm';
import { rolesApi } from 'src/api/rolesApi';

const EditUser: FC = () => {
  setTitle('CRM | Users - Edit');
  const { organizationID, projectID, userID } = useParams();
  const baseUrl = '/erp/crm/organization/details';
  const [roles, setRoles] = useState<any>([]);

  const INITIAL_VALUES = {
    firstName: '',
    lastName: '',
    designation: '',
    roleId: '',
    email: '',
    contactNumber: '',
    avatar: '',
    info: '',
  };
  const [userData, setUserData] = useState<User>(INITIAL_VALUES);
  const InitialValues = {
    firstName: userData?.firstName || '',
    lastName: userData?.lastName || '',
    designation: userData?.designation || '',
    roleId: userData?.roleId || '',
    email: userData?.email || '',
    phoneNumber: userData?.contactNumber[0] || '',
    image: userData?.avatar || ''
  };

  const previewData = [
    {
      name: 'Edit User',
      fields: [
        {
          id: '1',
          name: 'firstName',
          label: 'First Name',
          placeholder: 'First Name',
          type: 'text',
          required: true,
          fullWidth: true
        },
        {
          id: '2',
          name: 'lastName',
          label: 'Last Name',
          placeholder: 'Last Name',
          type: 'text',
          required: true,
          fullWidth: true
        },
        {
          id: '4',
          name: 'designation',
          label: 'Designation',
          placeholder: 'Designation',
          type: 'text',
          required: true,
          fullWidth: true
        },
        {
          id: '5',
          name: 'roleId',
          label: 'Role',
          placeholder: 'Role',
          type: 'dropDownSimple',
          required: true,
          columnSize: 6,
          array: roles
        },
        {
          id: '6',
          name: 'email',
          label: 'Email',
          placeholder: 'Email',
          type: 'email',
          required: true,
          fullWidth: true
        },
        {
          id: '7',
          name: 'phoneNumber',
          label: 'Phone Number',
          placeholder: 'Phone Number',
          type: 'text',
          required: true,
          fullWidth: true
        },
        {
          id: '8',
          name: 'image',
          label: 'Image',
          placeholder: 'Image',
          type: 'file',
          required: true,
          fullWidth: true
        }
      ]
    }
  ];

  const Schema = Yup.object({
    firstName: Yup.string()
      .trim()
      .required('First name required')
      .test(
        'Field must not contain only spaces',
        (value) => value && value.length > 0
      ),
    lastName: Yup.string()
      .trim()
      .required('Last name required')
      .test(
        'Field must not contain only spaces',
        (value) => value && value.length > 0
      ),
    email: Yup.string().email().required('Email required'),
    phoneNumber: Yup.string()
      .trim()
      .required('Phone number required')
      .test(
        'Field must not contain only spaces',
        (value) => value && value.length > 0
      ),
    designation: Yup.string()
      .trim()
      .min(3, 'Minimum 3 characters required').max(100, 'Maximum 100 characters allowed')
      .required('Designation required')
      .test(
        'Field must not contain only spaces',
        (value) => value && value.length > 0
      ),
    roleId: Yup.string()
      .trim()
      .required('Role required'),
    image: Yup.string().required('Image url required'),
    description: Yup.string().max(2000, 'Maximum 2000 characters allowed')
  });

  const handleOnSubmit = async (values: any) => {
    try {
      const payload = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        designation: values?.designation.trim(),
        roleId: values?.roleId,
        email: values?.email,
        contactNumber: values?.phoneNumber,
        avatar: values?.image
      };
      const response = await userApi.UpdateUserById(userID, payload);
      if (response?.status === 200) {
        toast.success('updated successfully');
      }
    } catch (error) {
      if (error?.response?.status === 409) {
        toast.error(`${error?.response?.data?.message}`);
      } else {
        toast.error('Internal Server Error');
      }
    }
  };

  const BreadCrumbsData = {
    title: '',
    links: [
      {
        name: 'users',
        to: `${baseUrl}/${organizationID}/project/${projectID}/user`
      },
      {
        name: 'Edit user',
        to: ''
      }
    ]
  };

  const getUserById = async () => {
    try {
      const res = await userApi.getById(`${userID}`);
      setUserData(res);
      // if (response?.status == 200) {
      // }
    } catch (error) {
      logger(error, 'error');
    }
  };

  const getAllRoles = async () => {
    try {
      const response = await rolesApi.getAllActiveRoles();
      if (response?.status === 200) {
        setRoles(response?.data);
      } else {
        setRoles([]);
      }
    } catch (error) {
      setRoles([]);
    }
  };

  useEffect(() => {
    getAllRoles();
    getUserById();
  }, []);
  return (
    <>
      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 8 }}
      >
        <Grid
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <SimpleBreadCrumb data={BreadCrumbsData} />
          </Grid>
        </Grid>
        <Box
          sx={{ mt: 3 }}
          className="EditUserForm"
        >
          <EditUserForm
            previewer={previewData}
            schema={Schema}
            handleOnSubmit={handleOnSubmit}
            initialValues={InitialValues}
            returnUrl={`${baseUrl}/${organizationID}/project/${projectID}/user`}
          />
        </Box>
      </Box>
    </>
  );
};

export default EditUser;
