import { Box, Button, InputLabel, TextField, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import SimpleBreadCrumb from 'src/components/widgets/BreadCrumb/SimpleBreadCrumb';
import ConfigurationOrgListTable from 'src/components/widgets/tables/ConfigurationOrgListTable';

const useStyles = makeStyles<Theme>(() => createStyles({
  paper: {
    backgroundColor: '#fff',
    padding: '10px 80px 30px 80px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
  },
  fieldsRoot: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '238px',
    gridRowGap: '16px',

    '& .item': {
      '& .MuiInputLabel-root': {
        fontSize: '14px',
        fontWeight: 600,
        color: '#231f20',
      },
    }
  },
  checkboxRoot: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    rowGap: '16px',

    '& .MuiFormControlLabel-root': {
      minWidth: '240px',
    },
    '& .MuiTypography-body1': {
      fontSize: '14px',
      fontWeight: 400,
      color: '231f20',
    },
    '& .MuiSvgIcon-root': {
      width: '16px',
      height: '16px',
    },
    '& .flexGrow': {
      flexGrow: 1,
    }
  },
  btnRoot: {
    display: 'flex',
    marginTop: '30px',
    gap: '9px',
    // flexDirection: 'row-reverse',
  }
}));
const ConfigurationAdd = () => {
  const baseUrl = '/erp/settings';
  const classes = useStyles();
  const data = [
    {
      identifier: 'sheryar ahmed',
      name: 'sheryar ahmed',
      description: 'sheryar Ahmed',

      website: 'https://portal.it22.nl/',
      status: true
    },
    {
      identifier: 'sheryar ahmed',
      name: 'sheryar ahmed',
      description: 'sheryar Ahmed',

      website: 'https://portal.it22.nl/',
      status: false
    },
  ];
  const rolesBaseUrl = '/erp/settings/configuration';
  const columns = [
    {
      name: 'Name',
      value: 'name',
    },
    {
      name: 'Description',
      value: 'description',
    },
    {
      name: 'Website',
      value: 'website',
    },

    {
      name: 'Status',
      value: 'status',
    },
  ];
  const BreadCrumbsData = {
    title: '',
    links: [
      {
        name: 'configuration',
        to: `${baseUrl}/configuration`
      },
      {
        name: 'add configuration',
        to: ''
      }
    ]
  };
  return (
    <>
      <SimpleBreadCrumb data={BreadCrumbsData} />
      <Typography
        variant="h3"
        style={{ marginTop: '16px', marginBottom: '10px', fontSize: '20px', fontWeight: '700', color: '#000', }}
      >
        Add configuration
      </Typography>
      <Box className={`${classes.paper} ${classes.fieldsRoot}`}>
        <Box className="item">
          <InputLabel htmlFor="identifier-simple">Identifier</InputLabel>
          <TextField
            id="identifier-simple"
            fullWidth
            placeholder="Identifier"
            size="small"
          />
        </Box>
        <Box className="item">
          <InputLabel htmlFor="permission-name-simple"> Name</InputLabel>
          <TextField
            id="permission-name-simple"
            fullWidth
            placeholder="Name"
            size="small"
          />
        </Box>
        <Box className="item">
          <InputLabel htmlFor="module-simple">User</InputLabel>
          <TextField
            id="module-simple"
            fullWidth
            placeholder="User"
            size="small"
          />
        </Box>
        <Box className="item">
          <InputLabel htmlFor="sub-module-simple">Role</InputLabel>
          <TextField
            id="sub-module-simple"
            fullWidth
            placeholder="Role"
            size="small"
          />
        </Box>
      </Box>
      <Typography
        variant="h3"
        style={{ marginTop: '16px', marginBottom: '10px', fontSize: '20px', fontWeight: '700', color: '#000', }}
      >
        Organization list
      </Typography>

      <ConfigurationOrgListTable
        data={data}
        allowedColumns={columns}
        redirectUrl={`${rolesBaseUrl}`}
        editRouteUrl={`${rolesBaseUrl}`}
      />
      <Typography
        variant="h3"
        style={{ marginTop: '16px', marginBottom: '10px', fontSize: '20px', fontWeight: '700', color: '#000', }}
      >
        Project list
      </Typography>

      <ConfigurationOrgListTable
        data={data}
        allowedColumns={columns}
        redirectUrl={`${rolesBaseUrl}`}
        editRouteUrl={`${rolesBaseUrl}`}
      />

      <Box className={classes.btnRoot}>
        <Link
          to={`${baseUrl}/permissions`}
          style={{ marginLeft: 'auto', }}
        >
          <Button
            variant="outlined"
            color="primary"
            sx={{ height: '38px' }}
          >
            Cancel
          </Button>
        </Link>
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          sx={{ height: '38px' }}
        >
          Save
        </Button>
      </Box>
    </>
  );
};

export default ConfigurationAdd;
