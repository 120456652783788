/* eslint-disable no-nested-ternary */
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Container, Grid, Tab, Tabs } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { EmployeeGrid } from './EmployeeGrid';
import EmployeeGraph from './EmployeeGraph';
import { okrApi } from 'src/api/okrApi';
import toast from 'react-hot-toast';
import EmployeeHourlyGraph from './EmployeeHourlyGraph';

const tabs = [
  {
    label: 'Graph',
    value: 'graph',
  },
  {
    label: 'Summary',
    value: 'summary',
  },
  {
    label: 'Hourly',
    value: 'hourly',
  },
];
const useStyles = makeStyles<Theme>(
  () =>
    createStyles({
      btn: {
        padding: 0,
      },
      tabs: {
        '& .MuiTab-root': {
          backgroundColor: 'transparent',
          minWidth: '120px',
        },
        '& .Mui-selected': {
          backgroundColor: '#231F20',
          color: '#fff',
        },
      },
    }),
  {
    name: 'EmployeeSummary',
  }
);
function EmployeeSummary() {
  const classes = useStyles();
  const [value, setValue] = useState('graph');
  const [groupData, setGroupData] = useState({});

  const groupDataByDepartment = (data: any[]) => {
    const groupedData = data.reduce((groups, item) => {
      const { subDepartment } = item;
      if (!groups[subDepartment]) {
        groups[subDepartment] = [];
      }
      groups[subDepartment].push(item);
      return groups;
    }, {});
    return groupedData;
  };
  const getEmployeeData = async () => {
    // setDepartmentData(myData.EMPLOYEE);
    try {
      const response = await okrApi.getEmployeeSummary();
      setGroupData(groupDataByDepartment(response.data));
      // setDepartmentData(response.data);
    } catch (error) {
      toast.error(error.message);
    }
  };
  useEffect(() => {
    getEmployeeData();
  }, []);
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 4,
      }}>
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={12} md={6}>
            <Tabs
              indicatorColor="primary"
              scrollButtons="auto"
              textColor="primary"
              value={value}
              variant="scrollable"
              className={classes.tabs}>
              {tabs.map((tab) => (
                <Tab
                  key={tab?.value}
                  label={tab?.label}
                  value={tab?.value}
                  onClick={() => {
                    setValue(tab?.value);
                  }}
                />
              ))}
            </Tabs>
          </Grid>
        </Grid>
        <Box sx={{ mt: 1 }}>
          {value === 'graph' ? (
            <EmployeeGraph data={groupData} />
          ) : value === 'summary' ? (
            <EmployeeGrid data={groupData} />
          ) : value === 'hourly' ? (
            <EmployeeHourlyGraph
              groupDataByDepartment={groupDataByDepartment}
            />
          ) : null}
        </Box>
      </Container>
    </Box>
  );
}

export default EmployeeSummary;
