/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import { Mention } from '@ckeditor/ckeditor5-mention';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  List,
  Typography,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { UserActivity } from 'src/components/widgets/display-blocks/user-activity';
import FileUploadDragDrop from 'src/components/widgets/fileUpload/FileUploadDragDrop';
// eslint-disable-next-line @typescript-eslint/no-unused-vars

import parseHTML from 'html-react-parser';
import { requestApi } from 'src/api/requestApi';

const commentStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      '& .commentHelperText': {
        margin: '0px',
      },
      '& .commentsTabRoot': {
        marginTop: '15px',
        borderRadius: '4px 4px 0 0',
        '& .MuiTabs-flexContainer': {
          backgroundColor: '#ddd',
          height: '39px',
          border: 'none',
        },
        '& .Mui-selected': {
          '& .MuiTab-wrapper': {
            color: '#333',
          },
        },
        '& .MuiButtonBase-root.Mui-selected[aria-selected="true"]': {
          fontWeight: '700',
          backgroundColor: 'transparent !important',
          border: 'none !important',
        },
        '& .MuiButtonBase-root': {
          textTransform: 'capitalize',
          color: '#333',
          fontWeight: 400,
          fontSize: '1rem',
          minHeight: '39px!important',
          minWidth: '155px !important',
        },
        '& .MuiButtonBase-root:first-child': {
          marginLeft: 0,
        },
        '& .MuiTabs-indicator': {
          backgroundColor: '#C22027!important',
          height: '4px!important',
          top: '39px!important',
          display: 'block!important',
        },
      },
      '& .commentsPreview': {
        // marginLeft: '50px',
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        '& p': {
          margin: '0px!important',
        },
        '& figure': {
          margin: '0px!important',
        },
        '& .MuiAvatar-root': {
          backgroundColor: '#231f20',
          width: '40px',
          height: '40px',
        },
        '& img': {
          width: ' 60px',
          height: '67px',
          border: `1px solid ${theme.palette.grey[300]}`,
          objectFit: 'contain',
        },
        '& .MuiListItemText-root': {
          margin: 0,
          fontSize: '14px',
          fontWeight: 300,
          '& strong': {
            color: '#000',
          },
        },
      },
      '& .timelineRoot': {
        margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        '& p': {
          margin: '0px!important',
          wordBreak: 'break-all',
        },
        '& .MuiAvatar-root': {
          backgroundColor: '#231f20',
          width: '40px',
          height: '40px',
        },
        '& img': {
          width: ' 60px',
          height: '67px',
          objectFit: 'contain',
        },
        '& .MuiListItemText-root': {
          margin: 0,
          fontSize: '14px',
          fontWeight: 300,
          '& strong': {
            color: '#000',
          },
        },
      },

      '& .detailsRoot': {
        display: 'grid',
        gridTemplateColumns: '49% 49%',
        rowGap: '5px',
        columnGap: '10px',
        '& .MuiAutocomplete-root .MuiChip-root': {
          maxWidth: '115px',
        },
        '& .MuiAutocomplete-root .MuiAutocomplete-tag': {
          fontSize: '12px',
        },
        [theme.breakpoints.down(1850)]: {
          gridTemplateColumns: '1fr',
        },
      },
      '& .transalationBtn': {
        display: 'flex',
        justifyContent: 'flex-end',
        cursor: 'pointer',
      },
      '& .externalComment': {
        backgroundColor: '#fff9f9',
        boxShadow: '0px 0px 1px 1px #ffb0b0',
      },
      '& .commentEditorContainer': {
        border: '2px solid white',
        borderRadius: 4,
        '& .editCommentFileParent': {
          display: 'flex',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: '15px',
          '& a': {
            position: 'relative',
            display: 'block',
            color: ' #757575',
            cursor: 'pointer',
            border: `1px solid ${theme.palette.grey[300]}`,
            '&  .editCommentFileEdit': {
              position: 'absolute',
              top: '-10px',
              right: '-10px',
              cursor: 'pointer',
              color: '#C22027',
            },
            '&  span': {
              position: 'absolute',
              background: ' #f7f7f7',
              fontSize: '10px',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%)',
              padding: '2px',
              fontWeight: 'bold',
            },
            '& img': {
              width: ' 60px',
              height: '67px',
              objectFit: 'contain',
            },
          },
        },
      },
      '& .commentOptionButtons': {
        display: 'flex',
        padding: '10px!important',
        gap: '10px',
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: 4,
        '& .internalCommentLink': {
          cursor: 'pointer',
          paddingBottom: '5px',
          textDecoration: 'underline',
          textUnderlineOffset: '3px',
          // transition: '0.3s linear',
          '&:hover': {
            textDecoration: 'underline #f5f5f5',
            // color: '#f5f5f5'
          },
        },
        '& .externalCommentLink': {
          cursor: 'pointer',
          paddingBottom: '5px',
          textDecoration: 'underline',
          textUnderlineOffset: '3px',
          '&:hover': {
            textDecoration: 'underline #f5f5f5',
            // color: '#f5f5f5'
          },
        },
      },
      '& .customClassAddComment': {
        padding: '0px 5px',
      },
      '& .btnComments': {
        display: 'flex',
        gap: '5px',
      },
      '& .btn': {
        fontSize: '13px',
        minWidth: '130px',
        height: '30px',
        padding: '0px',
        borderRadius: '4px',
        textTransform: 'capitalize',
        border: '2px solid #000',
        color: '#000',
        backgroundColor: '#fff',
        transition: 'all 0.2s linear',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'rgba(35,31,32,0.04)',
        },
      },
      '& .btnRed': {
        color: '#fff',
        backgroundColor: '#c22027',
        borderColor: '#c22027',
        '&:hover': {
          backgroundColor: 'rgba(35,31,32,0.04)',
          color: '#C22027',
        },
      },
      '& .userActivityBtn': {
        fontSize: '10px',
        minWidth: 'auto',
        padding: '0px 5px',
      },
    },
  })
);
export const Comments = ({
  toggleIsLoad,
  requestData,
  handleDocumentClick,
  handleImageClick,
}: any) => {
  const classes = commentStyles();
  const commentRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [commentValue, setCommentValue] = useState('');
  const [commentEdit, setCommentEdit] = useState<any>({});
  const [filePath, setFilePath] = useState<Array<any>>([]);
  const [commentDeleteId, setCommentDeleteId] = useState<string>('');
  // const [editCommentFiles, setEditCommentFiles] = useState<string[]>([]);
  const handleCancelComment = () => {
    setCommentValue('');
    setIsEditing(false);
  };
  const isNotEmpty = (val: string | any[]) => {
    if (Array.isArray(val)) {
      return val.length > 0;
    }
    return typeof val === 'string' && val.trim() !== '';
  };
  const handleSaveComment = async (e) => {
    e.preventDefault();
    try {
      if (isNotEmpty(commentValue) || isNotEmpty(filePath)) {
        const payload: any = {
          itsmId: requestData?.id,
          description: commentValue,
          attachmentPath: filePath,
        };

        const response = await requestApi?.createComment(payload);
        if (response?.status === 201) {
          toast.success('Comment Added');
          setCommentValue('');
          setFilePath([]);
          toggleIsLoad();
        }
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const handleDeleteComment = async () => {
    try {
      const response = await requestApi?.deleteComment(
        requestData?.id,
        commentDeleteId
      );
      if (response?.status === 200) {
        toast.success('Comment Deleted');
        toggleIsLoad();
        setOpenDialog(false);
        toggleIsLoad();
        if (isEditing) {
          setIsEditing(false);
          setCommentValue('');
          setCommentEdit({
            commentId: '',
          });
        }
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const editCommentAPI = async (e) => {
    e.preventDefault();
    try {
      if (isNotEmpty(commentValue)) {
        const payload: any = {
          itsmId: commentEdit?.itsmId,
          description: commentValue,
          attachmentPath: filePath,
        };
        const response = await requestApi?.editComment(
          commentEdit?.commentID,
          payload
        );
        if (response?.status === 200) {
          toast.success('Comment Updated');
          toggleIsLoad();
          setCommentValue('');
          setIsEditing(false);
        }
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const editComment = async (
    itsmId: string,
    commentId: string,
    commentDes: string,
    indexMap: number,
    filePaths: string[]
  ) => {
    setIsEditing(true);
    setFilePath(filePaths);
    setCommentValue(commentDes);
    setCommentEdit({
      itsmId,
      commentID: commentId,
      currentIndex: indexMap,
    });
  };
  const handleEditCommentDeleteFile = (e, index) => {
    e.stopPropagation();
    const updatedFiles = [...filePath];
    updatedFiles.splice(index, 1);
    setFilePath(updatedFiles);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  // const handleCheckExternalComment = () => {
  //   setIsExternalComment(!isExternalComment);
  // };

  function uploadAdapter(loader) {
    return {
      upload: () =>
        new Promise((resolve) => {
          loader.file.then(async (file) => {
            const formData: any = new FormData();
            formData.append('file', file);
            // const image = await uploadApi?.uploadAttachments(formData);
            const image = null; // <---- remove it
            resolve({
              default: `${image?.data?.blob_url}`,
            });
          });
        }),
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => uploadAdapter(loader);
  }
  return (
    <Box className={classes.root}>
      <div
        style={{ flexGrow: 1, marginBottom: 16 }}
        data-color-mode="light"
        className="editorMarkup"
        ref={commentRef}>
        <div className="commentEditorContainer">
          <CKEditor
            editor={ClassicEditor}
            data={commentValue}
            onChange={(event, editor) => {
              const data = editor.getData();
              setCommentValue(data);
            }}
            config={{
              placeholder: 'Enter your comment here',
              toolbar: [
                'heading',
                '|',
                'bold',
                'italic',
                'link',
                'bulletedList',
                'numberedList',
                'blockQuote',
                'mention'
                // 'uploadimage',
              ],
              extraPlugins: [uploadPlugin],
              mention: {
                feeds: [
                  {
                    marker: "@",
                    feed: ["@Barney", "@Lily", "@Marshall", "@Robin", "@Ted"],
                    // minimumCharacters: 1
                  }
                ]
              },
            }}
          />
          <FileUploadDragDrop setFilePath={setFilePath} filePath={filePath} />
          {isEditing && (
            <div className="editCommentFileParent">
              {isEditing &&
                filePath?.map((item, index) => {
                  const splittedUrl = item?.split('?');
                  splittedUrl[1] =
                    'sp=racwdl&st=2024-02-27T13:21:58Z&se=2030-07-29T21:21:58Z&spr=https&sv=2022-11-02&sr=c&sig=YXOHT7%2Bx8VW3RDdgzehtPZLWJ6zHJz%2Bp3QtnduafL4o%3D';
                  item = splittedUrl?.join('?');
                  const getFileType = (items) => {
                    if (items.includes('.pdf')) {
                      return 'PDF';
                    }
                    if (items.includes('.doc')) {
                      return 'DOC';
                    }
                    if (items.includes('.docx')) {
                      return 'DOCX';
                    }
                    if (items.includes('.txt')) {
                      return 'TXT';
                    }
                    if (items.includes(".xls")) {
                      return "XLS";
                    }
                    if (items.includes(".xlsx")) {
                      return "XLSX";
                    }
                    if (items.includes(".csv")) {
                      return "CSV";
                    }
                    if (items.includes(".tsv")) {
                      return "TSV";
                    }
                    return null;
                  };

                  const fileType = getFileType(item);
                  if (
                    item.includes('.pdf') ||
                    item.includes('.doc') ||
                    item.includes('.docx') ||
                    item.includes(".xls") ||
                    item.includes(".xlsx") ||
                    item.includes(".csv") ||
                    item.includes(".tsv") ||
                    item.includes('.txt')
                  ) {
                    return (
                      <a
                        role="presentation"
                        onClick={() => handleDocumentClick(item)}>
                        {' '}
                        <span>{fileType}</span>
                        <InsertDriveFileIcon sx={{ fontSize: '60px' }} />
                        <CancelIcon
                          onClick={(e) => handleEditCommentDeleteFile(e, index)}
                          className="editCommentFileEdit"
                        />
                      </a>
                    );
                  }
                  const imageFormats = [
                    '.jpg',
                    '.jpeg',
                    '.png',
                    '.gif',
                    '.bmp',
                    '.tiff',
                    '.webp',
                    '.svg',
                    '.ico',
                  ];
                  if (
                    imageFormats.some((extension) => item?.includes(extension))
                  ) {
                    return (
                      <>
                        <a
                          role="presentation"
                          onClick={() => handleImageClick(item)}>
                          <img src={item} alt="img" />
                          <CancelIcon
                            onClick={(e) =>
                              handleEditCommentDeleteFile(e, index)
                            }
                            className="editCommentFileEdit"
                          />
                        </a>
                      </>
                    );
                  }
                  return null;
                })}
            </div>
          )}
        </div>
        <div className="btnComments">
          {!isEditing ? (
            <button
              // customClass={classes.customClassAddComment}
              // color="secondary"
              onClick={handleSaveComment}
              className="btn btnRed"
              type="button">
              Save
            </button>
          ) : (
            <>
              <button
                type="button"
                value="Update Comment"
                color="secondary"
                onClick={(e) => editCommentAPI(e)}
                className="btn">
                Update
              </button>

              <button
                type="button"
                value="Cancel"
                color="text"
                onClick={handleCancelComment}
                className="btn">
                Cancel
              </button>
            </>
          )}
        </div>
      </div>
      {requestData?.comment?.length > 0 && (
        <>
          <Typography
            variant="body2"
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              margin: '0px 0px 8px 0px',
            }}>
            Comments
          </Typography>
          <List className="commentsPreview">
            {requestData?.comment?.map((comment, i) => (
              <UserActivity
                customClasses={`${isEditing && commentEdit?.currentIndex === i ? 'current' : ''
                  }
              ${comment?.isExternal && 'externalComment'} `}
                meta={{
                  description: 'Comment added',
                  createdBy: comment?.createdBy?.name,
                  createdAt: comment?.createdAt,
                }}
                details={parseHTML(comment?.description)}
                filesPath={comment?.attachmentPath}
                actions={[
                  <Button
                    size="small"
                    title="Edit comment"
                    type="button"
                    className="userActivityBtn"
                    onClick={() => {
                      editComment(
                        requestData?.id,
                        comment?.id,
                        comment?.description,
                        i,
                        comment?.attachmentPath
                      );
                      // commentRef.current?.scrollIntoView({ behaviour: 'smooth' });
                    }}
                    sx={{ padding: '0' }}>
                    Edit
                  </Button>,
                  <Button
                    size="small"
                    title="Delete comment"
                    type="button"
                    className="userActivityBtn"
                    onClick={() => {
                      setOpenDialog(true);
                      setCommentDeleteId(comment?.id);
                    }}>
                    Delete
                  </Button>,
                  <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Are you sure, you want to delete a comment?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                      </Button>
                      <Button
                        onClick={handleDeleteComment}
                        color="primary"
                        autoFocus>
                        Yes
                      </Button>
                    </DialogActions>
                  </Dialog>,
                ]}
              />
            ))}
          </List>
        </>
      )}
    </Box>
  );
}; // ----> Comments()
