import { Box, Card, TablePagination } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import type { FC } from 'react';
import { useCallback } from 'react';
import { TableProps } from 'src/types/components/tableList.types';
import Scrollbar from 'src/components/Scrollbar';
import CustomTable from './CustomTable';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    btn: {
      width: '47px',
      height: '40px',
      padding: 0,
      minWidth: 'initial',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '4px',
      color: '#fff',
      marginLeft: '13px',
      '& .MuiButton-iconSizeMedium': {
        margin: '0',
      },
    },
    clip: {
      display: 'inline-block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '10ch',
    },
    textFieldSelect: {
      width: '230px',
      marginLeft: '13px',
    },
    textFieldSelectRoot: {
      borderRadius: '4px',
      height: '38px',
    },
    tabButton: {
      width: '181px',
    },
    cardRoot: {
      borderRadius: '4px',
    },
    tabs: {
      '& .MuiTab-root': {
        backgroundColor: 'transparent',
        minWidth: '120px',
      },
      '& .Mui-selected': {
        backgroundColor: '#231F20',
        color: '#fff',
      },
    },
    tableHead: {
      '& .MuiTableCell-root': {
        textTransform: 'capitalize',
      },
    },
    tableRow: {
      '& :hover': {
        cursor: 'pointer',
      },
      '& .ticketStatusTrue': {
        backgroundColor: 'rgba(7, 83, 0, 0.16)',
        color: '#1D7A15',
        padding: '5px',
        fontWeight: 'Bold',
      },
      '& .ticketStatusFalse': {
        background: '#DEEBFF',
        color: '#3375D7',
        padding: '5px',
        fontWeight: 'Bold',
      },
    },
    priorityComponent: {
      height: '30px',
      background: 'rgba(189,189,189,0.1)',
      color: '#000000aa',
      width: '100px',
      display: 'flex',
      alignItems: 'center',
      gap: '6px',
      textTransform: 'capitalize',
      justifyContent: 'center',
    },
    loaderTable: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: '2222',
    },
  })
);

interface CustomListViewTypes extends TableProps {
  editBaseRoute?: string;
  pageSize?: any;
  setPageSize?: any;
  setPage?: any;
  HeaderComponent?: any;
  selectedSort?: any;
  setSelectedSort?: any;
  hidePagination?: boolean;
  onRowClick?: any;
}
const CustomListView: FC<CustomListViewTypes> = ({
  data,
  columns,
  editBaseRoute,
  onRowClick,
  count,
  page,
  setPage,
  pageSize,
  setPageSize,
  selectedSort,
  setSelectedSort,
  HeaderComponent,
  hidePagination = false,
}: any) => {
  const classes = useStyles();
  const handleChangePage = useCallback(
    (event: any, _page: number) => {
      setPage(_page);
    },
    [page, data]
  );
  const handleChangePageSize = useCallback(
    (event: any) => {
      setPageSize(parseInt(event.target.value, 10));
      setPage(0);
    },
    [pageSize, data]
  );
  return (
    <>
      {HeaderComponent}
      <Box
        sx={{
          backgroundColor: 'background.default',
          py: 1,
        }}>
        <Card className={classes.cardRoot}>
          <Scrollbar>
            <Box sx={{ minWidth: 700, px: 1 }}>
              <CustomTable
                columns={columns}
                rows={data}
                editBaseRoute={editBaseRoute}
                onRowClick={onRowClick}
                selectedSort={selectedSort}
                setSelectedSort={setSelectedSort}
              />
            </Box>
          </Scrollbar>
          {!hidePagination && (
            <TablePagination
              component="div"
              count={count}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangePageSize}
              page={page}
              rowsPerPage={pageSize}
              rowsPerPageOptions={[25, 50, 100, 500, 1000]}
            />
          )}
          {/* <TablePagination
            component="div"
            count={count}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangePageSize}
            page={page}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[25, 50, 100, 500, 1000]}
          /> */}
        </Card>
      </Box>
    </>
  );
};

export default CustomListView;
