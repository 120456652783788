import { Box, ListItem, ListItemText, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { ReactNode, useEffect, useRef, useState } from 'react';
import time from 'src/utils/time';
import SImpleModal from '../modal/SImpleModal';
import LockIcon from '@material-ui/icons/Lock';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DocumentViewer from '../modal/DocumentViewer';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    meta: {
      width: '90%',
      gap: 8,
      fontSize: '14px',
      marginBottom: 8,
      fontWeight: 500,
      color: theme.palette.grey[500],
      '& .author': {
        fontWeight: 600,
        color: theme.palette.grey[600],
      },
    },
    ListItem: {
      paddingTop: '0px',
      paddingBottom: '0px',
      paddingLeft: '5px',
      alignItems: 'start',
      border: '2px solid #e2e2e2',
      borderRadius: 4,
      '& .MuiTypography-subtitle2': {
        color: theme.palette.grey[600],
        fontSize: '12px',
        fontWeight: 400,
        marginRight: '10px',
        textWrap: 'nowrap',
      },
      '& .MuiTypography-body2, .change': {
        color: theme.palette.grey[600],
        fontSize: '14px',
        fontWeight: 400,
        overflow: 'hidden',
      },
      '&.current': {
        border: `1px solid ${theme.palette.grey[600]}`,
      },
      '& .top': { display: 'flex', justifyContent: 'space-between' },
      '& strong': { color: theme.palette.grey[900] },
      '& .MuiListItemText-root .MuiTypography-body2': {
        overflow: 'hidden',
      },
      '& .editCommentFileParent': {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        gap: '15px',
        alignItems: 'center',
        '& a': {
          position: 'relative',
          display: 'block',
          color: ' #757575',
          cursor: 'pointer',
          border: `1px solid ${theme.palette.grey[300]}`,
          '&  .editCommentFileEdit': {
            position: 'absolute',
            top: '-10px',
            right: '-10px',
            cursor: 'pointer',
            color: '#C22027',
            zIndex: '122',
          },
          '&  span': {
            position: 'absolute',
            background: ' #f7f7f7',
            fontSize: '10px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%)',
            padding: '2px',
            fontWeight: 'bold',
          },
        },
      },
      '& .otherLogs': {
        marginBottom: '10px',
        '& .otherLogsimg': {
          width: ' 60px',
          height: '67px',
          objectFit: 'contain',
          display: 'block',
          color: ' #757575',
          cursor: 'pointer',
          border: `1px solid ${theme.palette.grey[300]}`,
        },
      },
    },
    ListItemWithoutBorder: {
      paddingTop: '0px',
      paddingBottom: '0px',
      paddingLeft: '5px',
      alignItems: 'start',
      // border: '2px solid #e2e2e2',
      borderRadius: 4,
      '& .MuiTypography-subtitle2': {
        color: theme.palette.grey[600],
        fontSize: '12px',
        fontWeight: 400,
        marginRight: '10px',
        textWrap: 'nowrap',
      },
      '& .MuiTypography-body2, .change': {
        color: theme.palette.grey[600],
        fontSize: '14px',
        fontWeight: 400,
        overflow: 'hidden',
      },
      '&.current': {
        border: `1px solid ${theme.palette.grey[600]}`,
      },
      '& .top': { display: 'flex', justifyContent: 'space-between' },
      '& strong': { color: theme.palette.grey[900] },
      '& .MuiListItemText-root .MuiTypography-body2': {
        overflow: 'hidden',
      },
      '& .editCommentFileParent': {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        gap: '15px',
        alignItems: 'center',
        '& a': {
          position: 'relative',
          display: 'block',
          color: ' #757575',
          cursor: 'pointer',
          border: `1px solid ${theme.palette.grey[300]}`,
          '&  .editCommentFileEdit': {
            position: 'absolute',
            top: '-10px',
            right: '-10px',
            cursor: 'pointer',
            color: '#C22027',
            zIndex: '122',
          },
          '&  span': {
            position: 'absolute',
            background: ' #f7f7f7',
            fontSize: '10px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%)',
            padding: '2px',
            fontWeight: 'bold',
          },
        },
      },
      '& .otherLogs': {
        marginBottom: '10px',
        '& .otherLogsimg': {
          width: ' 60px',
          height: '67px',
          objectFit: 'contain',
          display: 'block',
          color: ' #757575',
          cursor: 'pointer',
          border: `1px solid ${theme.palette.grey[300]}`,
        },
      },
    },
  })
);
interface Props {
  customClasses?: string;
  sx?: {};
  onClick?: any;
  // image?: { src:string, alt?:string };
  meta: {
    description?: string;
    createdBy: string;
    createdAt: string;
    change?: any;
    isExternal?: boolean;
  };
  details: unknown;
  actions?: Array<ReactNode | HTMLElement | string>;
  filesPath?: any;
  type?: string;
  isExternalComment?: boolean;
}
export const UserActivity: React.FC<Props> = ({
  customClasses = '',
  isExternalComment = false,
  sx,
  meta,
  details,
  actions,
  onClick,
  filesPath,
  type,
}: Props) => {
  const classes = useStyles();
  const notificationTime = time?.absoluteTime(meta?.createdAt, true);
  const timeSplit = notificationTime?.split('-');
  const dateAndYear = timeSplit[0]?.trim();
  const timeInMinutes = timeSplit[1]?.trim();
  const parsedHtmlRef = useRef(null);
  const [parsedHtml, setParsedHtml] = useState<any>(null);
  const [isImagePopupOpen, setIsImagePopupOpen] = useState<boolean>(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState<string>('');
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState('');
  const [isDocumentViewerOpen, setIsDocumentViewerOpen] = useState(false);

  const handleDocumentClick = (filePath: string) => {
    setSelectedDocumentUrl(filePath);
    setIsDocumentViewerOpen(true);
  };

  const handleCloseDocumentViewer = () => {
    setIsDocumentViewerOpen(false);
  };
  const handleImageClick = (e) => {
    const clickedImageSrc = e.target.getAttribute('src');
    setSelectedImageUrl(clickedImageSrc);
    setIsImagePopupOpen(true);
  };

  const handleCloseImagePopup = () => {
    setIsImagePopupOpen(false);
  };

  useEffect(() => {
    setParsedHtml(details);

    const imageElements = parsedHtmlRef.current.querySelectorAll('img');

    const addEventListeners = () => {
      imageElements.forEach((imageElement) => {
        imageElement.classList.add('otherLogsimg');
        imageElement.addEventListener('click', handleImageClick);
      });
    };

    const removeEventListeners = () => {
      imageElements.forEach((imageElement) => {
        imageElement.classList.add('otherLogsimg');
        imageElement.removeEventListener('click', handleImageClick);
      });
    };

    addEventListeners();

    // Cleanup event listeners when component unmounts or when details change
    return () => {
      removeEventListeners();
    };
  }, [details, parsedHtml]);

  const borderColor = {
    'ticket-stale': '#72ddf7',
    'case-created': '#56cfe1',
    case: '#48bfe3',
    'case-type-updated': '#a3cef1',
    comment: '#274c77',
    'added-comment': '#6096ba',
    'summary-updated': '#40916c',
    'assignee-updated': '#2d6a4f',
    'escalated-case': '#7f4f24',
    'escalation-manager-updated': '#31572c',
    'priority-updated': '#b5838d',
    'status-updated': '#6d6875',
    'project-sponsor-updated': '#7796cb',
    'description-updated': '#8a5a44',
    'severity-updated': '#e76f51',
    'resolved-time-violated!': '#bc4749',
    'resolve-time-violated!': '#fcaf58',
    'response-time-violated!': '#a1c181',
    'stake-holder-updated': '#656d4a',
    'first-response-time-violated!': '#adc178',
    'case-comment-successfully-deleted': '#f4f0bb',
    'escalation-manager-assigned': '#f4aba0',
  };
  return (
    <ListItem
      className={`${
        !isExternalComment ? classes.ListItem : classes.ListItemWithoutBorder
      } ${customClasses}`}
      style={sx}
      sx={{ borderColor: `${borderColor[type]}!important` }}
      onClick={onClick}
      ref={parsedHtmlRef}>
      <Box>
        <Typography variant="subtitle2">
          {dateAndYear}
          <br />
          {timeInMinutes}
        </Typography>

        {!meta?.isExternal && (
          <Box>
            <LockIcon style={{ fontSize: 12, color: '#bdbdbd' }} />
          </Box>
        )}
      </Box>
      {/* <ListItemAvatar>
        <Avatar>
          <img
            src={image.src}
            alt={image.alt}
          />
        </Avatar>
      </ListItemAvatar> */}
      <ListItemText
        primary={
          <div className="top">
            <div className={classes.meta}>
              {meta?.description && (
                <>
                  <strong>{meta?.description}</strong>
                  {' by '}
                </>
              )}
              <strong>{`${meta?.createdBy} `}</strong>
              <div className="change">{meta?.change}</div>
            </div>
            {actions}
          </div>
        }
        secondary={
          <>
            <div className="otherLogs">{details}</div>
            <div className="editCommentFileParent">
              {filesPath?.length > 0 &&
                filesPath?.map((filePath) => {
                  const splittedUrl = filePath?.split('?');
                  splittedUrl[1] =
                    'sp=racwdl&st=2024-02-27T13:21:58Z&se=2030-07-29T21:21:58Z&spr=https&sv=2022-11-02&sr=c&sig=YXOHT7%2Bx8VW3RDdgzehtPZLWJ6zHJz%2Bp3QtnduafL4o%3D';
                  filePath = splittedUrl?.join('?');
                  const isDocs = (fileName) => {
                    const lowerCaseFileName = fileName?.toLowerCase();
                    const supportedExtensions = [
                      '.pdf',
                      '.doc',
                      '.txt',
                      '.docx',
                      '.xls',
                      '.xlsx',
                      '.csv',
                      '.tsv',
                    ];

                    return supportedExtensions.some((extension) =>
                      lowerCaseFileName.includes(extension)
                    );
                  };
                  if (filePath !== '' && isDocs(filePath)) {
                    const getFileType = (items) => {
                      if (items.includes('.pdf')) {
                        return 'PDF';
                      }
                      if (items.includes('.doc')) {
                        return 'DOC';
                      }
                      if (items.includes('.docx')) {
                        return 'DOCX';
                      }
                      if (items.includes('.txt')) {
                        return 'TXT';
                      }
                      if (items.includes('.xls')) {
                        return 'XLS';
                      }
                      if (items.includes('.xlsx')) {
                        return 'XLSX';
                      }
                      if (items.includes('.csv')) {
                        return 'CSV';
                      }
                      if (items.includes('.tsv')) {
                        return 'TSV';
                      }
                      return null;
                    };

                    const fileType = getFileType(filePath);
                    return (
                      <>
                        <a
                          style={{ position: 'relative' }}
                          role="presentation"
                          onClick={() => handleDocumentClick(filePath)}>
                          {' '}
                          <span>{fileType}</span>
                          <InsertDriveFileIcon sx={{ fontSize: '60px' }} />
                        </a>
                        {isDocumentViewerOpen && (
                          <DocumentViewer
                            documentUrl={selectedDocumentUrl}
                            isOpen={isDocumentViewerOpen}
                            onClose={handleCloseDocumentViewer}
                          />
                        )}
                      </>
                    );
                  }
                  if (filePath !== '') {
                    return (
                      // eslint-disable-next-line jsx-a11y/img-redundant-alt
                      <img src={filePath} alt="Image" />
                    );
                  }
                  return null;
                })}
            </div>
          </>
        }
      />
      <SImpleModal
        isOpen={isImagePopupOpen}
        onClose={handleCloseImagePopup}
        imageUrl={selectedImageUrl}
      />
    </ListItem>
  );
};
