import React from 'react';
import { Handle, Position } from 'reactflow';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme>(() => createStyles({
  root: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    backgroundColor: 'rgba(38, 67, 110, 1)',
    border: '1px solid #fff',
    boxShadow: '0 0 0 3px rgba(38, 67, 110, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'bolder',
    color: '#fff',
  }
}));
const StartNode = ({ isConnectable }: any) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>start</div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="start-node-source-1"
        style={{ bottom: '-6px', background: 'green' }}
        isConnectable={isConnectable}
      />
    </>
  );
};

export default React.memo(StartNode);
