import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { Column } from './CustomTable';

const CustomTableHead = ({ columns, setSelectedSort, selectedSort }: any) => (
  <TableHead sx={{ marginBottom: '1rem' }}>
    <TableRow>
      {columns?.map(({ id, label = '', isSort }: Column) => (
        <TableCell
          align="center"
          className="textBold"
          sortDirection={selectedSort?.id === id ? selectedSort?.value : false}>
          {isSort ? (
            <TableSortLabel
              active={selectedSort?.id === id}
              direction={selectedSort?.id === id ? selectedSort?.value : 'asc'}
              onClick={() => {
                setSelectedSort({
                  id,
                  value: selectedSort.value === 'asc' ? 'desc' : 'asc',
                });
              }}>
              {label}
            </TableSortLabel>
          ) : (
            label
          )}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

export default CustomTableHead;
