import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { FC, useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { userApi } from 'src/api/userApi';
import SearchBar from 'src/components/widgets/searchBar/SearchBar';
import { Columns, TableProps } from 'src/types/components/tableList.types';
import Scrollbar from '../../../Scrollbar';
import ToggleStatusDialogue from '../../../widgets/Dialog/ToggleStatus';
import CreateUser from './CreateUsers';
import logger from 'src/utils/logger';
import RestartAltIcon from '@material-ui/icons/RestartAlt';
import SingleDeleteConfirmation from 'src/components/widgets/Dialog/SingleDeleteConfirmation';

const useStyles = makeStyles<Theme>(() => createStyles({
  btn: {
    width: '30px',
    height: '24px',
    padding: 0,
    minWidth: 'initial',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    color: '#fff',
    marginLeft: '8px',
    marginBottom: '7px',
    '& .MuiButton-iconSizeMedium': {
      margin: '0'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '16px'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  textFieldSelect: {
    width: '230px',
    marginLeft: '13px'
  },
  textFieldSelectRoot: {
    borderRadius: '4px',
    height: '38px'
  },
  tabButton: {
    width: '181px'
  },
  cardRoot: {
    borderRadius: '4px'
  },
  tabs: {
    '& .MuiTab-root': {
      backgroundColor: 'transparent',
      minWidth: '120px'
    },
    '& .Mui-selected': {
      backgroundColor: '#231F20',
      color: '#fff'
    }
  },
  tableHead: {
    '& .MuiTableCell-root': {
      textTransform: 'capitalize'
    }
  },
  tableRow: {
    '& :hover': {
      cursor: 'pointer'
    },
    '& tabelCheckBox': {
      zIndex: '999'
    },
    '& .MuiTableCell-root': {
      padding: '11px 16px'
    }
  },
  activeButton: {
    background: 'rgba(7, 83, 0, 0.1)',
    color: 'rgba(29, 122, 21, 1)',
    width: '100px'
  },
  inActiveButton: {
    background: 'rgba(255, 161, 161, 0.3)',
    color: 'red',
    width: '100px'
  },
  table: {
    '&  .MuiTableCell-head': {
      padding: ' 10px 8px'
    }
  }
}));

const TableHeadComponent: FC<any> = ({ allowedColumns }: any) => (
  <TableHead sx={{ marginBottom: '1rem' }}>
    <TableRow>
      <TableCell padding="checkbox" />
      {allowedColumns.map((col: Columns, index) => (
        <TableCell
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          align="center"
          className="textBold capitalize"
        >
          {col.name}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

const TableBodyComponent: FC<any> = ({
  checkedUser,
  data,
  editRouteUrl,
  allowedColumns,
  handleCheckUser,
  handleUncheckUser,
  handleStatusToggle
}: any) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [toggleStatusAlert, setToggleStatusAlert] = useState(false);
  const [clickedItem, setClickedItem] = useState(null);
  const [clickedItemStatus, setClickedItemStatus] = useState<boolean | null>(null);

  const handleRedirectNext = (id: string) => {
    navigate(`/${editRouteUrl}/user/${id}`);
  };

  const checkValue = (value: any, id: number, isActivated: boolean) => {
    logger(isActivated, '', 'checkValue(): isActivated');
    if (typeof value === 'boolean') {
      return (
        <Button
          className={value ? classes.activeButton : classes.inActiveButton}
          onClick={(e) => {
            e.stopPropagation();
            try {
              setClickedItem(id);
              setClickedItemStatus(value);
              setToggleStatusAlert(true);
            } catch (error) {
              logger(error, 'error');
            }
          }}
        >
          {value ? 'Active' : 'Inactive'}
        </Button>
      );
    }
    if (value == null) {
      return 'Null';
    }
    return value;
  };

  return data?.length ? (
    <TableBody>
      {data?.map((row) => {
        const rowValue = Object.keys(row);
        return (
          <>
            <TableRow
              hover
              onClick={() => handleRedirectNext(row.id)}
              className={classes.tableRow}
            >
              <TableCell
                key={row.id}
                padding="checkbox"
              >
                <Checkbox
                  checked={row.id === checkedUser}
                  color="primary"
                  className={classes.tableCheckBox}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      handleCheckUser(row.id);
                    } else {
                      handleUncheckUser();
                    }
                  }}
                />
              </TableCell>
              {allowedColumns.map((column: Columns) => {
                if (rowValue.includes(column.value)) {
                  return (
                    <TableCell align="center">
                      {checkValue(row[column.value], row.id, row.isActive)}
                    </TableCell>
                  );
                }
                // cheapi : Faiz : i haven't idea what happen here.
                return <TableCell />;
              })}
            </TableRow>
          </>
        );
      })}
      <ToggleStatusDialogue
        isOpen={toggleStatusAlert}
        setToggleStatusAlert={setToggleStatusAlert}
        handleStatusToggle={handleStatusToggle}
        clickedItem={clickedItem}
        clickedItemStatus={clickedItemStatus}
      />
    </TableBody>
  ) : (
    <TableBody>
      <TableRow>
        <TableCell
          align="center"
          sx={{ textTransform: 'capitalize', textAlign: 'center' }}
          colSpan={Number(allowedColumns?.length + 1)}
        >
          no data found
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

const ViewUsers: FC<TableProps> = () => {
  const classes = useStyles();
  const [deleteUserAlert, setDeleteUserAlert] = useState(false);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState<any>(JSON.parse(localStorage.getItem('usersByProjPagePg'))?.casesPageNo || 0);
  const [limit, setLimit] = useState<any>(JSON.parse(localStorage.getItem('usersByProjPagePg'))?.casesPageLimit || 25);
  const [searchQuery, setSearchQuery] = useState<any>(JSON.parse(localStorage.getItem('usersByProjPagePg'))?.casesPageSearch || '');
  const { organizationID, projectID } = useParams();
  const [checkedUser, setCheckedUser] = useState<string>('');
  const { addUser, handleCloseCreateUserDrawer, createUserDrawer }:any = useOutletContext();
  const allowedColumns: Array<any> = [
    {
      name: 'Name',
      value: 'name'
    },
    {
      name: 'Email',
      value: 'email'
    },
    {
      name: 'Status',
      value: 'isActive'
    },
    {
      name: 'Phone number',
      value: 'contactNumber'
    }
  ];
  const [count, setCount] = useState(0);

  const handleChangePage = useCallback(
    (event: any, _page: number) => {
      setPage(_page);
    },
    [page, users]
  );
  const handleChangeLimit = useCallback(
    (event: any) => {
      setLimit(parseInt(event.target.value, 10));
    },
    [limit, users]
  );
  const handleCheckUser = (id: string) => {
    setCheckedUser(id);
  };
  const handleUncheckUser = () => {
    setCheckedUser('');
  };

  const handleSearch = (queryString) => {
    setSearchQuery(queryString);
  };
  const getAllUsers = async () => {
    try {
      const response = await userApi.getUsersByOrganizationId(organizationID, page, limit, searchQuery);
      if (response?.status === 200) {
        if (response?.data?.data) {
          setUsers(response?.data?.data);
          setCount(response?.data?.totalRecords);
        }
      }
      if (response?.status === 204) {
        setUsers([]);
        setCount(0);
      }
    } catch (error) {
      toast.error(`${error?.response?.data?.message}`);
    }
  };
  const handleStatusToggle = async (id: string, status: boolean) => {
    try {
      const response: any = await userApi.updateStatus(
        id,
        status
      );
      if (response?.status === 200) {
        toast.success('updated successfully');
        getAllUsers();
      }
      if (response?.status === 204) {
        toast.error('User does not exist');
      }
    } catch (error) {
      logger(error, 'error');
      toast.error('Internal Server Error');
    }
  };

  const handleDeleteUser = async (checkedId: string) => {
    try {
      const response = await userApi.deleteUser(checkedId);
      if (response?.status === 200) {
        toast.success('Deleted successfully');
        setCheckedUser('');
        getAllUsers();
      }
      if (response?.status === 204) {
        toast.error('Unsuccessfull');
      }
    } catch (error) {
      toast.error('try again later');
    }
  };

  useEffect(() => {
    getAllUsers();
    const usersByProjPagePg = { casesPageLimit: limit, casesPageNo: page, search: searchQuery };
    localStorage.setItem('usersByProjPagePg', JSON.stringify(usersByProjPagePg));
  }, [page, limit, searchQuery]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default'
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          md={4}
        >
          <SearchBar
            placeholderData="Search"
            margins="0 0 8px"
            onSearch={handleSearch}
            value={searchQuery}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddCircleIcon />}
            className={classes.btn}
            onClick={(e) => addUser(true, e)}
          />
          <Button
            variant="contained"
            color="secondary"
            startIcon={<RestartAltIcon />}
            className={classes.btn}
            onClick={() => {
              localStorage.removeItem('casesByProjPagePg');
              setLimit(5);
              setPage(0);
              setSearchQuery('');
            }}
          />
          <Button
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            className={classes.btn}
            disabled={checkedUser === ''}
            onClick={() => setDeleteUserAlert(true)}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          backgroundColor: 'background.default'
        }}
        className={classes.table}
      >
        <Card className={classes.cardRoot}>
          <Scrollbar>
            <Box sx={{ minWidth: 700 }}>
              <Table>
                <TableHeadComponent allowedColumns={allowedColumns} />
                <TableBodyComponent
                  checkedUser={checkedUser}
                  data={users}
                  editRouteUrl={`erp/crm/organization/details/${organizationID}/project/${projectID}`}
                  allowedColumns={allowedColumns}
                  handleCheckUser={handleCheckUser}
                  handleUncheckUser={handleUncheckUser}
                  handleStatusToggle={handleStatusToggle}
                />
                <SingleDeleteConfirmation
                  isOpen={deleteUserAlert}
                  setDeleteDialogueVisible={setDeleteUserAlert}
                  handleDelete={handleDeleteUser}
                  selectedItem={checkedUser}
                  text="Do you want to Delete the selected User"
                />
              </Table>
            </Box>
          </Scrollbar>
          <TablePagination
            component="div"
            count={count}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeLimit}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[25, 50, 100, 500, 1000]}
          />
        </Card>
      </Box>
      <CreateUser
        isOpen={createUserDrawer}
        addUser={addUser}
        closeDrawer={handleCloseCreateUserDrawer}
        getAllUsers={getAllUsers}
      />
    </Box>
  );
};

export default ViewUsers;
