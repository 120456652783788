import React, { useEffect } from 'react';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { BillingType } from './billing-type';
import { Tooltip } from '@material-ui/core';

const ReportingTable = ({
  columns,
  rows,
  page,
  setPage,
  pageSize,
  setPageSize,
  totalRecords,
  onSelectionChange,
  selectedRows,
  setSelectedRows,
}: any) => {
  // Function to convert seconds to hours or minutes
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    if (hours > 0) {
      return `${hours}h ${minutes}m`;
    }
    return `${minutes}m`;
  };
  // Handle the "Select All" checkbox click
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelectedRows(newSelecteds);
      onSelectionChange(newSelecteds); // Pass the selected IDs directly
    } else {
      setSelectedRows([]);
      onSelectionChange([]); // No selected items when deselected all
    }
  };

  // Handle row selection
  const handleRowClick = (event, id) => {
    event.stopPropagation();
    const selectedIndex = selectedRows.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selectedRows, id];
    } else {
      newSelected = selectedRows.filter((rowId) => rowId !== id);
    }

    setSelectedRows(newSelected);
    onSelectionChange(newSelected); // Update the parent component with the new selection
  };

  // Handle checkbox click for individual row
  const handleCheckboxClick = (event, id) => {
    event.stopPropagation(); // Prevent row click event
    handleRowClick(event, id); // Manually handle the row click logic when checkbox is clicked
  };

  // Check if a specific row is selected
  const isSelected = (id) => selectedRows.indexOf(id) !== -1;

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
  };

  // Handle rendering cell content
  const renderCellContent = (row, column) => {
    const content = row[column.id];
    let displayContent;
    let tooltipContent;

    // If the column is time-related, convert seconds to hours/minutes
    if (['timeEstimate', 'loggedTime', 'remainingTime'].includes(column.id)) {
      displayContent = formatTime(content);
      tooltipContent = displayContent;
    } else if (column.id === 'category') {
      if (content !== null) {
        displayContent = <BillingType value={content} />;
        tooltipContent = content;
      } else {
        displayContent = '-';
        tooltipContent = 'No Category';
      }
    } else if (content && typeof content === 'object' && content !== null) {
      // If content is an object, handle nested fields (e.g., project.name)
      if (content.name) {
        displayContent = content.name;
        tooltipContent = content.name;
      } else if (Object.keys(content).length === 0) {
        displayContent = '-';
        tooltipContent = 'No Content';
      } else {
        displayContent = JSON.stringify(content);
        tooltipContent = displayContent;
      }
    } else {
      displayContent = content ?? '-';
      tooltipContent = displayContent;
    }

    return (
      <Tooltip title={tooltipContent} followCursor>
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}>
          {displayContent}
        </div>
      </Tooltip>
    );
  };

  // Reset selected rows when rows data changes
  useEffect(() => {
    setSelectedRows([]); // Reset selected rows when rows data changes
  }, [rows]);

  // Determine the "Select All" checkbox state
  const numSelected = selectedRows.length;
  const rowCount = rows.length;
  const isSelectAllChecked = numSelected > 0 && numSelected === rowCount;
  const isIndeterminate = numSelected > 0 && numSelected < rowCount;

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          aria-label="customizable table"
          sx={{ tableLayout: 'fixed', width: '100%' }}>
          <TableHead>
            <TableRow>
              {/* <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={isIndeterminate} // Show indeterminate state if some but not all are selected
                  checked={isSelectAllChecked} // Show checked state if all are selected
                  onChange={handleSelectAllClick} // Handle "Select All" logic
                  inputProps={{ 'aria-label': 'select all items' }}
                />
              </TableCell> */}
              {columns?.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{
                    width: column.width,
                    maxWidth: column.maxWidth,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontSize: '14px',
                    fontWeight: '700',
                  }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length + 1} align="center">
                  <Typography
                    sx={{ fontSize: '12px', fontWeight: '700' }}
                    color="textSecondary"
                    align="center">
                    No Data
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              rows?.map((row) => {
                const isItemSelected = isSelected(row.id);
                return (
                  <TableRow
                    key={row.id}
                    hover
                    component={Link}
                    to={`/erp/cases/${row?.caseKey}`}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}
                    style={{ textDecoration: 'none', color: 'inherit' }}>
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        onClick={(event) => handleCheckboxClick(event, row.id)} 
                        inputProps={{
                          'aria-labelledby': `enhanced-table-checkbox-${row.id}`,
                        }}
                      />
                    </TableCell> */}
                    {columns?.map((column) => (
                      <TableCell
                        key={column.id}
                        sx={{
                          width: column.width,
                          maxWidth: column.maxWidth,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontSize: '12px',
                          // fontWeight: '700',
                        }}>
                        {renderCellContent(row, column)}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={totalRecords}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
};

export default ReportingTable;
