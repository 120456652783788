import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
} from '@material-ui/core';
// import myData from '../../../__fakeApi__/tempdata';
import { useEffect, useState } from 'react';
import BarChart from 'src/components/barChart/Bar';

const useStyles = makeStyles<Theme>(
  () =>
    createStyles({
      btn: {
        padding: 0,
      },
      graphBox: {
        padding: 16,
        marginTop: '10px',
        background: 'white',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
      },
    }),
  {
    name: 'EmployeeGraph',
  }
);
function EmployeeGraph(props: any) {
  const classes = useStyles();
  // const [departmentData, setDepartmentData] = useState([]);
  const [groupData, setGroupData] = useState({});
  console.log(props, classes);
  // const keys = [
  //   { key: 'totalCount', value: 'Total Assigned', color: '#4285f4' },
  //   { key: 'Done', value: 'Total Completed', color: '#ea4335' },
  //   { key: 'In Progress', value: 'Total In-Progress', color: '#34a853' },
  //   { key: 'Dev Testing', value: 'Dev Testing', color: '#ff6d01' },
  //   { key: 'Pending', value: 'Total Pending', color: '#fbbc04' },
  // ];

  const keys = [
    { key: 'totalCount', title: 'Total', color: '#4285f4' },
    { key: 'Done', title: 'Done', color: '#34a853' },
    { key: 'In Progress', title: 'In Progress', color: '#fbbc04' },
    { key: 'Pending', title: 'Pending', color: '#ea4335' },
  ];

  function parseData(jsonData: any, graphKeys: any) {
    const datasets = [
      {
        label: '',
        data: [],
        backgroundColor: [],
      },
    ];
    const labels = [];
    graphKeys.forEach((item) => {
      labels.push(item.title);
      datasets[0].data.push(jsonData[item.key]);
      datasets[0].backgroundColor.push(item.color);
    });

    return { labels, datasets };
  }
  useEffect(() => {
    // getEmployeeData();
    setGroupData(props?.data);
  }, [props?.data]);
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
      }}>
      {Object.entries(groupData).length
        ? Object.entries(groupData).map(([subDepartment, item]: any[]) => (
            <Accordion>
              <AccordionSummary>{subDepartment}</AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} className={classes.root}>
                  {item &&
                    item.map((i) => (
                      <Grid item md={6} lg={4}>
                        <Box className={classes.graphBox}>
                          <h1
                            style={{
                              color: '#000',
                              margin: 0,
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: '116.7%',
                            }}>
                            {i.name}
                          </h1>

                          <BarChart
                            data={parseData(i, keys) || []}
                            showLegend={false}
                          />
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))
        : null}
    </Box>
  );
}

export default EmployeeGraph;
