/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  // Checkbox,
  // Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  // FormControlLabel,
  Grid,
  List,
  ListItem,
  MenuItem,
  Select,
  // Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import parseHTML from 'html-react-parser';
import { /* useCallback, */ useEffect, useRef, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';

import { casesApi } from 'src/api/casesApi';
import SimpleBreadCrumb from 'src/components/widgets/BreadCrumb/SimpleBreadCrumb';
import CustomButton from 'src/components/widgets/buttons/CustomButton';
import useSettings from 'src/hooks/useSettings';
import setTitle from 'src/utils/setTitle';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ClearIcon from '@material-ui/icons/Clear';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import SwapVerticalCircleIcon from '@material-ui/icons/SwapVerticalCircle';
import moment from 'moment';
import toast from 'react-hot-toast';
import { userApi } from 'src/api/userApi';
import { UserActivity } from 'src/components/widgets/display-blocks/user-activity';
import useAuth from 'src/hooks/useAuth';
import time from 'src/utils/time';
import TranslateIcon from '@material-ui/icons/Translate';
import { translationApi } from 'src/api/translationApi';
import * as Yup from 'yup';
import SImpleModal from 'src/components/widgets/modal/SImpleModal';
import DocumentViewer from 'src/components/widgets/modal/DocumentViewer';
import FileUploadDragDrop from 'src/components/widgets/fileUpload/FileUploadDragDrop';
import CancelIcon from '@material-ui/icons/Cancel';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import LogTimeModal from 'src/components/widgets/modal/LogTimeModal';

const smallShadow = '0px 0px 4px 0px rgba(0, 0, 0, 0.25)';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    '.MuiPaper-root-MuiCard-root': {
      boxShadow: smallShadow,
    },
    leftSideBarRoot: {
      padding: '10px 10px',
    },
    label: {
      letterSpacing: ' 0.15px',
      color: '#231F20',
      fontWeight: '600',
      paddingBottom: '6px',
      display: 'block',
      fontSize: '14px',
    },
    meta: {
      width: '100%',
      gap: 8,
      fontSize: '14px',
      marginBottom: 8,
      fontWeight: 500,
      color: theme.palette.grey[500],
      '& .author': {
        fontWeight: 600,
        color: theme.palette.grey[600],
      },
    },
    box: {
      '& .MuiAutocomplete-root': {
        '& .Mui-disabled': {
          cursor: 'not-allowed',
        },
      },
      '& .orgEditCase': {
        padding: 0,
        '& .MuiAccordion-root': {
          '& .MuiAccordionDetails-root': {},
        },
        '& .MuiAccordionDetails-root': {
          padding: '16px',
          background: '#fff!important',
          borderRadius: '0 0 4px 4px',
        },
        '& .MuiAccordionSummary-root': {
          background: '#fff !important',
          color: '#231F20 !important',
          borderRadius: '4px 4px 0 0',
          padding: '0 16px',
          flexDirection: 'initial',
        },
      },
      '& .MuiAccordionSummary-expandIconWrapper ': {
        color: '#000 !important',
      },
      '& .boxSub': {
        background: '#fff',
        padding: '16px !important',
        boxShadow: smallShadow,
        borderRadius: '4px',
        '& .boxHeader': {
          background: '#fff',
          color: '#231F20',
          padding: '7px 24px',
          borderRadius: '4px 4px  0  0',
          height: '39px',
        },

        '& p': {
          margin: '25px 0 10px',
        },
        '& .editorMarkup': {
          '& .wmde-markdown-var': {
            border: '1px solid transparent',
          },
        },
        '& .editorMarkupError': {
          '& .wmde-markdown-var': {
            border: '1px solid #c22027',
          },
        },
        '& .btnComments': {
          margin: '16px 0 0',
          '& button+button': {
            marginLeft: '12px',
          },
        },
        '& .MuiFormHelperText-root': {
          margin: 0,
        },
      },
      '& .commentBoxRoot': {
        marginTop: '25px',
      },
      '& .commentBox': {
        marginTop: '10px',
        padding: '10px 25px',
        border: '1px solid #eceaea',
        borderRadius: '5px',
        '& .commentBoxInner': {
          display: 'flex',
          alignItems: 'start',

          '& h4': {
            fontSize: '14px',
            fontWeight: 'bold',
            margin: 0,
          },
          '& span': {
            fontSize: '14px',
            marginLeft: '10px',
          },
        },
        '& p': {
          margin: '0px 0px 0px 0px !important',
        },
        '& span': {
          fontSize: '14px',
        },
        '& span+span': {
          marginLeft: '10px',
        },
      },
      '& .commentBox.current': {
        border: '1px solid #231f20',
      },
      '& select, & .MuiAutocomplete-inputRoot, & input': {
        height: '38px',
        padding: '0 10px',
        lineHeight: '116.7%',
        color: '#696969',
        width: '100%',
        '& .MuiAutocomplete-input': {
          padding: 0,
        },
      },
      '& .customField': {
        height: '38px',
        padding: '0 10px',
        lineHeight: '116.7%',
        color: '#696969',
        width: '100%',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        '& .MuiChip-root': {
          border: 0,
          borderRadius: '4px',
          margin: '2px 0',
          background: 'rgb(241 240 240)',
        },
      },
      '& .labelsDataCss .MuiAutocomplete-inputRoot': {
        height: 'initial',
      },

      '& .labelField': {
        // width: '50%',
      },
      '& .caseRightInner+.caseRightInner': {
        marginTop: '16px',
      },
      '& .sLa': {
        display: 'flex',
        fontSize: '14px',
        '& .btnTime': {
          width: '179px',
          height: '36px',
          borderRadius: '43px',
          background: '#B0B0B0',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& svg': {
            marginLeft: '10px',
          },
        },
        '& .btnTime.bgRed': {
          background: '#CC4D53',
        },
        '& .btnTimeFirst': {
          width: '179px',
          height: '36px',
          borderRadius: '43px',
          background: '#B0B0B0',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& svg': {
            marginLeft: '10px',
          },
        },
        '& .btnTimeFirst.bgRed': {
          background: '#CC4D53',
        },
        '& .resolveTime': {
          fontSize: '14px',
          fontWeight: 'bold',
          marginLeft: '10px',
          '& p': {
            fontSize: '12px',
            fontWeight: 'normal',
            margin: '2px 0 0',
          },
          '& svg': {
            marginLeft: '10px',
          },
        },
      },
      '& .jiraSync': {
        marginTop: 16,
        '& b': {
          fontSize: '14px',
        },
        fontSize: '12px',
      },
      '& .escalations': {
        display: 'flex',
        '& span': {
          fontSize: '14px',
        },
      },
      '& .rightSide': {
        boxShadow: smallShadow,
        '& hr': {
          border: 0,
          borderTop: '0.8px solid #E0E0E0',
          margin: '15px 0',
          borderWidth: '0.8px',
        },
      },
      '& .rightAccordion': {
        '& .MuiTypography-h4': {
          color: '#231f20',
          fontSize: '14px',
          fontWeight: 600,
        },
        '& label': {
          color: '#231f20',
          fontSize: '12px',
          fontWeight: 600,
        },
      },
      '& .createdAt': {
        fontSize: '14px',
        color: '#777',
        '& p': {
          margin: '0',
        },
        '& span': {
          fontWeight: 500,
        },
      },
      '& .btnCommentInner': {
        marginTop: 2,
        display: 'flex',
        position: 'absolute',
        top: 10,
        right: 10,
        gap: '8px',
        '& button': {
          display: 'flex',
          cursor: 'pointer',
          border: '1px solid transparent',
          borderRadius: '3px',
          color: '#696969',
          backgroundColor: 'rgba(0 0 0/0)',
          transition: 'all 300ms',
          '&:hover': {
            backgroundColor: 'rgba(0 0 0/0.1)',
          },
        },
      },
      '& .attachmentsDiv': {
        marginTop: '30px',
        '& .editCommentFileParent': {
          display: 'flex',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
          gap: '15px',
          alignItems: 'center',
          '& a': {
            position: 'relative',
            display: 'inline-flex',
            borderRadius: 4,
            marginBottom: 8,
            width: 200,
            height: 200,
            padding: 4,
            color: ' #757575',
            boxSizing: 'border-box',
            justifyContent: 'center',
            alignItems: 'center',
            border: `1px solid ${theme.palette.grey[300]}`,
            cursor: 'pointer',
            '&  .editCommentFileEdit': {
              position: 'absolute',
              top: '-10px',
              right: '-10px',
              cursor: 'pointer',
              color: '#C22027',
            },
            '&  span': {
              position: 'absolute',
              background: ' #f7f7f7',
              fontSize: '10px',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%)',
              padding: '2px',
              fontWeight: 'bold',
            },
          },
        },
        '& a img': {
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        },
        '& img': {
          width: ' 60px',
          height: '67px',
          objectFit: 'contain',
        },
      },
    },
    commentHelperText: {
      margin: '0px',
    },
    commentsTabRoot: {
      marginTop: '15px',
      borderRadius: '4px 4px 0 0',
      '& .MuiTabs-flexContainer': {
        backgroundColor: '#ddd',
        height: '39px',
        border: 'none',
      },
      '& .Mui-selected': {
        '& .MuiTab-wrapper': {
          color: '#333',
        },
      },
      '& .MuiButtonBase-root.Mui-selected[aria-selected="true"]': {
        fontWeight: '700',
        backgroundColor: 'transparent !important',
        border: 'none !important',
      },
      '& .MuiButtonBase-root': {
        textTransform: 'capitalize',
        color: '#333',
        fontWeight: 400,
        fontSize: '1rem',
        minHeight: '39px!important',
        minWidth: '155px !important',
      },
      '& .MuiButtonBase-root:first-child': {
        marginLeft: 0,
      },
      '& .MuiTabs-indicator': {
        backgroundColor: '#C22027!important',
        height: '4px!important',
        top: '39px!important',
        display: 'block!important',
      },
    },
    commentsPreview: {
      // marginLeft: '50px',
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      '& p': {
        margin: '0px!important',
      },
      '& figure': {
        margin: '0px!important',
      },
      '& .MuiAvatar-root': {
        backgroundColor: '#231f20',
        width: '40px',
        height: '40px',
      },
      '& img': {
        width: ' 60px',
        height: '67px',
        border: `1px solid ${theme.palette.grey[300]}`,
        objectFit: 'contain',
      },
      '& .MuiListItemText-root': {
        margin: 0,
        fontSize: '14px',
        fontWeight: 300,
        '& strong': {
          color: '#000',
        },
      },
    },
    timelineRoot: {
      margin: 0,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      '& p': {
        margin: '0px!important',
        wordBreak: 'break-all',
      },
      '& .MuiAvatar-root': {
        backgroundColor: '#231f20',
        width: '40px',
        height: '40px',
      },
      '& img': {
        width: ' 60px',
        height: '67px',
        objectFit: 'contain',
      },
      '& .MuiListItemText-root': {
        margin: 0,
        fontSize: '14px',
        fontWeight: 300,
        '& strong': {
          color: '#000',
        },
      },
    },

    detailsRoot: {
      display: 'grid',
      gridTemplateColumns: '49% 49%',
      rowGap: '5px',
      columnGap: '10px',
      '& .MuiAutocomplete-root .MuiChip-root': {
        maxWidth: '115px',
      },
      '& .MuiAutocomplete-root .MuiAutocomplete-tag': {
        fontSize: '12px',
      },
      [theme.breakpoints.down(1850)]: {
        gridTemplateColumns: '1fr',
      },
    },
    transalationBtn: {
      display: 'flex',
      justifyContent: 'flex-end',
      cursor: 'pointer',
    },
    externalComment: {
      backgroundColor: '#fff9f9',
      boxShadow: '0px 0px 1px 1px #ffb0b0',
    },
    commentEditorContainer: {
      border: '2px solid white',
      borderRadius: 4,
      '& .editCommentFileParent': {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: '15px',
        '& a': {
          position: 'relative',
          display: 'block',
          color: ' #757575',
          cursor: 'pointer',
          border: `1px solid ${theme.palette.grey[300]}`,
          '&  .editCommentFileEdit': {
            position: 'absolute',
            top: '-10px',
            right: '-10px',
            cursor: 'pointer',
            color: '#C22027',
          },
          '&  span': {
            position: 'absolute',
            background: ' #f7f7f7',
            fontSize: '10px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%)',
            padding: '2px',
            fontWeight: 'bold',
          },
          '& img': {
            width: ' 60px',
            height: '67px',
            objectFit: 'contain',
          },
        },
      },
    },
    commentOptionButtons: {
      display: 'flex',
      padding: '10px!important',
      gap: '10px',
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 4,
      '& .internalCommentLink': {
        cursor: 'pointer',
        paddingBottom: '5px',
        textDecoration: 'underline',
        textUnderlineOffset: '3px',
        // transition: '0.3s linear',
        '&:hover': {
          textDecoration: 'underline #f5f5f5',
          // color: '#f5f5f5'
        },
      },
      '& .externalCommentLink': {
        cursor: 'pointer',
        paddingBottom: '5px',
        textDecoration: 'underline',
        textUnderlineOffset: '3px',
        '&:hover': {
          textDecoration: 'underline #f5f5f5',
          // color: '#f5f5f5'
        },
      },
    },
    customClassAddComment: {
      padding: '0px 5px',
    },
  })
);

type TimelineTypes = {
  createdAt: string;
  description: string;
  changeFrom: string;
  changeTo: string;
  change?: string;
  id: string;
  createdBy: {
    id: string;
    name: string;
  };
  attachmentPath?: string;
};

type TimelineSectionTypes = {
  user: any;
  data: TimelineTypes[];
  caseData: any;
  toggleIsLoad: () => void;
  languageType?: string;
  showTranslation?: any;
  handleDocumentClick?: any;
  handleImageClick?: any;
};

type CommentLinksTypes = 'none' | 'external' | 'internal';

export const Comments = ({
  toggleIsLoad,
  caseData,
  user,
  languageType,
  showTranslation,
  handleDocumentClick,
  handleImageClick,
}: any) => {
  const classes = useStyles();
  const commentRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [commentValue, setCommentValue] = useState('');
  const [commentEdit, setCommentEdit] = useState<any>({});
  const [filePath, setFilePath] = useState<Array<any>>([]);
  const [commentType, setCommentType] = useState<CommentLinksTypes>('none');
  const [isExternalComment, setIsExternalComment] = useState<boolean>(false);
  const [commentDeleteId, setCommentDeleteId] = useState<string>('');
  // const [editCommentFiles, setEditCommentFiles] = useState<string[]>([]);
  const handleCancelComment = () => {
    setCommentType('none');
    setCommentValue('');
    setIsEditing(false);
  };
  const isNotEmpty = (val: string | any[]) => {
    if (Array.isArray(val)) {
      return val.length > 0;
    }
    return typeof val === 'string' && val.trim() !== '';
  };
  const handleSaveComment = async (e) => {
    e.preventDefault();
    try {
      if (isNotEmpty(commentValue) || isNotEmpty(filePath)) {
        const payload = {
          caseId: caseData?.id,
          description: commentValue,
          isExternal: isExternalComment,
          attachmentPath: filePath,
        };
        const response = await casesApi?.createComment(payload);
        if (response?.status === 201) {
          toast.success('Comment Added');
          setCommentValue('');
          setCommentType('none');
          toggleIsLoad();
        }
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const deleteComment = async (caseId: String) => {
    try {
      const response = await casesApi?.deleteComments(caseId, commentDeleteId);
      if (response?.status === 200) {
        toast.success('Comment Deleted');
        toggleIsLoad();
        setOpenDialog(false);
        toggleIsLoad();
        if (isEditing) {
          setIsEditing(false);
          setCommentValue('');
          setCommentEdit({
            commentId: '',
          });
        }
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const editCommentAPI = async (e) => {
    e.preventDefault();
    try {
      if (isNotEmpty(commentValue)) {
        const payload = {
          caseId: commentEdit?.caseId,
          description: commentValue,
          attachmentPath: filePath,
        };
        const response = await casesApi?.editCaseComment(
          commentEdit?.commentID,
          payload
        );
        if (response?.status === 200) {
          toast.success('Comment Updated');
          toggleIsLoad();
          setCommentValue('');
          setCommentType('none');
          setIsEditing(false);
        }
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const editComment = async (
    caseIDs: string,
    commentId: string,
    commentDes: string,
    indexMap: Number,
    filePaths: string[]
  ) => {
    setIsEditing(true);
    setFilePath(filePaths);
    setCommentValue(commentDes);
    setCommentEdit({
      caseId: caseIDs,
      commentID: commentId,
      currentIndex: indexMap,
    });
  };
  const handleEditCommentDeleteFile = (e, index) => {
    e.stopPropagation();
    const updatedFiles = [...filePath];
    updatedFiles.splice(index, 1);
    setFilePath(updatedFiles);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  // const handleCheckExternalComment = () => {
  //   setIsExternalComment(!isExternalComment);
  // };

  const handleClickInternalComment = () => {
    setCommentType('internal');
    setIsExternalComment(false);
  };

  const handleClickExternalComment = () => {
    setCommentType('external');
    setIsExternalComment(true);
  };

  function uploadAdapter(loader) {
    return {
      upload: () =>
        new Promise((resolve) => {
          loader.file.then(async (file) => {
            const formData = new FormData();
            formData.append('file', file);
            const image = await userApi.uploadAttachments(formData);
            resolve({
              default: `${image?.data?.blob_url}`,
            });
          });
        }),
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
      uploadAdapter(loader);
  }

  return (
    <Box padding="17px 0 24px">
      {commentType === 'none' && (
        <Box className={classes.commentOptionButtons}>
          <Box
            className="internalCommentLink"
            onClick={handleClickInternalComment}>
            Add Internal Comment
          </Box>{' '}
          |
          <Box
            className="externalCommentLink"
            onClick={handleClickExternalComment}>
            Add External Comment
          </Box>
        </Box>
      )}
      {commentType !== 'none' && (
        <div
          style={{ flexGrow: 1, marginBottom: 16 }}
          data-color-mode="light"
          className="editorMarkup"
          ref={commentRef}>
          <div className={classes.commentEditorContainer}>
            <CKEditor
              editor={ClassicEditor}
              data={commentValue}
              onChange={(event, editor) => {
                const data = editor.getData();
                setCommentValue(data);
                // Create a temporary DOM element to parse the content
                // const tempDiv = document.createElement('div');
                // tempDiv.innerHTML = data;

                // // Extract all image elements
                // const imageElements = tempDiv.querySelectorAll('img');

                // // Extract image sources
                // const imageSources = Array.from(imageElements).map((image) => image?.src);
                // setFilePath((prev) => [...prev, imageSources.length > 0 ? imageSources : '']);
                // setCommentValue(data);
              }}
              config={{
                placeholder: 'Enter your comment here',
                toolbar: [
                  '|',
                  'heading',
                  '|',
                  'bold',
                  'italic',
                  'link',
                  'bulletedList',
                  'numberedList',
                  'blockQuote',
                  'uploadimage',
                ],
                extraPlugins: [uploadPlugin],
              }}
            />
            <FileUploadDragDrop setFilePath={setFilePath} filePath={filePath} />
            {isEditing && (
              <div className="editCommentFileParent">
                {isEditing &&
                  filePath?.map((item, index) => {
                    const getFileType = (items) => {
                      if (items.includes('.pdf')) {
                        return 'PDF';
                      }
                      if (items.includes('.doc')) {
                        return 'DOC';
                      }
                      if (items.includes('.docx')) {
                        return 'DOCX';
                      }
                      if (items.includes('.txt')) {
                        return 'TXT';
                      }
                      if (items.includes(".xls")) {
                        return "XLS";
                      }
                      if (items.includes(".xlsx")) {
                        return "XLSX";
                      }
                      if (items.includes(".csv")) {
                        return "CSV";
                      }
                      if (items.includes(".tsv")) {
                        return "TSV";
                      }
                      return null;
                    };

                    const fileType = getFileType(item);
                    if (
                      item.includes('.pdf') ||
                      item.includes('.doc') ||
                      item.includes('.docx') ||
                      item.includes(".xls") ||
                      item.includes(".xlsx") ||
                      item.includes(".csv") ||
                      item.includes(".tsv") ||
                      item.includes('.txt')
                    ) {
                      return (
                        <a
                          role="presentation"
                          onClick={() => handleDocumentClick(item)}>
                          {' '}
                          <span>{fileType}</span>
                          <InsertDriveFileIcon sx={{ fontSize: '60px' }} />
                          <CancelIcon
                            onClick={(e) =>
                              handleEditCommentDeleteFile(e, index)
                            }
                            className="editCommentFileEdit"
                          />
                        </a>
                      );
                    }
                    const imageFormats = [
                      '.jpg',
                      '.jpeg',
                      '.png',
                      '.gif',
                      '.bmp',
                      '.tiff',
                      '.webp',
                      '.svg',
                      '.ico',
                    ];
                    if (
                      imageFormats.some((extension) =>
                        item?.includes(extension)
                      )
                    ) {
                      return (
                        <>
                          <a
                            role="presentation"
                            onClick={() => handleImageClick(item)}>
                            <img src={item} alt="img" />
                            <CancelIcon
                              onClick={(e) =>
                                handleEditCommentDeleteFile(e, index)
                              }
                              className="editCommentFileEdit"
                            />
                          </a>
                        </>
                      );
                    }
                    return null;
                  })}
              </div>
            )}
          </div>
          <div className="btnComments">
            {(user?.permissions?.includes('organization-case-comment-update') ||
              user?.permissions?.includes(
                'organization-case-comment-create'
              )) &&
              (!isEditing ? (
                <CustomButton
                  value={
                    isExternalComment
                      ? 'Add External Comment'
                      : 'Add Internal Comment'
                  }
                  customClass={classes.customClassAddComment}
                  color="secondary"
                  onClick={handleSaveComment}
                />
              ) : (
                user?.permissions?.includes(
                  'organization-case-comment-update'
                ) && (
                  <>
                    <CustomButton
                      value="Update Comment"
                      color="secondary"
                      onClick={(e) => editCommentAPI(e)}
                    />

                    <CustomButton
                      value="Cancel"
                      color="text"
                      onClick={handleCancelComment}
                    />
                  </>
                )
              ))}
            {!isEditing && (
              <CustomButton
                value="Cancel"
                color="text"
                onClick={() => setCommentType('none')}
              />
            )}
          </div>
        </div>
      )}
      {caseData?.comment?.length !== 0 && (
        <>
          <Typography
            variant="body2"
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              margin: '0px 0px 8px 0px',
            }}>
            Comments
          </Typography>
          <List className={classes.commentsPreview}>
            {caseData?.comment?.map((comment, i) => {
              function extractTextFromHTML(htmlString) {
                // Create a new DOMParser
                const parser = new DOMParser();

                // Parse the HTML string
                const doc = parser.parseFromString(htmlString, 'text/html');

                // Get the text content from the parsed HTML
                const { textContent } = doc.body;

                return textContent;
              }
              const extractedText = extractTextFromHTML(comment?.description);
              return (
                <UserActivity
                  customClasses={`${isEditing && commentEdit?.currentIndex === i
                    ? 'current'
                    : ''
                    }
              ${comment?.isExternal && classes.externalComment} `}
                  meta={{
                    description: '',
                    createdBy: comment?.createdByName,
                    createdAt: comment?.createdAt,
                    isExternal: comment?.isExternal || false,
                  }}
                  details={parseHTML(comment?.description)}
                  filesPath={comment?.attachmentPath}
                  actions={[
                    <Button
                      size="small"
                      title="Translate"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        showTranslation(
                          extractedText,
                          languageType,
                          'caseCommentData',
                          comment?.id
                        );
                      }}
                      sx={{ padding: '0' }}>
                      Translate
                    </Button>,
                    <Button
                      size="small"
                      title="Edit comment"
                      type="button"
                      onClick={() => {
                        editComment(
                          caseData?.id,
                          comment?.id,
                          comment?.description,
                          i,
                          comment?.attachmentPath
                        );
                        setCommentType(
                          comment?.isExternal ? 'external' : 'internal'
                        );
                        // commentRef.current?.scrollIntoView({ behaviour: 'smooth' });
                      }}
                      sx={{ padding: '0' }}>
                      Edit
                    </Button>,
                    <Button
                      size="small"
                      title="Delete comment"
                      type="button"
                      onClick={() => {
                        setOpenDialog(true);
                        setCommentDeleteId(comment?.id);
                      }}>
                      Delete
                    </Button>,
                    <Dialog
                      open={openDialog}
                      onClose={handleCloseDialog}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description">
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Are you sure, you want to delete a comment?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary">
                          Cancel
                        </Button>
                        <Button
                          onClick={() => deleteComment(caseData?.id)}
                          color="primary"
                          autoFocus>
                          Yes
                        </Button>
                      </DialogActions>
                    </Dialog>,
                  ]}
                />
              );
            })}
          </List>
        </>
      )}
    </Box>
  );
}; // ----> Comments()

export const TimelineSection = ({
  user,
  data,
  caseData,
  toggleIsLoad,
  languageType,
  showTranslation,
  handleDocumentClick,
  handleImageClick,
}: TimelineSectionTypes) => {
  const classes = useStyles();
  return (
    <>
      {(user?.permissions?.includes('organization-case-comment-get-all') ||
        user?.permissions?.includes(
          'organization-organization-case-comment-create'
        )) && (
          <Comments
            caseData={caseData}
            toggleIsLoad={toggleIsLoad}
            user={user}
            languageType={languageType}
            showTranslation={showTranslation}
            handleDocumentClick={handleDocumentClick}
            handleImageClick={handleImageClick}
          />
        )}
      {user?.permissions?.includes(
        'organization-case-timeline-activity-get-all'
      ) && (
          <List className={classes.timelineRoot}>
            {data?.length ? (
              <>
                <span
                  style={{
                    fontSize: '16px',
                    fontWeight: 600,
                  }}>
                  Logs
                </span>
                {caseData?.logs?.map((item: TimelineTypes) => (
                  <UserActivity
                    key={item.id}
                    // image={{ src: '/static/icons/avatar.png', alt: item?.createdBy?.name }}
                    meta={{
                      description: item.description,
                      createdBy: item.createdBy.name,
                      createdAt: item.createdAt,
                      change:
                        item?.change === null ? '' : parseHTML(item?.change),
                    }}
                    filesPath={item?.attachmentPath}
                    actions={[
                      <Button
                        size="small"
                        title="Translate"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          if (item?.change !== null) {
                            showTranslation(
                              item?.change,
                              languageType,
                              'caseLogData',
                              item?.id
                            );
                          }
                        }}
                        sx={{ padding: '0' }}>
                        Translate
                      </Button>,
                    ]}
                    details={
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                        }}>
                        {item?.changeFrom && (
                          <span style={{ fontSize: '12px' }}>
                            {item?.changeFrom === null
                              ? 'none'
                              : parseHTML(item?.changeFrom)}
                          </span>
                        )}
                        {item?.changeTo && (
                          <>
                            <span
                              style={{
                                flex: '0 0 40px',
                                textAlign: 'center',
                                margin: '-2px 0 0',
                              }}>
                              <ArrowRightAltIcon />
                            </span>
                            <span style={{ fontSize: '12px' }}>
                              {item?.changeTo === null
                                ? 'none'
                                : parseHTML(item?.changeTo)}
                            </span>
                          </>
                        )}
                      </div>
                    }
                  />
                ))}
              </>
            ) : (
              <>
                <ListItem style={{ display: 'flex', justifyContent: 'center' }}>
                  <strong>No Data Found</strong>
                </ListItem>
              </>
            )}
          </List>
        )}
    </>
  );
};
// ----> TimelineSection()

export const JiraSync = () => null;

function EditCaseByProject() {
  const orgEditComponent = true;
  setTitle('CRM | Cases Edit');
  const { user } = useAuth();
  const { caseID } = useParams();
  const navigate = useNavigate();
  let hideItem = null;
  let returnUrl = '/erp/cases';
  if (orgEditComponent) {
    const { hideItems }: any = useOutletContext();
    hideItem = hideItems || false;
    const location = useLocation();
    const locationSlugs = location.pathname.split('/');
    locationSlugs.pop(); // remove case-id from location
    returnUrl = locationSlugs.join('/');
  }
  const handleReturn = () => {
    navigate(returnUrl);
  };
  const [billingAccounts, setBillingAccounts] = useState([]);
  const [logTimeModalOpen, setlogTimeModalOpen] = useState<boolean>(false);
  const [billingType, setBillingType] = useState(null);
  const [timeEstimate, setTimeEstimate] = useState('');
  const [caseDataOld, setCaseDataOld] = useState<any>(null);
  const [caseData, setCaseData] = useState<any>(null);
  const [isSyncing, setIsSyncing] = useState<boolean>(false);
  const [labelsData, setLabelsData] = useState<any>([]);
  const [labelInputValue, setLabelInputValue] = useState('');
  const [labelsOptions, setLabelsOptions] = useState<any>([]);
  const [statusInputValue, setStatusInputValue] = useState<any>({});
  const [statuses, setStatuses] = useState([]);
  const classes = useStyles();
  const [bugOccur, setBugOccur] = useState('');
  const [bugExpect, setBugExpect] = useState('');
  const { settings } = useSettings();
  const [organization, setOrganization] = useState<any>({
    id: '',
    name: '',
  });

  const [project, setProject] = useState<any>({
    id: '',
    name: '',
  });
  const [priorityAllOptions, setPriorityAllOptions] = useState<any>([]);
  const [caseTypeOptions, setCaseTypeOptions] = useState<any>([]);
  const [caseType, setCaseType] = useState<any>({});
  const [priority, setPriority] = useState<any>({});
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const toggleIsLoad = () => setIsLoad(!isLoad);
  const [projectSponserId, setProjectSponserId] = useState<any>([]);
  const [stakeholderId, setStakeholderId] = useState<any>([]);
  const [severity, setSeverity] = useState(null);
  const [stakeholderInput, setStakeholderInput] = useState<any>('');
  const [escalationManagerId, setEscalationManagerId] = useState<any>('');
  const [escalationManagerInput, setEscalationManagerInput] = useState<any>('');
  const [assigneeId, setAssigneeId] = useState<any>({});
  const [allInternalUsers, setAllInternalUsers] = useState<any>([]);
  const [allStakeholders, setAllStakeholders] = useState<any>([]);
  const [allLogs, setAllLogs] = useState<any>([]);
  const [description, setDescription] = useState<string>('');
  const [tabValue, setTabValue] = useState<number>(1);
  const [languageType, setLanguageType] = useState<string>('en');
  const [allSeverities, setAllSeverities] = useState<any>([]);
  const [isImagePopupOpen, setIsImagePopupOpen] = useState<boolean>(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState<string>('');
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState<string>('');
  const [isDocumentViewerOpen, setIsDocumentViewerOpen] =
    useState<boolean>(false);
  const handleDocumentClick = (documentUrl) => {
    setSelectedDocumentUrl(documentUrl);
    setIsDocumentViewerOpen(true);
  };

  const convertSecondsToTimeString = (seconds) => {

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    let result = '';
    if (hours > 0) {
      result += `${hours}h `;
    }
    if (minutes > 0) {
      result += `${minutes}m`;
    }

    return result.trim();
  };


  const handleCloseDocumentViewer = () => {
    setIsDocumentViewerOpen(false);
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setIsImagePopupOpen(true);
  };

  const handleCloseImagePopup = () => {
    setIsImagePopupOpen(false);
  };
  const handleTabValue = (e: any, newValue: number) => {
    setTabValue(newValue);
  };

  const handleLogTimeModalOpen = (state) => {
    setlogTimeModalOpen(state);
  };


  const getCaseById = async () => {
    try {
      const response = await casesApi?.getCaseById(caseID);
      const res = response?.data;
      setBillingAccounts(res.billingAccount || []);
      setCaseData(res);
      setCaseDataOld(res);
      setOrganization({
        id: res?.organization?.id,
        name: res?.organization?.name,
      });
      if (res?.caseType?.name === 'Application Bug') {
        setSeverity({ id: res?.severity?.id, name: res?.severity?.name });
      }
      setProject({
        id: res?.project?.id,
        name: res?.project?.name,
      });
      setProjectSponserId(res?.projectSponsor);
      setStakeholderId(res?.stakeHolder);
      setAssigneeId(res?.assignee);
      setEscalationManagerId(res?.escalationManager);
      setEscalationManagerInput(res?.escalationManager?.name);
      setPriority(res?.priority);
      setCaseType(res?.caseType);
      setLabelsData(res?.labels);
      setStatusInputValue(res?.status);
      setAllLogs(res?.logs || []);
      setDescription(res?.description || '');
      setBugOccur(res?.bugQuestions[0]?.description || '');
      setBugExpect(res?.bugQuestions[1]?.description || '');
      setBillingType({ id: res?.billingAccountType || '', name: res?.billingAccountType || '' });
      const timeString = convertSecondsToTimeString(res?.timeEstimate);
      setTimeEstimate(timeString);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setCaseData(null);
        setCaseDataOld(null);
        setOrganization({});
        setProject({});
        setProjectSponserId({});
        setStakeholderId({});
        setAssigneeId({});
        setEscalationManagerId('');
        setEscalationManagerInput('');
        setBugOccur('');
        setBugExpect('');
        setPriority({});
        setLabelsData([]);
        setStatusInputValue({});
        setAllLogs([]);
        setSeverity(null);
        setBillingType(null);
      }
    }
  };
  // const getAllExternalUsersAutoComplete = async () => {
  //   try {
  //     const res = await casesApi?.getAutoCompleteUser(true);
  //     setAllExternalUsers(res?.data);
  //   } catch (error) {
  //     if (error?.response?.status !== 401) {
  //       toast.error(error?.response?.data?.message);
  //       setAllExternalUsers([]);
  //     }
  //   }
  // };
  const getAllInternalUsersAutoComplete = async () => {
    try {
      const res = await casesApi?.getAutoCompleteUser(false);
      setAllInternalUsers(res?.data);
      setAllStakeholders(res?.data);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setAllInternalUsers([]);
        setAllStakeholders([]);
      }
    }
  };

  const getAutoCompletePriority = async () => {
    try {
      const res = await casesApi?.getPriorities();
      setPriorityAllOptions(res?.data);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setPriorityAllOptions([]);
      }
    }
  };

  const getAllSeverities = async () => {
    try {
      const response = await casesApi.getAutoCompleteSeverity();
      setAllSeverities(response?.data);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setAllSeverities([]);
      }
    }
  };

  const getCaseType = async () => {
    try {
      const response = await casesApi.getCaseTypes();
      setCaseTypeOptions(response?.data);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setCaseTypeOptions([]);
      }
    }
  };
  const getAutoCompleteStatus = async () => {
    try {
      const res = await casesApi?.getStatuses();
      setStatuses(res?.data);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setStatuses([]);
      }
    }
  };
  const escalateById = async () => {
    try {
      const response = await casesApi?.escalateById(caseData?.id);
      if (response?.status === 201) {
        setCaseData(response?.data);
        getCaseById();
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setCaseData([]);
      }
    }
  };

  const syncJiraByCaseId = async () => {
    try {
      setIsSyncing(true);
      const res = await casesApi?.syncJiraByCaseId(caseData?.id);
      if (res?.status === 200) {
        toast.success('successfully Synced');
        getCaseById();
      }
      setIsSyncing(false);
    } catch (error) {
      setIsSyncing(false);
      const errorMessage = error?.response?.data?.message;
      if (error?.response?.status !== 401) {
        toast.error(errorMessage);
      }
    }
  };
  const updateIndividual = async (columnName: any, values: any) => {
    try {
      const payload = {
        [columnName]: values,
      };
      const caseId = caseData?.id;
      const response = await casesApi?.updateCase(caseId, payload);
      if (response?.status === 200) {
        toast.success('Case Successfully Updated');
        getCaseById();
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const BreadCrumbsData = {
    title: 'Edit Case',
    links: [
      {
        name: 'cases',
        to: '/erp/cases',
      },
      {
        name: `${caseData?.caseKey}`,
        to: `/erp/cases/${caseID}`,
        color: `${caseData?.issueType?.name === 'Bug' ? 'red' : '#4baee8'}`,
      },
    ],
  };
  const BreadCrumbCaseProj = {
    title: '',
    links: [
      {
        name: 'cases',
        to: `${hideItem?.link}`,
      },
      {
        name: `${caseData?.caseKey}`,
        to: `/erp/cases/${caseID}`,
        color: 'red',
      },
    ],
  };

  const hasMultipleNullValues = (obj: any) => {
    let nullCount = 0;

    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key) && obj[key] === null) {
        nullCount++;
        if (nullCount > 1) {
          return true; // Found multiple null values
        }
      }
    }

    return false; // No or only one null value found
  };

  const hasMultipleNulls = hasMultipleNullValues(assigneeId);
  const getLabels = async () => {
    try {
      const res = await casesApi?.getAutoCompleteLabels();
      const uniqueArray = res?.data?.filter(
        (value, index, self) => self.indexOf(value) === index
      );
      setLabelsOptions(uniqueArray);
    } catch (error) {
      if (error?.response?.status !== 401) {
        setLabelsOptions([]);
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const showTranslation = async (
    textData: string,
    lang?: string,
    field?: string,
    id?: string
  ) => {
    const payload = {
      text: textData,
      target_lang: lang,
    };

    try {
      const res = await translationApi?.translateText(payload);
      const detectedLanguage =
        res?.data?.data?.translations[0].detectedSourceLanguage;
      const translatedText = res?.data?.data?.translations[0].translatedText;
      const updatedComments = caseData.comment.map((comment) => {
        if (comment.id === id) {
          return { ...comment, description: translatedText };
        }
        return comment;
      });
      const updatedLogs = caseData.logs.map((log) => {
        if (log.id === id) {
          return { ...log, change: translatedText };
        }
        return log;
      });
      // Define a conditional object to specify how to update the state based on the field
      const fieldActions = {
        caseSummary: () => {
          setCaseData({
            ...caseData,
            summary: translatedText,
          });
        },
        caseCommentData: () => {
          setCaseData({
            ...caseData,
            comment: updatedComments,
          });
        },
        caseLogData: () => {
          setCaseData({
            ...caseData,
            logs: updatedLogs,
          });
        },
        caseDescription: () => {
          setDescription(translatedText);
        },
        // Add more fields and actions as needed
      };

      // Call the appropriate action based on the field
      if (field && fieldActions[field]) {
        fieldActions[field]();
      }

      setLanguageType(detectedLanguage);
    } catch (error) {
      if (error?.response?.status !== 401) {
        setCaseData([]);
        toast.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    getCaseById();
  }, [isLoad]);

  useEffect(() => {
    getAutoCompletePriority();
    getAutoCompleteStatus();
    getLabels();
    getCaseType();
    getAllSeverities();
    setTimeout(() => {
      getAllInternalUsersAutoComplete();
    }, 800);
    if (
      !user?.permissions?.includes('organization-case-get-by-id') &&
      !user?.permissions?.includes('organization-case-update') &&
      !user?.permissions?.includes(
        'organization-case-timeline-activity-get-all'
      ) &&
      !user?.permissions?.includes('organization-case-comment-get-by-id') &&
      !user?.permissions?.includes('organization-case-comment-update') &&
      !user?.permissions?.includes('organization-case-comment-create') &&
      !user?.permissions?.includes('organization-case-comment-delete') &&
      !user?.permissions?.includes('organization-case-comment-get-all') &&
      !user?.permissions?.includes('organization-case-escalate-read') &&
      !user?.permissions?.includes('organization-case-jira-sync-read') &&
      !user?.permissions?.includes('organization-case-status-update')
    ) {
      window.location.href = '/admin/not-found';
    }
  }, []);

  return (
    <>
      {
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 8,
          }}
          className={classes.box}>
          <Container
            maxWidth={settings.compact ? 'xl' : false}
            className={`${hideItem?.customClass ? 'orgEditCase' : ''}`}>
            {hideItem?.heading !== false ? (
              <Grid container justifyContent="space-between" spacing={3}>
                <Grid item md={12}>
                  <SimpleBreadCrumb data={BreadCrumbsData} />
                </Grid>
              </Grid>
            ) : (
              <div style={{ marginTop: '-20px' }}>
                <SimpleBreadCrumb data={BreadCrumbCaseProj} />
              </div>
            )}

            <Formik
              enableReinitialize
              initialValues={{
                summary: caseData?.summary,
                description,
                sponsorId: projectSponserId,
                stakeHolderId: stakeholderId,
                escalationManagerId: escalationManagerInput,
                assigneeID: assigneeId,
                orgId: organization,
                projectId: project,
                caseOwner: caseData?.createdBy?.name,
                priority,
                severity,
                bugOccur,
                bugExpect,
                caseType: caseData?.caseType, // added for conditional yup validation when caseType is a bug
                labels: [],
                issueType: { id: '', name: '' },
                statusId: { id: '', name: '' },
              }}
              onSubmit={async (values) => {
                try {
                  const payload: any = {};
                  if (caseDataOld?.summary !== values?.summary) {
                    payload.summary = values?.summary;
                  }
                  if (caseDataOld?.description !== values?.description) {
                    payload.description = values?.description;
                  }
                  if (caseDataOld?.caseType?.name === 'Application Bug') {
                    payload.bugQuestions = [
                      {
                        questionKey: caseData?.bugQuestions[0]?.questionKey,
                        description: values?.bugOccur,
                      },
                      {
                        questionKey: caseData?.bugQuestions[1]?.questionKey,
                        description: values?.bugExpect,
                      },
                    ];
                  }
                  const caseId = caseData?.id;
                  const response = await casesApi?.updateCase(caseId, payload);
                  if (response?.status === 200) {
                    toast.success('Case Successfully Updated');
                    getCaseById();
                  }
                } catch (error) {
                  if (error?.response?.status !== 401) {
                    toast.error(error?.response?.data?.message);
                  }
                }
              }}
              validationSchema={Yup.object({
                summary: Yup.string()
                  .trim()
                  .required('Title required')
                  .max(256, 'Maximum 256 characters allowed'),
                description: Yup.string()
                  .trim()
                  .required('Description required'),
                bugOccur: Yup.string().when('caseType.id', {
                  is: (value) => value === '65005f2c0f96e012c1a9b1eb',
                  then: Yup.string()
                    .trim()
                    .required('Answer required')
                    .max(1000, 'Maximum 1000 characters allowed'),
                  otherwise: Yup.string(),
                }),
                bugExpect: Yup.string().when('caseType.id', {
                  is: (value) => value === '65005f2c0f96e012c1a9b1eb',
                  then: Yup.string()
                    .trim()
                    .required('Answer required')
                    .max(1000, 'Maximum 1000 characters allowed'),
                  otherwise: Yup.string(),
                }),
              })}>
              {({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    gap={4}
                    sx={{ flexWrap: 'nowrap', marginTop: '8px' }}>
                    {/** Left Side Bar */}
                    <Grid
                      item
                      xs={8}
                      sx={{
                        flexDirection: 'column',
                        gap: 4,
                      }}>
                      <Box className="boxSub">
                        <Grid container>
                          <Grid xs={12}>
                            <Grid
                              container
                              sx={{
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}>
                              <label>Title</label>
                              <Grid container sx={{ width: 'auto', gap: 2 }}>
                                <Grid className={classes.transalationBtn}>
                                  <Tooltip title="Translate">
                                    <TranslateIcon
                                      sx={{ height: 18 }}
                                      onClick={() => {
                                        showTranslation(
                                          values?.summary,
                                          languageType,
                                          'caseSummary'
                                        );
                                      }}
                                    />
                                  </Tooltip>
                                </Grid>
                                <Grid>
                                  {values?.summary?.length}
                                  /256
                                </Grid>
                              </Grid>
                            </Grid>
                            <TextField
                              type="text"
                              fullWidth
                              name="summary"
                              onChange={handleChange}
                              value={values?.summary}
                              error={Boolean(
                                touched?.summary && errors?.summary
                              )}
                              helperText={touched?.summary && errors?.summary}
                              inputProps={{
                                maxLength: 256,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sx={{ marginTop: 2 }}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}>
                              <label>Description</label>
                              <div className={classes.transalationBtn}>
                                <Tooltip title="Translate">
                                  <TranslateIcon
                                    sx={{ height: 18 }}
                                    onClick={() => {
                                      showTranslation(
                                        description,
                                        languageType,
                                        'caseDescription'
                                      );
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            </div>
                            <div
                              data-color-mode="light"
                              className="editorMarkup">
                              <CKEditor
                                editor={ClassicEditor}
                                data={description}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setDescription(data);
                                }}
                                style={{ maxHeight: '400px', overflow: 'auto' }}
                                config={{
                                  placeholder: 'Enter your Description here',
                                  toolbar: [
                                    'heading',
                                    '|',
                                    'bold',
                                    'italic',
                                    'link',
                                    'bulletedList',
                                    'numberedList',
                                    'blockQuote',
                                  ],
                                }}
                              />
                              {touched.description && errors.description && (
                                <div
                                  style={{
                                    color: '#C22027',
                                    fontFamily: 'Quicksand,sans-serif',
                                    fontWeight: '400',
                                    fontSize: '0.75rem',
                                    lineHeight: '1.66',
                                    textAlign: 'left',
                                    marginTop: '3px',
                                    marginRight: '14px',
                                    marginBottom: '0',
                                    // marginLeft: '14px',
                                  }}>
                                  {errors.description}
                                </div>
                              )}
                              {caseData?.caseType?.name ===
                                'Application Bug' && (
                                  <>
                                    <Box marginTop="16px">
                                      <label>
                                        {caseData?.bugQuestions[0]?.questionKey}
                                      </label>
                                      <TextField
                                        type="text"
                                        fullWidth
                                        // placeholder="bugquestion one"
                                        name="bugOccur"
                                        size="small"
                                        multiline
                                        rows={4}
                                        onChange={handleChange}
                                        value={values?.bugOccur}
                                        error={Boolean(
                                          touched?.bugOccur && errors?.bugOccur
                                        )}
                                        helperText={
                                          touched?.bugOccur && errors?.bugOccur
                                        }
                                      />
                                    </Box>
                                    <Box marginTop="16px">
                                      <label>
                                        {caseData?.bugQuestions[1]?.questionKey}
                                      </label>
                                      <TextField
                                        type="text"
                                        fullWidth
                                        // placeholder="bugquestion Two"
                                        name="bugExpect"
                                        size="small"
                                        multiline
                                        rows={4}
                                        onChange={handleChange}
                                        value={values?.bugExpect}
                                        error={Boolean(
                                          touched?.bugExpect && errors?.bugExpect
                                        )}
                                        helperText={
                                          touched?.bugExpect && errors?.bugExpect
                                        }
                                      />
                                    </Box>
                                  </>
                                )}
                              {caseData?.attachmentId?.length > 0 && (
                                <div className="attachmentsDiv">
                                  <Typography
                                    gutterBottom
                                    variant="h4"
                                    component="div"
                                    sx={{
                                      marginBottom: '16px',
                                      fontWeight: 'bolder',
                                    }}>
                                    Attachments
                                  </Typography>

                                  {caseData?.attachmentId?.length > 0 && (
                                    <div className="editCommentFileParent">
                                      {caseData?.attachmentId?.map((item) => {
                                        const splittedUrl = item?.split("?");
                                        splittedUrl[1] =
                                          "sp=racwdl&st=2024-02-27T13:21:58Z&se=2030-07-29T21:21:58Z&spr=https&sv=2022-11-02&sr=c&sig=YXOHT7%2Bx8VW3RDdgzehtPZLWJ6zHJz%2Bp3QtnduafL4o%3D";
                                        item = splittedUrl?.join("?");
                                        const getFileType = (items) => {
                                          if (items.includes('.pdf')) {
                                            return 'PDF';
                                          }
                                          if (items.includes('.doc')) {
                                            return 'DOC';
                                          }
                                          if (items.includes('.docx')) {
                                            return 'DOCX';
                                          }
                                          if (items.includes('.txt')) {
                                            return 'TXT';
                                          }
                                          if (items.includes(".xls")) {
                                            return "XLS";
                                          }
                                          if (items.includes(".xlsx")) {
                                            return "XLSX";
                                          }
                                          if (items.includes(".csv")) {
                                            return "CSV";
                                          }
                                          if (items.includes(".tsv")) {
                                            return "TSV";
                                          }
                                          return null;
                                        };

                                        const fileType = getFileType(item);
                                        if (
                                          item.includes('.pdf') ||
                                          item.includes('.doc') ||
                                          item.includes('.docx') ||
                                          item.includes(".xls") ||
                                          item.includes(".xlsx") ||
                                          item.includes(".csv") ||
                                          item.includes(".tsv") ||
                                          item.includes('.txt')
                                        ) {
                                          return (
                                            <>
                                              <a
                                                role="presentation"
                                                onClick={() =>
                                                  handleDocumentClick(item)
                                                }>
                                                {' '}
                                                <span>{fileType}</span>
                                                <InsertDriveFileIcon
                                                  sx={{ fontSize: '60px' }}
                                                />
                                              </a>
                                            </>
                                          );
                                        }
                                        const imageFormats = [
                                          '.jpg',
                                          '.jpeg',
                                          '.png',
                                          '.gif',
                                          '.bmp',
                                          '.tiff',
                                          '.webp',
                                          '.svg',
                                          '.ico',
                                        ];
                                        if (
                                          imageFormats.some((extension) =>
                                            item?.includes(extension)
                                          )
                                        ) {
                                          return (
                                            <>
                                              <a>
                                                <img
                                                  role="presentation"
                                                  src={item}
                                                  alt="img"
                                                  onClick={() =>
                                                    handleImageClick(item)
                                                  }
                                                />
                                              </a>
                                            </>
                                          );
                                        }
                                        return null;
                                      })}
                                    </div>
                                  )}
                                </div>
                              )}

                              <div
                                style={{
                                  marginTop: '15px',
                                  display: 'flex',
                                  gap: 16,
                                }}>
                                {user?.permissions?.includes(
                                  'organization-case-update'
                                ) && (
                                    <CustomButton
                                      value="update"
                                      onClick={handleSubmit}
                                      color="secondary"
                                    />
                                  )}
                                <CustomButton
                                  value="cancel"
                                  onClick={handleReturn}
                                />
                              </div>
                            </div>

                            <Tabs
                              value={tabValue}
                              onChange={handleTabValue}
                              className={classes.commentsTabRoot}>
                              <Tab value={1} label="Timeline" />
                              <Tab value={2} label="JiraSync" />
                            </Tabs>
                            <div>
                              {tabValue === 1 && (
                                <TimelineSection
                                  data={allLogs}
                                  caseData={caseData}
                                  toggleIsLoad={toggleIsLoad}
                                  user={user}
                                  languageType={languageType}
                                  showTranslation={showTranslation}
                                  handleDocumentClick={handleDocumentClick}
                                  handleImageClick={handleImageClick}
                                />
                              )}
                              {tabValue === 2 && <JiraSync />}
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>

                    <Grid item xs={4}>
                      <Card className="rightSide">
                        <CardContent>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 20,
                            }}>
                            <div className="rightAccordion">
                              {/* <Card variant="outlined">
                                <CardContent> */}
                              <Typography
                                gutterBottom
                                variant="h4"
                                component="div">
                                Status
                              </Typography>
                              <FormControl fullWidth>
                                <Select
                                  id="priorities"
                                  disabled={
                                    !user?.permissions?.includes(
                                      'organization-case-status-update'
                                    )
                                  }
                                  value={
                                    Object.keys(statusInputValue).length !==
                                    0 && statusInputValue?.id
                                  }
                                  onClick={(e) => {
                                    if (
                                      user?.permissions?.includes(
                                        'organization-case-status-update'
                                      ) &&
                                      e
                                    ) {
                                      const getTarget: any = e.target;
                                      const dataName =
                                        getTarget?.getAttribute('data-name');
                                      const dataValue =
                                        getTarget?.getAttribute('data-value');
                                      if (dataValue !== null) {
                                        setFieldValue('statusId', {
                                          id: dataValue,
                                        });
                                        updateIndividual('status', {
                                          ...statusInputValue,
                                          name: dataName,
                                          id: dataValue,
                                        });
                                      }
                                    }
                                  }}
                                  size="small">
                                  {statuses?.map((opt) => (
                                    <MenuItem
                                      value={opt?.id}
                                      data-name={opt?.name}>
                                      {opt?.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              {/* </CardContent>
                              </Card> */}
                            </div>
                            <div className="rightAccordion">
                              <Typography
                                gutterBottom
                                variant="h4"
                                component="div">
                                Details
                              </Typography>
                              <div className={classes.detailsRoot}>
                                <div className="">
                                  <label>Organization</label>
                                  <TextField
                                    id="outlined-select-organization"
                                    value={values?.orgId?.name}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                                <div className="">
                                  <label> Case Owner</label>
                                  <TextField
                                    id="outlined-select-caseOwner"
                                    value={values?.caseOwner}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                                <div className="">
                                  <label> Project</label>
                                  <TextField
                                    id="outlined-project"
                                    value={values?.projectId?.name}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                                <div className="">
                                  <label> Assignee</label>
                                  <Autocomplete
                                    id="assigneeID"
                                    disabled={
                                      !user?.permissions?.includes(
                                        'organization-case-update'
                                      )
                                    }
                                    options={
                                      allInternalUsers?.length === 0
                                        ? []
                                        : allInternalUsers
                                    }
                                    onChange={(event: any, value: any) => {
                                      setFieldValue('assigneeID', {
                                        id: value?.id,
                                        name: value?.name,
                                      });
                                      updateIndividual('assignee', {
                                        ...assigneeId,
                                        name: value?.name,
                                        id: value?.id,
                                      });
                                    }}
                                    getOptionLabel={(option) =>
                                      Object.keys(option).length !== 0 &&
                                      option?.name
                                    }
                                    inputValue={
                                      hasMultipleNulls
                                        ? ''
                                        : Object.keys(assigneeId).length !==
                                        0 && assigneeId?.name
                                    }
                                    onInputChange={(
                                      event: any,
                                      newInputValue: any
                                    ) => {
                                      if (event) {
                                        setAssigneeId({
                                          ...assigneeId,
                                          name: newInputValue,
                                        });
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </div>
                                <div className="">
                                  <label> Priority</label>
                                  <FormControl fullWidth>
                                    <Select
                                      id="priorities"
                                      value={
                                        Object.keys(priority).length !== 0 &&
                                        priority?.id
                                      }
                                      disabled={
                                        !user?.permissions?.includes(
                                          'organization-case-update'
                                        )
                                      }
                                      onClick={(e) => {
                                        if (
                                          user?.permissions?.includes(
                                            'organization-case-update'
                                          ) &&
                                          e
                                        ) {
                                          const getTarget: any = e.target;
                                          const dataName =
                                            getTarget?.getAttribute(
                                              'data-name'
                                            );
                                          const dataValue =
                                            getTarget?.getAttribute(
                                              'data-value'
                                            );
                                          if (dataValue !== null) {
                                            setPriority({
                                              ...priority,
                                              id: dataValue,
                                              name: dataName,
                                            });
                                            updateIndividual('priority', {
                                              ...priority,
                                              id: dataValue,
                                              name: dataName,
                                            });
                                          }
                                        }
                                      }}
                                      size="small">
                                      {priorityAllOptions?.map((opt) => (
                                        <MenuItem
                                          value={opt?.id}
                                          data-name={opt?.name}>
                                          {opt?.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                                <div className=" labelsDataCss">
                                  <label> Escalation Manager</label>
                                  <Autocomplete
                                    id="escalation-manager"
                                    options={
                                      allInternalUsers?.length === 0
                                        ? []
                                        : allInternalUsers
                                    }
                                    getOptionLabel={(option) =>
                                      Object.keys(option).length !== 0 &&
                                      option?.name
                                    }
                                    onChange={(event: any, value: any) => {
                                      setEscalationManagerId(value);
                                      updateIndividual('escalationManager', {
                                        ...escalationManagerId,
                                        id: value?.id,
                                        name: value?.name,
                                      });
                                    }}
                                    inputValue={escalationManagerInput}
                                    onInputChange={(
                                      event: any,
                                      newInputValue: any
                                    ) => {
                                      setEscalationManagerInput(newInputValue);
                                    }}
                                    disabled={
                                      caseData?.escalationManager?.id === '' ||
                                      caseData?.escalationManager?.id ===
                                      null ||
                                      caseData?.escalationManager?.id ===
                                      undefined ||
                                      !user?.permissions?.includes(
                                        'organization-case-update'
                                      )
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        name="escalationManagerIds"
                                      />
                                    )}
                                  />
                                </div>

                                <div className="">
                                  <label> Case Type</label>
                                  <FormControl fullWidth>
                                    <Select
                                      id="case-types"
                                      // disabled={!user?.permissions?.includes('organization-case-update')}
                                      disabled
                                      value={
                                        Object.keys(caseType).length !== 0 &&
                                        caseType?.id
                                      }
                                      // onClick={(e) => {
                                      //   if (user?.permissions?.includes('organization-case-update') && e) {
                                      //     const getTarget: any = e.target;
                                      //     const dataName = getTarget?.getAttribute('data-name');
                                      //     const dataValue = getTarget?.getAttribute('data-value');
                                      //     setCaseType({ ...caseType, id: dataValue, name: dataName });
                                      //     updateIndividual('caseType', { ...caseType, id: dataValue, name: dataName });
                                      //   }
                                      // }}
                                      size="small">
                                      {caseTypeOptions?.map((opt) => (
                                        <MenuItem
                                          value={opt?.id}
                                          data-name={opt?.name}>
                                          {opt?.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                                <div className=" labelsDataCss">
                                  <label> Labels</label>
                                  <Autocomplete
                                    multiple
                                    options={labelsOptions}
                                    limitTags={1}
                                    value={labelsData}
                                    onChange={(e: any, newValue: any) => {
                                      if (e && newValue) {
                                        setLabelsData(newValue);
                                        updateIndividual('labels', newValue);
                                      }
                                    }}
                                    inputValue={labelInputValue}
                                    onInputChange={(
                                      event: any,
                                      newInputValue: any
                                    ) => {
                                      if (event) {
                                        setLabelInputValue(newInputValue);
                                      }
                                    }}
                                    // filterOptions={customFilterOptions}
                                    disabled={
                                      !user?.permissions?.includes(
                                        'organization-case-update'
                                      )
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label=""
                                        variant="outlined"
                                        name="labelsId"
                                      />
                                    )}
                                  />
                                </div>
                                <div className=" labelsDataCss">
                                  <label> Project Sponser</label>
                                  <div
                                    style={{
                                      width: '100%',
                                      border:
                                        '1px solid rgba(35, 31, 32, 0.26)',
                                      padding: '2px',
                                      minHeight: '38px',
                                      borderRadius: '4px',
                                    }}>
                                    {values?.sponsorId?.map((chip) => (
                                      <Chip
                                        key={chip?.id}
                                        label={chip?.name}
                                        // size="small"
                                        sx={{
                                          marginRight: '2px',
                                          marginBottom: '2px',
                                          maxWidth: '150px',
                                          overflow: 'hidden',
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis',
                                          cursor: 'no-drop',
                                        }}
                                      />
                                    ))}
                                  </div>
                                </div>
                                <div className=" labelsDataCss">
                                  <label> Stakeholder</label>
                                  <Autocomplete
                                    id="stakeHolderId-name"
                                    multiple
                                    limitTags={1}
                                    options={
                                      allStakeholders?.length === 0
                                        ? []
                                        : allStakeholders?.filter(
                                          (itm1) =>
                                            !values?.stakeHolderId?.some(
                                              (itm2) => itm1?.id === itm2?.id
                                            )
                                        )
                                    }
                                    onChange={(event: any, value: any) => {
                                      if (event) {
                                        updateIndividual('stakeHolder', value);
                                      }
                                    }}
                                    value={values?.stakeHolderId}
                                    getOptionLabel={(option) =>
                                      Object.keys(option)?.length !== 0 &&
                                      option?.name
                                    }
                                    inputValue={stakeholderInput}
                                    disabled={
                                      !user?.permissions?.includes(
                                        'organization-case-update'
                                      )
                                    }
                                    onInputChange={(
                                      event: any,
                                      newInputValue: any
                                    ) => {
                                      if (event) {
                                        setStakeholderInput(newInputValue);
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </div>
                                <div className=" labelsDataCss">
                                  <label htmlFor="label">{`${`Billing Type`}`}</label>
                                  <Autocomplete
                                    id="caseAccountBilling-type"
                                    options={[{
                                      id: 'Opex',
                                      name: 'Opex'
                                    }, {
                                      id: 'Capex',
                                      name: 'Capex'
                                    }
                                    ]}
                                    value={billingType}
                                    onChange={(event: any, value: any) => {
                                      if (value !== null) {
                                        setBillingType(value);
                                        updateIndividual('billingAccountType', value?.id);
                                      }
                                    }}
                                    getOptionLabel={(option) => option?.name}
                                    // style={{ minWidth: 300 }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        name='billingAccountType'
                                        variant="outlined"
                                        placeholder="Billing Type*"
                                      // error={Boolean(formik?.touched?.billingAccountType && formik?.errors?.billingAccountType)}
                                      // helperText={formik?.touched?.billingAccountType && formik?.errors?.billingAccountType}
                                      />
                                    )}
                                  />
                                </div>
                                <div className=" labelsDataCss">
                                  <label htmlFor="label">{`${`Time Estimate`}`}</label>
                                  <TextField
                                    id="outlined-project"
                                    value={timeEstimate}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                                {caseData?.caseType?.name ===
                                  'Application Bug' && (
                                    <div className=" labelsDataCss">
                                      <label>Severity</label>
                                      <Autocomplete
                                        id="severities"
                                        options={allSeverities || []}
                                        value={severity}
                                        onChange={(event: any, value: any) => {
                                          if (value !== null) {
                                            setFieldValue('severity', value);
                                            updateIndividual('severity', value);
                                          }
                                        }}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Severity*"
                                          />
                                        )}
                                      />
                                    </div>
                                  )}
                              </div>
                            </div>
                            {user?.permissions?.includes(
                              'organization-case-escalate-read'
                            ) && (
                                <div className="rightAccordion">
                                  <Typography
                                    gutterBottom
                                    variant="h4"
                                    component="div">
                                    Escalate
                                  </Typography>
                                  <div className="jiraSync">
                                    {caseData?.escalations?.length ? (
                                      caseData?.escalations?.map(
                                        (escalation: any) => (
                                          <div>
                                            <span>
                                              <b>
                                                {escalation?.escalatedBy?.name}
                                              </b>
                                            </span>{' '}
                                            <span>has escalated on </span>{' '}
                                            <span>
                                              {time.absoluteTime(
                                                escalation?.createdAt
                                              )}
                                            </span>
                                          </div>
                                        )
                                      )
                                    ) : (
                                      <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.button}
                                        startIcon={<SwapVerticalCircleIcon />}
                                        onClick={escalateById}>
                                        Escalate
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              )}
                            <div className="rightAccordion">
                              <Typography
                                gutterBottom
                                variant="h4"
                                component="div"
                                sx={{ marginBottom: '4px' }}>
                                SLAs
                              </Typography>
                              <div className="caseRightInner">
                                <div className="sLa">
                                  <span
                                    className={`${caseData?.sla?.isResolveTimeViolated ===
                                      true
                                      ? 'bgRed'
                                      : ''
                                      } btnTime`}>
                                    {caseData?.sla?.actualTime?.resolveTime !==
                                      null &&
                                      moment(
                                        caseData?.sla?.actualTime?.resolveTime
                                      ).format('MMM Do hh:mm')}{' '}
                                    <QueryBuilderIcon />
                                  </span>
                                  <span className="resolveTime">
                                    Time to resolution
                                    <p>
                                      {`before ${time.absoluteTime(
                                        caseData?.sla?.estimatedTime
                                          ?.resolveTime
                                      )}`}
                                    </p>
                                  </span>
                                </div>
                              </div>
                              <div className="caseRightInner">
                                <div className="sLa">
                                  <span
                                    className={`${caseData?.sla
                                      ?.isFirstResponseTimeViolated === true
                                      ? 'bgRed'
                                      : ''
                                      } btnTimeFirst`}>
                                    {caseData?.sla?.actualTime
                                      ?.firstResponseTime !== null &&
                                      moment(
                                        caseData?.sla?.actualTime
                                          ?.firstResponseTime
                                      ).format('MMM Do hh:mm')}{' '}
                                    <ClearIcon />
                                  </span>
                                  <span className="resolveTime">
                                    Time to first response
                                    <p>
                                      {`before ${time.absoluteTime(
                                        caseData?.sla?.estimatedTime
                                          ?.firstResponseTime
                                      )}`}
                                    </p>
                                  </span>
                                </div>
                              </div>
                            </div>
                            {user?.permissions?.includes(
                              'organization-case-jira-sync-read'
                            ) && (
                                <div className="rightAccordion">
                                  <Typography
                                    gutterBottom
                                    variant="h4"
                                    component="div"
                                    sx={{ marginBottom: '4px' }}>
                                    Jira Sync
                                  </Typography>
                                  <div className="jiraSync">
                                    <span className="">
                                      {caseData?.thirdPartySync?.isSynced ? (
                                        <>
                                          <Box>
                                            <span>Synced &nbsp;</span>
                                            <a
                                              href={
                                                caseData?.thirdPartySync
                                                  ?.syncedCaseLink
                                              }
                                              target="_blank"
                                              rel="noreferrer">
                                              {
                                                caseData?.thirdPartySync
                                                  ?.syncedCaseKey
                                              }
                                            </a>
                                          </Box>
                                        </>
                                      ) : (
                                        <>
                                          {isSyncing ? (
                                            <CircularProgress />
                                          ) : (
                                            <Button
                                              variant="contained"
                                              color="secondary"
                                              className={classes.button}
                                              startIcon={
                                                <SwapVerticalCircleIcon />
                                              }
                                              onClick={syncJiraByCaseId}>
                                              Sync
                                            </Button>
                                          )}
                                        </>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              )}

                            <div className="rightAccordion">
                              <div className="jiraSync">
                                <span className="">
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.button}
                                    onClick={() => handleLogTimeModalOpen(true)}>
                                    Log Time
                                  </Button>
                                </span>
                              </div>
                            </div>
                            <div
                              className="createdAt"
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}>
                              <p
                                title={moment(caseData?.createdAt).format(
                                  'MMM DD YYYY - HH:mm:ss'
                                )}>
                                Created:{' '}
                                <span>
                                  {time.absoluteTime(caseData?.createdAt)}
                                </span>
                              </p>
                              <p
                                title={moment(caseData?.updatedAt).format(
                                  'MMM DD YYYY - HH:mm:ss'
                                )}>
                                Updated:{' '}
                                <span>
                                  {time.absoluteTime(caseData?.updatedAt)}
                                </span>
                              </p>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>

                  <SImpleModal
                    isOpen={isImagePopupOpen}
                    onClose={handleCloseImagePopup}
                    imageUrl={selectedImageUrl}
                  />

                  <LogTimeModal
                    isOpen={logTimeModalOpen}
                    convertSecondsToTimeString={convertSecondsToTimeString}
                    onClose={handleLogTimeModalOpen}
                    billingAccounts={billingAccounts}
                    caseId={caseData?.id}
                  />
                  {isDocumentViewerOpen && (
                    <DocumentViewer
                      documentUrl={selectedDocumentUrl}
                      isOpen={isDocumentViewerOpen}
                      onClose={handleCloseDocumentViewer}
                    />
                  )}
                </form>
              )}
            </Formik>
          </Container>
        </Box>
      }
    </>
  );
}

export default EditCaseByProject;
