import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableProps } from 'src/types/components/tableList.types';
import Scrollbar from '../../Scrollbar';
import { STATUS } from 'src/utils/constants';
import dayjs from 'dayjs';

const useStyles = makeStyles<Theme>(() => createStyles({
  btn: {
    width: '47px',
    height: '40px',
    padding: 0,
    minWidth: 'initial',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    color: '#fff',
    marginLeft: '13px',
    '& .MuiButton-iconSizeMedium': {
      margin: '0',
    },
  },
  textFieldSelect: {
    width: '230px',
    marginLeft: '13px',
  },
  textFieldSelectRoot: {
    borderRadius: '4px',
    height: '38px',
  },
  tabButton: {
    width: '181px',
  },
  cardRoot: {
    borderRadius: '4px',
  },
  tabs: {
    '& .MuiTab-root': {
      backgroundColor: 'transparent',
      minWidth: '120px',
    },
    '& .Mui-selected': {
      backgroundColor: '#231F20',
      color: '#fff',
    },
  },
  tableHead: {
    '& .MuiTableCell-root': {
      textTransform: 'capitalize',
    },
  },
  tableRow: {
    '& :hover': {
      cursor: 'pointer',
    },
    '& .ticketStatusTrue': {
      backgroundColor: 'rgba(7, 83, 0, 0.16)',
      color: '#1D7A15',
      padding: '5px',
      fontWeight: 'Bold',
    },
    '& .ticketStatusFalse': {
      background: '#DEEBFF',
      color: '#3375D7',
      padding: '5px',
      fontWeight: 'Bold',
    },
  },
  priorityComponent: {
    height: '30px',
    background: 'rgba(189,189,189,0.1)',
    color: '#000000aa',
    width: '100px',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    textTransform: 'capitalize',
    justifyContent: 'center'
  },
  loaderTable: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '2222'
  },
}));

const TableHeadComponent: FC<any> = ({ data, setSelectedSort, selectedSort }: any) => (
  <TableHead sx={{ marginBottom: '1rem' }}>
    <TableRow>
      {data?.map((col: any) => (
        <TableCell
          align="center"
          className="textBold"
          sortDirection={selectedSort?.key === col?.id ? selectedSort?.value : false}
        >
          {
            col.isSort
              ? (
                <>
                  <TableSortLabel
                    active={selectedSort?.key === col?.id}
                    direction={selectedSort?.key === col?.id ? selectedSort?.value : 'asc'}
                    // active={col.isSort}
                    // direction={col.direction}
                    onClick={() => {
                      col.toggleSortData(`${col.id}`);
                      // getAllSortedData(`${col.id}`, col.direction === 'asc' ? '1' : '-1');
                      // logger(getAllSortedData);
                      setSelectedSort({ key: `${col?.id}`, value: col.direction === 'asc' ? 'asc' : 'desc' });
                      // setSort('', '');
                    }}
                  // hideSortIcon={col.isSort || true}
                  >
                    {col?.label}
                  </TableSortLabel>
                </>
              )
              : col?.label
          }
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

const TableBodyComponent: FC<any> = ({ data, editRouteUrl }: any) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleRedirectNext = (keyOrId: string) => {
    navigate(`${editRouteUrl}/${keyOrId}`);
  };
  return (
    <>
      <TableBody style={{ position: 'relative' }}>
        {data?.length !== 0 ? (data?.map((row: any) => (
          <TableRow
            hover
            onClick={() => handleRedirectNext(row?.key)}
            className={classes.tableRow}
            key={row?.id}
          >

            <TableCell
              align="center"
            >
              <span>
                {row?.key}
              </span>
            </TableCell>
            <TableCell
              align="center"
              title={row?.summary === null ? 'N/A' : row?.summary}
              style={{
                maxWidth: '200px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <span>
                {row?.summary === null ? 'N/A' : row?.summary}
              </span>
            </TableCell>

            <TableCell align="center">
              <strong style={{ textTransform: 'capitalize' }}>
                {row?.categoryName === null ? 'N/A' : row?.categoryName}
              </strong>
            </TableCell>
            <TableCell align="center">
              <strong style={{ textTransform: 'capitalize' }}>
                {row?.categoryName === null ? 'N/A' : row?.createdBy?.name}
              </strong>
            </TableCell>

            <TableCell align="center">
              {row?.status !== null ? <strong style={{ textTransform: 'capitalize' }}>{STATUS[row?.status]}</strong> : 'N/A'}
            </TableCell>

            <TableCell align="center">
              {dayjs(row?.createdAt).format('MMM D, YYYY h:mm')}
            </TableCell>

            <TableCell align="center">
              {dayjs(row?.updatedAt).format('MMM D, YYYY h:mm')}
            </TableCell>

          </TableRow>
        ))) : (
          <TableRow selected>
            <TableCell
              colSpan={7}
              sx={{ textAlign: 'center' }}
            >
              No Data Found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </>
  );
};

interface CaseListViewTypes extends TableProps {
  sortData?: any;
  toggleSortData?: any;
  setSelectedSort?: any;
  selectedSort?: any;
  isOrganization?: boolean;
  isProject?: boolean;
}
const CaseListView: FC<CaseListViewTypes> = ({
  data,
  editRouteUrl,
  count,
  page,
  limit,
  handlePage,
  bulkIds,
  handleLimit,
  handleAddBulkIds,
  handleRemoveBulkIds,
  sortData,
  toggleSortData,
  selectedSort,
  setSelectedSort
}: any) => {
  const classes = useStyles();

  const Columns = [
    {
      id: 'key',
      label: 'Key',
      isSort: true,
      toggleSortData,
      direction: sortData?.key
    },
    {
      id: 'summary',
      label: 'Title',
      isSort: true,
      toggleSortData,
      direction: sortData?.summary
    },
    {
      id: 'category',
      label: 'Category',
      isSort: true,
      toggleSortData,
      direction: sortData?.category
    },
    {
      id: 'createdBy',
      label: 'Reporter',
      isSort: true,
      toggleSortData,
      direction: sortData?.createdBy
    },
    {
      id: 'status',
      label: 'Status',
      isSort: true,
      toggleSortData,
      direction: sortData?.status
    },
    {
      id: 'createdAt',
      label: 'Created At',
      isSort: true,
      toggleSortData,
      direction: sortData?.createdAt
    },
    {
      id: 'updatedAt',
      label: 'Updated At',
      isSort: true,
      toggleSortData,
      direction: sortData?.updatedAt
    },
  ];
  const handleChangePage = useCallback(
    (event: any, _page: number) => {
      handlePage(_page);
    },
    [page, data]
  );
  const handleChangeLimit = useCallback(
    (event: any) => {
      handleLimit(parseInt(event.target.value, 10));
      handlePage(0);
    },
    [limit, data]
  );

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        py: 1,
      }}
    >
      {' '}
      <Card className={classes.cardRoot}>
        <Scrollbar>
          <Box sx={{ minWidth: 700, px: 1, }}>
            <Table>
              <TableHeadComponent
                data={Columns}
                selectedSort={selectedSort}
                setSelectedSort={setSelectedSort}
              />
              <TableBodyComponent
                data={data}
                editRouteUrl={editRouteUrl}
                handleAddBulkIds={handleAddBulkIds}
                bulkIds={bulkIds}
                handleRemoveBulkIds={handleRemoveBulkIds}
              />

            </Table>
          </Box>
        </Scrollbar>
        <TablePagination
          component="div"
          count={count}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeLimit}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 100, 500, 1000]}
        />
      </Card>
    </Box>
  );
};

export default CaseListView;
