/* eslint-disable prefer-rest-params */
import { isUndefined } from 'lodash';
import axios from 'src/utils/axios';
import parse from 'src/utils/parse';

class CasesApi {
  async getProjectsById(orgId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/auto-complete/projects?organizationId=${orgId}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async getOrganizationId(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/auto-complete/organizations`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getUserById(isExternal: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/auto-complete/users?isExternal=${isExternal}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getIssueTypes(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/auto-complete/issue/types`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async getCaseTypes(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/auto-complete/case/types`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getPriorities(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/auto-complete/priorities`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getStatuses(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/auto-complete/statuses`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async createCase(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_CASES}/cases`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async updateCase(id: string, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const showLoader = true;
      axios
        .patch(`${process.env.REACT_APP_CASES}/cases?id=${id}`, payload, { params: { showLoader } })
        .then((response) => {
          response = parse(response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async updateBulkCases(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const showLoader = true;
      axios
        .patch(`${process.env.REACT_APP_CASES}/cases/bulkUpdate/caseIds`, payload)
        .then((response) => {
          response = parse(response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async moveBulkCases(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const showLoader = true;
      axios
        .patch(`${process.env.REACT_APP_CASES}/cases/bulkUpdate/accountTypes/caseIds`, payload)
        .then((response) => {
          response = parse(response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  // https://erp-it22-support-dev.apps.alpha.it22.cloud/cases/bulkUpdate/accountTypes/caseIds

  async getCaseById(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/cases/${id}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getCasesCards(...args: any[]): Promise<any> {
    const [page, size] = args;
    return new Promise((resolve, reject) => {
      axios
        // eslint-disable-next-line no-nested-ternary
        .get(`${process.env.REACT_APP_CASES}/cases?page=${page || 0}&size=${size || 10}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async advanceSearch(args: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        // eslint-disable-next-line no-nested-ternary
        .get(`${process.env.REACT_APP_CASES}/cases/advance-search?${args}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async declinedAdvanceSearch(args: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        // eslint-disable-next-line no-nested-ternary
        .get(`${process.env.REACT_APP_CASES}/cases/declined?${args}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getCasesCardsByProj(...args: any[]): Promise<any> {
    const [page, size, projectId] = args;
    return new Promise((resolve, reject) => {
      axios
        // eslint-disable-next-line no-nested-ternary
        .get(`${process.env.REACT_APP_CASES}/cases?page=${page || 0}&size=${size || 10}&projectId=${projectId}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async getAllProjects(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/auto-complete/projects`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async getAllOrganizations(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/auto-complete/organizations`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async getEscalationCardById(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/api/escalation-card/${id}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async getCasesByProjectId(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/cases/list/projectId/${id}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async deleteCase(id: String): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${process.env.REACT_APP_CASES}/cases/${id}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // not able to get id in delete payload how to do that
  async deleteBulkCase(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log('payload', payload);
      axios
        .delete(`${process.env.REACT_APP_CASES}/cases/bulk/caseIds`, {
          data: payload // sending payload as request body
        })
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async bulkComment(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_CASES}/comment/bulkComment/caseIds`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async createComment(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_CASES}/comment`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async deleteComments(caseId: String, commentId: String): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${process.env.REACT_APP_CASES}/comment/${caseId}/delete/${commentId}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async editCaseComment(id: String, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${process.env.REACT_APP_CASES}/comment/${id}`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async bulkCaseComment(id: String, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${process.env.REACT_APP_CASES}/comment/bulkComment/caseIds`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }


  // POST
  // /comment/bulkComment/caseIds

  async getNoteById(id: string, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_NOTES_USER_URL}/${id}`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response?.data);
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async updateNote(id: string, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${process.env.REACT_APP_NOTES_USER_URL}/${id}`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response?.data);
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async escalateById(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.REACT_APP_CASES}/escalation/${id}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async syncJiraByCaseId(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.patch(`${process.env.REACT_APP_CASES}/cases/${id}/sync`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      }).catch((error) => {
        reject(error);
      });
    });
  }

  async getAutoComplete(pid: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/auto-complete?pid${pid}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async getAutoCompleteUser(isExternal: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/auto-complete/users?isExternal=${isExternal}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getAutoCompleteLabels(search = ''): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/auto-complete/labels${search !== '' ? `?search=${search}` : ''}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getAutoCompleteSeverity(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/auto-complete/severity`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getAutoCompleteReleaseVersion(projectId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/releaseVersion/getActiveRecentAndOldNames/${projectId}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getAutoCompleteSprints(projectId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/sprint/getActiveRecentAndOldNames/${projectId}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async deleteCaseAttachment(caseId: string, attId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${process.env.REACT_APP_CASES}/cases/${caseId}/attachment`, {
          data: { attachmentId: attId },
        })
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export const casesApi = new CasesApi();
