import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { fontSize } from '@material-ui/system';

const smallShadow = '0px 0px 4px 0px rgba(0, 0, 0, 0.25)';
export const editCaseStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    '.MuiPaper-root-MuiCard-root': {
      boxShadow: smallShadow,
    },
    select: {
      backgroundColor: '#FFECEC',
      color: '#E53E3E',
      fontWeight: '700',
      '& .MuiSelect-select': {
        padding: '4px 8px',
        fontSize: '12px',
      },
      '&.Mui-disabled': {
        backgroundColor: '#FFECEC',
        color: '#E53E3E',
      },
    },
    leftSideBarRoot: {
      padding: '10px 10px',
    },
    label: {
      letterSpacing: ' 0.15px',
      color: '#231F20',
      fontWeight: '600',
      paddingBottom: '6px',
      display: 'block',
      fontSize: '14px',
    },
    meta: {
      width: '100%',
      gap: 8,
      fontSize: '14px',
      marginBottom: 8,
      fontWeight: 500,
      color: theme.palette.grey[500],
      '& .author': {
        fontWeight: 600,
        color: theme.palette.grey[600],
      },
    },
    customRadio: {
      '&.Mui-checked': {
        color: '#c22027',
      },
    },
    labelClassName: {
      '& .MuiInputBase-formControl': {
        width: '90%',
        height: '25px',
        fontSize: '12px',
      },
    },
    inputClassName: {
      '& .MuiInputBase-formControl': {
        width: '100%',
        height: '25px',
        fontSize: '12px',
      },
    },
    box: {
      '& .MuiAutocomplete-root': {
        '& .Mui-disabled': {
          cursor: 'not-allowed',
        },
      },
      '& .orgEditCase': {
        padding: 0,
        '& .MuiAccordion-root': {
          '& .MuiAccordionDetails-root': {},
        },
        '& .MuiAccordionDetails-root': {
          padding: '16px',
          background: '#fff!important',
          borderRadius: '0 0 4px 4px',
        },
        '& .MuiAccordionSummary-root': {
          background: '#fff !important',
          color: '#231F20 !important',
          borderRadius: '4px 4px 0 0',
          padding: '0 16px',
          flexDirection: 'initial',
        },
      },
      '& .MuiAccordionSummary-expandIconWrapper ': {
        color: '#000 !important',
      },
      '& .boxSub': {
        background: '#fff',
        padding: '16px !important',
        boxShadow: smallShadow,
        borderRadius: '4px',
        '& label': {
          color: '#231f20',
          fontSize: '14px',
          fontWeight: 600,
        },
        '& .boxHeader': {
          background: '#fff',
          color: '#231F20',
          padding: '7px 24px',
          borderRadius: '4px 4px  0  0',
          height: '39px',
        },

        '& p': {
          margin: '10px 0 10px',
        },
        '& .editorMarkup': {
          '& .wmde-markdown-var': {
            border: '1px solid transparent',
          },
        },
        '& .editorMarkupError': {
          '& .wmde-markdown-var': {
            border: '1px solid #c22027',
          },
        },
        '& .btnComments': {
          margin: '16px 0 0',
          '& button+button': {
            marginLeft: '12px',
          },
        },
        '& .MuiFormHelperText-root': {
          margin: 0,
        },
      },
      '& .commentBoxRoot': {
        marginTop: '25px',
      },
      '& .commentBox': {
        marginTop: '10px',
        padding: '10px 25px',
        border: '1px solid #eceaea',
        borderRadius: '5px',
        '& .commentBoxInner': {
          display: 'flex',
          alignItems: 'start',

          '& h4': {
            fontSize: '14px',
            fontWeight: 'bold',
            margin: 0,
          },
          '& span': {
            fontSize: '14px',
            marginLeft: '10px',
          },
        },
        '& p': {
          margin: '0px 0px 0px 0px !important',
        },
        '& span': {
          fontSize: '14px',
        },
        '& span+span': {
          marginLeft: '10px',
        },
      },
      '& .commentBox.current': {
        border: '1px solid #231f20',
      },
      '& select, & .MuiAutocomplete-inputRoot, & input': {
        height: '38px',
        padding: '0 10px',
        lineHeight: '116.7%',
        color: '#696969',
        width: '100%',
        '& .MuiAutocomplete-input': {
          padding: 0,
        },
      },
      '& .customField': {
        height: '38px',
        padding: '0 10px',
        lineHeight: '116.7%',
        color: '#696969',
        width: '100%',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        '& .MuiChip-root': {
          border: 0,
          borderRadius: '4px',
          margin: '2px 0',
          background: 'rgb(241 240 240)',
        },
      },
      '& .labelsDataCss .MuiAutocomplete-inputRoot': {
        height: 'initial',
      },

      '& .labelField': {
        // width: '50%',
      },
      '& .caseRightInner+.caseRightInner': {
        marginTop: '8px',
      },
      '& .sLa': {
        display: 'flex',
        fontSize: '14px',
        alignItems: 'center',
        '& .btnTime': {
          width: '179px',
          height: '36px',
          borderRadius: '43px',
          background: '#B0B0B0',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& svg': {
            marginLeft: '10px',
          },
        },
        '& .btnTime.bgRed': {
          background: '#CC4D53',
        },
        '& .btnTimeFirst': {
          width: '179px',
          height: '36px',
          borderRadius: '43px',
          background: '#B0B0B0',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& svg': {
            marginLeft: '10px',
          },
        },
        '& .btnTimeFirst.bgRed': {
          background: '#CC4D53',
        },
        '& .resolveTime': {
          fontSize: '14px',
          fontWeight: 'bold',
          marginLeft: '10px',
          '& p': {
            fontSize: '12px',
            fontWeight: 'normal',
            margin: '2px 0 0',
          },
          '& svg': {
            marginLeft: '10px',
          },
        },
      },
      '& .jiraSync': {
        marginTop: '0px',
        '& b': {
          fontSize: '14px',
        },
        fontSize: '12px',
      },
      '& .escalations': {
        display: 'flex',
        '& span': {
          fontSize: '14px',
        },
      },
      '& .rightSide': {
        boxShadow: smallShadow,
        '& hr': {
          border: 0,
          borderTop: '0.8px solid #E0E0E0',
          margin: '15px 0',
          borderWidth: '0.8px',
        },
      },
      '& .rightAccordion': {
        '& .MuiTypography-h4': {
          color: '#231f20',
          fontSize: '14px',
          fontWeight: 600,
        },
        '& label': {
          color: '#231f20',
          fontSize: '12px',
          fontWeight: 600,
        },
      },
      '& .createdAt': {
        fontSize: '14px',
        color: '#777',
        '& p': {
          margin: '0',
        },
        '& span': {
          fontWeight: 500,
        },
      },
      '& .btnCommentInner': {
        marginTop: 2,
        display: 'flex',
        position: 'absolute',
        top: 10,
        right: 10,
        gap: '8px',
        '& button': {
          display: 'flex',
          cursor: 'pointer',
          border: '1px solid transparent',
          borderRadius: '3px',
          color: '#696969',
          backgroundColor: 'rgba(0 0 0/0)',
          transition: 'all 300ms',
          '&:hover': {
            backgroundColor: 'rgba(0 0 0/0.1)',
          },
        },
      },
      '& .attachmentsDiv': {
        marginTop: '30px',
        '& .editCommentFileParent': {
          display: 'flex',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
          gap: '15px',
          '& a': {
            position: 'relative',
            display: 'inline-flex',
            borderRadius: 4,
            marginBottom: 8,
            width: 150,
            height: 150,
            padding: 4,
            color: ' #757575',
            boxSizing: 'border-box',
            justifyContent: 'center',
            alignItems: 'center',
            border: `1px solid ${theme.palette.grey[300]}`,
            cursor: 'pointer',
            '&  .editCommentFileEdit': {
              position: 'absolute',
              top: '-10px',
              right: '-10px',
              cursor: 'pointer',
              color: '#C22027',
            },
            '&  span': {
              position: 'absolute',
              background: ' #f7f7f7',
              fontSize: '10px',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%)',
              padding: '2px',
              fontWeight: 'bold',
            },
          },
        },
        '& a img': {
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        },
        '& img': {
          width: ' 60px',
          height: '67px',
          objectFit: 'contain',
        },
      },
    },
    commentHelperText: {
      margin: '0px',
    },
    commentsTabRoot: {
      marginTop: '15px',
      borderRadius: '4px 4px 0 0',
      '& .MuiTabs-flexContainer': {
        backgroundColor: '#ddd',
        height: '39px',
        border: 'none',
      },
      '& .Mui-selected': {
        '& .MuiTab-wrapper': {
          color: '#333',
        },
      },
      '& .MuiButtonBase-root.Mui-selected[aria-selected="true"]': {
        fontWeight: '700',
        backgroundColor: 'transparent !important',
        border: 'none !important',
      },
      '& .MuiButtonBase-root': {
        textTransform: 'capitalize',
        color: '#333',
        fontWeight: 400,
        fontSize: '1rem',
        minHeight: '39px!important',
        minWidth: '155px !important',
      },
      '& .MuiButtonBase-root:first-child': {
        marginLeft: 0,
      },
      '& .MuiTabs-indicator': {
        backgroundColor: '#C22027!important',
        height: '4px!important',
        top: '39px!important',
        display: 'block!important',
      },
    },
    commentsPreview: {
      // marginLeft: '50px',
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      '& p': {
        margin: '0px!important',
      },
      '& .MuiAvatar-root': {
        backgroundColor: '#231f20',
        width: '40px',
        height: '40px',
      },
      '& img': {
        width: ' 60px',
        height: '67px',
        objectFit: 'contain',
        position: 'relative',
        display: 'block',
        color: ' #757575',
        cursor: 'pointer',
        border: `1px solid ${theme.palette.grey[300]}`,
      },
      '& .MuiListItemText-root': {
        margin: 0,
        fontSize: '14px',
        fontWeight: 300,
        '& strong': {
          color: '#000',
        },
      },
    },
    timelineRoot: {
      margin: 0,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      '& p': {
        margin: '0px!important',
        wordBreak: 'break-all',
      },
      '& .MuiAvatar-root': {
        backgroundColor: '#231f20',
        width: '40px',
        height: '40px',
      },
      '& img': {
        width: ' 60px',
        height: '67px',
        objectFit: 'contain',
      },
      '& .MuiListItemText-root': {
        margin: 0,
        fontSize: '14px',
        fontWeight: 300,
        '& strong': {
          color: '#000',
        },
      },
    },

    detailsRoot: {
      display: 'grid',
      gridTemplateColumns: '49% 49%',
      rowGap: '5px',
      columnGap: '10px',
      '& .MuiAutocomplete-root .MuiChip-root': {
        maxWidth: '115px',
      },
      '& .MuiAutocomplete-root .MuiAutocomplete-tag': {
        fontSize: '12px',
      },
      [theme.breakpoints.down(1850)]: {
        gridTemplateColumns: '1fr',
      },
    },
    transalationBtn: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      cursor: 'pointer',
    },
    externalComment: {
      backgroundColor: '#fff9f9',
      boxShadow: '0px 0px 1px 1px #ffb0b0',
    },
    commentEditorContainer: {
      border: '2px solid white',
      borderRadius: 4,
      '& .editCommentFileParent': {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        gap: '15px',
        '& a': {
          position: 'relative',
          display: 'block',
          color: ' #757575',
          cursor: 'pointer',
          border: `1px solid ${theme.palette.grey[300]}`,
          '&  .editCommentFileEdit': {
            position: 'absolute',
            top: '-10px',
            right: '-10px',
            cursor: 'pointer',
            color: '#C22027',
          },
          '&  span': {
            position: 'absolute',
            background: ' #f7f7f7',
            fontSize: '10px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%)',
            padding: '2px',
            fontWeight: 'bold',
          },
          '& img': {
            width: ' 60px',
            height: '67px',
            objectFit: 'contain',
          },
        },
      },
    },
    commentOptionButtons: {
      display: 'flex',
      padding: '10px',
      gap: '10px',
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 4,
      '& .internalCommentLink': {
        cursor: 'pointer',
        paddingBottom: '5px',
        textDecoration: 'underline',
        textUnderlineOffset: '3px',
        // transition: '0.3s linear',
        '&:hover': {
          textDecoration: 'underline #f5f5f5',
          // color: '#f5f5f5'
        },
      },
      '& .externalCommentLink': {
        cursor: 'pointer',
        paddingBottom: '5px',
        textDecoration: 'underline',
        textUnderlineOffset: '3px',
        '&:hover': {
          textDecoration: 'underline #f5f5f5',
          // color: '#f5f5f5'
        },
      },
    },
    customClassAddComment: {
      padding: '0px 5px',
    },
    projLinks: {
      margin: '10px 5px 10px 5px',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      gap: '10px',
      '& h3': {
        fontSize: '14px',
        margin: '0px 0px 10px 0px',
      },
      '& ul': {
        margin: '0px',
        paddingLeft: '0px',
        listStyleType: 'none',
      },
      '& li': {
        // border: 'solid 1px #acaaaa',
        width: '300px',
        // borderRadius: '5px',
        // padding: '5px 10px',
        marginTop: '16px',
        padding: '0px 5px 5px',
        backgroundColor: 'rgba(237, 237, 237, 0.2)',
        borderRadius: '5px',
      },
      '& .border': {
        border: 'solid 1px red',
        minWidth: '200px',
      },

      '& a': {
        color: '#696969',
        fontSize: '18px',
        margin: '0px',
      },
    },
    pointerEvent: {
      pointerEvents: 'none',
      cursor: 'not-allowed',
    },
    caseUpdateButton: {
      fontSize: '12px',
      minWidth: '100px',
      height: '25px',
    },
  })
);
