import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import toast from 'react-hot-toast';
// import { useSearchParams } from 'react-router-dom';

import { consultingApi } from 'src/api/consultingApi';
// import CreateCase from 'src/components/cases/CreateCase';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { casesApi } from 'src/api/casesApi';
import logger from 'src/utils/logger';
import setTitle from 'src/utils/setTitle';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    btn: {
      width: '47px',
      height: '30px',
      padding: 0,
      minWidth: 'initial',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '4px',
      color: '#fff',
      marginLeft: '13px',
      '& .MuiButton-iconSizeMedium': {
        margin: '0',
      },
    },
    textFieldSelect: {
      width: '230px',
      marginLeft: '13px',
    },
    textFieldSelectRoot: {
      borderRadius: '4px',
      height: '38px',
    },
    tabButton: {
      width: '181px',
    },
    cardRoot: {
      borderRadius: '4px',
    },
    tabs: {
      '& .MuiTab-root': {
        backgroundColor: 'transparent',
        minWidth: '120px',
      },
      '& .Mui-selected': {
        backgroundColor: '#231F20',
        color: '#fff',
      },
    },
    tableHead: {
      '& .MuiTableCell-root': {
        textTransform: 'capitalize',
      },
    },
    tableRow: {
      '& :hover': {
        cursor: 'pointer',
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    ticketMain: {
      position: 'relative',
      '& .customCss': {
        padding: 0,
      },
      '& .filterArea': {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'relative',
        '& .rdrCalendarWrapper': {
          position: 'absolute',
          zIndex: '999',
          right: '0',
          top: -20,
        },
      },
      '&  .MuiTableCell-head': {
        padding: ' 10px 8px',
      },
      '& .dropDowns': {
        display: 'flex',
        '& .buttonParents': {
          position: 'relative',
        },
        '&  .buttonParents .dropDownInner': {
          overflowY: 'auto',
          position: 'absolute',
          padding: '13px',
          height: '220px',
          width: '350px',
          background: '#fff',
          zIndex: '22',
          boxShadow: ' 0px 0px 8px 3px rgba(0, 0, 0, 0.25)',
          borderRadius: '2px',
          top: '100%',
          left: 0,
          '& .dropDownInnerRow': {
            '& span': {
              fontSize: '12px',
            },
            '& p': {
              margin: '0',
            },
            '& .status': {
              alignItems: 'center',
            },
            display: 'flex',
            alignItems: 'center',
          },
        },
        '& .buttonParents+.buttonParents': {
          marginLeft: '10px',
        },
        '& button img': {
          marginLeft: '20px',
        },
      },
      '& .MuiAutocomplete-root': {
        background: '#fff',
      },
    },
    addButton: {
      height: 40,
      width: '47px',
      borderRadius: '5px',
      minWidth: 'initial',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: '10px',
      '& .MuiButton-startIcon': {
        margin: 0,
      },
    },
  })
);

function EditConsultingUnits() {
  setTitle('Consulting | Units');
  const classes = useStyles();
  const navigate = useNavigate();
  const { consultingID, organizationID }: any = useParams();
  const [data, setData] = useState<any>();
  const [allProjects, setAllProjects] = useState<any>();
  console.log('all-projects', allProjects);
  const [allOrganization, setorganizations] = useState<any>([]);
  console.log('all-organizations: ', allOrganization);
  const [allClients, setAllClients] = useState<any>([]);
  const [allCuRoles, setAllCuRoles] = useState<any>([]);
  console.log(allCuRoles);
  // const [labelsOptions, setLabelsOptions] = useState<any>([]);
  // const [projectInputValue, setProjectInputValue] = useState<any>('');
  // const [orgInputValue, setOrgInputValue] = useState<any>('');
  // const [description, setDescription] = useState<any>('');
  // const getOrgDetails = useSelector((state:any) => state?.orgInfo);
  // const dispatch = useDispatch();
  // const locatiion = useLocation();
  // const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const getAllOrganizations = async () => {
    try {
      const response = await consultingApi.getOrganizationId();

      if (response?.status === 200) {
        setorganizations(response?.data);
      }
    } catch (error) {
      logger(error, 'error');
      setorganizations([]);
    }
  };
  const getAllClients = async () => {
    try {
      const response = await consultingApi.getClients();

      if (response?.status === 200) {
        setAllClients(response?.data);
      }
    } catch (error) {
      logger(error, 'error');
      setAllClients([]);
    }
  };
  const getAllCURoles = async () => {
    try {
      const response = await consultingApi.getCURolesAutoComplete(organizationID);

      if (response?.status === 200) {
        setAllCuRoles(response?.data);
      }
    } catch (error) {
      logger(error, 'error');
      setAllCuRoles([]);
    }
  };

  const getAllProjects = async (orgId: any) => {
    try {
      const response = await casesApi.getProjectsById(orgId);
      if (response?.status === 200) {
        setAllProjects(response?.data);
      }
    } catch (error) {
      setAllProjects([]);
      logger(error, 'error');
    }
  };
  console.log(getAllProjects);

  const getAllData = async () => {
    try {
      const response = await consultingApi.getById(consultingID);
      setData(response?.data);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
      setData({});
    }
  };

  useEffect(() => {
    getAllData();
    getAllOrganizations();
    getAllClients();
    getAllCURoles();
  }, []);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          // eslint-disable-next-line no-underscore-dangle
          orgId: data?.org || null,
          client: {
            // eslint-disable-next-line no-underscore-dangle
            id: data?.client?._id || '',
            name: data?.client?.name || '',
          },
          projectId: data?.project || null,
          cuRoles: data?.cuRoles || [],
          totalAllowedHours: data?.totalAllowedHours || '',
          totalConsumedHours: data?.totalConsumedHours || '',
        }}
        validationSchema={Yup.object().shape({
          cuRoles: Yup.array().test(
            'is-nonempty',
            'Labels required',
            (value) => value && value.length > 0
          ),
          orgId: Yup.mixed().nullable().required('Organization required'),
          projectId: Yup.mixed().nullable().required('Project required'),
          client: Yup.mixed().nullable().required('Client required'),
          totalAllowedHours: Yup.number().required(
            'Total Allowed Hours required'
          ),
          totalConsumedHours: Yup.number().required(
            'Total Consumed Hours required'
          ),
        })}
        onSubmit={async (values) => {
          try {
            logger(values);
            const payload = {
              // eslint-disable-next-line no-underscore-dangle
              org: { id: values?.orgId?._id, name: values?.orgId?.name },
              client: values?.client,
              cuRoles: values?.cuRoles,
              project: {
                // eslint-disable-next-line no-underscore-dangle
                id: values?.projectId?._id,
                name: values?.projectId?.name,
              },
              totalAllowedHours: values?.totalAllowedHours,
              totalConsumedHours: values?.totalConsumedHours,
              allocatedResources: [
                {
                  role: 'string',
                },
              ],
              // cuRoles: [
              //   {
              //     role: 'string',
              //     cunit: 0,
              //   },
              // ],
              cuWorkingLog: [
                {
                  cuWType: {
                    role: 'string',
                    cu: 0,
                  },
                  employeeId: 'string',
                  cuWDescription: 'string',
                  cuWReport: 'string',
                  cuWAuditUser: 'string',
                },
              ],
              cuList: [
                {
                  // "cuBatchID": uuid(),
                  orderNumber: 0,
                  cuQuality: 0,
                },
              ],
            };

            // const res = await permissionsApi.edit(payload, 'kasdf');
            const res = await consultingApi.edit(consultingID, payload);
            if (res?.status === 200) {
              toast.success('Updated Successfully');
              navigate(
                `/erp/crm/organization/details/${organizationID}/consulting`
              );
            } else {
              toast.error('Internal Server Error');
            }
          } catch (error) {
            logger(error, 'error');
            if (error?.response?.status === 401) {
              toast.error('Unauthorized User');
            } else {
              toast.error('Internal Server Error');
            }
          }
        }}>
        {({
          values,
          handleChange,
          setFieldValue,
          handleSubmit,
          touched,
          errors,
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{ marginRight: '30px', marginLeft: '30px' }}>
            <Typography
              variant="h3"
              style={{
                marginTop: '16px',
                marginBottom: '10px',
                fontSize: '20px',
                fontWeight: '700',
                color: '#000',
              }}>
              Edit Consulting Unit
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                {/* <Autocomplete
                  id="Organization-name"
                  size="small"
                  options={allOrganization || []}
                  value={values?.orgId}
                  onChange={(event: any, value: any) => {
                    setFieldValue('orgId', value);
                    setFieldValue('projectId', null);
                    // setOrgID(value?.id);
                    getAllProjects(value?.id);
                    if (value === null || value === undefined) {
                      // Handle the clear button click event
                      // setOrgID(0);
                    }
                    // addDataToLocalStorage('orgId', value);
                  }}
                  getOptionLabel={(option) => option.name}
                  inputValue={orgInputValue}
                  onInputChange={(event: any, newInputValue: any) => {
                    setOrgInputValue(newInputValue);
                  }}
                  style={{ minWidth: 300 }}
                  disabled={projectID !== undefined}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Organization*"
                      error={Boolean(touched?.orgId && errors?.orgId)}
                      helperText={touched?.orgId && errors?.orgId}
                      // disabled={projectID !== undefined}
                    />
                  )}
                /> */}
                <TextField
                  disabled
                  label="Organization"
                  name="org"
                  value={data?.org?.name || 'N/A'}
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                {/* <Autocomplete
                id="project-name"
                size="small"
                options={allProjects || []}
                value={values?.projectId}
                onChange={(event: any, value: any) => {
                  setFieldValue('projectId', value);
                  // addDataToLocalStorage('projectId', value);
                }}
                getOptionLabel={(option) => option.name}
                inputValue={projectInputValue}
                onInputChange={(event: any, newInputValue: any) => {
                  setProjectInputValue(newInputValue);
                }}
                style={{ minWidth: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Project*"
                    error={Boolean(touched?.projectId && errors?.projectId)}
                    helperText={touched?.projectId && errors?.projectId}
                  />
                )}
                disabled={values?.orgId?.name === undefined || projectID !== undefined}
              /> */}
                <TextField
                  disabled
                  label="Project"
                  name="projectId"
                  value={data?.project?.name || 'N/A'}
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  id="client"
                  size="small"
                  // multiple
                  options={allClients || []}
                  limitTags={1}
                  value={values?.client}
                  getOptionLabel={(option) => option.name}
                  onChange={(e: any, newValue: any) => {
                    setFieldValue('client', newValue);
                    // addDataToLocalStorage('client', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Clients"
                      label="Clients"
                      error={Boolean(touched?.client && errors?.client)}
                      helperText={touched?.client && errors?.client}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  size="small"
                  multiple
                  options={allCuRoles || []}
                  limitTags={1}
                  value={values?.cuRoles}
                  getOptionLabel={(option) => option.role}
                  onChange={(e: any, newValue: any) => {
                    setFieldValue('cuRoles', newValue);
                    // addDataToLocalStorage('unitRoles', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Consultant Unit Roles"
                      placeholder={
                        values?.cuRoles?.length ? '' : 'Consultant Unit Roles'
                      }
                      error={Boolean(touched?.cuRoles && errors?.cuRoles)}
                      helperText={touched?.cuRoles && errors?.cuRoles}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  id="outlined-select-status-native"
                  value={values?.totalAllowedHours}
                  variant="outlined"
                  label="Allowed Hours"
                  name="totalAllowedHours"
                  fullWidth
                  onChange={(e) => {
                    handleChange(e);
                    // addDataToLocalStorage('totalAllowedHours', e.target.value);
                  }}
                  inputProps={{ maxLength: 250 }}
                  placeholder="Total Allowed Hours"
                  error={Boolean(
                    touched?.totalAllowedHours && errors?.totalAllowedHours
                  )}
                  helperText={
                    touched?.totalAllowedHours && errors?.totalAllowedHours
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  id="outlined-select-status-native"
                  value={values?.totalConsumedHours}
                  variant="outlined"
                  label="Cosumed Hours"
                  name="totalConsumedHours"
                  fullWidth
                  onChange={(e) => {
                    handleChange(e);
                    // addDataToLocalStorage('totalConsumedHours', e.target.value);
                  }}
                  type="number"
                  inputProps={{ maxLength: 250 }}
                  placeholder="Total Consumed Hours"
                  error={Boolean(
                    touched?.totalConsumedHours && errors?.totalConsumedHours
                  )}
                  helperText={
                    touched?.totalConsumedHours && errors?.totalConsumedHours
                  }
                />
              </Grid>
            </Grid>
            <Box className={classes.btnRoot} style={{ padding: '10px 0 0 0' }}>
              <Link
                to={`/erp/crm/organization/details/${organizationID}/consulting`}
                style={{ marginLeft: 'auto' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ height: '38px' }}>
                  Cancel
                </Button>
              </Link>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                sx={{ height: '38px', ml: '10px' }}>
                Update
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
}

export default EditConsultingUnits;
