import { isUndefined } from 'lodash';
import axiosInstance from 'src/utils/axios';

import logger from 'src/utils/logger';
import parse from 'src/utils/parse';


class SprintsApi {
    async getSprintsByProject(projectId: string, filters: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axiosInstance
                // eslint-disable-next-line no-nested-ternary
                .get(`${process.env.REACT_APP_CASES}/sprint/getActiveSprint`, {
                    params: {
                        pid: projectId,
                        ...filters
                    }
                })
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    } else {
                        reject(new Error('internal Server Error'));
                    }
                })
                .catch(() => {
                    reject(new Error('internal Server Error'));
                });
        });
    }


    async getSprintsById(sprintId: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axiosInstance
                // eslint-disable-next-line no-nested-ternary
                .get(`${process.env.REACT_APP_CASES}/sprint/${sprintId}`)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    } else {
                        reject(new Error('internal Server Error'));
                    }
                })
                .catch(() => {
                    reject(new Error('internal Server Error'));
                });
        });
    }

    async getAllSprintsByProject(projectId: string, filters: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axiosInstance
                // eslint-disable-next-line no-nested-ternary
                .get(`${process.env.REACT_APP_CASES}/sprint/project/${projectId}`, {
                    params: {
                        ...filters
                    }
                })
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    } else {
                        reject(new Error('internal Server Error'));
                    }
                })
                .catch(() => {
                    reject(new Error('internal Server Error'));
                });
        });
    }



    async updateSprintStatus(Id: string, status: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const payload = {
                'status': status
            }
            axiosInstance
                .patch(`${process.env.REACT_APP_CASES}/sprint/status/${Id}`, payload)
                .then((response) => {
                    response = parse(response);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }


    async createSprintByProject(payload: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axiosInstance
                .post(`${process.env.REACT_APP_CASES}/sprint`, payload)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }


    async updateSprintByProject(id: string, payload: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axiosInstance
                .put(`${process.env.REACT_APP_CASES}/sprint/${id}`, payload)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch(() => {
                    reject(new Error('internal Server Error'));
                });
        });
    }
}

export const sprintsApi = new SprintsApi();
