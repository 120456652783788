import {
  Box,
  Button,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Columns, TableProps } from 'src/types/components/tableList.types';
import Scrollbar from '../../Scrollbar';
import logger from 'src/utils/logger';

const useStyles = makeStyles<Theme>(() => createStyles({
  btn: {
    width: '47px',
    height: '40px',
    padding: 0,
    minWidth: 'initial',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    color: '#fff',
    marginLeft: '13px',
    '& .MuiButton-iconSizeMedium': {
      margin: '0',
    },
  },
  textFieldSelect: {
    width: '230px',
    marginLeft: '13px',
  },
  textFieldSelectRoot: {
    borderRadius: '4px',
    height: '38px',
  },
  tabButton: {
    width: '181px',
  },
  cardRoot: {
    borderRadius: '4px',
  },
  tabs: {
    '& .MuiTab-root': {
      backgroundColor: 'transparent',
      minWidth: '120px',
    },
    '& .Mui-selected': {
      backgroundColor: '#231F20',
      color: '#fff',
    },
  },
  tableHead: {
    '& .MuiTableCell-root': {
      textTransform: 'capitalize',
    },
  },
  tableRow: {
    '& :hover': {
      cursor: (props) => (props.isEdit ? 'pointer' : 'default'),
    },
    '& tabelCheckBox': {
      zIndex: '999',
    },
  },
  activeButton: {
    background: 'rgba(7, 83, 0, 0.1)',
    color: 'rgba(29, 122, 21, 1)',
    width: '100px',
  },
  inActiveButton: {
    background: 'rgba(255, 161, 161, 0.3)',
    color: 'red',
    width: '100px',
  },
  table: {
    '&  .MuiTableCell-head': {
      padding: ' 10px 8px'
    }
  }
}));

const TableHeadComponent: FC<any> = ({ allowedColumns, getAllSortedData, column, sortVal }: any) => (
  <TableHead sx={{ marginBottom: '1rem' }}>
    <TableRow>
      <TableCell padding="checkbox" />
      {allowedColumns?.map((col: any) => (
        <TableCell
          align="center"
          className="textBold"
          sortDirection={column === col?.sortName ? sortVal : false}
        >
          {logger(col, '', 'col: ')}
          {logger(column, '', 'column: ')}
          {
            col.isSort
              ? (
                <>
                  <TableSortLabel
                    active={column === col?.sortName}
                    direction={column === col?.sortName ? sortVal : 'asc'}
                    onClick={() => {
                      col.toggleSortData(`${col?.id}`);
                      getAllSortedData(`${col?.id}`, col.direction === 'asc' ? 'asc' : 'desc');
                    }}
                  >
                    {col?.name}
                  </TableSortLabel>
                </>
              )
              : col?.name
          }
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

const TableBodyComponent: FC<any> = ({
  data,
  editRouteUrl,
  allowedColumns,
  bulkIds,
  handleAddBulkIds,
  handleRemoveBulkIds,
  handleProjectVisibility,
  isEdit = false,
}: any) => {
  const classes = useStyles({ isEdit });
  const navigate = useNavigate();
  const handleRedirectNext = (id: string) => {
    localStorage.removeItem('formikValues');
    navigate(`${editRouteUrl}/project/${id}/case`);
  };

  const checkValue = (row, columnValue) => {
    if (columnValue === 'description') {
      return (
        row[columnValue]?.length > 90
          ? (
            <Tooltip
              title={row[columnValue].replace(/<[^>]*>|&nbsp;/g, '').trim()}
              placement="top"
            >
              <div>{`${row[columnValue]?.replace(/<[^>]*>|&nbsp;/g, '').trim().slice(0, 90)} ... `}</div>
            </Tooltip>
          ) : row[columnValue].replace(/<[^>]*>|&nbsp;/g, '')
      );
    }
    if (columnValue === 'isVisibleToClient') {
      if (row[columnValue] === undefined) {
        row[columnValue] = true;
      }
      return (
        <Button
          className={row[columnValue] ? classes.activeButton : classes.inActiveButton}
          onClick={(e) => {
            e.stopPropagation();
            handleProjectVisibility(row.id);
          }}
        >
          {row[columnValue] ? 'Visible' : 'Hidden'}
        </Button>
      )
    }
    if (typeof row[columnValue] === 'boolean') {
      return (
        <Button
          className={row[columnValue] ? classes.activeButton : classes.inActiveButton}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {row[columnValue] ? 'Active' : 'Inactive'}
        </Button>
      );
    }
    if (row[columnValue] == null) {
      return 'Null';
    }
    return row[columnValue];
  };

  return data?.length ? (
    <TableBody>
      {data?.map((row) => {
        const rowValue = Object.keys(row);
        return (
          <>

            <TableRow
              hover={isEdit}
              onClick={() => {
                if (isEdit) {
                  handleRedirectNext(row.id);
                }
              }}
              className={classes.tableRow}
            >
              <TableCell
                key={row.id}
                padding="checkbox"
              >
                <Checkbox
                  checked={bulkIds.includes(row.id)}
                  color="primary"
                  className={classes.tableCheckBox}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      handleAddBulkIds(row.id);
                    } else {
                      handleRemoveBulkIds('');
                    }
                  }}
                />
              </TableCell>
              {allowedColumns.map((column: Columns) => {
                if (rowValue.includes(column.value) || column.value === "isVisibleToClient") {
                  return (
                    <TableCell align="center">
                      {checkValue(row, column.value)}
                    </TableCell>
                  );
                }
                return <TableCell />;
              })}
            </TableRow>

          </>
        );
      })}
    </TableBody>
  )
    : (
      <TableBody>
        <TableRow>
          <TableCell
            align="center"
            sx={{ textTransform: 'capitalize', textAlign: 'center' }}
            colSpan={Number(allowedColumns?.length + 1)}
          >
            no data found
          </TableCell>
        </TableRow>
      </TableBody>
    );
};

const TableProjects: FC<TableProps> = ({
  data,
  editRouteUrl,
  allowedColumns,
  count,
  page,
  limit,
  handleLimit,
  handlePage,
  bulkIds,
  handleAddBulkIds,
  handleRemoveBulkIds,
  isEditAllowed,
  getAllSortedData,
  sortVal,
  column,
  handleProjectVisibility
}: TableProps) => {
  const classes = useStyles();

  const handleChangePage = useCallback(
    (event: any, _page: number) => {
      handlePage(_page);
    },
    [page, data]
  );
  const handleChangeLimit = useCallback(
    (event: any) => {
      handleLimit(parseInt(event.target.value, 10));
      handlePage(0);
    },
    [limit, data]
  );

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
      }}
      className={classes.table}
    >
      <Card className={classes.cardRoot}>
        <Scrollbar>
          <Box sx={{ minWidth: 700 }}>
            <Table>
              <TableHeadComponent
                allowedColumns={allowedColumns}
                column={column}
                sortVal={sortVal}
                getAllSortedData={getAllSortedData}
              />
              <TableBodyComponent
                bulkIds={bulkIds}
                data={data}
                editRouteUrl={editRouteUrl}
                allowedColumns={allowedColumns}
                handleAddBulkIds={handleAddBulkIds}
                handleRemoveBulkIds={handleRemoveBulkIds}
                isEdit={isEditAllowed}
                handleProjectVisibility={handleProjectVisibility}
              />
            </Table>
          </Box>
        </Scrollbar>
        <TablePagination
          component="div"
          count={count}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeLimit}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 100, 500, 1000]}
        />
      </Card>
    </Box>
  );
};

export default TableProjects;
