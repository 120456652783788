import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import RestartAltIcon from '@material-ui/icons/RestartAlt';
import { casesApi } from 'src/api/casesApi';
import CreateCase from 'src/components/cases/CreateCase';
import SearchBar from 'src/components/widgets/searchBar/SearchBar';
import CaseListView from 'src/components/widgets/tables/CaseListView';
import useSettings from 'src/hooks/useSettings';
import setTitle from 'src/utils/setTitle';
import useAuth from 'src/hooks/useAuth';
import CaseFilter from 'src/components/cases/CasesFilter';
import { Filter } from 'src/components/widgets/buttons/Filter';
import { ViewCaseStyle } from './styleSheet';
import { caseSortData, initialFilterState } from 'src/constants';
import { useNavigate } from 'react-router';
import AllProjects from './allProjects';

const useStyles = ViewCaseStyle

function ViewCases(props: any) {
  const navigate = useNavigate();
  setTitle('CRM | Cases');
  const { user } = useAuth();
  const { hiddenColumns } = props;
  const classes = useStyles();
  const { settings } = useSettings();
  type CaseViewStates = {
    id: string;
    name: string;
  };
  const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);
  const [autoRefresh, setAutoRefresh] = useState<number>(0);
  const [drawer, setDrawer] = useState<boolean>(false);
  const [bulkIds, setBulkIds] = useState<String>('');
  const [allData, setAllData] = useState<any>([]);
  const [isSubmit, setIsSubmit] = React.useState<boolean>(false);


  const initialFilters = initialFilterState;

  const [filtersApplied, setFiltersApplied] = useState(
    JSON.parse(localStorage.getItem('casesPagePg'))?.filtersApplied ||
    initialFilters
  );
  const [page, setPage] = useState<number>(
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesPageNo || 0
  );
  const [pageSize, setPageSize] = useState<number>(
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesPageLimit || 25
  );
  const handlePage = (temp: number) => {
    setPage(temp);
  };
  const [totalCount, setTotalCount] = useState<number>(0);
  const handlePageSize = (temp: number) => {
    setPageSize(temp);
  };
  const [search, setSearch] = useState<string>(
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesSearch || ''
  );
  const handleSearch = (val) => {
    setSearch(val);
    if (val === '') {
      setFiltersApplied({
        ...filtersApplied,
        search: null,
      });
    } else {
      setFiltersApplied({
        ...filtersApplied,
        search: {
          name: 'Search',
          data: val,
        },
      });
    }
  };
  const handleCloseDrawer = () => {
    setDrawer(!drawer);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const addCases = useCallback(
    async (open: boolean, event: any) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setDrawer(open);
    },
    [drawer]
  );

  const handleAddBulkIds = (id: String) => {
    setBulkIds(id);
  };

  const handleRemoveBulkIds = (id: String) => {
    setBulkIds(id);
  };

  const toggleAdvancedSearch = () => {
    setShowAdvancedSearch(!showAdvancedSearch);
  };

  type SortDataTypes = {
    id: 'asc' | 'desc';
    caseName: 'asc' | 'desc';
    organization: 'asc' | 'desc';
    createdBy: 'asc' | 'desc';
    assignee: 'asc' | 'desc';
    caseType: 'asc' | 'desc';
    project: 'asc' | 'desc';
    resolveTime: 'asc' | 'desc';
    firstResponseTime: 'asc' | 'desc';
    priority: 'asc' | 'desc';
    status: 'asc' | 'desc';
  };
  type SortOption = {
    key: string;
    value: string;
  };

  const [selectedSort, setSelectedSort] = useState<SortOption>({
    key:
      JSON.parse(localStorage.getItem('casesPagePg'))?.casesSelectedSortKey ||
      '',
    value:
      JSON.parse(localStorage.getItem('casesPagePg'))?.casesSelectedSortValue ||
      '',
  });
  const [sortData, setSortData] = useState<SortDataTypes>(caseSortData);
  const toggleSortData = (fieldName: string) => {
    type SortStatusType = 'asc' | 'desc';
    let sortStatus: SortStatusType = 'asc';
    const initial: SortDataTypes = {
      id: 'asc',
      caseName: 'asc',
      organization: 'asc',
      createdBy: 'asc',
      assignee: 'asc',
      caseType: 'asc',
      project: 'asc',
      resolveTime: 'asc',
      firstResponseTime: 'asc',
      priority: 'asc',
      status: 'asc',
    };
    
    // eslint-disable-next-line default-case
    switch (fieldName) {
      case 'id':
        if (sortData.id === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          id: sortStatus,
        });
        break;
      case 'title':
        if (sortData.caseName === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          caseName: sortStatus,
        });
        break;
      case 'organization':
        if (sortData.organization === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          organization: sortStatus,
        });
        break;
      case 'reporter':
        if (sortData.createdBy === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          createdBy: sortStatus,
        });
        break;
      case 'assignee':
        if (sortData.assignee === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          assignee: sortStatus,
        });
        break;
      case 'type':
        if (sortData.caseType === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          caseType: sortStatus,
        });
        break;
      case 'project':
        if (sortData.project === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          project: sortStatus,
        });
        break;
      case 'resolveTime':
        if (sortData.resolveTime === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          resolveTime: sortStatus,
        });
        break;
      case 'firstResponseTime':
        if (sortData.firstResponseTime === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          firstResponseTime: sortStatus,
        });
        break;
      case 'priority':
        if (sortData.priority === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          priority: sortStatus,
        });
        break;
      case 'status':
        if (sortData.status === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          status: sortStatus,
        });
        break;
    }
  };
  /** advance filters */
  type DateRange = {
    to: Date | null;
    from: Date | null;
  };
  const [date, setDate] = React.useState<DateRange>(
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesDate || {
      to: null,
      from: null,
    }
  );
  const [caseType, setCaseType] = React.useState<CaseViewStates[]>(
    (JSON.parse(localStorage.getItem('casesPagePg'))?.casesCaseType ||
      []) as CaseViewStates[]
  );
  const [project, setProject] = React.useState<any>(
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesProject || []
  );
  const [organization, setOrganization] = React.useState<CaseViewStates[]>(
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesOrganization || []
  );
  const [priority, setPriority] = React.useState<CaseViewStates[]>(
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesPriority || []
  );
  const [stakeholder, setStakeholder] = React.useState<CaseViewStates[]>(
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesStakeholder || []
  );
  const [assignee, setAssignee] = React.useState<CaseViewStates[]>(
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesAssignee || []
  );
  const [projectSponser, setProjectSponser] = React.useState<CaseViewStates[]>(
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesProjectSponser || []
  );
  const [escalationManager, setEscalationManager] = React.useState<
    CaseViewStates[]
  >(
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesEscalationManager ||
    []
  );
  const [status, setStatus] = React.useState<CaseViewStates[]>(
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesStatus || []
  );
  const [hasAttachments, setHasAttachments] = React.useState<boolean>(
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesHasAttachments ||
    false
  );
  const [isEscalated, setIsEscalated] = React.useState<boolean>(
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesIsEscalated || false
  );
  const [isFirstResponseTimeViolated, setIsFirstResponseTimeViolated] =
    React.useState<boolean>(
      JSON.parse(localStorage.getItem('casesPagePg'))
        ?.casesIsFirstResponseTimeViolated || false
    );
  const [isSynced, setIsSynced] = React.useState<boolean>(
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesIsSynced || false
  );
  const [isResolveTimeViolated, setIsResolveTimeViolated] =
    React.useState<boolean>(
      JSON.parse(localStorage.getItem('casesPagePg'))
        ?.casesIsResolvedTimeViolated || false
    );

  const [newTicketIds, setNewTicketIds] = useState<CaseViewStates[]>([]);
  const getAllData = async () => {
    try {
      const fieldToColumnMap = {
        id: 'caseKeyTruncate',
        title: 'summary',
        organization: 'organization.name',
        reporter: 'createdBy.name',
        assignee: 'assignee.name',
        type: 'caseType.name',
        resolveTime: 'sla.actualTime.resolveTime',
        firstResponseTime: 'sla.actualTime.firstResponseTime',
        priority: 'priority.rank',
        status: 'status.rank',
        project: 'project.name',
      };

      const columnValue = fieldToColumnMap[selectedSort?.key] || 'defaultSort';
      const payload = {
        page,
        pageSize,
        search,
        caseType: caseType?.map((itm) => itm.id).toString(),
        priority: priority?.map((itm) => itm.id).toString(),
        stakeholder: stakeholder?.map((itm) => itm.id).toString(),
        assignee: assignee?.map((itm) => itm.id).toString(),
        projectSponser: projectSponser?.map((itm) => itm.id).toString(),
        escalationManager: escalationManager?.map((itm) => itm.id).toString(),
        status: status?.map((itm) => itm.id).toString(),
        projectId: project?.map((itm) => itm.id).toString(),
        organizationId: organization?.map((itm) => itm.id).toString(),
        hasAttachments,
        isEscalated,
        isFirstResponseTimeViolated,
        isSynced,
        isResolveTimeViolated,
        to: date?.to,
        from: date?.from,
        column: columnValue,
        sort: selectedSort?.value === 'asc' ? '1' : '-1',
      };
      if (selectedSort?.key === '' || selectedSort?.value === undefined) {
        delete payload.column;
        delete payload.sort;
      }
      const keys = Object.keys(payload);
      const values = Object.values(payload);

      // Create an array to store the non-empty key-value pairs
      const queryParams = [];
      for (let i = 0; i < values.length; i++) {
        if (values[i] !== '' && values[i] !== null && values[i] !== undefined) {
          queryParams.push(`${encodeURIComponent(keys[i])}=${values[i]}`);
        }
      }
      const queryString = queryParams.join('&');
      let response = await casesApi.advanceSearch(queryString);
      response = response?.data;
      const existingTicketIds = allData?.map((ticket) => ticket.id);
      // Compare with existing data to find newly added tickets
      const newTickets = response?.data?.filter(
        (ticket) => !existingTicketIds.includes(ticket.id)
      );

      if (newTickets.length > 0 && autoRefresh !== 0) {
        setNewTicketIds(newTickets.map((ticket) => ticket.id));
      }

      setAllData(response?.data);
      setTotalCount(response?.totalRecords);
      setIsSubmit(false);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setAllData([]);
        setTotalCount(0);
      }
    }
  };

  const deleteCaseById = async (id: String) => {
    try {
      const res = await casesApi.deleteCase(id);
      if (res?.status === 200) {
        toast.success('Case deleted successfully');
        getAllData();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleAutoRefresh = (event) => {
    const newDuration = parseInt(event.target.value, 10);
    setAutoRefresh(newDuration);
  };

  const requiredPermissions = [
    'case-get-all',
    'case-filter-tabs-read',
    'case-filter-advanced-search-read',
    'case-filter-autorefresh-read',
    'case-create',
    'case-toggle-status',
    'case-delete',
    'case-bulk-deactivate',
    'case-get-by-id',
    'case-update',
  ];
  const permissions = {
    getAll: user?.permissions?.includes('case-get-all'),
    filterTabs: user?.permissions?.includes('case-filter-tabs-read'),
    create: user?.permissions?.includes('case-create'),
    toggleStatus: user?.permissions?.includes('case-toggle-status'),
    delete: user?.permissions?.includes('case-delete'),
    bulkDeactivate: user?.permissions?.includes('case-bulk-deactivate'),
    getById: user?.permissions?.includes('case-get-by-id'),
    update: user?.permissions?.includes('case-update'),
  };
  const updateAutocompleteState = (id, newData): any => {
    switch (id) {
      case 'caseType':
        setCaseType(newData);
        break;
      case 'priority':
        setPriority(newData);
        break;
      case 'stakeholder':
        setStakeholder(newData);
        break;
      case 'assignee':
        setAssignee(newData);
        break;
      case 'projectSponser':
        setProjectSponser(newData);
        break;
      case 'escalationManager':
        setEscalationManager(newData);
        break;
      case 'status':
        setStatus(newData);
        break;
      case 'hasAttachments':
        setHasAttachments(newData);
        break;
      case 'escalatedCases':
        setIsEscalated(newData);
        break;
      case 'isFirstResponseTimeViolated':
        setIsFirstResponseTimeViolated(newData);
        break;
      case 'isSynced':
        setIsSynced(newData);
        break;
      case 'isResolveTimeViolated':
        setIsResolveTimeViolated(newData);
        break;
      case 'date':
        setDate(newData);
        break;
      case 'project':
        setProject(newData);
        break;
      case 'organization':
        setOrganization(newData);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (
      !requiredPermissions?.some((permission) =>
        user?.permissions?.includes(permission)
      )
    ) {
      window.location.href = '/admin/not-found';
    }
    getAllData();
    const casesPagePg = {
      casesPageLimit: pageSize,
      casesPageNo: page,
      casesSearch: search,
      casesSortId: sortData?.id,
      casesSortCaseName: sortData?.caseName,
      casesSortOrganization: sortData?.organization,
      casesSortCreatedBy: sortData?.createdBy,
      casesSortAssignee: sortData?.assignee,
      casesSortCaseType: sortData?.caseType,
      casesSortProject: sortData?.project,
      casesSortResolveTime: sortData?.resolveTime,
      casesSortFirstResponseTime: sortData?.firstResponseTime,
      casesSortPriority: sortData?.priority,
      casesSortStatus: sortData?.status,
      casesCaseType: caseType,
      casesPriority: priority,
      casesStakeholder: stakeholder,
      casesAssignee: assignee,
      casesOrganization: organization,
      casesProject: project,
      casesProjectSponser: projectSponser,
      casesEscalationManager: escalationManager,
      casesStatus: status,
      casesHasAttachments: hasAttachments,
      casesIsEscalated: isEscalated,
      casesIsFirstResponseTimeViolated: isFirstResponseTimeViolated,
      casesIsSynced: isSynced,
      casesIsResolvedTimeViolated: isResolveTimeViolated,
      casesDate: date,
      casesSelectedSortKey: selectedSort?.key,
      casesSelectedSortValue: selectedSort?.value,
    };
    localStorage.setItem('casesPagePg', JSON.stringify(casesPagePg));
    // document.addEventListener('mousedown', handleClickOutside);
    let autoRefreshTime;
    if (autoRefresh !== 0) {
      autoRefreshTime = setInterval(() => {
        getAllData();
      }, autoRefresh * 1000);
    }
    return () => {
      // document.removeEventListener('mousedown', handleClickOutside);
      clearTimeout(autoRefreshTime);
    };
  }, [
    isSubmit,
    autoRefresh,
    page,
    pageSize,
    search,
    sortData?.id,
    sortData?.caseName,
    sortData?.organization,
    sortData?.createdBy,
    sortData?.assignee,
    sortData?.caseType,
    sortData?.project,
    sortData?.resolveTime,
    sortData?.firstResponseTime,
    sortData?.priority,
    sortData?.status,
    selectedSort?.key,
    selectedSort?.value,
  ]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 4,
        }}
        className={classes.ticketMain}>
        <Container
          maxWidth={settings.compact ? 'xl' : false}
          className={
            hiddenColumns?.customCss === 'customCss' ? 'customCss' : ''
          }>
          <Grid container justifyContent="space-between" spacing={3}>
            {hiddenColumns?.heading === false ? (
              ''
            ) : (
              <Grid item>
                <Typography color="textPrimary" variant="h3">
                  Cases
                </Typography>
              </Grid>
            )}
          </Grid>
          <Box sx={{ mt: 1 }}>
            <Grid container>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                {user?.permissions?.includes('case-filter-search-read') && (
                  <SearchBar
                    onSearch={handleSearch}
                    value={search}
                    anyState={autoRefresh !== 0}
                  />
                )}
                {user?.permissions?.includes(
                  'case-filter-advanced-search-read'
                ) && (
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{ height: 30, marginLeft: 1, fontWeight: 'normal' }}
                      onClick={toggleAdvancedSearch}
                      disabled={autoRefresh !== 0}>
                      Advanced Search
                    </Button>
                  )}
                {user?.permissions?.includes(
                  'case-filter-autorefresh-read'
                ) && (
                    <Select
                      value={autoRefresh}
                      onChange={handleAutoRefresh}
                      sx={{ height: 30, marginLeft: 1, fontWeight: 'normal' }}>
                      <MenuItem selected value={0}>
                        Refresh
                      </MenuItem>
                      <MenuItem value={30}>30 sec</MenuItem>
                      <MenuItem value={60}>1 min</MenuItem>
                      <MenuItem value={300}>5 mins</MenuItem>
                      <MenuItem value={900}>15 mins</MenuItem>
                    </Select>
                  )}
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}>
                {' '}
                <Button
                  className='bulkChange'
                  variant="contained"
                  color="secondary"
                  // className={classes.btn}
                  sx={{ height: 30, marginRight: '10px' }}
                  // onClick={(e) => navigate('bulk-change')}
                  onClick={openModal}
                >
                  Bulk Change
                </Button>
                {user?.permissions?.includes('case-create') && (
                  <Button
                    variant="contained"
                    color="secondary"
                    endIcon={<AddCircleIcon />}
                    // className={classes.btn}
                    sx={{ height: 30 }}
                    onClick={(e) => addCases(true, e)}>
                    Create case
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<RestartAltIcon />}
                  className={classes.btn}
                  onClick={() => {
                    localStorage.removeItem('casesPagePg');
                    handlePageSize(10);
                    handlePage(0);
                    handleSearch('');
                    setSortData({
                      id: 'asc',
                      caseName: 'asc',
                      organization: 'asc',
                      createdBy: 'asc',
                      assignee: 'asc',
                      caseType: 'asc',
                      project: 'asc',
                      resolveTime: 'asc',
                      firstResponseTime: 'asc',
                      priority: 'asc',
                      status: 'asc',
                    });
                    setCaseType([]);
                    setProject([]);
                    setPriority([]);
                    setOrganization([]);
                    setStakeholder([]);
                    setAssignee([]);
                    setProjectSponser([]);
                    setEscalationManager([]);
                    setStatus([]);
                    setHasAttachments(false);
                    setIsEscalated(false);
                    setIsFirstResponseTimeViolated(false);
                    setIsSynced(false);
                    setIsResolveTimeViolated(false);
                    setDate({ to: null, from: null });
                    setSelectedSort({ key: '', value: '' });
                  }}
                />
                {user?.permissions?.includes('case-delete') && (
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<DeleteIcon />}
                    className={classes.btn}
                    disabled={!bulkIds.length}
                    onClick={() => deleteCaseById(bulkIds)}
                    style={{ display: 'none' }}
                  />
                )}
              </Grid>
            </Grid>
            {Object.values(filtersApplied).some((val) => val !== null) && (
              <Box marginTop="5px" marginLeft="-5px">
                {/* Filters: */}
                {Object.keys(filtersApplied).map((keyName) =>
                  filtersApplied[keyName] !== null ? (
                    <Filter
                      filtersApplied={{ ...filtersApplied }}
                      key={keyName}
                      id={keyName}
                      setFiltersApplied={setFiltersApplied}
                      updateAutocompleteState={updateAutocompleteState}
                      setIsSubmit={setIsSubmit}
                    />
                  ) : (
                    ''
                  )
                )}
              </Box>
            )}
            <CaseFilter
              // _headingMain="Advanced Search"
              _isOpen={showAdvancedSearch}
              _setShowAdvancedSearch={setShowAdvancedSearch}
              _handleSubmit={() => null}
              _search={search}
              _setSearch={handleSearch}
              _caseType={caseType}
              _setCaseType={setCaseType}
              _priority={priority}
              _setPriority={setPriority}
              _stakeholder={stakeholder}
              _setStakeholder={setStakeholder}
              _assignee={assignee}
              _setAssignee={setAssignee}
              _projectSponser={projectSponser}
              _setProjectSponser={setProjectSponser}
              _escalationManager={escalationManager}
              _setEscalationManager={setEscalationManager}
              _status={status}
              _setStatus={setStatus}
              _hasAttachments={hasAttachments}
              _setHasAttachments={setHasAttachments}
              _isEscalated={isEscalated}
              _setIsEscalated={setIsEscalated}
              _isFirstResponseTimeViolated={isFirstResponseTimeViolated}
              _setIsFirstResponseTimeViolated={setIsFirstResponseTimeViolated}
              _isSynced={isSynced}
              _setIsSynced={setIsSynced}
              _isResolveTimeViolated={isResolveTimeViolated}
              _setIsResolveTimeViolated={setIsResolveTimeViolated}
              _date={date}
              _setDate={setDate}
              _project={project}
              _setProject={setProject}
              _setPage={setPage}
              _organization={organization}
              _setOrganization={setOrganization}
              _setFiltersApplied={setFiltersApplied}
              _filtersApplied={filtersApplied}
              isSubmit={isSubmit}
              setIsSubmit={setIsSubmit}
            />
            {user?.permissions?.includes('case-get-all') && (
              <CaseListView
                data={allData}
                sortData={sortData}
                toggleSortData={toggleSortData}
                selectedSort={selectedSort}
                setSelectedSort={setSelectedSort}
                redirectUrl="/erp/cases"
                editRouteUrl="/erp/cases"
                page={page}
                limit={pageSize}
                handleLimit={handlePageSize}
                handlePage={handlePage}
                count={totalCount}
                bulkIds={bulkIds}
                handleAddBulkIds={handleAddBulkIds}
                handleRemoveBulkIds={handleRemoveBulkIds}
                permissions={permissions}
                newTicketIds={newTicketIds}
              />
            )}
          </Box>
        </Container>
      </Box>


      {/* Modal component */}
      <AllProjects
        isOpen={isModalOpen}
        onClose={closeModal}
      />

      {drawer && (
        <CreateCase
          isOpen={drawer}
          addCases={addCases}
          closeDrawer={handleCloseDrawer}
          getAllData={getAllData}
          nested={false}
        />
      )}
    </>
  );
}

export default ViewCases;
