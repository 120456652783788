/* eslint-disable @typescript-eslint/naming-convention */
import { Autocomplete, Box, Button, Checkbox, Chip, FormControlLabel, Grid, IconButton, InputAdornment, Modal, TextField, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import moment from 'moment';
import * as React from 'react';
import { DateRange } from 'react-date-range';
import { casesApi } from 'src/api/casesApi';
import CancelIcon from '@material-ui/icons/Cancel';
import logger from 'src/utils/logger';
import { requestApi } from 'src/api/requestApi';

const useStyles = makeStyles(() => ({
  modal: {
    '& .modalBody': {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '735px',
      background: '#fff',
      boxShadow: '0px 4px 31px 0px rgba(0, 0, 0, 0.25)',
      borderRadius: '4px',
      padding: '16px 24px',

      '& .MuiOutlinedInput-root:has(.MuiButtonBase-root:nth-child(n+3))': {
        // backgroundColor: 'pink',
        // width: '200px',
        paddingLeft: '5px',
        '& .MuiChip-root': {
          maxWidth: '120px',
          fontSize: '10px',
          height: '23px',
        },
        '& .MuiChip-label': {
          paddingLeft: '8px',
          paddingRight: '8px',
        },
        '& .MuiSvgIcon-root': {
          marginRight: '3px',
        }
      }
    },
    '& .modal-title': {
      background: '#231F20',
      borderRadius: '4px 4px 0px 0px',
      height: '48px',
      margin: '-16px -24px',
      padding: '16px 24px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '& .MuiTypography-root': {
        color: '#fff',
        fontSize: '14px',
        fontstyle: 'normal',
      },
      '& .MuiIconButton-root': {
        padding: '0px',
      }
    },
    '& .fields+.fields': {
      marginTop: '15px'
    },
    '& .datefield': {
      position: 'relative',
      '& .rdrCalendarWrapper': {
        zIndex: '999',
        position: 'absolute',
        right: '-1px',
        top: '40px',
        borderRadius: '5px',
        border: '1px solid rgb(239, 242, 247)',
        '& .rdrDateDisplayWrapper': {
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
        }
      }
    },
    '& select, & .MuiAutocomplete-inputRoot, & input': {
      minHeight: '38px',
      padding: '0 10px',
      lineHeight: '116.7%',
      color: '#696969',
      width: '100%',
      '& .MuiAutocomplete-input': {
        padding: 0
      }
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.2rem'
    },
    '& .MuiFormControlLabel-root': {
      padding: '0 20px',
      '& span': {
        fontSize: '14px'
      }
    },
    '& .btns': {
      display: 'flex',
      // marginTop: '148px',
      position: 'absolute',
      bottom: '30px',
      right: '24px',
      '& button': {
        width: '115px',
        height: 30
      }
    },
  }
}));
export default function RequestAdvanceSearch(props: any) {
  const {
    _headingMain,
    _setShowAdvancedSearch,
    _search,
    _setSearch,
    _category,
    _setCategory,
    _status,
    _setStatus,
    _createdBy,
    _setCreatedBy,
    _hasAttachments,
    _setHasAttachments,
    _date,
    _setDate,
  } = props;
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
    _setShowAdvancedSearch(false);
  };
  const classes = useStyles();

  const [search, setSearch] = React.useState(_search);
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  /** date */
  const [state, setState] = React.useState<any>([
    {
      startDate: _date.from ? new Date(_date.from) : null,
      endDate: _date.to ? new Date(_date.to) : null,
      key: 'selection',
      color: '#c22027',
    }
  ]);
  const [isOpen, setIsOpen] = React.useState(false);
  const ref = React.useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  /** status */
  const [status, setStatus] = React.useState<any>(_status || []);
  const [statusInput, setStatusInput] = React.useState('');
  const allStatus = [
    {
      id: 0,
      name: 'Waiting',
    },
  ];

  /** createdBy */
  const [createdBy, setCreatedBy] = React.useState<any>(_createdBy || []);
  const [createdByInput, setCreatedByInput] = React.useState('');

  /** categories */
  const [category, setCategory] = React.useState<any>(_category || []);
  const [categoryInput, setCategoryInput] = React.useState('');
  const [allCategories, setAllCategories] = React.useState([]);
  const getAllCategories = async () => {
    try {
      const response = await requestApi.getAllCategories();
      setAllCategories(response?.data);
    } catch (error) {
      setAllCategories([]);
    }
  };

  /** has-attachments */
  const [hasAttachments, setHasAttachments] = React.useState(_hasAttachments);
  const toggleHasAttachments = () => {
    setHasAttachments(!hasAttachments);
  };

  /** internal-users */
  const [allInternalUsers, setAllInternalUsers] = React.useState([]);
  logger(allInternalUsers);
  const getAllInternalUsers = async () => {
    try {
      const response = await casesApi.getUserById(false);
      if (response?.status === 200) {
        setAllInternalUsers(response?.data);
      }
    } catch (error) {
      setAllInternalUsers([]);
    }
  };

  React.useEffect(() => {
    getAllInternalUsers();
    getAllCategories();
  }, []);

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleReset = () => {
    _setSearch('');
    _setCategory([]);
    _setCreatedBy([]);
    _setStatus([]);
    _setHasAttachments(false);
    _setDate({
      to: null,
      from: null
    });
    handleClose();
  };
  const handleSubmit = () => {
    _setSearch(search);
    _setCategory(category);
    _setCreatedBy(createdBy);
    _setStatus(status);
    _setHasAttachments(hasAttachments);
    _setDate({
      to: state[0].endDate ? moment(state[0].endDate).format('YYYY-MM-DD') : null,
      from: state[0].startDate ? moment(state[0].startDate).format('YYYY-MM-DD') : null
    });
    handleClose();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modal}
      >
        <Box className="modalBody">
          <div className="modal-title">
            <Typography
              variant="h6"
              id="modal-title"
            >
              {_headingMain}
            </Typography>
            <IconButton
              aria-label="close"
              color="primary"
              onClick={handleClose}
            >
              <CancelIcon style={{ color: '#fff', }} />
            </IconButton>
          </div>

          <Grid
            container
            spacing={3}
            sx={{ py: 2 }}
          >

            <Grid
              item
              md={6}
              xs={12}

            >
              <div className="fields">
                <TextField
                  id="search-18323038"
                  value={search}
                  onChange={handleSearch}
                  variant="outlined"
                  name="search"
                  fullWidth
                  placeholder="Request no, summary, status..."
                />
              </div>
              <div className="fields">
                <Autocomplete
                  id="issue-name"
                  options={allCategories || []}
                  multiple
                  value={category}
                  onChange={(event: any, value: any) => {
                    setCategory(value);
                  }}
                  getOptionLabel={(option) => option.name}
                  inputValue={categoryInput}
                  onInputChange={(event: any, newInputValue: any) => {
                    setCategoryInput(newInputValue);
                  }}
                  style={{ minWidth: 300 }}
                  renderTags={(value, getTagProps) => value.map((option, index) => (
                    <Tooltip title={option?.name || ''}>
                      <Chip
                        variant="filled"
                        label={option?.name || ''}
                        size="small"
                        {...getTagProps({ index })}
                      />
                    </Tooltip>
                  ))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={category.length ? "" : "Category"}
                    />
                  )}
                />
              </div>
              <div className="fields">
                <Autocomplete
                  id="issue-name"
                  options={allInternalUsers || []}
                  multiple
                  value={createdBy}
                  onChange={(event: any, value: any) => {
                    setCreatedBy(value);
                  }}
                  getOptionLabel={(option) => option.name}
                  inputValue={createdByInput}
                  onInputChange={(event: any, newInputValue: any) => {
                    setCreatedByInput(newInputValue);
                  }}
                  style={{ minWidth: 300 }}
                  renderTags={(value, getTagProps) => value.map((option, index) => (
                    <Tooltip title={option?.name || ''}>
                      <Chip
                        variant="filled"
                        label={option?.name || ''}
                        size="small"
                        {...getTagProps({ index })}
                      />
                    </Tooltip>
                  ))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={createdBy.length ? "" : "Created By"}
                    />
                  )}
                />
              </div>
              <div className="fields">
                <Autocomplete
                  id="issue-name"
                  options={allStatus || []}
                  multiple
                  value={status}
                  onChange={(event: any, value: any) => {
                    setStatus(value);
                  }}
                  getOptionLabel={(option) => option.name}
                  inputValue={statusInput}
                  onInputChange={(event: any, newInputValue: any) => {
                    setStatusInput(newInputValue);
                  }}
                  style={{ minWidth: 300 }}
                  renderTags={(value, getTagProps) => value.map((option, index) => (
                    <Tooltip title={option?.name || ''}>
                      <Chip
                        variant="filled"
                        label={option?.name || ''}
                        size="small"
                        {...getTagProps({ index })}
                      />
                    </Tooltip>
                  ))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={status.length ? "" : "Status"}
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <div className="fields datefield">
                <TextField
                  value={state[0].startDate ? moment(state[0].startDate).format('MM-DD-YYYY') : 'mm/dd/yyyy'}
                  onClick={() => { setIsOpen(!isOpen); }}
                  size="small"
                  style={{ width: '48%', marginRight: '4%', }}
                  label="From"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CalendarTodayIcon
                          color="primary"
                          fontSize="small"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  value={state[0].endDate ? moment(state[0].endDate).format('MM-DD-YYYY') : 'mm/dd/yyyy'}
                  disabled
                  size="small"
                  style={{ width: '48%', }}
                  label="To"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CalendarTodayIcon
                          color="primary"
                          fontSize="small"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                {isOpen && (
                  <div ref={ref}>
                    <DateRange
                      ranges={state}
                      onChange={(item) => {
                        setState([item.selection]);
                      }}
                      editableDateInputs
                    />
                  </div>
                )}
              </div>
              <div className="fields">
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={hasAttachments}
                      onChange={toggleHasAttachments}
                    />
                  )}
                  label="Has attachments"
                />
              </div>
              <div className="btns">
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ marginLeft: 'auto', height: '38px' }}
                  onClick={handleReset}
                >
                  Reset
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  sx={{ height: '38px', marginLeft: '12px', }}
                  onClick={handleSubmit}
                >
                  Search
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
