/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  Autocomplete,
  Avatar,
  Box,
  Grid,
  Link,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Formik } from 'formik';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { organizationApi } from 'src/api/organizationApi';
import setTitle from 'src/utils/setTitle';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { organizationDetailData } from 'src/store/slices/organizationDetailsSlice';
import { casesApi } from 'src/api/casesApi';
import logger from 'src/utils/logger';
import countriesAndCities from '../addNew/countriesData';
import { projectApi } from 'src/api/projectApi';
import * as Yup from 'yup';
import useAuth from 'src/hooks/useAuth';
import MultiFileLinkInput from 'src/components/MultiFileLinkInput';

interface Input {
  id: number;
  value: string;
  label: string;
}


const useStyles = makeStyles<Theme>(() => createStyles({
  root: {
    padding: '0  ',
    position: 'relative',
    '& .MuiFormControl-root': {
      width: '100%'
    },
    '& .createTicket': {
      '& .MuiDrawer-root': {
        width: '800px'
      }
    },
    '& .borderRight': {
      borderRight: '1px solid #E0E0E0'
    },
    '& .MuiBox-root': {
      padding: '10px 10px 0',
      '& .MuiBox-root': {
        padding: '0'
      }
    },
    '& .MuiAccordion-root.Mui-expanded:first-of-type ': {
      marginTop: '13px'
    },
    '& .MuiAccordion-root:first-child': {
      borderTop: '1px solid #E0E0E0',
    },
    '& .MuiAccordion-root': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      margin: '11px 0  0',
      padding: 0,
      '& .headingA': {
        fontWeight: 600
      },

      '& MuiAccordionDetails-root': {
        padding: '8px 0px 16px'
      },
      '& .MuiAccordionSummary-expandIconWrapper': {
        margin: '0 10px 0 0'
      },
      '& .MuiAccordionDetails-root': {
        // height: "72.5vh",
        overflowX: 'auto'
      },
      '& .MuiAccordionSummary-root': {
        flexDirection: ' row-reverse',
        postion: 'relative',
      },
      '&::before': {
        content: 'initial'
      },
    },
    '& .orgEdit, orgEdit.textArea': {
      '& fieldset': {
        border: '0',
        background: 'none',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '0px',
      },
      '& input': {
        padding: '5px 0',
        fontWeight: 600,
        fontSize: '14px',
        color: ' #202124',
        WebkitTextFillColor: '#202124',
        cursor: 'default',
      },
      '& .MuiSelect-select, .MuiOutlinedInput-root': {
        padding: '0px',
        fontWeight: 600,
        pointerEvents: 'none',
        webkitTextFillColor: 'rgb(32 33 36)',
        fontSize: '14px',
        color: ' #202124',
        cursor: 'default',
        '& textarea': {
          WebkitTextFillColor: '#202124',
        },
        '& ~ svg': {
          display: 'none'
        }
      },
      '& .MuiIconButton-label': {
        '& svg': {
          display: 'none'
        }
      },
      '& .MuiAutocomplete-input': {
        padding: 0
      }
    },
    '& .orgEditEnable': {
      '& fieldset': {
        padding: '16.5px 10px',
        margin: '10px 0',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        width: '100%'
      },
      '& .MuiSelect-select': {
        padding: '16.5px 10px',
        color: ' #202124',
        '& ~ svg': {
          display: 'block'
        }
      },
      '& .MuiIconButton-label': {
        '& svg': {
          display: 'block'
        }
      },
    },
    '& .orgInfo+.orgInfo': {
      margin: '20px 0 ',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    '& .MuiAccordionSummary-content': {
      justifyContent: 'space-between'
    },
    '& .editInfoDetails': {
      maxWidth: '15px',
      position: 'absolute',
      right: '10px',
      top: '20px',
      zIndex: '222',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    '& .MuiAccordionSummary-root[disable=true]': {
      pointerEvents: 'none'
    },

    '& .tabsArea': {
      margin: '25px 0 0',
      '& .MuiTab-root': {
        height: '32px',
        minHeight: 'inherit',
        margin: '0 10px'
      },
      '& hr': {
        border: 0,
        borderTop: '0.8px solid #E0E0E0',
        // margin: '21px 0 0',
        // borderWidth: '0.8px',
      },
      '& .MuiTabs-indicator ': {
        display: 'none'
      },
      "& .Mui-selected[aria-selected='true']": {
        background: '#231F20',
        color: '#fff'
      }
    },

    '& .tickets': {
      padding: '0 0 0  20px',
      '& .ticketsInner+.ticketsInner': {
        marginTop: '20px'
      },
      '& .addTicket': {
        marginTop: '20px',
        marginRight: '15px',
        position: 'relative',
        zIndex: '222'
      }
    },
    '& .MuiAccordionSummary-root': {
      background: 'none',
      color: '#231F20',
      borderRadius: '0  0 4px 4px  ',
      padding: '18px 16px',
      boxShadow: 'none',
      '& .accordionSummary': {
        margin: '10px 0 5px 0',
        '& .meetingCardTitle&Desc': {}
      }
    },
    '& .MuiAccordionSummary-expandIconWrapper ': {
      color: '#231F20'
    },
    '& .drawerButton': {
      minWidth: '31px',
      width: '30px',
      height: '30px',
      display: 'flex',
      backgroundColor: 'black',
      position: 'absolute',
      top: '20px',
      right: 0,
      borderRadius: '50%',
      transition: 'linear 0.3s',
      '& img': {
        width: '100%',
        objectFit: 'contain',
      },
      '&:hover': {
        transform: 'scale(1.1)',
      }
    },
    '& .multiFileInputContainer': {
      paddingLeft: 0
    },
    '& .multiFileInputDisabled': {
      border: '0',
      background: 'none',
      fontSize: '14px',
      fontWeight: 'bold',
      padding: '0px',
    },
    '& .errorInvalid': {
      color: 'red',
      fontSize: '12px',
      paddingleft: '15px',
      margin: '0px',
    }
  },
  addButton: {
    float: 'right',
    width: '30px',
    borderRadius: '5px',
    minWidth: 'initial',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiButton-startIcon': {
      margin: 0
    }
  },
  createTicketDrawer: {
    width: '40%',
    padding: '5px 20px ',
    zIndex: 1300,
    border: 'none',
    '& .ticketBody': {
      height: '90vh',
      overflowY: 'auto',
      padding: '0 20px'
    },
    '& h4': {
      background: '#C22027',
      color: '#fff',
      padding: '0 40px',
      margin: '-5px -20px 30px',
      height: '64px',
      display: 'flex',
      alignItems: 'center'
    },
    '& label': {
      letterSpacing: ' 0.15px',
      color: '#231F20',
      fontWeight: '600',
      paddingBottom: '6px',
      display: 'block'
    },

    '& select': {
      height: '38px',
      backgroundColor: '#F3F3F3',
      padding: '0 10px',
      lineHeight: '116.7%',
      color: '#696969',
      border: '0.9px solid #C7C7C7',
      width: '100%'
    },
    '& .ticketDropDown+.ticketDropDown': {
      marginTop: '15px'
    },
    '& .submitTicket ': {
      margin: '35px 0'
    },
    '& .submitTicket button+button': {
      marginLeft: '15px'
    }
  }
}));

type CustomComponentTypes = {
  url: string;
  altText: string;
  handleClick: () => void;
  checkStates?: any;
};
const CustomComponent = ({
  url,
  altText,
  handleClick,
  checkStates
}: CustomComponentTypes) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
  <div
    className="editInfoDetails"
    onClick={handleClick}
  >
    {checkStates ? '' : (
      <img
        src={url}
        alt={altText}
      />
    )}
  </div>
);
// ------------> START <---------------
const OrganizationDetails: FC = () => {
  setTitle('CRM | Organization - Projects');
  const baseUrl = '/erp/crm/organization/details';
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { organizationID, projectID } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const locationSlugs = location?.pathname.split('/');
  const getProjDetails = useSelector((state: any) => state.orgInfo?.projInfo);
  const timeZones = moment.tz.names();
  const [editInfo, setEditInfo] = useState<Array<boolean>>([
    true,
    true,
    true
  ]);
  const [expanded, setExpanded] = useState<string | boolean>('aboutDetails');
  const [value, setValue] = useState<string>(locationSlugs[6]);
  const classes = useStyles();
  const [organizationData, setOrganizationData] = useState<any>();
  const [organizationSummary, setOrganizationSummary] = useState<any>({});
  const [projectDetails, setProjectDetails] = useState<any>({
    projectName: '',
    projectDescription: '',
    projectEscalationManager: {},
    projectStakeHolders: [],
    projectSponsors: [],
    approvalManagers: [],
    projectTotalCases: 0,
    projectKey: '',
    projectDomain: '',
    projectWebsite: '',
    projectSLAFirstResponseTime: '',
    projectSLAResolveTime: '',
  });
  const [allEscalationManagers, setAllEscalationManagers] = useState<Array<any>>([]);
  const [allStakeholders, setAllStakeholders] = useState<Array<any>>([]);
  const [allSponsors, setAllSponsors] = useState<Array<any>>([]);
  const [organizationType, setOrganizationType] = useState<any>([]);

  const [googleDocLinks, setGoogleDocLinks] = useState<Input[]>([{ id: Date.now(), value: '', label: '' }]);
  const [drawioLinks, setDrawioLinks] = useState<Input[]>([{ id: Date.now(), value: '', label: '' }]);
  const [figmaLinks, setFigmaLinks] = useState<Input[]>([{ id: Date.now(), value: '', label: '' }]);
  const [lowLevelDesignLinks, setLowLevelDesignLinks] = useState<Input[]>([{ id: Date.now(), value: '', label: '' }]);
  const [highLevelDesignLinks, setHighLevelDesignLinks] = useState<Input[]>([{ id: Date.now(), value: '', label: '' }]);

  const [webSiteError, setWebSiteError] = useState('');
  const [projectResolveTime, setProjectResolveTime] = useState('');

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
    if (newValue === 'project') {
      navigate(`${baseUrl}/${organizationID}/project`);
    } else if (newValue === 'contacts') {
      navigate(`${baseUrl}/${organizationID}/contacts`);
    } else if (newValue === 'events') {
      navigate(`${baseUrl}/${organizationID}/events`);
    } else if (newValue === 'consulting') {
      navigate(`${baseUrl}/${organizationID}/consulting`);
    } else if (newValue === 'client-settings') {
      navigate(`${baseUrl}/${organizationID}/client-settings`);
    } else if (newValue === 'white-label') {
      navigate(`${baseUrl}/${organizationID}/white-label`);
    }
  };
  const handleChangeName = (e) => {

    setProjectDetails((pre) => ({
      ...pre,
      projectName: e.target.value,
    }));
  };

  const handleChangeDescription = (e) => {
    setProjectDetails((pre) => ({
      ...pre,
      projectDescription: e.target.value,
    }));
  };

  const handleChangeKey = (e) => {
    setProjectDetails((pre) => ({
      ...pre,
      projectKey: e.target.value,
    }));
  };

  const handleChangeDomain = (e) => {
    setProjectDetails((pre) => ({
      ...pre,
      projectDomain: e.target.value,
    }));
  };

  const handleChangeWebsite = (e) => {
    setProjectDetails((pre) => ({
      ...pre,
      projectWebsite: e.target.value,
    }));
  };

  const handleChangeSLAFirstResponseTime = (e) => {
    setProjectDetails((pre) => ({
      ...pre,
      projectSLAFirstResponseTime: e.target.value,
    }));
  };

  const handleChangeSLAResolveTime = (e) => {
    setProjectDetails((pre) => ({
      ...pre,
      projectSLAResolveTime: e.target.value,
    }));
  };

  const editOrgnaizationInfo = (type: number) => {
    logger(type, '', 'e');
    setEditInfo((prev) => {
      const arrCopy = [...editInfo];
      arrCopy[type] = !prev[type];
      return arrCopy;
    });
  };

  const saveOrgnaizationInfo = (type: number) => {
    setEditInfo((prev) => {
      const arrCopy = [...editInfo];
      arrCopy[type] = !prev[type];
      return arrCopy;
    });
  };

  const handleChangeA = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const getById = async () => {
    try {
      const response = await organizationApi.getById(`${organizationID}`);
      setOrganizationData(response?.data);
      dispatch(organizationDetailData(response?.data));
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setOrganizationData([]);
      }
    }
  };

  const getAllSponsors = async () => {
    try {
      const res = await projectApi?.getUsersByProj();
      setAllSponsors(res?.data);
      setAllStakeholders(res?.data);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setAllSponsors([]);
        setAllStakeholders([]);
      }
    }
  };

  const getAllEscalationManagers = async () => {
    try {
      const res = await casesApi?.getAutoCompleteUser(false);
      setAllEscalationManagers(res?.data);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setAllEscalationManagers([]);
      }
    }
  };
  const handleOnSubmit = async (values: any) => {
    const payload = {
      name: values?.name || '',
      type: values?.type || '',
      email: values?.email || '',
      contactNo: [values?.contactNo] || '',
      website: values?.website || '',
      street: values?.street || '',
      postal: values?.postalCode || '',
      city: values?.city || '',
      province: values?.province || '',
      country: values?.country || '',
      timeZone: values?.timeZones || '',
      description: values?.description || '',
    };
    try {
      const response = await organizationApi.updateById(`${organizationID}`, payload);

      if (response?.status === 200) {
        setOrganizationData(response?.data?.data);
        toast.success('Organization info updated');
        getById();
      }
    } catch (error) {
      if (error?.response?.status === 409) {
        toast.error(`${error?.response?.data?.message}`);
      } else if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const getOrgSummaryData = async () => {
    try {
      const response = await organizationApi.getOrgSummary(`${organizationID}`);

      if (response?.status === 200) {
        setOrganizationSummary(response?.data);
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(`${error?.response?.data?.message}`);
      }
    }
  };

  const schema = Yup.object({
    name: Yup.string()
      .required('Name required'),
    type: Yup.string()
      .required('Type required'),
    email: Yup.string().email('Invalid email').required('Email required'),
    contactNo: Yup.string().trim()
      .required('Phone number required')
      .trim()
      .matches(/^.{10,20}$/, 'Phone number must be between 10 and 20 characters in length'),
    website: Yup.string().url().required('Website required'),
    street: Yup.string()
      .trim()
      .required('Street required')
      .test(
        'Field must not contain only spaces',
        (val) => val && val.length > 0
      ),
    postalCode: Yup.number().integer().required('Postal code required'),
    city: Yup.string()
      .trim()
      .required('City required')
      .test(
        'Field must not contain only spaces',
        (val) => val && val.length > 0
      ),
    province: Yup.string()
      .trim()
      .required('Province required')
      .test(
        'Field must not contain only spaces',
        (val) => val && val.length > 0
      ),
    country: Yup.string()
      .trim()
      .required('Country required')
      .test(
        'Field must not contain only spaces',
        (val) => val && val.length > 0
      ),
    timeZones: Yup.string().required('TimeZone required'),
    description: Yup.string().trim().required('Description required').max(2000, 'Maximum 2000 characters allowed'),
  });

  // const isValidURL = (url) => {
  //   /* eslint-disable no-useless-escape */
  //   const regex = (/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
  //   /* eslint-enable no-useless-escape */
  //   return regex.test(url);
  // };

  const isValidURL = (url: string): boolean => {
    /* eslint-disable no-useless-escape */
    const urlRegex = /^((https):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g;
    /* eslint-disable no-useless-escape */
    const result = url.match(urlRegex);
    return result !== null;
  };

  const filterEmptyLinks = (links: Input[]) => (links?.filter(link => link.value.trim() !== '' || link.label.trim() !== ''))

  const handleProjectDetailsEdit = async () => {
    const payload = {
      name: projectDetails?.projectName,
      escalationManager: projectDetails?.projectEscalationManager?.id,
      description: projectDetails?.projectDescription,
      key: projectDetails?.projectKey.split(' ').join('')?.toUpperCase(),
      projectSponsor: projectDetails?.projectSponsors,
      approvalManagers: projectDetails?.approvalManagers,
      stakeHolder: projectDetails?.projectStakeHolders,
      firstResponseTime: parseInt(projectDetails?.projectSLAFirstResponseTime, 10),
      resolvedTime: parseInt(projectDetails?.projectSLAResolveTime, 10),
      domain: projectDetails?.projectDomain,
      website: projectDetails?.projectWebsite,
      docUrls: {
        googleDocLinks: googleDocLinks.map(link => ({ url: link.value, label: link.label })),
        drawIOLinks: drawioLinks.map(link => ({ url: link.value, label: link.label })),
        figmaLinks: figmaLinks.map(link => ({ URL: link.value, label: link.label })),
        lowLevelDesign: lowLevelDesignLinks.map(link => ({ url: link.value, label: link.label })),
        highLevelDesign: highLevelDesignLinks.map(link => ({ url: link.value, label: link.label })),
      }
    };
    try {
      if (projectDetails?.projectWebsite === "") {
        setWebSiteError('Invalid URL');
        return;
      }
      if (projectDetails?.projectSLAResolveTime === "") {
        setProjectResolveTime('Value required');
        return;
      }
      setProjectResolveTime('')
      setWebSiteError('');
      const response = await projectApi.updateProjectById(`${projectID}`, payload);
      if (response?.status === 200) {
        toast.success('Project info updated');
      }
      saveOrgnaizationInfo(2);
    } catch (error) {
      if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message);
      } if (error?.response?.status !== 401) {
        toast.error(`${error?.response?.data?.message}`);
      }
    }
  };

  const projDetails = () => {

    setProjectDetails({
      projectName: getProjDetails?.name,
      projectDescription: getProjDetails?.description,
      projectTotalCases: getProjDetails?.totalCases,
      projectEscalationManager: { name: getProjDetails?.escalationManagerName, id: getProjDetails?.escalationManager },
      projectSponsors: getProjDetails?.projectSponsor,
      projectStakeHolders: getProjDetails?.stakeHolder,
      approvalManagers: getProjDetails?.approvalManagers,
      projectSLAFirstResponseTime: getProjDetails?.projectSla?.firstResponseTime,
      projectSLAResolveTime: getProjDetails?.projectSla?.resolvedTime,
      projectKey: getProjDetails?.key,
      projectDomain: getProjDetails?.domain,
      projectWebsite: getProjDetails?.website,
      errors: {
        projectName: '',
        projectDescription: '',
        projectEscalationManager: '',
        projectStakeHolders: '',
        projectSponsors: '',
        projectKey: '',
        projectDomain: '',
        projectWebsite: '',
        hasError: false
      }
    });

    setGoogleDocLinks(getProjDetails?.docUrls?.googleDocLinks?.length > 0 ? getProjDetails?.docUrls?.googleDocLinks?.map((link, index) => ({ id: index, value: link.url, label: link.label })) : [{ id: Date.now(), value: '', label: '' }]);
    setDrawioLinks(getProjDetails?.docUrls?.drawIOLinks?.length > 0 ? getProjDetails?.docUrls?.drawIOLinks?.map((link, index) => ({ id: index, value: link.url, label: link.label })) : [{ id: Date.now(), value: '', label: '' }]);
    setFigmaLinks(getProjDetails?.docUrls?.figmaLinks?.length > 0 ? getProjDetails?.docUrls?.figmaLinks?.map((link, index) => ({ id: index, value: link.url, label: link.label })) : [{ id: Date.now(), value: '', label: '' }]);
    setLowLevelDesignLinks(getProjDetails?.docUrls?.lowLevelDesign?.length > 0 ? getProjDetails?.docUrls?.lowLevelDesign?.map((link, index) => ({ id: index, value: link.url, label: link.label })) : [{ id: Date.now(), value: '', label: '' }]);
    setHighLevelDesignLinks(getProjDetails?.docUrls?.highLevelDesign?.length > 0 ? getProjDetails?.docUrls?.highLevelDesign?.map((link, index) => ({ id: index, value: link.url, label: link.label })) : [{ id: Date.now(), value: '', label: '' }]);
  };

  const areAnyStatesEmpty = () => {
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in projectDetails) {
      if (key === 'projectSLAFirstResponseTime' && (projectDetails[key] < 1 || projectDetails[key] > 8)) {
        return true;
      }
      if (key === 'projectSLAResolveTime' && (projectDetails[key] < 1 || projectDetails[key] > 72)) {
        return true;
      }
      if (projectDetails[key]?.length === 0 || Object.keys(projectDetails?.projectEscalationManager)?.length === 0) {
        return true;
      }
    }
    return false;
  };
  const hasEmptyValues = (values) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const websitePattern = /^(http|https):\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;
    const contactNoPattern = /^\d{5,20}$/;
    // eslint-disable-next-line no-restricted-syntax
    for (const val of Object.values(values)) {
      if ((Array.isArray(val) && val.length === 0) || values?.description?.length > 2000 || !emailPattern.test(values?.email) || !websitePattern.test(values?.website) || !contactNoPattern.test(values?.contactNo)) {
        return true;
      }
      if (typeof val === 'string' && val.trim() === '') {
        return true; // Empty string found
      }
    }
    return false; // No empty values found
  };
  const getAllOrganizationTypes = async () => {
    try {
      const response = await organizationApi.getOrganizationType();
      if (response?.status === 200) {
        setOrganizationType(response?.data?.data);
      }
      if (response?.status === 204) {
        toast.error('Something went wrong');
        setOrganizationType([]);
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        setOrganizationType([]);
        toast.error(`${error?.response?.data?.message}`);
      }
    }
  };

  useEffect(() => {
    if (!user?.permissions?.includes('organization-update')
      && !user?.permissions?.includes('organization-get-by-id')
      && !user?.permissions?.includes('organization-project-tab-read')
      && !user?.permissions?.includes('organization-project-create')
      && !user?.permissions?.includes('organization-project-get-all')
      && !user?.permissions?.includes('organization-project-update')
      && !user?.permissions?.includes('organization-project-get-by-id')
      && !user?.permissions?.includes('organization-project-delete')
      && !user?.permissions?.includes('organization-project-bulk-deactivate')
      && !user?.permissions?.includes('organization-project-toggle-status')
      && !user?.permissions?.includes('organization-project-filter-tabs-read')) {
      window.location.href = '/admin/not-found';
    } else {
      getById();
      getAllEscalationManagers();
      getAllSponsors();
      getOrgSummaryData();
      getAllOrganizationTypes();
    }
  }, []);

  useEffect(() => {
    projDetails();
  }, [getProjDetails]);

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={0}
      >
        <Grid
          item
          xs={2}
          className="borderRight"
          sx={{
            position: 'relative',
            background: 'white',
          }}
        >
          {user?.permissions?.includes('organization-get-by-id') && (
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: 'background.default',
                borderRadius: 1,
                display: 'flex',
                overflow: 'hidden',
                background: 'white',
                p: 2,
                position: 'relative'
              }}
            >
              <Avatar
                src="dasdasd"
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48
                }}
              />

              <Box sx={{ mx: 2 }}>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {organizationData?.name}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="h6"
                >
                  {organizationData?.website}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="h6"
                >
                  {organizationData?.contactNo[0]}
                </Typography>
              </Box>
            </Box>
          )}
          <Formik
            enableReinitialize
            initialValues={{
              name: organizationData?.name || '',
              type: organizationData?.organizationType?.id || '',
              email: organizationData?.email || '',
              contactNo: organizationData?.contactNo[0] || '',
              website: organizationData?.website || '',
              street: organizationData?.address?.street || '',
              postalCode: organizationData?.address?.postal || '',
              city: organizationData?.address?.city || '',
              province: organizationData?.address?.province || '',
              country: organizationData?.address?.country || '',
              timeZones: organizationData?.timeZone || '',
              description: organizationData?.description || '',
            }}
            onSubmit={async (values) => {
              handleOnSubmit(values);
            }}
            validationSchema={schema}
          >
            {({ values, errors, touched, handleChange, handleSubmit, handleBlur, setFieldValue }) => (
              user?.permissions?.includes('organization-get-by-id') && (
                <form>
                  <Accordion
                    expanded={expanded === 'aboutDetails'}
                    onChange={handleChangeA('aboutDetails')}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="headingA">About</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <>
                        {
                          // eslint-disable-next-line no-nested-ternary
                          user?.permissions?.includes('organization-update') && (
                            editInfo[0] ? (
                              <CustomComponent
                                url="/static/icons/editIcon.png"
                                altText="edit-icon"
                                handleClick={() => editOrgnaizationInfo(0)}
                              />
                            ) : (
                              <CustomComponent
                                url="/static/icons/saveIcon.png"
                                altText="save-icon"
                                handleClick={() => {
                                  handleSubmit();
                                  const emptyFields = hasEmptyValues(values);
                                  if (!emptyFields) {
                                    saveOrgnaizationInfo(0);
                                  }
                                }}
                              />
                            )
                          )
                        }
                        <div className="orgInfo">
                          <Typography variant="h5">
                            {' '}
                            Company Domain Name
                          </Typography>
                          <TextField
                            type="text"
                            fullWidth
                            className={`${!editInfo[0] ? 'orgEditEnable' : 'orgEdit'
                              } `}
                            value={values.name}
                            disabled={editInfo[0]}
                            name="name"
                            onChange={handleChange}
                            error={Boolean(
                              errors?.name
                              && touched?.name
                            )}
                            helperText={touched?.name && errors?.name}
                          />
                        </div>
                        <div className="orgInfo">
                          <Typography variant="h5">Organization Type</Typography>
                          <Select
                            fullWidth
                            value={values?.type}
                            onChange={handleChange}
                            className={`${!editInfo[0] ? 'orgEditEnable' : 'orgEdit'
                              } `}
                            name="type"
                          >
                            {organizationType?.map((arr) => <MenuItem value={arr?.id}>{arr?.name}</MenuItem>)}
                          </Select>
                        </div>
                        <div className="orgInfo">
                          <Typography variant="h5">Email</Typography>
                          <TextField
                            type="text"
                            fullWidth
                            className={` ${!editInfo[0] ? 'orgEditEnable' : 'orgEdit'
                              } `}
                            value={values.email}
                            disabled={editInfo[0]}
                            name="email"
                            onChange={handleChange}
                            error={Boolean(
                              errors?.email
                              && touched?.email
                            )}
                            helperText={touched?.email && errors?.email}
                          />
                        </div>
                        <div className="orgInfo">
                          <Typography variant="h5">Phone No</Typography>
                          <TextField
                            // type="number"
                            type="text"
                            className={` ${!editInfo[0] ? 'orgEditEnable' : 'orgEdit'
                              } `}
                            value={values?.contactNo}
                            name="contactNo"
                            disabled={editInfo[0]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(errors?.contactNo)}
                            helperText={errors?.contactNo}
                          />
                        </div>
                        <div className="orgInfo">
                          <Typography variant="h5">Website</Typography>
                          {!editInfo[0] ? <TextField
                            type="text"
                            className={` ${!editInfo[0] ? 'orgEditEnable' : 'orgEdit'
                              } `}
                            value={values.website}
                            disabled={editInfo[0]}
                            name="website"
                            onChange={handleChange}
                            error={Boolean(
                              errors?.website
                              && touched?.website
                            )}
                            helperText={touched?.website && errors?.website}
                          /> : <Link className='orgEdit' style={{ fontWeight: 600, fontSize: '14px' }} underline='none' target='_blank' href={values.website} >
                            {values.website}
                          </Link>}
                        </div>
                        <div className="orgInfo">
                          <Typography variant="h5">Street</Typography>
                          <TextField
                            type="text"
                            className={` ${!editInfo[0] ? 'orgEditEnable' : 'orgEdit'
                              } `}
                            value={values.street}
                            disabled={editInfo[0]}
                            name="street"
                            onChange={handleChange}
                            error={Boolean(
                              errors?.street
                              && touched?.street
                            )}
                            helperText={touched?.street && errors?.street}
                          />
                        </div>
                        <div className="orgInfo">
                          <Typography variant="h5">Postal Code</Typography>
                          <TextField
                            type="number"
                            className={` ${!editInfo[0] ? 'orgEditEnable' : 'orgEdit'
                              } `}
                            value={values.postalCode}
                            disabled={editInfo[0]}
                            name="postalCode"
                            onChange={handleChange}
                            error={Boolean(
                              errors?.postalCode
                              && touched?.postalCode
                            )}
                            helperText={touched?.postalCode && errors?.postalCode}
                          />
                        </div>
                        <div className="orgInfo">
                          <Typography variant="h5">Country</Typography>
                          {/* <FormControl
                          fullWidth
                        >
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            fullWidth
                            label="Country"
                            value={values.country}
                            onChange={handleChange}
                            name="country"
                            className={` ${
                              !editInfo[0] ? 'orgEditEnable' : 'orgEdit'
                            } `}
                            error={Boolean(
                              errors?.country
                              && touched?.country
                            )}
                          >

                            {Object.keys(countriesAndCities)?.map((country) => <MenuItem value={country}>{country}</MenuItem>)}

                          </Select>
                        </FormControl> */}
                          <Autocomplete
                            id="country-183320"
                            options={Object.keys(countriesAndCities) || []}
                            className={` ${!editInfo[0] ? 'orgEditEnable' : 'orgEdit'
                              } `}
                            value={values.country}
                            disabled={editInfo[0]}
                            onChange={(event: any, newValue: any) => {
                              if (newValue === null) {
                                setFieldValue('country', '');
                                setFieldValue('city', '');
                              } else {
                                setFieldValue('country', newValue);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={Boolean(
                                  errors.country
                                  && touched.country
                                )}
                                placeholder="Country"
                              />
                            )}
                          />
                        </div>
                        <div className="orgInfo">
                          <Typography variant="h5">City</Typography>
                          {/* <FormControl
                          fullWidth
                        >
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            fullWidth
                            label="City"
                            value={values.city}
                            onChange={handleChange}
                            name="city"
                            className={` ${
                              !editInfo[0] ? 'orgEditEnable' : 'orgEdit'
                            } `}
                            error={Boolean(
                              errors?.city
                              && touched?.city
                            )}
                          >
                            {values.country ? countriesAndCities[values.country]?.map((city) => <MenuItem value={city}>{city}</MenuItem>) : <MenuItem value="">Select a country</MenuItem>}

                          </Select>
                        </FormControl> */}
                          <Autocomplete
                            id="city-183320"
                            options={countriesAndCities[values.country] || []}
                            className={` ${!editInfo[0] ? 'orgEditEnable' : 'orgEdit'
                              } `}
                            value={values.city}
                            onChange={(event: any, newValue: any) => {
                              if (newValue === null) {
                                setFieldValue('city', '');
                              } else {
                                setFieldValue('city', newValue);
                              }
                            }}
                            disabled={editInfo[0]}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={Boolean(
                                  errors?.city
                                  && touched?.city
                                )}
                                placeholder="City"
                              />
                            )}
                          />
                        </div>
                        <div className="orgInfo">
                          <Typography variant="h5">Province</Typography>
                          <TextField
                            type="text"
                            className={` ${!editInfo[0] ? 'orgEditEnable' : 'orgEdit'
                              } `}
                            value={values.province}
                            disabled={editInfo[0]}
                            name="province"
                            onChange={handleChange}
                            error={Boolean(
                              errors?.province
                              && touched?.province
                            )}
                            helperText={touched?.province && errors?.province}
                          />
                        </div>
                        <div className="orgInfo">
                          <Typography variant="h5">Time Zone</Typography>
                          <Select
                            fullWidth
                            className={` ${!editInfo[0] ? 'orgEditEnable' : 'orgEdit'
                              } `}
                            variant="outlined"
                            // disabled={editInfo[0]}
                            value={values?.timeZones || 'N/A'}
                            onChange={handleChange}
                            name="timeZones"
                            error={Boolean(
                              errors?.timeZones
                              && touched?.timeZones
                            )}
                          >
                            {timeZones?.map((arr) => <MenuItem value={arr}>{arr}</MenuItem>)}
                          </Select>
                        </div>
                        <div className="orgInfo">
                          <Typography variant="h5">Description</Typography>
                          <TextField
                            type="text"
                            multiline
                            className={` ${!editInfo[0] ? 'orgEditEnable textArea' : 'orgEdit textArea'
                              } `}
                            value={values.description}
                            disabled={editInfo[0]}
                            name="description"
                            onChange={handleChange}
                            inputProps={{
                              maxLength: 2001, // Set the maximum character limit
                            }}
                            error={Boolean(
                              errors?.description
                              && touched?.description
                            )}
                            helperText={touched?.description && errors?.description ? errors.description : ''}
                          />
                        </div>
                      </>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === 'orgSummary'}
                    onChange={handleChangeA('orgSummary')}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="headingA">Summary</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <>
                        <div className="orgInfo">
                          <Typography variant="h5">
                            {' '}
                            Total Projects
                          </Typography>
                          <TextField
                            type="text"
                            fullWidth
                            className={`${!editInfo[1] ? 'orgEditEnable' : 'orgEdit'
                              } `}
                            value={organizationSummary?.totalProjects || 'N/A'}
                            disabled={editInfo[1]}
                          />
                        </div>
                        <div className="orgInfo">
                          <Typography variant="h5">Total Cases</Typography>
                          <TextField
                            type="text"
                            fullWidth
                            className={` ${!editInfo[1] ? 'orgEditEnable' : 'orgEdit'
                              } `}
                            value={organizationSummary?.totalCases || 'N/A'}
                            disabled={editInfo[1]}
                          />
                        </div>
                        <div className="orgInfo">
                          <Typography variant="h5">Total Users</Typography>
                          <TextField
                            type="text"
                            fullWidth
                            className={` ${!editInfo[1] ? 'orgEditEnable' : 'orgEdit'
                              } `}
                            value={organizationSummary?.totalUser || 'N/A'}
                            disabled={editInfo[1]}
                          />
                        </div>
                        <div className="orgInfo">
                          <Typography variant="h5">Escalations</Typography>
                          <TextField
                            type="number"
                            className={` ${!editInfo[1] ? 'orgEditEnable' : 'orgEdit'
                              } `}
                            value={organizationSummary?.totalEscalations || 'N/A'}
                            disabled={editInfo[1]}
                          />
                        </div>
                      </>
                    </AccordionDetails>
                  </Accordion>
                </form>
              )
            )}
          </Formik>
          {user?.permissions?.includes('organization-project-get-by-id') && projectDetails && projectID && (
            <Accordion
              expanded={expanded === 'projectDetails'}
              onChange={handleChangeA('projectDetails')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="headingA">Project details</Typography>

              </AccordionSummary>
              <AccordionDetails>
                <>
                  {user?.permissions?.includes('organization-project-update') &&
                    (editInfo[2] ? (
                      <CustomComponent
                        url="/static/icons/editIcon.png"
                        altText="edit-icon"
                        handleClick={() => editOrgnaizationInfo(2)}
                      />
                    ) : (
                      <CustomComponent
                        url="/static/icons/saveIcon.png"
                        altText="save-icon"
                        // checkStates={areAnyStatesEmpty()}
                        handleClick={() => {
                          handleProjectDetailsEdit();
                          // saveOrgnaizationInfo(2);
                        }}
                      />
                    ))}


                  <div className="orgInfo">
                    <Typography variant="h5">
                      {' '}
                      Project Name
                    </Typography>
                    <TextField
                      type="text"
                      fullWidth
                      error={projectDetails?.projectName?.length === 0}
                      className={`${!editInfo[2] ? 'orgEditEnable' : 'orgEdit'
                        } `}
                      value={projectDetails?.projectName}
                      disabled={editInfo[2]}
                      name="projectName"
                      onChange={handleChangeName}
                    />
                  </div>
                  <div className="orgInfo">
                    <Typography variant="h5">Project Description</Typography>
                    <TextField
                      type="text"
                      fullWidth
                      className={` ${!editInfo[2] ? 'orgEditEnable' : 'orgEdit'
                        } `}
                      error={projectDetails?.projectDescription?.length === 0}
                      value={projectDetails?.projectDescription?.replace(/<[^>]*>|&nbsp;/g, '')}
                      disabled={editInfo[2]}
                      name="projectDescription"
                      onChange={handleChangeDescription}
                    />
                  </div>
                  <div className="orgInfo">
                    <Typography variant="h5">Project Key</Typography>
                    <TextField
                      type="text"
                      fullWidth
                      className={` ${!editInfo[2] ? 'orgEditEnable' : 'orgEdit'
                        } `}
                      error={projectDetails?.projectKey?.length === 0}
                      value={projectDetails?.projectKey}
                      disabled={editInfo[2]}
                      name="projectKey"
                      onChange={handleChangeKey}
                    />
                  </div>
                  <div className="orgInfo">
                    <Typography variant="h5">Domain</Typography>
                    <TextField
                      type="text"
                      fullWidth
                      className={` ${!editInfo[2] ? 'orgEditEnable' : 'orgEdit'
                        } `}
                      error={projectDetails?.projectDomain?.length === 0}
                      value={projectDetails?.projectDomain}
                      disabled={editInfo[2]}
                      name="projectDomain"
                      onChange={handleChangeDomain}
                    />
                  </div>
                  <div className="orgInfo">
                    <Typography variant="h5">Website</Typography>
                    {!editInfo[2] ? <TextField
                      type="text"
                      fullWidth
                      className={` ${!editInfo[2] ? 'orgEditEnable' : 'orgEdit'
                        } `}
                      value={projectDetails?.projectWebsite}
                      error={projectDetails?.projectWebsite?.length === 0}
                      disabled={editInfo[2]}
                      name="projectDomain"
                      onChange={handleChangeWebsite}
                    /> : <Link className='orgEdit' style={{ fontWeight: 600, fontSize: '14px' }} underline='none' target='_blank' href={projectDetails?.projectWebsite} >
                      {projectDetails?.projectWebsite}
                    </Link>}
                    <div className=''>
                      {webSiteError && <p className="errorInvalid">Website required</p>}
                    </div>
                  </div>
                  <div className="orgInfo">
                    <Typography variant="h5">Escalation Manager</Typography>
                    <Autocomplete
                      id="escalation-manager-3238383"
                      options={allEscalationManagers}
                      getOptionLabel={(option) => option?.name}
                      value={projectDetails?.projectEscalationManager || {}}
                      className={` ${!editInfo[2] ? 'orgEditEnable' : 'orgEdit'
                        } `}
                      onChange={(event: any, newValue: any) => {
                        if (event && newValue) {
                          setProjectDetails((pre) => ({ ...pre, projectEscalationManager: newValue }));
                        }
                      }}
                      // style={{ minWidth: 300 }}
                      disabled={editInfo[2]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Escalation Manager*"
                          error={Object.keys(projectDetails?.projectEscalationManager).length === 0}
                        />
                      )}
                    />
                  </div>
                  <div className="orgInfo">
                    <Typography variant="h5">Stakeholders</Typography>
                    <Autocomplete
                      id="stakeholder-3829382"
                      options={allStakeholders}
                      getOptionLabel={(option) => option?.name}
                      multiple
                      limitTags={1}
                      value={projectDetails?.projectStakeHolders || []}
                      className={` ${!editInfo[2] ? 'orgEditEnable' : 'orgEdit'
                        } `}
                      onChange={(event: any, newValue: any) => {
                        if (event) {
                          setProjectDetails((pre) => ({ ...pre, projectStakeHolders: newValue }));
                        }
                      }}
                      // style={{ minWidth: 300 }}
                      disabled={editInfo[2]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={projectDetails?.projectStakeHolders?.length === 0}
                        />
                      )}
                    />
                  </div>
                  <div className="orgInfo">
                    <Typography variant="h5">Project Sponsors</Typography>

                    <Autocomplete
                      id="projectSponsors-3829382"
                      options={allSponsors || []}
                      getOptionLabel={(option) => option?.name}
                      multiple
                      limitTags={1}
                      value={projectDetails?.projectSponsors || []}
                      className={` ${!editInfo[2] ? 'orgEditEnable' : 'orgEdit'
                        } `}
                      onChange={(event: any, newValue: any) => {
                        if (event) {
                          setProjectDetails((pre) => ({ ...pre, projectSponsors: newValue }));
                        }
                      }}
                      // style={{ minWidth: 300 }}
                      disabled={editInfo[2]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={projectDetails?.projectSponsors?.length === 0}
                        />
                      )}
                    />
                  </div>
                  <div className="orgInfo">
                    <Typography variant="h5">Approval Manager(s)</Typography>
                    <Autocomplete
                      id="Approval-Managers-3829382"
                      options={allSponsors || []}
                      getOptionLabel={(option) => option?.name}
                      multiple
                      limitTags={10}
                      value={projectDetails?.approvalManagers || []}
                      className={` ${!editInfo[2] ? 'orgEditEnable' : 'orgEdit'
                        } `}
                      onChange={(event: any, newValue: any) => {
                        if (event) {
                          setProjectDetails((pre) => ({ ...pre, approvalManagers: newValue }));
                        }
                      }}
                      // style={{ minWidth: 300 }}
                      disabled={editInfo[2]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={projectDetails?.approvalManagers?.length === 0}
                        />
                      )}
                    />
                  </div>
                  <div className="orgInfo">
                    <Typography variant="h5">Platform</Typography>
                    <TextField
                      type="text"
                      className={` ${!editInfo[2] ? 'orgEditEnable' : 'orgEdit'
                        } `}
                      value={getProjDetails?.thirdPartySync?.platformName || 'N/A'}
                      disabled
                      name="platformName"
                    />
                  </div>
                  <div className="orgInfo">
                    <Typography variant="h5">SLA First Response Time</Typography>
                    <TextField
                      id="outlined-select-status-native"
                      type="number"
                      className={` ${!editInfo[2] ? 'orgEditEnable' : 'orgEdit'
                        } `}
                      disabled={editInfo[2]}
                      value={projectDetails?.projectSLAFirstResponseTime}
                      fullWidth
                      onChange={handleChangeSLAFirstResponseTime}
                      error={projectDetails?.projectSLAFirstResponseTime < 1 || projectDetails?.projectSLAFirstResponseTime > 8}
                    />
                  </div>
                  <div className="orgInfo">
                    <Typography variant="h5">SLA Resolve Time</Typography>

                    <TextField
                      id="outlined-select-status-native"
                      type="number"
                      className={` ${!editInfo[2] ? 'orgEditEnable' : 'orgEdit'
                        } `}
                      value={projectDetails?.projectSLAResolveTime}
                      disabled={editInfo[2]}
                      fullWidth
                      onChange={handleChangeSLAResolveTime}
                      error={projectDetails?.projectSLAResolveTime < 1 || projectDetails?.projectSLAResolveTime > 72}
                    />

                    <div className=''>
                      {projectResolveTime && <p className="errorInvalid">Value is required</p>}
                    </div>
                  </div>
                  <div className="orgInfo">
                    <Typography variant="h5">Total Cases</Typography>
                    <TextField
                      type="text"
                      className={` ${!editInfo[2] ? 'orgEditEnable' : 'orgEdit'
                        } `}
                      value={getProjDetails?.totalCases || '0'}
                      disabled
                      name="totalCases"
                    />
                  </div>
                  <div className="orgInfo">
                    <Typography variant="h5">Google Doc Links</Typography>
                    <MultiFileLinkInput
                      placeholder="Google Doc Link"
                      labelPlaceholder='Google Doc Label'
                      inputs={googleDocLinks}
                      onAddInput={() => setGoogleDocLinks([...googleDocLinks, { id: Date.now(), value: '', label: '' }])}
                      onRemoveInput={(id) => setGoogleDocLinks(googleDocLinks.filter(input => input.id !== id))}
                      onInputChange={(id, event) => setGoogleDocLinks(googleDocLinks.map(input => input.id === id ? { ...input, value: event.target.value } : input))}
                      onLabelChange={(id, event) => setGoogleDocLinks(googleDocLinks.map(input => input.id === id ? { ...input, label: event.target.value } : input))}
                      disabled={editInfo[2]}
                      mainClassName='multiFileInputContainer'
                      inputClassName={` ${!editInfo[2] ? 'orgEditEnable' : 'orgEdit'
                        } `}
                      labelClassName={` ${!editInfo[2] ? 'orgEditEnable' : 'orgEdit'
                        } `}
                    />
                  </div>
                  <div className="orgInfo">
                    <Typography variant="h5">DrawIO Links</Typography>
                    <MultiFileLinkInput
                      placeholder="DrawIO Link"
                      labelPlaceholder='DrawIO Label'
                      inputs={drawioLinks}
                      onAddInput={() => setDrawioLinks([...drawioLinks, { id: Date.now(), value: '', label: '' }])}
                      onRemoveInput={(id) => setDrawioLinks(drawioLinks.filter(input => input.id !== id))}
                      onInputChange={(id, event) => setDrawioLinks(drawioLinks.map(input => input.id === id ? { ...input, value: event.target.value } : input))}
                      onLabelChange={(id, event) => setDrawioLinks(drawioLinks.map(input => input.id === id ? { ...input, label: event.target.value } : input))}
                      disabled={editInfo[2]}
                      mainClassName='multiFileInputContainer'
                      inputClassName={` ${!editInfo[2] ? 'orgEditEnable' : 'orgEdit'
                        } `}
                      labelClassName={` ${!editInfo[2] ? 'orgEditEnable' : 'orgEdit'
                        } `}
                    />
                  </div>
                  <div className="orgInfo">
                    <Typography variant="h5">Figma Links</Typography>
                    <MultiFileLinkInput
                      placeholder="Figma Link"
                      labelPlaceholder='Figma Label'
                      inputs={figmaLinks}
                      onAddInput={() => setFigmaLinks([...figmaLinks, { id: Date.now(), value: '', label: '' }])}
                      onRemoveInput={(id) => setFigmaLinks(figmaLinks.filter(input => input.id !== id))}
                      onInputChange={(id, event) => setFigmaLinks(figmaLinks.map(input => input.id === id ? { ...input, value: event.target.value } : input))}
                      onLabelChange={(id, event) => setFigmaLinks(figmaLinks.map(input => input.id === id ? { ...input, label: event.target.value } : input))}
                      disabled={editInfo[2]}
                      mainClassName='multiFileInputContainer'
                      inputClassName={` ${!editInfo[2] ? 'orgEditEnable' : 'orgEdit'
                        } `}
                      labelClassName={` ${!editInfo[2] ? 'orgEditEnable' : 'orgEdit'
                        } `}
                    />
                  </div>
                  <div className="orgInfo">
                    <Typography variant="h5">Low Level Design Links</Typography>
                    <MultiFileLinkInput
                      placeholder="Low Level Design (Drive Link)"
                      labelPlaceholder='Low Level Design Label'
                      inputs={lowLevelDesignLinks}
                      onAddInput={() => setLowLevelDesignLinks([...lowLevelDesignLinks, { id: Date.now(), value: '', label: '' }])}
                      onRemoveInput={(id) => setLowLevelDesignLinks(lowLevelDesignLinks.filter(input => input.id !== id))}
                      onInputChange={(id, event) => setLowLevelDesignLinks(lowLevelDesignLinks.map(input => input.id === id ? { ...input, value: event.target.value } : input))}
                      onLabelChange={(id, event) => setLowLevelDesignLinks(lowLevelDesignLinks.map(input => input.id === id ? { ...input, label: event.target.value } : input))}
                      disabled={editInfo[2]}
                      mainClassName='multiFileInputContainer'
                      inputClassName={` ${!editInfo[2] ? 'orgEditEnable' : 'orgEdit'
                        } `}
                      labelClassName={` ${!editInfo[2] ? 'orgEditEnable' : 'orgEdit'
                        } `}
                    />
                  </div>
                  <div className="orgInfo">
                    <Typography variant="h5">High Level Design Links</Typography>
                    <MultiFileLinkInput
                      placeholder="High Level Design (Drive Link)"
                      labelPlaceholder='High Level Design Label'
                      inputs={highLevelDesignLinks}
                      onAddInput={() => setHighLevelDesignLinks([...highLevelDesignLinks, { id: Date.now(), value: '', label: '' }])}
                      onRemoveInput={(id) => setHighLevelDesignLinks(highLevelDesignLinks.filter(input => input.id !== id))}
                      onInputChange={(id, event) => setHighLevelDesignLinks(highLevelDesignLinks.map(input => input.id === id ? { ...input, value: event.target.value } : input))}
                      onLabelChange={(id, event) => setHighLevelDesignLinks(highLevelDesignLinks.map(input => input.id === id ? { ...input, label: event.target.value } : input))}
                      disabled={editInfo[2]}
                      mainClassName='multiFileInputContainer'
                      inputClassName={` ${!editInfo[2] ? 'orgEditEnable' : 'orgEdit'
                        } `}
                      labelClassName={` ${!editInfo[2] ? 'orgEditEnable' : 'orgEdit'
                        } `}
                    />
                  </div>
                </>
              </AccordionDetails>
            </Accordion>
          )}
        </Grid>
        <Grid
          item
          xs={10}
          className="borderRight"
        >
          <div className="tabsArea">
            <Tabs
              value={value}
              onChange={handleChangeTab}
              aria-label="Organization Tabs"
            >
              {user?.permissions?.includes('organization-project-tab-read') && (
                <Tab
                  label="Projects"
                  value="project"
                />
              )}

              {user?.permissions?.includes('organization-client-contact-tab-read') && (
                <Tab
                  label="Contacts"
                  value="contacts"
                />
              )}
              <Tab
                label="Consulting Units"
                value="consulting"
              />
              <Tab
                label="Client Settings"
                value="client-settings"
              />
              <Tab
                label="White Label"
                value="white-label"
              />
            </Tabs>
            <hr />
            <Outlet context={{
              organizationID,
              hide_title: true,
              is_compact_size: true,
            }}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default OrganizationDetails;
