/* eslint-disable no-lone-blocks */
/* eslint-disable eqeqeq */
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { FC, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { userApi } from 'src/api/userApi';
import { FormTypes } from 'src/types/components/form.type';
import logger from 'src/utils/logger';
import CustomButton from '../widgets/buttons/CustomButton';
// import { useDispatch } from 'react-redux';
// import { userInfos } from 'src/store/slices/userInfoSlice';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles<Theme>((theme: Theme) => createStyles({
  header: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    textTransform: 'capitalize',
  },

  cardRoot: {
    margin: '27px 0 0px 0',
  },
  btnContainer: {
    paddingTop: '27px',
    paddingBottom: '58px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
  },
  loadingCircle: {
    color: theme.palette.primary.main,
    width: '40px !important',
    height: '40px !important',
    marginLeft: 5,
    marginRight: 5,
  },
  loadingWrapper: {
    //   marginTop: 20,
    textAlign: 'center',
  },
  imageUploadAvatar: {
    width: '50px',
    height: '50px',
  },
  imageUploadButton: {
    marginLeft: 10,
  },
}));

const EditUserForm: FC<FormTypes> = ({
  previewer,
  initialValues,
  schema,
  handleOnSubmit,
  returnUrl,
}: FormTypes) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [defaultTimeZone, setdefaultTimeZone] = useState(initialValues?.timeZone);
  const { t } = useTranslation();
  // const dispatch = useDispatch();
  const handleCancel = () => {
    navigate(returnUrl || '/dashboard');
  };

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handlePicture = async (e) => {
    try {
      setIsSubmitting(true);
      const fileType = e.target.files[0].type;
      if (!['image/jpeg', 'image/png'].includes(fileType)) {
        toast.dismiss();
        toast.error('Invalid File Type');
        setIsSubmitting(false);
        return null;
      }
      const image = await userApi.uploadFile(
        e.target.files[0],
        'user'
        // profileImage
      );
        // dispatch(userInfos(image?.fileName));
      setIsSubmitting(false);
      const msg = t('Image uploaded successfully');
      toast.dismiss();
      toast.success(msg, {
        duration: 10000,
      });
      return image;
    } catch (err) {
      setIsSubmitting(false);
      logger(err, 'error');
      const errorMsg = err?.response?.status === 400
        ? t('Image not uploaded file type error')
        : t('Image not uploaded file size error');
      toast.dismiss();
      toast.error(errorMsg, {
        duration: 10000,
      });
      return ''; // <--- cheapi
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100%',
      }}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
          handleOnSubmit(values);
        }}
        validationSchema={schema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => (
          <form
            noValidate
          >
            {previewer?.map((card) => (
              <Card
                variant="outlined"
                className={classes.cardRoot}
              >
                <CardHeader
                  title={` ${card.name} `}
                  className={classes.header}
                />
                <Divider />
                <Grid
                  container
                  spacing={3}
                  sx={{ px: '24px', py: '35px' }}
                >
                  {card?.fields?.map((field: any) => {
                    {
                      if (field.type === 'dropDown') {
                        return (
                          <Grid
                            item
                            xs={field?.columnSize}
                          >
                            {' '}
                            <FormControl fullWidth>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                fullWidth
                                value={defaultTimeZone}
                                onChange={(e) => {
                                  setdefaultTimeZone(e.target.value);
                                  setFieldValue('timeZone', defaultTimeZone);
                                }}
                                name={field.name}
                              >

                                {
                                    field?.array?.map((arr) => <MenuItem value={arr}>{arr}</MenuItem>)
                                  }

                              </Select>
                            </FormControl>
                          </Grid>
                        );
                      }
                      if (field.type === 'dropDownSimple') {
                        return (
                          <Grid
                            item
                            xs={field?.columnSize}
                          >
                            {' '}
                            <FormControl
                              fullWidth
                              error={Boolean(
                                errors[`${field.name}`]
                                    && touched[`${field.name}`]
                              )}
                            >
                              <InputLabel id="demo-simple-select-label">{field?.label}</InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id={field.id}
                                fullWidth
                                label={field?.label}
                                  // value={values?.organizationTypes}
                                value={values[`${field.name}`]}
                                onChange={handleChange}
                                name={field?.name}
                              >
                                {field?.array?.length > 0 ? field?.array?.map((arr) => <MenuItem value={arr?.id}>{arr?.name}</MenuItem>) : <MenuItem value="">No Options</MenuItem>}

                              </Select>
                              <FormHelperText
                                id={field.id}
                                error={Boolean(
                                  errors[`${field.name}`]
                                    && touched[`${field.name}`]
                                )}
                              >
                                {
                                    touched[`${field.name}`]
                                    && errors[`${field.name}`]
                                  }
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        );
                      }
                      if (field.type === 'textarea') {
                        return (
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              fullWidth
                              label={field.label}
                              name={field.name}
                              required={field.required}
                              value={values[`${field.name}`]}
                              variant="outlined"
                              multiline
                              rows={4}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={Boolean(
                                errors[`${field.name}`]
                                  && touched[`${field.name}`]
                              )}
                              helperText={errors[`${field.name}`]}
                            />
                          </Grid>
                        );
                      }
                      if (field.type === 'file') {
                        return (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{ display: 'flex', alignItems: 'center' }}
                          >
                            {isSubmitting ? (
                              <div className={classes.loadingWrapper}>
                                <CircularProgress
                                  className={classes.loadingCircle}
                                />
                              </div>
                            ) : (
                              <Avatar
                                className={classes.imageUploadAvatar}
                                src={
                                    values[field.name] == 'dummy_image.png'
                                      ? 'https://assets.it22.nl/fortes-energy/admin/dummy_image.png'
                                      : `${process.env.REACT_APP_IMAGE_UPLOAD_URL
                                      }/images/user/${values[field.name]}`
                                  }
                              />
                            )}
                            <TextField
                              className={classes.imageUploadButton}
                              name={field.name}
                              type={field.type}
                              onChange={async (e) => {
                                setIsSubmitting(true);
                                const image = await handlePicture(e);
                                if (image) {
                                  setFieldValue('image', image?.fileName);
                                }
                                setIsSubmitting(false);
                              }}
                              inputProps={{
                                accept: 'image/png, image/jpeg'
                              }}
                            />
                          </Grid>
                        );
                      }
                      if (field.type === 'password') {
                        return (
                          <Grid
                            item
                            xs={12}
                            md={6}
                          >
                            <FormControl
                              variant="outlined"
                              fullWidth
                            >
                              <InputLabel htmlFor={field.id}>Password</InputLabel>
                              <OutlinedInput
                                id={field.id}
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={(
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={toggleShowPassword}
                                      edge="end"
                                    >
                                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                  </InputAdornment>
                                  )}
                                label={field.label}
                                name={field.name}
                                required={field.required}
                                value={values[`${field.name}`]}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(
                                  errors[`${field.name}`]
                                    && touched[`${field.name}`]
                                )}
                              />
                              <FormHelperText
                                id={field.id}
                                error={Boolean(errors[`${field.name}`] && touched[`${field.name}`])}
                              >
                                {
                                    touched[`${field.name}`]
                                    && errors[`${field.name}`]
                                  }

                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        );
                      }
                      return (
                        <Grid
                          item
                          xs={12}
                          md={6}
                        >
                          <TextField
                            fullWidth
                            label={field.label}
                            name={field.name}
                            required={field.required}
                            value={values[`${field.name}`]}
                            type={field?.type}
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(
                              errors[`${field.name}`]
                                && touched[`${field.name}`]
                            )}
                            helperText={
                                touched[`${field.name}`]
                                && errors[`${field.name}`]
                              }
                          />
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </Card>
            ))}
            <div className={classes.btnContainer}>
              <CustomButton
                value="cancel"
                onClick={handleCancel}
              />
              <CustomButton
                value="submit"
                color="secondary"
                onClick={handleSubmit}
                type="submit"
              />
            </div>
          </form>
        )}
      </Formik>

    </Box>
  );
};

export default EditUserForm;
