import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
  Divider,
} from '@mui/material';

interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onSaveWithoutUpdating: () => void;
  onSaveAndUpdate: () => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  onClose,
  onSaveWithoutUpdating,
  onSaveAndUpdate,
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    maxWidth="md"
    fullWidth={false}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    BackdropProps={{
      style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' }, // Set the color and opacity of the backdrop
    }}>
    <DialogTitle id="alert-dialog-title">
      <Typography
        variant="h6"
        align="center"
        sx={{
          fontWeight: '400',
          fontSize: '27px',
        }}>
        Are you sure?
      </Typography>
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        <Typography align="center">
          Please select how you would like to save these changes.
        </Typography>
      </DialogContentText>
    </DialogContent>
    <Divider
      sx={{
        width: '95%',
        margin: '0px 2.5% 0px 2.5%',
      }}
    />
    <DialogActions
      sx={{
        padding: '10px',
      }}>
      <Button onClick={onClose} color="primary">
        Cancel
      </Button>
      <Button
        onClick={onSaveWithoutUpdating}
        variant="outlined"
        color="primary">
        Save without Updating Leave Quota
      </Button>
      <Button
        onClick={onSaveAndUpdate}
        variant="contained"
        color="secondary"
        autoFocus>
        Save and Update Leave Quota
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmationDialog;
