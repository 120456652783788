import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
// import myData from '../../../__fakeApi__/tempdata';
import { useEffect, useState } from 'react';
import BarChart from 'src/components/barChart/Bar';
import { okrApi } from 'src/api/okrApi';
import toast from 'react-hot-toast';

const useStyles = makeStyles<Theme>(
  () =>
    createStyles({
      btn: {
        padding: 0,
      },
      graphBox: {
        padding: 16,
        marginTop: '10px',
        background: 'white',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
      },
    }),
  {
    name: 'SubDepartmentGraph',
  }
);
function SubDepartmentGraph(props: any) {
  const classes = useStyles();
  const [departmentData, setDepartmentData] = useState([]);
  console.log(props, classes);

  // Total Assigned ==  blue ==     4285f4
  // completed ==       greeen ==   34a853
  // depriorites ==     red ==      ea4335
  // inprogress ==      yellow ==   fbbc04
  const keys = [
    { key: 'totalHours', title: 'Total Hours', color: '#4285f4' },
    { key: 'consumedHours', title: 'Consumed Hours', color: '#34a853' },
    { key: 'extraHours', title: 'Extra Hours', color: '#fbbc04' },
  ];
  function parseData(jsonData: any, graphKeys: any) {
    const datasets = [
      {
        label: '',
        data: [],
        backgroundColor: [],
      },
    ];
    const labels = [];

    graphKeys.forEach((item) => {
      labels.push(item.title);
      datasets[0].data.push(jsonData[item.key]);
      datasets[0].backgroundColor.push(item.color);
    });
    return { labels, datasets };
  }

  const getDepartmentData = async () => {
    // setDepartmentData(myData.DEPARTMENTDATA);
    try {
      const response = await okrApi.getDepartmentsHourly();
      setDepartmentData(response.data);
    } catch (error) {
      toast.error(error.message);
    }
  };
  useEffect(() => {
    getDepartmentData();
  }, []);
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
      }}
      className={classes.table}>
      <Grid container spacing={2} className={classes.root}>
        {departmentData &&
          departmentData.map((item) => (
            <Grid item md={6} lg={4}>
              <Box className={classes.graphBox}>
                <h1
                  style={{
                    color: '#000',
                    margin: 0,
                    fontSize: 20,
                    fontWeight: 700,
                    lineHeight: '116.7%',
                  }}>
                  {item.subDepartment}
                </h1>

                <BarChart
                  data={parseData(item, keys) || []}
                  showLegend={false}
                />
              </Box>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}

export default SubDepartmentGraph;
