import { isUndefined } from 'lodash';
import axiosInstance, { postRequest } from 'src/utils/axios';
import logger from 'src/utils/logger';
import parse from 'src/utils/parse';

type FlowProps = {
  data: any;
};

class StatusFlowApi {
  async createFlow(payload: FlowProps): Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };
      const customHeader = {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };
      const data = {
        url: `${process.env.REACT_APP_CASES}/status/flow`,
        payload,
        successFunction,
        errorFunction,
        customHeader,
      };
      postRequest(data);
    });
  }

  async advanceSearch(args: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`${process.env.REACT_APP_CASES}/status/flow?${args}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getById(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`${process.env.REACT_APP_CASES}/status/flow/${id}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getAllStatus(): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`${process.env.REACT_APP_CASES}/status`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async updateBulkStatus(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .patch(`${process.env.REACT_APP_CASES}/cases/status/bulkUpdate/caseIds`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async update(id: string, payload: FlowProps): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(`${process.env.REACT_APP_CASES}/status/flow/${id}`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export const statusFlowApi = new StatusFlowApi();
