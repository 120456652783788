/* eslint-disable no-underscore-dangle */
import { TextField, Box, Button, Autocomplete } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

type WorkLogFormProps = {
  closeDrawer: any;
  formData?: any;
  onSubmit: any;
  editForm?: boolean;
};

const orderStatusList = [
  { name: 'Pending', id: 'PENDING' },
  { name: 'Active', id: 'ACTIVE' },
  { name: 'Closed', id: 'CLOSED' },
  { name: 'Expired', id: 'EXPIRED' },
];
const ConsultingOrderForm = ({
  closeDrawer,
  onSubmit,
  formData,
  editForm,
}: WorkLogFormProps) => {
  const formik = useFormik({
    initialValues: {
      orderId: formData?.data?.orderNumber || '',
      orgId: formData?.orgId || null,
      project: formData?.data?.project || '',
      cuQuantity: formData?.data?.cuQuantity || null,
      id: formData?._id,
      cuOrderStatus: {
        name:
          formData?.data?.cuOrderStatus.charAt(0)?.toUpperCase() +
          formData?.data?.cuOrderStatus.slice(1)?.toLowerCase(),
        id: formData?.data?.cuOrderStatus,
      } || {
        name: 'Pending',
        id: 'PENDING',
      },
    },
    onSubmit,
    validationSchema: Yup.object().shape({
      orderId: Yup.string().required('Id required'),
      // project: Yup.string().required('Project required'),
      cuQuantity: Yup.number()
        .nullable()
        .min(1, 'The minimum amount is one')
        .required('Hours required'),
    }),
  });
  const addDataToLocalStorage = (column, data) => {
    const obj = JSON.parse(localStorage?.getItem('formikValues'));
    localStorage.setItem(
      'formikValues',
      JSON.stringify({
        ...obj,
        [column]: data,
      })
    );
  };
  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <div className="ticketBody">
        <div className="ticketDropDown">
          <TextField
            id="outlined-select-status-native"
            value={formik.values?.orderId}
            variant="outlined"
            name="orderId"
            fullWidth
            onChange={(e) => {
              formik?.handleChange(e);
              addDataToLocalStorage('orderId', e.target.value);
            }}
            inputProps={{ maxLength: 250 }}
            placeholder="Id"
            error={Boolean(formik.touched?.orderId && formik?.errors?.orderId)}
            helperText={formik.touched?.orderId && formik?.errors?.orderId}
          />
        </div>
        <div className="ticketDropDown">
          <TextField
            id="outlined-select-status-native"
            value={formik.values?.cuQuantity}
            variant="outlined"
            name="cuQuantity"
            fullWidth
            onChange={(e) => {
              formik?.handleChange(e);
              addDataToLocalStorage('cuQuantity', e.target.value);
            }}
            type="number"
            inputProps={{ maxLength: 250 }}
            placeholder="Hours"
            error={Boolean(
              formik.touched?.cuQuantity && formik?.errors?.cuQuantity
            )}
            helperText={
              formik.touched?.cuQuantity && formik?.errors?.cuQuantity
            }
          />
        </div>
        <div className="ticketDropDown">
          <TextField
            id="outlined-select-status-native"
            value={formik.values?.project}
            variant="outlined"
            name="project"
            fullWidth
            onChange={(e) => {
              formik?.handleChange(e);
              addDataToLocalStorage('project', e.target.value);
            }}
            type="text"
            inputProps={{ maxLength: 250 }}
            placeholder="Project"
            error={Boolean(formik?.errors?.project && formik.touched?.project)}
            helperText={formik.touched?.project && formik?.errors?.project}
          />
        </div>

        {editForm && (
          <div className="ticketDropDown labelsDataCss">
            <Autocomplete
              id="order-status"
              options={orderStatusList || []}
              value={formik?.values?.cuOrderStatus}
              getOptionLabel={(option) => option.name}
              onChange={(e: any, newValue: any) => {
                formik?.setFieldValue('cuOrderStatus', newValue);
                // addDataToLocalStorage('clientId', newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Status"
                  error={Boolean(
                    formik?.touched?.cuOrderStatus &&
                    formik?.errors?.cuOrderStatus
                  )}
                  helperText={
                    formik?.touched?.cuOrderStatus &&
                    formik?.errors?.cuOrderStatus
                  }
                />
              )}
            />
          </div>
        )}

        <div className="submitTicket">
          <Box display="flex" sx={{ marginLeft: 'auto' }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={closeDrawer}
              sx={{ marginLeft: 'auto', height: '38px' }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              sx={{ height: '38px' }}>
              {editForm ? 'Save' : 'Create'}
            </Button>
          </Box>
        </div>
      </div>
    </form>
  );
};

export default ConsultingOrderForm;
