import { Box, Container, Grid } from '@material-ui/core';
import { useState, type FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useSettings from '../../../hooks/useSettings';

import toast from 'react-hot-toast';
import { employeesApi } from 'src/api/employeesApi';
import SimpleBreadCrumb from 'src/components/widgets/BreadCrumb/SimpleBreadCrumb';
import Form from 'src/components/widgets/forms/Form';
import * as Yup from 'yup';
import { rolesApi } from 'src/api/rolesApi';
import useAuth from 'src/hooks/useAuth';
import { RoleTypes } from 'src/types/globalTypes';

const AddEmployees: FC = () => {
  const { user } = useAuth();
  const returnUrl = '/erp/crm/employees';
  const { settings } = useSettings();
  const navigate = useNavigate();
  type Role = {
    id?: number;
  };
  type EmployeeData = {
    firstName: string;
    lastName: string;
    designation: string;
    roles?: RoleTypes[];
    email: string;
    contactNumber: string;
    avatar: string;
    info: string;
    oldPassword?: string;
    newPassword?: string;
    confirmNewPassword?: string;
    roleId?: Role[]
};

  const [roles, setRoles] = useState<RoleTypes[]>([]);

  const getAllRoles = async () => {
    try {
      const response = await rolesApi.getAllActiveRoles();
      if (response?.status === 200) {
        setRoles(response?.data);
      } else {
        setRoles([]);
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setRoles([]);
      }
    }
  };
  const InitialValues:EmployeeData = {
    firstName: '',
    lastName: '',
    designation: '',
    roles: [],
    email: '',
    contactNumber: '',
    avatar: 'dummy_image.png',
    info: ''
  };

  const previewData = [
    {
      name: 'employee details',
      fields: [
        {
          id: '1',
          name: 'firstName',
          label: 'First Name',
          placeholder: 'First Name',
          type: 'text',
          required: true,
          fullWidth: true,
        },
        {
          id: '2',
          name: 'lastName',
          label: 'Last Name',
          placeholder: 'Last Name',
          type: 'text',
          required: true,
          fullWidth: true,
        },
        {
          id: '4',
          name: 'designation',
          label: 'Designation',
          placeholder: 'Designation',
          type: 'text',
          required: true,
          fullWidth: true,
        },
        {
          id: '5',
          name: 'roles',
          label: 'Role',
          placeholder: 'Role',
          type: 'muiAutoComplete',
          required: true,
          fullWidth: true,
          columnSize: 6,
          array: roles?.filter((item) => !item.identifier.includes('client_contact'))
        },
        {
          id: '6',
          name: 'email',
          label: 'Email',
          placeholder: 'Email',
          type: 'email',
          required: true,
          fullWidth: true,
        },
        {
          id: '7',
          name: 'contactNumber',
          label: 'Phone Number',
          placeholder: 'Phone Number',
          type: 'string',
          required: true,
          fullWidth: true,
        },
        {
          id: '8',
          name: 'avatar',
          label: 'Image',
          placeholder: 'Image',
          type: 'file',
          required: true,
          fullWidth: true,
        },
      ],
    },
    {
      name: 'Description',
      fields: [
        {
          id: '11',
          name: 'info',
          label: 'Description',
          placeholder: 'Description',
          type: 'textarea',
          fullWidth: true,
        },
      ],
    },
  ];

  const Schema = Yup.object({
    firstName: Yup.string()
      .required('First name required')
      .trim()
      .min(3, 'Minimum 3 characters allowed')
      .max(50, 'Maximum 50 characters allowed')
      .test(
        'Field must not contain only spaces',
        (value) => value && value.length > 0
      ),
    lastName: Yup.string()
      .required('Last name required')
      .trim()
      .min(3, 'Minimum 3 characters allowed')
      .max(50, 'Maximum 50 characters allowed')
      .test(
        'Field must not contain only spaces',
        (value) => value && value.length > 0
      ),
    email: Yup.string().email().required('email required'),
    contactNumber: Yup.string()
      .required('Phone number required')
      .trim()
      .matches(/^.{10,20}$/, 'Phone number must be between 10 and 20 characters in length'),
    designation: Yup.string()
      .required('Designation required')
      .trim()
      .min(3, 'Minimum 3 characters required')
      .max(100, 'Maximum 100 characters allowed')
      .test(
        'Field must not contain only spaces',
        (value) => value && value.length > 0
      ),
      roles: Yup.array().nullable().test('is-nonempty', 'Role required', (value) => value && value?.length > 0),
    avatar: Yup.string().required('Image required'),
    info: Yup.string().max(2000, 'Maximum 2000 characters allowed')
  });

  const handleOnSubmit = async (values: EmployeeData) => {
    try {
      const payload = {
        name: values?.email,
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        designation: values?.designation.trim(),
        roleId: values?.roles?.map((role) =>  role?.id),
        contactNumber: values?.contactNumber,
        avatar: values?.avatar,
        info: values?.info,
        projectId: ['string']
      };
      const response = await employeesApi.create(payload);
      if (response?.status === 201) {
        toast.success('Employee created successfully. Check your Email.');
        navigate(returnUrl);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        if (Array.isArray(error?.response?.data?.message)) {
          error?.response?.data?.message?.map((msg) => toast?.error(msg));
        } else if (error?.response?.status !== 401) {
          toast.error(error?.response?.data?.message);
        }
      } else if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };


  const BreadCrumbsData = {
    title: 'Add Employee',
    links: [
      {
        name: 'Employees',
        to: returnUrl,
      },
      {
        name: 'Add Employee',
        to: `${returnUrl}/new`,
      },
    ],
  };

  useEffect(() => {
    if (
      !user?.permissions?.includes('employee-create')
    ) {
      window.location.href = '/admin/not-found';
    } else {
      getAllRoles();
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <SimpleBreadCrumb data={BreadCrumbsData} />
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Form
              previewer={previewData}
              initialValues={InitialValues}
              handleOnSubmit={handleOnSubmit}
              schema={Schema}
              returnUrl={returnUrl}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default AddEmployees;
