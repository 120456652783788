import { useEffect, useState, useRef } from 'react';
import OverviewTable from './OverviewTable';
import {
  Box,
  Container,
  Grid,
  TextField,
  InputAdornment,
  Typography,
  Autocomplete,
  Chip,
  Checkbox,
  FormControlLabel,
  Card,
  CardContent,
  OutlinedInput,
  IconButton,
  Select,
  MenuItem,
} from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { leavesApi } from 'src/api/leavesApi';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import { userApi } from 'src/api/userApi';
import useStyles from './useStyles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Button } from '@mui/material';
import toast from 'react-hot-toast';

// Leave breakdown details type
type LeaveDetails = {
  paid: number;
  medical: number;
  unpaid: number;
  holidays: number;
};

type MonthDetails = {
  month: string;
  monthIndex: number;
  paid: number;
  unpaid: number;
  medical: number;
  holidays: number;
};

type EmployeeLeaveData = {
  userName: string;
  totalConsumed: number;
  totalRemaining: number;
  totalAllocated: number;
  consumedLeaves: LeaveDetails;
  allocatedLeaves: LeaveDetails;
  remainingLeaves: LeaveDetails;
  months?: MonthDetails[]; // Monthly data field
};

const LeavesReporting = () => {
  const [employees, setEmployees] = useState<EmployeeLeaveData[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedLeaveTypes, setSelectedLeaveTypes] = useState<string[]>([]);
  const [availableUsers, setAvailableUsers] = useState<
    { id: string; name: string }[]
  >([]);
  const [availableLeaveTypes, setAvailableLeaveTypes] = useState<
    { id: string; name: string; key: string }[]
  >([]);
  const [showMonthlyData, setShowMonthlyData] = useState<boolean>(false); // State for checkbox
  const [year, setYear] = useState(new Date().getFullYear());
  const classes = useStyles();

  const fetchFilterData = async () => {
    try {
      const [usersResponse, leaveTypesResponse] = await Promise.all([
        userApi.getUsersByOrganizationId(null, null, 25000, null),
        leavesApi.getLeaveTypes(),
      ]);
      const users = usersResponse?.data?.data.map((user: any) => ({
        id: user.id,
        name: user.name,
      }));
      const leaveTypes = leaveTypesResponse?.data?.map((leave: any) => ({
        id: leave.id,
        name: leave.name,
        key: leave.key, // Store the key for API requests
      }));
      setAvailableUsers(users);
      setAvailableLeaveTypes(leaveTypes);
    } catch (error) {
      console.error('Error fetching filter data:', error);
    }
  };

  const fetchEmployeeData = async () => {
    const startDate = `${year}-01-01`;
    const endDate = `${year}-12-31`;

    const usersFilter = selectedUsers.length > 0 ? selectedUsers.join(',') : '';
    const leaveTypesFilter =
      selectedLeaveTypes.length > 0 ? selectedLeaveTypes.join(',') : '';

    try {
      const response = showMonthlyData
        ? await leavesApi.getMonthlyLeaveData(usersFilter, leaveTypesFilter)
        : await leavesApi.getLeavesReportingOverview(
            startDate,
            endDate,
            usersFilter, // Send users as a comma-separated string
            leaveTypesFilter // Send leave types as a comma-separated string
          );

      setEmployees(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching employee data:', error);
      setEmployees([]); // Set employees to an empty array on error
    }
  };

  useEffect(() => {
    fetchFilterData();
    fetchEmployeeData();
  }, []);

  useEffect(() => {
    fetchEmployeeData();
  }, [year, selectedUsers, selectedLeaveTypes, showMonthlyData]);

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const generateColumns = () => {
    const baseColumns = [
      { id: 'userName', label: 'Employee Name' },
      { id: 'totalAllocated', label: 'Allocated Leaves' },
      { id: 'totalConsumed', label: 'Consumed Leaves' },
      { id: 'totalRemaining', label: 'Remaining Leaves' },
    ];

    if (showMonthlyData) {
      const monthColumns = Array.from({ length: 12 }, (_, i) => ({
        id: `month_${i + 1}`,
        label: moment().month(i).format('MMMM'),
      }));

      return [...baseColumns, ...monthColumns]; // Combine base columns with monthly columns
    }

    return baseColumns;
  };

  const prepareRows = () => {
    if (showMonthlyData) {
      return employees?.map((employee) => {
        const monthData = employee.months?.reduce((acc, month) => {
          acc[`month_${month.monthIndex}`] =
            month.paid + month.unpaid + month.medical + month.holidays;
          return acc;
        }, {} as Record<string, number>); // Store total leaves for each month

        return {
          ...employee,
          months: employee.months, // Pass detailed month data for expanded rows
          ...monthData, // Total leaves per month in the main row
        };
      });
    }

    return employees;
  };

  const leaveReportingExport = async () => {
    const startDate = `${year}-01-01`;
    const endDate = `${year}-12-31`;

    const users = selectedUsers.length > 0 ? selectedUsers.join(',') : '';
    const leaveTypes =
      selectedLeaveTypes.length > 0 ? selectedLeaveTypes.join(',') : '';

    try {
      const blob = await leavesApi.leaveReportingExport({
        endDate,
        startDate,
        leaveTypes,
        users,
      });

      // Create a link to download the blob
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Leave Reporting ${year}.xlsx`); // Adjust the filename and extension if needed
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      link.parentNode?.removeChild(link);
      toast.success('Leave Reporting Export Successfully');
    } catch (error) {
      toast.error('Something went wrong');
      console.error('Failed to export', error);
    }
  };

  return (
    <Container maxWidth={false} className={classes.scrollableContainer}>
      <Typography
        variant="h3"
        gutterBottom
        sx={{ marginTop: '16px', paddingTop: '16px' }}>
        Leaves Reporting
      </Typography>
      <Card variant="outlined">
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2}>
              <Select
                value={year}
                onChange={handleYearChange}
                displayEmpty
                fullWidth>
                {Array.from({ length: 5 }, (_, i) => (
                  <MenuItem key={i} value={new Date().getFullYear() + i}>
                    {new Date().getFullYear() + i}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            {/* User and Leave Type Filters */}
            <Grid item xs={3}>
              <Autocomplete
                multiple
                options={availableUsers}
                limitTags={1}
                getOptionLabel={(option) => option.name}
                value={selectedUsers
                  .map((userId) =>
                    availableUsers.find((user) => user.id === userId)
                  )
                  .filter((user) => user !== undefined)}
                onChange={(event, newValue) => {
                  setSelectedUsers(
                    newValue.map((option) =>
                      typeof option === 'string' ? option : option.id
                    )
                  );
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Filter by User"
                    placeholder={
                      selectedUsers.length === 0 ? 'Select Users' : ''
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                multiple
                options={availableLeaveTypes}
                limitTags={1}
                getOptionLabel={(option) => option.name} // Show leave type name in dropdown
                value={availableLeaveTypes.filter((leaveType) =>
                  selectedLeaveTypes.includes(leaveType.key)
                )} // Match based on key
                onChange={(event, newValue) => {
                  setSelectedLeaveTypes(
                    newValue.map((option) =>
                      typeof option === 'string' ? option : option.key
                    )
                  ); // Set selected keys
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Filter by Leave Type"
                    placeholder={
                      selectedLeaveTypes.length === 0
                        ? 'Select Leave Types'
                        : ''
                    }
                  />
                )}
              />
            </Grid>
            {/* Checkbox to toggle monthly data */}
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showMonthlyData}
                    onChange={(e) => setShowMonthlyData(e.target.checked)}
                    color="primary"
                  />
                }
                label="Show Monthly Data"
              />
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="secondary"
                onClick={leaveReportingExport}>
                Export
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <OverviewTable
        columns={generateColumns()} // Dynamically generated columns
        rows={prepareRows()} // Rows with or without monthly data
        isMonthlyData={showMonthlyData} // Pass this prop to enable monthly data view
        selectedLeaveTypes={selectedLeaveTypes}
      />
    </Container>
  );
};

export default LeavesReporting;
