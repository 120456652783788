/* eslint-disable @typescript-eslint/naming-convention */
import { Autocomplete, Box, Button, Checkbox, Chip, FormControlLabel, Grid, IconButton, InputAdornment, Modal, TextField, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import moment from 'moment';
import * as React from 'react';
import { DateRange } from 'react-date-range';
import { casesApi } from 'src/api/casesApi';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(() => ({
  modal: {
    '& .modalBody': {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '735px',
      background: '#fff',
      boxShadow: '0px 4px 31px 0px rgba(0, 0, 0, 0.25)',
      borderRadius: '4px',
      padding: '16px 24px',

      '& .MuiOutlinedInput-root:has(.MuiButtonBase-root:nth-child(n+3))': {
        // backgroundColor: 'pink',
        // width: '200px',
        paddingLeft: '5px',
        '& .MuiChip-root': {
          maxWidth: '120px',
          fontSize: '10px',
          height: '23px',
        },
        '& .MuiChip-label': {
          paddingLeft: '8px',
          paddingRight: '8px',
        },
        '& .MuiSvgIcon-root': {
          marginRight: '3px',
        }
      }
    },
    '& .modal-title': {
      background: '#231F20',
      borderRadius: '4px 4px 0px 0px',
      height: '48px',
      margin: '-16px -24px',
      padding: '16px 24px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '& .MuiTypography-root': {
        color: '#fff',
        fontSize: '14px',
        fontstyle: 'normal',
      },
      '& .MuiIconButton-root': {
        padding: '0px',
      }
    },
    '& .fields+.fields': {
      marginTop: '15px'
    },
    '& .datefield': {
      position: 'relative',
      '& .rdrCalendarWrapper': {
        zIndex: '999',
        position: 'absolute',
        right: '-1px',
        top: '40px',
        borderRadius: '5px',
        border: '1px solid rgb(239, 242, 247)',
        '& .rdrDateDisplayWrapper': {
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
        }
      }
    },
    '& select, & .MuiAutocomplete-inputRoot, & input': {
      minHeight: '38px',
      padding: '0 10px',
      lineHeight: '116.7%',
      color: '#696969',
      width: '100%',
      '& .MuiAutocomplete-input': {
        padding: 0
      }
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.2rem'
    },
    '& .MuiFormControlLabel-root': {
      padding: '0 20px',
      '& span': {
        fontSize: '14px'
      }
    },
    '& .btns': {
      display: 'flex',
      // marginTop: '148px',
      position: 'absolute',
      bottom: '30px',
      right: '24px',
      '& button': {
        width: '115px',
        height: 30
      }
    },
  }
}));
export default function AdvancedSearchModal(props: any) {
  const {
    _headingMain,
    _setShowAdvancedSearch,
    _search,
    _setSearch,
    _caseType,
    _setCaseType,
    _priority,
    _setPriority,
    _stakeholder,
    _setStakeholder,
    _assignee,
    _setAssignee,
    _projectSponser,
    _setProjectSponser,
    _escalationManager,
    _setEscalationManager,
    _status,
    _setStatus,
    _hasAttachments,
    _setHasAttachments,
    _isEscalated,
    _setIsEscalated,
    _isFirstResponseTimeViolated,
    _setIsFirstResponseTimeViolated,
    _isSynced,
    _setIsSynced,
    _isResolveTimeViolated,
    _setIsResolveTimeViolated,
    _date,
    _setDate,
    _project,
    _setProject,
    _organization,
    _setOrganization,
  } = props;
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
    _setShowAdvancedSearch(false);
  };
  const classes = useStyles();

  const [search, setSearch] = React.useState(_search);
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  /** date */
  const [state, setState] = React.useState<any>([
    {
      startDate: _date.from ? new Date(_date.from) : null,
      endDate: _date.to ? new Date(_date.to) : null,
      key: 'selection',
      color: '#c22027',
    }
  ]);
  const [isOpen, setIsOpen] = React.useState(false);
  const ref = React.useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  /** case-types */
  const [caseType, setCaseType] = React.useState<any>(_caseType || []);
  // logger('case-type: value: ', issueType);
  const [caseTypeInput, setCaseTypeInput] = React.useState('');
  const [allCaseTypes, setAllCaseTypes] = React.useState([]);
  const getAllCaseTypes = async () => {
    try {
      const response = await casesApi.getCaseTypes();
      setAllCaseTypes(response?.data);
    } catch (error) {
      setAllCaseTypes([]);
    }
  };

  /** projects */
  const [project, setProject] = React.useState<any>(_project || []);
  const [projectInput, setProjectInput] = React.useState('');
  const [allProjects, setAllProjects] = React.useState([]);
  const getAllProjects = async () => {
    try {
      const response = await casesApi.getAllProjects();
      setAllProjects(response?.data);
    } catch (error) {
      setAllProjects([]);
    }
  };
  /** priorities */
  const [priority, setPriority] = React.useState<any>(_priority || []);
  const [priorityInput, setPriorityInput] = React.useState('');
  const [allPriorities, setAllPriorities] = React.useState([]);
  const getAllPriorities = async () => {
    try {
      const response = await casesApi.getPriorities();
      setAllPriorities(response?.data);
    } catch (error) {
      setAllPriorities([]);
    }
  };

  /** stakeholder */
  const [stakeholder, setStakeholder] = React.useState<any>(_stakeholder || []);
  const [stakeholderInput, setStakeholderInput] = React.useState('');

  /** assignee */
  const [assignee, setAssignee] = React.useState<any>(_assignee || []);
  const [assigneeInput, setAssigneeInput] = React.useState('');

  /** project sponser */
  const [projectSponser, setProjectSponser] = React.useState<any>(_projectSponser || []);
  const [projectSponserInput, setProjectSponserInput] = React.useState('');

  /** escalation manager */
  const [escalationManager, setEscalationManager] = React.useState<any>(_escalationManager || []);
  const [escalationManagerInput, setEscalationManagerInput] = React.useState('');

  /** escalation manager */
  const [organization, setOrganization] = React.useState<any>(_organization || []);
  const [organizationInput, setOrganizationInput] = React.useState('');
  const [allOrganizations, setAllOrganizations] = React.useState([]);
  const getAllOrganizations = async () => {
    try {
      const res = await casesApi.getAllOrganizations();
      setAllOrganizations(res?.data);
    } catch (error) {
      setAllOrganizations([]);
    }
  };

  /** statuses */
  const [status, setStatus] = React.useState<any>(_status || []);
  const [statusInput, setStatusInput] = React.useState('');
  const [allStatuses, setAllStatuses] = React.useState([]);
  const getAllStatuses = async () => {
    try {
      const res = await casesApi?.getStatuses();
      setAllStatuses(res?.data);
    } catch (error) {
      setAllStatuses([]);
    }
  };

  /** has-attachments */
  const [hasAttachments, setHasAttachments] = React.useState(_hasAttachments);
  const toggleHasAttachments = () => {
    setHasAttachments(!hasAttachments);
  };
  /** is-escalated */
  const [isEscalated, setIsEscalated] = React.useState(_isEscalated);
  const toggleIsEscalated = () => {
    setIsEscalated(!isEscalated);
  };
  /** is-1st-response-time-voilated */
  const [isFirstResponseTimeViolated, setIsFirstResponseTimeViolated] = React.useState(_isFirstResponseTimeViolated);
  const toggleIsFirstResponseTimeViolated = () => {
    setIsFirstResponseTimeViolated(!isFirstResponseTimeViolated);
  };
  /** is-sync */
  const [isSynced, setIsSynced] = React.useState(_isSynced);
  const toggleIsSynced = () => {
    setIsSynced(!isSynced);
  };
  /** is-resolve-time-violated */
  const [isResolveTimeViolated, setIsResolveTimeViolated] = React.useState(_isResolveTimeViolated);
  const toggleIsResolveTimeViolated = () => {
    setIsResolveTimeViolated(!isResolveTimeViolated);
  };

  /** internal-users */
  const [allInternalUsers, setAllInternalUsers] = React.useState([]);
  const getAllInternalUsers = async () => {
    try {
      const response = await casesApi.getUserById(false);
      if (response?.status === 200) {
        setAllInternalUsers(response?.data);
      }
    } catch (error) {
      setAllInternalUsers([]);
    }
  };

  /** internal-users */
  const [allExternalUsers, setAllExternalUsers] = React.useState([]);
  const getAllExteranlUsers = async () => {
    try {
      const res = await casesApi?.getAutoCompleteUser(true);
      setAllExternalUsers(res?.data);
    } catch (error) {
      setAllExternalUsers([]);
    }
  };

  React.useEffect(() => {
    getAllCaseTypes();
    getAllPriorities();
    getAllInternalUsers();
    getAllExteranlUsers();
    getAllStatuses();
    getAllProjects();
    getAllOrganizations();
  }, []);

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleReset = () => {
    _setSearch('');
    _setCaseType([]);
    _setPriority([]);
    _setStakeholder([]);
    _setAssignee([]);
    _setProjectSponser([]);
    _setEscalationManager([]);
    _setStatus([]);
    _setHasAttachments(false);
    _setIsEscalated(false);
    _setIsFirstResponseTimeViolated(false);
    _setIsSynced(false);
    _setIsResolveTimeViolated(false);
    if (_project) {
      _setProject([]);
    }
    if (_organization) {
      _setOrganization([]);
    }
    _setDate({
      to: null,
      from: null
    });
    handleClose();
  };
  const handleSubmit = () => {
    _setSearch(search);
    _setCaseType(caseType);
    _setPriority(priority);
    _setStakeholder(stakeholder);
    _setAssignee(assignee);
    _setProjectSponser(projectSponser);
    _setEscalationManager(escalationManager);
    _setStatus(status);
    _setHasAttachments(hasAttachments);
    _setIsEscalated(isEscalated);
    _setIsFirstResponseTimeViolated(isFirstResponseTimeViolated);
    _setIsSynced(isSynced);
    _setIsResolveTimeViolated(isResolveTimeViolated);
    if (_project) {
      _setProject(project);
    }
    if (_organization) {
      _setOrganization(organization);
    }
    _setDate({
      to: state[0].endDate ? moment(state[0].endDate).format('YYYY-MM-DD') : null,
      from: state[0].startDate ? moment(state[0].startDate).format('YYYY-MM-DD') : null
    });
    handleClose();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modal}
      >
        <Box className="modalBody">
          <div className="modal-title">
            <Typography
              variant="h6"
              id="modal-title"
            >
              {_headingMain}
            </Typography>
            <IconButton
              aria-label="close"
              color="primary"
              onClick={handleClose}
            >
              <CancelIcon style={{ color: '#fff', }} />
            </IconButton>
          </div>

          <Grid
            container
            spacing={3}
            sx={{ py: 2 }}
          >

            <Grid
              item
              md={6}
              xs={12}

            >
              <div className="fields">
                <TextField
                  id="search-18323038"
                  value={search}
                  onChange={handleSearch}
                  variant="outlined"
                  name="search"
                  fullWidth
                  placeholder="Case no, summary, description..."
                />
              </div>
              <div className="fields">
                <Autocomplete
                  id="issue-name"
                  options={allCaseTypes || []}
                  multiple
                  value={caseType}
                  onChange={(event: any, value: any) => {
                    setCaseType(value);
                  }}
                  getOptionLabel={(option) => option.name}
                  inputValue={caseTypeInput}
                  onInputChange={(event: any, newInputValue: any) => {
                    setCaseTypeInput(newInputValue);
                  }}
                  style={{ minWidth: 300 }}
                  renderTags={(value, getTagProps) => value.map((option, index) => (
                    <Tooltip title={option?.name || ''}>
                      <Chip
                        variant="filled"
                        label={option?.name || ''}
                        size="small"
                        {...getTagProps({ index })}
                      />
                    </Tooltip>
                  ))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Case Type"
                    />
                  )}
                />
              </div>
              <div className="fields">
                <Autocomplete
                  id="status-183320"
                  options={allStatuses || []}
                  value={status}
                  multiple
                  onChange={(event: any, value: any) => {
                    setStatus(value);
                  }}
                  getOptionLabel={(option) => option.name}
                  inputValue={statusInput}
                  onInputChange={(event: any, newInputValue: any) => {
                    setStatusInput(newInputValue);
                  }}
                  style={{ minWidth: 300 }}
                  renderTags={(value, getTagProps) => value.map((option, index) => (
                    <Tooltip title={option?.name || ''}>
                      <Chip
                        variant="filled"
                        label={option?.name || ''}
                        size="small"
                        {...getTagProps({ index })}
                      />
                    </Tooltip>
                  ))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Status"
                    />
                  )}
                />
              </div>
              <div className="fields">
                <Autocomplete
                  id="priority-183320"
                  options={allPriorities || []}
                  value={priority}
                  multiple
                  onChange={(event: any, value: any) => {
                    setPriority(value);
                  }}
                  getOptionLabel={(option) => option.name}
                  inputValue={priorityInput}
                  onInputChange={(event: any, newInputValue: any) => {
                    setPriorityInput(newInputValue);
                  }}
                  style={{ minWidth: 300 }}
                  renderTags={(value, getTagProps) => value.map((option, index) => (
                    <Tooltip title={option?.name || ''}>
                      <Chip
                        variant="filled"
                        label={option?.name || ''}
                        size="small"
                        {...getTagProps({ index })}
                      />
                    </Tooltip>
                  ))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Priority"
                    />
                  )}
                />
              </div>
              {
                _project && (
                  <div className="fields">
                    <Autocomplete
                      id="priority-183320"
                      options={allProjects || []}
                      value={project}
                      multiple
                      onChange={(event: any, value: any) => {
                        setProject(value);
                      }}
                      getOptionLabel={(option) => option.name}
                      inputValue={projectInput}
                      onInputChange={(event: any, newInputValue: any) => {
                        setProjectInput(newInputValue);
                      }}
                      style={{ minWidth: 300 }}
                      renderTags={(value, getTagProps) => value.map((option, index) => (
                        <Tooltip title={option?.name || ''}>
                          <Chip
                            variant="filled"
                            label={option?.name || ''}
                            size="small"
                            {...getTagProps({ index })}
                          />
                        </Tooltip>
                      ))}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Project"
                        />
                      )}
                    />
                  </div>
                )
              }
              {
                _organization && (
                <div className="fields">
                  <Autocomplete
                    id="project-183320"
                    options={allOrganizations || []}
                    value={organization}
                    multiple
                    onChange={(event: any, value: any) => {
                      setOrganization(value);
                    }}
                    getOptionLabel={(option) => option.name}
                    inputValue={organizationInput}
                    onInputChange={(event: any, newInputValue: any) => {
                      setOrganizationInput(newInputValue);
                    }}
                    style={{ minWidth: 300 }}
                    renderTags={(value, getTagProps) => value.map((option, index) => (
                      <Tooltip title={option?.name || ''}>
                        <Chip
                          variant="filled"
                          label={option?.name || ''}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      </Tooltip>
                    ))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Organization"
                      />
                    )}
                  />
                </div>
                )
              }
              <div className="fields">
                <Autocomplete
                  id="escalation-manager-183320"
                  options={allInternalUsers || []}
                  value={escalationManager}
                  multiple
                  onChange={(event: any, value: any) => {
                    setEscalationManager(value);
                  }}
                  getOptionLabel={(option) => option.name}
                  inputValue={escalationManagerInput}
                  onInputChange={(event: any, newInputValue: any) => {
                    setEscalationManagerInput(newInputValue);
                  }}
                  style={{ minWidth: 300 }}
                  renderTags={(value, getTagProps) => value.map((option, index) => (
                    <Tooltip title={option?.name || ''}>
                      <Chip
                        variant="filled"
                        label={option?.name || ''}
                        size="small"
                        {...getTagProps({ index })}
                      />
                    </Tooltip>
                  ))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Escalation Manager"
                    />
                  )}
                />
              </div>
              <div className="fields">
                <Autocomplete
                  id="stakeholder-183320"
                  options={allInternalUsers || []}
                  value={stakeholder}
                  multiple
                  onChange={(event: any, value: any) => {
                    setStakeholder(value);
                  }}
                  getOptionLabel={(option) => option.name}
                  inputValue={stakeholderInput}
                  onInputChange={(event: any, newInputValue: any) => {
                    setStakeholderInput(newInputValue);
                  }}
                  style={{ minWidth: 300 }}
                  renderTags={(value, getTagProps) => value.map((option, index) => (
                    <Tooltip title={option?.name || ''}>
                      <Chip
                        variant="filled"
                        label={option?.name || ''}
                        size="small"
                        {...getTagProps({ index })}
                      />
                    </Tooltip>
                  ))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Stakeholder"
                    />
                  )}
                />
              </div>
              <div className="fields">
                <Autocomplete
                  id="project-sponser-183320"
                  options={allExternalUsers || []}
                  value={projectSponser}
                  multiple
                  onChange={(event: any, value: any) => {
                    setProjectSponser(value);
                  }}
                  getOptionLabel={(option) => option.name}
                  inputValue={projectSponserInput}
                  onInputChange={(event: any, newInputValue: any) => {
                    setProjectSponserInput(newInputValue);
                  }}
                  style={{ minWidth: 300 }}
                  renderTags={(value, getTagProps) => value.map((option, index) => (
                    <Tooltip title={option?.name || ''}>
                      <Chip
                        variant="filled"
                        label={option?.name || ''}
                        size="small"
                        {...getTagProps({ index })}
                      />
                    </Tooltip>
                  ))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Project Sponser"
                    />
                  )}
                />
              </div>
              <div className="fields">
                <Autocomplete
                  id="assignee-183320"
                  options={allInternalUsers || []}
                  value={assignee}
                  multiple
                  onChange={(event: any, value: any) => {
                    setAssignee(value);
                  }}
                  getOptionLabel={(option) => option.name}
                  inputValue={assigneeInput}
                  onInputChange={(event: any, newInputValue: any) => {
                    setAssigneeInput(newInputValue);
                  }}
                  style={{ minWidth: 300 }}
                  renderTags={(value, getTagProps) => value.map((option, index) => (
                    <Tooltip title={option?.name || ''}>
                      <Chip
                        variant="filled"
                        label={option?.name || ''}
                        size="small"
                        {...getTagProps({ index })}
                      />
                    </Tooltip>
                  ))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Assignee"
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}

            >
              <div className="fields datefield">
                <TextField
                  value={state[0].startDate ? moment(state[0].startDate).format('MM-DD-YYYY') : 'mm/dd/yyyy'}
                  onClick={() => { setIsOpen(!isOpen); }}
                  size="small"
                  style={{ width: '48%', marginRight: '4%', }}
                  label="From"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CalendarTodayIcon
                          color="primary"
                          fontSize="small"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  value={state[0].endDate ? moment(state[0].endDate).format('MM-DD-YYYY') : 'mm/dd/yyyy'}
                  disabled
                  size="small"
                  style={{ width: '48%', }}
                  label="To"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CalendarTodayIcon
                          color="primary"
                          fontSize="small"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                {isOpen && (
                <div ref={ref}>
                  <DateRange
                    ranges={state}
                    onChange={(item) => {
                      setState([item.selection]);
                    }}
                    editableDateInputs
                  />
                </div>
                )}
              </div>
              <div className="fields">
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={isSynced}
                      onChange={toggleIsSynced}
                    />
                    )}
                  label="3rd party sync"
                />
              </div>
              <div className="fields">
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={isEscalated}
                      onChange={toggleIsEscalated}
                    />
                    )}
                  label="Escalated cases"
                />
              </div>
              <div className="fields">
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={hasAttachments}
                      onChange={toggleHasAttachments}
                    />
                    )}
                  label="Has attachments"
                />
              </div>
              <div className="fields">
                SLA
              </div>
              <div className="fields">
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={isFirstResponseTimeViolated}
                      onChange={toggleIsFirstResponseTimeViolated}
                    />
                    )}
                  label="Is 1st response time violated"
                />
              </div>
              <div className="fields">
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={isResolveTimeViolated}
                      onChange={toggleIsResolveTimeViolated}
                    />
                    )}
                  label="Is resolve time violated"
                />
              </div>
              <div className="btns">
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ marginLeft: 'auto', height: '38px' }}
                  onClick={handleReset}
                >
                  Reset
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  sx={{ height: '38px', marginLeft: '12px', }}
                  onClick={handleSubmit}
                >
                  Search
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
