import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PermissionTypes {
  permissions: any;
}
const initialState: PermissionTypes = {
  permissions: {}
};
const userPermission = createSlice({
  name: 'userPermissions',
  initialState,
  reducers: {
    setUserPermissions: (state:any, action: PayloadAction<any>) => {
      state.permissions = action.payload;
      return state;
    }
  }
});

export const { setUserPermissions } = userPermission.actions;
export default userPermission.reducer;
