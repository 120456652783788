import React, { FC, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';
import { useNavigate } from 'react-router';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import toast from 'react-hot-toast';
import { useOutletContext, useParams } from 'react-router-dom';
import ListSelectedCases from 'src/components/widgets/tables/listSelectedCases';
import ArrowIcon from './ArrowIcon';


import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { casesApi } from 'src/api/casesApi';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
interface EditPreviewProps {
    performAction: (action: string) => void;
    actionType: string;
    editPayload: any;
    selectedIds: string[];
}

interface RowData {
    name: string;
    value: string | number;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderRadius: theme.shape.borderRadius,
        margin: "30px",
        '& th': {
            fontWeight: '600'
        },
        '& .commentSec img': {
            width: ' 60px',
            height: '67px',
            objectFit: 'contain',
            display: 'block',
            color: ' #757575',
            cursor: 'pointer',
            border: `1px solid ${theme.palette.grey[300]}`,
        },
        '& .labelBox': {
            marginTop: '20px'
        }
    },
    formControl: {
        width: '100%',
    },
    button: {
        backgroundColor: '#c22027',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#d43f40',
        },
        alignSelf: 'flex-end',
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    textLeft: {
        marginLeft: theme.spacing(3),
        fontSize: '15px',
        margin: '0px'
    },
    formLabel: {
        marginLeft: theme.spacing(3),

        fontWeight: 600,
        fontSize: 14
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    subtitle: {
        marginLeft: theme.spacing(3),
    },
    controlLabel: {
        marginLeft: theme.spacing(3),
    },
    btnStyle: {
        height: '24px',
        padding: '20px',
        gap: '10px',
        borderRadius: '6px 0px 0px 0px',
        opacity: 1,
        margin: '10px'
    },
    Done: {
        background: '#DEFFEE',
        color: '#66CB9F'
    },
    Todo: {
        background: '#FFEDE3',
        color: '#F7936F'
    },
    Testing: {
        background: '#FFE6E4',
        color: '#F16063'
    },
    Inprogress: {
        background: '#E5FDFF',
        color: '#09A0BF'
    },
    flexBtn: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        '@media (min-width:900px)': {
            marginLeft: '15%',
        },
    },
    tansitionSec: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexWrap: 'wrap',
        '& .MuiFormControlLabel-label': {
            paddingLeft: '25px',
        }
    },
    BoxCenter: {
        textAlign: 'center'
    },
    confirmBG: {
        display: 'flex',
        justifyContent: 'center',
        margin: '10px',
    },
    subtitleCon: {
        background: '#ff000066',
        maxWidth: '333px',
        color: 'white !important',
        padding: '10px',
        borderRadius: '5px'
    },
    arrowMOve: {
        width: '35px',
        position: 'relative',
        top: '8px'

    },
    arrow: {
        width: '35px',
        position: 'relative',
        top: '8px'

    },
    Movto: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        gap: '10px'
    },
    textProj: {
        fontSize: '15px',
        margin: '0px'
    },
    buttonDel: {
        marginRight: '0px',
        marginBottom: '0px',
        marginTop: '20px',
        padding: '0px',
    },
    buttonCancel: {
        backgroundColor: '#ededed!important',
        color: '#3b3b3c',
        border: 'none !important',
    },
    afrText: {
        color: 'red',
        fontSize: '16px',
        paddingLeft: '24px',

    }

}));

const EditPreview: FC<any> = ({ performAction, editPayload, selectedIds, actionType, moveCase, status, commentPayload, sendAfrIds }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { projectName, projectID } = useParams();
    const [rowss, setRows] = useState<any>([]);
    const [casesByProj, setCasesByProj] = useState<any>([]);
    const [afrIds, SetAfrIds] = useState<any>([]);
    const [afrChk, SetAfrChk] = useState<any>(null);
    const [allOrganizations, SetAllOrganizations] = useState<any>([]);
    const [oldOrg, SetOldOrg] = useState<any>('');
    const [newOrg, SetNewOrg] = useState<any>('');

    const { description, isExternal, attachmentPath } = commentPayload;


    const [modalIsOpen, setIsOpen] = React.useState(false);

    const handleSubmit = async () => {
        try {
            // const response = await casesApi.updateBulkCases(payload);
            toast.success('Updated Successfully');
            navigate('/erp/cases');
        } catch (apiError) {
            if (apiError?.response?.status !== 401) {
                toast.error(apiError?.response?.data?.message || 'An error occurred');
            } else {
                toast.error('Unauthorized access. Please log in.');
            }
        }
    };

    const getCasesByProject = async () => {
        try {

            const varr = {
                // page,
                pageSize: '1000',
                hasAttachments: 'false',
                projectId: projectID,
            };
            const keys = Object.keys(varr);
            const values = Object.values(varr);

            // Create an array to store the non-empty key-value pairs
            const queryParams = [];
            for (let i = 0; i < values.length; i++) {
                if (values[i] !== '' && values[i] !== null && values[i] !== undefined) {
                    queryParams.push(`${encodeURIComponent(keys[i])}=${values[i]}`);
                }
            }
            /* eslint-disable no-use-before-define */
            const queryString = queryParams.join('&');
            // let response = await casesApi.getCasesByProjectId(projectID);
            let response = await casesApi.advanceSearch(queryString);
            response = response?.data;
            /* eslint-disable-next-line */
            const filteredData = response?.data.filter(item => selectedIds.includes(item._id));
            if (actionType === 'transition' && status.name === 'Declined') {
                SetAfrIds([]);
                const AFRID = filteredData
                    ?.filter(row => row.caseKey.includes("AFR"))
                    ?.map(row => row._id);
                SetAfrIds(AFRID);
                SetAfrChk(AFRID.length > 0);
            }

            setCasesByProj(filteredData);
        } catch (error) {
            if (error?.response?.status !== 401) {
                toast.error(error?.response?.data?.message);
                setCasesByProj([]);

            }
        }
    };


    useEffect(() => {
        const createRows = (data: Record<string, any>) => {
            const rows: { name: string; value: any }[] = [];

            Object.entries(data).forEach(([key, value]) => {
                if (key === 'caseIds') {
                    // Ignore caseIds
                    return;
                }

                if (Array.isArray(value)) {
                    if (value.length > 0 && typeof value[0] === 'object' && 'name' in value[0]) {
                        value.forEach((item) => {
                            rows.push({ name: key, value: item.name });
                        });
                        console.log('1');
                    } else {
                        console.log('2');/* eslint-disable-line */
                        rows.push({ name: key, value: value.join(', ') });/* eslint-disable-line */
                    }
                } else if (typeof value === 'object' && value !== null) {
                    if ('name' in value) {
                        rows.push({ name: key, value: (value as { name: string }).name });
                        console.log('3');
                    } else {
                        Object.entries(value).forEach(([subKey, subValue]) => {
                            rows.push({ name: `${key} (${subKey})`, value: subValue });
                        });
                        console.log('4');
                    }
                } else {
                    console.log('5');
                    rows.push({ name: key, value });
                }
            });

            return rows;
        };

        setRows(createRows(editPayload));
    }, [editPayload]);


    const convertSecondsToTimeString = (seconds) => {

        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);

        let result = '';
        if (hours > 0) {
            result += `${hours}h `;
        }
        if (minutes > 0) {
            result += `${minutes}m`;
        }

        return result.trim();
    };


    const openModal = () => {
        setIsOpen(true);
    }


    const closeModal = () => {
        setIsOpen(false);
    }



    const Done = () => {
        if (actionType === 'delete') {
            openModal()
        } else if (afrIds.length) {
            if (afrIds.length === selectedIds.length) {
                toast.error("Cannot proceed; all selected cases are approved");
            } else {
                sendAfrIds(afrIds);
                performAction('performAction');
            }
        } else {
            performAction('performAction');
        }
        // 
    };
    const delAction = () => {
        performAction('performAction');
    }
    const cancelAction = () => {
        closeModal()
    }

    const getAllOrganizations = async () => {
        const res = await casesApi.getAllOrganizations();
        if (moveCase?.old?.organizationId) {
            const oldOrgFilter = res.data.find(org => org.id === moveCase.old.organizationId);
            SetOldOrg(oldOrgFilter);
        }

        if (moveCase?.new?.organizationId) {
            const newOrgFilter = res.data.find(org => org.id === moveCase.new.organizationId);
            SetNewOrg(newOrgFilter);
        }
    };

    useEffect(() => {
        getCasesByProject();
    }, []);
    useEffect(() => {
        if (actionType === 'move' && Object.keys(moveCase).length > 0) {
            getAllOrganizations()
        }
    }, [moveCase]);

    return (
        <div className={classes.root}>
            <FormControl component="fieldset" className={classes.formControl}>
                <Box className='labelBox'>
                    <Typography variant="h6" className={classes.formLabel}>
                        Step 4 of 4: Confirmation
                    </Typography>
                </Box>
                <Divider className={classes.divider} />
                {/* actions preview table */}
                {actionType === 'edit' &&
                    <Box sx={{ margin: '20px', marginTop: '0px' }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Column Name</TableCell>
                                        <TableCell align="right">Column Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rowss.map((row) => (
                                        <TableRow
                                            key={row.value}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="right">{row.name}</TableCell>
                                            <TableCell align="right">
                                                {row.name === 'timeEstimate' ? convertSecondsToTimeString(row.value) : row.value}
                                            </TableCell>


                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                }
                {actionType === 'move' && moveCase && (
                    <Box>

                        <Box className={classes.Movto}>
                            <Typography variant="h6" className={classes.formLabel}>
                                {projectName}
                            </Typography>
                            <ArrowIcon className={classes.arrowMOve} />
                            <p className={classes.textProj}>
                                {moveCase?.project?.name || 'Default Project Name'}
                            </p>
                        </Box>

                        <Box className={classes.Movto}>
                            <Typography variant="h6" className={classes.formLabel}>
                                Fix version
                            </Typography>
                            <ArrowIcon className={classes.arrowMOve} />
                            <p className={classes.textProj}>
                                Null
                            </p>
                        </Box>

                        <Box className={classes.Movto}>
                            <Typography variant="h6" className={classes.formLabel}>
                                Sprint
                            </Typography>
                            <ArrowIcon className={classes.arrowMOve} />
                            <p className={classes.textProj}>
                                Null
                            </p>
                        </Box>

                        <Divider className={classes.divider} />

                        <Box className={classes.Movto}>
                            <Typography variant="h6" className={classes.formLabel}>
                                Organization
                            </Typography>
                        </Box>
                        <Box className={classes.Movto}>
                            <p className={classes.textLeft}>
                                {newOrg.name || 'N/A'}
                            </p>
                            <ArrowIcon className={classes.arrowMOve} />
                            <p className={classes.textProj}>
                                {oldOrg.name || 'N/A'}
                            </p>
                        </Box>
                        <Box className={classes.Movto}>
                            <Typography variant="h6" className={classes.formLabel}>
                                StakeHolder
                            </Typography>
                        </Box>
                        <Box className={classes.Movto}>
                            <p className={classes.textLeft}>
                                {moveCase?.old?.stakeHolder?.[0]?.name || 'N/A'}
                            </p>
                            <ArrowIcon className={classes.arrowMOve} />
                            <p className={classes.textProj}>
                                {moveCase?.new?.stakeHolder?.[0]?.name || 'N/A'}
                            </p>
                        </Box>

                        <Box className={classes.Movto}>
                            <Typography variant="h6" className={classes.formLabel}>
                                Escalation Manager
                            </Typography>
                        </Box>
                        <Box className={classes.Movto}>
                            <p className={classes.textLeft}>
                                {moveCase?.old?.escalationManagerName || 'N/A'}
                            </p>
                            <ArrowIcon className={classes.arrowMOve} />
                            <p className={classes.textProj}>
                                {moveCase?.new?.escalationManagerName || 'N/A'}
                            </p>
                        </Box>

                        <Box className={classes.Movto}>
                            <Typography variant="h6" className={classes.formLabel}>
                                Project Sponsor
                            </Typography>
                        </Box>
                        <Box className={classes.Movto}>
                            <p className={classes.textLeft}>
                                {moveCase?.old?.projectSponsor?.[0]?.name || 'N/A'}
                            </p>
                            <ArrowIcon className={classes.arrowMOve} />
                            <p className={classes.textProj}>
                                {moveCase?.new?.projectSponsor?.[0]?.name || 'N/A'}
                            </p>
                        </Box>

                        <Box className={classes.Movto}>
                            <Typography variant="h6" className={classes.formLabel}>
                                Project
                            </Typography>
                        </Box>
                        <Box className={classes.Movto}>
                            <p className={classes.textLeft}>
                                {moveCase?.old?.name || 'N/A'}
                            </p>
                            <ArrowIcon className={classes.arrowMOve} />
                            <p className={classes.textProj}>
                                {moveCase?.new?.name || 'N/A'}
                            </p>
                        </Box>

                        <Box sx={{ margin: '20px', marginTop: '20px' }}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Account Move From</TableCell>
                                            <TableCell align="right">Account Move To</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {moveCase?.account?.map((row, index) => (
                                            <TableRow
                                                key={row.accountMoveFrom.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>
                                                    {row.accountMoveFrom.name}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.accountMoveTo.name}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                )}

                {actionType === 'comment' && (
                    <Box className='commentSec'>
                        <Typography variant="h6" className={classes.formLabel}>
                            Comment
                        </Typography>
                        <Box sx={{ marginLeft: '24px' }}>
                            {/* Render the description HTML */}
                            <div dangerouslySetInnerHTML={{ __html: description }} />

                            {/* Display external attachment if isExternal is true */}
                            {isExternal && attachmentPath.length > 0 && (
                                <div>
                                    <h6>Attachments:</h6>
                                    {attachmentPath.map((path) => (
                                        <a href={path} target="_blank" rel="noopener noreferrer" key={path}>
                                            <img src={path} alt="Attachment" style={{ width: "100px", marginRight: "10px" }} />
                                        </a>
                                    ))}
                                </div>
                            )}
                        </Box>
                    </Box>
                )}

                {actionType === 'delete' &&
                    <Box className={classes.Movto}>
                        <Typography variant="h6" className={classes.formLabel}>
                            Delete Cases
                        </Typography>

                    </Box>
                }
                {actionType === 'transition' &&
                    <Box>

                        <Box className={classes.Movto}>
                            <Typography variant="h6" className={classes.formLabel}>
                                Change Status
                            </Typography>
                            <ArrowIcon className={classes.arrow} />
                            <p className={classes.textProj}>
                                {status.name}
                            </p>
                        </Box>

                        {afrChk === true &&
                            <p className={classes.afrText}>Highlighted approved cases cannot be declined.</p>
                        }
                    </Box>
                }

                <Divider className={classes.divider} />
                {/* list selected cases */}
                <Box sx={{ margin: '20px', marginTop: '0px' }}>
                    <ListSelectedCases data={casesByProj} status={status} />
                </Box>



            </FormControl>
            <Button variant="contained" className={classes.button} onClick={Done}>
                Confirm
            </Button>
            <Modal
                isOpen={modalIsOpen}

                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <Box className={classes.BoxCenter}>
                    <Typography variant="body2" color="textSecondary" >
                        Are you sure you want to delete these items?
                    </Typography>
                    {/* <div className={classes.confirmBG}>
                        <Typography variant="body2" color="textSecondary" className={classes.subtitleCon}>
                            This action cannot be undone.
                        </Typography>
                    </div> */}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                    <Button variant="contained" className={`${classes.button} ${classes.buttonDel}`} onClick={delAction}>
                        Yes
                    </Button>
                    <Button variant="contained" className={`${classes.button} ${classes.buttonDel} ${classes.buttonCancel}`} onClick={cancelAction}>
                        No
                    </Button>
                </Box>
            </Modal>

        </div >
    );
};

export default EditPreview;
