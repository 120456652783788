import { CssBaseline, ThemeProvider } from '@material-ui/core';
import type { FC } from 'react';
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useRoutes } from 'react-router-dom';
import RTL from './components/RTL';
import SplashScreen from './components/SplashScreen';
import { gtmConfig } from './config';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import './i18n';
// import gtm from './lib/gtm';
import routes from './routes';
import { createCustomTheme } from './theme';
import SimpleSnackBar from './components/widgets/snackBar/SimpleSnackBar';

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();

  useScrollReset();
  // const navigate = useNavigate();
  // let inactivityTimer;

  // Define a function to handle user inactivity and redirect
  // const handleInactivity = () => {
  //   clearTimeout(inactivityTimer);
  //   // Redirect to the login page
  //   localStorage.removeItem('accessToken');
  //   localStorage.removeItem('refreshToken');
  //   navigate('/erp/login');
  // };

  // Reset the inactivity timer on user activity
  // const resetInactivityTimer = () => {
  //   clearTimeout(inactivityTimer);
  //   inactivityTimer = setTimeout(handleInactivity, 30 * 60 * 1000); // 15 minutes
  // };
  useEffect(() => {
    // gtm.initialize(gtmConfig);
    // resetInactivityTimer();

    // Add event listeners to reset the timer on user activity
    // window.addEventListener('mousemove', resetInactivityTimer);
    // window.addEventListener('keydown', resetInactivityTimer);

    // Clean up the event listeners when the component unmounts
    // return () => {
      // window.removeEventListener('mousemove', resetInactivityTimer);
      // window.removeEventListener('keydown', resetInactivityTimer);
    // };
  }, []);

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  return (

    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <CssBaseline />
        <SimpleSnackBar />
        <Toaster
          position="top-center"
          toastOptions={{
            style: {
              textTransform: 'capitalize',
            }
          }}
        />
        {/* <SettingsDrawer /> */}
        {auth?.isInitialized ? content : <SplashScreen />}
      </RTL>
    </ThemeProvider>

  );
};

export default App;
