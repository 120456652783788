import { isUndefined } from 'lodash';
import axios from 'src/utils/axios';
import parse from 'src/utils/parse';

class DashboardApi {
  async getUpcomingMeetings(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_CASES}/dashboard/upcoming/meetings`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        } else {
          reject(new Error('internal Server Error'));
        }
      })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async getUpcomingEvents(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_CASES}/dashboard/upcoming/events`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        } else {
          reject(new Error('internal Server Error'));
        }
      })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async getCasesData(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_CASES}/dashboard/status/count`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getRecentCases(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_CASES}/dashboard/recent/cases`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getTotalOrganizationsData(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_CASES}/organization/records`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export const dashboardApi = new DashboardApi();
