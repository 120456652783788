import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  IconButton,
  Divider,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { leavesApi } from 'src/api/leavesApi';
import AddLeaveDialog from './AddLeaveDialog';
import EditLeaveDialog from './EditLeaveDialog';
import RemoveLeaveDialog from './RemoveLeaveDialog';
import moment from 'moment';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      padding: '10px',
      backgroundColor: '#fff',
      borderRadius: '4px',
      marginTop: '12px',
      marginBottom: '12px',
      '& h4': {
        fontSize: '14px',
        fontWeight: 600,
      },
      '& .leaveBreakdown': {
        marginTop: '10px',
      },
    },
    plannedLoggedRemaining: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '8px 0',
    },
    employeeName: {
      maxWidth: '140px',
      minWidth: '140px',
      fontSize: '12px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    iconButton: {
      padding: '5px',
    },
    text: {
      fontSize: '12px',
    },
    smallIcon: {
      fontSize: '16px',
    },
  })
);

interface LeaveData {
  id: string;
  user: {
    id: string;
    name: string;
  };
  leaveType: string;
  status: string;
  loggedTimeInHours: number;
  loggedDate: string;
}

interface LeaveManagementProps {
  caseKey: string;
  caseId: string;
  requestData: any; // Pass requestData from EditRequest component
}

const LeaveManagement: React.FC<LeaveManagementProps> = ({
  caseKey,
  caseId,
  requestData,
}) => {
  const classes = useStyles();
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [leaveToEdit, setLeaveToEdit] = useState<LeaveData | null>(null);
  const [leaveToRemove, setLeaveToRemove] = useState<string | null>(null);
  const [leaveData, setLeaveData] = useState<LeaveData[]>([]);

  const fetchLeaveData = async () => {
    try {
      if (caseKey) {
        const response = await leavesApi.getLeavesByCaseId(caseKey);
        if (response?.status === 200) {
          setLeaveData(response.data);
        }
      }
    } catch (error) {
      console.error('Error fetching leave data:', error);
    }
  };

  const handleAddDialogOpen = () => {
    setOpenAddDialog(true);
  };

  const handleAddDialogClose = () => {
    setOpenAddDialog(false);
  };

  const handleEditDialogOpen = (leave: LeaveData) => {
    setLeaveToEdit(leave);
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setLeaveToEdit(null); // Reset the state after dialog closes
    fetchLeaveData();
  };

  const handleRemoveDialogOpen = (leaveId: string) => {
    setLeaveToRemove(leaveId);
    setRemoveDialogOpen(true);
  };

  const handleRemoveDialogClose = () => {
    setRemoveDialogOpen(false);
    setLeaveToRemove(null);
  };

  useEffect(() => {
    fetchLeaveData();
  }, [caseId]);

  // Calculate the number of planned days from requestData's startDate and endDate
  const plannedLeaves =
    requestData?.startDate && requestData?.endDate
      ? moment(requestData.endDate).diff(
          moment(requestData.startDate),
          'days'
        ) + 1 // Add 1 to include the start date
      : 0;

  // Count all the leaves for the user who created the request
  const loggedLeaves = leaveData.filter(
    (leave) => leave.user.id === requestData?.createdBy?.id
  ).length;

  // Calculate remaining leaves as planned - logged
  const remainingLeaves = plannedLeaves - loggedLeaves;

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4" className={classes.text}>
          Leave Management
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleAddDialogOpen}
          style={{ fontSize: '12px', padding: '5px 10px' }}>
          Add Leave
        </Button>
      </Box>

      {/* Add Leave Dialog */}
      <AddLeaveDialog
        open={openAddDialog}
        onClose={handleAddDialogClose}
        caseKey={caseKey}
        caseId={caseId}
        fetchLeaveData={fetchLeaveData}
        requestData={requestData}
      />

      {/* Edit Leave Dialog */}
      <EditLeaveDialog
        open={editDialogOpen}
        onClose={handleEditDialogClose}
        leaveData={leaveToEdit}
        fetchLeaveData={fetchLeaveData}
      />

      {/* Remove Leave Dialog */}
      <RemoveLeaveDialog
        open={removeDialogOpen}
        onClose={handleRemoveDialogClose}
        leaveId={leaveToRemove}
        fetchLeaveData={fetchLeaveData}
      />

      {/* Planned, Logged, Remaining sections */}
      <Box className={classes.plannedLoggedRemaining}>
        <Typography className={classes.text}>Planned</Typography>
        <Typography className={classes.text}>{plannedLeaves} days</Typography>
      </Box>
      <Box className={classes.plannedLoggedRemaining}>
        <Typography className={classes.text}>Logged</Typography>
        <Typography className={classes.text}>{loggedLeaves} days</Typography>
      </Box>
      <Box className={classes.plannedLoggedRemaining}>
        <Typography className={classes.text}>Remaining</Typography>
        <Typography className={classes.text}>{remainingLeaves} days</Typography>
      </Box>
      {leaveData?.length > 0 && <Divider style={{ margin: '10px 0' }} />}

      {/* Leave Breakdown */}
      <Box className="leaveBreakdown">
        {leaveData?.length > 0 && (
          <Typography variant="h4" gutterBottom className={classes.text}>
            Breakdown
          </Typography>
        )}
        {leaveData.map((leave) => (
          <Box
            key={leave.id}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginBottom="8px">
            <Typography className={classes.employeeName}>
              {leave.user.name}
            </Typography>
            <Typography className={classes.text}>
              {moment(leave.loggedDate).format('ddd DD MMM, YYYY')}
            </Typography>
            <Box>
              <IconButton
                onClick={() => handleEditDialogOpen(leave)}
                className={classes.iconButton}>
                <EditIcon className={classes.smallIcon} />
              </IconButton>
              <IconButton
                onClick={() => handleRemoveDialogOpen(leave.id)}
                className={classes.iconButton}>
                <DeleteIcon className={classes.smallIcon} />
              </IconButton>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default LeaveManagement;
