import React, { useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Card,
} from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import useStyles from './useStyles'; // Import the custom styles

// Define types for the props
type Column = {
  id: string;
  label: string;
  render?: (row: any) => JSX.Element | string | number;
};

type TableComponentProps = {
  columns: Column[];
  rows: any[];
  showIcons?: boolean;
  isMonthlyData?: boolean; // Add a prop to toggle between monthly and overview data
  selectedLeaveTypes?: string[]; // Add a prop for selected leave types from filter
};

const OverviewTable: React.FC<TableComponentProps> = ({
  columns,
  rows,
  showIcons = true,
  isMonthlyData = false, // Default to false for overview data
  selectedLeaveTypes = [], // Receive selected leave types from filter
}) => {
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const classes = useStyles();

  const handleRowClick = (id: string) => {
    setExpandedRows((prev) =>
      prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
    );
  };

  const isRowExpanded = (id: string) => expandedRows.includes(id);

  const convertHoursToDays = (hours: number) => hours / 8;

  // Function to handle conditional rendering without nested ternaries
  const renderCell = (column: Column, row: any) => {
    if (column.render) {
      return column.render(row);
    }

    if (
      ['totalAllocated', 'totalConsumed', 'totalRemaining'].includes(column.id)
    ) {
      return convertHoursToDays(row[column.id]);
    }

    if (column.id.startsWith('month_')) {
      const monthIndex = parseInt(column.id.replace('month_', ''), 10);
      const monthData = row.months?.find(
        (month: any) => month.monthIndex === monthIndex
      );
      if (monthData) {
        return convertHoursToDays(
          monthData.paid +
            monthData.unpaid +
            monthData.medical +
            monthData.holidays
        );
      }
    }

    return row[column.id];
  };

  const renderExpandedRow = (row: any) => {
    const leaveTypes = [
      { type: 'Paid', dataKey: 'paid' },
      { type: 'Unpaid', dataKey: 'unpaid' },
      { type: 'Medical', dataKey: 'medical' },
      { type: 'Holidays', dataKey: 'holidays' },
    ];

    const filteredLeaveTypes =
      selectedLeaveTypes.length === 0
        ? leaveTypes
        : leaveTypes.filter((leaveType) =>
            selectedLeaveTypes.includes(leaveType.dataKey)
          );

    return filteredLeaveTypes.map((leaveType) => (
      <TableRow key={leaveType.type} className={classes.collapseRow}>
        <TableCell />
        <TableCell className={classes.leftAlignCell}>
          <strong>{leaveType.type}</strong>
        </TableCell>
        <TableCell align="center">
          {convertHoursToDays(row.allocatedLeaves?.[leaveType.dataKey])}
        </TableCell>
        <TableCell align="center">
          {convertHoursToDays(row.consumedLeaves?.[leaveType.dataKey])}
        </TableCell>
        <TableCell align="center">
          {convertHoursToDays(row.remainingLeaves?.[leaveType.dataKey])}
        </TableCell>

        {isMonthlyData &&
          row.months?.map((month: any) => (
            <TableCell align="center" key={month.monthIndex}>
              {convertHoursToDays(month[leaveType.dataKey])}
            </TableCell>
          ))}
      </TableRow>
    ));
  };

  React.useEffect(() => {
    if (rows?.length > 0) {
      const allRowIds = rows.map((row) => row.id || row.userName); // Map all row IDs or usernames
      setExpandedRows(allRowIds); // Set expandedRows to include all rows
    }
  }, [rows]); // Trigger the effect when the rows change

  return (
    <Box className={classes.tableContainer}>
      <Card className={classes.cardRoot}>
        <Box className={classes.scrollableContainer}>
          <Table>
            <TableHead className={classes.tableHead}>
              <TableRow>
                {showIcons && <TableCell />} {/* Empty cell for icons */}
                {columns?.map((column, idx) => (
                  <TableCell
                    className={idx === 0 && classes.leftAlignCell}
                    key={column.id}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.length > 0 &&
                rows?.map((row) => (
                  <React.Fragment key={row.id || row.userName}>
                    {/* Main Row */}
                    <TableRow
                      hover
                      className={classes.tableRow}
                      onClick={() => handleRowClick(row.id || row.userName)} // Click the entire row
                    >
                      {showIcons && (
                        <TableCell
                          className={classes.tableBodyCell}
                          onClick={(event) => event.stopPropagation()}>
                          <IconButton
                            className={classes.iconButton}
                            onClick={() =>
                              handleRowClick(row.id || row.userName)
                            }>
                            {isRowExpanded(row.id || row.userName) ? (
                              <KeyboardArrowUp />
                            ) : (
                              <KeyboardArrowDown />
                            )}
                          </IconButton>
                        </TableCell>
                      )}
                      {columns.map((column, idx) => (
                        <TableCell
                          key={column.id}
                          className={`${idx === 0 && classes.leftAlignCell} ${
                            classes.tableBodyCell
                          }`}>
                          {renderCell(column, row)}
                        </TableCell>
                      ))}
                    </TableRow>

                    {/* Expandable Rows */}
                    {isRowExpanded(row.id || row.userName) && (
                      <>{renderExpandedRow(row)}</>
                    )}
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </Box>
      </Card>
    </Box>
  );
};

export default OverviewTable;
