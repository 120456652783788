import { isUndefined } from 'lodash';
import axios from 'src/utils/axios';
import parse from 'src/utils/parse';


class OmsApi {

    async getOmsProductsAutoComplete(url: string): Promise<any> {
        return new Promise((resolve, reject) => {
            // const showLoader = true;
            axios
                .get(url)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }


    async getOmsClient(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_OMS_URL}/autoComplete/client`)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async getOmsProducts(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_OMS_URL}/autoComplete/product`)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async getAllOrders(args: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_OMS_URL}/order?${args}`)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async getAllQuotes(args: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_OMS_URL}/quote?${args}`)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async CreateOrder(payload: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .post(`${process.env.REACT_APP_OMS_URL}/order`, payload)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async CreateQuote(payload: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .post(`${process.env.REACT_APP_OMS_URL}/quote`, payload)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async EditOrder(id: any, payload: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${process.env.REACT_APP_OMS_URL}/order/${id}`, payload)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async EditQuote(id: any, payload: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${process.env.REACT_APP_OMS_URL}/quote/${id}`, payload)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }



}

export const omsApi = new OmsApi();