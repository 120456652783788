import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
// import myData from '../../../__fakeApi__/tempdata';
import { useEffect, useState } from 'react';
import { okrApi } from 'src/api/okrApi';
import toast from 'react-hot-toast';
import BarChart from 'src/components/barChart/Bar';

const useStyles = makeStyles<Theme>(
  () =>
    createStyles({
      btn: {
        padding: 0,
      },
      graphBox: {
        padding: 16,
        marginTop: '10px',
        background: 'white',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
      },
    }),
  {
    name: 'ProjectGraph',
  }
);
function ProjectGraph(props: any) {
  const classes = useStyles();
  const [departmentData, setDepartmentData] = useState({});
  console.log(props, classes, departmentData);

  const keys = [
    { key: 'To Do', title: 'Total Assigned', color: '#4285f4' },
    { key: 'Done', title: 'Done', color: '#34a853' },
    { key: 'Pending', title: 'Pending', color: '#ea4335' },
    { key: 'In Progress', title: 'In Progress', color: '#fbbc04' },
  ];
  // const keys = [
  //   { key: 'totalCount', title: 'Total', color: '#4285f4' },
  //   { key: 'Done', title: 'Done', color: '#34a853' },
  //   { key: 'In Progress', title: 'In Progress', color: '#fbbc04' },
  //   { key: 'Pending', title: 'Pending', color: '#ea4335' },
  // ];
  // function parseData(jsonData: any, graphKeys: any)  {
  //   const datasets  = [];
  //   const labels = [];
  //   // eslint-disable-next-line no-restricted-syntax, guard-for-in
  //   for (const key in jsonData) {
  //     const keyData = graphKeys.filter((x) => x.key === key)[0];
  //     if (keyData) {
  //       labels.push(keyData.title);
  //       datasets.push({
  //         label: keyData.title,
  //         data: [jsonData[key]],
  //         backgroundColor: keyData.color,
  //       });
  //     }
  //   }
  //   return { labels , datasets};
  // }
  function groupDataByDepartment(data: any[]) {
    const labels = [];
    let datasets = [];
   data.forEach((item) => {
      const values  = keys.map((v,index) => ({
        label: v.title,
        // data: [item[v.key]],
        data: datasets.length ? [...datasets[index].data , item[v.key]] : [item[v.key]],
        backgroundColor: v.color,
      }));



      labels.push(item.name);
      datasets = values;
    });
    return { labels , datasets };
  }

  const getDepartmentData = async () => {
    // const groupedData = groupDataByDepartment(myData.PROJECTSUMMARY);
    // setDepartmentData(groupedData);
    // console.log(groupedData);

    try {
      const response = await okrApi.getProjectWiseSummary();
      const groupedData = groupDataByDepartment(response.data);
      setDepartmentData(groupedData);
    } catch (error) {
      toast.error(error.message);
    }
  };
  useEffect(() => {
    getDepartmentData();
  }, []);
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
      }}
      className={classes.table}>
      <Grid container spacing={2} className={classes.root}>
        <Grid item lg={12}>
          <Box className={classes.graphBox}>
            <h1
              style={{
                color: '#000',
                margin: 0,
                fontSize: 20,
                fontWeight: 700,
                lineHeight: '116.7%',
              }}>
              Projects Wise Summary
            </h1>
            {Object.keys(departmentData).length  ? (
              <BarChart data={departmentData || {}} showLegend />
            ) : null}

          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ProjectGraph;
