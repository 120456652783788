import React from 'react';
import { Container, Typography } from '@mui/material';
import { Theme, makeStyles } from '@material-ui/core/styles';
import GlobalLeavesQuota from './GlobalLeavesQuota';

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
}));

const LeavesQuota: React.FC = () => {
  const classes = useStyles();

  return (
    <Container maxWidth={false}>
      <Typography variant="h3" gutterBottom className={classes.heading}>
        Leaves Quota
      </Typography>
      <GlobalLeavesQuota />
    </Container>
  );
};

export default LeavesQuota;
