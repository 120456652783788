import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Grid, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FC, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { notesApi } from 'src/api/notesApi';
import toast from 'react-hot-toast';

const NoteCard:FC<any> = ({ notesData, setIsUpdate, isUpdate }:any) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [noteTitle, setNoteTitle] = useState(notesData.title);
  const [description, setDescription] = useState(notesData.text);
  const [expanded, setExpanded] = useState(false);
  const handleDelete = async (e) => {
    if (e.target.value === 'Delete') {
      try {
        const response = await notesApi.deleteNote(notesData.id);
        if (response?.status === 200) {
          toast.success('Note Deleted');
          setIsUpdate(!isUpdate);
        }
      } catch (error) {
        if (error?.response?.status === 409) {
          toast.error(`${error?.response?.data?.message}`);
        } else {
          toast.error('Internal Server Error');
        }
      }
    }
  };

  const handleUpdate = async () => {
    const payload = {
      title: noteTitle,
      text: description,
    };
    setIsSubmitting(true);
    try {
      const response = await notesApi.updateNote(notesData.id, payload);
      if (response?.status === 200) {
        setIsSubmitting(false);
        setIsUpdate(!isUpdate);
        setExpanded(false);
        toast.success('Note Updated');
      }
    } catch (error) {
      toast.success(`${error?.message}`);
    }
  };
  return (
    <Accordion
      className="addMeetingCard"
      expanded={expanded}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className="accordionSummary"
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={() => setExpanded(!expanded)}
      >
        <div className="meetingCardTitle&Desc">
          <Typography sx={{ fontWeight: 'bold' }}>{notesData?.title}</Typography>
          {/* eslint-disable-next-line react/no-danger */}
          <Typography dangerouslySetInnerHTML={{ __html: notesData?.text }} />
        </div>
        <div className="noteCardActions">
          <Select
            labelId="outcomeLabel"
            id="select"
            value=" "
            variant="standard"
            disableUnderline
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => handleDelete(e)}
          >
            <MenuItem value=" ">Actions</MenuItem>
            <MenuItem value="Delete">Delete</MenuItem>
          </Select>
          <Typography className="meetingCardDate">
            {` ${new Date(notesData?.createdAt).toLocaleString()} `}
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            md={12}
          >
            <Typography>Title</Typography>
            <TextField
              placeholder="Enter Title"
              fullWidth
              inputProps={{ maxLength: 225 }}
              value={noteTitle}
              onChange={(e) => setNoteTitle(e.target.value)}
            />
          </Grid>
          <Grid
            item
            md={12}
          >
            <div className="ckEditor">
              <Typography>
                Description
              </Typography>
              <CKEditor
                editor={ClassicEditor}
                data={description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setDescription(data);
                }}
              />
            </div>
          </Grid>
        </Grid>

        {isSubmitting ? (
          <div className="loadingCircle">
            <CircularProgress />
          </div>
        ) : (
          <Button
            className="createButton"
            variant="contained"
            color="secondary"
            onClick={handleUpdate}
          >
            Update
          </Button>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default NoteCard;
