export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE',
};

export const statusTabs: { label: string; value: string }[] = [
  {
    label: 'ALL',
    value: '',
  },
  {
    label: 'ACTIVE',
    value: 'active',
  },
  {
    label: 'INACTIVE',
    value: 'inActive',
  },
];

export const borderColors = {
  'ticket-stale': '#72ddf7',
  'case-created': '#56cfe1',
  case: '#48bfe3',
  'case-type-updated': '#a3cef1',
  comment: '#274c77',
  'added-comment': '#6096ba',
  'summary-updated': '#40916c',
  'assignee-updated': '#2d6a4f',
  'escalated-case': '#7f4f24',
  'escalation-manager-updated': '#31572c',
  'priority-updated': '#b5838d',
  'status-updated': '#6d6875',
  'project-sponsor-updated': '#7796cb',
  'description-updated': '#8a5a44',
  'severity-updated': '#e76f51',
  'resolved-time-violated!': '#bc4749',
  'resolve-time-violated!': '#fcaf58',
  'response-time-violated!': '#a1c181',
  'stake-holder-updated': '#656d4a',
  'first-response-time-violated!': '#adc178',
  'case-comment-successfully-deleted': '#f4f0bb',
  'escalation-manager-assigned': '#f4aba0',
}

export const pimCatalogData = [
  { name: 'Red Hat - Traditional', id: 'redhat-traditional' }
]

interface FilterData {
  name: string;
  data: string[] | null;
}

interface FilterState {
  search: FilterData | null;
  caseType: FilterData | null;
  status: FilterData | null;
  priority: FilterData | null;
  project: FilterData | null;
  organization: FilterData | null;
  escalationManager: FilterData | null;
  stakeholder: FilterData | null;
  projectSponser: FilterData | null;
  assignee: FilterData | null;
  date: FilterData | null;
  thirdPartySync: FilterData | null;
  escalatedCases: FilterData | null;
  hasAttachments: FilterData | null;
  isFirstResponseTimeViolated: FilterData | null;
  isResolveTimeViolated: FilterData | null;
}
export const initialFilterState: FilterState = {
  search: null,
  caseType: null,
  status: null,
  priority: null,
  project: null,
  organization: null,
  escalationManager: null,
  stakeholder: null,
  projectSponser: null,
  assignee: null,
  date: null,
  thirdPartySync: null,
  escalatedCases: null,
  hasAttachments: null,
  isFirstResponseTimeViolated: null,
  isResolveTimeViolated: null,
};


export const caseSortData = {
  id: JSON.parse(localStorage.getItem('casesPagePg'))?.casesSortId || 'asc',
  caseName:
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesSortCaseName ||
    'asc',
  organization:
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesSortOrganization ||
    'asc',
  createdBy:
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesSortCreatedBy ||
    'asc',
  assignee:
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesSortAssignee ||
    'asc',
  caseType:
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesSortCaseType ||
    'asc',
  project:
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesSortProject ||
    'asc',
  resolveTime:
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesSortResolveTime ||
    'asc',
  firstResponseTime:
    JSON.parse(localStorage.getItem('casesPagePg'))
      ?.casesSortFirstResponseTime || 'asc',
  priority:
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesSortPriority ||
    'asc',
  status:
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesSortStatus || 'asc',
}
export const filtersAppliedData = {
  search: JSON.parse(localStorage.getItem('casesPagePg'))?.casesSearch
    ? {
      name: 'Search',
      data: JSON.parse(localStorage.getItem('casesPagePg'))?.casesSearch,
    }
    : null,
  caseType:
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesCaseType?.length !==
      0
      ? {
        name: 'Case Type',
        data: JSON.parse(
          localStorage.getItem('casesPagePg')
        )?.casesCaseType?.map((obj) => obj?.name),
      }
      : null,
  status:
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesStatus?.length !== 0
      ? {
        name: 'Status',
        data: JSON.parse(
          localStorage.getItem('casesPagePg')
        )?.casesStatus?.map((obj) => obj?.name),
      }
      : null,
  priority:
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesPriority?.length !==
      0
      ? {
        name: 'Priority',
        data: JSON.parse(
          localStorage.getItem('casesPagePg')
        )?.casesPriority?.map((obj) => obj?.name),
      }
      : null,
  project:
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesProject?.length !==
      0
      ? {
        name: 'Project',
        data: JSON.parse(
          localStorage.getItem('casesPagePg')
        )?.casesProject?.map((obj) => obj?.name),
      }
      : null,
  organization:
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesOrganization
      ?.length !== 0
      ? {
        name: 'Organization',
        data: JSON.parse(
          localStorage.getItem('casesPagePg')
        )?.casesOrganization?.map((obj) => obj?.name),
      }
      : null,
  escalationManager:
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesEscalationManager
      ?.length !== 0
      ? {
        name: 'Escalation Manager',
        data: JSON.parse(
          localStorage.getItem('casesPagePg')
        )?.casesEscalationManager?.map((obj) => obj?.name),
      }
      : null,
  stakeholder:
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesStakeholder
      ?.length !== 0
      ? {
        name: 'Stakeholder',
        data: JSON.parse(
          localStorage.getItem('casesPagePg')
        )?.casesStakeholder?.map((obj) => obj?.name),
      }
      : null,
  projectSponser:
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesProjectSponser
      ?.length !== 0
      ? {
        name: 'Project Sponsor',
        data: JSON.parse(
          localStorage.getItem('casesPagePg')
        )?.casesProjectSponser?.map((obj) => obj?.name),
      }
      : null,
  assignee:
    JSON.parse(localStorage.getItem('casesPagePg'))?.casesAssignee?.length !==
      0
      ? {
        name: 'Assignee',
        data: JSON.parse(
          localStorage.getItem('casesPagePg')
        )?.casesAssignee?.map((obj) => obj?.name),
      }
      : null,
  date: JSON.parse(localStorage.getItem('casesPagePg'))?.casesDate?.to
    ? {
      name: 'Date',
      data: [
        JSON.parse(localStorage.getItem('casesPagePg'))?.casesDate?.from,
        JSON.parse(localStorage.getItem('casesPagePg'))?.casesDate?.to,
      ],
    }
    : null,
  thirdPartySync: JSON.parse(localStorage.getItem('casesPagePg'))
    ?.casesIsSynced
    ? {
      name: 'Third Party Synced',
      data: 'True',
    }
    : null,
  escalatedCases: JSON.parse(localStorage.getItem('casesPagePg'))
    ?.casesIsEscalated
    ? {
      name: 'Escalated',
      data: 'True',
    }
    : null,
  hasAttachments: JSON.parse(localStorage.getItem('casesPagePg'))
    ?.casesHasAttachments
    ? {
      name: 'Attachments',
      data: 'True',
    }
    : null,
  isFirstResponseTimeViolated: JSON.parse(localStorage.getItem('casesPagePg'))
    ?.casesIsFirstResponseTimeViolated
    ? {
      name: 'First Response Time Violated',
      data: 'True',
    }
    : null,
  isResolveTimeViolated: JSON.parse(localStorage.getItem('casesPagePg'))
    ?.casesIsResolvedTimeViolated
    ? {
      name: 'Resolve Time Violated',
      data: 'True',
    }
    : null,
}


export const casePermissions = [
  'case-get-all',
  'case-filter-tabs-read',
  'case-filter-advanced-search-read',
  'case-filter-autorefresh-read',
  'case-create',
  'case-toggle-status',
  'case-delete',
  'case-bulk-deactivate',
  'case-get-by-id',
  'case-update',
]