import { InputAdornment, TextField } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import type { FC } from 'react';
import { useEffect, useState, } from 'react';

interface SearchBarProps {
  onSearch?: (query: string) => void;
  placeholderData?: string;
  margins?: string | number;
  value?: string;
  anyState?: any;
}
const useStyles = makeStyles<Theme>(() => createStyles({
  root: {
    '& .MuiTextField-root': {
      width: 'initial'
    }
  },
}));
const SearchBar: FC<SearchBarProps> = ({ onSearch, placeholderData, margins, value, anyState }) => {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState(value || '');
  placeholderData = placeholderData || 'Search';

  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);
    // Call the API with the search query
  };

  useEffect(() => {
    setSearchQuery(value);
  }, [value]);

  return (
    <div className={classes.root}>
      <TextField
        variant="outlined"
        placeholder={`${placeholderData}`}
        value={searchQuery}
        onChange={handleSearchChange}
        disabled={anyState || false}
        onKeyUp={(e) => e.key === 'Enter' && onSearch(searchQuery.trim())}
        InputProps={{
          style: {
            height: '30px',
            fontSize: '14px',
            width: '246.5px',
            margin: margins,
          },
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default SearchBar;
