import React, { FC, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Formik, Form, Field } from 'formik';
import { FormControl, FormGroup, Box, Divider, RadioGroup } from '@material-ui/core';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FileUploadDragDrop from 'src/components/widgets/fileUpload/FileUploadDragDrop';
import { userApi } from 'src/api/userApi';
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';
import { casesApi } from 'src/api/casesApi';




const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
        borderRadius: theme.shape.borderRadius,
        margin: '30px',
        '& .MuiFormControlLabel-label': {
            fontSize: '15px !important',
        },
        '& .mainForBox': {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
        },
        '& .NextBtn': {
            display: 'flex',
            justifyContent: 'end'
        }
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    subtitle: {
        marginLeft: theme.spacing(3),
    },
    formControl: {
        width: '100%',
    },
    button: {
        backgroundColor: '#c22027',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#d43f40',
        },
        alignSelf: 'flex-end',
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    formLabel: {
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(3),
        marginTop: theme.spacing(3),
        fontWeight: 600,
        fontSize: 14,
    },
}));

const CommentCase: FC<any> = ({ selectedIds, commentCase }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const commentRef = useRef(null);
    const [filePath, setFilePath] = useState<string[]>([]);

    function uploadAdapter(loader) {
        return {
            upload: () =>
                new Promise((resolve) => {
                    loader.file.then(async (file) => {
                        const formData = new FormData();
                        formData.append('file', file);
                        const image = await userApi.uploadAttachments(formData);
                        resolve({
                            default: `${image?.data?.blob_url}`,
                        });
                    });
                }),
        };
    }
    function uploadPlugin(editor) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
            uploadAdapter(loader);
    }

    const handleSubmit = (values: any) => {

        if (!values.comment) {
            toast.error('Comment cannot be empty');
            return;
        }

        const payload = {
            description: values.comment,
            isExternal: values.selectedAction === 'External',
            attachmentPath: filePath,
        };
        commentCase(payload)

    };


    return (
        <div className={classes.root}>
            <Typography variant="h6" className={classes.formLabel}>
                Step 3 of 4: Add Comment
            </Typography>
            <Typography
                variant="body2"
                color="textSecondary"
                className={classes.subtitle}>
                Choose which action you&apos;d like to take on the selected issues.
            </Typography>
            <Divider className={classes.divider} />
            <Formik
                initialValues={{
                    selectedAction: 'External',
                    comment: '',
                }}
                onSubmit={handleSubmit}
            >
                {({ values, handleChange, setFieldValue }) => (
                    <Box className='mainForBox'>
                        <Form>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormGroup>
                                    <RadioGroup
                                        sx={{ marginBottom: '10px' }}
                                        aria-label="actions"
                                        name="selectedAction"
                                        value={values.selectedAction}
                                        onChange={handleChange}
                                        row // To display the radio buttons in a row
                                    >
                                        <FormControlLabel
                                            value="External"
                                            control={<Field as={Radio} />}
                                            label="External"
                                        />
                                        <FormControlLabel
                                            value="Internal"
                                            control={<Field as={Radio} />}
                                            label="Internal"
                                        />
                                    </RadioGroup>
                                    <CKEditor
                                        mt={3}
                                        editor={ClassicEditor}
                                        data={values.comment}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setFieldValue('comment', data);
                                        }}
                                        config={{
                                            placeholder: 'Enter your comment here',
                                            toolbar: [
                                                'heading',
                                                '|',
                                                'bold',
                                                'italic',
                                                'link',
                                                'bulletedList',
                                                'numberedList',
                                                'blockQuote',
                                                'uploadimage',
                                            ],
                                            extraPlugins: [uploadPlugin],
                                        }}
                                    />
                                    <FileUploadDragDrop setFilePath={setFilePath} filePath={filePath} />
                                </FormGroup>
                            </FormControl>
                            {/* sx={{ display: 'flex', justifyContent: 'end' }} */}
                            <Box mt={3} className="NextBtn">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    className={classes.button}
                                >
                                    Next
                                </Button>
                            </Box>
                        </Form>
                    </Box>
                )}
            </Formik>
        </div >
    );
};

export default CommentCase;
