import { isUndefined } from 'lodash';
import { getRequest, patchRequest, postRequest, updateRequest } from 'src/utils/axios';
import parse from 'src/utils/parse';
import logger from 'src/utils/logger';
import { ModuleUpdateByIdPayloadTypes } from 'src/types/settings/modules/edit.types';

class ModuleApi {
  async create(payload:any):Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_PERMISSIONS}/module`,
        payload,
        successFunction,
        errorFunction,
      };
      postRequest(data);
    });
  }

  async getAll(
    page: number | null,
    pageSize: number | null,
    search:string,
    isActive:boolean | null,
    columns?: any,
    sortVal?: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const payloadParams = {
        page,
        pageSize,
        search,
        isActive,
        column: columns,
        sort: sortVal === 'asc' ? '1' : '-1'
      };
      if (search === '') {
        delete payloadParams.search;
      }
      if (columns === '' || columns === undefined) {
        delete payloadParams.column;
        delete payloadParams.sort;
      }
      if (sortVal === '') {
        delete payloadParams.sort;
      }
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_PERMISSIONS}/module`,
        successFunction,
        errorFunction,
        payloadParams
      };
      getRequest(data);
    });
  }

  async getById(
    id:string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_PERMISSIONS}/module/${id}`,
        successFunction,
        errorFunction,
      };
      getRequest(data);
    });
  }

  async autoComplete(search:string):Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        reject(error);
      };
      let queryParams = '';
      if (search && search !== '' && search !== null && search !== undefined) {
        queryParams = `?search=${search}`;
      }
      const data = {
        url: `${process.env.REACT_APP_PERMISSIONS}/module/auto-complete${queryParams}`,
        successFunction,
        errorFunction
      };
      getRequest(data);
    });
  }

  async updateStatusById(id:string):Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_PERMISSIONS}/module/${id}/toggle`,
        successFunction,
        errorFunction
      };
      patchRequest(data);
    });
  }

  async updateById(id:string, payload:ModuleUpdateByIdPayloadTypes):Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_PERMISSIONS}/module/${id}`,
        payload,
        successFunction,
        errorFunction
      };
      updateRequest(data);
    });
  }

  async getAllActiveModules():Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_PERMISSIONS}/module/active`,
        successFunction,
        errorFunction
      };
      getRequest(data);
    });
  }

  // async deleteById(id:string):Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     const successFunction = (response) => {
  //       response = parse(response);
  //       if (!isUndefined(response?.data)) {
  //         resolve(response);
  //       }
  //     };
  //     const errorFunction = (error) => {
  //       logger(error, 'error');
  //       reject(error);
  //     };
  //     const data = {
  //       url: `${process.env.REACT_APP_PERMISSIONS}/module/${id}`,
  //       successFunction,
  //       errorFunction
  //     };
  //     deleteRequest(data);
  //   });
  // }
}

export const moduleApi = new ModuleApi();
