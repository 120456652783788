import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { isUndefined } from 'lodash';
import { KeycloakLogin } from 'src/types/keycloak_login';
import { User } from 'src/types/user';
import parse from 'src/utils/parse';
import { userApi } from './userApi';
import logger from 'src/utils/logger';

class AuthApi {
  async login({ email, password }): Promise<KeycloakLogin> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_CASES}/user/login`, {
          username: email,
          password,
        })
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const data = response?.data;
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async register(): Promise<void> {
    logger('register');
  }

  me(accessToken, permissions = []): Promise<User> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        // Decode access token
        const decoded: any = jwtDecode(accessToken);
        const userByKeyCloak = await userApi.getByKeycloakId();
        if (!decoded) {
          reject(new Error('Invalid authorization token'));
          return;
        }
        if (userByKeyCloak) {
          resolve({
            id: userByKeyCloak?.id,
            user_id: decoded?.sub,
            name: `${userByKeyCloak.firstName} ${userByKeyCloak.lastName}`,
            email: decoded.email,
            image: userByKeyCloak.image,
            userKafkaTopic: userByKeyCloak?.userKafkaTopic,
            notifications: userByKeyCloak?.notifications?.list,
            role: userByKeyCloak?.role,
            permissions
          });
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  async refreshSession(refreshToken): Promise<KeycloakLogin> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.REACT_APP_CASES}/user/refresh-token`,
          {
            refreshToken,
          }
        )
        .then((response) => {
          const res: any = parse(response);
          if (!isUndefined(res?.data?.refreshToken)) {
            localStorage.setItem('accessToken', res?.data?.accessToken);
            localStorage.setItem('refreshToken', res?.data?.refreshToken);
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export const authApi = new AuthApi();
