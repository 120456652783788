import { Autocomplete, Box, Button, Checkbox, FormControlLabel, InputLabel, TextField, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { moduleApi } from 'src/api/modulesApi';
import { permissionsApi } from 'src/api/permissions';
import SimpleBreadCrumb from 'src/components/widgets/BreadCrumb/SimpleBreadCrumb';
import logger from 'src/utils/logger';
import * as Yup from 'yup';
import useAuth from 'src/hooks/useAuth';
import setTitle from 'src/utils/setTitle';

const useStyles = makeStyles<Theme>(() => createStyles({
  paper: {
    backgroundColor: '#fff',
    padding: '10px 80px 30px 80px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
  },
  fieldsRoot: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '238px',
    gridRowGap: '16px',

    '& .item': {
      '& .MuiInputLabel-root': {
        fontSize: '14px',
        fontWeight: 600,
        color: '#231f20',
      },
    }
  },
  checkboxRoot: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    rowGap: '16px',

    '& .MuiFormControlLabel-root': {
      minWidth: '240px',
    },
    '& .MuiTypography-body1': {
      fontSize: '14px',
      fontWeight: 400,
      color: '231f20',
    },
    '& .MuiSvgIcon-root': {
      width: '16px',
      height: '16px',
    },
    '& .flexGrow': {
      flexGrow: 1,
    }
  },
  btnRoot: {
    display: 'flex',
    marginTop: '30px',
    gap: '9px',
    // flexDirection: 'row-reverse',
  }
}));
const PermissionNew = () => {
  setTitle('ERP | Permissions - Add');
  const baseUrl = '/erp/settings';
  const { user } = useAuth();
  const navigate = useNavigate();
  const classes = useStyles();
  const BreadCrumbsData = {
    title: '',
    links: [
      {
        name: 'permissions',
        to: `${baseUrl}/permissions`
      },
      {
        name: 'add',
        to: ''
      }
    ]
  };

  useEffect(() => {
    if (
      !user?.role?.some((roleObj) => roleObj.identifier === 'it22_admin')
    ) {
      window.location.href = '/admin/not-found';
    }
  }, []);

  const schema = Yup.object({
    identifier: Yup.string().trim().required('Identifier required').test(
      'no-spaces-between-words',
      'Use \'-\' between words for identifiers',
      (value) => !/\s/.test(value)
    ),
    name: Yup.string().trim().required('Permission name required'),
    moduleId: Yup.string().trim().required('Module required'),
    subModuleId: Yup.string().trim().required('Sub Module required'),
  });

  type ModuleValueType = {
    id: string;
    name: string;
  };
  const [moduleValue, setModuleValue] = useState<ModuleValueType>({ id: '', name: 'Select Module' });
  const [moduleInputValue, setModuleInputValue] = useState('');
  const [allModules, setAllModules] = useState<any>([]);

  type SubModuleValueType = {
    id: string;
    name: string;
  };
  const [subModuleValue, setSubModuleValue] = useState<SubModuleValueType>({ id: '', name: 'Select Sub-Module' });
  const [subModuleInputValue, setSubModuleInputValue] = useState('');

  const getAllModules = async (search: string = '') => {
    try {
      const res = await moduleApi.autoComplete(search);
      setAllModules(res?.data);
    } catch (error) {
      if (error?.response?.status !== 401) {
        setAllModules([]);
        toast.error(error?.response?.data?.message);
      }
    }
  };
  useEffect(() => {
    getAllModules();
  }, []);
  return (
    <>
      <SimpleBreadCrumb data={BreadCrumbsData} />

      <Formik
        initialValues={{
          identifier: '',
          name: '',
          moduleId: '',
          subModuleId: '',
          isRead: false,
          isCreate: false,
          isUpdate: false,
          isDelete: false,
          isToggleStatus: false,
          isGetAll: false,
          isGetById: false,
          isBulkDeactivate: false,
        }}
        validationSchema={schema}
        onSubmit={async (values) => {
          try {
            logger(values);

            const capitalizeFunction = (str) => {
              const arr = str.split(' ');
              for (let i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0)?.toUpperCase() + arr[i].slice(1);
              }
              const capitalizedString = arr.join(' ');
              return capitalizedString;
            };

            const permissions = [
              values?.isRead && {
                identifier: `${values.identifier}-read`,
                name: capitalizeFunction(values.name),
                description: capitalizeFunction(values.name),
                moduleId: values?.moduleId ? values?.moduleId : '',
                subModuleId: values?.subModuleId ? values?.subModuleId : ''
              },
              values?.isCreate && {
                identifier: `${values.identifier}-create`,
                name: capitalizeFunction(values.name),
                description: capitalizeFunction(values.name),
                moduleId: values?.moduleId ? values?.moduleId : '',
                subModuleId: values?.subModuleId ? values?.subModuleId : ''
              },
              values?.isUpdate && {
                identifier: `${values.identifier}-update`,
                name: capitalizeFunction(values.name),
                description: capitalizeFunction(values.name),
                moduleId: values?.moduleId ? values?.moduleId : '',
                subModuleId: values?.subModuleId ? values?.subModuleId : ''
              },
              values?.isDelete && {
                identifier: `${values.identifier}-delete`,
                name: capitalizeFunction(values.name),
                description: capitalizeFunction(values.name),
                moduleId: values?.moduleId ? values?.moduleId : '',
                subModuleId: values?.subModuleId ? values?.subModuleId : ''
              },
              values?.isToggleStatus && {
                identifier: `${values.identifier}-toggle-status`,
                name: capitalizeFunction(values.name),
                description: capitalizeFunction(values.name),
                moduleId: values?.moduleId ? values?.moduleId : '',
                subModuleId: values?.subModuleId ? values?.subModuleId : ''
              },
              values?.isGetAll && {
                identifier: `${values.identifier}-get-all`,
                name: capitalizeFunction(values.name),
                description: capitalizeFunction(values.name),
                moduleId: values?.moduleId ? values?.moduleId : '',
                subModuleId: values?.subModuleId ? values?.subModuleId : ''
              },
              values?.isGetById && {
                identifier: `${values.identifier}-get-by-id`,
                name: capitalizeFunction(values.name),
                description: capitalizeFunction(values.name),
                moduleId: values?.moduleId ? values?.moduleId : '',
                subModuleId: values?.subModuleId ? values?.subModuleId : ''
              },
              values?.isBulkDeactivate && {
                identifier: `${values.identifier}-bulk-deactivate`,
                name: capitalizeFunction(values.name),
                description: capitalizeFunction(values.name),
                moduleId: values?.moduleId ? values?.moduleId : '',
                subModuleId: values?.subModuleId ? values?.subModuleId : ''
              },
            ];
            const filteredPermissions = permissions.filter(Boolean);
            if (filteredPermissions.length === 0) { toast.error('Please select a permission'); } else {
              const res = await permissionsApi.create(filteredPermissions);
              if (res?.status === 201) {
                toast.success('Permission Created Successfully');
                navigate(`${baseUrl}/permissions`);
              } else {
                toast.error('Internal Server Error');
              }
            }
          } catch (error) {
            logger(error, 'error');
            if (error?.response?.status === 401) {
              toast.error('Unauthorized User');
            } else {
              toast.error('Internal Server Error');
            }
          }
        }}
      >
        {({ values, handleChange, handleBlur, setFieldValue, handleSubmit, touched, errors }) => (
          <form onSubmit={handleSubmit}>
            <Typography
              variant="h3"
              style={{ marginTop: '16px', marginBottom: '10px', fontSize: '20px', fontWeight: '700', color: '#000', }}
            >
              Add Permission
            </Typography>
            <Box className={`${classes.paper} ${classes.fieldsRoot}`}>
              <Box className="item">
                <InputLabel htmlFor="identifier-simple">Identifier*</InputLabel>
                <TextField
                  id="identifier-simple"
                  fullWidth
                  placeholder="Identifier"
                  size="small"
                  name="identifier"
                  value={values.identifier}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched?.identifier && errors?.identifier)}
                  helperText={touched?.identifier && errors?.identifier}
                />
              </Box>
              <Box className="item">
                <InputLabel htmlFor="permission-name-simple">Permission Name*</InputLabel>
                <TextField
                  id="permission-name-simple"
                  fullWidth
                  placeholder="Permission Name"
                  size="small"
                  name="name"
                  value={values.name}
                  onChange={(e) => {
                    let str = e.target.value;
                    str = str.charAt(0)?.toUpperCase() + str.slice(1);
                    str = str.replaceAll('-', ' ');
                    logger(str, '', 'str---value: ');
                    setFieldValue('name', str);
                  }}
                  onBlur={handleBlur}
                  error={Boolean(touched?.name && errors?.name)}
                  helperText={touched?.name && errors?.name}
                />
              </Box>
              <Box className="item">
                <InputLabel htmlFor="module-simple">Module*</InputLabel>
                <Autocomplete
                  id="module-simple"
                  fullWidth
                  placeholder="Module"
                  size="small"
                  options={allModules.sort((a, b) => a.name.localeCompare(b.name))}
                  getOptionLabel={(option: any) => option?.name}
                  inputValue={moduleInputValue}
                  onInputChange={(event, newInputValue) => {
                    setModuleInputValue(newInputValue);
                  }}
                  value={moduleValue}
                  onChange={(event, newValue) => {
                    setFieldValue('moduleId', newValue?.id ? newValue?.id : '');
                    setModuleValue(newValue);
                  }}
                  onBlur={handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      name="moduleId"
                      error={Boolean(touched?.moduleId && errors?.moduleId)}
                      helperText={touched?.moduleId && errors?.moduleId}
                    />
                  )}
                />
              </Box>
              <Box className="item">
                <InputLabel htmlFor="sub-module-simple">Sub Module</InputLabel>
                <Autocomplete
                  id="sub-module-simple"
                  fullWidth
                  placeholder="Sub-Module"
                  size="small"
                  options={allModules}
                  getOptionLabel={(option: any) => option?.name}
                  inputValue={subModuleInputValue}
                  onInputChange={(event, newInputValue) => {
                    setSubModuleInputValue(newInputValue);
                  }}
                  value={subModuleValue}
                  onChange={(event, newValue) => {
                    setFieldValue('subModuleId', newValue?.id ? newValue?.id : '');
                    setSubModuleValue(newValue);
                  }}
                  onBlur={handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      name="subModuleId"
                      error={Boolean(touched?.subModuleId && errors?.subModuleId)}
                      helperText={touched?.subModuleId && errors?.subModuleId}
                    />
                  )}
                />
              </Box>
            </Box>
            <Typography
              variant="h3"
              style={{ marginTop: '16px', marginBottom: '10px', fontSize: '20px', fontWeight: '700', color: '#000', }}
            >
              Permissions
            </Typography>
            <Box
              className={`${classes.paper}`}
              style={{ paddingBottom: '10px', }}
            >
              <Box className={classes.checkboxRoot}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      name="isRead"
                      checked={values?.isRead}
                      onChange={() => setFieldValue('isRead', !values?.isRead)}
                    />
                  )}
                  label="Read"
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      name="isCreate"
                      checked={values?.isCreate}
                      onChange={() => setFieldValue('isCreate', !values?.isCreate)}
                    />
                  )}
                  label="Create"
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      name="isUpdate"
                      checked={values?.isUpdate}
                      onChange={() => setFieldValue('isUpdate', !values?.isUpdate)}
                    />
                  )}
                  label="Edit"
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      name="isDelete"
                      checked={values?.isDelete}
                      onChange={() => setFieldValue('isDelete', !values?.isDelete)}
                    />
                  )}
                  label="Delete"
                />
              </Box>
              <Box
                className={classes.checkboxRoot}
                mt={2}
              >
                <FormControlLabel
                  control={(
                    <Checkbox
                      name="isToggleStatus"
                      checked={values?.isToggleStatus}
                      onChange={() => setFieldValue('isToggleStatus', !values?.isToggleStatus)}
                    />
                  )}
                  label="ToggleStatus"
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      name="isGetAll"
                      checked={values?.isGetAll}
                      onChange={() => setFieldValue('isGetAll', !values?.isGetAll)}
                    />
                  )}
                  label="Get All"
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      name="isGetById"
                      checked={values?.isGetById}
                      onChange={() => setFieldValue('isGetById', !values?.isGetById)}
                    />
                  )}
                  label="Get By Id"
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      name="isBulkDeactivate"
                      checked={values?.isBulkDeactivate}
                      onChange={() => setFieldValue('isBulkDeactivate', !values?.isBulkDeactivate)}
                    />
                  )}
                  label="Bulk De-activate"
                />
              </Box>
            </Box>
            <Box className={classes.btnRoot}>
              <Link
                to={`${baseUrl}/permissions`}
                style={{ marginLeft: 'auto', }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ height: '38px' }}
                >
                  Cancel
                </Button>
              </Link>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                sx={{ height: '38px' }}
              >
                Save
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default PermissionNew;
