import { Typography } from '@material-ui/core';
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { okrApi } from 'src/api/okrApi';
import CustomListView from 'src/components/customTable/CustomListView';

export const DepartmentGrid = () => {
    const [overAllTasks, setOverAllTasks] = useState([]);

    // const keys = [
    //   { key: 'totalCount', title: 'Total', color: '#4285f4' },
    //   { key: 'Done', title: 'Done', color: '#34a853' },
    //   { key: 'In Progress', title: 'In Progress', color: '#fbbc04' },
    //   { key: 'Pending', title: 'Pending', color: '#ea4335' },
    // ];
    const columns = [
        // {
        //   id: 'name',
        //   label: 'Department',
        // },
        {
            id: 'subDepartmentName',
            label: 'Department',
          },
          // {
          //   id: 'To Do',
          //   label: 'To Do Tasks',
          //   isSort: true,
          // },
          {
            id: 'Pending',
            label: 'Pending Tasks',
            isSort: true,
          },
          {
            id: 'In Progress',
            label: 'In Progress Tasks',
            isSort: true,
          },
          {
            id: 'Done',
            label: 'Done',
            isSort: true,
          },
          {
            id: 'totalCount',
            label: 'Total Tasks',
            isSort: true,
          }
      ];

      const getOverallTasksSummary = async () =>  {
        try{
          const response = await okrApi.getOverallTaskSummary();
          setOverAllTasks(response.data);
        }catch(error){
          toast.error(error.message)
        }

      }


      useEffect(() => {
        getOverallTasksSummary();
      }, [])


  return (
    <>
     <Typography color="textPrimary" variant="h4">
        Overall Tasks Summary
      </Typography>
      <CustomListView
        columns={columns}
        data={overAllTasks}
        hidePagination
      />
    </>
  )
}
