import React, { FC, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';
import {
    FormControl,
    FormLabel,
    Button,
    Typography,
    Divider,
    MenuItem,
    Select,
    CircularProgress,
    LinearProgress,
    FormHelperText,
    Checkbox,
    FormControlLabel,
    TextField,
    Box
} from '@material-ui/core';
import { finalCaseStyles } from './finalStyles';
import { casesApi } from 'src/api/casesApi';
import moment from 'moment';
import { statusFlowApi } from 'src/api/statusFlowApi';
// import './style.css';



const FinalStep: FC<any> = ({ performAction, editPayload, selectedIds, actionType, moveCase, status, commentPayload, afrIds }) => {
    const classes = finalCaseStyles();
    const navigate = useNavigate();
    const [progress, setProgress] = React.useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [startTime, setStartTime] = useState<number | null>(null);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [localTime, setlocalTime] = useState(moment().format('HH:mm'));
    const [endTime, setEndTime] = useState('');


    const clearStorage = () => {
        localStorage.removeItem("finalStep");
    }
    // const localTime = moment().format('HH:mm');
    const handleEdit = async () => {
        try {
            setIsSubmitting(true);
            setStartTime(Date.now());

            const caseIds = selectedIds;
            const batchSize = 50;
            const caseCount = caseIds.length;
            const batches: number[][] = [];

            let currentIndex = 0;
            let processedCount = 0; // Track the number of processed cases

            while (currentIndex < caseCount) {
                let nextBatchSize = batchSize;
                if (caseCount - currentIndex <= 100 && caseCount - currentIndex > 50) {
                    nextBatchSize = Math.ceil((caseCount - currentIndex) / 2);
                }

                const batch = caseIds.slice(currentIndex, currentIndex + nextBatchSize);
                batches.push(batch);

                currentIndex += nextBatchSize;
            }

            for (const batch of batches) {/* eslint-disable-line */
                const payload = { ...editPayload, caseIds: batch }; /* eslint-disable-line */
                await casesApi.updateBulkCases(payload); /* eslint-disable-line */
                processedCount += batch.length; // Update processed count by batch size
                setProgress((processedCount / caseCount) * 100); // Update progress based on processed count
            }

            toast.success('Updated Successfully');
            clearStorage()
            setEndTime(moment().format('HH:mm'))
        } catch (apiError) {
            if (apiError?.response?.status !== 401) {
                toast.error(apiError?.response?.data?.message || 'An error occurred');
            } else {
                toast.error('Unauthorized access. Please log in.');
            }
        } finally {
            setIsSubmitting(false);
        }
    };



    const handleStatus = async () => {
        try {
            setIsSubmitting(true); // Set the submitting state to true
            setStartTime(Date.now()); // Record the start time

            if (afrIds.length > 0) {
                selectedIds = selectedIds.filter(id => !afrIds.includes(id));
            }


            const caseIds = selectedIds || [];
            const batchSize = 50;
            const caseCount = caseIds.length;
            const batches: number[][] = [];

            let currentIndex = 0;
            let processedCount = 0; // Track the number of processed cases

            while (currentIndex < caseCount) {
                let nextBatchSize = batchSize;
                if (caseCount - currentIndex <= 100 && caseCount - currentIndex > 50) {
                    nextBatchSize = Math.ceil((caseCount - currentIndex) / 2);
                }

                const batch = caseIds.slice(currentIndex, currentIndex + nextBatchSize);
                batches.push(batch);

                currentIndex += nextBatchSize;
            }
            /* eslint-disable-line */
            for (const batch of batches) {/* eslint-disable-line */
                const data = {
                    ...status,
                    caseIds: batch,
                };
                await statusFlowApi.updateBulkStatus(data);/* eslint-disable-line */

                processedCount += batch.length; // Update processed count by batch size
                setProgress((processedCount / caseCount) * 100); // Update progress based on the processed count
            }

            toast.success('Status Updated successfully');
            clearStorage()
            setEndTime(moment().format('HH:mm'))
        } catch (error) {
            if (error?.response?.status !== 401) {
                toast.error(error?.response?.data?.message || 'An error occurred');
            } else {
                toast.error('Unauthorized access. Please log in.');
            }
        } finally {
            setIsSubmitting(false); // Set the submitting state to false
        }
    };

    // progress Pending /* eslint-disable-line */
    const handleDlete = async () => {
        try {
            setIsSubmitting(true);
            setStartTime(Date.now());

            const caseIds = selectedIds || [];
            const batchSize = 50;
            const caseCount = caseIds.length;
            const batches: number[][] = [];

            let currentIndex = 0;
            let processedCount = 0; // Track the number of processed cases

            while (currentIndex < caseCount) {
                let nextBatchSize = batchSize;
                if (caseCount - currentIndex <= 100 && caseCount - currentIndex > 50) {
                    nextBatchSize = Math.ceil((caseCount - currentIndex) / 2);
                }

                const batch = caseIds.slice(currentIndex, currentIndex + nextBatchSize);
                batches.push(batch);

                currentIndex += nextBatchSize;
            }
            /* eslint-disable-line */
            for (const batch of batches) {/* eslint-disable-line */
                const data = { caseIds: batch };/* eslint-disable-line */
                await casesApi.deleteBulkCase(data);/* eslint-disable-line */

                processedCount += batch.length; // Update processed count by batch size
                setProgress((processedCount / caseCount) * 100); // Update progress based on the processed count
            }
            toast.success('Cases Deleted successfully');
            clearStorage()
            setEndTime(moment().format('HH:mm'))
        } catch (error) {
            if (error?.response?.status !== 401) {
                toast.error(error?.response?.data?.message || 'An error occurred');
            } else {
                toast.error('Unauthorized access. Please log in.');
            }
        } finally {
            setIsSubmitting(false); // Set the submitting state to false
        }
    };

    const handleMoveCase = async () => {
        const convertedPayload = {
            project: moveCase.project,
            billingAccountType: moveCase.account.map(account => ({
                name: account.accountMoveTo.name,
                id: account.accountMoveTo.id,
                moveFrom: account.accountMoveFrom.id
            }))
        };


        const caseIds = selectedIds || [];
        const batchSize = 50;
        const caseCount = caseIds.length;
        const batches: number[][] = [];

        let currentIndex = 0;
        let processedCount = 0; // Track the number of processed cases

        while (currentIndex < caseCount) {
            let nextBatchSize = batchSize;
            if (caseCount - currentIndex <= 100 && caseCount - currentIndex > 50) {
                nextBatchSize = Math.ceil((caseCount - currentIndex) / 2);
            }
            const batch = caseIds.slice(currentIndex, currentIndex + nextBatchSize);
            batches.push(batch);
            currentIndex += nextBatchSize;
        }

        try { /* eslint-disable-line */
            for (const batch of batches) { /* eslint-disable-line */
                const accPayload = { ...convertedPayload, caseIds: batch };
                // await casesApi.updateBulkCases(proj);/* eslint-disable-line */
                await casesApi.moveBulkCases(accPayload); /* eslint-disable-line */
                processedCount += batch.length;
                setProgress((processedCount / caseCount) * 100);
            }
            clearStorage()
            toast.success('Updated Successfully');
            setEndTime(moment().format('HH:mm'))
        } catch (apiError) {
            if (apiError?.response?.status !== 401) {
                toast.error(apiError?.response?.data?.message || 'An error occurred');
            } else {
                toast.error('Unauthorized access. Please log in.');
            }
        }
    };


    const handleComment = async (values: any) => {
        try {
            setIsSubmitting(true); // Set the submitting state to true
            setStartTime(Date.now()); // Record the start time

            const caseIds = selectedIds || [];
            const batchSize = 25;
            const caseCount = caseIds.length;
            const batches: number[][] = [];

            let currentIndex = 0;
            let processedCount = 0; // Track the number of processed cases

            while (currentIndex < caseCount) {
                let nextBatchSize = batchSize;
                if (caseCount - currentIndex <= 100 && caseCount - currentIndex > 50) {
                    nextBatchSize = Math.ceil((caseCount - currentIndex) / 2);
                }

                const batch = caseIds.slice(currentIndex, currentIndex + nextBatchSize);
                batches.push(batch);

                currentIndex += nextBatchSize;
            }
            /* eslint-disable-line */
            for (const batch of batches) {/* eslint-disable-line */
                const payload = {
                    ...values,
                    caseIds: batch,
                };
                /* eslint-disable-line */
                await casesApi.bulkComment(payload);/* eslint-disable-line */

                processedCount += batch.length; // Update processed count by batch size
                setProgress((processedCount / caseCount) * 100); // Update progress based on the processed count
            }

            toast.success('Updated Successfully');
            clearStorage()
            setEndTime(moment().format('HH:mm'))
        } catch (apiError) {
            if (apiError?.response?.status !== 401) {
                toast.error(apiError?.response?.data?.message || 'An error occurred');
            } else {
                toast.error('Unauthorized access. Please log in.');
            }
        } finally {
            setIsSubmitting(false); // Set the submitting state to false
        }
    };


    const handleSubmit = () => {
        localStorage.removeItem("finalStep");
        navigate('/erp/cases');

    }
    useEffect(() => {
        localStorage.setItem("finalStep", "true");
    }, []);

    useEffect(() => {
        const finalStep = localStorage.getItem("finalStep");
        if (finalStep === "true") {
            const loaderRoot = document.getElementById("loader-root");
            if (loaderRoot) {
                loaderRoot.style.display = "none";
            }
        }
    }, []);


    useEffect(() => {
        let timerInterval: NodeJS.Timeout;

        if (isSubmitting && startTime !== null) {
            timerInterval = setInterval(() => {
                setElapsedTime(Math.floor((Date.now() - startTime) / 1000));
            }, 1000);
        }

        return () => clearInterval(timerInterval);
    }, [isSubmitting, startTime]);


    useEffect(() => {
        const timer = setTimeout(() => {
            if (actionType === 'edit') {
                handleEdit();
            }
            // handleMoveCase
            if (actionType === 'move') {
                handleMoveCase();
            }
            // transition
            if (actionType === 'transition') {
                handleStatus();
            }
            // comment commentPayload
            if (actionType === 'comment') {
                handleComment(commentPayload);
            }
            // delete
            if (actionType === 'delete') {
                handleDlete();
            }
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (progress === 100) {
                handleSubmit();
            }
        }, 10000);
        return () => clearTimeout(timer);
    }, [progress])
    return (
        <div className={classes.root}>
            <FormControl component="fieldset" className={classes.formControl}>
                <Typography variant="h6" className={classes.formLabel}>
                    Step 4 of 4: Confirmation
                </Typography>
                <Divider className={classes.divider} />
                {/* actions preview table */}
            </FormControl>
            <div className={classes.wrapperLoader}>
                <p className="editH">
                    Editing {selectedIds.length} Cases
                </p>
                <div className={classes.loading}>
                    {(progress != null ? `${progress.toFixed(0)}%` : 'N/A')}
                    <Box sx={{ width: '100%' }} className='ProgressBAr'>
                        <LinearProgress variant="determinate" value={progress} />
                    </Box>
                </div>
                <p className="editp">
                    Bulk Operation {(progress != null ? `${progress.toFixed(0)}%` : 'N/A')} Completed;
                </p>
                <p className="editp">
                    Started at: {localTime}
                </p>
                <p className="editp">
                    Ended at: {endTime}
                </p>
                {/* endTime */}
            </div>
            <div className='btnAlignmant'>
                {progress === 100 &&
                    <Button variant="contained" className={classes.button} onClick={handleSubmit} disabled={isSubmitting}>
                        Done
                    </Button>}
            </div>
        </div>
    );
};

export default FinalStep;
