
import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // padding: theme.spacing(3),
        backgroundColor: '#fff',
        borderRadius: theme.shape.borderRadius,
        margin: "30px",
        '& .MuiFormControlLabel-label': {
            fontSize: '15px !important',
        }
    },
    formControl: {
        // margin: theme.spacing(3),
        width: '100%',
    },
    radioGroup: {
        marginBottom: theme.spacing(3),
    },
    button: {
        backgroundColor: '#c22027',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#d43f40',
        },
        alignSelf: 'flex-end',
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    formLabel: {
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(3),
        marginTop: theme.spacing(3),
        fontWeight: 600,
        fontSize: 14
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    subtitle: {
        marginLeft: theme.spacing(3),
    },
    controlLabel: {
        marginLeft: theme.spacing(3),
    },
    radio: {
        color: '#c22027', // Set the unchecked color
        '&.Mui-checked': {
            color: '#c22027', // Set the checked color
        },
    },
}));

const SelectOperation: FC<any> = ({ sendActionType, selectedIds }) => {

    // const SelectOperation = ({ sendActionType }) => {
    const classes = useStyles();
    const [value, setValue] = React.useState('');

    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const chooseAction = () => {
        sendActionType(value)
    };

    return (
        <div className={classes.root}>
            <FormControl component="fieldset" className={classes.formControl}>
                <Typography variant="h6" className={classes.formLabel}>
                    Step 2 of 4: Choose bulk action
                </Typography>
                <Typography variant="body2" color="textSecondary" className={classes.subtitle}>
                    Choose which action you&apos;d like to take on the selected issues.
                </Typography>
                <Divider className={classes.divider} />
                <RadioGroup
                    aria-label="bulk-action"
                    name="bulk-action"
                    value={value}
                    onChange={handleChange}
                    className={classes.radioGroup}
                >
                    <FormControlLabel className={classes.controlLabel} value="edit" control={<Radio className={classes.radio} />} label="Edit Cases - Edit field values of cases" />
                    <Divider className={classes.divider} />
                    <FormControlLabel className={classes.controlLabel} value="move" control={<Radio className={classes.radio} />} label="Move Cases - Move Cases to new projects " />
                    <Divider className={classes.divider} />
                    <FormControlLabel className={classes.controlLabel} value="transition" control={<Radio className={classes.radio} />} label="Transition Cases - Transition Cases through workflow" />
                    <Divider className={classes.divider} />
                    <FormControlLabel className={classes.controlLabel} value="delete" control={<Radio className={classes.radio} />} label={`Delete Cases - Delete the selected ${selectedIds.length} Cases`} />
                    <Divider className={classes.divider} />
                    <FormControlLabel className={classes.controlLabel} value="comment" control={<Radio className={classes.radio} />} label={`Add Comment - to the selected ${selectedIds.length} Cases`} />
                    <Divider className={classes.divider} />
                </RadioGroup>
            </FormControl>
            <Button variant="contained"
                className={classes.button}
                onClick={chooseAction}
            >
                Next
            </Button>
        </div>
    );
};

export default SelectOperation;
