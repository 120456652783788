import { isUndefined } from 'lodash';
import axios from 'src/utils/axios';
import logger from 'src/utils/logger';
import parse from 'src/utils/parse';

class MeetingsApi {
  async getMeetingsByProjectId(
    orgId: string,
    projectId: string,
    page: number,
    pageSize: number
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/meeting`, {
          params: {
            organizationId: orgId,
            projectId,
            page,
            pageSize
          }
        })
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch((error: Error) => {
          logger(error, 'error');
          reject(new Error('internal Server Error'));
        });
    });
  }

  async createMeeting(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_CASES}/meeting`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async deleteMeeting(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${process.env.REACT_APP_CASES}/meeting/${id}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  }

  async getMeetingById(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/meeting/${id}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response?.data);
          }
        })
        .catch((error: Error) => {
          logger(error, 'error');
          reject(new Error('internal Server Error'));
        });
    });
  }

  async updateMeeting(id: string, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${process.env.REACT_APP_CASES}/meeting/${id}`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error: Error) => {
          logger(error, 'error');
          reject(error);
        });
    });
  }
}

export const meetingsApi = new MeetingsApi();
