import {
  Button,
  Theme,
  Typography,
  createStyles,
  makeStyles
} from '@material-ui/core';
import { FC, useEffect, useState, useRef } from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { meetingsApi } from 'src/api/meetingsApi';
import AddMeetingCard from './AddMeetingCard';
import MeetingCard from './MeetingCard';
import toast from 'react-hot-toast';
import { DateRange } from 'react-date-range';
import FilterAltIcon from '@material-ui/icons/FilterAlt';
import CloseIcon from '@material-ui/icons/Close';
import { useParams } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import logger from 'src/utils/logger';

const MeetingsSection: FC<any> = () => {
  const { user } = useAuth();
  const { organizationID, projectID } = useParams();
  const [meetingsData, setMeetingsData] = useState<any>({});
  const [addMeeting, setAddMeeting] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const toggleIsUpdate = () => setIsUpdate(!isUpdate);
  const [years, setYears] = useState([]);
  const [pageSizes, setPageSizes] = useState(25);
  const [scrollFlag, setScrollFlag] = useState<boolean>(true);
  const [state, setState] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(new Date().setDate(new Date().getDate() + 30)),
      key: 'selection'
    }
  ]);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const useStyles = makeStyles<Theme>((theme) => createStyles({
    meetingTab: {
      '& .meetingTabSub': {
        height: '70vh',
        overflowY: 'auto',
      },
      '& .filterArea': {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'relative',
        '& .rdrCalendarWrapper': {
          position: 'absolute',
          zIndex: '999',
          right: '83px'

        },

      },
      '& .MuiAccordionSummary-root': {
        background: '#fff',
        color: '#231F20',
        borderRadius: '0  0 4px 4px  ',
      },
      '& .MuiAccordionSummary-expandIconWrapper ': {
        color: '#231F20'
      },
      '& .meetingTabYearCard': {
        '& .meetingCard': {
          '& .Mui-expanded': {
            margin: '12px 0px',
            '& .MuiAccordionSummary-expandIconWrapper': {
              margin: '0 10px 0 0'
            },

          },
          '& .meetingCardHeading': {},
          '& .meetingCardDescription': {
            marginTop: '10px',
            // textOverflow: 'ellipsis',
            overflow: 'hidden',
            // width: '90%',
            // height: '40px',
            // display: 'inline-block',
            // whiteSpace: 'nowrap',
            height: '50px',
            overflowY: 'auto',
            width: '500px',
            [theme.breakpoints.between(1200, 1919)]: {
              width: '340px',
            },
            '& p': {
              margin: '0',
              // textOverflow: 'ellipsis',
              // overflow: 'hidden',
              // width: '90%',
              // height: '40px',
              // display: 'inline-block',
              // height: '50px',
              // overflowY: 'auto',
              // width: '500px',
            },
            '& p+p': {
              marginTop: '5px'
            },

          },
          '& .meetingCardTitleDesc': {
            '& .showMoreText': {
              fontSize: '11px',
              fontWeight: 'bold',
            },
          },
          '& .meetingCardActions': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            '& .meetingCardInfo': {
              display: 'flex',
              marginTop: '5px',
              '& .MuiSvgIcon-root': {
                fontSize: '18px',
              },
              '& h5': {
                marginLeft: '8px',
                display: 'flex',
                alignItems: 'center'
              },
              '& .meetingCardInfoSub': {
                display: 'flex',
                alignItems: 'center',
                '& h5': {
                  marginLeft: '8px',
                  display: 'flex',
                  alignItems: 'center'
                }
              },
              '& .meetingCardInfoSub+.meetingCardInfoSub': {
                marginLeft: '12px'
              }
            },

            '& .meetingCardDate': {
              marginLeft: '20px'
            }
          },
          '& .MuiAccordionSummary-content': {
            margin: '12px 0'
          },
          '& .heading': {
            color: '#616161',
            fontSize: '16px'
          },
          margin: '0 0px 10px 0',

          background: '#FFFFFF',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
          '& .innerInfo': {
            display: 'flex',
            justifyContent: 'space-between',
            '& .infoCard': {}
          },
          '& .updateButton': {
            display: 'flex',
            marginLeft: 'auto',
            width: '30px',
            marginTop: '10px'
          },
          '& .addComment': {}
        }
      },
      '& .createButton': {
        display: 'flex',
        marginLeft: 'auto',
        width: '30px',
        marginTop: '10px',
      },
      '& .addMeetingCard': {
        margin: '0 0px 10px 0',
        background: '#FFFFFF',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        '& .meetingCardHeading': {},
        '& .meetingCardDescription': {
          marginTop: '10px'
        },
        '& .accordionSummary': {
          margin: '5px 0 5px 0',
          justifyContent: 'center'
        },
        '& .heading': {
          color: '#616161',
          fontSize: '25px',
          fontWeight: 'bold'
        },
        '& .innerInfo': {
          display: 'flex'
        },

        '& .addComment': {},

        '& .loadingCircle': {
          height: '30px',
          width: '30px',
          display: 'flex',
          marginLeft: 'auto'
        }
      }
    },
    addButton: {
      height: 30,
      width: '30px',
      borderRadius: '5px',
      minWidth: 'initial',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: '10px',
      marginBottom: '10px',
      '& .MuiButton-startIcon': {
        margin: 0
      }
    }
  }));

  const classes = useStyles();

  const getMeetingsByProjectId = async (pages:number, pageSize:number) => {
    try {
      const res = await meetingsApi.getMeetingsByProjectId(
        organizationID,
        projectID,
        pages,
        pageSize
      );
      if (res?.status === 204) {
        setScrollFlag(false);
      }
      if (res?.data?.data) {
        setMeetingsData(res?.data?.data);
        setYears(Object.keys(res?.data?.data));
      }
    } catch (error) {
      // toast.error(`${error?.response?.data?.message}`);
      logger(error, 'error');
    }
  };

  const deleteMeetingById = async (idDelete: string) => {
    try {
      const response = await meetingsApi.deleteMeeting(idDelete);
      if (response?.status === 200) {
        setIsUpdate(!isUpdate);
        toast.success('Meeting Deleted');
      }
    } catch (error) {
      if (error?.response?.status === 409) {
        toast.error(`${error?.response?.data?.message}`);
      } else {
        toast.error('Internal Server Error');
      }
    }
  };
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (!user?.permissions?.includes('organization-meeting-tab-read')
   && !user?.permissions?.includes('organization-meeting-create')
   && !user?.permissions?.includes('organization-meeting-get-all')
   && !user?.permissions?.includes('organization-meeting-update')
   && !user?.permissions?.includes('organization-meeting-get-by-id')
   && !user?.permissions?.includes('organization-meeting-delete')
   && !user?.permissions?.includes('organization-meeting-bulk-deactivate')
   && !user?.permissions?.includes('organization-meeting-toggle-status')
   && !user?.permissions?.includes('organization-meeting-filter-tabs-read')) {
      window.location.href = '/admin/not-found';
    }

    getMeetingsByProjectId(0, pageSizes);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [addMeeting, isUpdate, state[0].startDate, state[0].endDate]);

  const handleScroll = (e) => {
    const div = e.target;
    const scrollThresholdPercent = 1;
    const scrollThreshold = div.scrollHeight * scrollThresholdPercent;
    const scrollBottom = div.clientHeight + div.scrollTop;
    if (
      scrollBottom >= scrollThreshold && scrollFlag
    ) {
      getMeetingsByProjectId(0, pageSizes + 5);
      setPageSizes(pageSizes + 5);
    }
  };

  return (
    <div className={classes.meetingTab}>
      <div className="filterArea">

        {isOpen && (
        <div ref={ref}>
          <DateRange
            ranges={state}
            onChange={(item) => setState([item.selection])}
            editableDateInputs
          />
        </div>
        )}
        <Button
          variant="contained"
          color="secondary"
          className={classes.addButton}
          onClick={handleToggle}
        >
          {!isOpen ? <FilterAltIcon /> : <CloseIcon />}
        </Button>
        {user?.permissions?.includes('organization-meeting-create')

        && (
        <Button
          variant="contained"
          color="secondary"
          startIcon={<AddCircleIcon />}
          className={classes.addButton}
          onClick={() => setAddMeeting(!addMeeting)}
        />
        )}
      </div>

      {(addMeeting
      && (
      <AddMeetingCard
        setAddMeeting={setAddMeeting}
      />
      )
      )}
      <>

        {Object.keys(meetingsData).length === 0 ? (
          <Typography
            className="yearCardDate"
            color="textPrimary"
            variant="h4"
            sx={{ textAlign: 'center' }}
          >
            No Records Found
          </Typography>
        )
          : (
            <div
              className="meetingTabSub"
              onScroll={handleScroll}
            >
              {years.map((year) => (
                <div
                  className="meetingTabYearCard"
                  key={year}
                >
                  <Typography
                    className="yearCardDate"
                    color="textPrimary"
                    variant="subtitle2"
                    sx={{ mb: 2 }}
                  >
                    {year}
                  </Typography>
                  {Object.values(meetingsData[year]).map((monthlyArray: Array<any>) => (
                    monthlyArray.map((meeting) => (
                      <MeetingCard
                        meeting={meeting}
                        // isUpdateData={setIsUpdate}
                        isUpdateData={toggleIsUpdate}
                        deleteMeetingById={deleteMeetingById}
                      />
                    ))))}
                </div>
              ))}
            </div>
          )}
      </>
    </div>
  );
};

export default MeetingsSection;
