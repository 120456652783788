/* eslint-disable implicit-arrow-linebreak */
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  Button,
  CircularProgress,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogContentText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { FC, useEffect, useRef, useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { meetingsApi } from 'src/api/meetingsApi';
import toast from 'react-hot-toast';
import GroupIcon from '@material-ui/icons/Group';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import SingleDeleteConfirmation from '../widgets/Dialog/SingleDeleteConfirmation';
import logger from 'src/utils/logger';

const MeetingCard: FC<any> = ({
  meeting,
  deleteMeetingById,
  isUpdateData
}: any) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [meetingDateTime, setMeetingDateAndTime] = useState('');
  const [titleMain, setTitleMain] = useState<string>('');
  const [durations, setDuration] = useState<string>(meeting.duration);
  const [numberOfAttendees, setNumberOfAttendees] = useState(
    meeting.numberOfAttendees
  );
  const [meetingDescription, setMeetingDescription] = useState<string>('');
  const [meetingData, setMeetingData] = useState<any>({});
  const [isExternalMeeting, setIsExternalMeeting] = useState<boolean>(
    meeting.isExternal
  );
  const durationArr = [
    { title: '30 Minutes', value: '30M' },
    {
      title: '1 Hour',
      value: '1H'
    },
    {
      title: '1 Hour 30 Minutes',
      value: '1H30M'
    },
    {
      title: '2 Hours',
      value: '2H'
    },
    {
      title: '2 Hours 30 Minutes',
      value: '2H30M'
    }
  ];

  const attendeesArr = [
    { label: 'No of Attendees', value: 0 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
  ];
  const [dialogueOpen, setDialogueOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const descriptionRef = useRef(null);
  const handleDelete = (id:string) => {
    deleteMeetingById(id);
  };
  const handleAccordionDropdown = async () => {
    try {
      const response = await meetingsApi.getMeetingById(meeting.id);
      setMeetingData(response);
      setMeetingDateAndTime(response?.startTime);
      setDuration(response?.duration);
      setNumberOfAttendees(response?.numberOfAttendees);
      setMeetingDescription(response?.description);
      setTitleMain(response?.title);
      if (response?.status === 200) {
        toast.success(`${response?.statusText}`);
      }
    } catch (error) {
      if (error?.response?.status === 409) {
        toast.error(`${error?.response?.data?.message}`);
      } else {
        toast.error('Internal Server Error');
      }
    }
  };

  const handleSubmit = async () => {
    const payload = {
      description: meetingDescription,
      startTime: meetingDateTime,
      duration: durations,
      numberOfAttendees: parseInt(numberOfAttendees, 10),
      title: titleMain,
      isExternal: isExternalMeeting
    };
    logger(payload, '', 'handle-submit: payload: ');
    // setIsSubmitting(true);
    try {
      const response = await meetingsApi.updateMeeting(meeting.id, payload);
      logger(response, '', 'handle-submit: response: ');
      // setIsSubmitting(false);
      if (response?.status === 200) {
        toast.success('Updated successfully');
        isUpdateData();
        setIsSubmitting(false);
      }
    } catch (error) {
      toast.error(error?.message);
      setIsSubmitting(false);
    }
  };
  const checkForNbsp = (element) => {
    const { children } = element;
    for (let i = 0; i < children.length; i++) {
      if (children[i].innerHTML.includes('&nbsp;')) {
        children[i].innerHTML = '';
      }
    }
  };

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  const displayText = showMore
    ? meeting?.description
    : meeting?.description.slice(0, 100);

  const customValidation = () => {
    if (titleMain.trim() === '') {
      toast.error('Title cannot be empty');
      return false;
    } if (numberOfAttendees === 0) {
      toast.error('Please select no of attendees');
      return false;
    } if (durations === 'Select Duration') {
      toast.error('Please select Duration');
      return false;
    } if (meetingDateTime === '') {
      toast.error('Please select start time of Meeting');
      return false;
    } if (meetingDescription.trim() === '') {
      toast.error('Please enter Meeting description');
      return false;
    }
    return true;
  };

  useEffect(() => {
    checkForNbsp(descriptionRef.current);
  }, []);
  return (
    <>
      {/* <Dialog
        open={dialogueOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to change the status
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogueOpen(false)}
            color="primary"
          >
            No
          </Button>
          <Button
            onClick={() => handleDelete()}
            color="primary"
            variant="outlined"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog> */}
      <SingleDeleteConfirmation
        isOpen={dialogueOpen}
        setDeleteDialogueVisible={setDialogueOpen}
        handleDelete={handleDelete}
        selectedItem={meeting.id}
        text="Do you want to delete this Meeting ?"
      />
      <Accordion
        className="meetingCard"
        onChange={() => {
          setExpanded(false);
        }}
        expanded={expanded}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className="accordionSummary"
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="meetingCardTitleDesc">
            <Typography
              className="meetingCardHeading"
              variant="h4"
            >
              {meeting.title}
            </Typography>
            <Tooltip
              // eslint-disable-next-line react/no-danger
              title={<span dangerouslySetInnerHTML={{ __html: displayText }} />}
              aria-label="Date"
              placement="bottom-end"
            >
              <>
                <Typography
                  ref={descriptionRef}
                  className="meetingCardDescription"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: displayText }}
                />
                {meeting?.description.length > 100 && (
                  // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
                  <span
                    onClick={toggleShowMore}
                    className="showMoreText"
                  >
                    {showMore ? 'Show less' : 'Show more'}
                  </span>
                )}
              </>
            </Tooltip>
          </div>
          <div className="meetingCardActions">
            <Select
              labelId="outcomeLabel"
              id="select"
              value=" "
              variant="standard"
              disableUnderline
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                if (e.target.value === 'Delete') {
                  setDialogueOpen(true);
                } else {
                  handleAccordionDropdown();
                  setExpanded(true);
                }
              }}
            >
              <MenuItem value=" ">Actions</MenuItem>
              <MenuItem value="edit">Edit</MenuItem>
              <MenuItem value="Delete">Delete</MenuItem>
            </Select>
            <div className="meetingCardInfo">
              <Tooltip
                title="Date"
                aria-label="Date"
                placement="bottom-end"
              >
                <div className="meetingCardInfoSub">
                  <CalendarTodayIcon />
                  <Typography variant="h5">
                    {` ${new Date(meeting.startTime).toLocaleString()} `}
                  </Typography>
                </div>
              </Tooltip>
            </div>
            <div className="meetingCardInfo">
              <Tooltip
                title="No of Attendees"
                aria-label="No of Attendees"
                placement="bottom-end"
              >
                <div className="meetingCardInfoSub">
                  <GroupIcon />
                  <Typography variant="h5">{numberOfAttendees}</Typography>
                </div>
              </Tooltip>
              <Tooltip
                title="Duration"
                aria-label="Duration"
                placement="bottom-end"
              >
                <div className="meetingCardInfoSub">
                  <QueryBuilderIcon />
                  <Typography variant="h5">{durations}</Typography>
                </div>
              </Tooltip>
            </div>
          </div>
        </AccordionSummary>
        {Object.keys(meetingData).length ? (
          <AccordionDetails>
            {/* <Typography> Edit Meeting</Typography> */}
            {isSubmitting ? (
              <div className="loadingCircle">
                <CircularProgress />
              </div>
            ) : (
              <Grid
                container
                spacing={4}
                className="justifyContentBw innerInfo"
              >
                <Grid
                  item
                  md={6}
                >
                  <Typography>Title</Typography>
                  <TextField
                    placeholder="Enter Title"
                    fullWidth
                    inputProps={{ maxLength: 225 }}
                    value={titleMain}
                    onChange={(e) => setTitleMain(e.target.value)}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  className="infoCard
              "
                >
                  <InputLabel id="attendeesLabel">Attendees</InputLabel>
                  <Select
                    labelId="attendeesLabel"
                    id="select"
                    fullWidth
                    // value={attendees}
                    value={numberOfAttendees}
                    // onChange={(e) => setAttendees(e.target.value)}
                    onChange={(e) => setNumberOfAttendees(e.target.value)}
                  >
                    {attendeesArr.map((item) => (
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
                  </Select>
                  {/* <TextField
                    fullWidth
                    value={numberOfAttendees}
                    onChange={(e) => setNumberOfAttendees(e.target.value)}
                  /> */}
                </Grid>

                <Grid
                  item
                  md={6}
                  className="infoCard
              "
                >
                  <InputLabel id="durationLabel">Duration</InputLabel>
                  <Select
                    labelId="durationLabel"
                    id="select"
                    fullWidth
                    value={durations}
                    onChange={(e) => setDuration(e.target.value)}
                  >
                    {durationArr.map((item) => (
                      <MenuItem value={item.value}>{item.title}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid
                  item
                  md={6}
                  className="infoCard"
                >
                  <Typography>Date</Typography>
                  <TextField
                    id="date"
                    label=""
                    type="datetime-local"
                    value={meetingDateTime}
                    fullWidth
                    onChange={(e) => setMeetingDateAndTime(e.target.value)}
                    className="infoCardTextField"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  className="infoCard"
                >
                  <InputLabel id="isExternalLabel">Is External</InputLabel>
                  <Select
                    labelId="isExternalLabel"
                    id="select"
                    fullWidth
                    value={isExternalMeeting}
                    onChange={(e) => setIsExternalMeeting(e.target.value)}
                  >
                    <MenuItem value="true">True</MenuItem>
                    <MenuItem value="false">False</MenuItem>
                  </Select>
                </Grid>
                <Grid
                  item
                  md={12}
                  className="infoCard"
                >
                  <div className="addComment">
                    <Typography>Description</Typography>
                  </div>
                  <div className="ckEditor">
                    <CKEditor
                      editor={ClassicEditor}
                      data={meetingDescription}
                      config={{
                        toolbar: [
                          'heading',
                          '|',
                          'bold',
                          'italic',
                          'link',
                          '|',
                          'undo',
                          'redo'
                        ],
                        entities: {
                          additional: '&#32;' // Use '&#32;' to represent a regular space
                        },
                        dataProcessor: {
                          html: (data) =>
                            // Replace all &nbsp; entities with regular spaces
                            logger(data, 'data')
                        }
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setMeetingDescription(data);
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            )}

            <Button
              className="updateButton"
              variant="contained"
              color="secondary"
              onClick={() => {
                if (customValidation()) {
                  handleSubmit();
                }
              }}

            >
              Update
            </Button>
          </AccordionDetails>
        ) : null}
      </Accordion>
    </>
  );
};

export default MeetingCard;
