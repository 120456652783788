/* eslint-disable @typescript-eslint/naming-convention */
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import moment from 'moment';
import * as React from 'react';
import { DateRange } from 'react-date-range';
import { casesApi } from 'src/api/casesApi';
import CreateFormSideBar from '../widgets/CreateFormSideBar/CreateFormSideBar';

const useStyles = makeStyles(() => ({
  advancedFilter: {
    '& .fields+.fields': {
      marginTop: '15px',
    },
    '& .datefield': {
      position: 'relative',
      '& .rdrCalendarWrapper': {
        zIndex: '999',
        position: 'absolute',
        right: '-1px',
        borderRadius: '5px',
        border: '1px solid rgb(239, 242, 247)',
        '& .rdrDateDisplayWrapper': {
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
        },
      },
    },
    '& .btns': {
      display: 'flex',
      marginTop: '20px',
      '& button': {
        width: '115px',
        height: 30,
      },
    },
  },
  datePicker: {
    zIndex: '999',
    position: 'absolute',
    borderRadius: '5px',
  },
}));
export default function CaseFilter(props: any) {
  const {
    _isOpen,
    _setShowAdvancedSearch,
    _search,
    _setSearch,
    _caseType,
    _setCaseType,
    _priority,
    _setPriority,
    _stakeholder,
    _setStakeholder,
    _assignee,
    _setAssignee,
    _projectSponser,
    _setProjectSponser,
    _escalationManager,
    _setEscalationManager,
    _status,
    _setStatus,
    _hasAttachments,
    _setHasAttachments,
    _isEscalated,
    _setIsEscalated,
    _isFirstResponseTimeViolated,
    _setIsFirstResponseTimeViolated,
    _isSynced,
    _setIsSynced,
    _isResolveTimeViolated,
    _setIsResolveTimeViolated,
    _date,
    _setDate,
    _project,
    _setProject,
    _organization,
    _setOrganization,
    _setFiltersApplied,
    _filtersApplied,
    isSubmit,
    setIsSubmit,
  } = props;
  const handleClose = () => {
    _setShowAdvancedSearch(false);
  };
  const classes = useStyles();
  const [search, setSearch] = React.useState(_search);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    if (e.target.value === '') {
      _setFiltersApplied({
        ..._filtersApplied,
        search: null,
      });
    } else {
      _setFiltersApplied({
        ..._filtersApplied,
        search: {
          name: 'Search',
          data: e.target.value,
        },
      });
    }
  };

  /** date */
  const [state, setState] = React.useState<any>([
    {
      startDate: _date.from ? new Date(_date.from) : null,
      endDate: _date.to ? new Date(_date.to) : null,
      key: 'selection',
      color: '#c22027',
    },
  ]);
  const [isOpen, setIsOpen] = React.useState(false);
  const ref = React.useRef(null);
  const [allOrganizations, setAllOrganizations] = React.useState([]);
  const getAllOrganizations = async () => {
    try {
      const res = await casesApi.getAllOrganizations();
      setAllOrganizations(res?.data);
    } catch (error) {
      setAllOrganizations([]);
    }
  };

  const [allStatuses, setAllStatuses] = React.useState([]);
  const getAllStatuses = async () => {
    try {
      const res = await casesApi?.getStatuses();
      setAllStatuses(res?.data);
    } catch (error) {
      setAllStatuses([]);
    }
  };

  const [allPriorities, setAllPriorities] = React.useState([]);
  const getAllPriorities = async () => {
    try {
      const response = await casesApi.getPriorities();
      setAllPriorities(response?.data);
    } catch (error) {
      setAllPriorities([]);
    }
  };

  const [allProjects, setAllProjects] = React.useState([]);
  const getAllProjects = async () => {
    try {
      const response = await casesApi.getAllProjects();
      setAllProjects(response?.data);
    } catch (error) {
      setAllProjects([]);
    }
  };

  const [allCaseTypes, setAllCaseTypes] = React.useState([]);
  const getAllCaseTypes = async () => {
    try {
      const response = await casesApi.getCaseTypes();
      setAllCaseTypes(response?.data);
    } catch (error) {
      setAllCaseTypes([]);
    }
  };

  const toggleHasAttachments = () => {
    _setHasAttachments(!_hasAttachments);
    if (!isSubmit) return;
    if (_hasAttachments) {
      _setFiltersApplied({
        ..._filtersApplied,
        hasAttachments: null,
      });
    } else {
      _setFiltersApplied({
        ..._filtersApplied,
        hasAttachments: {
          name: 'Attachments',
          data: 'True',
        },
      });
    }
  };
  /** is-escalated */

  const toggleIsEscalated = () => {
    _setIsEscalated(!_isEscalated);
    if (!isSubmit) return;
    if (_isEscalated) {
      _setFiltersApplied({
        ..._filtersApplied,
        escalatedCases: null,
      });
    } else {
      _setFiltersApplied({
        ..._filtersApplied,
        escalatedCases: {
          name: 'Escalated',
          data: 'True',
        },
      });
    }
  };
  const toggleIsFirstResponseTimeViolated = () => {
    _setIsFirstResponseTimeViolated(!_isFirstResponseTimeViolated);
    if (!isSubmit) return;
    if (_isFirstResponseTimeViolated) {
      _setFiltersApplied({
        ..._filtersApplied,
        isFirstResponseTimeViolated: null,
      });
    } else {
      _setFiltersApplied({
        ..._filtersApplied,
        isFirstResponseTimeViolated: {
          name: 'First Time Violated',
          data: 'True',
        },
      });
    }
  };
  /** is-sync */

  const toggleIsSynced = () => {
    _setIsSynced(!_isSynced);
    if (!isSubmit) return;
    if (_isSynced) {
      _setFiltersApplied({
        ..._filtersApplied,
        thirdPartySync: null,
      });
    } else {
      _setFiltersApplied({
        ..._filtersApplied,
        thirdPartySync: {
          name: 'Third Party Sync',
          data: 'True',
        },
      });
    }
  };

  const toggleIsResolveTimeViolated = () => {
    _setIsResolveTimeViolated(!_isResolveTimeViolated);
    if (!isSubmit) return;
    if (_isResolveTimeViolated) {
      _setFiltersApplied({
        ..._filtersApplied,
        isResolveTimeViolated: null,
      });
    } else {
      _setFiltersApplied({
        ..._filtersApplied,
        isResolveTimeViolated: {
          name: 'Resolve Time Violated',
          data: 'True',
        },
      });
    }
  };

  /** internal-users */
  const [allInternalUsers, setAllInternalUsers] = React.useState([]);
  const getAllInternalUsers = async () => {
    try {
      const response = await casesApi.getUserById(false);
      if (response?.status === 200) {
        setAllInternalUsers(response?.data);
      }
    } catch (error) {
      setAllInternalUsers([]);
    }
  };

  /** internal-users */
  const [allExternalUsers, setAllExternalUsers] = React.useState([]);
  const getAllExteranlUsers = async () => {
    try {
      const res = await casesApi?.getAutoCompleteUser(true);
      setAllExternalUsers(res?.data);
    } catch (error) {
      setAllExternalUsers([]);
    }
  };

  React.useEffect(() => {
    getAllCaseTypes();
    getAllPriorities();
    getAllInternalUsers();
    getAllExteranlUsers();
    getAllStatuses();
    getAllProjects();
    getAllOrganizations();
  }, []);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      console.log('invoked');

      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleReset = () => {
    setIsSubmit(true);
    _setSearch('');
    setSearch('');
    _setCaseType([]);
    _setPriority([]);
    _setStakeholder([]);
    _setAssignee([]);
    _setProjectSponser([]);
    _setEscalationManager([]);
    _setStatus([]);
    _setHasAttachments(false);
    _setIsEscalated(false);
    _setIsFirstResponseTimeViolated(false);
    _setIsSynced(false);
    _setIsResolveTimeViolated(false);
    if (_project) {
      _setProject([]);
    }
    if (_organization) {
      _setOrganization([]);
    }
    _setDate({
      to: null,
      from: null,
    });
    setState([
      {
        startDate: null,
        endDate: null,
        key: 'selection',
        color: '#c22027',
      },
    ]);
    _setFiltersApplied({
      search: null,
      caseType: null,
      status: null,
      priority: null,
      project: null,
      organization: null,
      escalationManager: null,
      stakeholder: null,
      projectSponser: null,
      assignee: null,
      date: null,
      thirdPartySync: null,
      escalatedCases: null,
      hasAttachments: null,
      isFirstResponseTimeViolated: null,
      isResolveTimeViolated: null,
    });
  };

  function areAllKeysNull(obj) {
    const keys = Object.keys(obj);
    const nullValuesCount = keys.filter((key) => {
      const value = obj[key];
      if (Array.isArray(value)) {
        return value.every((item) => item?.startDate === null);
      }
      return (
        value === null ||
        value === false ||
        (Array.isArray(value) && value.length === 0)
      );
    }).length;
    return keys.length === nullValuesCount;
  }
  const internalFilterStates = {
    _caseType,
    _status,
    _priority,
    _project,
    _organization,
    _escalationManager,
    _stakeholder,
    _projectSponser,
    _assignee,
    _isSynced,
    _isEscalated,
    _hasAttachments,
    _isFirstResponseTimeViolated,
    _isResolveTimeViolated,
    state,
  };

  const handleSubmit = () => {
    setIsSubmit(true);
    const modifiedFiltered = {
      caseType: _caseType,
      status: _status,
      priority: _priority,
      project: _project,
      organization: _organization,
      escalationManager: _escalationManager,
      stakeholder: _stakeholder,
      projectSponser: _projectSponser,
      assignee: _assignee,
      date: state,
      ...(_isSynced && { thirdPartySync: _isSynced }),
      ...(_isEscalated && { escalatedCases: _isEscalated }),
      ...(_hasAttachments && { hasAttachments: _hasAttachments }),
      ...(_isFirstResponseTimeViolated && {
        isFirstResponseTimeViolated: _isFirstResponseTimeViolated,
      }),
      ...(_isResolveTimeViolated && {
        isResolveTimeViolated: _isResolveTimeViolated,
      }),
    };

    const updatedFiltersApplied = { ..._filtersApplied };

    Object.keys(modifiedFiltered).forEach((key) => {
      const value = modifiedFiltered[key];

      if (Array.isArray(value)) {
        // If the value is an array, update the state accordingly
        const names = value.map((item) => item?.name).filter(Boolean);

        if (names.length > 0) {
          const result = key.replace(/([A-Z])/g, ' $1');
          const finalResult = result.charAt(0)?.toUpperCase() + result.slice(1);
          updatedFiltersApplied[key] = {
            name: finalResult,
            data: names,
          };
        }
      } else if (typeof value === 'boolean' && value) {
        // If the value is a boolean and is true, add the name property
        updatedFiltersApplied[key] = {
          name: key,
          data: [key],
        };
      } else if (key === 'date') {
        // If the key is 'date', update the state in the same format as 'name'
        updatedFiltersApplied[key] = {
          name: key,
          data: [
            value.from ? value.from : null,
            value.to ? value.to : null,
          ].filter(Boolean),
        };
      } else {
        // If the value is not an array, a true boolean, or 'date', update the state accordingly
        updatedFiltersApplied[key] = value;
      }
    });

    _setFiltersApplied(updatedFiltersApplied);
  };

  return (
    <CreateFormSideBar
      title="Advanced Filters"
      isOpen={_isOpen}
      closeDrawer={handleClose}
      customClass="advancedFilter">
      <div className={classes.advancedFilter}>
        <Box>
          <Grid container spacing={1}>
            <Grid item container spacing={1.5} md={12} xs={12}>
              <Grid item md={6}>
                <TextField
                  id="search-18323038"
                  value={search}
                  onChange={handleSearch}
                  variant="outlined"
                  name="search"
                  fullWidth
                  placeholder="Case no, summary, description..."
                  size="small"
                  inputProps={{
                    style: { minHeight: '40px' },
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <Autocomplete
                  id="issue-name"
                  options={allCaseTypes || []}
                  multiple
                  limitTags={1}
                  // ListboxProps={{ style: { maxHeight: '40px' } }}
                  size="small"
                  value={_caseType}
                  onChange={(event: any, value: any) => {
                    _setCaseType(value);
                    if (!isSubmit) return;
                    if (value?.length === 0) {
                      _setFiltersApplied({
                        ..._filtersApplied,
                        caseType: null,
                      });
                    } else {
                      _setFiltersApplied({
                        ..._filtersApplied,
                        caseType: {
                          name: 'Case Type',
                          data: value?.map((obj) => obj?.name),
                        },
                      });
                    }
                  }}
                  getOptionLabel={(option) => option.name}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Tooltip title={option?.name || ''}>
                        <Chip
                          variant="filled"
                          label={
                            index > 0 &&
                              option?.name?.length &&
                              option.name.length > 5
                              ? `${option.name.slice(0, 5)}...`
                              : option?.name || ''
                          }
                          size="small"
                          {...getTagProps({ index })}
                        />
                      </Tooltip>
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      InputLabelProps={{
                        sx: {
                          fontSize: '14px',
                        },
                      }}
                      label="Case Type"
                    />
                  )}
                />
              </Grid>
              <Grid item md={6}>
                <Autocomplete
                  id="status-183320"
                  options={allStatuses || []}
                  value={_status}
                  multiple
                  limitTags={1}
                  size="small"
                  onChange={(event: any, value: any) => {
                    _setStatus(value);
                    if (!isSubmit) return;

                    if (value?.length === 0) {
                      _setFiltersApplied({ ..._filtersApplied, status: null });
                    } else {
                      _setFiltersApplied({
                        ..._filtersApplied,
                        status: {
                          name: 'Status',
                          data: value?.map((obj) => obj?.name),
                        },
                      });
                    }
                  }}
                  getOptionLabel={(option) => option.name}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Tooltip title={option?.name || ''}>
                        <Chip
                          variant="filled"
                          label={
                            index > 0 &&
                              option?.name?.length &&
                              option.name.length > 5
                              ? `${option.name.slice(0, 5)}...`
                              : option?.name || ''
                          }
                          size="small"
                          {...getTagProps({ index })}
                        />
                      </Tooltip>
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      InputLabelProps={{
                        sx: {
                          fontSize: '14px',
                        },
                      }}
                      label="Status"
                    />
                  )}
                />
              </Grid>
              <Grid item md={6}>
                <Autocomplete
                  id="priority-183320"
                  options={allPriorities || []}
                  value={_priority}
                  multiple
                  limitTags={1}
                  size="small"
                  onChange={(event: any, value: any) => {
                    _setPriority(value);
                    if (!isSubmit) return;
                    if (value?.length === 0) {
                      _setFiltersApplied({
                        ..._filtersApplied,
                        priority: null,
                      });
                    } else {
                      _setFiltersApplied({
                        ..._filtersApplied,
                        priority: {
                          name: 'Priority',
                          data: value?.map((obj) => obj?.name),
                        },
                      });
                    }
                  }}
                  getOptionLabel={(option) => option.name}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Tooltip title={option?.name || ''}>
                        <Chip
                          variant="filled"
                          label={
                            index > 0 &&
                              option?.name?.length &&
                              option.name.length > 5
                              ? `${option.name.slice(0, 5)}...`
                              : option?.name || ''
                          }
                          size="small"
                          {...getTagProps({ index })}
                        />
                      </Tooltip>
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      InputLabelProps={{
                        sx: {
                          fontSize: '14px',
                        },
                      }}
                      label="Priority"
                    />
                  )}
                />
              </Grid>
              {_project && (
                <Grid item md={6}>
                  <Autocomplete
                    id="priority-183320"
                    options={allProjects || []}
                    value={_project}
                    multiple
                    limitTags={1}
                    size="small"
                    onChange={(event: any, value: any) => {
                      _setProject(value);
                      if (!isSubmit) return;
                      if (value?.length === 0) {
                        _setFiltersApplied({
                          ..._filtersApplied,
                          project: null,
                        });
                      } else {
                        _setFiltersApplied({
                          ..._filtersApplied,
                          project: {
                            name: 'Project',
                            data: value?.map((obj) => obj?.name),
                          },
                        });
                      }
                    }}
                    getOptionLabel={(option) => option.name}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Tooltip title={option?.name || ''}>
                          <Chip
                            variant="filled"
                            label={
                              index > 0 &&
                                option?.name?.length &&
                                option.name.length > 5
                                ? `${option.name.slice(0, 5)}...`
                                : option?.name || ''
                            }
                            size="small"
                            {...getTagProps({ index })}
                          />
                        </Tooltip>
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        InputLabelProps={{
                          sx: {
                            fontSize: '14px',
                          },
                        }}
                        label="Project"
                      />
                    )}
                  />
                </Grid>
              )}
              {_organization && (
                <Grid item md={6}>
                  <Autocomplete
                    id="project-183320"
                    options={allOrganizations || []}
                    value={_organization}
                    multiple
                    limitTags={1}
                    size="small"
                    onChange={(event: any, value: any) => {
                      _setOrganization(value);
                      if (!isSubmit) return;
                      if (value?.length === 0) {
                        _setFiltersApplied({
                          ..._filtersApplied,
                          organization: null,
                        });
                      } else {
                        _setFiltersApplied({
                          ..._filtersApplied,
                          organization: {
                            name: 'Organization',
                            data: value?.map((obj) => obj?.name),
                          },
                        });
                      }
                    }}
                    getOptionLabel={(option) => option.name}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Tooltip title={option?.name || ''}>
                          <Chip
                            variant="filled"
                            label={
                              index > 0 &&
                                option?.name?.length &&
                                option.name.length > 5
                                ? `${option.name.slice(0, 5)}...`
                                : option?.name || ''
                            }
                            size="small"
                            {...getTagProps({ index })}
                          />
                        </Tooltip>
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        InputLabelProps={{
                          sx: {
                            fontSize: '14px',
                          },
                        }}
                        label="Organization"
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item md={6}>
                <Autocomplete
                  id="escalation-manager-183320"
                  options={allInternalUsers || []}
                  value={_escalationManager}
                  multiple
                  limitTags={1}
                  size="small"
                  onChange={(event: any, value: any) => {
                    _setEscalationManager(value);
                    if (!isSubmit) return;
                    if (value?.length === 0) {
                      _setFiltersApplied({
                        ..._filtersApplied,
                        escalationManager: null,
                      });
                    } else {
                      _setFiltersApplied({
                        ..._filtersApplied,
                        escalationManager: {
                          name: 'Escalation Manager',
                          data: value?.map((obj) => obj?.name),
                        },
                      });
                    }
                  }}
                  getOptionLabel={(option) => option.name}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Tooltip title={option?.name || ''}>
                        <Chip
                          variant="filled"
                          label={
                            index > 0 &&
                              option?.name?.length &&
                              option.name.length > 5
                              ? `${option.name.slice(0, 5)}...`
                              : option?.name || ''
                          }
                          size="small"
                          {...getTagProps({ index })}
                        />
                      </Tooltip>
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      InputLabelProps={{
                        sx: {
                          fontSize: '14px',
                        },
                      }}
                      label="Escalation Manager"
                    />
                  )}
                />
              </Grid>
              <Grid item md={6}>
                <Autocomplete
                  id="stakeholder-183320"
                  options={allInternalUsers || []}
                  value={_stakeholder}
                  multiple
                  limitTags={1}
                  size="small"
                  onChange={(event: any, value: any) => {
                    _setStakeholder(value);
                    if (!isSubmit) return;
                    if (value?.length === 0) {
                      _setFiltersApplied({
                        ..._filtersApplied,
                        stakeholder: null,
                      });
                    } else {
                      _setFiltersApplied({
                        ..._filtersApplied,
                        stakeholder: {
                          name: 'Stakeholder',
                          data: value?.map((obj) => obj?.name),
                        },
                      });
                    }
                  }}
                  getOptionLabel={(option) => option.name}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Tooltip title={option?.name || ''}>
                        <Chip
                          variant="filled"
                          label={
                            index > 0 &&
                              option?.name?.length &&
                              option.name.length > 5
                              ? `${option.name.slice(0, 5)}...`
                              : option?.name || ''
                          }
                          size="small"
                          {...getTagProps({ index })}
                        />
                      </Tooltip>
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      InputLabelProps={{
                        sx: {
                          fontSize: '14px',
                        },
                      }}
                      label="Stakeholder"
                    />
                  )}
                />
              </Grid>
              <Grid item md={6}>
                <Autocomplete
                  id="project-sponser-183320"
                  options={allExternalUsers || []}
                  value={_projectSponser}
                  multiple
                  limitTags={1}
                  size="small"
                  onChange={(event: any, value: any) => {
                    _setProjectSponser(value);
                    if (!isSubmit) return;
                    if (value?.length === 0) {
                      _setFiltersApplied({
                        ..._filtersApplied,
                        projectSponser: null,
                      });
                    } else {
                      _setFiltersApplied({
                        ..._filtersApplied,
                        projectSponser: {
                          name: 'Project Sponsor',
                          data: value?.map((obj) => obj?.name),
                        },
                      });
                    }
                  }}
                  getOptionLabel={(option) => option.name}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Tooltip title={option?.name || ''}>
                        <Chip
                          variant="filled"
                          label={
                            index > 0 &&
                              option?.name?.length &&
                              option.name.length > 5
                              ? `${option.name.slice(0, 5)}...`
                              : option?.name || ''
                          }
                          size="small"
                          {...getTagProps({ index })}
                        />
                      </Tooltip>
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      InputLabelProps={{
                        sx: {
                          fontSize: '14px',
                        },
                      }}
                      label="Project Sponser"
                    />
                  )}
                />
              </Grid>
              <Grid item md={6}>
                <Autocomplete
                  id="assignee-183320"
                  options={allInternalUsers || []}
                  value={_assignee}
                  multiple
                  limitTags={1}
                  size="small"
                  onChange={(event: any, value: any) => {
                    _setAssignee(value);
                    if (!isSubmit) return;
                    if (value?.length === 0) {
                      _setFiltersApplied({
                        ..._filtersApplied,
                        assignee: null,
                      });
                    } else {
                      _setFiltersApplied({
                        ..._filtersApplied,
                        assignee: {
                          name: 'Assignee',
                          data: value?.map((obj) => obj?.name),
                        },
                      });
                    }
                  }}
                  getOptionLabel={(option) => option.name}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Tooltip title={option?.name || ''}>
                        <Chip
                          variant="filled"
                          label={
                            index > 0 &&
                              option?.name?.length &&
                              option.name.length > 5
                              ? `${option.name.slice(0, 5)}...`
                              : option?.name || ''
                          }
                          size="small"
                          {...getTagProps({ index })}
                        />
                      </Tooltip>
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      InputLabelProps={{
                        sx: {
                          fontSize: '14px',
                        },
                      }}
                      label="Assignee"
                    />
                  )}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  value={
                    state[0].startDate
                      ? moment(state[0].startDate).format('MM-DD-YYYY')
                      : 'mm/dd/yyyy'
                  }
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                  size="small"
                  label="From"
                  fullWidth
                  InputProps={{
                    style: { minHeight: '40px' },
                    endAdornment: (
                      <InputAdornment position="end">
                        <CalendarTodayIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />
                {isOpen && (
                  <Box ref={ref} className={classes.datePicker}>
                    <DateRange
                      ranges={state}
                      onChange={(item) => {
                        setState([item.selection]);
                        if (!isSubmit) return;
                        _setFiltersApplied({
                          ..._filtersApplied,
                          date: {
                            name: 'Date',
                            data: [
                              `${moment(item.selection?.startDate).format(
                                'MM-DD-YYYY'
                              )}`,
                              `${moment(item.selection?.endDate).format(
                                'MM-DD-YYYY'
                              )}`,
                            ],
                          },
                        });
                      }}
                      editableDateInputs
                    />
                  </Box>
                )}
              </Grid>
              <Grid item md={6}>
                <TextField
                  value={
                    state[0].endDate
                      ? moment(state[0].endDate).format('MM-DD-YYYY')
                      : 'mm/dd/yyyy'
                  }
                  disabled
                  size="small"
                  label="To"
                  fullWidth
                  InputProps={{
                    style: { minHeight: '40px' },
                    endAdornment: (
                      <InputAdornment position="end">
                        <CalendarTodayIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
              <Box>
                <Box>
                  <Typography fontWeight={500} className="fields">
                    Others
                  </Typography>
                  <Grid
                    container
                    columns={3}
                    spacing={3}
                    justifyContent="flex-start">
                    <Grid item>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={_isSynced}
                              onChange={toggleIsSynced}
                            />
                          }
                          label="3rd party sync"
                        />
                      </div>
                    </Grid>
                    <Grid item>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={_isEscalated}
                              onChange={toggleIsEscalated}
                            />
                          }
                          label="Escalated cases"
                        />
                      </div>
                    </Grid>
                    <Grid item>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={_hasAttachments}
                              onChange={toggleHasAttachments}
                            />
                          }
                          label="Has attachments"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Typography fontWeight={500} className="fields" marginTop={1}>
                    SLA
                  </Typography>
                  <Box display="flex" className="fields">
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={_isFirstResponseTimeViolated}
                            onChange={toggleIsFirstResponseTimeViolated}
                          />
                        }
                        label="Is 1st response time violated"
                      />
                    </div>
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={_isResolveTimeViolated}
                            onChange={toggleIsResolveTimeViolated}
                          />
                        }
                        label="Is resolve time violated"
                      />
                    </div>
                  </Box>
                </Box>
              </Box>
              <div className="btns">
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  sx={{ height: '38px' }}
                  disabled={areAllKeysNull(internalFilterStates)}
                  onClick={handleSubmit}>
                  Search
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ height: '38px', marginLeft: '12px' }}
                  disabled={areAllKeysNull(internalFilterStates)}
                  onClick={handleReset}>
                  Reset
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ height: '38px', marginLeft: 'auto' }}
                  onClick={handleClose}>
                  Close
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </CreateFormSideBar>
  );
}
