import { Typography } from '@material-ui/core';
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { okrApi } from 'src/api/okrApi';
import CustomListView from 'src/components/customTable/CustomListView';

export const ProjectGrid = () => {
  const [projectWiseSummary, setProjectWiseSummary] = useState([]);

  const columns = [
    {
      id: 'name',
      label: 'Department',
    },
    {
      id: 'To Do',
      label: 'Total Assigned',
      isSort: true,
    },
    {
      id: 'In Progress',
      label: 'Total In-Progress',
      isSort: true,
    },
    {
      id: 'Pending',
      label: 'Total De-Prioritized',
      isSort: true,
    },
    {
      id: 'Done',
      label: 'Total Completed',
      isSort: true,
    },
    {
      id: 'totalCount',
      label: 'Total Tasks',
      isSort: true,
    },
  ];

  const getProjectWiseSummary = async () => {
    try {
      const response = await okrApi.getProjectWiseSummary();
      setProjectWiseSummary(response.data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getProjectWiseSummary();
  }, []);

  return (
    <>
     <Typography color="textPrimary" variant="h4">
        Project wise tasks summary
      </Typography>
      <CustomListView
        columns={columns}
        data={projectWiseSummary}
        hidePagination
      />
    </>
  )
}
