import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import BarChart from 'src/components/barChart/Bar';
import toast from 'react-hot-toast';
import { okrApi } from 'src/api/okrApi';

const useStyles = makeStyles<Theme>(
  () =>
    createStyles({
      btn: {
        padding: 0,
      },
      graphBox: {
        padding: 16,
        marginTop: '10px',
        background: 'white',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
      },
    }),
  {
    name: 'EmployeeHourlyGraph',
  }
);

function EmployeeHourlyGraph(props: any) {
  const classes = useStyles();
  // const [departmentData, setDepartmentData] = useState([]);
  const [groupData, setGroupData] = useState({});

  const keys = [
    { key: 'totalHours', title: 'Total Hours', color: '#4285f4' },
    { key: 'consumedHours', title: 'Consumed Hours', color: '#34a853' },
    { key: 'extraHours', title: 'Extra Hours', color: '#fbbc04' },
  ];

  function parseData(jsonData: any, graphKeys: any) {
    const datasets = [
      {
        label: '',
        data: [],
        backgroundColor: [],
      },
    ];
    const labels = [];
    graphKeys.forEach((item) => {
      labels.push(item.title);
      datasets[0].data.push(jsonData[item.key]);
      datasets[0].backgroundColor.push(item.color);
    });

    return { labels, datasets };
  }

  const getEmployeeData = async () => {
    try {
      const response = await okrApi.getEmployeeHourlySummary();
      setGroupData(props?.groupDataByDepartment(response.data));
      // setDepartmentData(response.data);
    } catch (error) {
      toast.error(error.message);
    }
  };
  useEffect(() => {
    getEmployeeData();
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
      }}>
      {Object.entries(groupData).length
        ? Object.entries(groupData).map(([subDepartment, item]: any[]) => (
            <Accordion>
              <AccordionSummary>{subDepartment}</AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {item &&
                    item.map((i) => (
                      <Grid item md={6} lg={4}>
                        <Box className={classes.graphBox}>
                          <h1
                            style={{
                              color: '#000',
                              margin: 0,
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: '116.7%',
                            }}>
                            {i.name}
                          </h1>

                          <BarChart
                            data={parseData(i, keys) || []}
                            showLegend={false}
                          />
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))
        : null}
    </Box>
  );
}

export default EmployeeHourlyGraph;
