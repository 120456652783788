import { Box, ListItem, ListItemText, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { ReactNode, useEffect, useRef } from 'react';
import { borderColors } from 'src/constants';
import time from 'src/utils/time';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    meta: {
      width: '90%',
      gap: 8,
      fontSize: '14px',
      marginBottom: 8,
      fontWeight: 500,
      color: theme.palette.grey[500],
      '& .author': {
        fontWeight: 600,
        color: theme.palette.grey[600],
      },
    },
    ListItem: {
      alignItems: 'start',
      border: '2px solid #e2e2e2',
      borderRadius: 4,
      '& .MuiTypography-subtitle2': {
        color: theme.palette.grey[600],
        fontSize: '12px',
        fontWeight: 400,
        marginRight: '10px',
        textWrap: 'nowrap',
      },
      '& .MuiTypography-body2, .change': {
        color: theme.palette.grey[600],
        fontSize: '14px',
        fontWeight: 400,
        overflow: 'hidden',
      },
      '&.current': {
        border: `1px solid ${theme.palette.grey[600]}`,
      },
      '& .top': { display: 'flex', justifyContent: 'space-between' },
      '& strong': { color: theme.palette.grey[900] },
      '& .MuiListItemText-root .MuiTypography-body2': {
        overflow: 'hidden',
      },
      '& .editCommentFileParent': {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        gap: '15px',
        alignItems: 'center',
        '& a': {
          position: 'relative',
          display: 'block',
          color: ' #757575',
          cursor: 'pointer',
          border: `1px solid ${theme.palette.grey[300]}`,
          '&  .editCommentFileEdit': {
            position: 'absolute',
            top: '-10px',
            right: '-10px',
            cursor: 'pointer',
            color: '#C22027',
            zIndex: '122',
          },
          '&  span': {
            position: 'absolute',
            background: ' #f7f7f7',
            fontSize: '10px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%)',
            padding: '2px',
            fontWeight: 'bold',
          },
        },
      },
      '& .otherLogs': {
        marginBottom: '10px',
        '& .otherLogsimg': {
          width: ' 60px',
          height: '67px',
          objectFit: 'contain',
          display: 'block',
          color: ' #757575',
          cursor: 'pointer',
          border: `1px solid ${theme.palette.grey[300]}`,
        },
      },
    },
  })
);
interface Props {
  customClasses?: string;
  sx?: {};
  onClick?: any;
  meta: {
    description?: string;
    updatedBy: string;
    createdAt: string;
    change?: any;
    isExternal?: boolean;
  };
  details: any;
  actions?: Array<ReactNode | HTMLElement | string>;
  type?: string;
}
export const SingleLog: React.FC<Props> = ({
  customClasses = '',
  sx,
  meta,
  actions,
  onClick,
  details,
  type,
}: Props) => {
  const classes = useStyles();
  const notificationTime = time?.absoluteTime(meta?.createdAt, true);
  const timeSplit = notificationTime?.split('-');
  const dateAndYear = timeSplit[0]?.trim();
  const timeInMinutes = timeSplit[1]?.trim();
  const parsedHtmlRef = useRef(null);

  useEffect(() => { }, []);

  const borderColor = borderColors;
  return (
    <ListItem
      className={`${classes.ListItem} ${customClasses}`}
      style={sx}
      sx={{ borderColor: `${borderColor[type]}!important` }}
      onClick={onClick}
      ref={parsedHtmlRef}>
      <Box>
        <Typography variant="subtitle2">
          {dateAndYear}
          <br />
          {timeInMinutes}
        </Typography>

        {/* {!meta?.isExternal && (
          <Box>
            <LockIcon style={{ fontSize: 12, color: '#bdbdbd' }} />
          </Box>
        )} */}
      </Box>
      <ListItemText
        primary={
          <div className="top">
            <div className={classes.meta}>
              {meta?.description && (
                <>
                  <strong>{meta?.description}</strong>
                  {' by '}
                </>
              )}
              <strong>{`${meta?.updatedBy} `}</strong>
              <div className="change">{meta?.change}</div>
            </div>
            {actions}
          </div>
        }
        secondary={
          <div className="otherLogs">
            {details}
          </div>
        }
      />
    </ListItem>
  );
};
