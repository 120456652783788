/* eslint-disable no-underscore-dangle */
import CustomListView from 'src/components/customTable/CustomListView';
import CreateFormSideBar from 'src/components/widgets/CreateFormSideBar/CreateFormSideBar';
import { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Container,
  Tab,
  Tabs,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import useSettings from 'src/hooks/useSettings';
import HistoryIcon from '@material-ui/icons/History';
import { useDispatch, useSelector } from 'react-redux';
import {
  editCategory,
  fetchAllPimCategories,
} from 'src/store/slices/pimModuleSlice';
import { AppDispatch } from 'src/store/store';
import ToggleStatusDialogue from './ToggleStatus';
import AuditLogComponent from './auditLog';
import SearchBar from 'src/components/widgets/searchBar/SearchBar';
import setTitle from 'src/utils/setTitle';
import RestartAltIcon from '@material-ui/icons/RestartAlt';
import SimpleBreadCrumb from 'src/components/widgets/BreadCrumb/SimpleBreadCrumb';
import { useNavigate, useParams } from 'react-router';
import { statusTabs } from 'src/constants';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    btn: {
      width: '47px',
      height: '30px',
      padding: 0,
      minWidth: 'initial',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '4px',
      color: '#fff',
      marginLeft: '13px',
      '& .MuiButton-iconSizeMedium': {
        margin: '0',
      },
    },
    activeButton: {
      background: 'rgba(7, 83, 0, 0.1)',
      color: 'rgba(29, 122, 21, 1)',
      width: '100px',
    },
    inActiveButton: {
      background: 'rgba(255, 161, 161, 0.3)',
      color: 'red',
      width: '100px',
    },
    tabs: {
      '& .MuiTab-root': {
        backgroundColor: 'transparent',
        minWidth: '120px',
      },
      '& .Mui-selected': {
        backgroundColor: '#231F20',
        color: '#fff',
      },
    },
  })
);
const Categories = () => {
  setTitle('ERP | Product Information Management');

  const classes = useStyles();
  const [auditLogOpened, setAuditLogOpened] = useState(false);
  const [tabValue, setTabValue] = useState('');
  const [selectedProductId, setSelectedProductId] = useState('');
  const navigate = useNavigate();
  const { catalogueName } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const pimCategories = useSelector((state: any) => state.pim.pimCategories);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [page, setPage] = useState<any>(
    JSON.parse(localStorage.getItem('pimCategoriesPg'))?.pageNo || 0
  );
  const [pageSize, setPageSize] = useState<any>(
    JSON.parse(localStorage.getItem('pimCategoriesPg'))?.pageLimit || 25
  );
  const [search, setSearch] = useState<string>(
    JSON.parse(localStorage.getItem('pimCategoriesPg'))?.search || ''
  );
  const [selectedSort, setSelectedSort] = useState<any>(
    JSON.parse(localStorage.getItem('pimCategoriesPg'))?.sortColumn || {
      id: '',
      value: 'asc',
    });

  const [confirmStatus, setConfirmStatus] = useState(false);
  const [updateItem, setUpdateItem] = useState(null);

  const tabs = statusTabs

  const handleOpenAuditLogs = (e, data) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedProductId(data._id);
    setAuditLogOpened(true);
  };
  const handleCloseAuditLogs = () => {
    setAuditLogOpened(false);
  };

  const handleRowClick = (row) => {
    const { _id } = row;
    navigate(`${_id}`);
  };

  const confirmStatusChange = (e, item) => {
    e.stopPropagation();
    e.preventDefault();
    setConfirmStatus(true);
    setUpdateItem(item);
  };

  const handleUpdateStatus = (data) => {
    const apiPayload = { isActive: !data.isActive };
    const payload = {
      id: data._id,
      data: apiPayload,
    };
    dispatch(editCategory(payload));
  };

  const handleReset = () => {
    localStorage.removeItem('pimCategoriesPg');
    setSearch('');
    setPageSize(25);
    setPage(0);
    setSelectedSort({
      id: '',
      value: 'asc',
    });
  };
  const { settings } = useSettings();

  const logButtonRendered = (id, row) => (
    <Button
      onClick={(e) => {
        handleOpenAuditLogs(e, row);
      }}>
      <HistoryIcon />
    </Button>
  );

  const statusCellRenderer = (id, row) => (
    <Button
      className={row.isActive ? classes.activeButton : classes.inActiveButton}
      onClick={(e) => {
        confirmStatusChange(e, row);
      }}>
      {row.isActive ? 'Active' : 'In-Active'}
    </Button>
  );
  const columns = [
    { id: 'name', label: 'Name', isSort: true },
    { id: 'totalProductCount', label: 'Total Products', isSort: true },
    { id: 'activeProductCount', label: 'Active Products', isSort: true },
    { id: 'inactiveProductCount', label: 'Inactive Products', isSort: true },
    {
      id: 'status',
      label: 'Status',
      Cell: (val, row) => statusCellRenderer(val, row),
    },
    {
      id: 'logButton',
      label: 'History',
      Cell: (val, row) => logButtonRendered(val, row),
    },
  ];

  const getAllProducts = () => {
    const isActive = (tabVal) => {
      switch (tabVal) {
        case 'active':
          return true;
        case 'inActive':
          return false;
        default:
          return null;
      }
    };

    const apiPayload = {
      page,
      pageSize,
      search,
      isActive: isActive(tabValue),
      column: selectedSort?.id || 'defaultSort',
      sort: selectedSort?.value === 'asc' ? '1' : '-1',
    };
    const pimCategoriesPg = {
      pageLimit: pageSize,
      pageNo: page,
      search,
      sortColumn: selectedSort,
    };
    localStorage.setItem('pimCategoriesPg', JSON.stringify(pimCategoriesPg));
    const keys = Object.keys(apiPayload);
    const values = Object.values(apiPayload);

    // Create an array to store the non-empty key-value pairs
    const queryParams = [];
    for (let i = 0; i < values.length; i++) {
      if (values[i] !== '' && values[i] !== null && values[i] !== undefined) {
        queryParams.push(`${encodeURIComponent(keys[i])}=${values[i]}`);
      }
    }
    const queryString = queryParams.join('&');
    dispatch(fetchAllPimCategories(queryString)).then((res) => {
      setTotalCount(res?.payload?.totalRecords || 0);
    });
  };
  const handleSearch = (val) => {
    setSearch(val);
  };

  useEffect(() => {
    getAllProducts();
  }, [page, pageSize, search, tabValue, selectedSort]);
  const BreadCrumbsData = {
    title: 'Categories',
    links: [
      {
        name: 'catalogues',
        to: '/pim/catalogues',
      },
      {
        name: `${catalogueName}`,
        to: `/pim/catalogues/${catalogueName}`,
      },
    ],
  };
  return (
    <Container
      maxWidth={settings.compact ? 'xl' : false}
      sx={{
        py: 4,
      }}>
      <Grid container justifyContent="space-between" spacing={3}>
        <Grid
          item
          sx={{
            mb: 1,
          }}>
          {/* <Typography color="textPrimary" variant="h3">
            PIM
          </Typography> */}

          <Grid item md={12}>
            <SimpleBreadCrumb data={BreadCrumbsData} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <Tabs
            indicatorColor="primary"
            scrollButtons="auto"
            textColor="primary"
            value={tabValue}
            variant="scrollable"
            className={classes.tabs}>
            {tabs.map((tab) => (
              <Tab
                // key={tab?.value}
                label={tab?.label}
                value={tab?.value}
                onClick={() => {
                  setTabValue(tab?.value);
                }}
              />
            ))}
          </Tabs>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: '10px',
          }}>
          <SearchBar onSearch={handleSearch} value={search} placeholderData="Search" />

          {' '}
          <Button
            variant="contained"
            color="secondary"
            title="Reset"
            startIcon={<RestartAltIcon />}
            className={classes.btn}
            onClick={handleReset}
          />
        </Grid>
      </Grid>
      <CustomListView
        onRowClick={handleRowClick}
        columns={columns}
        data={pimCategories.data || []}
        page={page}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setPage={setPage}
        count={totalCount}
        selectedSort={selectedSort}
        setSelectedSort={setSelectedSort}
      />
      {auditLogOpened && (
        <CreateFormSideBar
          isOpen={auditLogOpened}
          closeDrawer={handleCloseAuditLogs}
          title="History">
          <AuditLogComponent productId={selectedProductId} />
        </CreateFormSideBar>
      )}

      {confirmStatus && (
        <ToggleStatusDialogue
          isOpen={confirmStatus}
          data={updateItem}
          close={() => setConfirmStatus(false)}
          handleConfrim={handleUpdateStatus}
        />
      )}
    </Container>
  );
};

export default Categories;
