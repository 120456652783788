import { Avatar, Box, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    root: {
      marginTop: '12px', // Reduced margin
      '& .userInfo': {
        display: 'flex',
        alignItems: 'center',
        '& .MuiTypography-root': {
          marginLeft: '10px', // Reduced margin between avatar and text
          fontSize: '12px', // Smaller text size for normal text
        },
      },
      '& .requestReason': {
        marginTop: '12px', // Reduced margin
        '& h4': {
          color: '#423636',
          fontSize: '14px', // Smaller heading size
        },
        '& .MuiTypography-root': {
          fontSize: '12px', // Reduced size for the answer text
        },
      },
    },
    avatar: {
      width: '30px', // Smaller avatar size
      height: '30px', // Smaller avatar size
    },
  })
);

type RequestInfoTypes = {
  creater: string;
  date: string;
  answer: string;
};

export const RequestInfo = ({ creater, date, answer }: RequestInfoTypes) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box className="userInfo">
        <Avatar src="assets/images/avatar.png" className={classes.avatar} />
        <Typography>
          <span style={{ fontWeight: 'bold' }}>{creater}</span> raised this on{' '}
          {moment(date).format('LL')}
        </Typography>
      </Box>
      <Box className="requestReason">
        <Typography>{answer}</Typography>
      </Box>
    </div>
  );
};
