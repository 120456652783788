/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, Drawer, TextField, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { casesApi } from 'src/api/casesApi';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import MDEditor, { selectWord } from '@uiw/react-md-editor';
import { useParams } from 'react-router';
import { organizationApi } from 'src/api/organizationApi';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { projectApi } from 'src/api/projectApi';
import * as Yup from 'yup';
import logger from 'src/utils/logger';
import MultiFileLinkInput from 'src/components/MultiFileLinkInput';
// import parseHTML from 'html-react-parser';

interface CreateProjectTypes {
  isOpen: boolean;
  addProject: (isFalse: boolean, e: any) => void;
  closeDrawer: () => void;
  getAllProjectsByOrg: () => void;
}

interface Input {
  id: number;
  value: string;
  label: string;
}

const CreateProject: FC<any> = ({
  isOpen,
  addProject,
  closeDrawer,
  getAllProjectsByOrg
}: CreateProjectTypes) => {
  const useStyles = makeStyles<Theme>(() => createStyles({
    CreateProject: {
      width: '40%',
      padding: '5px 20px ',
      zIndex: 1300,
      border: 'none',
      '& .ticketBody': {
        height: '90vh',
        overflowY: 'auto',
        padding: '0 20px',
        '& input': {
          borderRadius: '2px'
        }
      },
      '& h4': {
        background: '#C22027',
        color: '#fff',
        padding: '0 40px',
        margin: '-5px -20px 30px',
        height: '64px',
        display: 'flex',
        alignItems: 'center'
      },
      '& label': {
        letterSpacing: ' 0.15px',
        color: '#231F20',
        fontWeight: '600',
        paddingBottom: '6px',
        display: 'block'
      },

      '& select, & .MuiAutocomplete-inputRoot, & input': {
        height: '38px',
        padding: '0 10px',
        lineHeight: '116.7%',
        color: '#696969',
        width: '100%',
        '& .MuiAutocomplete-input': {
          padding: 0
        }
      },
      '& .ticketDropDown+.ticketDropDown': {
        marginTop: '15px'
      },
      '& .submitTicket ': {
        margin: '35px 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      '& .submitTicket button+button': {
        marginLeft: '15px'
      }
    },
    datePicker: {
      '&:hover': {
        cursor: 'pointer'
      }
    }
  }));

  const classes = useStyles();
  const { organizationID } = useParams();
  const [escalationManager, setEscalationManager] = useState<any>(JSON.parse(localStorage?.getItem('formikValuesProject'))?.escalationManagers?.name || '');
  const [description, setDescription] = useState<any>('');
  const [allExternalUsers, setAllExternalUsers] = useState<any>([]);
  const [allUsersInternal, setAllUsersInternal] = useState<any>([]);
  const [jiraProjects, setJiraProjects] = useState<any>([]);
  const [platforms, setPlatforms] = useState<any>([]);
  const [projectSponserInput, setProjectSponserInput] = useState<string>('');
  const [thirdPartyInput, setThirdPartyInput] = useState<string>(JSON.parse(localStorage?.getItem('formikValuesProject'))?.thirdPartyProjectId?.name || '');
  const [platformInput, setPlatformInput] = useState<string>(JSON.parse(localStorage?.getItem('formikValuesProject'))?.platforms?.name || '');
  const [stakeHolderInput, setStakeHolderInput] = useState<string>('');
  const [googleDocLinks, setGoogleDocLinks] = useState<Input[]>([{ id: Date.now(), value: '', label: '' }]);
  const [drawioLinks, setDrawioLinks] = useState<Input[]>([{ id: Date.now(), value: '', label: '' }]);
  const [figmaLinks, setFigmaLinks] = useState<Input[]>([{ id: Date.now(), value: '', label: '' }]);
  const [lowLevelDesignLinks, setLowLevelDesignLinks] = useState<Input[]>([{ id: Date.now(), value: '', label: '' }]);
  const [highLevelDesignLinks, setHighLevelDesignLinks] = useState<Input[]>([{ id: Date.now(), value: '', label: '' }]);

  const schema = Yup.object({
    projectName: Yup.string()
      .required('Project name required'),
    projKey: Yup.string()
      .required('Project Key required'),
    escalationManagers: Yup.mixed()
      .test('escalationManagers', 'Escalation Manager must be selected', (value) => value && typeof value === 'object' && value.id !== undefined),
    projectSponsor: Yup.mixed()
      .test('projectSponsor', 'Project Sponsor must be selected', (value) => (Array.isArray(value) ? value.length > 0 : false)),
    stakeholder: Yup.mixed()
      .test('stakeholder', 'Stakeholder must be selected', (value) => (Array.isArray(value) ? value.length > 0 : false)),
    SLAFirstResponseTime: Yup.number().min(1, 'Must be greater than or equal to 1 hour')
      .max(8, 'Must be less than or equal to 8 hours').required('SLA First Response Time is required'),
    SLAResolveTime: Yup.number().min(1, 'Must be greater than or equal to 1 hour')
      .max(72, 'Must be less than or equal to 72 hours').required('SLA Resolve Time is required'),
    domain: Yup.string().required('Domain name required'),
    website: Yup.string().required('Website URL required').url('Website URL invalid'),
  });

  const formik = useFormik({
    initialValues: {
      projectName: '',
      projKey: '',
      organizationId: organizationID,
      escalationManagers: null,
      isActive: true,
      logo: 'string',
      description,
      domain: '',
      website: '',
      SLAFirstResponseTime: '',
      SLAResolveTime: '',
      projectSponsor: [],
      stakeholder: [],
      thirdPartyProjectId: null,
      platforms: null,
    },
    onSubmit: async (values) => {
      const payload = {
        name: values?.projectName,
        organizationId: organizationID,
        escalationManager: values?.escalationManagers?.id,
        projectSponsor: values?.projectSponsor,
        stakeHolder: values?.stakeholder,
        firstResponseTime: values?.SLAFirstResponseTime,
        resolvedTime: values?.SLAResolveTime,
        isActive: true,
        logo: 'string',
        thirdPartyProjectId: values?.thirdPartyProjectId?.id,
        description,
        domain: values?.domain,
        website: values?.website,
        key: values?.projKey?.toUpperCase(),
        thirdPartyPlatformId: values?.platforms?.id,
        docUrls: {
          googleDocLinks: googleDocLinks.map(link => ({ url: link.value, label: link.label })),
          drawIOLinks: drawioLinks.map(link => ({ url: link.value, label: link.label })),
          figmaLinks: figmaLinks.map(link => ({ url: link.value, label: link.label })),
          lowLevelDesign: lowLevelDesignLinks.map(link => ({ url: link.value, label: link.label })),
          highLevelDesign: highLevelDesignLinks.map(link => ({ url: link.value, label: link.label })),
        }
      };
      try {
        const response = await organizationApi.createProject(payload);
        if (response?.status === 201) {
          toast.success('Created Successfully');
          localStorage.removeItem('formikValuesProject');
          closeDrawer();
          getAllProjectsByOrg();
        }
      } catch (error) {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error?.response?.data?.message);
        }
      }
    },
    validationSchema: schema
  });

  const getAllUsersInternal = async () => {
    try {
      const response = await casesApi.getUserById(false);
      if (response?.status === 200) {
        setAllUsersInternal(response?.data);
      }
    } catch (error) {
      setAllUsersInternal([]);
    }
  };
  const getAllExternalUsersAutoComplete = async () => {
    try {
      const res = await projectApi?.getUsersByProj();
      setAllExternalUsers(res?.data);
    } catch (error) {
      setAllExternalUsers([]);
    }
  };
  const getJiraProjects = async (platformId: string) => {
    try {
      const res = await projectApi?.getJiraProjects(platformId);
      setJiraProjects(res?.data);
    } catch (error) {
      setJiraProjects([]);
    }
  };
  const getPlatformsData = async () => {
    try {
      const res = await projectApi?.getPlatforms();
      setPlatforms(res?.data);
    } catch (error) {
      setPlatforms([]);
    }
  };

  const addDataToLocalStorage = (column, data) => {
    const obj = JSON.parse(localStorage?.getItem('formikValuesProject'));
    localStorage.setItem('formikValuesProject', JSON.stringify({
      ...obj,
      [column]: data
    }));
  };

  const setFormikValuesFromLocalStorage = () => {
    const storedFromikValues = JSON.parse(localStorage?.getItem('formikValuesProject'));
    logger(storedFromikValues, '', 'stored formik values');
    if (storedFromikValues) {
      formik?.setValues({
        projectName: storedFromikValues?.projectName || '',
        projKey: storedFromikValues?.projKey || '',
        organizationId: organizationID,
        escalationManagers: storedFromikValues?.escalationManagers,
        isActive: true,
        logo: 'string',
        description: storedFromikValues?.description || '',
        domain: storedFromikValues?.domain || '',
        website: storedFromikValues?.website || '',
        SLAFirstResponseTime: storedFromikValues?.SLAFirstResponseTime || '',
        SLAResolveTime: storedFromikValues?.SLAResolveTime || '',
        projectSponsor: storedFromikValues?.projectSponsor || [],
        stakeholder: storedFromikValues?.stakeholder || [],
        thirdPartyProjectId: storedFromikValues?.thirdPartyProjectId || null,
        platforms: storedFromikValues?.platforms || null,
      });
      if (storedFromikValues?.description) {
        setDescription(storedFromikValues?.description || '');
      }
    }
    logger(storedFromikValues?.escalationManagers, '', 'formikEscalationManager');
  };

  const handleReset = () => {
    localStorage.removeItem('formikValuesProject');
    setEscalationManager('');
    setPlatformInput('');
    setThirdPartyInput('');
    setDescription('');
    formik?.setValues({
      projectName: '',
      projKey: '',
      organizationId: organizationID,
      escalationManagers: null,
      isActive: true,
      logo: 'string',
      description,
      domain: '',
      website: '',
      SLAFirstResponseTime: '',
      SLAResolveTime: '',
      projectSponsor: [],
      stakeholder: [],
      thirdPartyProjectId: null,
      platforms: null,
    });
    setGoogleDocLinks([{ id: Date.now(), value: '', label: '' }]); // Reset google doc links
    setDrawioLinks([{ id: Date.now(), value: '', label: '' }]); // Reset drawio links
    setFigmaLinks([{ id: Date.now(), value: '', label: '' }]); // Reset figma links
    setLowLevelDesignLinks([{ id: Date.now(), value: '', label: '' }]); // Reset low level design links
    setHighLevelDesignLinks([{ id: Date.now(), value: '', label: '' }]); // Reset high level design links
  };

  const handleAddInput = (setter: React.Dispatch<React.SetStateAction<Input[]>>) => {
    setter((prevInputs) => [
      ...prevInputs,
      { id: Date.now(), value: '', label: '' },
    ]);
  };

  const handleRemoveInput = (setter: React.Dispatch<React.SetStateAction<Input[]>>, id: number) => {
    setter((prevInputs) => prevInputs.filter((input) => input.id !== id));
  };

  const handleInputChange = (setter: React.Dispatch<React.SetStateAction<Input[]>>, id: number, value: string) => {
    setter((prevInputs) =>
      prevInputs.map((input) =>
        input.id === id ? { ...input, value } : input
      )
    );
  };

  const handleLabelChange = (setter: React.Dispatch<React.SetStateAction<Input[]>>, id: number, label: string) => {
    setter((prevInputs) =>
      prevInputs.map((input) =>
        input.id === id ? { ...input, label } : input
      )
    );
  };

  useEffect(() => {
    getAllUsersInternal();
    getAllExternalUsersAutoComplete();
    getPlatformsData();
    setFormikValuesFromLocalStorage();
  }, []);
  return (
    <div className="createTicket">
      <Drawer
        // variant="persistent"
        open={isOpen}
        anchor="right"
        onClose={(e) => addProject(false, e)}
        classes={{ paper: classes.CreateProject }}
      >
        <Typography
          variant="h4"
          component="h4"
        >
          Create Project
        </Typography>
        <div className="ticketBody">
          <div className="ticketDropDown">
            <TextField
              id="outlined-select-status-native"
              value={formik?.values?.projectName}
              variant="outlined"
              name="projectName"
              fullWidth
              onChange={(e) => {
                formik?.handleChange(e);
                addDataToLocalStorage('projectName', e.target.value);
              }}
              placeholder="Name*"
              error={Boolean(
                formik?.errors?.projectName
                && formik?.touched?.projectName
              )}
              helperText={formik?.touched?.projectName && formik?.errors?.projectName}
            />
          </div>
          <div className="ticketDropDown">
            <TextField
              id="outlined-select-status-native"
              value={formik?.values?.projKey}
              variant="outlined"
              name="projKey"
              fullWidth
              onChange={(e) => {
                formik?.handleChange(e);
                addDataToLocalStorage('projKey', e.target.value);
              }}
              placeholder="Project Key*"
              error={Boolean(
                formik?.errors?.projKey
                && formik?.touched?.projKey
              )}
              helperText={formik?.touched?.projKey && formik?.errors?.projKey}
            />
          </div>
          <div className="ticketDropDown">
            <div data-color-mode="light">
              <CKEditor
                editor={ClassicEditor}
                data={description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  function extractTextFromHTML(htmlString) {
                    // Create a new DOMParser
                    const parser = new DOMParser();

                    // Parse the HTML string
                    const doc = parser.parseFromString(htmlString, 'text/html');

                    // Get the text content from the parsed HTML
                    const { textContent } = doc.body;

                    return textContent;
                  }
                  const extractedText = extractTextFromHTML(data);
                  setDescription(extractedText);
                  addDataToLocalStorage('description', data);
                }}
                config={
                  {
                    placeholder: 'Enter your Description here',
                    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'uploadImage']
                  }
                }
              />
            </div>
          </div>
          <div className="ticketDropDown">
            <Autocomplete
              id="escalationManagers"
              options={allUsersInternal || []}
              value={formik?.values?.escalationManagers}
              onChange={(event: any, value: any) => {
                formik?.setFieldValue('escalationManagers', value);
                addDataToLocalStorage('escalationManagers', value);
              }}
              getOptionLabel={(option) => option.name}
              inputValue={escalationManager}
              onInputChange={(event: any, newInputValue: any) => {
                if (event) {
                  setEscalationManager(newInputValue);
                }
              }}
              style={{ minWidth: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Escalation Manager*"
                  name="escalationManagers"
                  error={Boolean(
                    formik?.errors?.escalationManagers
                    && formik?.touched?.escalationManagers
                  )}
                  helperText={formik?.touched?.escalationManagers && formik?.errors?.escalationManagers}
                />
              )}
            />
          </div>
          <div className="ticketDropDown">
            <Autocomplete
              id="sponsorId-name"
              options={allExternalUsers?.length === 0 ? [] : allExternalUsers}
              onChange={(event: any, value: any) => {
                formik?.setFieldValue('projectSponsor', value);
                addDataToLocalStorage('projectSponsor', value);
              }}
              value={formik?.values?.projectSponsor}
              multiple
              limitTags={1}
              getOptionLabel={(option) => Object.keys(option).length !== 0 && option?.name}
              inputValue={projectSponserInput}
              onInputChange={(event: any, newInputValue: any) => {
                if (event) {
                  setProjectSponserInput(newInputValue);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  name="projectSponsor"
                  placeholder={`${formik?.values?.projectSponsor?.length === 0 || formik?.values?.projectSponsor == null ? 'Project Sponser*' : ''} `}
                  error={Boolean(
                    formik?.errors?.projectSponsor
                    && formik?.touched?.projectSponsor
                  )}
                  helperText={formik?.touched?.projectSponsor && formik?.errors?.projectSponsor}
                />
              )}
            />
          </div>
          <div className="ticketDropDown">
            <Autocomplete
              id="stakeHolder-name"
              options={allExternalUsers?.length === 0 ? [] : allExternalUsers.filter((itm1) => !formik?.values?.stakeholder?.some((itm2: any) => itm1.id === itm2.id))}
              onChange={(event: any, value: any) => {
                formik?.setFieldValue('stakeholder', value);
                addDataToLocalStorage('stakeholder', value);
              }}
              value={formik?.values?.stakeholder}
              multiple
              limitTags={1}
              getOptionLabel={(option) => Object.keys(option).length !== 0 && option?.name}
              inputValue={stakeHolderInput}
              onInputChange={(event: any, newInputValue: any) => {
                if (event) {
                  setStakeHolderInput(newInputValue);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  name="stakeholder"
                  placeholder={formik?.values?.stakeholder?.length === 0 || formik?.values?.stakeholder == null ? 'Stakeholder*' : ''}
                  error={Boolean(
                    formik?.errors?.stakeholder
                    && formik?.touched?.stakeholder
                  )}
                  helperText={formik?.touched?.stakeholder && formik?.errors?.stakeholder}
                />
              )}
            />
          </div>
          <div className="ticketDropDown">
            <Autocomplete
              id="sponsorId-name"
              options={platforms?.length === 0 ? [] : platforms}
              onChange={(event: any, value: any) => {
                if (value !== null) {
                  formik?.setFieldValue('platforms', value);
                  addDataToLocalStorage('platforms', value);
                  getJiraProjects(value?.id);
                } else {
                  formik?.setFieldValue('platforms', { id: '', name: '' });
                  setJiraProjects([]);
                }
              }}
              value={formik?.values?.platforms}
              limitTags={1}
              getOptionLabel={(option) => Object.keys(option).length !== 0 && option?.name}
              inputValue={platformInput}
              onInputChange={(event: any, newInputValue: any) => {
                if (event) {
                  setPlatformInput(newInputValue);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  name="platforms"
                  placeholder="Third Party Platform"
                  error={Boolean(
                    formik?.errors?.platforms
                    && formik?.touched?.platforms
                  )}
                  helperText={formik?.touched?.platforms && formik?.errors?.platforms}
                />
              )}
            />
          </div>
          <div className="ticketDropDown">
            <Autocomplete
              id="sponsorId-name"
              options={jiraProjects?.length === 0 ? [] : jiraProjects}
              onChange={(event: any, value: any) => {
                formik?.setFieldValue('thirdPartyProjectId', value);
                addDataToLocalStorage('thirdPartyProjectId', value);
              }}
              value={formik?.values?.thirdPartyProjectId}
              limitTags={1}
              getOptionLabel={(option) => Object.keys(option).length !== 0 && option?.name}
              inputValue={thirdPartyInput}
              onInputChange={(event: any, newInputValue: any) => {
                if (event) {
                  setThirdPartyInput(newInputValue);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  name="thirdPartyProjectId"
                  placeholder="Third Party Project"
                  disabled={jiraProjects?.length === 0}
                  error={Boolean(
                    formik?.errors?.thirdPartyProjectId
                    && formik?.touched?.thirdPartyProjectId
                  )}
                  helperText={formik?.touched?.thirdPartyProjectId && formik?.errors?.thirdPartyProjectId}
                />
              )}
            />
          </div>
          <div className="ticketDropDown">
            <TextField
              id="outlined-select-status-native"
              variant="outlined"
              name="SLAFirstResponseTime"
              type="number"
              value={formik?.values?.SLAFirstResponseTime}
              fullWidth
              onChange={(e) => {
                formik?.handleChange(e);
                addDataToLocalStorage('SLAFirstResponseTime', e.target.value);
              }}
              placeholder="SLA First Response Time*"
              error={Boolean(formik?.touched?.SLAFirstResponseTime && formik?.errors?.SLAFirstResponseTime)}
              helperText={formik?.touched?.SLAFirstResponseTime && formik?.errors?.SLAFirstResponseTime}
            />
          </div>
          <div className="ticketDropDown">
            <TextField
              id="outlined-select-status-native"
              variant="outlined"
              name="SLAResolveTime"
              type="number"
              value={formik?.values?.SLAResolveTime}
              fullWidth
              onChange={(e) => {
                formik?.handleChange(e);
                addDataToLocalStorage('SLAResolveTime', e.target.value);
              }}
              placeholder="SLA Resolve Time*"
              error={Boolean(formik?.touched?.SLAResolveTime && formik?.errors?.SLAResolveTime)}
              helperText={formik?.touched?.SLAResolveTime && formik?.errors?.SLAResolveTime}
            />
          </div>
          <div className="ticketDropDown">
            <TextField
              id="outlined-select-status-native"
              variant="outlined"
              name="domain"
              fullWidth
              onChange={(e) => {
                formik?.handleChange(e);
                addDataToLocalStorage('domain', e.target.value);
              }}
              value={formik?.values?.domain}
              placeholder="Domain*"
              error={Boolean(formik?.touched?.domain && formik?.errors?.domain)}
              helperText={formik?.touched?.domain && formik?.errors?.domain}
            />
          </div>
          <div className="ticketDropDown">
            <TextField
              id="outlined-select-status-native"
              variant="outlined"
              name="website"
              fullWidth
              value={formik?.values?.website}
              onChange={(e) => {
                formik?.handleChange(e);
                addDataToLocalStorage('website', e.target.value);
              }}
              placeholder="Website*"
              error={Boolean(formik?.touched?.website && formik?.errors?.website)}
              helperText={formik?.touched?.website && formik?.errors?.website}
            />
          </div>
          <div className="ticketDropDown">
            <TextField
              id="outlined-select-status-native"
              variant="outlined"
              name="caseLink"
              type="file"
              fullWidth
              onChange={(e) => formik?.handleChange(e)}
              placeholder="Upload Image"
              InputProps={{
                style: { padding: '10px 0' }
              }}
            />
          </div>
          <div className="ticketDropDown">
            <MultiFileLinkInput
              inputs={googleDocLinks}
              onAddInput={() => handleAddInput(setGoogleDocLinks)}
              onRemoveInput={(id) => handleRemoveInput(setGoogleDocLinks, id)}
              onInputChange={(id, event) => handleInputChange(setGoogleDocLinks, id, event.target.value)}
              onLabelChange={(id, event) => handleLabelChange(setGoogleDocLinks, id, event.target.value)}
              placeholder="Google Doc Link"
              labelPlaceholder='Google Doc Label'
            />
          </div>
          <div className="ticketDropDown">
            <MultiFileLinkInput
              inputs={drawioLinks}
              onAddInput={() => handleAddInput(setDrawioLinks)}
              onRemoveInput={(id) => handleRemoveInput(setDrawioLinks, id)}
              onInputChange={(id, event) => handleInputChange(setDrawioLinks, id, event.target.value)}
              onLabelChange={(id, event) => handleLabelChange(setDrawioLinks, id, event.target.value)}
              placeholder="DrawIO Link"
              labelPlaceholder='DrawIO Label'
            />
          </div>
          <div className="ticketDropDown">
            <MultiFileLinkInput
              inputs={figmaLinks}
              onAddInput={() => handleAddInput(setFigmaLinks)}
              onRemoveInput={(id) => handleRemoveInput(setFigmaLinks, id)}
              onInputChange={(id, event) => handleInputChange(setFigmaLinks, id, event.target.value)}
              onLabelChange={(id, event) => handleLabelChange(setFigmaLinks, id, event.target.value)}
              placeholder="Figma Link"
              labelPlaceholder='Figma Label'
            />
          </div>
          <div className="ticketDropDown">
            <MultiFileLinkInput
              inputs={lowLevelDesignLinks}
              onAddInput={() => handleAddInput(setLowLevelDesignLinks)}
              onRemoveInput={(id) => handleRemoveInput(setLowLevelDesignLinks, id)}
              onInputChange={(id, event) => handleInputChange(setLowLevelDesignLinks, id, event.target.value)}
              onLabelChange={(id, event) => handleLabelChange(setLowLevelDesignLinks, id, event.target.value)}
              placeholder="Low Level Design (Drive Link)"
              labelPlaceholder='Low Level Design Label'
            />
          </div>
          <div className="ticketDropDown">
            <MultiFileLinkInput
              inputs={highLevelDesignLinks}
              onAddInput={() => handleAddInput(setHighLevelDesignLinks)}
              onRemoveInput={(id) => handleRemoveInput(setHighLevelDesignLinks, id)}
              onInputChange={(id, event) => handleInputChange(setHighLevelDesignLinks, id, event.target.value)}
              onLabelChange={(id, event) => handleLabelChange(setHighLevelDesignLinks, id, event.target.value)}
              placeholder="High Level Design (Drive Link)"
              labelPlaceholder='High Level Design Label'
            />
          </div>

          <div className="submitTicket">
            <div>
              <Button
                // variant="outlined"
                color="primary"
                onClick={() => handleReset()}
                sx={{ marginLeft: 'auto', height: '38px' }}
              >
                Reset
              </Button>
            </div>
            <div>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => closeDrawer()}
                sx={{ marginLeft: 'auto', height: '38px' }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  logger(formik?.values, '', 'values');
                  formik?.handleSubmit();
                }}
                sx={{ height: '38px' }}
              >
                Create
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default CreateProject;
