import { Box, Grid, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { requestApi } from 'src/api/requestApi';
import AttachmentViewer from 'src/components/attachments/AttachmentViewer';
import { RequestInfo } from 'src/components/requests/RequestInfo';
import { Comments } from './Comments';
import logger from 'src/utils/logger';
import { STATUS, STATUSENUMS } from 'src/utils/constants';
import toast from 'react-hot-toast';
import SimpleBreadCrumb from 'src/components/widgets/BreadCrumb/SimpleBreadCrumb';
import setTitle from 'src/utils/setTitle';
import moment from 'moment';
import LeaveManagement from './LeaveManagement';

const requestViewStyles = makeStyles<Theme>(() =>
  createStyles({
    root: {
      padding: '10px 12px', // Reduced padding
      '& h4': {
        fontSize: '14px', // Smaller heading
        fontWeight: '500',
      },
      '& .DateStyle': {
        display: 'flex',
        justifyContent: 'start',
        gap: '12px',
      },
      '& .requestViewWrapper': {
        margin: '20px auto 0', // Reduced margin
        '& .requestViewDetails': {
          borderRadius: '4px',
          padding: '20px 30px', // Reduced padding
          backgroundColor: 'white',
          '& h1': {
            color: '#423636',
            fontSize: '16px', // Smaller heading
            fontWeight: '500',
          },
        },
        '& .requestViewStatus': {
          padding: '20px 30px', // Reduced padding
          backgroundColor: 'white',
          borderRadius: '4px',
          '& .actions': {
            marginTop: '15px', // Reduced margin
            '& .statusAction': {
              display: 'flex',
              gap: '8px',
              padding: '4px 8px', // Smaller padding
              borderRadius: '4px',
              marginBottom: '8px',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#F2F2F2',
              },
              fontSize: '12px', // Smaller text
            },
          },
        },
      },
    },
    iconButton: {
      padding: '5px', // Smaller icon button padding
    },
    smallIcon: {
      fontSize: '16px', // Smaller icon size
    },
    text: {
      fontSize: '12px', // Smaller font size for normal text
    },
  })
);

export const EditRequest = () => {
  setTitle('ERP | Internal Requests');
  const classes = requestViewStyles();
  const [requestData, setRequestData] = useState<any>({});
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const toggleIsLoad = () => setIsLoad(!isLoad);
  const { requestID } = useParams();

  const getByIdRequest = async () => {
    try {
      const response = await requestApi.getByKeyOrId(requestID);
      if (response?.status === 200) {
        setRequestData(response?.data);
      }
    } catch (error) {
      setRequestData({});
    }
  };

  const updateStatus = async (statusId: number) => {
    try {
      const response = await requestApi.updateStatus(requestID, statusId);
      logger(response, 'update-status: response: ');
      if (response?.status === 200) {
        toggleIsLoad();
      }
    } catch (error) {
      logger(error, 'updateStatus');
      toast.error('Something Went Wrong. Try Again!');
    }
  };

  const breadCrumbs = {
    title: '',
    links: [
      {
        name: 'Internal Requests',
        to: '/erp/internal-requests',
      },
      {
        name: requestID,
        to: ``,
      },
    ],
  };

  useEffect(() => {
    getByIdRequest();
  }, [isLoad]);

  return (
    <>
      <Box className={classes.root}>
        <Box className="requestViewWrapper">
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <Box className="requestViewDetails">
                {/* Heading from the props come under here */}
                <SimpleBreadCrumb data={breadCrumbs} />
                <Typography variant="h1" marginTop="12px">
                  {requestData?.summary || ''}
                </Typography>
                <RequestInfo
                  creater={requestData?.createdBy?.name}
                  date={requestData?.createdAt || ''}
                  answer={requestData?.description}
                />
                <hr style={{ margin: '10px 0' }} />
                <AttachmentViewer data={requestData?.attachmentId} />
                {requestData?.attachmentId?.length > 0 && (
                  <hr style={{ margin: '10px 0' }} />
                )}
                <Comments
                  requestData={requestData}
                  toggleIsLoad={toggleIsLoad}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box className="requestViewStatus">
                <Box marginBottom="20px">
                  <Typography variant="h4" gutterBottom>
                    Status
                  </Typography>
                  <span
                    style={{
                      color: 'blue',
                      fontWeight: 400,
                      textTransform: 'capitalize',
                      fontSize: '12px', // Compact text size
                    }}>
                    {STATUS[`${requestData?.status}`]}
                  </span>
                  {requestData?.status !== STATUSENUMS.Canceled ? (
                    <Box className="actions">
                      <Box
                        className="statusAction"
                        onClick={() => updateStatus(1)}>
                        <CompareArrowsIcon className={classes.smallIcon} />
                        Inprogress this issue
                      </Box>
                      <Box
                        className="statusAction"
                        onClick={() => updateStatus(2)}>
                        <CompareArrowsIcon className={classes.smallIcon} />
                        Resolve this issue
                      </Box>
                      <Box
                        className="statusAction"
                        onClick={() => updateStatus(3)}>
                        <CompareArrowsIcon className={classes.smallIcon} />
                        Cancel request
                      </Box>
                    </Box>
                  ) : null}
                </Box>
                <Box>
                  <Typography variant="h4" gutterBottom>
                    Request Type
                  </Typography>
                  <Box
                    display="flex"
                    gap="8px"
                    alignItems="center"
                    marginTop="12px">
                    <Typography
                      className={classes.text}
                      style={{ color: '#717171' }}>
                      {requestData?.categoryName}
                    </Typography>
                  </Box>
                </Box>
                <Box className="DateStyle">
                  <Box mt={3}>
                    <Typography variant="h4" gutterBottom>
                      Start Date
                    </Typography>
                    <Box
                      display="flex"
                      gap="8px"
                      alignItems="center"
                      marginTop="12px">
                      <Typography
                        className={classes.text}
                        style={{ color: '#717171' }}>
                        {moment(requestData?.startDate).format('MMMM D, YYYY')}
                      </Typography>
                    </Box>
                  </Box>
                  <Box mt={3}>
                    <Typography variant="h4" gutterBottom>
                      End Date
                    </Typography>
                    <Box
                      display="flex"
                      gap="8px"
                      alignItems="center"
                      marginTop="12px">
                      <Typography
                        className={classes.text}
                        style={{ color: '#717171' }}>
                        {moment(requestData?.endDate).format('LL')}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {console.log(requestData)}
              {(requestData.categoryName === 'Request Planned Leave(s)' ||
                requestData.categoryName === 'Request Sick Leave(s)') && (
                <LeaveManagement
                  caseKey={requestData?.key}
                  caseId={requestData?.id}
                  requestData={requestData} // Pass the requestData for planned leaves
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default EditRequest;
