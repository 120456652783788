import { Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { itsmFlowApi } from 'src/api/itsmFlowApi';
import TableList from 'src/components/widgets/tables/TableList';
import logger from 'src/utils/logger';

const ItsmFlowListView = () => {
  const baseUrl = '/erp/settings';
  const [allFlows, setAllFlows] = useState<Array<any>>([]);
  const [count, setCount] = useState<number>(0);
  const [isActivated, setIsActivated] = useState<boolean | null>(null);
  const [page, setPage] = useState<any>(
    JSON.parse(localStorage.getItem('itsmFlowPagePg'))?.itsmFlowPageNo || 0
  );
  const [pageSize, setPageSize] = useState<any>(
    JSON.parse(localStorage.getItem('itsmFlowPagePg'))?.itsmFlowPageSize || 25
  );
  const [search, setSearch] = useState(
    JSON.parse(localStorage.getItem('itsmFlowPagePg'))?.itsmFlowSearch || ''
  );
  const [isDataRendered, setIsDataRendered] = useState<boolean>(false);
  logger(setIsDataRendered);

  const handleSearch = (val: any) => {
    setSearch(val);
  };

  const columns: Array<any> = [
    {
      name: 'Identifier',
      value: 'identifier',
    },
    {
      name: 'Name',
      value: 'name',
    },
    {
      name: 'Category Name',
      value: 'categoryId',
    },
    {
      name: 'Status',
      value: 'isActive',
    },
  ];

  const handlePage = (temp: number) => {
    setPage(temp);
  };
  const handlePageSize = (temp: number) => {
    setPageSize(temp);
  };

  const handleBulkDeactivate = async (checkedIds: Array<number>) => {
    try {
      //   const response = await employeesApi.bulkDeactivate(checkedIds);
      //   if (response?.status === 200) {
      //     toast.success('updated successfully');
      //     setIsDataRendered(!isDataRendered);
      //   }
      //   if (response?.status === 204) {
      //     toast.error('try again later');
      //   }
      logger(checkedIds, '', 'handle-bulk-deactivate: checkedIds: ');
      toast.success('Successfuly Deactivated');
    } catch (error) {
      logger(error, 'error');
      toast.error('try again later');
    }
  };

  const handleStatusToggle = async (id: number, _isActivated: boolean) => {
    try {
      //   const response: any = await employeesApi.updateStatusById(
      //     id,
      //     _isActivated
      //   );
      //   if (response?.status === 200) {
      //     toast.success('updated successfully');
      //     setIsDataRendered(!isDataRendered);
      //   }
      //   if (response?.status === 204) {
      //     toast.error('try again later');
      //   }
      //   logger(response);
      logger(id, '', 'handle-status-toggle: id: isActivated');
      logger(_isActivated, '', 'handle-status-toggle: id: isActivated');
      toast.success('Status Toggled');
    } catch (error) {
      logger(error, 'error');
      toast.error('try again later');
    }
  };

  const getData = async () => {
    try {
      // const fieldToColumnMap = {
      //   id: 'caseKeyTruncate',
      //   title: 'summary',
      //   organization: 'organization.name',
      //   reporter: 'createdBy.name',
      //   assignee: 'assignee.name',
      //   type: 'caseType.name',
      //   resolveTime: 'sla.actualTime.resolveTime',
      //   firstResponseTime: 'sla.actualTime.firstResponseTime',
      //   priority: 'priority.rank',
      //   status: 'status.rank',
      //   project: 'project.name',
      // };

      // const columnValue = fieldToColumnMap[selectedSort?.key] || 'defaultSort';
      const varr = {
        page,
        pageSize,
        search,
        isActive: isActivated,
        // caseType: caseType?.map((itm) => itm.id).toString(),
        // priority: priority?.map((itm) => itm.id).toString(),
        // stakeholder: stakeholder?.map((itm) => itm.id).toString(),
        // assignee: assignee?.map((itm) => itm.id).toString(),
        // projectSponser: projectSponser?.map((itm) => itm.id).toString(),
        // escalationManager: escalationManager?.map((itm) => itm.id).toString(),
        // status: status?.map((itm) => itm.id).toString(),
        // hasAttachments,
        // isEscalated,
        // isFirstResponseTimeViolated,
        // isSynced,
        // isResolveTimeViolated,
        // to: date?.to,
        // from: date?.from,
        // projectId: projectID,
        // column: columnValue,
        // sort: selectedSort?.value === 'asc' ? '1' : '-1'
      };
      // if (selectedSort?.key === '' || selectedSort?.value === undefined) {
      //   delete varr.column;
      //   delete varr.sort;
      // }
      const keys = Object.keys(varr);
      const values = Object.values(varr);

      // // Create an array to store the non-empty key-value pairs
      const queryParams = [];
      for (let i = 0; i < values.length; i++) {
        if (values[i] !== '' && values[i] !== null && values[i] !== undefined) {
          queryParams.push(`${encodeURIComponent(keys[i])}=${values[i]}`);
        }
      }
      const queryString = queryParams.join('&');

      const res = await itsmFlowApi.advanceSearch(queryString);
      setAllFlows(res?.data?.data || []);
      setCount(res?.data?.totalRecords || 0);
    } catch (error) {
      logger(error);
      setAllFlows([]);
      setCount(0);
    }
  };

  useEffect(() => {
    getData();
    const itsmFlowPagePg = {
      itsmFlowPageSize: pageSize,
      itsmFlowPageNo: page,
      itsmFlowSearch: search,
    };
    localStorage.setItem('itsmFlowPagePg', JSON.stringify(itsmFlowPagePg));
  }, [isActivated, page, pageSize, search, isDataRendered]);
  return (
    <>
      <Typography
        variant="h3"
        style={{
          marginTop: '16px',
          marginBottom: '10px',
          fontSize: '20px',
          fontWeight: '700',
          color: '#000',
        }}>
        ITSM Flow List
      </Typography>
      <TableList
        pageName="itsmFlowPagePg"
        allowedColumns={columns}
        data={allFlows}
        redirectUrl={`${baseUrl}/itsm-flow/add`}
        editRouteUrl={`${baseUrl}/itsm-flow/edit`}
        search={search}
        handleSearch={handleSearch}
        setIsActivated={setIsActivated}
        count={count}
        page={page}
        limit={pageSize}
        handleLimit={handlePageSize}
        handlePage={handlePage}
        handleBulkDeactivate={handleBulkDeactivate}
        handleStatusToggle={handleStatusToggle}
      />
    </>
  );
};

export default ItsmFlowListView;
