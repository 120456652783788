/* eslint no-underscore-dangle: 0 */

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  // FormControlLabel,
  Grid,
  List,
  ListItem,
  MenuItem,
  Select,
  // Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AddCommentIcon from '@material-ui/icons/AddComment';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import CancelIcon from '@material-ui/icons/Cancel';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import TranslateIcon from '@material-ui/icons/Translate';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import EditNoteIcon from '@mui/icons-material/EditNote';
import EditIcon from '@mui/icons-material/Edit';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { Formik } from 'formik';
import parseHTML from 'html-react-parser';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { /* useCallback, */ useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import toast from 'react-hot-toast';
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { casesApi } from 'src/api/casesApi';
import { translationApi } from 'src/api/translationApi';
import { userApi } from 'src/api/userApi';
import MultiFileLinkInputCase from 'src/components/MultiFileLinkInputCase';
import SimpleBreadCrumb from 'src/components/widgets/BreadCrumb/SimpleBreadCrumb';
import CustomButton from 'src/components/widgets/buttons/CustomButton';
import { UserActivity } from 'src/components/widgets/display-blocks/user-activity';
import FileUploadDragDrop from 'src/components/widgets/fileUpload/FileUploadDragDrop';
import DocumentViewer from 'src/components/widgets/modal/DocumentViewer';
import LogTimeModal from 'src/components/widgets/modal/LogTimeModal';
import SImpleModal from 'src/components/widgets/modal/SImpleModal';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import logger from 'src/utils/logger';
import setTitle from 'src/utils/setTitle';
import time from 'src/utils/time';
import * as Yup from 'yup';
import { editCaseStyles } from './stylesheet';
import AttachmentViewModal from 'src/components/widgets/modal/AttachmentViewModal';
import { ClickAwayListener } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { logTimeApi } from 'src/api/logTimeApi';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#c22027' : '#c22027',
  },
}));

const useStyles = editCaseStyles;

type TimelineTypes = {
  id: string;
  description: string;
  createdBy: {
    id: string;
    name: string;
  };
  createdAt: string;
  changeFrom?: string | null;
  changeTo?: string | null;
  change: string | null;
  isExternal?: boolean;
  attachmentPath?: string;
};

type TimelineSectionTypes = {
  user: any;
  data: TimelineTypes[];
  caseData: any;
  toggleIsLoad: () => void;
  languageType?: string;
  showTranslation?: any;
  handleDocumentClick?: any;
  handleImageClick?: any;
};

type CommentLinksTypes = 'none' | 'external' | 'internal';

export const Comments = ({
  toggleIsLoad,
  caseData,
  user,
  languageType,
  showTranslation,
  handleDocumentClick,
  handleImageClick,
}: any) => {
  const classes = useStyles();
  const commentRef = useRef(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [commentValue, setCommentValue] = useState<string>('');
  const [commentEdit, setCommentEdit] = useState<any>({});
  const [filePath, setFilePath] = useState<string[]>([]);
  const [commentType, setCommentType] = useState<CommentLinksTypes>('none');
  const [isExternalComment, setIsExternalComment] = useState<boolean>(false);
  const [commentDeleteId, setCommentDeleteId] = useState<string>('');
  const [isDeclineCase, setIsDeclineCase] = useState<boolean>(false);

  useEffect(() => {
    if (window.location.href.includes('declined-cases')) {
      setIsDeclineCase(true);
    }
  }, []);
  const handleCancelComment = () => {
    setCommentType('none');
    setCommentValue('');
    setIsEditing(false);
  };

  const isNotEmpty = (val: string | any[]) => {
    if (Array.isArray(val)) {
      return val.length > 0;
    }
    return typeof val === 'string' && val.trim() !== '';
  };
  const handleSaveComment = async (e) => {
    e.preventDefault();
    try {
      if (isNotEmpty(commentValue) || isNotEmpty(filePath)) {
        const payload = {
          caseId: caseData?.id,
          description: commentValue,
          isExternal: isExternalComment,
          attachmentPath: filePath,
        };
        const response = await casesApi?.createComment(payload);
        if (response?.status === 201) {
          toast.success('Comment Added');
          setCommentValue('');
          setFilePath([]);
          setCommentType('none');
          toggleIsLoad();
        }
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const deleteComment = async (caseId: String) => {
    try {
      const response = await casesApi?.deleteComments(caseId, commentDeleteId);
      if (response?.status === 200) {
        toast.success('Comment Deleted');
        toggleIsLoad();
        setOpenDialog(false);
        toggleIsLoad();
        if (isEditing) {
          setIsEditing(false);
          setCommentValue('');
          setCommentEdit({
            commentId: '',
          });
        }
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const editCommentAPI = async (e) => {
    e.preventDefault();
    try {
      if (isNotEmpty(commentValue)) {
        const payload = {
          caseId: commentEdit?.caseId,
          description: commentValue,
          attachmentPath: filePath,
        };
        const response = await casesApi?.editCaseComment(
          commentEdit?.commentID,
          payload
        );
        if (response?.status === 200) {
          toast.success('Comment Updated');
          toggleIsLoad();
          setCommentValue('');
          setCommentType('none');
          setIsEditing(false);
        }
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const editComment = async (
    caseIDs: string,
    commentId: string,
    commentDes: string,
    indexMap: Number,
    filePaths: string[]
  ) => {
    setIsEditing(true);
    setFilePath(filePaths);
    setCommentValue(commentDes);
    setCommentEdit({
      caseId: caseIDs,
      commentID: commentId,
      currentIndex: indexMap,
    });
  };
  const handleEditCommentDeleteFile = (e, index) => {
    e.stopPropagation();
    const updatedFiles = [...filePath];
    updatedFiles.splice(index, 1);
    setFilePath(updatedFiles);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  // const handleCheckExternalComment = () => {
  //   setIsExternalComment(!isExternalComment);
  // };

  const handleClickInternalComment = () => {
    setCommentType('internal');
    setIsExternalComment(false);
  };

  const handleClickExternalComment = () => {
    setCommentType('external');
    setIsExternalComment(true);
  };

  function uploadAdapter(loader) {
    return {
      upload: () =>
        new Promise((resolve) => {
          loader.file.then(async (file) => {
            const formData = new FormData();
            formData.append('file', file);
            const image = await userApi.uploadAttachments(formData);
            resolve({
              default: `${image?.data?.blob_url}`,
            });
          });
        }),
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
      uploadAdapter(loader);
  }

  const modifyImgAttributes = (node) => {
    if (node.name === 'img') {
      // Check if src attribute is present
      if (
        node.attribs &&
        node.attribs.src &&
        node.attribs.src.includes('backupdbaccount.blob.core.windows.net')
      ) {
        // Modify the src attribute as per your requirement
        const splittedUrl = node.attribs.src?.split('?');
        splittedUrl[1] =
          'sp=racwdl&st=2024-02-27T13:21:58Z&se=2030-07-29T21:21:58Z&spr=https&sv=2022-11-02&sr=c&sig=YXOHT7%2Bx8VW3RDdgzehtPZLWJ6zHJz%2Bp3QtnduafL4o%3D';
        node.attribs.src = splittedUrl?.join('?');
      }
    }
    return node;
  };

  return (
    <Box
      padding="10px 0 12px"
      className={`${classes.box} ${isDeclineCase ? classes.pointerEvent : ''}`}>
      {!isDeclineCase && commentType === 'none' && (
        <Box className={classes.commentOptionButtons}>
          <Box
            className="internalCommentLink"
            onClick={handleClickInternalComment}>
            Add Internal Comment
          </Box>{' '}
          |
          <Box
            className="externalCommentLink"
            onClick={handleClickExternalComment}>
            Add External Comment
          </Box>
        </Box>
      )}
      {commentType !== 'none' && (
        <div
          style={{ flexGrow: 1, marginBottom: 16 }}
          data-color-mode="light"
          className="editorMarkup"
          ref={commentRef}>
          <div className={classes.commentEditorContainer}>
            <CKEditor
              editor={ClassicEditor}
              data={commentValue}
              onChange={(event, editor) => {
                const data = editor.getData();
                setCommentValue(data);
                // Create a temporary DOM element to parse the content
                // const tempDiv = document.createElement('div');
                // tempDiv.innerHTML = data;

                // // Extract all image elements
                // const imageElements = tempDiv.querySelectorAll('img');

                // // Extract image sources
                // const imageSources = Array.from(imageElements).map((image) => image?.src);
                // setFilePath((prev) => [...prev, imageSources.length > 0 ? imageSources : '']);
                // setCommentValue(data);
              }}
              config={{
                placeholder: 'Enter your comment here',
                toolbar: [
                  'heading',
                  '|',
                  'bold',
                  'italic',
                  'link',
                  'bulletedList',
                  'numberedList',
                  'blockQuote',
                  'uploadimage',
                ],
                extraPlugins: [uploadPlugin],
              }}
            />
            <FileUploadDragDrop setFilePath={setFilePath} filePath={filePath} />
            {isEditing && (
              <div className="editCommentFileParent">
                {isEditing &&
                  filePath?.map((item, index) => {
                    const splittedUrl = item?.split('?');
                    splittedUrl[1] =
                      'sp=racwdl&st=2024-02-27T13:21:58Z&se=2030-07-29T21:21:58Z&spr=https&sv=2022-11-02&sr=c&sig=YXOHT7%2Bx8VW3RDdgzehtPZLWJ6zHJz%2Bp3QtnduafL4o%3D';
                    item = splittedUrl?.join('?');
                    const getFileType = (items) => {
                      if (items.includes('.pdf')) {
                        return 'PDF';
                      }
                      if (items.includes('.doc')) {
                        return 'DOC';
                      }
                      if (items.includes('.docx')) {
                        return 'DOCX';
                      }
                      if (items.includes('.txt')) {
                        return 'TXT';
                      }
                      return null;
                    };

                    const fileType = getFileType(item);
                    if (
                      item.includes('.pdf') ||
                      item.includes('.doc') ||
                      item.includes('.docx') ||
                      item.includes('.txt')
                    ) {
                      return (
                        <a
                          role="presentation"
                          onClick={() => handleDocumentClick(item)}>
                          {' '}
                          <span>{fileType}</span>
                          <InsertDriveFileIcon sx={{ fontSize: '60px' }} />
                          <CancelIcon
                            onClick={(e) =>
                              handleEditCommentDeleteFile(e, index)
                            }
                            className="editCommentFileEdit"
                          />
                        </a>
                      );
                    }
                    const imageFormats = [
                      '.jpg',
                      '.jpeg',
                      '.png',
                      '.gif',
                      '.bmp',
                      '.tiff',
                      '.webp',
                      '.svg',
                      '.ico',
                    ];
                    if (
                      imageFormats.some((extension) =>
                        item?.includes(extension)
                      )
                    ) {
                      return (
                        <>
                          <a
                            role="presentation"
                            onClick={() => handleImageClick(item)}>
                            <img src={item} alt="img" />
                            <CancelIcon
                              onClick={(e) =>
                                handleEditCommentDeleteFile(e, index)
                              }
                              className="editCommentFileEdit"
                            />
                          </a>
                        </>
                      );
                    }
                    return null;
                  })}
              </div>
            )}
          </div>
          <div className="btnComments">
            {(user?.permissions?.includes('case-comment-update') ||
              user?.permissions?.includes('case-comment-create')) &&
              (!isEditing ? (
                <CustomButton
                  value={
                    isExternalComment
                      ? 'Add External Comment'
                      : 'Add Internal Comment'
                  }
                  customClass={classes.customClassAddComment}
                  color="secondary"
                  onClick={handleSaveComment}
                />
              ) : (
                user?.permissions?.includes('case-comment-update') && (
                  <>
                    <CustomButton
                      value="Update Comment"
                      color="secondary"
                      onClick={(e) => editCommentAPI(e)}
                    />

                    <CustomButton
                      value="Cancel"
                      color="text"
                      onClick={handleCancelComment}
                    />
                  </>
                )
              ))}
            {!isEditing && (
              <CustomButton
                value="Cancel"
                color="text"
                onClick={() => setCommentType('none')}
              />
            )}
          </div>
        </div>
      )}
      {caseData?.comment?.length !== 0 && (
        <>
          <Typography
            variant="body2"
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              margin: '0px 0px 8px 0px',
            }}>
            Comments
          </Typography>
          <List className={classes.commentsPreview}>
            {caseData?.comment?.map((comment, i) => {
              function extractTextFromHTML(htmlString) {
                // Create a new DOMParser
                const parser = new DOMParser();

                // Parse the HTML string
                const doc = parser.parseFromString(htmlString, 'text/html');

                // Get the text content from the parsed HTML
                const { textContent } = doc.body;

                return textContent;
              }
              const extractedText = extractTextFromHTML(comment?.description);
              return (
                <UserActivity
                  isExternalComment={comment?.isExternal}
                  customClasses={`${
                    isEditing && commentEdit?.currentIndex === i
                      ? 'current'
                      : ''
                  }
              ${comment?.isExternal && classes.externalComment} `}
                  meta={{
                    description: '',
                    createdBy: comment?.createdByName,
                    createdAt: comment?.createdAt,
                    isExternal: comment?.isExternal || false,
                  }}
                  details={parseHTML(comment?.description, {
                    replace: modifyImgAttributes,
                  })}
                  filesPath={comment?.attachmentPath}
                  actions={[
                    <Button
                      size="small"
                      title="Translate"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        showTranslation(
                          extractedText,
                          languageType,
                          'caseCommentData',
                          comment?.id
                        );
                      }}
                      sx={{ padding: '0' }}>
                      Translate
                    </Button>,
                    <Button
                      size="small"
                      title="Edit comment"
                      type="button"
                      onClick={() => {
                        editComment(
                          caseData?.id,
                          comment?.id,
                          comment?.description,
                          i,
                          comment?.attachmentPath
                        );
                        setCommentType(
                          comment?.isExternal ? 'external' : 'internal'
                        );
                        // commentRef.current?.scrollIntoView({ behaviour: 'smooth' });
                      }}
                      sx={{ padding: '0' }}>
                      Edit
                    </Button>,
                    <Button
                      size="small"
                      title="Delete comment"
                      type="button"
                      onClick={() => {
                        setOpenDialog(true);
                        setCommentDeleteId(comment?.id);
                      }}>
                      Delete
                    </Button>,
                    <Dialog
                      open={openDialog}
                      onClose={handleCloseDialog}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description">
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Are you sure, you want to delete a comment?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary">
                          Cancel
                        </Button>
                        <Button
                          onClick={() => deleteComment(caseData?.id)}
                          color="primary"
                          autoFocus>
                          Yes
                        </Button>
                      </DialogActions>
                    </Dialog>,
                  ]}
                />
              );
            })}
          </List>
        </>
      )}
    </Box>
  );
}; // ----> Comments()

export const TimelineSection = ({
  user,
  data,
  caseData,
  toggleIsLoad,
  languageType,
  showTranslation,
  handleDocumentClick,
  handleImageClick,
}: TimelineSectionTypes) => {
  const classes = useStyles();
  return (
    <>
      {(user?.permissions?.includes('case-comment-get-all') ||
        user?.permissions?.includes('case-comment-create')) && (
        <Comments
          caseData={caseData}
          toggleIsLoad={toggleIsLoad}
          user={user}
          languageType={languageType}
          showTranslation={showTranslation}
          handleDocumentClick={handleDocumentClick}
          handleImageClick={handleImageClick}
        />
      )}
      {user?.permissions?.includes('case-timeline-activity-get-all') && (
        <List className={classes.timelineRoot}>
          {data?.length ? (
            <>
              <span
                style={{
                  fontSize: '16px',
                  fontWeight: 600,
                }}>
                Logs
              </span>
              {caseData?.logs?.map((item: TimelineTypes) => (
                <UserActivity
                  key={item?.id}
                  // image={{ src: '/static/icons/avatar.png', alt: item?.createdBy?.name }}
                  meta={{
                    description: item?.description,
                    createdBy: item?.createdBy.name,
                    createdAt: item?.createdAt,
                    change:
                      item?.change === null ? '' : parseHTML(item?.change),
                  }}
                  filesPath={item?.attachmentPath}
                  actions={
                    [
                      // <Button
                      //   size="small"
                      //   title="Translate"
                      //   type="button"
                      //   onClick={(e) => {
                      //     e.preventDefault();
                      //     e.stopPropagation();
                      //     if (item?.change !== null) {
                      //       showTranslation(
                      //         item?.change,
                      //         languageType,
                      //         'caseLogData',
                      //         item?.id
                      //       );
                      //     }
                      //   }}
                      //   sx={{ padding: '0' }}>
                      //   Translate
                      // </Button>,
                    ]
                  }
                  details={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                      }}>
                      {item?.changeFrom && (
                        <span style={{ fontSize: '12px' }}>
                          {item?.changeFrom === null
                            ? 'none'
                            : parseHTML(item?.changeFrom)}
                        </span>
                      )}
                      {item?.changeTo && (
                        <>
                          <span
                            style={{
                              flex: '0 0 40px',
                              textAlign: 'center',
                              margin: '-2px 0 0',
                            }}>
                            <ArrowRightAltIcon />
                          </span>
                          <span style={{ fontSize: '12px' }}>
                            {item?.changeTo === null
                              ? 'none'
                              : parseHTML(item?.changeTo)}
                          </span>
                        </>
                      )}
                    </div>
                  }
                />
              ))}
            </>
          ) : (
            <>
              <ListItem style={{ display: 'flex', justifyContent: 'center' }}>
                <strong>No Data Found</strong>
              </ListItem>
            </>
          )}
        </List>
      )}
    </>
  );
};
// ----> TimelineSection()

export const JiraSync = () => null;

function EditCases() {
  setTitle('CRM | Cases Edit');
  const orgEditComponent = false;
  const { user } = useAuth();
  const { caseID } = useParams();
  const navigate = useNavigate();
  let hideItem = null;
  let returnUrl = '/erp/cases';
  if (orgEditComponent) {
    const { hideItems }: any = useOutletContext();
    hideItem = hideItems || false;
    const location = useLocation();
    const locationSlugs = location.pathname.split('/');
    locationSlugs.pop(); // remove case-id from location
    returnUrl = locationSlugs.join('/');
  }
  const handleReturn = () => {
    navigate(returnUrl);
  };

  const classes = useStyles();
  interface Status {
    id: string;
    name: string;
    rank: number;
  }

  interface Sponsor {
    id: string;
    name: string;
  }

  interface Stakeholder {
    id: string;
    name: string;
  }

  interface Comment {
    id: string;
    description: string;
    isExternal: boolean;
    createdBy: string;
    createdByName: string;
    updatedByName: string | null;
    updatedBy: string | null;
    createdAt: string;
    updatedAt: string;
    attachmentPath: string[];
  }

  interface Assignee {
    id: string | null;
    name: string | null;
  }

  interface Organization {
    id: string;
    name: string;
  }

  interface Project {
    id: string;
    name: string;
  }

  interface IssueType {
    id: string;
    name: string;
  }

  interface Priority {
    id: string;
    name: string;
    rank: number;
  }

  interface EstimatedTime {
    firstResponseTime: string;
    resolveTime: string;
  }

  interface ActualTime {
    firstResponseTime: string;
    resolveTime: string;
  }

  interface SLA {
    estimatedTime: EstimatedTime;
    actualTime: ActualTime;
    isFirstResponseTimeViolated: boolean;
    isResolveTimeViolated: boolean;
  }

  interface Log {
    id: string;
    description: string;
    createdBy: {
      id: string;
      name: string;
    };
    createdAt: string;
    changeFrom: string | null;
    changeTo: string | null;
    change: string | null;
    isExternal: boolean;
  }

  interface Report extends Log {}

  interface Severity {
    id: string;
    name: string;
    rank: number;
  }

  interface BugQuestion {
    questionKey: string;
    description: string;
  }

  interface ThirdPartySync {
    isSynced: boolean;
    syncedCaseKey: string | null;
    syncedCaseLink: string | null;
  }

  interface CreatedBy {
    id: string;
    name: string;
  }

  interface UpdatedBy {
    id: string;
    name: string;
  }

  interface Escalation {
    id: number;
    createdAt: string;
    escalatedBy: {
      id: string;
      name: string;
      role: string;
    };
  }

  interface CaseData {
    labels: string[];
    id: string;
    billingAccountType?: any;
    caseKey: string;
    description: string;
    summary: string;
    status: Status;
    startDate: string;
    dueDate: string;
    projectSponsor: Sponsor[];
    stakeHolder: Stakeholder[];
    attachmentId: string[];
    comment: Comment[];
    assignee: Assignee;
    escalationManager: {
      id: string;
      name: string;
    };
    organization: Organization;
    project: Project;
    issueType: IssueType;
    caseType: {
      id: string;
      name: string;
    };
    priority: Priority;
    sla: SLA;
    logs: Log[];
    reports: Report[];
    severity: Severity;
    bugQuestions: BugQuestion[];
    thirdPartySync: ThirdPartySync;
    isEscalated: boolean;
    createdBy: CreatedBy;
    updatedBy: UpdatedBy;
    createdAt: string;
    updatedAt: string;
    escalations: Escalation[];
  }
  type statusInputValueType = {
    id?: string;
    name?: string;
    rank?: number;
  };
  type statusesType = {
    id?: string;
    name?: string;
    rank?: number;
  };
  type idNameType = {
    id?: string;
    name?: string;
  };
  interface LogEntry {
    id: string;
    description: string;
    createdBy: {
      id: string;
      name: string;
    };
    createdAt: string;
    changeFrom?: string | null;
    changeTo?: string | null;
    change: string | null;
    isExternal?: boolean;
    attachmentPath?: string;
  }
  interface Input {
    id: number;
    value: string;
    label: string;
  }

  const [billingAccounts, setBillingAccounts] = useState([]);
  const [caseDataOld, setCaseDataOld] = useState<CaseData>(null);
  const [caseData, setCaseData] = useState<CaseData>(null);
  const [isSyncing, setIsSyncing] = useState<boolean>(false);
  const [labelsData, setLabelsData] = useState<string[]>([]);
  const [labelInputValue, setLabelInputValue] = useState<string>('');
  const [labelsOptions, setLabelsOptions] = useState<string[]>([]);
  const [statusInputValue, setStatusInputValue] =
    useState<statusInputValueType>({});
  const [statuses, setStatuses] = useState<statusesType[]>([]);
  const [chkIspApprove, setChkIspApprove] = useState<any>(null);
  const [bugOccur, setBugOccur] = useState<string>('');
  const [bugExpect, setBugExpect] = useState<string>('');
  const [dueDate, setDueDate] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [isStartDateEditable, setIsStartDateEditable] =
    useState<boolean>(false);
  const [isDueDateEditable, setIsDueDateEditable] = useState<boolean>(false);
  const [copied, setCopied] = useState(false);
  const timeoutRef = useRef(null);
  const uploadRef = useRef<any>(null);

  const { settings } = useSettings();
  const [organization, setOrganization] = useState<idNameType>({
    id: '',
    name: '',
  });
  const [project, setProject] = useState<idNameType>({
    id: '',
    name: '',
  });
  const [priorityAllOptions, setPriorityAllOptions] = useState<idNameType[]>(
    []
  );
  const [caseTypeOptions, setCaseTypeOptions] = useState<idNameType[]>([]);
  const [caseType, setCaseType] = useState<idNameType>({});
  const [priority, setPriority] = useState<idNameType>({});
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const toggleIsLoad = () => setIsLoad(!isLoad);
  const [projectSponserId, setProjectSponserId] = useState<idNameType[]>([]);
  const [releaseVersion, setReleaseVersion] = useState<any>([]);
  // const [projectSponserInput, setProjectSponserInput] = useState<any>('');
  const [stakeholderId, setStakeholderId] = useState<idNameType[]>([]);
  const [severity, setSeverity] = useState<idNameType>(null);
  const [stakeholderInput, setStakeholderInput] = useState<string>('');
  // const [stakeholderValue] = useState<any>([]);
  const [allExternalUsers, setAllExternalUsers] = useState<idNameType[]>([]);
  logger(allExternalUsers);
  const [escalationManagerId, setEscalationManagerId] = useState<idNameType>(
    {}
  );

  const [escalationManagerInput, setEscalationManagerInput] =
    useState<string>('');
  const [assigneeId, setAssigneeId] = useState<idNameType>({});
  const [allInternalUsers, setAllInternalUsers] = useState<idNameType[]>([]);
  const [allStakeholders, setAllStakeholders] = useState<idNameType[]>([]);
  const [allLogs, setAllLogs] = useState<LogEntry[]>([]);
  const [projectLinks, setProjectLinks] = useState<any>([]);
  const [description, setDescription] = useState<string>('');
  const [tabValue, setTabValue] = useState<number>(1);
  const [languageType, setLanguageType] = useState<string>('en');
  const [allSeverities, setAllSeverities] = useState<idNameType[]>([]);
  const [isImagePopupOpen, setIsImagePopupOpen] = useState<boolean>(false);
  const [logTimeModalOpen, setlogTimeModalOpen] = useState<boolean>(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState<string>('');
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState<string>('');
  const [isTitleEditMode, setIsTitleEditMode] = useState<boolean>(false);
  const [isBugDescriptionEditable, setIsBugDescriptionEditable] =
    useState<boolean>(false);
  const [isDescriptionEditMode, setIsDescriptionEditMode] =
    useState<boolean>(false);
  const [isPriorityEditMode, setIsPriorityEditMode] = useState<boolean>(false);
  const [isSeverityEditMode, setIsSeverityEditMode] = useState<boolean>(false);
  const [isAssigneeEditMode, setIsAssigneeEditMode] = useState<boolean>(false);
  const [isStakeholderEditMode, setIsStakeholderEditMode] =
    useState<boolean>(false);
  const [isEscalationManagerEditMode, setIsEscalationManagerEditMode] =
    useState<boolean>(false);
  const [isCaseTypeEditMode, setIsCaseTypeEditMode] = useState<boolean>(false);
  const [isLabelEditable, setIsLabelEditable] = useState<boolean>(false);
  const [isProjectEditable, setIsProjectEditable] = useState<boolean>(false);
  const [isBillingEditable, setIsBillingEditable] = useState<boolean>(false);
  const [isOrganizationEditable, setIsOrganizationEditable] =
    useState<boolean>(false);
  const [isTimeEstimateEditable, setIsTimeEstimateEditable] =
    useState<boolean>(false);
  const [billingAccountType, setBillingAccountType] = useState<string>('');
  const [billingAccountTypeEditable, setBillingAccountTypeEditable] =
    useState<boolean>(false);

  const [allFixVersions, setAllFixVersions] = useState<any>([]);
  const [isFixVersionEditable, setIsFixVersionEditable] =
    useState<boolean>(false);

  const [sprints, setSprints] = useState<any>([]);
  const [currentSprint, setCurrentSprint] = useState<any>({});
  const [isSprintsEditable, setIsSprintsEditable] = useState<boolean>(false);

  const [isDocumentViewerOpen, setIsDocumentViewerOpen] =
    useState<boolean>(false);
  const [billingType, setBillingType] = useState(null);
  const [timeEstimate, setTimeEstimate] = useState('');
  const [loggedTime, setLoggedTime] = useState([]);
  const [totalTime, setTotalTime] = useState(0);
  const [remainingTimePercent, setRemainingTimePercent] = useState(0);
  const [loggedTimePercent, setLoggedTimePercent] = useState(0);
  const [loggedTimeEditData, setLoggedTimeEditData] = useState({});

  const [isDeclineCase, setIsDeclineCase] = useState<boolean>(false);
  const [filePath, setFilePath] = useState<string[]>([]);

  const [googleDocLinks, setGoogleDocLinks] = useState<Input[]>([
    { id: Date.now(), value: '', label: '' },
  ]);
  const [figmaLinks, setFigmaLinks] = useState<Input[]>([
    { id: Date.now(), value: '', label: '' },
  ]);

  const [commentType, setCommentType] = useState('internal');
  const [commentValue, setCommentValue] = useState('');

  const handleChangeCommentType = (event) => {
    setCommentType(event.target.value);
  };

  const handleChangeCommentValue = (event) => {
    setCommentValue(event.target.value);
  };

  const handleCopy = async () => {
    const caseText = `
      Case Title: ${caseData?.caseKey} - ${caseData?.summary}
      Case Link: https://portal.it22.nl/cases/${caseData?.caseKey}
    `;
    try {
      await navigator.clipboard.writeText(caseText);
      setCopied(true);

      // Clear any existing timeout before setting a new one
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // Set a new timeout to reset the 'Copied!' message after 2 seconds
      timeoutRef.current = setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  // Handle button click to clear files by calling the method on the ref
  const handleClearFiles = () => {
    if (uploadRef.current) {
      uploadRef.current.clearFiles();
    }
  };

  useEffect(
    () =>
      // Clean up timeout when the component unmounts
      () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      },
    []
  );

  useEffect(() => {
    // Sum all logged hours in seconds
    let totalLoggedSeconds = loggedTime.reduce(
      (acc, item) => acc + item?.loggedHoursInSeconds,
      0
    );
    // Calculate the percentage of the total logged seconds with respect to the total time
    if (totalLoggedSeconds > totalTime) {
      totalLoggedSeconds = totalTime;
    }
    const progressPercentage = (totalLoggedSeconds / totalTime) * 100;
    const remainingPercent = 100 - progressPercentage;

    // Update the progress state
    setLoggedTimePercent(progressPercentage);
    setRemainingTimePercent(remainingPercent);
  }, [totalTime]);
  const handleDocumentClick = (documentUrl) => {
    setSelectedDocumentUrl(documentUrl);
    setIsDocumentViewerOpen(true);
  };

  const handleCloseDocumentViewer = () => {
    setIsDocumentViewerOpen(false);
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setIsImagePopupOpen(true);
  };

  const handleCloseImagePopup = () => {
    setIsImagePopupOpen(false);
  };

  const handleLogTimeModalOpen = (state) => {
    setlogTimeModalOpen(state);
    if (state) {
      setLoggedTimeEditData({});
    }
  };

  const handleEditLogTimeModalOpen = (event, item) => {
    setlogTimeModalOpen(true);
    setLoggedTimeEditData(item);
  };

  const handleTabValue = (e: any, newValue: number) => {
    setTabValue(newValue);
  };

  const convertSecondsToTimeString = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    let result = '';
    if (hours > 0) {
      result += `${hours}h `;
    }
    if (minutes > 0) {
      result += `${minutes}m`;
    }

    return result.trim();
  };

  const getAutoCompleteReleaseVersion = async (projectId) => {
    try {
      const res = await casesApi?.getAutoCompleteReleaseVersion(projectId);
      setAllFixVersions(res?.data?.active);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setAllFixVersions([]);
      }
    }
  };

  const getAutoCompleteSprints = async (projectId) => {
    try {
      const res = await casesApi?.getAutoCompleteSprints(projectId);
      setSprints(res?.data?.active);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setSprints([]);
      }
    }
  };

  const addEmptyRow = (links: Input[]): Input[] => [
    ...links,
    { id: Date.now(), value: '', label: '' },
  ];

  const getCaseById = async () => {
    try {
      const response = await casesApi?.getCaseById(caseID);
      const res = response?.data;
      setCaseData(res);
      setCaseDataOld(res);
      setBillingAccounts(res.billingAccount || []);
      setOrganization({
        id: res?.organization?.id,
        name: res?.organization?.name,
      });
      if (res?.caseType?.name === 'Application Bug') {
        setSeverity({ id: res?.severity?.id, name: res?.severity?.name });
      } else {
        setSeverity(res.severity);
      }
      setProject({
        id: res?.project?.id,
        name: res?.project?.name,
      });
      setProjectSponserId(res?.projectSponsor);

      setChkIspApprove(res?.isApproved);
      setReleaseVersion(res?.releaseVersion);
      setStakeholderId(res?.stakeHolder);
      setAssigneeId(res?.assignee);
      setEscalationManagerId(res?.escalationManager);
      setEscalationManagerInput(res?.escalationManager?.name);
      setPriority(res?.priority);
      setCaseType(res?.caseType);
      setCurrentSprint(res?.sprint);
      setLabelsData(res?.labels);
      setStatusInputValue(res?.status);
      setAllLogs(res?.logs || []);
      setProjectLinks(res?.project?.docUrls);
      setLoggedTime(res?.loggedTime || []);
      setDescription(res?.description || '');
      setBugOccur(res?.bugQuestions[0]?.description || '');
      setBugExpect(res?.bugQuestions[1]?.description || '');
      setBillingType({ id: res?.expense || '', name: res?.expense || '' });
      setDueDate(res?.dueDate);
      setBillingAccountType(res?.billingAccountType);
      setStartDate(res?.startDate);
      setGoogleDocLinks(
        res?.docUrls?.googleDocLinks?.length > 0
          ? addEmptyRow(
              res?.docUrls?.googleDocLinks?.map((link, index) => ({
                id: index,
                value: link?.url,
                label: link?.label,
              }))
            )
          : [{ id: Date.now(), value: '', label: '' }]
      );
      setFigmaLinks(
        res?.docUrls?.figmaLinks?.length > 0
          ? addEmptyRow(
              res?.docUrls?.figmaLinks?.map((link, index) => ({
                id: index,
                value: link?.url,
                label: link?.label,
              }))
            )
          : [{ id: Date.now(), value: '', label: '' }]
      );

      const timeString = convertSecondsToTimeString(res?.timeEstimate);
      setTimeEstimate(timeString);
      setTotalTime(res?.timeEstimate);
      await getAutoCompleteReleaseVersion(res?.project?.id);
      await getAutoCompleteSprints(res?.project?.id);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setCaseData(null);
        setCaseDataOld(null);
        setOrganization({});
        setProject({});
        setProjectSponserId([]);
        setStakeholderId([]);
        setAssigneeId({});
        setEscalationManagerId({});
        setEscalationManagerInput('');
        setBugOccur('');
        setBugExpect('');
        setPriority({});
        setLabelsData([]);
        setStatusInputValue({});
        setAllLogs([]);
        setSeverity(null);
        setBillingType(null);
      }
    }
  };

  const handleDeleteLogTimeModalOpen = async (item) => {
    try {
      await logTimeApi?.deleteLogTime(caseData?.id, item?.id);

      toast.success('Time Log delete successfully');
      getCaseById();
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const getAutoCompleteStatus = async () => {
    try {
      const res = await casesApi?.getStatuses();

      const filteredItems = res?.data.filter((item) => {
        // If chkIspApprove is true, remove "Declined"
        if (chkIspApprove === true && item.name === 'Declined') {
          return false;
        }
        return true; // Keep all other items
      });
      setStatuses(filteredItems);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setStatuses([]);
      }
    }
  };

  const clickAwayHandler = () => {
    console.log('first');
    setIsDescriptionEditMode(false);
  };

  const convertToSeconds = (timeString) => {
    const timeUnits = timeString.match(/\d+\s*[hm]/g);
    let totalSeconds = 0;

    timeUnits?.forEach((unit) => {
      const number = parseInt(unit, 10);
      const timeUnit = unit.replace(/\d+\s*/g, ''); // Remove digits and spaces to get the unit

      if (timeUnit === 'h') {
        totalSeconds += number * 3600; // 1 hour = 3600 seconds
      } else if (timeUnit === 'm') {
        totalSeconds += number * 60; // 1 minute = 60 seconds
      }
    });

    return totalSeconds;
  };

  const getAllExternalUsersAutoComplete = async () => {
    try {
      const res = await casesApi?.getAutoCompleteUser(true);
      setAllExternalUsers(res?.data);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setAllExternalUsers([]);
      }
    }
  };

  const getAllInternalUsersAutoComplete = async () => {
    try {
      const res = await casesApi?.getAutoCompleteUser(false);
      setAllInternalUsers(res?.data);
      setAllStakeholders(res?.data);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setAllInternalUsers([]);
        setAllStakeholders([]);
      }
    }
  };

  const getAutoCompletePriority = async () => {
    try {
      const res = await casesApi?.getPriorities();
      setPriorityAllOptions(res?.data);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setPriorityAllOptions([]);
      }
    }
  };

  const getAllSeverities = async () => {
    try {
      const response = await casesApi.getAutoCompleteSeverity();
      setAllSeverities(response?.data);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setAllSeverities([]);
      }
    }
  };

  const getCaseType = async () => {
    try {
      const response = await casesApi.getCaseTypes();
      setCaseTypeOptions(response?.data);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setCaseTypeOptions([]);
      }
    }
  };

  const escalateById = async () => {
    try {
      const response = await casesApi?.escalateById(caseData?.id);
      if (response?.status === 201) {
        setCaseData(response?.data);
        getCaseById();
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setCaseData(null);
      }
    }
  };

  const syncJiraByCaseId = async () => {
    try {
      setIsSyncing(true);
      const res = await casesApi?.syncJiraByCaseId(caseData?.id);
      if (res?.status === 200) {
        toast.success('successfully Synced');
        getCaseById();
      }
      setIsSyncing(false);
    } catch (error) {
      setIsSyncing(false);
      const errorMessage = error?.response?.data?.message;
      if (error?.response?.status !== 401) {
        toast.error(errorMessage);
      }
    }
  };
  const updateIndividual = async (columnName: any, values: any) => {
    try {
      const payload = {
        [columnName]: values,
      };
      const caseId = caseData?.id;
      const response = await casesApi?.updateCase(caseId, payload);
      if (response?.status === 200) {
        toast.success('Case Successfully Updated');
        getCaseById();
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const priorityColors = {
    highest: '#FF0000', // Red
    blocker: '#FF4500', // Orange Red
    high: '#F16063', // High (as specified)
    medium: '#FFA500', // Orange
    minor: '#FFD700', // Gold
    low: '#9ACD32', // Yellow Green
    lowest: '#00FF00', // Lime
    backLog: '#808080', // Gray
  };
  const getColor = (priorityName: any) =>
    priorityColors[priorityName.toLowerCase()] || '#000000';

  const BreadCrumbsData = {
    title: '',
    links: [
      {
        name: 'cases',
        to: '/erp/cases',
      },
      {
        name: `${caseData?.caseKey}`,
        to: `/erp/cases/${caseID}`,
        color: `${caseData?.issueType?.name === 'Bug' ? 'red' : '#4baee8'}`,
        copyDetails: {
          id: caseData?.caseKey,
          title: caseData?.summary,
        },
        allowCopy: true,
      },
      {
        name: `${caseData?.caseType?.name}`,
        to: `/erp/cases/${caseID}`,
      },
    ],
  };
  const BreadCrumbCaseProj = {
    title: '',
    links: [
      {
        name: 'cases',
        to: `${hideItem?.link}`,
      },
      {
        name: `${caseData?.caseKey}`,
        to: `/erp/cases/${caseID}`,
        color: 'red',
      },
    ],
  };

  const hasMultipleNullValues = (obj: any) => {
    let nullCount = 0;

    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key) && obj[key] === null) {
        nullCount++;
        if (nullCount > 1) {
          return true; // Found multiple null values
        }
      }
    }

    return false; // No or only one null value found
  };

  const hasMultipleNulls = hasMultipleNullValues(assigneeId);
  const getLabels = async () => {
    try {
      const res = await casesApi?.getAutoCompleteLabels();
      const uniqueArray = res?.data?.filter(
        (value, index, self) => self.indexOf(value) === index
      );
      setLabelsOptions(uniqueArray);
    } catch (error) {
      if (error?.response?.status !== 401) {
        setLabelsOptions([]);
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const showTranslation = async (
    textData: string,
    lang?: string,
    field?: string,
    id?: string
  ) => {
    const payload = {
      text: textData,
      target_lang: lang,
    };

    try {
      const res = await translationApi?.translateText(payload);
      const detectedLanguage =
        res?.data?.data?.translations[0].detectedSourceLanguage;
      const translatedText = res?.data?.data?.translations[0].translatedText;
      const updatedComments = caseData.comment.map((comment) => {
        if (comment.id === id) {
          return { ...comment, description: translatedText };
        }
        return comment;
      });
      const updatedLogs = caseData.logs.map((log) => {
        if (log.id === id) {
          return { ...log, change: translatedText };
        }
        return log;
      });
      // Define a conditional object to specify how to update the state based on the field
      const fieldActions = {
        caseSummary: () => {
          setCaseData({
            ...caseData,
            summary: translatedText,
          });
        },
        caseCommentData: () => {
          setCaseData({
            ...caseData,
            comment: updatedComments,
          });
        },
        caseLogData: () => {
          setCaseData({
            ...caseData,
            logs: updatedLogs,
          });
        },
        caseDescription: () => {
          setDescription(translatedText);
        },
        // Add more fields and actions as needed
      };

      // Call the appropriate action based on the field
      if (field && fieldActions[field]) {
        fieldActions[field]();
      }

      setLanguageType(detectedLanguage);
    } catch (error) {
      if (error?.response?.status !== 401) {
        setCaseData(null);
        toast.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    getAutoCompletePriority();

    getCaseType();
    getLabels();
    getAllSeverities();
    const timeout = setTimeout(() => {
      getAllExternalUsersAutoComplete();
      getAllInternalUsersAutoComplete();
    }, 800);

    if (
      !user?.permissions?.includes('case-get-by-id') &&
      !user?.permissions?.includes('case-update') &&
      !user?.permissions?.includes('case-timeline-activity-get-all') &&
      !user?.permissions?.includes('case-comment-get-by-id') &&
      !user?.permissions?.includes('case-comment-update') &&
      !user?.permissions?.includes('case-comment-create') &&
      !user?.permissions?.includes('case-comment-delete') &&
      !user?.permissions?.includes('case-comment-get-all') &&
      !user?.permissions?.includes('case-escalate-read') &&
      !user?.permissions?.includes('case-jira-sync-read') &&
      !user?.permissions?.includes('case-status-update')
    ) {
      window.location.href = '/admin/not-found';
    }

    return () => {
      clearTimeout(timeout);
      setAllInternalUsers([]);
      setAllStakeholders([]);
    };
  }, []);

  useEffect(() => {
    getCaseById();
  }, [isLoad]);

  const filterEmptyLinks = (links: Input[]) =>
    links?.filter(
      (link) => link?.value.trim() !== '' || link?.label.trim() !== ''
    );

  useEffect(() => {
    getCaseById();
  }, [isLoad]);

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
    if (event) {
      updateIndividual('startDate', event.target.value);
      setIsStartDateEditable(false);
    }
  };

  const handleDueDateChange = (event) => {
    setDueDate(event.target.value);
    if (event) {
      updateIndividual('dueDate', event.target.value);
      setIsDueDateEditable(false);
    }
  };

  const handleAddGoogleDocLinks = () => {
    const payload = [
      ...googleDocLinks,
      { id: Date.now(), value: '', label: '' },
    ];
    setGoogleDocLinks([
      ...googleDocLinks,
      { id: Date.now(), value: '', label: '' },
    ]);
    const docUrls = {
      googleDocLinks: filterEmptyLinks(payload).map((link) => ({
        url: link?.value,
        label: link?.label,
      })),
      figmaLinks: filterEmptyLinks(figmaLinks).map((link) => ({
        url: link?.value,
        label: link?.label,
      })),
    };
    updateIndividual('docUrls', docUrls);
  };

  const handleAddFigmaLinks = () => {
    const payload = [...figmaLinks, { id: Date.now(), value: '', label: '' }];
    setFigmaLinks([...figmaLinks, { id: Date.now(), value: '', label: '' }]);

    const docUrls = {
      googleDocLinks: filterEmptyLinks(googleDocLinks).map((link) => ({
        url: link?.value,
        label: link?.label,
      })),
      figmaLinks: filterEmptyLinks(payload).map((link) => ({
        url: link?.value,
        label: link?.label,
      })),
    };
    updateIndividual('docUrls', docUrls);
  };

  const handleRemoveGoogleDocLinks = (id) => {
    const payload = googleDocLinks?.filter((input) => input.id !== id);
    setGoogleDocLinks(googleDocLinks?.filter((input) => input.id !== id));
    const docUrls = {
      googleDocLinks: filterEmptyLinks(payload).map((link) => ({
        url: link?.value,
        label: link?.label,
      })),
      figmaLinks: filterEmptyLinks(figmaLinks).map((link) => ({
        url: link?.value,
        label: link?.label,
      })),
    };
    updateIndividual('docUrls', docUrls);
  };

  const handleRemoveFigmaLinks = (id) => {
    const payload = figmaLinks?.filter((input) => input.id !== id);
    setFigmaLinks(figmaLinks?.filter((input) => input.id !== id));

    const docUrls = {
      googleDocLinks: filterEmptyLinks(googleDocLinks).map((link) => ({
        url: link?.value,
        label: link?.label,
      })),
      figmaLinks: filterEmptyLinks(payload).map((link) => ({
        url: link?.value,
        label: link?.label,
      })),
    };
    updateIndividual('docUrls', docUrls);
  };

  useEffect(() => {
    if (window.location.href.includes('declined-cases')) {
      setIsDeclineCase(true);
    }
  }, []);
  useEffect(() => {
    if (chkIspApprove != null) {
      getAutoCompleteStatus();
    }
  }, [chkIspApprove]);

  // className={isDeclineCase ? classes.pointerEvent : ''}
  function uploadAdapter(loader) {
    return {
      upload: () =>
        new Promise((resolve) => {
          loader.file.then(async (file) => {
            const formData = new FormData();
            formData.append('file', file);
            const image = await userApi.uploadAttachments(formData);
            resolve({
              default: `${image?.data?.blob_url}`,
            });
          });
        }),
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
      uploadAdapter(loader);
  }

  return (
    <>
      {
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
          }}
          className={`${classes.box}`}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            padding="10px 15px"
            marginBottom="10px"
            sx={{ backgroundColor: 'white' }}>
            <Typography
              fontSize="14px"
              fontWeight="700">{`${caseData?.caseKey} - ${caseData?.summary}`}</Typography>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                padding: '4px 8px',
                fontSize: '12px',
              }}
              onClick={handleCopy}>
              {copied ? 'Copied!' : 'Copy'}
            </Button>
          </Box>
          <Container
            maxWidth={settings.compact ? 'xl' : false}
            className={`${hideItem?.customClass ? 'orgEditCase' : ''}`}>
            <Formik
              enableReinitialize
              initialValues={{
                summary: caseData?.summary,
                description: caseData?.description,
                sponsorId: projectSponserId,
                stakeHolderId: stakeholderId,
                escalationManagerId: escalationManagerInput,
                assigneeID: assigneeId,
                orgId: organization,
                projectId: project,
                caseOwner: caseData?.createdBy?.name,
                severity,
                bugOccur,
                bugExpect,
                priority,
                caseType: caseData?.caseType, // added for conditional yup validation when caseType is a bug
                labels: [],
                issueType: { id: '', name: '' },
                statusId: { id: '', name: '' },
              }}
              onSubmit={async (values) => {
                try {
                  const payload: any = {};
                  if (caseDataOld?.summary !== values?.summary) {
                    payload.summary = values?.summary;
                  }
                  if (caseData?.description !== description) {
                    payload.description = description;
                  }
                  if (filePath?.length) {
                    payload.attachmentId = [
                      ...caseData?.attachmentId,
                      ...filePath,
                    ];
                  }

                  if (caseDataOld?.caseType?.name === 'Application Bug') {
                    payload.bugQuestions = [
                      {
                        questionKey: caseData?.bugQuestions[0]?.questionKey,
                        description: values?.bugOccur,
                      },
                      {
                        questionKey: caseData?.bugQuestions[1]?.questionKey,
                        description: values?.bugExpect,
                      },
                    ];
                  }
                  payload.docUrls = {
                    googleDocLinks: filterEmptyLinks(googleDocLinks).map(
                      (link) => ({ url: link?.value, label: link?.label })
                    ),
                    figmaLinks: filterEmptyLinks(figmaLinks).map((link) => ({
                      url: link?.value,
                      label: link?.label,
                    })),
                  };
                  const caseId = caseData?.id;
                  const response = await casesApi?.updateCase(caseId, payload);
                  if (response?.status === 200) {
                    toast.success('Case Successfully Updated');
                    getCaseById();
                    setFilePath([]);
                    handleClearFiles();
                  }
                } catch (error) {
                  if (error?.response?.status !== 401) {
                    toast.error(error?.response?.data?.message);
                  }
                }
              }}
              validationSchema={Yup.object({
                summary: Yup.string()
                  .trim()
                  .required('Title required')
                  .max(256, 'Maximum 256 characters allowed'),
                // description: Yup.string()
                //   .trim()
                //   .required('Description required'),
                bugOccur: Yup.string().when('caseType.id', {
                  is: (value) => value === '65005f2c0f96e012c1a9b1eb',
                  then: Yup.string()
                    .trim()
                    .required('Answer required')
                    .max(1000, 'Maximum 1000 characters allowed'),
                  otherwise: Yup.string(),
                }),
                bugExpect: Yup.string().when('caseType.id', {
                  is: (value) => value === '65005f2c0f96e012c1a9b1eb',
                  then: Yup.string()
                    .trim()
                    .required('Answer required')
                    .max(1000, 'Maximum 1000 characters allowed'),
                  otherwise: Yup.string(),
                }),
              })}>
              {({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                setFieldValue,
                setFieldTouched,
                setFieldError,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    gap={4}
                    sx={{ flexWrap: 'nowrap', marginTop: '8px' }}>
                    {/** Left Side Bar */}
                    <Grid
                      item
                      xs={8}
                      sx={{
                        flexDirection: 'column',
                        gap: 4,
                      }}>
                      <Box className="boxSub">
                        <Grid container>
                          <Grid item xs={6}>
                            {hideItem?.heading !== false ? (
                              <Grid
                                container
                                justifyContent="space-between"
                                // spacing={3}
                                mb={1}>
                                <Grid item md={12}>
                                  <SimpleBreadCrumb data={BreadCrumbsData} />
                                </Grid>
                              </Grid>
                            ) : (
                              <div style={{ marginTop: '-20px' }}>
                                <SimpleBreadCrumb data={BreadCrumbCaseProj} />
                              </div>
                            )}
                          </Grid>
                          <Grid
                            xs={6}
                            className={`${classes.box} ${
                              isDeclineCase ? classes.pointerEvent : ''
                            }`}>
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="flex-end"
                              alignItems="flex-start"
                              // marginBottom="10px"
                            >
                              {console.log('statuses', statuses)}
                              <FormControl>
                                <Select
                                  sx={{
                                    boxShadow: 'none',
                                    '.MuiOutlinedInput-notchedOutline': {
                                      border: 0,
                                    },
                                  }}
                                  // IconComponent={() => null}
                                  id="priorities"
                                  className={classes.select}
                                  disabled={
                                    !user?.permissions?.includes(
                                      'case-status-update'
                                    )
                                  }
                                  value={
                                    Object.keys(statusInputValue).length !==
                                      0 && statusInputValue?.id
                                  }
                                  onClick={(e) => {
                                    if (
                                      user?.permissions?.includes(
                                        'case-status-update'
                                      ) &&
                                      e
                                    ) {
                                      const getTarget: any = e.target;
                                      const dataName =
                                        getTarget?.getAttribute('data-name');
                                      const dataValue =
                                        getTarget?.getAttribute('data-value');
                                      if (dataValue !== null) {
                                        setFieldValue('statusId', {
                                          id: dataValue,
                                        });
                                        updateIndividual('status', {
                                          ...statusInputValue,
                                          name: dataName,
                                          id: dataValue,
                                        });
                                      }
                                    }
                                  }}
                                  size="small">
                                  {statuses?.map((opt) => (
                                    <MenuItem
                                      value={opt?.id}
                                      data-name={opt?.name}>
                                      {opt?.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid xs={12}>
                            <>
                              <Grid
                                container
                                sx={{
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                }}>
                                <Typography
                                  variant="body1"
                                  fontSize="14px"
                                  fontWeight={600}
                                  sx={{
                                    margin: '0px !important',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setIsTitleEditMode(true);
                                    setFieldTouched('summary', false); // Reset the touched state
                                    setFieldError('summary', ''); // Clear the error state
                                  }}>{`${`Title`}`}</Typography>
                                <Grid container sx={{ width: 'auto', gap: 2 }}>
                                  {isTitleEditMode && (
                                    <Grid>
                                      {values?.summary?.length}
                                      /256
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                              {isTitleEditMode && (
                                <TextField
                                  type="text"
                                  fullWidth
                                  name="summary"
                                  onChange={handleChange}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      handleSubmit();
                                    }
                                  }}
                                  onBlur={() => {
                                    setIsTitleEditMode(false);
                                  }}
                                  value={values?.summary}
                                  error={Boolean(
                                    touched?.summary && errors?.summary
                                  )}
                                  helperText={
                                    touched?.summary && errors?.summary
                                  }
                                  inputProps={{
                                    maxLength: 256,
                                  }}
                                />
                              )}
                            </>
                            {!isTitleEditMode && (
                              <Box
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: '700',
                                  margin: 0,
                                  padding: 0,
                                }}
                                onClick={() => {
                                  setIsTitleEditMode(true);
                                }}>
                                {values?.summary}
                              </Box>
                            )}
                          </Grid>
                          <Grid item xs={12} sx={{ marginTop: 0 }}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                cursor: 'pointer',
                              }}>
                              <Typography
                                variant="body1"
                                fontSize="14px"
                                fontWeight={600}
                                sx={{
                                  margin: '0px !important',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  setIsDescriptionEditMode(true);
                                }}>
                                {' '}
                                {`${`Description`}`}
                              </Typography>
                              {/* <div className={classes.transalationBtn}>
                                <Tooltip title="Translate">
                                  <TranslateIcon
                                    sx={{ height: 18 }}
                                    onClick={() => {
                                      showTranslation(
                                        description,
                                        languageType,
                                        'caseDescription'
                                      );
                                    }}
                                  />
                                </Tooltip>
                              </div> */}
                            </div>
                            <div
                              data-color-mode="light"
                              className="editorMarkup">
                              {isDescriptionEditMode ? (
                                <>
                                  <ClickAwayListener
                                    onClickAway={clickAwayHandler}>
                                    <Box
                                      sx={{
                                        position: 'relative',
                                      }}>
                                      <CKEditor
                                        editor={ClassicEditor}
                                        data={description}
                                        onChange={(event, editor) => {
                                          const data = editor.getData();
                                          setDescription(data);
                                        }}
                                        // onBlur={() => {
                                        //   setIsDescriptionEditMode(false);
                                        // }}
                                        style={{
                                          maxHeight: '400px',
                                          overflow: 'auto',
                                        }}
                                        config={{
                                          placeholder:
                                            'Enter your Description here',
                                          toolbar: [
                                            'heading',
                                            '|',
                                            'bold',
                                            'italic',
                                            'link',
                                            'bulletedList',
                                            'numberedList',
                                            'blockQuote',
                                            'uploadimage',
                                          ],
                                          extraPlugins: [uploadPlugin],
                                        }}
                                      />
                                    </Box>
                                  </ClickAwayListener>
                                </>
                              ) : (
                                <Box
                                  style={{
                                    cursor: 'pointer',
                                    marginTop: 5,
                                    marginBottom: 4,
                                    maxHeight: '300px',
                                    overflow: 'auto',
                                  }}
                                  onClick={() =>
                                    setIsDescriptionEditMode(true)
                                  }>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: description,
                                    }}
                                  />
                                </Box>
                              )}
                              {/* {touched.description && errors.description && (
                                <div
                                  style={{
                                    color: '#C22027',
                                    fontFamily: 'Quicksand,sans-serif',
                                    fontWeight: '400',
                                    fontSize: '0.75rem',
                                    lineHeight: '1.66',
                                    textAlign: 'left',
                                    marginTop: '3px',
                                    marginRight: '14px',
                                    marginBottom: '0',
                                    // marginLeft: '14px',
                                  }}>
                                  {errors.description}
                                </div>
                              )} */}
                              <Box mt={1}>
                                <FileUploadDragDrop
                                  setFilePath={setFilePath}
                                  filePath={filePath}
                                  ref={uploadRef} // Attach the ref to the FileUploadDragDrop component
                                />
                              </Box>
                              {isBugDescriptionEditable ? (
                                <>
                                  {caseData?.caseType?.name ===
                                    'Application Bug' && (
                                    <>
                                      <Box marginTop="16px">
                                        <label htmlFor="label">
                                          {
                                            caseData?.bugQuestions[0]
                                              ?.questionKey
                                          }
                                        </label>
                                        <TextField
                                          type="text"
                                          fullWidth
                                          // placeholder="bugquestion one"
                                          name="bugOccur"
                                          size="small"
                                          multiline
                                          rows={4}
                                          onChange={handleChange}
                                          onBlur={() => {
                                            setIsBugDescriptionEditable(false);
                                          }}
                                          onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                              setIsBugDescriptionEditable(
                                                false
                                              );
                                            }
                                          }}
                                          value={values?.bugOccur}
                                          error={Boolean(
                                            touched?.bugOccur &&
                                              errors?.bugOccur
                                          )}
                                          helperText={
                                            touched?.bugOccur &&
                                            errors?.bugOccur
                                          }
                                        />
                                      </Box>
                                      <Box marginTop="16px">
                                        <label htmlFor="label">
                                          {
                                            caseData?.bugQuestions[1]
                                              ?.questionKey
                                          }
                                        </label>
                                        <TextField
                                          type="text"
                                          fullWidth
                                          // placeholder="bugquestion Two"
                                          name="bugExpect"
                                          size="small"
                                          multiline
                                          rows={4}
                                          onChange={handleChange}
                                          onBlur={() => {
                                            setIsBugDescriptionEditable(false);
                                          }}
                                          onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                              setIsBugDescriptionEditable(
                                                false
                                              );
                                            }
                                          }}
                                          value={values?.bugExpect}
                                          error={Boolean(
                                            touched?.bugExpect &&
                                              errors?.bugExpect
                                          )}
                                          helperText={
                                            touched?.bugExpect &&
                                            errors?.bugExpect
                                          }
                                        />
                                      </Box>
                                    </>
                                  )}
                                </>
                              ) : (
                                <div>
                                  {caseData?.caseType?.name ===
                                    'Application Bug' && (
                                    <Box
                                      style={{ cursor: 'pointer' }}
                                      display="flex"
                                      flexDirection="column"
                                      gap="10px"
                                      onClick={() => {
                                        setIsBugDescriptionEditable(true);
                                      }}>
                                      <label
                                        style={{ cursor: 'pointer' }}
                                        htmlFor="label">
                                        {caseData?.bugQuestions[0]?.questionKey}
                                      </label>
                                      <>{values?.bugOccur}</>
                                      <label
                                        style={{ cursor: 'pointer' }}
                                        htmlFor="label">
                                        {caseData?.bugQuestions[1]?.questionKey}
                                      </label>
                                      <>{values?.bugExpect}</>
                                    </Box>
                                  )}
                                </div>
                              )}
                              {caseData?.attachmentId?.length > 0 && (
                                <div className="attachmentsDiv">
                                  <Typography
                                    gutterBottom
                                    variant="body1"
                                    fontSize="14px"
                                    fontWeight={600}>
                                    Attachments
                                  </Typography>

                                  {caseData?.attachmentId?.length > 0 && (
                                    <div className="editCommentFileParent">
                                      {caseData?.attachmentId?.map(
                                        (item: any) => {
                                          const splittedUrl = item?.split('?');
                                          splittedUrl[1] =
                                            'sp=racwdl&st=2024-02-27T13:21:58Z&se=2030-07-29T21:21:58Z&spr=https&sv=2022-11-02&sr=c&sig=YXOHT7%2Bx8VW3RDdgzehtPZLWJ6zHJz%2Bp3QtnduafL4o%3D';
                                          item = splittedUrl?.join('?');
                                          const getFileType = (items) => {
                                            if (items.includes('.pdf')) {
                                              return 'PDF';
                                            }
                                            if (items.includes('.doc')) {
                                              return 'DOC';
                                            }
                                            if (items.includes('.docx')) {
                                              return 'DOCX';
                                            }
                                            if (items.includes('.txt')) {
                                              return 'TXT';
                                            }
                                            if (items.includes('.xls')) {
                                              return 'XLS';
                                            }
                                            if (items.includes('.xlsx')) {
                                              return 'XLSX';
                                            }
                                            if (items.includes('.csv')) {
                                              return 'CSV';
                                            }
                                            if (items.includes('.tsv')) {
                                              return 'TSV';
                                            }
                                            return null;
                                          };

                                          const fileType = getFileType(item);
                                          if (
                                            item?.includes('.pdf') ||
                                            item?.includes('.doc') ||
                                            item?.includes('.docx') ||
                                            item?.includes('.xls') ||
                                            item?.includes('.xlsx') ||
                                            item?.includes('.csv') ||
                                            item?.includes('.tsv') ||
                                            item?.includes('.txt')
                                          ) {
                                            return (
                                              <>
                                                <a
                                                  role="presentation"
                                                  onClick={() =>
                                                    handleDocumentClick(item)
                                                  }>
                                                  {' '}
                                                  <span>{fileType}</span>
                                                  <InsertDriveFileIcon
                                                    sx={{ fontSize: '60px' }}
                                                  />
                                                </a>
                                              </>
                                            );
                                          }
                                          const imageFormats = [
                                            '.jpg',
                                            '.jpeg',
                                            '.png',
                                            '.gif',
                                            '.bmp',
                                            '.tiff',
                                            '.webp',
                                            '.svg',
                                            '.ico',
                                          ];
                                          if (
                                            imageFormats.some((extension) =>
                                              item?.includes(extension)
                                            )
                                          ) {
                                            return (
                                              <>
                                                <a>
                                                  <img
                                                    role="presentation"
                                                    src={item}
                                                    alt="img"
                                                    onClick={() =>
                                                      handleImageClick(item)
                                                    }
                                                  />
                                                  <CancelIcon
                                                    onClick={async (e) => {
                                                      e.stopPropagation();
                                                      // const updatedFiles = [...filePath];
                                                      // updatedFiles.splice(index, 1);
                                                      // setFilePath(updatedFiles);
                                                      try {
                                                        await casesApi.deleteCaseAttachment(
                                                          caseData?.id,
                                                          item
                                                        );
                                                      } catch (err) {
                                                        console.error(
                                                          err?.message ??
                                                            'Something went wrong'
                                                        );
                                                      }
                                                    }}
                                                    className="editCommentFileEdit"
                                                  />
                                                </a>
                                              </>
                                            );
                                          }
                                          return null;
                                        }
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}

                              {/*           className={`${classes.box} ${isDeclineCase ? classes.pointerEvent : ''}`} */}
                              {!isDeclineCase && (
                                <div
                                  style={{
                                    marginTop: '5px',
                                    display: 'flex',
                                    gap: 16,
                                  }}>
                                  {user?.permissions?.includes(
                                    'case-update'
                                  ) && (
                                    <CustomButton
                                      value="update"
                                      onClick={handleSubmit}
                                      color="secondary"
                                      customClass={classes.caseUpdateButton}
                                    />
                                  )}
                                  <CustomButton
                                    value="cancel"
                                    onClick={handleReturn}
                                    customClass={classes.caseUpdateButton}
                                  />
                                </div>
                              )}
                            </div>
                            <Card
                              style={{
                                marginTop: '16px',
                                padding: '0px 10px 10px 10px',
                                boxShadow: '0px 0px 1px 1px #0C1A4B3D',
                                borderRadius: '16px',
                              }}>
                              <Tabs
                                value={tabValue}
                                onChange={handleTabValue}
                                sx={{
                                  '& .MuiButtonBase-root': {
                                    padding: '0px 10px',
                                    height: '30px',
                                    minHeight: '30px',
                                  },
                                  '& .MuiTabs-scroller': {
                                    height: '30px',
                                    minHeight: '30px',
                                  },
                                  height: '30px',
                                  minHeight: '30px',
                                }}
                                style={{
                                  borderBottom: '1px solid #D9D9D9',
                                }}
                                TabIndicatorProps={{
                                  style: {
                                    backgroundColor: '#c22027',
                                  },
                                }}>
                                <Tab value={1} label="TimeLine" />
                                <Tab value={3} label="WorkLogs" />
                                <Tab value={4} label="Project Documents" />
                              </Tabs>
                              <div>
                                {tabValue === 1 && (
                                  <TimelineSection
                                    data={allLogs}
                                    caseData={caseData}
                                    toggleIsLoad={toggleIsLoad}
                                    user={user}
                                    languageType={languageType}
                                    showTranslation={showTranslation}
                                    handleDocumentClick={handleDocumentClick}
                                    handleImageClick={handleImageClick}
                                  />
                                )}

                                {/* {tabValue === 2 && <JiraSync />} */}
                                {tabValue === 2 && (
                                  <Box>
                                    {allLogs.length > 0 &&
                                      allLogs.map((item) => (
                                        <>
                                          <Box
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent="space-between"
                                            padding="5px 10px"
                                            marginTop="-10px">
                                            <Box
                                              display="flex"
                                              flexDirection="row"
                                              justifyContent="center"
                                              alignItems="center"
                                              gap="10px">
                                              {/*  */}
                                              {item?.change ? (
                                                <AddCommentIcon
                                                  sx={{ marginTop: '10px' }}
                                                />
                                              ) : (
                                                <EditNoteIcon
                                                  sx={{ marginTop: '12px' }}
                                                />
                                              )}

                                              <Typography
                                                fontSize="16px"
                                                fontWeight="700">
                                                {item?.description}
                                              </Typography>
                                              <Typography
                                                fontSize="14px"
                                                fontWeight="700"
                                                px="5px">
                                                by
                                              </Typography>
                                              <Avatar
                                                sx={{
                                                  width: '30px',
                                                  height: '30px',
                                                  marginTop: '15px',
                                                }}
                                              />
                                              <Typography
                                                fontSize="16px"
                                                fontWeight="700">
                                                {item?.createdBy.name}
                                              </Typography>
                                            </Box>
                                            <Typography
                                              color="#7A7A9D"
                                              fontSize="12px"
                                              fontWeight="normal">
                                              {' '}
                                              {time.absoluteTime(
                                                item?.createdAt
                                              )}
                                            </Typography>
                                          </Box>
                                          <Box marginBottom="10px">
                                            {item?.changeFrom && (
                                              <Box padding="5px 10px">
                                                {item?.changeFrom} →{' '}
                                                {item?.changeTo}{' '}
                                              </Box>
                                            )}
                                            {item?.change && (
                                              <Box
                                                padding="5px 10px"
                                                sx={{
                                                  backgroundColor: '#F6F6F6',
                                                  borderRadius: '8px',
                                                }}>
                                                {item?.change}
                                              </Box>
                                            )}
                                          </Box>
                                          <Divider />
                                        </>
                                      ))}
                                  </Box>
                                )}

                                {tabValue === 3 && (
                                  <Box>
                                    {loggedTime.length > 0 ? (
                                      loggedTime.map((item) => (
                                        <Box key={item?.id}>
                                          {' '}
                                          {/* Added key to avoid React warnings */}
                                          <Box
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent="space-between"
                                            padding="0px 8px"
                                            marginTop="-5px">
                                            <Box
                                              display="flex"
                                              flexDirection="row"
                                              alignItems="center"
                                              gap="8px">
                                              <Typography
                                                fontSize="14px"
                                                fontWeight="700"
                                                sx={{
                                                  margin:
                                                    '5px 0px 0px 0px !important',
                                                  textTransform: 'capitalize',
                                                }}>
                                                {item?.createdBy?.name}{' '}
                                                <span
                                                  style={{
                                                    color: '#7A7A9D',
                                                    fontWeight: 'normal',
                                                  }}>
                                                  logged -
                                                </span>{' '}
                                                <span
                                                  style={{ color: '#c22027' }}>
                                                  {convertSecondsToTimeString(
                                                    item?.loggedHoursInSeconds
                                                  )}
                                                </span>
                                              </Typography>
                                            </Box>
                                            <Typography
                                              color="#7A7A9D"
                                              fontSize="12px"
                                              fontWeight="normal">
                                              {item?.date
                                                ? moment(item?.date).format(
                                                    'LL'
                                                  )
                                                : 'N/A'}
                                            </Typography>
                                          </Box>
                                          <Box
                                            sx={{
                                              background: '#F6F6F6',
                                              borderRadius: '6px',
                                              padding: '3px 8px',
                                              marginBottom: '8px',
                                              fontSize: '12px',
                                            }}>
                                            {item?.description}
                                          </Box>
                                          <Divider />
                                        </Box>
                                      ))
                                    ) : (
                                      <Typography
                                        color="#7A7A9D"
                                        fontSize="12px"
                                        fontWeight="500"
                                        padding="8px"
                                        textAlign="center">
                                        No logged time available.
                                      </Typography>
                                    )}
                                  </Box>
                                )}

                                {tabValue === 4 && (
                                  <Box className={classes.projLinks}>
                                    {Object.keys(projectLinks).length > 0 ? (
                                      Object.keys(projectLinks).map(
                                        (section) => {
                                          const sectionTitles = {
                                            googleDocLinks: 'Google Doc Links',
                                            figmaLinks: 'Figma Links',
                                            drawIOLinks: 'DrawIO Links',
                                            lowLevelDesign:
                                              'Low Level Design Links',
                                            highLevelDesign:
                                              'High Level Design Links',
                                          };

                                          const sectionTitle =
                                            sectionTitles[section] || 'Links';
                                          const links = projectLinks[section];

                                          return (
                                            <div
                                              key={section}
                                              className="projLinkSec"
                                              style={{ marginBottom: '0px' }}>
                                              <Typography
                                                variant="h6"
                                                style={{
                                                  fontSize: '14px',
                                                  fontWeight: 'bold',
                                                }}>
                                                {sectionTitle}
                                              </Typography>
                                              {links.length > 0 ? (
                                                <ul
                                                  style={{
                                                    // paddingLeft: '15px',
                                                    margin: '5px 0',
                                                  }}>
                                                  {links.map((link, index) => (
                                                    <li
                                                      key={
                                                        link.url ||
                                                        link.label ||
                                                        index
                                                      }
                                                      style={{
                                                        marginBottom: '5px',
                                                        marginTop: '5px',
                                                        height: '25px',
                                                      }}>
                                                      <a
                                                        href={link.url || '#'}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{
                                                          // color: '#007bff',
                                                          fontSize: '12px',
                                                        }}>
                                                        {link.label || 'N/A'}
                                                      </a>
                                                    </li>
                                                  ))}
                                                </ul>
                                              ) : (
                                                <Typography
                                                  color="#7A7A9D"
                                                  fontSize="14px"
                                                  fontWeight="500"
                                                  padding="8px"
                                                  textAlign="center">
                                                  No Links available for this
                                                  section.
                                                </Typography>
                                              )}
                                            </div>
                                          );
                                        }
                                      )
                                    ) : (
                                      <Typography
                                        color="#7A7A9D"
                                        fontSize="14px"
                                        fontWeight="500"
                                        padding="8px"
                                        textAlign="center">
                                        No Links available.
                                      </Typography>
                                    )}
                                  </Box>
                                )}
                              </div>
                            </Card>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      className={`${classes.box} ${
                        isDeclineCase ? classes.pointerEvent : ''
                      }`}>
                      <Card className="rightSide">
                        <CardContent>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}>
                            <div className="rightAccordion">
                              <Typography
                                gutterBottom
                                variant="h4"
                                component="div">
                                Details
                              </Typography>
                              <Divider />
                              <div
                                style={{
                                  display: 'grid',
                                  gridTemplateColumns: '1fr 1fr ',
                                  rowGap: '5px',
                                }}>
                                <Typography fontSize="12px" fontWeight="700">
                                  Case Type
                                </Typography>

                                {isCaseTypeEditMode ? (
                                  <>
                                    <FormControl fullWidth>
                                      <Select
                                        id="case-types"
                                        // disabled={!user?.permissions?.includes('case-update')}
                                        disabled
                                        value={
                                          Object.keys(caseType).length !== 0 &&
                                          caseType?.id
                                        }
                                        onBlur={() => {
                                          setIsCaseTypeEditMode(false);
                                        }}
                                        size="small">
                                        {caseTypeOptions?.map((opt) => (
                                          <MenuItem
                                            value={opt?.id}
                                            data-name={opt?.name}>
                                            {opt?.name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </>
                                ) : (
                                  <Typography
                                    fontSize="12px"
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'flex-start',
                                    }}
                                    // onClick={() => setIsCaseTypeEditMode(true)}
                                  >
                                    {caseType?.name || 'Select Case Type'}
                                  </Typography>
                                )}

                                <Typography fontSize="12px" fontWeight="700">
                                  Priority
                                </Typography>
                                {isPriorityEditMode ? (
                                  <FormControl fullWidth>
                                    <Select
                                      id="priorities"
                                      value={
                                        Object.keys(priority).length !== 0 &&
                                        priority?.id
                                      }
                                      disabled={
                                        !user?.permissions?.includes(
                                          'case-update'
                                        )
                                      }
                                      onBlur={() =>
                                        setIsPriorityEditMode(false)
                                      }
                                      onClick={(e) => {
                                        if (
                                          user?.permissions?.includes(
                                            'case-update'
                                          ) &&
                                          e
                                        ) {
                                          const getTarget: any = e.target;
                                          const dataName =
                                            getTarget?.getAttribute(
                                              'data-name'
                                            );
                                          const dataValue =
                                            getTarget?.getAttribute(
                                              'data-value'
                                            );
                                          if (dataValue !== null) {
                                            setPriority({
                                              ...priority,
                                              id: dataValue,
                                              name: dataName,
                                            });
                                            updateIndividual('priority', {
                                              ...priority,
                                              id: dataValue,
                                              name: dataName,
                                            });
                                          }
                                        }
                                        setIsPriorityEditMode(false);
                                      }}
                                      sx={{
                                        height: '25px',
                                        fontSize: '12px',
                                      }}
                                      size="small">
                                      {priorityAllOptions?.map((opt) => (
                                        <MenuItem
                                          key={opt?.id}
                                          value={opt?.id}
                                          data-name={opt?.name}>
                                          {opt?.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                ) : (
                                  <Typography
                                    fontSize="12px"
                                    fontWeight="700"
                                    sx={{
                                      cursor: 'pointer',
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                    onClick={() => setIsPriorityEditMode(true)}>
                                    <span
                                      style={{
                                        display: 'inline-block',
                                        width: '8px',
                                        height: '8px',
                                        borderRadius: '50%',
                                        backgroundColor: getColor(
                                          priority?.name || ''
                                        ),
                                        marginRight: '8px',
                                      }}
                                    />
                                    {priority?.name || 'Select Priority'}
                                  </Typography>
                                )}

                                {caseData?.caseType?.name ===
                                  'Application Bug' && (
                                  <Typography fontSize="12px" fontWeight="700">
                                    Severity
                                  </Typography>
                                )}
                                {isSeverityEditMode ? (
                                  <>
                                    {caseData?.caseType?.name ===
                                      'Application Bug' && (
                                      <div className=" labelsDataCss">
                                        <Autocomplete
                                          id="severities"
                                          options={allSeverities || []}
                                          value={severity}
                                          onBlur={() => {
                                            setIsSeverityEditMode(false);
                                          }}
                                          onChange={(
                                            event: any,
                                            value: any
                                          ) => {
                                            if (value !== null) {
                                              setFieldValue('severity', value);
                                              updateIndividual(
                                                'severity',
                                                value
                                              );
                                              setIsSeverityEditMode(false);
                                            }
                                          }}
                                          getOptionLabel={(option) =>
                                            option?.name
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              variant="outlined"
                                              placeholder="Severity*"
                                              InputProps={{
                                                ...params.InputProps,
                                                sx: {
                                                  height: '25px !important',
                                                  '& input': {
                                                    fontSize: '12px',
                                                    height: 'auto !important',
                                                  },
                                                },
                                              }}
                                            />
                                          )}
                                        />
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {caseData?.caseType?.name ===
                                    'Application Bug' ? (
                                      <Box maxWidth="100px" maxHeight="20px">
                                        <Chip
                                          sx={{
                                            height: '100%',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            backgroundColor: getColor(
                                              severity?.name || ''
                                            ),
                                            color: 'white',
                                            textShadow: '2px black',
                                            '&:hover': {
                                              backgroundColor: getColor(
                                                severity?.name || ''
                                              ),
                                            },
                                            '& .MuiChip-labelSmall': {
                                              paddingLeft: '5px !important',
                                              paddingRight: '5px !important',
                                            },
                                          }}
                                          onClick={() =>
                                            setIsSeverityEditMode(true)
                                          }
                                          label={severity?.name || '-'}
                                          size="small"
                                        />
                                      </Box>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                                <Typography fontSize="12px" fontWeight="700">
                                  Assignee
                                </Typography>
                                {isAssigneeEditMode ? (
                                  <>
                                    <Autocomplete
                                      id="assigneeID"
                                      options={
                                        allInternalUsers?.length === 0
                                          ? []
                                          : allInternalUsers
                                      }
                                      onChange={(event: any, value: any) => {
                                        setFieldValue('assigneeID', {
                                          id: value?.id,
                                          name: value?.name,
                                        });
                                        updateIndividual('assignee', {
                                          ...assigneeId,
                                          name: value?.name,
                                          id: value?.id,
                                        });
                                        setIsAssigneeEditMode(false);
                                      }}
                                      getOptionLabel={(option) =>
                                        Object.keys(option).length !== 0 &&
                                        option?.name
                                      }
                                      inputValue={
                                        hasMultipleNulls
                                          ? ''
                                          : Object.keys(assigneeId).length !==
                                              0 && assigneeId?.name
                                      }
                                      onBlur={() => {
                                        setIsAssigneeEditMode(false);
                                      }}
                                      onInputChange={(
                                        event: any,
                                        newInputValue: any
                                      ) => {
                                        if (event) {
                                          setAssigneeId({
                                            ...assigneeId,
                                            name: newInputValue,
                                          });
                                        }
                                      }}
                                      disabled={
                                        !user?.permissions?.includes(
                                          'case-update'
                                        )
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="outlined"
                                          InputProps={{
                                            ...params.InputProps,
                                            sx: {
                                              height: '25px !important',
                                              '& input': {
                                                fontSize: '12px',
                                                height: 'auto !important',
                                              },
                                            },
                                          }}
                                        />
                                      )}
                                    />
                                  </>
                                ) : (
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    gap="10px"
                                    onClick={() => {
                                      setIsAssigneeEditMode(true);
                                    }}
                                    style={{
                                      fontSize: '12px',
                                      // fontWeight: '600',
                                    }}>
                                    {/* <Avatar sx={{ width: 20, height: 20 }} /> */}
                                    {assigneeId?.name}
                                  </Box>
                                )}

                                <Typography fontSize="12px" fontWeight="700">
                                  Project
                                </Typography>
                                {isProjectEditable ? (
                                  <>
                                    <TextField
                                      id="outlined-project"
                                      value={values?.projectId?.name}
                                      onBlur={() => {
                                        setIsProjectEditable(false);
                                      }}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  </>
                                ) : (
                                  <Typography
                                    fontSize="12px"
                                    fontWeight="700"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      // setIsProjectEditable(true)
                                    }}>
                                    {values?.projectId?.name}
                                  </Typography>
                                )}

                                <Typography fontSize="12px" fontWeight="700">
                                  Organization
                                </Typography>
                                {isOrganizationEditable ? (
                                  <>
                                    <TextField
                                      id="outlined-select-organization"
                                      value={values?.orgId?.name}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      onBlur={() => {
                                        setIsOrganizationEditable(false);
                                      }}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  </>
                                ) : (
                                  <Typography
                                    fontSize="12px"
                                    fontWeight="700"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      // setIsProjectEditable(true)
                                    }}>
                                    {values?.orgId?.name}
                                  </Typography>
                                )}
                                <Typography fontSize="12px" fontWeight="700">
                                  Sponsers
                                </Typography>

                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  gap="5px">
                                  {values?.sponsorId?.map((chip) => (
                                    <Chip
                                      key={chip?.id}
                                      label={chip?.name}
                                      size="small"
                                      sx={{
                                        fontSize: '12px',
                                        marginRight: '0px',
                                        marginBottom: '0px',
                                        maxWidth: '150px',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        cursor: 'no-drop',
                                        '& .MuiChip-labelSmall': {
                                          paddingLeft: '5px !important',
                                          paddingRight: '5px !important',
                                        },
                                      }}
                                    />
                                  ))}
                                </Box>

                                <Typography fontSize="12px" fontWeight="700">
                                  Stakeholder
                                </Typography>
                                {isStakeholderEditMode ? (
                                  <>
                                    <Autocomplete
                                      id="stakeHolderId-name"
                                      multiple
                                      disableClearable
                                      limitTags={1}
                                      options={
                                        allStakeholders?.length === 0
                                          ? []
                                          : allStakeholders?.filter(
                                              (itm1) =>
                                                !values?.stakeHolderId?.some(
                                                  (itm2) =>
                                                    itm1?.id === itm2?.id
                                                )
                                            )
                                      }
                                      onChange={(event: any, value: any) => {
                                        if (event) {
                                          // updateIndividual('stakeHolder', [...values?.stakeHolderId, ...value]);
                                          updateIndividual(
                                            'stakeHolder',
                                            value
                                          );
                                          setIsStakeholderEditMode(false);
                                        }
                                      }}
                                      onBlur={() => {
                                        setIsStakeholderEditMode(false);
                                      }}
                                      value={values?.stakeHolderId}
                                      getOptionLabel={(option) =>
                                        Object.keys(option)?.length !== 0 &&
                                        option?.name
                                      }
                                      inputValue={stakeholderInput}
                                      disabled={
                                        !user?.permissions?.includes(
                                          'case-update'
                                        )
                                      }
                                      onInputChange={(
                                        event: any,
                                        newInputValue: any
                                      ) => {
                                        if (event) {
                                          setStakeholderInput(newInputValue);
                                        }
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="outlined"
                                          // sx={{marginTop: '5px',}}
                                          // placeholder="Add Stakeholder"
                                          InputProps={{
                                            ...params.InputProps,
                                            sx: {
                                              height: '25px !important',
                                              '& input': {
                                                fontSize: '12px',
                                                height: 'auto !important',
                                              },
                                              '& .MuiChip-root': {
                                                fontSize: '12px',
                                                height: '18px !important',
                                              },
                                            },
                                          }}
                                        />
                                      )}
                                    />
                                  </>
                                ) : (
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="flex-start"
                                    gap="10px"
                                    onClick={() => {
                                      setIsStakeholderEditMode(true);
                                    }}>
                                    {values?.stakeHolderId
                                      ?.slice(0, 1)
                                      .map((stakeholder) => (
                                        <Box
                                          key={stakeholder.id}
                                          display="flex"
                                          flexDirection="row"
                                          alignItems="center"
                                          gap="10px"
                                          sx={{
                                            fontSize: '12px',
                                          }}>
                                          {stakeholder.name}{' '}
                                          {values?.stakeHolderId.length >=
                                            2 && (
                                            <>
                                              +
                                              {values?.stakeHolderId.length - 1}{' '}
                                              more
                                            </>
                                          )}{' '}
                                        </Box>
                                      ))}
                                  </Box>
                                )}
                                <Typography fontSize="12px" fontWeight="700">
                                  Escalation Manager
                                </Typography>
                                {isEscalationManagerEditMode ? (
                                  <>
                                    <Autocomplete
                                      id="escalation-manager"
                                      options={
                                        allInternalUsers?.length === 0
                                          ? []
                                          : allInternalUsers
                                      }
                                      getOptionLabel={(option) =>
                                        Object.keys(option).length !== 0 &&
                                        option?.name
                                      }
                                      onChange={(event: any, value: any) => {
                                        setEscalationManagerId(value);
                                        updateIndividual('escalationManager', {
                                          ...escalationManagerId,
                                          id: value?.id,
                                          name: value?.name,
                                        });
                                        setIsEscalationManagerEditMode(false);
                                      }}
                                      inputValue={escalationManagerInput}
                                      onInputChange={(
                                        event: any,
                                        newInputValue: any
                                      ) => {
                                        setEscalationManagerInput(
                                          newInputValue
                                        );
                                      }}
                                      onBlur={() => {
                                        setIsEscalationManagerEditMode(false);
                                      }}
                                      disabled={
                                        caseData?.escalationManager?.id ===
                                          '' ||
                                        caseData?.escalationManager?.id ===
                                          null ||
                                        caseData?.escalationManager?.id ===
                                          undefined ||
                                        !user?.permissions?.includes(
                                          'case-update'
                                        )
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="outlined"
                                          name="escalationManagerIds"
                                          InputProps={{
                                            ...params.InputProps,
                                            sx: {
                                              height: '25px !important',
                                              '& input': {
                                                fontSize: '12px',
                                                height: 'auto !important',
                                              },
                                            },
                                          }}
                                        />
                                      )}
                                    />
                                  </>
                                ) : (
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    gap="10px"
                                    onClick={() => {
                                      setIsEscalationManagerEditMode(true);
                                    }}
                                    sx={{
                                      fontSize: '12px',
                                    }}>
                                    {/* <Avatar sx={{ width: 20, height: 20 }} /> */}
                                    {escalationManagerId?.name || '-'}
                                  </Box>
                                )}

                                <Typography fontSize="12px" fontWeight="700">
                                  Sprints
                                </Typography>
                                {isSprintsEditable ? (
                                  <Autocomplete
                                    options={sprints || []}
                                    value={currentSprint || null}
                                    // eslint-disable-next-line consistent-return
                                    onChange={(event: any, value: any) => {
                                      setCurrentSprint(value);
                                      updateIndividual('sprint', value);
                                      setIsSprintsEditable(false);
                                    }}
                                    getOptionLabel={(option) =>
                                      option?.name || ''
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        name="sprint"
                                        placeholder="Sprint"
                                        InputProps={{
                                          ...params.InputProps,
                                          sx: {
                                            height: '25px !important',
                                            '& input': {
                                              fontSize: '12px',
                                              height: 'auto !important',
                                            },
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                ) : (
                                  <Typography
                                    fontSize="12px"
                                    onClick={() => {
                                      setIsSprintsEditable(true);
                                    }}>
                                    {currentSprint?.name || 'N/A'}
                                  </Typography>
                                )}

                                <Typography fontSize="12px" fontWeight="700">
                                  Fix Versions
                                </Typography>
                                {isFixVersionEditable ? (
                                  <Autocomplete
                                    options={allFixVersions || []}
                                    // value={billingAccountType?.length > 0 ?
                                    //   billingAccounts?.filter((item) => item?._id === billingAccountType)?.pop()?.name : ''}
                                    // eslint-disable-next-line consistent-return
                                    onChange={(event: any, value: any) => {
                                      setReleaseVersion(value);
                                      updateIndividual('releaseVersion', [
                                        value,
                                      ]);
                                      setIsFixVersionEditable(false);
                                    }}
                                    getOptionLabel={(option) => option?.name}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        name="releaseVersion"
                                        placeholder="Release Version"
                                        InputProps={{
                                          ...params.InputProps,
                                          sx: {
                                            height: '25px !important',
                                            '& input': {
                                              fontSize: '12px',
                                              height: 'auto !important',
                                            },
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                ) : (
                                  <Typography
                                    fontSize="12px"
                                    onClick={() => {
                                      setIsFixVersionEditable(true);
                                    }}>
                                    {releaseVersion?.length > 0
                                      ? releaseVersion[0]?.name
                                      : 'N/A'}{' '}
                                  </Typography>
                                )}

                                <Typography fontSize="12px" fontWeight="700">
                                  Labels
                                </Typography>
                                {isLabelEditable ? (
                                  <>
                                    <Autocomplete
                                      multiple
                                      options={labelsOptions}
                                      limitTags={1}
                                      value={labelsData}
                                      onChange={(e: any, newValue: any) => {
                                        if (e && newValue) {
                                          setLabelsData(newValue);
                                          updateIndividual('labels', newValue);
                                          setIsLabelEditable(false);
                                        }
                                      }}
                                      onBlur={() => {
                                        setIsLabelEditable(false);
                                      }}
                                      inputValue={labelInputValue}
                                      onInputChange={(
                                        event: any,
                                        newInputValue: any
                                      ) => {
                                        if (event) {
                                          setLabelInputValue(newInputValue);
                                        }
                                      }}
                                      // filterOptions={customFilterOptions}
                                      disabled={
                                        !user?.permissions?.includes(
                                          'case-update'
                                        )
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label=""
                                          variant="outlined"
                                          name="labelsId"
                                          InputProps={{
                                            ...params.InputProps,
                                            sx: {
                                              height: '25px !important',
                                              '& input': {
                                                fontSize: '12px',
                                                height: 'auto !important',
                                              },
                                              '& .MuiChip-root': {
                                                fontSize: '12px',
                                                height: '18px !important',
                                              },
                                            },
                                          }}
                                        />
                                      )}
                                    />
                                  </>
                                ) : (
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    gap="5px">
                                    {labelsData.length > 0 ? (
                                      labelsData.map((item) => (
                                        <Box
                                          onClick={() =>
                                            setIsLabelEditable(true)
                                          }
                                          key={item}>
                                          <Chip
                                            sx={{
                                              cursor: 'pointer',
                                              display: 'flex',
                                              alignItems: 'center',
                                              color: 'black',
                                              textShadow: '2px black',
                                              maxWidth: '100px',
                                              fontSize: '12px',
                                              '& .MuiChip-labelSmall': {
                                                paddingLeft: '5px !important',
                                                paddingRight: '5px !important',
                                              },
                                            }}
                                            label={item}
                                            size="small"
                                          />
                                        </Box>
                                      ))
                                    ) : (
                                      <Box
                                        sx={{
                                          fontSize: '12px',
                                        }}
                                        onClick={() =>
                                          setIsLabelEditable(true)
                                        }>
                                        N/A
                                      </Box>
                                    )}
                                  </Box>
                                )}

                                <Typography fontSize="12px" fontWeight="700">
                                  Expense
                                </Typography>
                                {isBillingEditable ? (
                                  <>
                                    <Autocomplete
                                      id="caseAccountBilling-type"
                                      onBlur={() => {
                                        setIsBillingEditable(false);
                                      }}
                                      options={[
                                        {
                                          id: 'Opex',
                                          name: 'Opex',
                                        },
                                        {
                                          id: 'Capex',
                                          name: 'Capex',
                                        },
                                      ]}
                                      value={billingType}
                                      onChange={(event: any, value: any) => {
                                        if (value !== null) {
                                          setBillingType(value);
                                          updateIndividual(
                                            'expense',
                                            value?.id
                                          );
                                        }
                                        setIsBillingEditable(false);
                                      }}
                                      getOptionLabel={(option) => option?.name}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          name="expense"
                                          variant="outlined"
                                          placeholder="Billing Type*"
                                          InputProps={{
                                            ...params.InputProps,
                                            sx: {
                                              height: '25px !important',
                                              '& input': {
                                                fontSize: '12px',
                                                height: 'auto !important',
                                              },
                                            },
                                          }}
                                          // error={Boolean(formik?.touched?.expense && formik?.errors?.expense)}
                                          // helperText={formik?.touched?.expense && formik?.errors?.expense}
                                        />
                                      )}
                                    />
                                  </>
                                ) : (
                                  <Box
                                    sx={{
                                      display: 'inline-flex',
                                    }}
                                    maxWidth="150px">
                                    <Typography
                                      fontSize="12px"
                                      color={
                                        billingType?.name === 'Opex'
                                          ? '#c22027'
                                          : 'rgba(29, 122, 21, 1)'
                                      }
                                      fontWeight="700"
                                      sx={{
                                        cursor: 'pointer',
                                        backgroundColor:
                                          billingType?.name === 'Opex'
                                            ? '#FFE6E4'
                                            : 'rgba(7, 83, 0, 0.1)',
                                        padding: '0px 4px',
                                        borderRadius: '4px',
                                      }}
                                      onClick={() =>
                                        setIsBillingEditable(true)
                                      }>
                                      {billingType?.name || 'N/A'}
                                    </Typography>
                                  </Box>
                                )}

                                <Typography fontSize="12px" fontWeight="700">
                                  Start Date
                                </Typography>

                                {isStartDateEditable ? (
                                  <>
                                    <TextField
                                      value={moment(startDate).format(
                                        'YYYY-MM-DD'
                                      )}
                                      onChange={handleStartDateChange}
                                      onBlur={() => {
                                        setIsStartDateEditable(false);
                                      }}
                                      size="small"
                                      fullWidth
                                      type="date"
                                      className={classes.datePicker}
                                      InputProps={{
                                        sx: {
                                          height: '25px',
                                          fontSize: '12px',
                                        },
                                      }}
                                    />
                                  </>
                                ) : (
                                  <Box
                                    sx={{
                                      display: 'inline-flex',
                                    }}
                                    maxWidth="150px">
                                    <Typography
                                      fontSize="12px"
                                      fontWeight="700"
                                      sx={{ cursor: 'pointer' }}
                                      onClick={() =>
                                        setIsStartDateEditable(true)
                                      }>
                                      <Typography fontSize="12px">
                                        {startDate || 'N/A'}
                                      </Typography>
                                    </Typography>
                                  </Box>
                                )}

                                <Typography fontSize="12px" fontWeight="700">
                                  Due Date
                                </Typography>
                                {isDueDateEditable ? (
                                  <>
                                    <TextField
                                      value={moment(dueDate).format(
                                        'YYYY-MM-DD'
                                      )}
                                      onChange={handleDueDateChange}
                                      onBlur={() => {
                                        setIsDueDateEditable(false);
                                      }}
                                      size="small"
                                      fullWidth
                                      type="date"
                                      className={classes.datePicker}
                                      InputProps={{
                                        sx: {
                                          height: '25px',
                                          fontSize: '12px',
                                        },
                                      }}
                                    />
                                  </>
                                ) : (
                                  <Box
                                    sx={{
                                      display: 'inline-flex',
                                    }}
                                    maxWidth="150px">
                                    <Typography
                                      fontSize="12px"
                                      fontWeight="700"
                                      sx={{ cursor: 'pointer' }}
                                      onClick={() =>
                                        setIsDueDateEditable(true)
                                      }>
                                      <Typography fontSize="12px">
                                        {dueDate || 'N/A'}
                                      </Typography>
                                    </Typography>
                                  </Box>
                                )}

                                <Typography fontSize="12px" fontWeight="700">
                                  Time Estimate
                                </Typography>

                                {isTimeEstimateEditable ? (
                                  <TextField
                                    id="outlined-project"
                                    value={timeEstimate}
                                    onBlur={() => {
                                      setIsTimeEstimateEditable(false);
                                      updateIndividual(
                                        'timeEstimate',
                                        convertToSeconds(timeEstimate)
                                      );
                                    }}
                                    onChange={(e) => {
                                      setTimeEstimate(e.target.value);
                                    }}
                                    // onKeyDown={(e) => {
                                    //   if (e.key === 'Enter') {

                                    //     updateIndividual('timeEstimate', convertToSeconds(timeEstimate))
                                    //     setIsTimeEstimateEditable(false)
                                    //   }
                                    // }}
                                    InputProps={{
                                      sx: {
                                        height: '25px',
                                        fontSize: '12px',
                                      },
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                ) : (
                                  <Typography
                                    fontSize="12px"
                                    onClick={() => {
                                      setIsTimeEstimateEditable(true);
                                    }}>
                                    {timeEstimate || '0h'}
                                  </Typography>
                                )}

                                <Typography fontSize="12px" fontWeight="700">
                                  Account
                                </Typography>
                                {billingAccountTypeEditable ? (
                                  <Autocomplete
                                    options={billingAccounts}
                                    value={
                                      billingAccountType?.length > 0
                                        ? billingAccounts
                                            ?.filter(
                                              (item) =>
                                                item?._id === billingAccountType
                                            )
                                            ?.pop()?.name
                                        : ''
                                    }
                                    // eslint-disable-next-line consistent-return
                                    onChange={(event: any, value: any) => {
                                      value.id = value?._id;
                                      setBillingAccountType(value);
                                      updateIndividual(
                                        'billingAccountType',
                                        value
                                      );
                                      setBillingAccountTypeEditable(false);
                                    }}
                                    getOptionLabel={(option) => option?.name}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        name="billingAccountType"
                                        placeholder="Account"
                                        InputProps={{
                                          ...params.InputProps,
                                          sx: {
                                            height: '25px !important',
                                            '& input': {
                                              fontSize: '12px',
                                              height: 'auto !important',
                                            },
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                ) : (
                                  <Typography
                                    fontSize="12px"
                                    onClick={() => {
                                      setBillingAccountTypeEditable(true);
                                    }}>
                                    {!isEmpty(billingAccountType)
                                      ? caseData?.billingAccountType?.name ||
                                        'N/A'
                                      : 'N/A'}
                                  </Typography>
                                )}

                                <Typography fontSize="12px" fontWeight="700">
                                  Case Owner / Reporter
                                </Typography>
                                <Typography fontSize="12px" fontWeight="700">
                                  {values?.caseOwner}
                                </Typography>

                                {/* <div className=" labelsDataCss">
                                  <label htmlFor="label">{`${`Time Estimate`}`}</label>
                                  <TextField
                                    id="outlined-project"
                                    value={timeEstimate}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div> */}
                              </div>
                            </div>
                            {/* {user?.permissions?.includes(
                              'case-escalate-read'
                            ) && (
                                <div className="rightAccordion">
                                  <Typography
                                    gutterBottom
                                    variant="h4"
                                    component="div">
                                    Escalate
                                  </Typography>
                                  <div className="jiraSync">
                                    {caseData?.escalations?.length ? (
                                      caseData?.escalations?.map(
                                        (escalation: any) => (
                                          <div>
                                            <span>
                                              <b>
                                                {escalation?.escalatedBy?.name}
                                              </b>
                                            </span>{' '}
                                            <span>has escalated on </span>{' '}
                                            <span>
                                              {time.absoluteTime(
                                                escalation?.createdAt
                                              )}
                                            </span>
                                          </div>
                                        )
                                      )
                                    ) : (
                                      <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.button}
                                        startIcon={<SwapVerticalCircleIcon />}
                                        onClick={escalateById}>
                                        Escalate
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              )} */}

                            {/* <div className="rightAccordion">
                              <Typography
                                gutterBottom
                                variant="h4"
                                component="div"
                                sx={{ marginBottom: '4px' }}>
                                SLAs
                              </Typography>
                              <div className="caseRightInner">
                                <div className="sLa">
                                  <span
                                    className={`${caseData?.sla?.isResolveTimeViolated ===
                                      true
                                      ? 'bgRed'
                                      : ''
                                      } btnTime`}>
                                    {caseData?.sla?.actualTime?.resolveTime !==
                                      null &&
                                      moment(
                                        caseData?.sla?.actualTime?.resolveTime
                                      ).format('MMM Do hh:mm')}{' '}
                                    <QueryBuilderIcon />
                                  </span>
                                  <span className="resolveTime">
                                    Time to resolution
                                    <p>
                                      {`before ${time.absoluteTime(
                                        caseData?.sla?.estimatedTime
                                          ?.resolveTime
                                      )}`}
                                    </p>
                                  </span>
                                </div>
                              </div>
                              <div className="caseRightInner">
                                <div className="sLa">
                                  <span
                                    className={`${caseData?.sla
                                      ?.isFirstResponseTimeViolated === true
                                      ? 'bgRed'
                                      : ''
                                      } btnTimeFirst`}>
                                    {caseData?.sla?.actualTime
                                      ?.firstResponseTime !== null &&
                                      moment(
                                        caseData?.sla?.actualTime
                                          ?.firstResponseTime
                                      ).format('MMM Do hh:mm')}{' '}
                                    <ClearIcon />
                                  </span>
                                  <span className="resolveTime">
                                    Time to first response
                                    <p>
                                      {`before ${time.absoluteTime(
                                        caseData?.sla?.estimatedTime
                                          ?.firstResponseTime
                                      )}`}
                                    </p>
                                  </span>
                                </div>
                              </div>
                            </div> */}
                            {/* {user?.permissions?.includes(
                              'case-jira-sync-read'
                            ) && (
                                <div className="rightAccordion">
                                  <Typography
                                    gutterBottom
                                    variant="h4"
                                    component="div"
                                    sx={{ marginBottom: '4px' }}>
                                    Jira Sync
                                  </Typography>
                                  <div className="jiraSync">
                                    <span className="">
                                      {caseData?.thirdPartySync?.isSynced ? (
                                        <>
                                          <Box>
                                            <span>Synced &nbsp;</span>
                                            <a
                                              href={
                                                caseData?.thirdPartySync
                                                  ?.syncedCaseLink
                                              }
                                              target="_blank"
                                              rel="noreferrer">
                                              {
                                                caseData?.thirdPartySync
                                                  ?.syncedCaseKey
                                              }
                                            </a>
                                          </Box>
                                        </>
                                      ) : (
                                        <>
                                          {isSyncing ? (
                                            <CircularProgress />
                                          ) : (
                                            <Button
                                              variant="contained"
                                              color="secondary"
                                              className={classes.button}
                                              startIcon={
                                                <SwapVerticalCircleIcon />
                                              }
                                              onClick={syncJiraByCaseId}>
                                              Sync
                                            </Button>
                                          )}
                                        </>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              )} */}

                            {/* <div className="rightAccordion">

                            </div> */}
                            <div
                              className="createdAt"
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '10px',
                              }}>
                              <p
                                title={moment(caseData?.createdAt).format(
                                  'MMM DD YYYY - HH:mm:ss'
                                )}
                                style={{
                                  fontSize: '12px',
                                  // fontWeight: '600',
                                }}>
                                Created:{' '}
                                <span>
                                  {time.absoluteTime(caseData?.createdAt)}
                                </span>
                              </p>
                              <p
                                title={moment(caseData?.updatedAt).format(
                                  'MMM DD YYYY - HH:mm:ss'
                                )}
                                style={{
                                  fontSize: '12px',
                                  // fontWeight: '600',
                                }}>
                                Updated:{' '}
                                <span>
                                  {time.absoluteTime(caseData?.updatedAt)}
                                </span>
                              </p>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                      <Card style={{ marginTop: '10px' }}>
                        <CardContent sx={{ paddingBottom: '5px !important' }}>
                          <div className="orgInfo">
                            <Typography
                              gutterBottom
                              variant="h5"
                              sx={{ fontWeight: 'bold', fontSize: '14px' }}
                              component="div">
                              Case Documents
                            </Typography>
                            <Divider />
                            <Typography
                              style={{
                                fontSize: '12px',
                                fontWeight: '600',
                                marginTop: '1rem',
                              }}
                              variant="h5">
                              Google Doc Links
                            </Typography>
                            <MultiFileLinkInputCase
                              placeholder="Google Doc Link"
                              labelPlaceholder="Google Doc Label"
                              inputs={googleDocLinks}
                              onAddInput={handleAddGoogleDocLinks}
                              onRemoveInput={(id) =>
                                handleRemoveGoogleDocLinks(id)
                              }
                              onInputChange={(id, event) =>
                                setGoogleDocLinks(
                                  googleDocLinks.map((input) =>
                                    input.id === id
                                      ? { ...input, value: event.target.value }
                                      : input
                                  )
                                )
                              }
                              onLabelChange={(id, event) =>
                                setGoogleDocLinks(
                                  googleDocLinks.map((input) =>
                                    input.id === id
                                      ? { ...input, label: event.target.value }
                                      : input
                                  )
                                )
                              }
                              // disabled={editInfo[2]}
                              mainClassName="multiFileInputContainer"
                              inputClassName={classes.inputClassName}
                              labelClassName={classes.labelClassName}
                              // inputClassName={` ${!editInfo[2] ? 'orgEditEnable' : 'orgEdit'
                              //   } `}
                              // labelClassName={` ${!editInfo[2] ? 'orgEditEnable' : 'orgEdit'
                              //   } `}
                            />
                          </div>
                          <div className="orgInfo">
                            <Typography
                              style={{ fontSize: '12px', fontWeight: '600' }}
                              variant="h5">
                              Figma Links
                            </Typography>
                            <MultiFileLinkInputCase
                              placeholder="Figma Link"
                              labelPlaceholder="Figma Label"
                              inputs={figmaLinks}
                              onAddInput={handleAddFigmaLinks}
                              onRemoveInput={(id) => handleRemoveFigmaLinks(id)}
                              onInputChange={(id, event) =>
                                setFigmaLinks(
                                  figmaLinks.map((input) =>
                                    input.id === id
                                      ? { ...input, value: event.target.value }
                                      : input
                                  )
                                )
                              }
                              onLabelChange={(id, event) =>
                                setFigmaLinks(
                                  figmaLinks.map((input) =>
                                    input.id === id
                                      ? { ...input, label: event.target.value }
                                      : input
                                  )
                                )
                              }
                              // disabled={editInfo[2]}
                              mainClassName="multiFileInputContainer"
                              inputClassName={classes.inputClassName}
                              labelClassName={classes.labelClassName}
                              // inputClassName={` ${!editInfo[2] ? 'orgEditEnable' : 'orgEdit'
                              //   } `}
                              // labelClassName={` ${!editInfo[2] ? 'orgEditEnable' : 'orgEdit'
                              //   } `}
                            />
                          </div>
                        </CardContent>
                      </Card>
                      {totalTime > 0 && (
                        <Card
                          style={{ marginTop: '10px', marginBottom: '10px' }}>
                          <CardContent style={{ padding: '8px' }}>
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="center">
                              <Typography
                                gutterBottom
                                variant="h6" // Changed to variant h6 for a smaller heading
                                style={{ fontSize: '14px' }} // Set font size to 14px for heading
                              >
                                Time Tracking
                              </Typography>
                              {!isDeclineCase && (
                                <div className="jiraSync">
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    sx={{
                                      padding: '4px 8px',
                                      fontSize: '12px',
                                    }}
                                    onClick={() =>
                                      handleLogTimeModalOpen(true)
                                    }>
                                    Log Time
                                  </Button>
                                </div>
                              )}
                            </Box>
                            <Divider sx={{ margin: '8px 0' }} />
                            <Grid
                              container
                              xs={12}
                              spacing={1}
                              alignItems="center">
                              <Grid item xs={4} style={{ fontSize: '12px' }}>
                                Estimated
                              </Grid>
                              <Grid item xs={8}>
                                <Box color="#c22027">
                                  <BorderLinearProgress
                                    style={{ height: '8px' }} // Reduce progress bar height
                                    variant="determinate"
                                    value={(totalTime / totalTime) * 100}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={4} style={{ fontSize: '12px' }}>
                                Remaining
                              </Grid>
                              <Grid item xs={8}>
                                <Box
                                  color="#c22027"
                                  sx={{
                                    transform: 'scaleX(-1)',
                                    justifyContent: 'flex-end',
                                  }}>
                                  <BorderLinearProgress
                                    style={{ height: '8px' }} // Reduce progress bar height
                                    variant="determinate"
                                    value={remainingTimePercent}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={4} style={{ fontSize: '12px' }}>
                                Logged
                              </Grid>
                              <Grid item xs={8}>
                                <Box color="#c22027">
                                  <BorderLinearProgress
                                    style={{ height: '8px' }} // Reduce progress bar height
                                    variant="determinate"
                                    value={loggedTimePercent}
                                  />
                                </Box>
                              </Grid>
                              {loggedTime?.length > 0 && (
                                <>
                                  <Grid item xs={12}>
                                    <Divider />
                                    <Typography
                                      gutterBottom
                                      marginTop="8px"
                                      style={{ fontSize: '14px' }} // Heading font size
                                    >
                                      Breakdown
                                    </Typography>
                                  </Grid>
                                  {loggedTime.map((item) => {
                                    const progress =
                                      (item?.loggedHoursInSeconds / totalTime) *
                                      100;
                                    return (
                                      <React.Fragment key={item?.id}>
                                        <Grid
                                          item
                                          xs={3}
                                          style={{ fontSize: '12px' }}>
                                          {item?.createdBy.name}
                                        </Grid>
                                        <Grid item xs={9}>
                                          <Grid
                                            container
                                            alignItems="center"
                                            spacing={1}>
                                            <Grid item xs={7}>
                                              <BorderLinearProgress
                                                style={{ height: '8px' }}
                                                variant="determinate"
                                                value={progress}
                                              />
                                            </Grid>
                                            <Grid
                                              item
                                              xs={3}
                                              style={{ fontSize: '12px' }}>
                                              {convertSecondsToTimeString(
                                                item?.loggedHoursInSeconds
                                              )}{' '}
                                              / {timeEstimate}
                                            </Grid>
                                            <Grid item xs={2}>
                                              <EditIcon
                                                sx={{
                                                  cursor: 'pointer',
                                                  fontSize: '16px',
                                                  ':hover': {
                                                    color: '#C22027',
                                                  },
                                                }}
                                                onClick={(event) =>
                                                  handleEditLogTimeModalOpen(
                                                    event,
                                                    item
                                                  )
                                                }
                                              />
                                              <DeleteIcon
                                                sx={{
                                                  cursor: 'pointer',
                                                  fontSize: '16px',
                                                  ':hover': {
                                                    color: '#C22027',
                                                  },
                                                }}
                                                onClick={() => {
                                                  handleDeleteLogTimeModalOpen(
                                                    item
                                                  );
                                                }}
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </React.Fragment>
                                    );
                                  })}
                                </>
                              )}
                            </Grid>
                          </CardContent>
                        </Card>
                      )}
                    </Grid>
                  </Grid>

                  <AttachmentViewModal
                    isOpen={isImagePopupOpen}
                    onClose={handleCloseImagePopup}
                    imageUrl={selectedImageUrl}
                  />

                  <LogTimeModal
                    isOpen={logTimeModalOpen}
                    onClose={handleLogTimeModalOpen}
                    billingAccounts={billingAccounts}
                    caseId={caseData?.id}
                    caseKey={caseData?.caseKey}
                    caseSummary={caseData?.summary}
                    loggedTimeEditData={loggedTimeEditData}
                    reloadData={getCaseById}
                    convertSecondsToTimeString={convertSecondsToTimeString}
                  />
                  {isDocumentViewerOpen && (
                    <DocumentViewer
                      documentUrl={selectedDocumentUrl}
                      isOpen={isDocumentViewerOpen}
                      onClose={handleCloseDocumentViewer}
                    />
                  )}
                </form>
              )}
            </Formik>
          </Container>
        </Box>
      }
    </>
  );
}

export default EditCases;
