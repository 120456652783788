import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  //   Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  //   TablePagination,
  TableRow,
  //   Tabs,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Columns, TableProps } from 'src/types/components/tableList.types';
import Scrollbar from '../../Scrollbar';
import BulkDeactivateDialogue from '../Dialog/BulkDeActivate';
import ToggleStatusDialogue from '../Dialog/ToggleStatus';
import SearchBar from '../searchBar/SearchBar';
// import AlertDialog from "../Dialog/ToggleStatusConfimationDialogue";

// const tabs = [
//   {
//     label: 'All',
//     value: 'all',
//   },
//   {
//     label: 'Active',
//     value: 'active',
//   },
//   {
//     label: 'inActive',
//     value: 'inActive',
//   },
// ];

const useStyles = makeStyles<Theme>(() => createStyles({
  btn: {
    width: '47px',
    height: '40px',
    padding: 0,
    minWidth: 'initial',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    color: '#fff',
    marginLeft: '13px',
    '& .MuiButton-iconSizeMedium': {
      margin: '0',
    },
  },
  textFieldSelect: {
    width: '230px',
    marginLeft: '13px',
  },
  textFieldSelectRoot: {
    borderRadius: '4px',
    height: '38px',
  },
  tabButton: {
    width: '181px',
  },
  cardRoot: {
    marginTop: '15px',
    borderRadius: '4px',
  },
  tabs: {
    '& .MuiTab-root': {
      backgroundColor: 'transparent',
      minWidth: '120px',
    },
    '& .Mui-selected': {
      backgroundColor: '#231F20',
      color: '#fff',
    },
  },
  tableHead: {
    '& .MuiTableCell-root': {
      textTransform: 'capitalize',
    },
  },
  tableRow: {
    '& :hover': {
      cursor: 'pointer',
    },
    '& tabelCheckBox': {
      zIndex: '999',
    },
  },
  activeButton: {
    background: 'rgba(7, 83, 0, 0.1)',
    color: 'rgba(29, 122, 21, 1)',
    width: '100px',
  },
  inActiveButton: {
    background: 'rgba(255, 161, 161, 0.3)',
    color: 'red',
    width: '100px',
  },
  table: {
    '&  .MuiTableCell-head': {
      padding: ' 10px 8px'
    }
  }
}));

const TableHeadComponent: FC<any> = ({ allowedColumns }: any) => (
  <TableHead sx={{ marginBottom: '1rem' }}>
    <TableRow>
      <TableCell padding="checkbox" />
      {allowedColumns.map((col: Columns, index) => (
        <TableCell
                // eslint-disable-next-line react/no-array-index-key
          key={index}
          align="center"
          className="textBold capitalize"
        >
          {col.name}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

const TableBodyComponent: FC<any> = ({
  bulkIds,
  data,
  editRouteUrl,
  allowedColumns,
  handleAddBulkIds,
  handleRemoveBulkIds,
  handleStatusToggle,
}: any) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [toggleStatusAlert, setToggleStatusAlert] = useState(false);
  const [clickedItem, setClickedItem] = useState(null);
  const [clickedItemStatus, setClickedItemStatus] = useState(null);

  const handleRedirectNext = () => {
    navigate(`${editRouteUrl}/edit`);
  };

  const checkValue = (value: any, id: number, status: boolean) => {
    if (typeof value === 'boolean') {
      return (
        <Button
          className={value ? classes.activeButton : classes.inActiveButton}
          onClick={(e) => {
            e.stopPropagation();
            setClickedItem(id);
            setClickedItemStatus(status);
            setToggleStatusAlert(true);
          }}
        >
          {value ? 'Active' : 'Inactive'}
        </Button>
      );
    }
    if (value == null) {
      return 'Null';
    }
    return value;
  };

  return data?.length ? (
    <TableBody>
      {data?.map((row) => {
        const rowValue = Object.keys(row);
        return (
          <React.Fragment key={row.id}>

            <TableRow
              hover
              onClick={() => handleRedirectNext()}
              className={classes.tableRow}
            >
              <TableCell
                padding="checkbox"
              >
                <Checkbox
                  checked={bulkIds.includes(row.id)}
                  color="primary"
                  className={classes.tableCheckBox}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      handleAddBulkIds(row.id);
                    } else {
                      handleRemoveBulkIds(row.id);
                    }
                  }}
                />
              </TableCell>
              {allowedColumns.map((column: Columns) => rowValue.includes(column.value) && (
                <TableCell
                  align="center"
                  key={column.name}
                >
                  {checkValue(row[column.value], row.id, row.isActive)}
                </TableCell>
              ))}
            </TableRow>
          </React.Fragment>
        );
      })}
      <TableRow sx={{ display: 'none' }}>
        <TableCell>
          <ToggleStatusDialogue
            isOpen={toggleStatusAlert}
            setToggleStatusAlert={setToggleStatusAlert}
            handleStatusToggle={handleStatusToggle}
            clickedItem={clickedItem}
            clickedItemStatus={clickedItemStatus}
          />
        </TableCell>
      </TableRow>
    </TableBody>
  )
    : (
      <TableBody>
        <TableRow>
          <TableCell
            align="center"
            sx={{ textTransform: 'capitalize', textAlign: 'center' }}
            colSpan={Number(allowedColumns?.length + 1)}
          >
            No data found
          </TableCell>
        </TableRow>
      </TableBody>
    );
};

const ConfigurationOrgListTable: FC<TableProps> = ({
  data,
  //   pageName,
  editRouteUrl,
  allowedColumns,
  //   setIsActivated,
  //   count,
  //   page,
  //   limit,
  //   handleLimit,
  //   handlePage,
  handleBulkDeactivate,
  handleStatusToggle,
}: TableProps) => {
  const classes = useStyles();
  const [deactivateBulkAlert, setDeactivateBulkAlert] = useState(false);
  //   const [value, setValue] = useState('all');
  const [bulkIds, setBulkIds] = useState<Array<number>>([]);
  const handleBulkIds = (_data: any) => {
    setBulkIds(_data);
  };

  //   const handleChangePage = useCallback(
  //     (event: any, _page: number) => {
  //       handlePage(_page);
  //     },
  //     [page, data]
  //   );
  //   const handleChangeLimit = useCallback(
  //     (event: any) => {
  //       handleLimit(parseInt(event.target.value, 10));
  //       handlePage(0);
  //     },
  //     [limit, data]
  //   );
  const handleAddBulkIds = (id: number) => {
    setBulkIds([...bulkIds, id]);
  };
  const handleRemoveBulkIds = (idNum: number) => {
    setBulkIds((current) => current.filter((id) => id !== idNum));
  };

  //   const paginationLocalStorage = {
  //     employees: 'employeePagePg',
  //     contacts: 'contactPagePg',
  //     contactsByProj: 'contactsByProjPagePg',
  //   };
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
      }}
      className={classes.table}
    >
      {' '}
      <Grid container>

        <Grid
          item
          xs={12}
          md={12}
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
          }}
        >
          <SearchBar />
        </Grid>
      </Grid>
      <Card className={classes.cardRoot}>
        <Scrollbar>
          <Box sx={{ minWidth: 700 }}>
            <Table>
              <TableHeadComponent allowedColumns={allowedColumns} />
              <TableBodyComponent
                bulkIds={bulkIds}
                data={data}
                editRouteUrl={editRouteUrl}
                allowedColumns={allowedColumns}
                handleAddBulkIds={handleAddBulkIds}
                handleRemoveBulkIds={handleRemoveBulkIds}
                handleStatusToggle={handleStatusToggle}
              />

            </Table>
          </Box>
        </Scrollbar>
        {/* <TablePagination
              component="div"
              count={count}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeLimit}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[25, 50, 100, 500, 1000]}
            /> */}
      </Card>
      <BulkDeactivateDialogue
        setBulkIds={handleBulkIds}
        bulkIds={bulkIds}
        isOpen={deactivateBulkAlert}
        setDeactivateBulkAlert={setDeactivateBulkAlert}
        handleBulkDeactivate={handleBulkDeactivate}
      />
    </Box>
  );
};

export default ConfigurationOrgListTable;
