import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Tooltip, createStyles, makeStyles } from '@material-ui/core';

import { useNavigate } from 'react-router-dom';
import PriorityComponent from 'src/components/atoms/PriorityComponent';
import useAuth from 'src/hooks/useAuth';

const displayColumns = [
  {
    name: 'Case No',
    value: 'caseKey'
  },
  {
    name: 'Title',
    value: 'summary'
  },
  {
    name: 'Case Type',
    value: 'caseType'
  },
  {
    name: 'Priority',
    value: 'priority'
  },
  {
    name: 'Status',
    value: 'status'
  },
];

const DashboardTable = (props:any) => {
  const { recentCases } = props;
  const navigate = useNavigate();
  const { user } = useAuth();

  const produceStatusColor = (status) => {
    if (status === 'Done') return 'green';
    if (status === 'To Do') return 'red';
    return 'black';
  };

  const useStyles = makeStyles<Theme>(() => createStyles({
    tableContainer: {
      // padding: '0 16px',
      overflowX: 'initial'
    },
    tableHead: {
      textAlign: 'center',
      textWrap: 'nowrap',
      '& .MuiTableCell-root': {
        backgroundColor: '#F4F4F4',
      }
    },
    tableBody: {
    },
    tableCell: {
      border: 'none',
      padding: '2px 0px 2px 16px'
    },
    tableRow: {
      textAlign: 'center',
      border: 'none',
      '&:hover': {
        cursor: 'pointer',
      }
    },
  }));

  const classes = useStyles();
  const displayColumnValue = (recentCase, columnValue) => {
    if (typeof recentCase[columnValue] === 'object') {
      return recentCase[columnValue]?.name || 'N/A';
    }
    if (columnValue === 'summary') {
      return (
        // eslint-disable-next-line no-nested-ternary
        recentCase[columnValue]?.length > 90
          ? (
            <Tooltip
              title={recentCase[columnValue].replace(/<[^>]*>|&nbsp;/g, '')}
              placement="top"
            >
              <div>{`${recentCase[columnValue]?.replace(/<[^>]*>|&nbsp;/g, '').slice(0, 90)} ... `}</div>
            </Tooltip>
          ) : recentCase[columnValue] ? recentCase[columnValue]?.replace(/<[^>]*>|&nbsp;/g, '') : 'N/A'
      );
    }
    return recentCase[columnValue] || 'N/A';
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table
        stickyHeader
      >
        <TableHead className={classes.tableHead}>
          {displayColumns.map((heading) => (
            <TableCell
              align="center"
              // className={classes.tableCell}
              sx={{ fontWeight: 600, padding: '8px 0px 8px 16px' }}
            >
              {heading?.name}
            </TableCell>
          ))}
        </TableHead>
        <TableBody className={classes.tableBody}>
          {recentCases.map((recentCase) => (
            <TableRow
              hover
              className={classes.tableRow}
              onClick={() => { if (user?.permissions?.includes('case-update') || user?.permissions?.includes('case-get-by-id')) { navigate(`/erp/cases/${recentCase?.caseKey}`); } }}
            >
              {displayColumns?.map((column) => Object.keys(recentCase)?.includes(column.value) && (
              <TableCell
                align="center"
                key={column.name}
                // className={classes.tableCell}
                sx={{ color: column.value === 'status' && produceStatusColor(displayColumnValue(recentCase, column.value)),
                  padding: '3px 0px 3px 16px' }}
              >
                  {column.value === 'priority' ? <PriorityComponent label={displayColumnValue(recentCase, column.value)} /> : displayColumnValue(recentCase, column.value)}
                {/* {displayColumnValue(recentCase, column.value)} */}
              </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DashboardTable;
