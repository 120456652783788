import type { Direction, Theme, ThemeOptions } from '@material-ui/core';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import merge from 'lodash/merge';
import { THEMES } from '../constants';
import { lightShadows } from './shadows';

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  roundedCorners?: boolean;
  theme?: string;
}

const baseOptions: ThemeOptions = {
  direction: 'ltr',
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          textAlign: 'left',
          fontSize: '14px', // Adjust font size
          padding: 8
        },
        body: {
          // Add your custom styles for table body cells here
          // For example:
          fontSize: '13px', // Set font size for table body cells
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          // Add your custom styles for table header here
          // For example:
          fontSize: '14px', // Change header background color
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          height: '13px', // Set the height of checkboxes
          padding: '8px', // Adjust the padding around checkboxes
          color: '#231F20;', // Change the color of checkboxes
          borderRadius: '8px',
          '&.Mui-checked': {
            color: '#C22027', // Change the color of checked checkboxes
            borderRadius: '8px',
          },

          '& svg': {
            borderRadius: '8px', // Set the border radius of the SVG icon
          },

        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          // Add your custom styles for the backdrop root here
          // For example:
          backgroundColor: 'transparent', // Set the backdrop color
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          height: '100%',
          width: '100%',
        },
        body: {
          height: '100%',
        },
        '#root': {
          height: '100%',
        },
        '#nprogress .bar': {
          zIndex: '2000 !important',
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h5',
          fontSize: '16px'
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '& .MuiAccordionSummary-root': {
            background: '#231F20',
            color: '#fff',
            borderRadius: '0  0 4px 4px  ',
          },
          '& .MuiAccordionSummary-expandIconWrapper ': {
            color: '#fff'
          },
          '& .MuiAccordionSummary-content.Mui-expanded ': {
            margin: 0
          },
          '& .MuiAccordionSummary-content': {
            margin: 0
          },
          '& .MuiButtonBase-root.Mui-expanded': {
            minHeight: '39px'
          },
          '& .MuiButtonBase-root': {
            minHeight: '39px',
            borderRadius: 4,
          },
          '& .MuiAccordionDetails-root': {
            padding: '16px 16px 16px',
          },
        }

      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiButtonBase-root': {
            minHeight: 'initial',
          },
        },
      }
    },
  },
  typography: {
    button: {
      fontWeight: 600,
    },
    fontFamily: 'Quicksand, sans-serif',

    h1: {
      fontWeight: 600,
      fontSize: '3.5rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '3rem',
    },
    h3: {
      fontWeight: 600,
      fontSize: '2rem',
    },
    h4: {
      fontWeight: 500,
      fontSize: '1.3rem',
    },
    h5: {
      fontWeight: 500,
      fontSize: '0.88rem',
    },
    h6: {
      fontWeight: 600,
      fontSize: '0.7rem',
    },
    overline: {
      fontWeight: 600,
    },
  },
};

const themesOptions: Record<string, ThemeOptions> = {
  [THEMES.LIGHT]: {
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            background: 'white',
          },
          input: {
            '&::placeholder': {
              opacity: 0.86,
              color: '#42526e',
            },
          },
        },
      },
    },
    palette: {
      action: {
        active: '#C22027',
      },
      background: {
        default: '#e5e5e5',
        paper: '#ffffff',
      },
      error: {
        contrastText: '#ffffff',
        main: '#C22027',
      },
      mode: 'light',
      primary: {
        contrastText: '#ffffff',
        main: '#231F20',
      },
      success: {
        contrastText: '#ffffff',
        main: '#C22027',
      },
      text: {
        primary: '#231F20',
        secondary: '#000',
      },
      warning: {
        contrastText: '#ffffff',
        main: '#C22027',
      },
      secondary: {
        contrastText: '#ffffff',
        main: '#C22027',
      },
    },
    shadows: lightShadows,
  },
};

export const createCustomTheme = (config: ThemeConfig = {}): Theme => {
  let themeOptions = themesOptions[config.theme];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.LIGHT];
  }

  let theme = createTheme(
    merge(
      {},
      baseOptions,
      themeOptions,
      {
        ...(config.roundedCorners && {
          shape: {
            borderRadius: 4,
          },
        }),
      },
      {
        direction: config.direction,
      }
    )
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
