import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import CustomListView from 'src/components/customTable/CustomListView';

export const EmployeeGrid = (props: any) => {
  const [groupData, setGroupData] = useState({});
  console.log(props);

  const columns = [
    {
      id: 'name',
      label: 'Employee Name',
    },
    // {
    //   id: 'To Do',
    //   label: 'Assigned Tasks',
    //   isSort: true,
    // },
    {
      id: 'Pending',
      label: 'Pending Tasks',
      isSort: true,
    },
    {
      id: 'In Progress',
      label: 'In-Progress Tasks',
      isSort: true,
    },
    {
      id: 'Done',
      label: 'Completed Tasks',
      isSort: true,
      // Cell: (val , row ) =>  row.Done - (row.Testing + row.TESTING ) || '' ,
      // testing + testing2 + resolved + Done + Esclated = Done
    },
    {
      id: 'totalCount',
      label: 'Total Tasks',
      isSort: true,
      // Cell: (val , row ) =>  row.totalCount - (row.Rejected + row.Canceled || '' ) || '' ,
      // canceled + rejected - total = total
    },
  ];

  useEffect(() => {
    // getEmployeeData();
    setGroupData(props?.data);
  }, [props?.data]);

  return (
    <>
      {/* <CustomListView
        columns={columns}
        data={overAllTasks}
        hidePagination
      /> */}

      {Object.entries(groupData).length
        ? Object.entries(groupData).map(([subDepartment, item]: any[]) => (
            <Accordion>
              <AccordionSummary>{subDepartment}</AccordionSummary>
              <AccordionDetails>
                <CustomListView columns={columns} data={item} hidePagination />
              </AccordionDetails>
            </Accordion>
          ))
        : null}
    </>
  );
};
