/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, Drawer, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
// import MDEditor from '@uiw/react-md-editor';
import { Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { userApi } from 'src/api/userApi';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import * as Yup from 'yup';
import { rolesApi } from 'src/api/rolesApi';

interface CreateUserTypes {
  isOpen: boolean;
  addUser: (isFalse: boolean, e: any) => void;
  closeDrawer: () => void,
  getAllUsers: () => void,
}
const CreateUser: FC<any> = ({
  isOpen,
  addUser,
  closeDrawer,
  getAllUsers,
}: CreateUserTypes) => {
  const useStyles = makeStyles<Theme>(() => createStyles({
    CreateUser: {
      width: '40%',
      padding: '5px 20px ',
      zIndex: 1300,
      border: 'none',
      '& .ticketBody': {
        height: '90vh',
        overflowY: 'auto',
        padding: '0 20px',
        '& input': {
          borderRadius: '2px'
        }
      },
      '& h4': {
        background: '#C22027',
        color: '#fff',
        padding: '0 40px',
        margin: '-5px -20px 30px',
        height: '64px',
        display: 'flex',
        alignItems: 'center'
      },
      '& label': {
        letterSpacing: ' 0.15px',
        color: '#231F20',
        fontWeight: '600',
        paddingBottom: '6px',
        display: 'block'
      },

      '& select, & .MuiAutocomplete-inputRoot, & input': {
        height: '38px',
        padding: '0 10px',
        lineHeight: '116.7%',
        color: '#696969',
        width: '100%',
        '& .MuiAutocomplete-input': {
          padding: 0
        }
      },
      '& .ticketDropDown+.ticketDropDown': {
        marginTop: '15px'
      },
      '& .submitTicket ': {
        margin: '35px 0',
        display: 'flex',
        alignItems: 'center',
      },
      '& .submitTicket button+button': {
        marginLeft: '15px'
      }
    },
    datePicker: {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    ckeditorError: {
      '& .ck-sticky-panel': {
        border: '1px solid #c22027',
        borderBottom: '0px',
      },
      '& .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused)': {
        borderColor: '#c22027',
      }
    }
  }));

  const classes = useStyles();
  const [description, setDescription] = useState<any>('');
  const [roles, setRoles] = useState<any>([]);
  const getAllRoles = async () => {
    try {
      const response = await rolesApi.getAllActiveRoles();
      if (response?.status === 200) {
        setRoles(response?.data);
      } else {
        setRoles([]);
      }
    } catch (error) {
      setRoles([]);
    }
  };

  const Schema = Yup.object({
    name: Yup.string()
      .trim()
      .required('Name required')
      .test(
        'Field must not contain only spaces',
        (value) => value && value.length > 0
      ),
    firstName: Yup.string()
      .trim()
      .required('First name required'),
    lastName: Yup.string()
      .trim()
      .required('Last name required')
      .test(
        'Field must not contain only spaces',
        (value) => value && value.length > 0
      ),
    email: Yup.string().required('Email required').email('Invalid Email'),
    contactNumber: Yup.string()
      .trim()
      .required('Contact number required')
      .matches(/^\d{11,20}$/, 'Contact number must be between 11 and 20 digits'),
    designation: Yup.string()
      .trim()
      .required('Designation required')
      .test(
        'Field must not contain only spaces',
        (value) => value && value.length > 0
      ),
    password: Yup.string()
      .required('Password required')
      .min(8, 'Minimum 8 characters allowed')
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, 'At least one uppercase, one lowercase, one number & one special character allowed'),
    description: Yup.string().required('Description required'),
  });

  useEffect(() => {
    getAllRoles();
  }, []);

  return (
    <div className="createTicket">
      <Drawer
        variant="persistent"
        open={isOpen}
        anchor="right"
        onClose={(e) => addUser(false, e)}
        classes={{ paper: classes.CreateUser }}
      >
        <Typography
          variant="h4"
          component="h4"
        >
          Create User
        </Typography>
        <Formik
          initialValues={{
            name: '',
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            roleId: '',
            designation: '',
            contactNumber: '',
            avatar: '',
            description: '',
          }}
          onSubmit={async (values) => {
            try {
              const payload = {
                name: values?.name,
                firstName: values?.firstName,
                lastName: values?.lastName,
                email: values?.email,
                password: values?.password,
                designation: values?.designation.trim(),
                roleId: values?.roleId,
                avatar: 'dummy_image.png',
                info: description,
                contactNumber: values?.contactNumber,
              };
              const response = await userApi.createUser(payload);
              if (response?.status === 201) {
                toast.success('Created Successfully');
                getAllUsers();
                closeDrawer();
              }
            } catch (error) {
              if (error?.response?.status === 400) {
                if (error?.response?.data?.message) {
                  toast.error(error?.response?.data?.message);
                } else {
                  toast.error('Internal Server Error');
                }
              } else if (error?.response?.status === 401) {
                toast.error('Unauthorized User');
              } else {
                toast.error('Internal Server Error');
              }
            }
          }}
          validationSchema={Schema}
        >
          {({ values, errors, touched, handleSubmit, handleChange, handleBlur, setFieldValue, setFieldTouched }) => (
            <form
              noValidate
              onSubmit={handleSubmit}
            >
              <div className="ticketBody">
                <div className="ticketDropDown">
                  <TextField
                    id="outlined-select-status-native"
                    value={values?.name}
                    variant="outlined"
                    name="name"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Name"
                    error={Boolean(
                      errors.name
                        && touched.name
                    )}
                    helperText={
                      touched.name
                      && errors.name
                    }
                  />
                </div>
                <div className="ticketDropDown">
                  <TextField
                    id="outlined-select-status-native"
                    value={values?.firstName}
                    variant="outlined"
                    name="firstName"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="First Name"
                    error={Boolean(
                      errors.firstName
                        && touched.firstName
                    )}
                    helperText={
                      touched.firstName
                      && errors.firstName
                    }
                  />
                </div>
                <div className="ticketDropDown">
                  <TextField
                    id="outlined-select-status-native"
                    value={values?.lastName}
                    variant="outlined"
                    name="lastName"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Last Name"
                    error={Boolean(
                      errors.lastName
                        && touched.lastName
                    )}
                    helperText={
                      touched.lastName
                      && errors.lastName
                    }
                  />
                </div>
                <div className="ticketDropDown">
                  <TextField
                    id="outlined-select-status-native"
                    value={values?.email}
                    variant="outlined"
                    name="email"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Email"
                    error={Boolean(
                      errors.email
                        && touched.email
                    )}
                    helperText={
                      touched.email
                      && errors.email
                    }
                  />
                </div>
                <div className="ticketDropDown">
                  <TextField
                    id="outlined-select-status-native"
                    value={values?.password}
                    variant="outlined"
                    name="password"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Password"
                    error={Boolean(
                      errors.password
                        && touched.password
                    )}
                    helperText={
                      touched.password
                      && errors.password
                    }
                  />
                </div>
                <div className="ticketDropDown">
                  <TextField
                    id="outlined-select-status-native"
                    value={values?.designation}
                    variant="outlined"
                    name="designation"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Designation"
                    error={Boolean(
                      errors.designation
                        && touched.designation
                    )}
                    helperText={
                      touched.designation
                      && errors.designation
                    }
                  />
                </div>
                <div className="ticketDropDown">
                  <FormControl
                    fullWidth
                    error={Boolean(
                      errors.roleId
                                  && touched.roleId
                    )}
                  >
                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="RoleId"
                      fullWidth
                      label="Role"
                      value={values.roleId}
                      onChange={handleChange}
                      name="roleId"
                    >
                      {
                                  roles?.length > 0
                                    ? roles?.map((arr) => <MenuItem value={arr?.id}>{arr?.name}</MenuItem>)
                                    : <MenuItem value="">No Options</MenuItem>
                                }

                    </Select>
                    <FormHelperText
                      id="RoleId"
                      error={Boolean(
                        errors.roleId
                                  && touched.roleId
                      )}
                    >
                      {
                                  touched.roleId
                                  && errors.roleId
                                }
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="ticketDropDown">
                  <TextField
                    id="outlined-select-status-native"
                    value={values?.contactNumber}
                    variant="outlined"
                    name="contactNumber"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Contact Number"
                    error={Boolean(
                      errors.contactNumber
                        && touched.contactNumber
                    )}
                    helperText={
                      touched.contactNumber
                      && errors.contactNumber
                    }
                  />
                </div>
                <div className="ticketDropDown">
                  <TextField
                    id="outlined-select-status-native"
                    variant="outlined"
                    name="image"
                    type="file"
                    fullWidth
                    disabled
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Upload Image"
                    InputProps={{
                      style: { padding: '10px 0' } // Adjust the padding value as needed
                    }}
                  />
                </div>
                <div className="ticketDropDown">
                  <div
                    data-color-mode="light"
                    className={`${errors?.description && touched?.description ? classes.ckeditorError : null}`}
                  >
                    {/* <MDEditor
                      height={200}
                      value={description}
                      onChange={setDescription}
                      preview="edit"
                      placeholder="Summary"
                      textareaProps={{
                        placeholder: 'Enter User Info Here'
                      }}
                    /> */}
                    <CKEditor
                      editor={ClassicEditor}
                      data={description}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setDescription(data);
                        setFieldValue('description', data);
                      }}
                      onBlur={() => {
                        setFieldTouched('description', true);
                      }}
                      config={
                         { placeholder: 'Enter your Description here',
                           toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'uploadImage']
                         }
                      }
                    />
                    <FormHelperText error={Boolean(touched.description && errors.description)}>
                      {touched.description && errors?.description}
                    </FormHelperText>
                  </div>
                </div>
                <div className="submitTicket">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => closeDrawer()}
                    sx={{ marginLeft: 'auto', height: '38px' }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    sx={{ height: '38px' }}
                  >
                    Create
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </Drawer>
    </div>
  );
};

export default CreateUser;
