import { Box } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { pimApi } from 'src/api/pimApis';
import { SingleLog } from './singleLog';
import parseHTML from 'html-react-parser';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const AuditLogComponent = ({ productId }: { productId: string }) => {
  const [logs, setLogs] = useState([]);
  const getProductLogs = async () => {
    try {
      const response: any = await pimApi.getProductLogsById(productId);
      if (response.status === 200) {
        setLogs(response.data.data);
      }
    } catch (error) {
      setLogs([]);
    }
  };

  useEffect(() => {
    getProductLogs();
  }, []);

  return (
    <Box>
      {logs.length !== 0 ? (
        logs.map((log) => (
          <SingleLog
            key={log.id}
            sx={{
              marginBottom: '10px',
            }}
            meta={{
              description: log.description,
              updatedBy: log.updatedBy.name,
              createdAt: log.createdAt,
              change: log?.change === null ? '' : parseHTML(log?.change || ''),
            }}
            details={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}>
                {log?.changeFrom && (
                  <span style={{ fontSize: '12px' }}>
                    {log?.changeFrom === null
                      ? 'none'
                      : parseHTML(log?.changeFrom)}
                  </span>
                )}
                {log?.changeTo && (
                  <>
                    <span
                      style={{
                        flex: '0 0 40px',
                        textAlign: 'center',
                        margin: '-2px 0 0',
                      }}>
                      <ArrowRightAltIcon />
                    </span>
                    <span style={{ fontSize: '12px' }}>
                      {log?.changeTo === null
                        ? 'none'
                        : parseHTML(log?.changeTo)}
                    </span>
                  </>
                )}
              </div>
            }
          />
        ))
      ) : (
        <Box
          sx={{
            backgroundColor: '#fdf7f7',
            padding: '10px',
            textAlign: 'center',
            borderRadius: '5px',
          }}>
          <b>No Logs Found</b>
        </Box>
      )}
    </Box>
  );
};

export default AuditLogComponent;
