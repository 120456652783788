/* eslint-disable no-underscore-dangle */
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { consultingRoleApi } from 'src/api/consultingRoleApi';
import logger from 'src/utils/logger';
import EditRolePopUp from './EditRolePopUp';
import CustomButton from 'src/components/widgets/buttons/CustomButton';
import toast from 'react-hot-toast';
import RestartAltIcon from '@material-ui/icons/RestartAlt';

const useStyles = makeStyles({
  root: {
    marginTop: '20px',
    width: '50%',
  },
  table: {
    margin: '10px 0',
    backgroundColor: 'white',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: '10px',
  },
  editIcon:{
    display: 'inline-block',
    alignItems: 'center',
    '&:hover':{
      cursor: 'pointer'
    }
  }
});

export const ClientSettingsMain = () => {
  const classes = useStyles();
  const [cuRoles, setCuRoles] = useState([]);
  const [cuRolesCopy, setCuRolesCopy] = useState([]);
  const { organizationID } = useParams();
  const [editRoleData, setEditRoleData] = useState({});
  const [editPopUp, setEditPopUp] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);

  const columns = [
    { name: 'Role', value: 'role' },
    { name: 'Cunit', value: 'cunit' },
  ];

  const handleChangePage = (event: any, _page: number) => {
    setPage(_page);
  };
  const handleChangeLimit = (event: any) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const handleEdit = (roleData: any) => {
    setEditRoleData(roleData);
    setEditPopUp(true);
  };

  const handlePopUpSubmit = (updatedRoleObject: any) => {
    const updatedCuRoleIndex = cuRoles.findIndex(
      (role) => role._id === updatedRoleObject._id
    );
    // taking a clone of the old cuRoles state array with a shallow copy
    const updatedCuRoleState = [...cuRoles];
    // updating the object in the array on the updated index
    updatedCuRoleState[updatedCuRoleIndex] = updatedRoleObject;
    // setting the new state with the updated array
    setCuRoles(updatedCuRoleState);
  };

  const getAllCURoles = async () => {
    try {
      const response = await consultingRoleApi.getAllRoles(
        organizationID,
        page,
        limit
      );
      if (response?.status === 200) {
        setCuRoles(response?.data?.data);
        setCuRolesCopy(response?.data?.data);
        setCount(response?.data?.totalRecords);
      }
    } catch (error) {
      logger(error, 'error');
      setCuRoles([]);
      setCount(0);
    }
  };

  const handleBulkUpdateRoles = async () => {
    // making a new array with new object keys as per the BE requirement temporarily :p
    const payload = cuRoles.map((roleObj) => ({
      orgId: organizationID,
      role: roleObj.role,
      cunit: roleObj.cunit,
    }));
    try {
      const response = await consultingRoleApi.bulkUpdate(payload);
      if (response.status === 201) {
        toast.success(response.data);
        getAllCURoles();
      }
    } catch (error) {
      logger(error, 'error');
    }
  };

  const handleResetTable = () => {
    setCuRoles(cuRolesCopy);
  };

  useEffect(() => {
    getAllCURoles();
  }, [limit, page]);

  return (
    <>
      <Box className={classes.root}>
        <Typography variant="h4" fontWeight="bold">
          Cu Roles
        </Typography>
        <Box className={classes.table}>
          <Table>
            <TableHead>
              {columns.map((column) => (
                <TableCell>{column.name}</TableCell>
              ))}
               <TableCell />
            </TableHead>
            <TableBody>
              {cuRoles.length === 0 ? (
                <TableRow>
                  {' '}
                  <TableCell
                    align="center"
                    sx={{ textTransform: 'capitalize', textAlign: 'center' }}
                    colSpan={Number(columns?.length)}>
                    No data found
                  </TableCell>
                </TableRow>
              ) : (
                cuRoles.map((role) => (
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell>{role[column.value]}</TableCell>
                    ))}
                    <TableCell >
                      <Box onClick={() => handleEdit(role)} className={classes.editIcon}>
                      <img src="/static/icons/editIcon.png" alt="Edit Icons" />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={count}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeLimit}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[25, 50, 100, 500, 1000]}
          />
        </Box>
        <Box className={classes.buttons}>
          <Button style={{height : 30}} onClick={handleResetTable} endIcon={<RestartAltIcon />}>
            Reset Table
          </Button>
          <CustomButton
            value="Update"
            onClick={handleBulkUpdateRoles}
            color="secondary"
          />
        </Box>
      </Box>
      {editPopUp && (
        <EditRolePopUp
          isOpen={editPopUp}
          data={editRoleData}
          setEditPopUp={setEditPopUp}
          handleSubmit={handlePopUpSubmit}
        />
      )}
    </>
  );
};
