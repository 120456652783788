import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const DeclinedCasesStyle = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        pointerEvents: {
            PointerEvents: 'none'
        },
        btn: {
            width: '47px',
            height: '30px',
            padding: 0,
            minWidth: 'initial',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '4px',
            color: '#fff',
            marginLeft: '13px',
            '& .MuiButton-iconSizeMedium': {
                margin: '0',
            },
        },
        textFieldSelect: {
            width: '230px',
            marginLeft: '13px',
        },
        textFieldSelectRoot: {
            borderRadius: '4px',
            height: '38px',
        },
        tabButton: {
            width: '181px',
        },
        cardRoot: {
            borderRadius: '4px',
        },
        tabs: {
            '& .MuiTab-root': {
                backgroundColor: 'transparent',
                minWidth: '120px',
            },
            '& .Mui-selected': {
                backgroundColor: '#231F20',
                color: '#fff',
            },
        },
        tableHead: {
            '& .MuiTableCell-root': {
                textTransform: 'capitalize',
            },
        },
        tableRow: {
            '& :hover': {
                cursor: 'pointer',
            },
        },
        small: {
            width: theme.spacing(3),
            height: theme.spacing(3),
        },
        ticketMain: {
            position: 'relative',
            '& .customCss': {
                padding: 0,
            },
            '& .filterArea': {
                display: 'flex',
                justifyContent: 'flex-end',
                position: 'relative',
                '& .rdrCalendarWrapper': {
                    position: 'absolute',
                    zIndex: '999',
                    right: '0',
                    top: -20,
                },
            },
            '&  .MuiTableCell-head': {
                padding: ' 10px 8px',
            },
            '& .dropDowns': {
                display: 'flex',
                '& .buttonParents': {
                    position: 'relative',
                },
                '&  .buttonParents .dropDownInner': {
                    overflowY: 'auto',
                    position: 'absolute',
                    padding: '13px',
                    height: '220px',
                    width: '350px',
                    background: '#fff',
                    zIndex: '22',
                    boxShadow: ' 0px 0px 8px 3px rgba(0, 0, 0, 0.25)',
                    borderRadius: '2px',
                    top: '100%',
                    left: 0,
                    '& .dropDownInnerRow': {
                        '& span': {
                            fontSize: '12px',
                        },
                        '& p': {
                            margin: '0',
                        },
                        '& .status': {
                            alignItems: 'center',
                        },
                        display: 'flex',
                        alignItems: 'center',
                    },
                },
                '& .buttonParents+.buttonParents': {
                    marginLeft: '10px',
                },
                '& button img': {
                    marginLeft: '20px',
                },
            },
            '& .MuiAutocomplete-root': {
                background: '#fff',
            },
        },
        addButton: {
            height: 40,
            width: '47px',
            borderRadius: '5px',
            minWidth: 'initial',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: '10px',
            '& .MuiButton-startIcon': {
                margin: 0,
            },
        },
    })
);