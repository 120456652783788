/* eslint-disable no-underscore-dangle */
import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { consultingApi } from 'src/api/consultingApi';
// import SearchBar from 'src/components/widgets/searchBar/SearchBar';
import ConsultingUnitsListView from 'src/components/widgets/tables/ConsultingUnitsListView';
import useSettings from 'src/hooks/useSettings';
import setTitle from 'src/utils/setTitle';
import { useParams } from 'react-router';
import CreateFormSideBar from 'src/components/widgets/CreateFormSideBar/CreateFormSideBar';
import ConsultingOrderForm from './ConsultingOrderForm';
// import {
//   organizationDetailData,
//   projectDataByOrg,
// } from 'src/store/slices/organizationDetailsSlice';
// import { useDispatch } from 'react-redux';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    select: {
      height: '30px',
      borderRadius: 4,
      marginRight: '5px'
    },
    btn: {
      height: '30px',
      padding: '0 5px',
      borderRadius: 4,
      color: '#fff',
      '& .MuiButton-iconSizeMedium': {
        margin: '0',
      },
    },
    textFieldSelect: {
      width: '230px',
      marginLeft: '13px',
    },
    textFieldSelectRoot: {
      borderRadius: '4px',
      height: '38px',
    },
    tabButton: {
      width: '181px',
    },
    cardRoot: {
      borderRadius: '4px',
    },
    tabs: {
      '& .MuiTab-root': {
        backgroundColor: 'transparent',
        minWidth: '120px',
      },
      '& .Mui-selected': {
        backgroundColor: '#231F20',
        color: '#fff',
      },
    },
    tableHead: {
      '& .MuiTableCell-root': {
        textTransform: 'capitalize',
      },
    },
    tableRow: {
      '& :hover': {
        cursor: 'pointer',
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    ticketMain: {
      position: 'relative',
      background: 'transparent',
      '& .customCss': {
        padding: 0,
      },
      '& .filterArea': {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'relative',
        '& .rdrCalendarWrapper': {
          position: 'absolute',
          zIndex: '999',
          right: '0',
          top: -20,
        },
      },
      '&  .MuiTableCell-head': {
        padding: ' 10px 8px',
      },
      '& .dropDowns': {
        display: 'flex',
        '& .buttonParents': {
          position: 'relative',
        },
        '&  .buttonParents .dropDownInner': {
          overflowY: 'auto',
          position: 'absolute',
          padding: '13px',
          height: '220px',
          width: '350px',
          background: '#fff',
          zIndex: '22',
          boxShadow: ' 0px 0px 8px 3px rgba(0, 0, 0, 0.25)',
          borderRadius: '2px',
          top: '100%',
          left: 0,
          '& .dropDownInnerRow': {
            '& span': {
              fontSize: '12px',
            },
            '& p': {
              margin: '0',
            },
            '& .status': {
              alignItems: 'center',
            },
            display: 'flex',
            alignItems: 'center',
          },
        },
        '& .buttonParents+.buttonParents': {
          marginLeft: '10px',
        },
        '& button img': {
          marginLeft: '20px',
        },
      },
      '& .MuiAutocomplete-root': {
        background: '#fff',
      },
    },
    addButton: {
      height: 40,
      width: '47px',
      borderRadius: '5px',
      minWidth: 'initial',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: '10px',
      '& .MuiButton-startIcon': {
        margin: 0,
      },
    },
  })
);

function ConsultingUnits(props: any) {
  setTitle('Consulting | Units');
  const { organizationID }: any = useParams();
  const { hiddenColumns, searchQuery, updateReset, updateFlag, triggerUpdate } =
    props;
  const classes = useStyles();
  const { settings } = useSettings();
  const [drawer, setDrawer] = useState<boolean>(false);
  const [allData, setAllData] = useState<any>([]);
  const [rowData, setRowData] = useState({});
  const [isOpenEditOrderDrawer, setIsOpenEditOrderDrawer] = useState(false);
  const [status, setStatus] = useState('');
  const [page, setPage] = useState<any>(
    JSON.parse(localStorage.getItem('consultingViewLogsPg'))?.PageNo || 0
  );
  const [pageSize, setPageSize] = useState<any>(
    JSON.parse(localStorage.getItem('consultingViewLogsPg'))?.PageLimit || 25
  );
  const handlePage = (temp: number) => {
    setPage(temp);
  };
  const [totalCount, setTotalCount] = useState<number>(0);
  const handlePageSize = (temp: number) => {
    setPageSize(temp);
  };
  const handleCloseDrawer = () => {
    setDrawer(!drawer);
  };
  const addCU = useCallback(
    async (open: boolean, event: any) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setDrawer(open);
    },
    [drawer]
  );

  /** sort data */
  type SortDataTypes = {
    id: 'asc' | 'desc';
  };
  const [selectedSort, setSelectedSort] = useState({
    key: JSON.parse(localStorage.getItem('consultingViewLogsPg'))?.key || '',
    value:
      JSON.parse(localStorage.getItem('consultingViewLogsPg'))?.value || '',
  });
  const [sortData, setSortData] = useState<SortDataTypes>({
    id: JSON.parse(localStorage.getItem('consultingViewLogsPg'))?.id || 'asc',
  });
  const toggleSortData = (fieldName: string) => {
    type SortStatusType = 'asc' | 'desc';
    let sortStatus: SortStatusType = 'asc';
    const initial: SortDataTypes = {
      id: 'asc',
    };
    // eslint-disable-next-line default-case
    switch (fieldName) {
      case 'id':
        if (sortData.id === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          id: sortStatus,
        });
        break;
    }
  };

  const handleStatusChange = (changedStatus: string) => {
    setStatus(changedStatus);
  }

  const getAllData = async () => {
    try {
      const fieldToColumnMap = {
        id: '',
      };

      const columnValue = fieldToColumnMap[selectedSort?.key] || 'defaultSort';
      const varr = {
        page,
        pageSize,
        search: searchQuery,
        column: columnValue,
        sort: selectedSort?.value === 'asc' ? '1' : '-1',
        orgId: organizationID,
        eventType: 'cuList',
        cuOrderStatus: status
      };
      if (selectedSort?.key === '' || selectedSort?.value === undefined) {
        delete varr.column;
        delete varr.sort;
      }
      const keys = Object.keys(varr);
      const values = Object.values(varr);

      // Create an array to store the non-empty key-value pairs
      const queryParams = [];
      for (let i = 0; i < values.length; i++) {
        if (values[i] !== '' && values[i] !== null && values[i] !== undefined) {
          queryParams.push(`${encodeURIComponent(keys[i])}=${values[i]}`);
        }
      }
      const queryString = queryParams.join('&');
      // cu-log/findAll?eventType=cuList
      // cu-log/findAll?eventType=cuWorkLog

      let response = await consultingApi.getAllCunsulting(queryString);
      response = response?.data;
      setAllData(response?.data);
      setTotalCount(response?.totalRecords);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setAllData([]);
        setTotalCount(0);
      }
    }
  };

  const columns = [
    { id: 'batchId', label: 'Batch Id', isSort: true, toggleSortData },
    { id: 'orderId', label: 'Order Id', isSort: true, toggleSortData },
    { id: 'project', label: 'Project', isSort: true, toggleSortData },
    { id: 'cuQuantity', label: 'Quantity', isSort: true, toggleSortData },
    { id: 'cuOrderStatus', label: 'Status', isSort: true, toggleSortData },
    { id: 'createdAt', label: 'Created At', isSort: true, toggleSortData },
    { id: 'expDate', label: 'Expiring At', isSort: true, toggleSortData },
    { id: 'auditUser', label: 'Audit User', isSort: true, toggleSortData },
  ];
  // const visibleColumns = ['client.name', 'project.name', 'createdAt', 'cuBatchID'];
  const visibleColumns = [
    'data.cuBatchId',
    'data.orderNumber',
    'data.project',
    'data.cuQuantity',
    'data.cuOrderStatus',
    'data.createdAt',
    'data.createdAt',
    'data.cuAuditUser',
  ];

  const selectOptions = [{name: 'Default', value: ''},{name: 'Active', value: 'ACTIVE'}, {name: 'Closed', value: 'CLOSED'}, {name: 'Pending', value: 'PENDING'} ];
  useEffect(() => {
    getAllData();
    const consultingViewLogsPg = {
      consultingPageLimit: pageSize,
      consultingPageNo: page,
      consultingSearch: searchQuery,
      consultingSortId: sortData?.id,
      consultingSelectedSortKey: selectedSort?.key,
      consultingSelectedSortValue: selectedSort?.value,
    };
    localStorage.setItem(
      'consultingViewLogsPg',
      JSON.stringify(consultingViewLogsPg)
    );
  }, [
    page,
    pageSize,
    searchQuery,
    sortData?.id,
    selectedSort?.key,
    selectedSort?.value,
    updateFlag,
    status
  ]);

  const createOrder = async (values, actions) => {
    try {
      const payload = {
        orgId: organizationID,
        project: values?.project,
        // clientId: values?.client?.id,
        data: {
          orderNumber: values?.orderId,
          cuQuantity: values?.cuQuantity,
          cuOrderStatus: 'PENDING',
        },
      };

      const response = await consultingApi.createUnitOrder(payload);
      if (response?.status === 201) {
        toast.success('Created Successfully');
        localStorage.removeItem('formikValues');
        handleCloseDrawer();
        actions.resetForm();
        triggerUpdate();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleEditOrderDrawer = (drawerState: boolean, row?: any) => {
    setRowData(row);
    setIsOpenEditOrderDrawer(drawerState);
  };
  const handleCloseEditOrderDrawer = () => {
    setIsOpenEditOrderDrawer(false);
  };
  const editOrder = async (values, actions) => {
    try {
      const payload = {
        id: values?.id,
        orgId: organizationID,
        // clientId: values?.client?.id,
        cuList: {
          orderNumber: values?.orderId,
          project: values?.project,
          cuQuantity: values?.cuQuantity,
          cuOrderStatus: values?.cuOrderStatus?.id,
        },
      };

      const response = await consultingApi.editUnitOrder(payload);
      if (response?.status === 200) {
        toast.success('Update Successfully');
        localStorage.removeItem('formikValues');
        handleCloseEditOrderDrawer();
        actions.resetForm();
        triggerUpdate();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const resetTable = () => {
    localStorage.removeItem('consultingViewLogsPg');
    handlePageSize(10);
    handlePage(0);
    setSortData({
      id: 'asc',
    });
    setSelectedSort({ key: '', value: '' });
  };

  useEffect(() => {
    if (updateReset !== undefined) resetTable();
  }, [updateReset]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 4,
        }}
        className={classes.ticketMain}>
        <Container
          maxWidth={settings.compact ? 'xl' : false}
          className={
            hiddenColumns?.customCss === 'customCss' ? 'customCss' : ''
          }>
          <Box sx={{ mt: 1, mb: 1 }}>
            <Grid container alignItems="flex-center" sx={{ mb: 2 }}>
              {hiddenColumns?.heading === false ? (
                ''
              ) : (
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Typography color="textPrimary" variant="h4">
                    Orders
                  </Typography>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}>
                <Select
                  id="status-select"
                  value={status}
                  variant="outlined"
                  className={classes.select}
                  displayEmpty
                  onChange={(e) => handleStatusChange(e.target.value)}
                >
                  {selectOptions.map((option)=>  <MenuItem value={option.value}>{option.name}</MenuItem>)}
                </Select>
                  <Button
                    variant="contained"
                    color="secondary"
                    endIcon={<AddCircleIcon />}
                    className={classes.btn}
                    onClick={(e) => addCU(true, e)}>
                    <span style={{ margin: '0 10px' }}>Create Order</span>
                  </Button>
              </Grid>
            </Grid>
            <ConsultingUnitsListView
              data={allData}
              column={columns}
              sortData={sortData}
              visibleColumns={visibleColumns}
              toggleSortData={toggleSortData}
              selectedSort={selectedSort}
              setSelectedSort={setSelectedSort}
              // redirectUrl={`/erp/crm/organization/details/${organizationID}/consulting`}
              // editRouteUrl={`/erp/crm/organization/details/${organizationID}/consulting`}
              page={page}
              limit={pageSize}
              handleLimit={handlePageSize}
              handlePage={handlePage}
              count={totalCount}
              handleClick={handleEditOrderDrawer}
            />
          </Box>
        </Container>
      </Box>

      {drawer && (
        <>
          <CreateFormSideBar
            title="Create Unit Order"
            closeDrawer={handleCloseDrawer}
            isOpen={drawer}>
            <ConsultingOrderForm
              closeDrawer={handleCloseDrawer}
              onSubmit={createOrder}
            />
          </CreateFormSideBar>
        </>
      )}
      {isOpenEditOrderDrawer && (
        <>
          <CreateFormSideBar
            title="Edit Unit Order"
            closeDrawer={handleCloseEditOrderDrawer}
            isOpen={isOpenEditOrderDrawer}>
            <ConsultingOrderForm
              closeDrawer={handleCloseEditOrderDrawer}
              onSubmit={editOrder}
              formData={rowData}
              editForm
            />
          </CreateFormSideBar>
        </>
      )}
    </>
  );
}

export default ConsultingUnits;
