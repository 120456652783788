/* eslint-disable no-underscore-dangle */
import CustomListView from 'src/components/customTable/CustomListView';
import {
  Container,
  Typography,
  Box,
  Button,
  Dialog,
} from '@material-ui/core';
import setTitle from 'src/utils/setTitle';
import UploadIcon from '@material-ui/icons/Upload';
import { useNavigate } from 'react-router';
import { pimCatalogData } from 'src/constants';
import { useState } from 'react';
import CsvDropzone from './CsvDropzone';

const data = pimCatalogData
const Catalogs = () => {
  const [open, setOpen] = useState(false);
  setTitle('Catalogues | PIM | ERP');
  const navigate = useNavigate();
  const columns = [
    { id: 'name', label: 'Name' }
  ];

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRowClick = (row) => {
    const { id } = row;
    navigate(`${id}`);
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        py: 4,
      }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Typography color="textPrimary" variant="h3">
          Catalogues
        </Typography>

        <Button
          variant="contained"
          color="secondary"
          endIcon={<UploadIcon />}
          // className={classes.btn}
          onClick={handleOpen}
          sx={{ height: 30 }}>
          Upload
        </Button>

      </Box>
      <CustomListView
        onRowClick={handleRowClick}
        columns={columns}
        data={data || []}
        hidePagination
      />
      {open && (
        <Dialog onClose={handleClose} open={open} fullWidth>
          <Box style={{ padding: '0px 20px 20px 20px' }}>
            <p style={{ fontWeight: '700', fontSize: '20px' }}>Upload File</p>

            <CsvDropzone closeDialog={handleClose} />
          </Box>
        </Dialog>
      )}
    </Container>

  );
};

export default Catalogs;
