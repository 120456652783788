import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
// import DeleteIcon from '@material-ui/icons/Delete';
import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useOutletContext, useParams } from 'react-router-dom';
import { casesApi } from 'src/api/casesApi';
import AdvancedSearchModal from 'src/components/widgets/modal/AdvancedSearchModal';
import SearchBar from 'src/components/widgets/searchBar/SearchBar';
import CaseListView from 'src/components/widgets/tables/CaseListView';
import { TableProps } from 'src/types/components/tableList.types';
import RestartAltIcon from '@material-ui/icons/RestartAlt';
import useAuth from 'src/hooks/useAuth';

const useStyles = makeStyles<Theme>(() => createStyles({
  btn: {
    width: '30px',
    height: '24px',
    padding: 0,
    minWidth: 'initial',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    color: '#fff',
    marginLeft: '8px',
    marginBottom: '7px',
    '& .MuiButton-iconSizeMedium': {
      margin: '0',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '16px'
    },
    '&:hover': {
      cursor: 'pointer'
    },
  },
  activeButton: {
    background: 'rgba(7, 83, 0, 0.1)',
    color: 'rgba(29, 122, 21, 1)',
    width: '100px',
  },
  inActiveButton: {
    background: 'rgba(255, 161, 161, 0.3)',
    color: 'red',
    width: '100px',
  },
  table: {
    '& .MuiTableCell-head': {
      padding: ' 10px 8px'
    }
  },
  breadCrumbsContainer: {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
  },
  tabsAreaProject: {
    margin: '16px 10px 0',
    '& .MuiTabs-flexContainer': {
      borderBottom: '1px solid #231F20',
    },
    '& .tabsArea': {
      margin: '16px 0 0',
      '& .MuiTab-root': {
        height: '32px',
        minHeight: 'inherit',
        margin: '0',
        minWidth: '105px',
      },
      '& hr': {
        border: 0,
        borderTop: '0.8px solid #E0E0E0',
        margin: '21px 0 0',
        borderWidth: '0.8px',
      },
      '& .MuiTabs-indicator ': {
        display: 'none'
      },
      "& .Mui-selected[aria-selected='true']": {
        background: '#FFF;',
        color: '#231F20',
        border: '1px solid #231F20',
        borderBottom: '1px solid #fff',
        position: 'relative',
        top: 1
      }
    },
  },
}));

interface CasesContext {
  addCases?: (flag: boolean, event: React.MouseEvent<HTMLButtonElement>) => void;
}

const CasesViewByProjects: FC<TableProps> = () => {
  const classes = useStyles();
  const { organizationID, projectID } = useParams();
  const { user } = useAuth();
  const baseUrl = '/erp/crm/organization/details';
  const [casesByProj, setCasesByProj] = useState<any>([]);
  // const { addCases }: any = useOutletContext();
  const isRenderComponent = useSelector((state: any) => state.orgInfo?.reRenderComponent);
  const [autoRefresh, setAutoRefresh] = useState<number>(0);
  const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);
  const toggleAdvancedSearch = () => {
    setShowAdvancedSearch(!showAdvancedSearch);
  };


  const context = useOutletContext<CasesContext>();

  // Ensure context and addCases are defined
  const addCases = context?.addCases;

  const handleAddCases = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (addCases) {
      addCases(true, e); // Call addCases with a boolean and the event
    } else {
      console.warn('addCases function is not available');
    }
  };
  const [bulkIds, setBulkIds] = useState<String>('');
  const handleAddBulkIds = (id: String) => {
    setBulkIds(id);
  };
  const handleRemoveBulkIds = (id: String) => {
    setBulkIds(id);
  };

  const [page, setPage] = useState<any>(JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesByProjPageNo || 0);
  const [pageSize, setPageSize] = useState<any>(JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesByProjPageLimit || 25);
  const [search, setSearch] = useState<string>(JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesByProjSearch || '');
  const [totalCount, setTotalCount] = useState<number>(0);
  const [newTicketIds, setNewTicketIds] = useState<any>([]);
  const handleSearch = (val) => {
    setSearch(val);
  };

  /** sort data */
  type SortDataTypes = {
    id: 'asc' | 'desc',
    caseName: 'asc' | 'desc',
    organization: 'asc' | 'desc',
    createdBy: 'asc' | 'desc',
    assignee: 'asc' | 'desc',
    caseType: 'asc' | 'desc',
    project: 'asc' | 'desc',
    resolveTime: 'asc' | 'desc';
    firstResponseTime: 'asc' | 'desc';
    priority: 'asc' | 'desc';
    status: 'asc' | 'desc',

  };
  const [selectedSort, setSelectedSort] = useState({
    key: JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesSelectedSortKey || '',
    value: JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesSelectedSortValue || '',
  });
  const [sortData, setSortData] = useState<SortDataTypes>({
    id: JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesSortId || 'asc',
    caseName: JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesSortCaseName || 'asc',
    organization: JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesSortOrganization || 'asc',
    createdBy: JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesSortCreatedBy || 'asc',
    assignee: JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesSortAssignee || 'asc',
    caseType: JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesSortCaseType || 'asc',
    project: JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesSortProject || 'asc',
    resolveTime: JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesSortResolveTime || 'asc',
    firstResponseTime: JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesSortFirstResponseTime || 'asc',
    priority: JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesSortPriority || 'asc',
    status: JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesSortStatus || 'asc',
  });
  const toggleSortData = (fieldName: string) => {
    type SortStatusType = 'asc' | 'desc';
    let sortStatus: SortStatusType = 'asc';
    const initial: SortDataTypes = {
      id: 'asc',
      caseName: 'asc',
      organization: 'asc',
      createdBy: 'asc',
      assignee: 'asc',
      caseType: 'asc',
      project: 'asc',
      resolveTime: 'asc',
      firstResponseTime: 'asc',
      priority: 'asc',
      status: 'asc',
    };
    // eslint-disable-next-line default-case
    switch (fieldName) {
      case 'id':
        if (sortData.id === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          id: sortStatus,
        });
        break;
      case 'title':
        if (sortData.caseName === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          caseName: sortStatus,
        });
        break;
      case 'organization':
        if (sortData.organization === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          organization: sortStatus,
        });
        break;
      case 'reporter':
        if (sortData.createdBy === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          createdBy: sortStatus,
        });
        break;
      case 'assignee':
        if (sortData.assignee === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          assignee: sortStatus,
        });
        break;
      case 'type':
        if (sortData.caseType === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          caseType: sortStatus,
        });
        break;
      case 'project':
        if (sortData.project === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          project: sortStatus,
        });
        break;
      case 'resolveTime':
        if (sortData.resolveTime === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          resolveTime: sortStatus,
        });
        break;
      case 'firstResponseTime':
        if (sortData.firstResponseTime === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          firstResponseTime: sortStatus,
        });
        break;
      case 'priority':
        if (sortData.priority === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          priority: sortStatus,
        });
        break;
      case 'status':
        if (sortData.status === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          status: sortStatus,
        });
        break;
    }
  };
  /** advance filters */
  const [date, setDate] = useState(JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesDate || {
    to: null,
    from: null
  });
  const [caseType, setCaseType] = useState<any>(JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesCaseType || []);
  const [priority, setPriority] = useState<any>(JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesPriority || []);
  const [stakeholder, setStakeholder] = useState<any>(JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesStakeholder || []);
  const [assignee, setAssignee] = useState<any>(JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesAssignee || []);
  const [projectSponser, setProjectSponser] = useState<any>(JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesProjectSponser || []);
  const [escalationManager, setEscalationManager] = useState<any>(JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesEscalationManager || []);
  const [status, setStatus] = useState<any>(JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesStatus || []);
  const [hasAttachments, setHasAttachments] = useState<boolean>(JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesHasAttachments || false);
  const [isEscalated, setIsEscalated] = useState<boolean>(JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesIsEscalated || false);
  const [isFirstResponseTimeViolated, setIsFirstResponseTimeViolated] = useState<boolean>(JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesIsFirstResponseTimeViolated || false);
  const [isSynced, setIsSynced] = useState<boolean>(JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesIsSynced || false);
  const [isResolveTimeViolated, setIsResolveTimeViolated] = useState<boolean>(JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesIsResolvedTimeViolated || false);

  const getCasesByProject = async () => {
    try {
      const fieldToColumnMap = {
        id: 'caseKeyTruncate',
        title: 'summary',
        organization: 'organization.name',
        reporter: 'createdBy.name',
        assignee: 'assignee.name',
        type: 'caseType.name',
        resolveTime: 'sla.actualTime.resolveTime',
        firstResponseTime: 'sla.actualTime.firstResponseTime',
        priority: 'priority.rank',
        status: 'status.rank',
        project: 'project.name',
      };

      const columnValue = fieldToColumnMap[selectedSort?.key] || 'defaultSort';
      const varr = {
        page,
        pageSize,
        search,
        caseType: caseType?.map((itm) => itm.id).toString(),
        priority: priority?.map((itm) => itm.id).toString(),
        stakeholder: stakeholder?.map((itm) => itm.id).toString(),
        assignee: assignee?.map((itm) => itm.id).toString(),
        projectSponser: projectSponser?.map((itm) => itm.id).toString(),
        escalationManager: escalationManager?.map((itm) => itm.id).toString(),
        status: status?.map((itm) => itm.id).toString(),
        hasAttachments,
        isEscalated,
        isFirstResponseTimeViolated,
        isSynced,
        isResolveTimeViolated,
        to: date?.to,
        from: date?.from,
        projectId: projectID,
        column: columnValue,
        sort: selectedSort?.value === 'asc' ? '1' : '-1'
      };
      if (selectedSort?.key === '' || selectedSort?.value === undefined) {
        delete varr.column;
        delete varr.sort;
      }
      const keys = Object.keys(varr);
      const values = Object.values(varr);

      // Create an array to store the non-empty key-value pairs
      const queryParams = [];
      for (let i = 0; i < values.length; i++) {
        if (values[i] !== '' && values[i] !== null && values[i] !== undefined) {
          queryParams.push(`${encodeURIComponent(keys[i])}=${values[i]}`);
        }
      }
      const queryString = queryParams.join('&');
      let response = await casesApi.advanceSearch(queryString);
      response = response?.data;
      const existingTicketIds = casesByProj?.map((ticket) => ticket.id);
      // Compare with existing data to find newly added tickets
      const newTickets = response?.data?.filter((ticket) => !existingTicketIds.includes(ticket.id));

      if (newTickets.length > 0 && autoRefresh !== 0) {
        setNewTicketIds(newTickets.map((ticket) => ticket.id));
      }
      setCasesByProj(response?.data);
      setTotalCount(response?.totalRecords);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setCasesByProj([]);
        setTotalCount(0);
      }
    }
  };

  // Commented code because cases cannot be deleted for now

  // const deleteCaseById = async (id:String) => {
  //   try {
  //     const res = await casesApi.deleteCase(id);
  //     if (res?.status === 200) {
  //       toast.success('Case deleted successfully');
  //       setBulkIds('');
  //       getCasesByProject();
  //     }
  //   } catch (error) {
  //     toast.error(error);
  //   }
  // };

  const handleAutoRefresh = (event) => {
    const newDuration = parseInt(event.target.value, 10);
    setAutoRefresh(newDuration);
  };

  const permissions = {
    getAll: user?.permissions?.includes('organization-case-get-all'),
    filterTabs: user?.permissions?.includes('organization-case-filter-tabs-read'),
    create: user?.permissions?.includes('organization-case-create'),
    toggleStatus: user?.permissions?.includes('organization-case-toggle-status'),
    delete: user?.permissions?.includes('organization-case-delete'),
    bulkDeactivate: user?.permissions?.includes('organization-case-bulk-deactivate'),
    getById: user?.permissions?.includes('organization-case-get-by-id'),
    update: user?.permissions?.includes('organization-case-update'),
  };

  const setLocalStorage = () => {
    const casesByProjPagePg = {
      casesByProjPageLimit: pageSize,
      casesByProjPageNo: page,
      casesByProjSearch: search,
      casesSortId: sortData?.id,
      casesSortCaseName: sortData?.caseName,
      casesSortOrganization: sortData?.organization,
      casesSortCreatedBy: sortData?.createdBy,
      casesSortAssignee: sortData?.assignee,
      casesSortCaseType: sortData?.caseType,
      casesSortProject: sortData?.project,
      casesSortResolveTime: sortData?.resolveTime,
      casesSortFirstResponseTime: sortData?.firstResponseTime,
      casesSortPriority: sortData?.priority,
      casesSortStatus: sortData?.status,
      casesCaseType: caseType,
      casesPriority: priority,
      casesStakeholder: stakeholder,
      casesAssignee: assignee,
      casesProjectSponser: projectSponser,
      casesEscalationManager: escalationManager,
      casesStatus: status,
      casesHasAttachments: hasAttachments,
      casesIsEscalated: isEscalated,
      casesIsFirstResponseTimeViolated: isFirstResponseTimeViolated,
      casesIsSynced: isSynced,
      casesIsResolvedTimeViolated: isResolveTimeViolated,
      casesDate: date,
      casesSelectedSortKey: selectedSort?.key,
      casesSelectedSortValue: selectedSort?.value,
    };
    localStorage.setItem('casesByProjPagePg', JSON.stringify(casesByProjPagePg));
  };
  useEffect(() => {
    getCasesByProject();
    setLocalStorage();
    let autoRefreshTime;
    if (autoRefresh !== 0) {
      autoRefreshTime = setInterval(() => {
        getCasesByProject();
      }, autoRefresh * 1000);
    }

    return () => {
      clearTimeout(autoRefreshTime);
    };
  }, [
    isRenderComponent,
    page,
    pageSize,
    search,
    JSON.stringify(caseType),
    JSON.stringify(priority),
    JSON.stringify(stakeholder),
    JSON.stringify(assignee),
    JSON.stringify(projectSponser),
    JSON.stringify(escalationManager),
    JSON.stringify(status),
    // status?.id,
    hasAttachments,
    isEscalated,
    isFirstResponseTimeViolated,
    isSynced,
    isResolveTimeViolated,
    date?.from,
    date?.to,
    autoRefresh,
    sortData?.id,
    sortData?.caseName,
    sortData?.organization,
    sortData?.createdBy,
    sortData?.assignee,
    sortData?.caseType,
    sortData?.project,
    sortData?.resolveTime,
    sortData?.firstResponseTime,
    sortData?.priority,
    sortData?.status,
    selectedSort?.key,
    selectedSort?.value,
  ]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
      }}
      className={classes.table}
    >

      <Grid
        container
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          {user?.permissions?.includes('organization-case-filter-search-read')
            && (
              <SearchBar
                margins="0 0 8px"
                onSearch={handleSearch}
                value={search}
                anyState={autoRefresh !== 0}
              />
            )}
          {user?.permissions?.includes('organization-case-filter-advanced-search-read')
            && (
              <Button
                variant="contained"
                color="secondary"
                sx={{ height: 30, minWidth: '160px', marginLeft: 1, fontWeight: 'normal', marginBottom: '8px' }}
                onClick={toggleAdvancedSearch}
                disabled={autoRefresh !== 0}
              >
                Advanced Search
              </Button>
            )}
          {user?.permissions?.includes('organization-case-filter-autorefresh-read') && (
            <Select
              value={autoRefresh}
              onChange={handleAutoRefresh}
              sx={{ height: 30, marginLeft: 1, fontWeight: 'normal', marginBottom: '8px' }}
            >
              <MenuItem
                selected
                value={0}
              >
                Refresh
              </MenuItem>
              <MenuItem value={30}>30 sec</MenuItem>
              <MenuItem value={60}>1 min</MenuItem>
              <MenuItem value={300}>5 mins</MenuItem>
              <MenuItem value={900}>15 mins</MenuItem>
            </Select>
          )}

          {(showAdvancedSearch && user?.permissions?.includes('organization-case-filter-advanced-search-read')) && (
            <AdvancedSearchModal
              _headingMain="Advanced Search"
              _setShowAdvancedSearch={setShowAdvancedSearch}
              _handleSubmit={() => null}
              _search={search}
              _setSearch={handleSearch}
              _caseType={caseType}
              _setCaseType={setCaseType}
              _priority={priority}
              _setPriority={setPriority}
              _stakeholder={stakeholder}
              _setStakeholder={setStakeholder}
              _assignee={assignee}
              _setAssignee={setAssignee}
              _projectSponser={projectSponser}
              _setProjectSponser={setProjectSponser}
              _escalationManager={escalationManager}
              _setEscalationManager={setEscalationManager}
              _status={status}
              _setStatus={setStatus}
              _hasAttachments={hasAttachments}
              _setHasAttachments={setHasAttachments}
              _isEscalated={isEscalated}
              _setIsEscalated={setIsEscalated}
              _isFirstResponseTimeViolated={isFirstResponseTimeViolated}
              _setIsFirstResponseTimeViolated={setIsFirstResponseTimeViolated}
              _isSynced={isSynced}
              _setIsSynced={setIsSynced}
              _isResolveTimeViolated={isResolveTimeViolated}
              _setIsResolveTimeViolated={setIsResolveTimeViolated}
              _date={date}
              _setDate={setDate}
              _setPage={setPage}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {user?.permissions?.includes('organization-case-create') && (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddCircleIcon />}
              className={classes.btn}
              onClick={handleAddCases}

            />
          )}

          <Button
            variant="contained"
            color="secondary"
            startIcon={<RestartAltIcon />}
            className={classes.btn}
            onClick={() => {
              localStorage.removeItem('casesByProjPagePg');
              setPageSize(10);
              setPage(0);
              handleSearch('');
              setSortData({
                id: 'asc',
                caseName: 'asc',
                organization: 'asc',
                createdBy: 'asc',
                assignee: 'asc',
                caseType: 'asc',
                project: 'asc',
                resolveTime: 'asc',
                firstResponseTime: 'asc',
                priority: 'asc',
                status: 'asc',
              });

              setCaseType([]);
              setPriority([]);
              setStakeholder([]);
              setAssignee([]);
              setProjectSponser([]);
              setEscalationManager([]);
              setStatus([]);
              setHasAttachments(false);
              setIsEscalated(false);
              setIsFirstResponseTimeViolated(false);
              setIsSynced(false);
              setIsResolveTimeViolated(false);
              setDate({ to: null, from: null });
              setSelectedSort({ key: '', value: '' });
            }}
          />
          {/* {user?.permissions?.includes('organization-case-delete')
                && (
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<DeleteIcon />}
                    className={classes.btn}
                    disabled={!bulkIds.length}
                    onClick={() => deleteCaseById(bulkIds)}
                    style={{ display: 'none' }}
                  />

                )} */}

        </Grid>
      </Grid>
      {
        user?.permissions?.includes('organization-case-get-all') && (
          <CaseListView
            data={casesByProj}
            sortData={sortData}
            selectedSort={selectedSort}
            toggleSortData={toggleSortData}
            setSelectedSort={setSelectedSort}
            handlePage={setPage}
            handleLimit={setPageSize}
            count={totalCount}
            page={page}
            limit={pageSize}
            bulkIds={bulkIds}
            handleAddBulkIds={handleAddBulkIds}
            handleRemoveBulkIds={handleRemoveBulkIds}
            redirectUrl={baseUrl}
            editRouteUrl={`${baseUrl}/${organizationID}/project/${projectID}/case`}
            permissions={permissions}
            isProject={false}
            isOrganization={false}
            newTicketIds={newTicketIds}
          />
        )
      }

    </Box>
  );
};

export default CasesViewByProjects;
