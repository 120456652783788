import { Box, Button, Checkbox, FormControlLabel, InputLabel, TextField, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import SimpleBreadCrumb from 'src/components/widgets/BreadCrumb/SimpleBreadCrumb';

const useStyles = makeStyles<Theme>(() => createStyles({
  paper: {
    backgroundColor: '#fff',
    padding: '10px 80px 30px 80px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
  },
  fieldsRoot: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '238px',
    gridRowGap: '16px',

    '& .item': {
      '& .MuiInputLabel-root': {
        fontSize: '14px',
        fontWeight: 600,
        color: '#231f20',
      },
      '& .Mui-disabled': {
        backgroundColor: '#F3F3F3',
        cursor: 'not-allowed',
      }
    },
    '& .itemDescription': {
      gridColumn: '1/3',
    }
  },
  checkboxRoot: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    rowGap: '16px',

    '& .MuiFormControlLabel-root': {
      minWidth: '240px',
    },
    '& .MuiTypography-body1': {
      fontSize: '14px',
      fontWeight: 400,
      color: '231f20',
    },
    '& .MuiSvgIcon-root': {
      width: '16px',
      height: '16px',
    },
    '& .flexGrow': {
      flexGrow: 1,
    }
  },
  btnRoot: {
    display: 'flex',
    marginTop: '30px',
    gap: '9px',
    // flexDirection: 'row-reverse',
  }
}));
const ConfigurationEdit = () => {
  const baseUrl = '/erp/settings';
  const classes = useStyles();
  const BreadCrumbsData = {
    title: '',
    links: [

      {
        name: 'configuration',
        to: `${baseUrl}/configuration`
      },
      {
        name: 'edit configuration',
        to: ''
      }
    ]
  };
  return (
    <>
      <SimpleBreadCrumb data={BreadCrumbsData} />
      <Typography
        variant="h3"
        style={{ marginTop: '16px', marginBottom: '10px', fontSize: '20px', fontWeight: '700', color: '#000', }}
      >
        Edit Configuration
      </Typography>
      <Box className={`${classes.paper} ${classes.fieldsRoot}`}>
        <Box className="item">
          <InputLabel htmlFor="identifier-simple">Identifier</InputLabel>
          <TextField
            id="identifier-simple"
            fullWidth
            placeholder="Identifier"
            size="small"
            disabled
          />
        </Box>
        <Box className="item">
          <InputLabel htmlFor="permission-name-simple">Permission Name</InputLabel>
          <TextField
            id="permission-name-simple"
            fullWidth
            placeholder="Permission Name"
            size="small"
          />
        </Box>
        <Box className="item">
          <InputLabel htmlFor="module-simple">Module</InputLabel>
          <TextField
            id="module-simple"
            fullWidth
            placeholder="Module"
            size="small"
          />
        </Box>
        <Box className="item">
          <InputLabel htmlFor="sub-module-simple">Sub-Module</InputLabel>
          <TextField
            id="sub-module-simple"
            fullWidth
            placeholder="Sub-Module"
            size="small"
          />
        </Box>
        <Box className="item itemDescription">
          <InputLabel htmlFor="description-simple">Description</InputLabel>
          <TextField
            id="description-simple"
            fullWidth
            multiline
            rows={4}
            placeholder="Description"
            size="small"
            type="text"
          />
        </Box>
      </Box>
      <Typography
        variant="h3"
        style={{ marginTop: '16px', marginBottom: '10px', fontSize: '20px', fontWeight: '700', color: '#000', }}
      >
        Permissions
      </Typography>
      <Box
        className={`${classes.paper}`}
        style={{ paddingBottom: '10px', }}
      >
        <Box className={classes.checkboxRoot}>
          <FormControlLabel
            control={(
              <Checkbox
        //   checked={false}
        //   onChange={() => alert('form-handle-change-called')}
                name="checkedC"
              />
        )}
            label="Read"
          />
          <FormControlLabel
            control={<Checkbox name="checkedC" />}
            label="Create"
          />
          <FormControlLabel
            control={<Checkbox name="checkedC" />}
            label="Update"
          />
          <FormControlLabel
            control={<Checkbox name="checkedC" />}
            label="Delete"
          />
        </Box>
        <Box
          className={classes.checkboxRoot}
          mt={2}
        >
          <FormControlLabel
            control={<Checkbox name="checkedC" />}
            label="ToggleStatus"
          />
          <FormControlLabel
            control={<Checkbox name="checkedC" />}
            label="Get All"
          />
          <FormControlLabel
            control={<Checkbox name="checkedC" />}
            label="Get By Id"
          />
          <FormControlLabel
            control={<Checkbox name="checkedC" />}
            label="Bulk De-activate"
          />
        </Box>
      </Box>
      <Box className={classes.btnRoot}>
        <Link
          to={`${baseUrl}/permissions`}
          style={{ marginLeft: 'auto', }}
        >
          <Button
            variant="outlined"
            color="primary"
            sx={{ height: '38px' }}
          >
            Cancel
          </Button>
        </Link>
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          sx={{ height: '38px' }}
        >
          Save
        </Button>
      </Box>
    </>
  );
};

export default ConfigurationEdit;
