import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Container } from '@material-ui/core';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';
import { okrApi } from 'src/api/okrApi';


const useStyles = makeStyles<Theme>(
  () =>
    createStyles({
      wrapper: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #dadada',
        padding: '10px 0',
        margin: '10px 0',
        width: '49%',
    },
      name: {
        width: '50%',
        fontSize: 14,
        fontWeight: 600,
      },
      progressWrapper: {
        width: '100%',
        height: 20,
        background: '#cfcfcf',
        // borderRadius: 5,
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '50px',
      },
      progressBar: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '50%',
        borderRadius: '0 50px 50px 0',
        height: '100%',
        '& span': {
          fontSize: 14,
          color: '#fff',
          fontWeight: 500,
          position: 'absolute',
          right: 5,
        },
      },
      checkColor: {
        width: 100,
        height: 30,
        display: 'flex',
        '& span': {
          height: '100%',
          width: '100%',
        }
      },
    }),
  {
    name: 'Progress',
  }
);
function Progress( ) {
  const classes = useStyles();
  const [employeeData, setEmployeeData] = useState([]);
  const getEmployeeData = async () => {
    try {
      const response = await okrApi.getEmployeeSummary();
      setEmployeeData(response.data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getEmployeeData();
  }, []);


  const blendColors = (color1, color2, ratio) => {
    const hex = (x) => {
      x = x.toString(16);
      return x.length === 1 ? `0${  x}` : x;
    };

    const r = Math.round(parseInt(color1.substring(1, 3), 16) * ratio + parseInt(color2.substring(1, 3), 16) * (1 - ratio));
    const g = Math.round(parseInt(color1.substring(3, 5), 16) * ratio + parseInt(color2.substring(3, 5), 16) * (1 - ratio));
    const b = Math.round(parseInt(color1.substring(5, 7), 16) * ratio + parseInt(color2.substring(5, 7), 16) * (1 - ratio));

    return `#${  hex(r)  }${hex(g)  }${hex(b)}`;
  };

  const getColor = (percentage) => {
    if (percentage < 40) {
      return '#ea4335'; // red
    } if (percentage < 60) {
      const ratio = (percentage - 40) / 20; // Calculate the ratio between red and yellow
      const blendedColor = blendColors('#fbbc04' ,'#ea4335' , ratio); // between red and yellow
      return blendedColor;
    }
    // if (percentage < 80) {
    //   const ratio = (percentage - 60) / 20; // Calculate the ratio between red and yellow
    //   const blendedColor = blendColors('#34a853' ,'#fbbc04' , ratio); // between red and yellow
    //   return  blendedColor  ; // green
    // }
    return '#34a853'
  }

  const ProgressBar = (data) => {
    const {Done , totalCount} = data;
    const calculateProgress = (Done / totalCount) * 100 || 0;
    return (
        <div className={classes.progressWrapper} style={{  background:`${getColor(calculateProgress)}2b` }}>
          <div className={classes.progressBar} style={{width: `${calculateProgress}%`, background:`${getColor(calculateProgress)}` }}>
            <span>{`${calculateProgress.toFixed()}%`}</span>
          </div>
        </div>
    )
  }
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 4,
      }}>
      <Container maxWidth="xl">
        <Box sx={{
            mt: 1,
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
        }} >

          {/* check color here */}

          <div className={classes.checkColor}>
            {Array.from({ length: 101 }).map((item ,index ) => (
              <span style={{ background:`${getColor(index)}` }}/>
            ))}
          </div>
        {employeeData && employeeData.map((item) => (
          <div className={classes.wrapper}>
            <div className={classes.name}>
              {item.name}
            </div>
            {ProgressBar(item)}
          </div>
          ))}
        </Box>
      </Container>
    </Box>
  );
}

export default Progress;
