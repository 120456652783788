import type { FC } from 'react';
import PropTypes from 'prop-types';
import { experimentalStyled } from '@material-ui/core/styles';

interface CircularProgressProps {
  value: number;
}

const CircularProgressRoot = experimentalStyled('div')({
  // height: 56,
  width: '100%',
});

const CircularProgressBackground = experimentalStyled('path')(() => ({
  fill: 'none',
  strokeWidth: 4,
}));

const CircularProgressValue = experimentalStyled('path')(() => ({
  animation: 'progress 0.4s ease-out forwards',
  fill: 'none',
  // stroke: theme.palette.primary.main,
  strokeWidth: 4,
  '@keyframes progress': {
    '0%': {
      strokeDasharray: '0 100',
    },
  },
}));

const Text = experimentalStyled('text')({
  dominantBaseline: 'middle',
  textAnchor: 'middle',
  fontSize: 9,
  fontWeight: 'bold',
});

const CircularProgress: FC<CircularProgressProps> = (props) => {
  const { value, ...other } = props;


  const blendColors = (color1, color2, ratio) => {
    const hex = (x) => {
      x = x.toString(16);
      return x.length === 1 ? `0${  x}` : x;
    };

    const r = Math.round(parseInt(color1.substring(1, 3), 16) * ratio + parseInt(color2.substring(1, 3), 16) * (1 - ratio));
    const g = Math.round(parseInt(color1.substring(3, 5), 16) * ratio + parseInt(color2.substring(3, 5), 16) * (1 - ratio));
    const b = Math.round(parseInt(color1.substring(5, 7), 16) * ratio + parseInt(color2.substring(5, 7), 16) * (1 - ratio));

    return `#${  hex(r)  }${hex(g)  }${hex(b)}`;
  };

  const getColor = (percentage) => {
    if (percentage < 40) {
      return '#ea4335'; // red
    } if (percentage < 60) {
      const ratio = (percentage - 40) / 20; // Calculate the ratio between red and yellow
      const blendedColor = blendColors('#fbbc04' ,'#ea4335' , ratio); // between red and yellow
      return blendedColor;
    }
      return '#34a853'; // green
  }

  return (
    <CircularProgressRoot {...other} style={{color : `${getColor(value)}`}} >
      <svg viewBox="0 0 36 36" >
        <CircularProgressBackground
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          strokeDasharray="100, 100"
          stroke={`${getColor(value)}2b`}
        />
        <CircularProgressValue
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          strokeDasharray={`${value}, 100`}
          stroke='currentColor'
          stroke-linecap="round"
        />
        <Text x="50%" y="50%" fill='currentColor'>{value.toFixed()}%</Text>
      </svg>
    </CircularProgressRoot>
  );
};

CircularProgress.propTypes = {
  value: PropTypes.number.isRequired,
};

export default CircularProgress;
