import { createStyles, makeStyles, Theme } from '@material-ui/core';

const smallShadow = '0px 0px 4px 0px rgba(0, 0, 0, 0.25)';
export const finalCaseStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles(
        {
            root: {
                // display: 'flex',
                // flexDirection: 'column',
                // alignItems: 'center',
                backgroundColor: '#fff',
                borderRadius: theme.shape.borderRadius,
                margin: "30px",
                '& .btnAlignmant': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                },
                '& .wrapperLoader': {
                    margin: '24px'
                },
                '& .editH': {
                    fontSize: '16px',
                    fontWeight: 600
                },
                '& .editp': {
                    fontSize: '12px',
                    marginBottom: '10px'
                },
                '#loader-root': {
                    display: 'none !important'
                },

                "& .MuiLinearProgress-barColorPrimary": {
                    backgroundColor: "#c22027",
                }

            },
            wrapperLoader: {
                marginBottom: theme.spacing(2),
                marginLeft: theme.spacing(3),
                marginTop: theme.spacing(3),
            },
            formControl: {
                width: '100%',
            },
            button: {
                backgroundColor: '#c22027',
                color: '#fff',
                '&:hover': {
                    backgroundColor: '#d43f40',
                },
                alignSelf: 'flex-end',
                marginRight: theme.spacing(3),
                marginBottom: theme.spacing(3),


            },
            formLabel: {
                marginBottom: theme.spacing(2),
                marginLeft: theme.spacing(3),
                marginTop: theme.spacing(3),
                fontWeight: 600,
                fontSize: 14
            },
            divider: {
                margin: theme.spacing(2, 0),
            },
            subtitle: {
                marginLeft: theme.spacing(3),
            },
            controlLabel: {
                marginLeft: theme.spacing(3),
            },
            btnStyle: {
                height: '24px',
                padding: '20px',
                gap: '10px',
                borderRadius: '6px 0px 0px 0px',
                opacity: 1,
                margin: '10px'
            },
            Done: {
                background: '#DEFFEE',
                color: '#66CB9F'
            },
            Todo: {
                background: '#FFEDE3',
                color: '#F7936F'
            },
            Testing: {
                background: '#FFE6E4',
                color: '#F16063'
            },
            Inprogress: {
                background: '#E5FDFF',
                color: '#09A0BF'
            },
            flexBtn: {
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                '@media (min-width:900px)': {
                    marginLeft: '15%',
                },
            },
            tansitionSec: {
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                flexWrap: 'wrap',
                '& .MuiFormControlLabel-label': {
                    paddingLeft: '25px',
                }
            },
            BoxCenter: {
                textAlign: 'center'
            },
            confirmBG: {
                display: 'flex',
                justifyContent: 'center',
                margin: '10px',
            },
            subtitleCon: {
                background: '#ff000066',
                maxWidth: '333px',
                color: 'white !important',
                padding: '10px',
                borderRadius: '5px'
            },
            loading: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                // height: '100px',
                gap: 10,

                width: '95%'
            },
        }
    )
);
