import { Breadcrumbs, Button, Link, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import ChevronRight from 'src/icons/ChevronRight';
import LinkIcon from '@material-ui/icons/Link';
import toast from 'react-hot-toast';

type LinkTypes = {
  name: string;
  to: string;
  color?: string;
  allowCopy?: boolean;
  copyDetails?: any;
};
type SimpleBreadCrumbTypes = {
  data: {
    title: string;
    links?: LinkTypes[];
    color?: string;
  };
};

const useStyles = makeStyles<Theme>(
  () =>
    createStyles({
      heading: {
        textTransform: 'capitalize',
      },
      subHeading: {
        textTransform: 'capitalize',
      },
      copyLink: {
        padding: 0,
        minWidth: 'auto',
        opacity: 0,
        display: 'none',
        transition: '0.4s ease all',
        '& .MuiButton-startIcon': {
          margin: 0,
          transform: 'rotate(-45deg)',
        },
      },
      breadCrumb: {
        '& .colorChange': {
          width: '15px',
          height: '15px',
          borderRadius: '4px',
          background: 'black',
          display: 'inline-flex',
          marginRight: '5px',
          position: 'relative',
          top: '2px',
        },
        '& .MuiBreadcrumbs-li:last-child': {
          userSelect: 'none',
          // pointerEvents: 'none',
          textDecoration: 'none',
          '&:hover a': {
            textDecoration: 'none',
            cursor: 'default',
          },
        },
        '& .MuiBreadcrumbs-li:hover $copyLink': {
          opacity: 1,
        },
      },
    }),
  {
    name: 'SimpleBreadCrumb',
  }
);

const SimpleBreadCrumb = ({ data }: SimpleBreadCrumbTypes) => {
  const handleCopyLink = async (copyDetails) => {
    try {
      const caseDetails = `Case Title: ${copyDetails.id} - ${copyDetails.title}\nCase URL: ${window.location}`;
      await navigator.clipboard.writeText(`${caseDetails}`);
      toast.success('Copied successfully');
    } catch (error) {
      console.error('Error copying to clipboard:', error);
    }
  };
  const classes = useStyles();
  return (
    <>
      <Typography color="textPrimary" variant="h3" className={classes.heading}>
        {data?.title}
      </Typography>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<ChevronRight fontSize="small" />}
        className={classes.breadCrumb}>
        {data?.links?.map((link) => {
          if (link?.color) {
            return (
              <>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to={link?.to}
                  variant="subtitle2"
                  className={classes.subHeading}
                  key={link.name}
                  style={link.allowCopy && { cursor: 'pointer' }}
                  onClick={() => link.allowCopy && handleCopyLink(link?.copyDetails)}>
                  <span
                    className="colorChange"
                    style={{ background: link?.color }}
                  />{' '}
                  {link?.name}
                </Link>
                {link.allowCopy ? (
                  <Button
                    variant="text"
                    color="primary"
                    className={classes.copyLink}
                    startIcon={<LinkIcon sx={{ fill: 'black', fontSize: '16px' }} />}
                  // onClick={() => handleCopyLink()}
                  />
                ) : null}
              </>
            );
          }

          return (
            <Link
              color="textPrimary"
              component={RouterLink}
              to={link?.to}
              variant="subtitle2"
              className={classes.subHeading}
              key={link.name}>
              {link?.name}
            </Link>
          );
        })}
      </Breadcrumbs>
    </>
  );
};

export default SimpleBreadCrumb;
