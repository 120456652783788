import type { FC } from 'react';

interface MobileLogoProps {
  height?: number | string;
  width?: number | string;
}

const MobileLogo: FC<MobileLogoProps> = ({
  width,
  height,
}: MobileLogoProps) => (
  <img
    width={width}
    height={height}
    src="https://assets.it22.nl/it22/header/it22-layout-logo-black.webp"
    alt="IT22 Logo"
  />
);

export default MobileLogo;
