import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
// import { matchPath } from 'react-router-dom';
import { List } from '@material-ui/core';
import type { ListProps } from '@material-ui/core';
import NavItem from './NavItem';
import { matchPath } from 'react-router-dom';
import getNthCharacterPosition from 'src/utils/getNthCharacterPosition';

interface Item {
  path?: any;
  icon?: ReactNode;
  info?: ReactNode;
  children?: Item[];
  title: string;
  viewable: boolean;
}

interface NavSectionProps extends ListProps {
  items: Item[];
  pathname: any;
  viewable?: any;
}

const renderNavItems = ({
  depth = 0,
  items,
  pathname
}: {
  items: Item[];
  pathname: string;
  depth?: number;
}): JSX.Element => <List disablePadding>
    {items.reduce(
      // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
      (acc, item: any) => reduceChildRoutes({
        acc,
        pathname,
        item,
        depth,
        titleToolTip: item?.title,

      }),
      []
    )}
  </List>;

const reduceChildRoutes = ({
  acc,
  pathname,
  item,
  depth,
  titleToolTip
}: {
  acc: JSX.Element[];
  pathname: string;
  item: Item;
  depth: number;
  titleToolTip: string
}): Array<JSX.Element> => {
  const key = `${item.title}-${depth}`;

  // Check if the current path matches the item's path exactly or partially
  const exactMatch = !!(item.path ? matchPath({ path: item.path, exact: true }, pathname) : false);
  const partialMatch = !!(item.path ? matchPath({ path: item.path }, pathname) : false);

  if (item?.viewable) {
    if (item.children) {
      acc.push(
        <NavItem
          active={exactMatch || partialMatch}
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={exactMatch || partialMatch}
          path={item.path}
          title={item.title}
        >
          {renderNavItems({
            depth: depth + 1,
            items: item.children,
            pathname
          })}
        </NavItem>
      );
    } else {
      acc.push(
        <NavItem
          active={exactMatch || partialMatch}
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          path={item.path}
          title={item.title}
          titleToolTip={titleToolTip}
        />
      );
    }
  }

  return acc;
};


const NavSection: FC<NavSectionProps> = (props) => {
  const {
    items,
    pathname,
    viewable,
    ...other
  } = props;
  return (
    viewable ? <List
      className={`item-${items?.length}`}
      sx={{
        padding: '0 0 5px'
      }}

      {...other}
    >
      {renderNavItems({
        items,
        pathname
      })}
    </List> : <></>
  );
};

NavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
};

export default NavSection;
