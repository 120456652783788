import type { FC } from 'react';

interface LogoProps {
  height?: number | string;
  width?: number | string;
}

const Logo: FC<LogoProps> = ({ width, height }: LogoProps) => (
  <img
    width={width}
    height={height}
    src="/static/logo.png"
    alt="IT22 Logo"
  />
);

export default Logo;
