import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    tableHead: {
      '& .MuiTableCell-root': {
        textTransform: 'capitalize',
        fontWeight: 'bold',
        padding: '10px 8px',
        color: '#333',
        backgroundColor: '#f5f5f5',
        textAlign: 'center', // Center-align header text
      },
    },
    tableRow: {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#f1f1f1',
      },
    },
    tableBodyCell: {
      padding: '5px',
      textAlign: 'center', // Center-align all cells in the body
    },
    collapseRow: {
      backgroundColor: '#fafafa',
      '& .MuiTableCell-root': {
        textAlign: 'center', // Center-align all cells in the expandable rows
      },
    },
    tableContainer: {
      borderRadius: '4px',
      backgroundColor: '#fff',
    },
    cardRoot: {
      borderRadius: '4px',
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    },
    scrollableContainer: {
      overflowX: 'auto',
    },
    iconButton: {
      padding: '0px',
    },
    datePicker: {
        zIndex: '999',
        position: 'absolute',
        backgroundColor: '#fff',
        borderRadius: '5px',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
        padding: '10px',
    },
    leftAlignCell: {
        textAlign: 'left !important', // Left-align for specific cells
    },
  })
);

export default useStyles;
