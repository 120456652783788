import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import React, { useEffect } from 'react';

// faiz
// i have made some changes at handleYes and proptypes are missing here
const BulkDeactivateDialogue = ({
  setBulkIds,
  isOpen,
  bulkIds,
  setDeactivateBulkAlert,
  handleBulkDeactivate,
}: any) => {
  const [open, setOpen] = React.useState(isOpen);

  const handleClose = () => {
    setDeactivateBulkAlert(false);
    setBulkIds([]);
  };

  const handleYes = () => {
    handleBulkDeactivate(bulkIds);
    setBulkIds([]);
    setDeactivateBulkAlert(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <div>
      <Dialog
        sx={{ minWidth: '250px' }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ minWidth: '250px', minHeight: '70px' }}>
          <DialogContentText id="alert-dialog-description">
            Deactivate selected ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
          >
            No
          </Button>
          <Button
            onClick={() => handleYes()}
            color="primary"
            variant="outlined"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BulkDeactivateDialogue;
