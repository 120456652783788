import { Box } from '@material-ui/core';
import { Outlet } from 'react-router';

const ConfigurationLayout = () => (
  <>
    <Box sx={{ paddingBottom: '64px', minHeight: '100%', paddingLeft: '25px', paddingTop: '24px', paddingRight: '49px', }}>
      <Outlet />
    </Box>
  </>
);

export default ConfigurationLayout;
