import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import SImpleModal from '../widgets/modal/SImpleModal';
import DocumentViewer from '../widgets/modal/DocumentViewer';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    heading4: {
      color: '#423636',
      fontSize: '18px',
      marginBottom: '16px',
      fontWeight: 600,
    },
    editCommentFileParent: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      '& a': {
        position: 'relative',
        width: '200px',
        height: '200px',
        border: '1px solid #0003',
        borderRadius: '4px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0px 5px 5px 0px',
        cursor: 'pointer',
        '& img': {
          width: '100%',
          height: '100%',
          objectFit: 'contain'
        },
      },
    },
  })
);
const AttachmentViewer = ({ data }: any) => {
  const classes = useStyles();

  const [selectedImageUrl, setSelectedImageUrl] = useState<string>('');
  const [isImagePopupOpen, setIsImagePopupOpen] = useState<boolean>(false);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState<string>('');
  const [isDocumentViewerOpen, setIsDocumentViewerOpen] =
    useState<boolean>(false);
  const handleImageClick = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setIsImagePopupOpen(true);
  };
  const handleCloseImagePopup = () => {
    setIsImagePopupOpen(false);
  };

  const handleCloseDocumentViewer = () => {
    setIsDocumentViewerOpen(false);
  };
  const handleDocumentClick = (documentUrl) => {
    setSelectedDocumentUrl(documentUrl);
    setIsDocumentViewerOpen(true);
  };
  return (
    <>
      {data?.length > 0 && (
        <div>
          <Typography
            gutterBottom
            variant="h4"
            component="div"
            className={`${classes.heading4}`}>
            Attachments
          </Typography>

          {data?.length > 0 && (
            <div className={`${classes.editCommentFileParent}`}>
              {data?.map((item) => {
                const splittedUrl = item?.split("?");
                splittedUrl[1] =
                  "sp=racwdl&st=2024-02-27T13:21:58Z&se=2030-07-29T21:21:58Z&spr=https&sv=2022-11-02&sr=c&sig=YXOHT7%2Bx8VW3RDdgzehtPZLWJ6zHJz%2Bp3QtnduafL4o%3D";
                item = splittedUrl?.join("?");
                const getFileType = (items) => {
                  if (items.includes('.pdf')) {
                    return 'PDF';
                  }
                  if (items.includes('.doc')) {
                    return 'DOC';
                  }
                  if (items.includes('.docx')) {
                    return 'DOCX';
                  }
                  if (items.includes('.txt')) {
                    return 'TXT';
                  }
                  if (items.includes(".xls")) {
                    return "XLS";
                  }
                  if (items.includes(".xlsx")) {
                    return "XLSX";
                  }
                  if (items.includes(".csv")) {
                    return "CSV";
                  }
                  if (items.includes(".tsv")) {
                    return "TSV";
                  }
                  return null;
                };

                const fileType = getFileType(item);
                if (
                  item.includes('.pdf') ||
                  item.includes('.doc') ||
                  item.includes('.docx') ||
                  item.includes(".xls") ||
                  item.includes(".xlsx") ||
                  item.includes(".csv") ||
                  item.includes(".tsv") ||
                  item.includes('.txt')
                ) {
                  return (
                    <>
                      <a
                        role="presentation"
                        onClick={() => handleDocumentClick(item)}>
                        {' '}
                        <span>{fileType}</span>
                        <InsertDriveFileIcon sx={{ fontSize: '60px' }} />
                      </a>
                    </>
                  );
                }
                const imageFormats = [
                  '.jpg',
                  '.jpeg',
                  '.png',
                  '.gif',
                  '.bmp',
                  '.tiff',
                  '.webp',
                  '.svg',
                  '.ico',
                ];
                if (
                  imageFormats.some((extension) => item?.includes(extension))
                ) {
                  return (
                    <>
                      <a>
                        <img
                          role="presentation"
                          src={item}
                          alt="img"
                          onClick={() => handleImageClick(item)}
                        />
                      </a>
                    </>
                  );
                }
                return null;
              })}
            </div>
          )}
        </div>
      )}

      <SImpleModal
        isOpen={isImagePopupOpen}
        onClose={handleCloseImagePopup}
        imageUrl={selectedImageUrl}
      />

      {isDocumentViewerOpen && (
        <DocumentViewer
          documentUrl={selectedDocumentUrl}
          isOpen={isDocumentViewerOpen}
          onClose={handleCloseDocumentViewer}
        />
      )}
    </>
  );
};

export default AttachmentViewer;
