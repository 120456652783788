import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import React, { useState } from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const PasswordField = ({ field, values, errors, touched, handleBlur, handleChange }:any) => {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <FormControl
      variant="outlined"
      fullWidth
    >
      <InputLabel htmlFor={field.id}>{field?.label || 'Password'}</InputLabel>
      <OutlinedInput
        autoComplete="off"
        id={field.id}
        type={showPassword ? 'text' : 'password'}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={toggleShowPassword}
              edge="end"
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
                  )}
        label={field.label}
        name={field.name}
        required={field.required}
        value={values[`${field.name}`]}
        onChange={handleChange}
        onBlur={handleBlur}
        error={Boolean(
          errors[`${field.name}`]
                    && touched[`${field.name}`]
        )}
      />
      <FormHelperText
        id={field.id}
        error={Boolean(touched[`${field.name}`] && errors[`${field.name}`])}
      >
        {
                    touched[`${field.name}`]
                    && errors[`${field.name}`]
                  }

      </FormHelperText>
    </FormControl>
  );
};

export default PasswordField;
