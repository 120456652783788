import { Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { permissionsApi } from 'src/api/permissions';
import TableList from 'src/components/widgets/tables/TableList';
import logger from 'src/utils/logger';
import useAuth from 'src/hooks/useAuth';
import setTitle from 'src/utils/setTitle';

const PermissionsListView = () => {
  const baseUrl = '/erp/settings';
  setTitle('ERP | Permissions');
  const { user } = useAuth();
  const [allPermissions, setAllPermissions] = useState<Array<any>>([]);
  const [count, setCount] = useState<number>(0);
  const [isActivated, setIsActivated] = useState<boolean | null>(null);
  const [search, setSearch] = useState<any>(JSON.parse(localStorage.getItem('permissionsPagePg'))?.permissionsSearch || '');
  const [page, setPage] = useState<any>(JSON.parse(localStorage.getItem('permissionsPagePg'))?.permissionsPageNo || 0);
  const [limit, setLimit] = useState<any>(JSON.parse(localStorage.getItem('permissionsPagePg'))?.permissionsPageLimit || 25);
  const [isDataRendered, setIsDataRendered] = useState<boolean>(false);
  const [column, setColumn] = useState<string>(JSON.parse(localStorage.getItem('permissionsPagePg'))?.column || '');
  const [sortVal, setSortVal] = useState<string>(JSON.parse(localStorage.getItem('permissionsPagePg'))?.sort || '');
  type SortDataTypes = {
    identifier: 'asc' | 'desc';
    name: 'asc' | 'desc';
    module: 'asc' | 'desc';
    subModule: 'asc' | 'desc';
    status: 'asc' | 'desc';

  };
  const [sortData, setSortData] = useState<SortDataTypes>({
    identifier: JSON.parse(localStorage.getItem('permissionsPagePg'))?.identifier || 'asc',
    name: JSON.parse(localStorage.getItem('permissionsPagePg'))?.name || 'asc',
    module: JSON.parse(localStorage.getItem('permissionsPagePg'))?.module || 'asc',
    subModule: JSON.parse(localStorage.getItem('permissionsPagePg'))?.subModule || 'asc',
    status: JSON.parse(localStorage.getItem('permissionsPagePg'))?.status || 'asc',
  });

  const handlePage = (temp: number) => {
    setPage(temp);
  };
  const handleLimit = (temp: number) => {
    setLimit(temp);
  };
  const handleSearch = (val:any) => {
    setSearch(val);
  };

  const handleBulkDeactivate = async (checkedIds: Array<number>) => {
    try {
      //   const response = await employeesApi.bulkDeactivate(checkedIds);
      //   if (response?.status === 200) {
      //     toast.success('updated successfully');
      //     setIsDataRendered(!isDataRendered);
      //   }
      //   if (response?.status === 204) {
      //     toast.error('try again later');
    //   }
      logger(checkedIds, '', 'handle-bulk-deactivate: checkedIds: ');
      toast.success('Successfuly Deactivated');
    } catch (error) {
      logger(error, 'error');
      toast.error('try again later');
    }
  };

  const handleStatusToggle = async (id: string) => {
    try {
      const response: any = await permissionsApi.toggleStatusById(
        id
      );
      if (response?.status === 200) {
        toast.success('updated successfully');
        setIsDataRendered(!isDataRendered);
      }
      if (response?.status === 204) {
        toast.error('try again later');
      }
      logger(id, '', 'handle-status-toggle: id: isActivated');
    } catch (error) {
      logger(error, 'error');
      toast.error('try again later');
    }
  };

  const getAll = async () => {
    try {
      const res = await permissionsApi.getAll(page, limit, search, isActivated, column, sortVal);
      if (res?.status === 200) {
        setAllPermissions(res?.data?.data);
        setCount(res?.data?.totalRecords);
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.data?.message);
        setAllPermissions([]);
        setCount(0);
      }
    }
  };
  const toggleSortData = (fieldName: string) => {
    type SortStatusType = 'asc' | 'desc';
    let sortStatus: SortStatusType = 'asc';
    // eslint-disable-next-line default-case
    switch (fieldName) {
      case 'identifier':
        if (sortData.identifier === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          name: 'asc',
          module: 'asc',
          subModule: 'asc',
          status: 'asc',
          identifier: sortStatus
        });
        break;
      case 'name':
        if (sortData.name === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          identifier: 'asc',
          module: 'asc',
          subModule: 'asc',
          status: 'asc',
          name: sortStatus
        });
        break;
      case 'module':
        if (sortData.module === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          identifier: 'asc',
          name: 'asc',
          subModule: 'asc',
          status: 'asc',
          module: sortStatus
        });
        break;
      case 'subModule':
        if (sortData.subModule === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          identifier: 'asc',
          name: 'asc',
          module: 'asc',
          status: 'asc',
          subModule: sortStatus
        });
        break;
      case 'status':
        if (sortData.status === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          identifier: 'asc',
          name: 'asc',
          module: 'asc',
          subModule: 'asc',
          status: sortStatus
        });
        break;
    }
  };
  const getAllSortedData = async (fieldName: string, value: string) => {
    try {
      let columnValue = '';
      const fieldToColumnMap = {
        identifier: 'identifier',
        name: 'name',
        module: 'moduleName',
        subModule: 'subModuleName',
        status: 'isActive',
      };

      columnValue = fieldToColumnMap[fieldName] || 'defaultSort';
      setColumn(columnValue);
      setSortVal(value);
    } catch (error) {
      toast.error(error);
    }
  };
  const columns: Array<any> = [
    {
      id: 'identifier',
      name: 'Identifier',
      value: 'identifier',
      label: 'Identifier',
      sortName: 'identifier',
      isSort: true,
      toggleSortData,
      direction: sortData?.identifier,
    },
    {
      id: 'name',
      name: 'Name',
      value: 'name',
      label: 'Name',
      sortName: 'name',
      isSort: true,
      toggleSortData,
      direction: sortData?.name,
    },
    {
      id: 'module',
      name: 'Module',
      value: 'module',
      label: 'Module',
      sortName: 'moduleName',
      isSort: true,
      toggleSortData,
      direction: sortData?.module,
      objectKey: 'name',
    },
    { id: 'subModule',
      name: 'Sub Module',
      value: 'subModule',
      label: 'Sub Module',
      sortName: 'subModuleName',
      isSort: true,
      toggleSortData,
      direction: sortData?.subModule,
      objectKey: 'name',
    },
    { id: 'status',
      name: 'Status',
      value: 'isActive',
      label: 'Status',
      isSort: false,
      toggleSortData,
      direction: sortData?.status,
    },
  ];
  useEffect(() => {
    getAll();
    const permissionsPagePg = {
      permissionsPageLimit: limit,
      permissionsPageNo: page,
      permissionsSearch: search,
      identifier: sortData?.identifier,
      name: sortData?.name,
      module: sortData?.module,
      subModule: sortData?.subModule,
      status: sortData?.status,
      column,
      sort: sortVal,
    };
    localStorage.setItem('permissionsPagePg', JSON.stringify(permissionsPagePg));
  }, [isActivated, page, limit, search, isDataRendered, sortData?.identifier,
    sortData?.name,
    sortData?.module,
    sortData?.subModule,
    sortData?.status, column,
    sortVal]);

  useEffect(() => {
    if (
      !user?.role?.some((roleObj) =>  roleObj.identifier === 'it22_admin')
    ) {
      window.location.href = '/admin/not-found';
    }
  }, []);
  return (
    <>
      <Typography
        variant="h3"
        style={{ marginTop: '16px', marginBottom: '10px', fontSize: '20px', fontWeight: '700', color: '#000', }}
      >
        Permissions List
      </Typography>
      <TableList
        pageName="permissions"
        allowedColumns={columns}
        data={allPermissions}
        redirectUrl={`${baseUrl}/permissions/new`}
        editRouteUrl={`${baseUrl}/permissions/edit`}
        setIsActivated={setIsActivated}
        getAllSortedData={getAllSortedData}
        count={count}
        page={page}
        limit={limit}
        search={search}
        handleSearch={handleSearch}
        handleLimit={handleLimit}
        handlePage={handlePage}
        column={column}
        setColumn={setColumn}
        sortVal={sortVal}
        setSortVal={setSortVal}
        setSortData={setSortData}
        handleBulkDeactivate={handleBulkDeactivate}
        handleStatusToggle={handleStatusToggle}
      />
    </>
  );
};

export default PermissionsListView;
