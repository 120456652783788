/* eslint-disable no-underscore-dangle */
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import type { FC } from 'react';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { TableProps } from 'src/types/components/tableList.types';
import Scrollbar from '../../Scrollbar';
// import logger from 'src/utils/logger';
// import moment from 'moment';
// import { absoluteTime } from 'src/utils/time';
import moment from 'moment';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    btn: {
      width: '47px',
      height: '40px',
      padding: 0,
      minWidth: 'initial',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '4px',
      color: '#fff',
      marginLeft: '13px',
      '& .MuiButton-iconSizeMedium': {
        margin: '0',
      },
    },
    clip: {
      display: 'inline-block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '10ch',
    },
    textFieldSelect: {
      width: '230px',
      marginLeft: '13px',
    },
    textFieldSelectRoot: {
      borderRadius: '4px',
      height: '38px',
    },
    tabButton: {
      width: '181px',
    },
    cardRoot: {
      borderRadius: '4px',
    },
    tabs: {
      '& .MuiTab-root': {
        backgroundColor: 'transparent',
        minWidth: '120px',
      },
      '& .Mui-selected': {
        backgroundColor: '#231F20',
        color: '#fff',
      },
    },
    tableHead: {
      '& .MuiTableCell-root': {
        textTransform: 'capitalize',
      },
    },
    tableRow: {
      '& :hover': {
        cursor: 'pointer',
      },
      '& .ticketStatusTrue': {
        backgroundColor: 'rgba(7, 83, 0, 0.16)',
        color: '#1D7A15',
        padding: '5px',
        fontWeight: 'Bold',
      },
      '& .ticketStatusFalse': {
        background: '#DEEBFF',
        color: '#3375D7',
        padding: '5px',
        fontWeight: 'Bold',
      },
    },
    statueBox: {
      gap: 6,
      color: '#6C6C6C',
      width: 100,
      height: 28,
      display: 'flex',
      background: '#F1F1F1',
      alignItems: 'center',
      textTransform: 'capitalize',
      justifyContent: 'center',
      fontSize: 13,
      fontWeight: 500,
      borderRadius: 4,
      border: '1px solid #6C6C6C',
    },
    loaderTable: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: '2222',
    },
  })
);

const TableHeadComponent: FC<any> = ({
  data,
  setSelectedSort,
  selectedSort,
}: any) => (
  <TableHead sx={{ marginBottom: '1rem' }}>
    <TableRow>
      {data?.map((col: any) => (
        <TableCell
          align="center"
          className="textBold"
          sortDirection={
            selectedSort?.key === col?.id ? selectedSort?.value : false
          }>
          {col.isSort ? (
            <>
              <TableSortLabel
                active={selectedSort?.key === col?.id}
                direction={
                  selectedSort?.key === col?.id ? selectedSort?.value : 'asc'
                }
                // active={col.isSort}
                // direction={col.direction}
                onClick={() => {
                  col.toggleSortData(`${col.id}`);
                  // getAllSortedData(`${col.id}`, col.direction === 'asc' ? '1' : '-1');
                  // logger(getAllSortedData);
                  setSelectedSort({
                    key: `${col?.id}`,
                    value: col.direction === 'asc' ? 'asc' : 'desc',
                  });
                  // setSort('', '');
                }}
              // hideSortIcon={col.isSort || true}
              >
                {col?.label}
              </TableSortLabel>
            </>
          ) : (
            col?.label
          )}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);
interface TableBodyComponentProps {
  data?: any[] | undefined;
  editRouteUrl?: string;
  handleClick?: any;
  visibleColumns?: string[];
}

const TableBodyComponent: FC<TableBodyComponentProps> = ({
  data,
  editRouteUrl,
  handleClick,
  visibleColumns,
}: TableBodyComponentProps) => {
  const classes = useStyles();
  // const navigate = useNavigate();

  // const handleRedirectNext = (id: string) => {
  //   navigate(`${editRouteUrl}/${id}`);
  // };

  // logger(handleRedirectNext);
  const getNestedValue = (obj: any, path: string): any => {
    const keys = path.split('.');
    return keys.reduce(
      (acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
      obj
    );
  };

  const StatusComponent = (label: string): void => {
    const obj = {
      pending: (
        <Box
          className={classes.statueBox}
          style={{
            background: '#F1F1F1',
            color: '#6C6C6C',
            borderColor: '#6C6C6C',
          }}>
          {' '}
          {label}{' '}
        </Box>
      ),
      active: (
        <Box
          className={classes.statueBox}
          style={{
            background: '#E9FFE5',
            color: '#3EAB2C',
            borderColor: '#3EAB2C',
          }}>
          {' '}
          {label}{' '}
        </Box>
      ),
      closed: (
        <Box
          className={classes.statueBox}
          style={{
            background: '#FFF1E4',
            color: '#A76A32',
            borderColor: '#A76A32',
          }}>
          {' '}
          {label}{' '}
        </Box>
      ),
      expired: (
        <Box
          className={classes.statueBox}
          style={{
            background: '#FFE4E4',
            color: '#A73232',
            borderColor: '#A73232',
          }}>
          {' '}
          {label}{' '}
        </Box>
      ),
    };
    return obj[label?.toLowerCase()];
  };
  const formatCellValue = (key: string, row: any): any => {
    const value = getNestedValue(row, key);

    if (
      key === 'data.createdAt' ||
      key === 'data.date' ||
      key === 'data.CUWAuditDate'
    ) {
      // return absoluteTime(value);
      return moment(value).format('DD-MM-YYYY');
    }
    if (key === 'data.cuOrderStatus') {
      return StatusComponent(value);
    }
    if (key === 'data.project') {
      return value || 'Global';
    }
    return value;
  };
  return (
    <TableBody style={{ position: 'relative' }}>
      {data && data.length !== 0 && visibleColumns ? (
        data.map((row: any) => (
          <TableRow
            key={row?.id}
            {...(editRouteUrl !== undefined
              ? {
                component: Link,
                to: `${editRouteUrl}/${row?._id}`,
              }
              : {
                onClick: () => {
                  handleClick(row?._id, row);
                },
              })}
            hover
            className={classes.tableRow}
            style={{
              textDecoration: 'none',
            }}>
            {visibleColumns?.map((key) => (
              <TableCell key={key} align="center" title={row[key]}>
                {formatCellValue(key, row)}
              </TableCell>
            ))}
          </TableRow>
        ))
      ) : (
        <TableRow selected>
          <TableCell
            colSpan={visibleColumns.length}
            sx={{ textAlign: 'center' }}>
            No Data Found
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

interface ConsultingListViewTypes extends TableProps {
  sortData?: any;
  toggleSortData?: any;
  setSelectedSort?: any;
  selectedSort?: any;
  isOrganization?: boolean;
  visibleColumns?: any;
  handleClick?: any;
}
const ConsultingUnitsListView: FC<ConsultingListViewTypes> = ({
  data,
  editRouteUrl,
  count,
  page,
  limit,
  handlePage,
  handleLimit,
  visibleColumns,
  // sortData,
  // toggleSortData,
  column,
  selectedSort,
  setSelectedSort,
  handleClick,
}: any) => {
  const classes = useStyles();

  const handleChangePage = useCallback(
    (event: any, _page: number) => {
      handlePage(_page);
    },
    [page, data]
  );
  const handleChangeLimit = useCallback(
    (event: any) => {
      handleLimit(parseInt(event.target.value, 10));
      handlePage(0);
    },
    [limit, data]
  );

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        py: 1,
      }}>
      {' '}
      <Card className={classes.cardRoot}>
        <Scrollbar>
          <Box sx={{ minWidth: 700, px: 1 }}>
            <Table>
              <TableHeadComponent
                data={column}
                selectedSort={selectedSort}
                setSelectedSort={setSelectedSort}
              />
              <TableBodyComponent
                data={data}
                visibleColumns={visibleColumns}
                editRouteUrl={editRouteUrl}
                handleClick={handleClick}
              />
            </Table>
          </Box>
        </Scrollbar>
        <TablePagination
          component="div"
          count={count}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeLimit}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 100, 500, 1000]}
        />
      </Card>
    </Box>
  );
};

export default ConsultingUnitsListView;
