import React, { FC, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
    FormControl,
    FormLabel,
    Button,
    Typography,
    Divider,
    MenuItem,
    Select,
    CircularProgress,
    FormHelperText,
    Checkbox,
    FormControlLabel,
    TextField,
    Box
} from '@material-ui/core';
import { casesApi } from 'src/api/casesApi';
import { billingApi } from 'src/api/billingApi';
// 

import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { margin, width } from '@material-ui/system';
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';


const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        backgroundColor: '#fff',
        borderRadius: theme.shape.borderRadius,
        margin: "30px",
        '& .MuiFormControlLabel-label': {
            fontSize: '14px'
        },
        '& .MuiSelect-select': {
            padding: '6px 10px',
            fontSize: '12px'
        },
        '& .MuiInputBase-input': {
            padding: '6px 10px',
            fontSize: '12px',
            height: '28px'
        },
        '& input::placeholder': {
            fontSize: '12px',
            color: '#bdbdbd'
        }
    },
    formControl: {
        width: '100%',
    },
    button: {
        backgroundColor: '#c22027',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#d43f40',
        },
        alignSelf: 'flex-end',
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    formLabel: {
        // marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(3),
        // marginTop: theme.spacing(3),
        fontWeight: 600,
        fontSize: 14,

    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    subtitle: {
        marginLeft: theme.spacing(3),
    },
    flexCol: {
        display: 'flex',
        justifyContent: 'start',
        margin: '30px 30px 30px 0px',
        '& .row': {
            width: '50%',
            padding: '10px'
        },
        '& .rowLable': {
            width: '30%',
        },
        '& .rowField': {
            width: '70%',
        },
        '& .react-datepicker-wrapper': {
            width: '100% !important',
            borderColor: '#bdbdbd',
            '& input': {
                width: '100% !important',
                height: '41px',
                paddingLeft: '10px'
            }
        },
        '& .formLabel': {
            marginLeft: '0px !important'
        }
    },
    FormSection: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px'
    },
    loading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100px',
    },

}));

const EditOperation: FC<any> = ({ selectedIds, editPayload }) => {
    const classes = useStyles();


    const { projectID } = useParams();
    const [disableBtn, setDisableBtn] = useState<any>(true);

    const [priorityAllOptions, setPriorityAllOptions] = useState<any[]>([]);
    const [allExternalUsers, setAllExternalUsers] = useState<any[]>([]);
    const [allFixVersions, setAllFixVersions] = useState<any[]>([]);
    const [sprints, setSprints] = useState<any>([]);
    const [labelsOptions, setLabelsOptions] = useState<string[]>([]);
    const [expenseAllOptions, setExpenseAllOptions] = useState<any[]>([
        {
            id: 'Opex',
            name: 'Opex',
        },
        {
            id: 'Capex',
            name: 'Capex',
        },
    ]);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [caseTypeOptions, setCaseTypeOptions] = useState<any>([]);
    const [accountOptions, SetAccountOptions] = useState<any>([]);



    // section
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    // section chck box
    const [isPriorityChecked, setIsPriorityChecked] = useState(false);
    const [isAssigneeChecked, setIsAssigneeChecked] = useState(false);
    const [isFixVersionChecked, setIsFixVersionChecked] = useState(false);
    const [isSprintChecked, setIsSprintChecked] = useState(false);
    const [isLabelChecked, setIsLabelChecked] = useState(false);
    const [isExpenseChecked, setIsExpenseChecked] = useState(false);
    const [isStartDateChecked, setIsstartDateChecked] = useState(false);
    const [isEndDateChecked, setIsEndDateChecked] = useState(false);
    const [isTimeEstimationChecked, setIsTimeEstimationChecked] = useState(false);
    const [isCaseTypeChecked, setIsCaseTypeChecked] = useState(false);
    const [isAccountChecked, setIsAccountChecked] = useState(false);





    const getAutoCompletePriority = async () => {
        try {
            const res = await casesApi?.getPriorities();
            setPriorityAllOptions(res?.data || []);
        } catch (apiError) {
            if (apiError?.response?.status !== 401) {
                toast.error(apiError?.response?.data?.message);
                setError('Failed to load priorities.');
            }
        } finally {
            setLoading(false);
        }
    };

    const getAllExternalUsersAutoComplete = async () => {
        try {
            const res = await casesApi?.getAutoCompleteUser(true);
            setAllExternalUsers(res?.data);
        } catch (apiError) {
            if (apiError?.response?.status !== 401) {
                toast.error(apiError?.response?.data?.message);
                setAllExternalUsers([]);
            }
        }
    };

    const getAutoCompleteReleaseVersion = async (projectId) => {
        try {
            const res = await casesApi?.getAutoCompleteReleaseVersion(projectId);
            setAllFixVersions(res?.data?.active);
        } catch (apiError) {
            if (apiError?.response?.status !== 401) {
                toast.error(apiError?.response?.data?.message);
                setAllFixVersions([]);
            }
        }
    };

    const getAutoCompleteSprints = async (projectId) => {
        try {
            const res = await casesApi?.getAutoCompleteSprints(projectId);
            setSprints(res?.data?.active);
        } catch (apiError) {
            if (apiError?.response?.status !== 401) {
                toast.error(apiError?.response?.data?.message);
                setSprints([]);
            }
        }
    };

    const getLabels = async () => {
        try {
            const res = await casesApi?.getAutoCompleteLabels();
            const uniqueArray = res?.data?.filter(
                (value, index, self) => self.indexOf(value) === index
            );
            setLabelsOptions(uniqueArray);
        } catch (apiError) {
            if (apiError?.response?.status !== 401) {
                setLabelsOptions([]);
                toast.error(apiError?.response?.data?.message);
            }
        }
    };
    const getCaseType = async () => {
        try {
            const response = await casesApi.getCaseTypes();
            setCaseTypeOptions(response?.data);
        } catch (apiError) {
            if (apiError?.response?.status !== 401) {
                toast.error(apiError?.response?.data?.message);
                setCaseTypeOptions([]);
            }
        }
    };

    const getAllBillingAccountsByProject = async (projectId) => {
        try {
            const response = await billingApi.getAllBillingAccountsByProject(projectId);
            const accountData = response?.data.data.length > 0 && response?.data.data.map(account => ({
                id: account.id,
                name: account.name
            }));

            // Set the transformed data
            SetAccountOptions(accountData);
        } catch (apiError) {


            if (apiError?.response?.status !== 401) {
                toast.error(apiError?.response?.data?.message);
                SetAccountOptions([]);
            }
        }
    };


    const convertToSeconds = (timeString) => {
        const timeUnits = timeString.match(/\d+\s*[hm]/g);
        let totalSeconds = 0;

        timeUnits.forEach((unit) => {
            const number = parseInt(unit, 10);
            const timeUnit = unit.replace(/\d+\s*/g, '');

            if (timeUnit === 'h') {
                totalSeconds += number * 3600;
            } else if (timeUnit === 'm') {
                totalSeconds += number * 60;
            }
        });

        return totalSeconds;
    };



    const createPayload = (values: any,) => {
        const payload: any = {
            priority: values.priority || '',
            assignee: values.assignee || '',
            releaseVersion: values.fixVersion ? [values.fixVersion] : [],
            sprint: values.sprint || '',
            labels: values.Label ? [values.Label] : [],
            caseType: values.feature || '',
            expense: values.Expense || '',
            startDate: values.startDate ? moment(values.startDate).format('YYYY-MM-DD') : '',
            dueDate: values.endDate ? moment(values.endDate).format('YYYY-MM-DD') : '',
            timeEstimate: values.timeEstimation ? convertToSeconds(values.timeEstimation) : 0,
            billingAccountType: values.account || '',
            // caseIds: selectedIds || []
        };


        return Object.fromEntries(
            Object.entries(payload).filter(([_, value]) =>
                value !== '' && value !== 0 && (Array.isArray(value) ? value.length !== 0 : true)
            )
        );
    };

    const handleSubmit = async (values: any) => {
        const payload = createPayload(values);

        if (Object.keys(payload).length > 0) {
            editPayload(payload);
        } else {
            toast.error('Please Select Value');
        }
    };

    useEffect(() => {
        getAutoCompletePriority();
        getAllExternalUsersAutoComplete();
        getAutoCompleteReleaseVersion(projectID);
        getAutoCompleteSprints(projectID);
        getLabels()
        getCaseType()
        getAllBillingAccountsByProject(projectID)
    }, [projectID]);

    return (
        <div className={classes.root}>
            <Formik
                initialValues={{
                    priority: '',
                    assignee: '',
                    fixVersion: '',
                    sprint: '',
                    Label: '',
                    feature: '',
                    Expense: '',
                    startDate: '',
                    endDate: '',
                    timeEstimation: '',
                    account: '',
                }}
                validationSchema={Yup.object().shape({
                    priority: isPriorityChecked ? Yup.mixed().required('Required') : Yup.mixed(),
                    assignee: isAssigneeChecked ? Yup.mixed().required('Required') : Yup.mixed(),
                    fixVersion: isFixVersionChecked ? Yup.mixed().required('Required') : Yup.mixed(),
                    sprint: isSprintChecked ? Yup.mixed().required('Required') : Yup.mixed(),
                    Label: isLabelChecked ? Yup.mixed().required('Required') : Yup.mixed(),
                    startDate: isStartDateChecked ? Yup.mixed().required('Required') : Yup.mixed(),
                    endDate: isEndDateChecked ? Yup.mixed().required('Required') : Yup.mixed(),
                    timeEstimation: isTimeEstimationChecked ? Yup.mixed().required('Required') : Yup.mixed(),
                    account: isAccountChecked ? Yup.mixed().required('Required') : Yup.mixed(),
                })}
                onSubmit={handleSubmit}
            >
                {({ errors, touched, setFieldValue, values }) => {
                    useEffect(() => {
                        if (!isPriorityChecked) setFieldValue('priority', '');
                        if (!isAssigneeChecked) setFieldValue('assignee', '');
                        if (!isFixVersionChecked) setFieldValue('fixVersion', '');
                        if (!isSprintChecked) setFieldValue('sprint', '');
                        if (!isLabelChecked) setFieldValue('Label', '');
                        if (!isExpenseChecked) setFieldValue('Expense', '');
                        if (!isStartDateChecked) setFieldValue('startDate', '');
                        if (!isEndDateChecked) setFieldValue('endDate', '');
                        if (!isTimeEstimationChecked) setFieldValue('timeEstimation', '');
                        if (!isAccountChecked) setFieldValue('account', '');
                        // Optionally handle isCaseTypeChecked if applicable
                    }, [
                        isPriorityChecked,
                        isAssigneeChecked,
                        isFixVersionChecked,
                        isSprintChecked,
                        isLabelChecked,
                        isExpenseChecked,
                        isStartDateChecked,
                        isEndDateChecked,
                        isTimeEstimationChecked,
                        isAccountChecked
                    ]);

                    return (
                        <Form>
                            <div className={classes.formControl}>
                                <div className="headingSection">
                                    <Typography variant="h6" sx={{ paddingTop: '20px' }} className={classes.formLabel}>
                                        Step 3 of 4: Select Operation
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" className={classes.subtitle}>
                                        Choose which action you&apos;d like to take on the selected issues.
                                    </Typography>
                                </div>
                                <Divider className={classes.divider} />
                                <div className={classes.flexCol}>
                                    <div className='row'>
                                        <div className={classes.FormSection}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={isPriorityChecked}
                                                        onChange={() => setIsPriorityChecked(!isPriorityChecked)}
                                                        name="isPriorityChecked"
                                                        color="primary"
                                                    />
                                                }
                                                label="Priority"
                                                className={`${classes.formLabel} rowLable`}
                                            />
                                            <div className='rowField'>
                                                {loading ? (
                                                    <div className={classes.loading}>
                                                        <CircularProgress />
                                                    </div>
                                                ) : (
                                                    <Field
                                                        as={Select}
                                                        name="priority"
                                                        fullWidth
                                                        onChange={(e) => setFieldValue("priority", e.target.value)}
                                                        displayEmpty
                                                        disabled={!isPriorityChecked}
                                                    >
                                                        <MenuItem value="" disabled>
                                                            Select Priority
                                                        </MenuItem>
                                                        {priorityAllOptions.map((option: any) => (
                                                            <MenuItem key={option.id} value={option}>
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                )}
                                                {errors.priority && touched.priority ? (
                                                    <FormHelperText error>{errors.priority}</FormHelperText>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className={classes.FormSection}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={isAssigneeChecked}
                                                        onChange={() => setIsAssigneeChecked(!isAssigneeChecked)}
                                                        name="isAssigneeChecked"
                                                        color="primary"
                                                    />
                                                }
                                                label="Assignee"
                                                className={`${classes.formLabel} rowLable`}
                                            />
                                            <div className='rowField'>
                                                {loading ? (
                                                    <div className={classes.loading}>
                                                        <CircularProgress />
                                                    </div>
                                                ) : (
                                                    <Field
                                                        as={Select}
                                                        name="assignee"
                                                        fullWidth
                                                        onChange={(e) => setFieldValue("assignee", e.target.value)}
                                                        displayEmpty
                                                        disabled={!isAssigneeChecked}
                                                    >
                                                        <MenuItem value="" disabled>
                                                            Select Assignee
                                                        </MenuItem>
                                                        {allExternalUsers.map((option: any) => (
                                                            <MenuItem key={option.id} value={option}>
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                )}
                                                {errors.assignee && touched.assignee ? (
                                                    <FormHelperText error>{errors.assignee}</FormHelperText>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className={classes.FormSection}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={isFixVersionChecked}
                                                        onChange={() => setIsFixVersionChecked(!isFixVersionChecked)}
                                                        name="isFixVersionChecked"
                                                        color="primary"
                                                    />
                                                }
                                                label="Fix Version"
                                                className={`${classes.formLabel} rowLable`}
                                            />
                                            <div className='rowField'>
                                                {loading ? (
                                                    <div className={classes.loading}>
                                                        <CircularProgress />
                                                    </div>
                                                ) : (
                                                    <Field
                                                        as={Select}
                                                        name="fixVersion"
                                                        fullWidth
                                                        onChange={(e) => setFieldValue("fixVersion", e.target.value)}
                                                        displayEmpty
                                                        disabled={!isFixVersionChecked}
                                                    >
                                                        <MenuItem value="" disabled>
                                                            Select Fix Version
                                                        </MenuItem>
                                                        {allFixVersions.map((version: any) => (
                                                            <MenuItem key={version.id} value={version}>
                                                                {version.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                )}
                                                {errors.fixVersion && touched.fixVersion ? (
                                                    <FormHelperText error>{errors.fixVersion}</FormHelperText>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className={classes.FormSection}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={isSprintChecked}
                                                        onChange={() => setIsSprintChecked(!isSprintChecked)}
                                                        name="isSprintChecked"
                                                        color="primary"
                                                    />
                                                }
                                                label="Sprint"
                                                className={`${classes.formLabel} rowLable`}
                                            />
                                            <div className='rowField'>
                                                {loading ? (
                                                    <div className={classes.loading}>
                                                        <CircularProgress />
                                                    </div>
                                                ) : (
                                                    <Field
                                                        as={Select}
                                                        name="sprint"
                                                        fullWidth
                                                        onChange={(e) => setFieldValue("sprint", e.target.value)}
                                                        displayEmpty
                                                        disabled={!isSprintChecked}
                                                    >
                                                        <MenuItem value="" disabled>
                                                            Select Sprint
                                                        </MenuItem>
                                                        {sprints.map((sprint: any) => (
                                                            <MenuItem key={sprint.id} value={sprint}>
                                                                {sprint.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                )}
                                                {errors.sprint && touched.sprint ? (
                                                    <FormHelperText error>{errors.sprint}</FormHelperText>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className={classes.FormSection}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={isLabelChecked}
                                                        onChange={() => setIsLabelChecked(!isLabelChecked)}
                                                        name="isLabelChecked"
                                                        color="primary"
                                                    />
                                                }
                                                label="Label"
                                                className={`${classes.formLabel} rowLable`}
                                            />
                                            <div className='rowField'>
                                                {loading ? (
                                                    <div className={classes.loading}>
                                                        <CircularProgress />
                                                    </div>
                                                ) : (
                                                    <Field
                                                        as={Select}
                                                        name="Label"
                                                        fullWidth
                                                        onChange={(e) => setFieldValue("Label", e.target.value)}
                                                        displayEmpty
                                                        disabled={!isLabelChecked}
                                                        value={values.Label || ""}
                                                    >
                                                        <MenuItem value="" disabled>
                                                            Select Label
                                                        </MenuItem>
                                                        {labelsOptions.map((Label: any) => (
                                                            <MenuItem key={Label} value={Label}>
                                                                {Label}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                )}
                                                {errors.Label && touched.Label ? (
                                                    <FormHelperText error>{errors.Label}</FormHelperText>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className={classes.FormSection}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={isCaseTypeChecked}
                                                        onChange={() => setIsCaseTypeChecked(!isCaseTypeChecked)}
                                                        name="isCaseTypeChecked"
                                                        color="primary"
                                                    />
                                                }
                                                label="Case Type"
                                                className={`${classes.formLabel} rowLable`}
                                            />
                                            <div className='rowField'>
                                                {loading ? (
                                                    <div className={classes.loading}>
                                                        <CircularProgress />
                                                    </div>
                                                ) : (
                                                    <Field
                                                        as={Select}
                                                        name="feature"
                                                        fullWidth
                                                        onChange={(e) => setFieldValue("feature", e.target.value)}
                                                        displayEmpty
                                                        disabled={!isCaseTypeChecked}
                                                    >
                                                        <MenuItem value="" disabled>
                                                            Select Feature
                                                        </MenuItem>
                                                        {caseTypeOptions.map((caseType: any) => (
                                                            <MenuItem key={caseType.id} value={caseType}>
                                                                {caseType.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                )}
                                                {errors.feature && touched.feature ? (
                                                    <FormHelperText error>{errors.feature}</FormHelperText>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    {/* row 2 */}

                                    <div className='row'>
                                        <div className={classes.FormSection}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={isExpenseChecked}
                                                        onChange={() => setIsExpenseChecked(!isExpenseChecked)}
                                                        name="isExpenseChecked"
                                                        color="primary"
                                                    />
                                                }
                                                label="Expense"
                                                className={`${classes.formLabel} rowLable`}
                                            />
                                            <div className='rowField'>
                                                {loading ? (
                                                    <div className={classes.loading}>
                                                        <CircularProgress />
                                                    </div>
                                                ) : (
                                                    <Field
                                                        as={Select}
                                                        name="Expense"
                                                        fullWidth
                                                        onChange={(e) => setFieldValue("Expense", e.target.value)}
                                                        displayEmpty
                                                        disabled={!isExpenseChecked}

                                                    >
                                                        <MenuItem value="" disabled>
                                                            Select expense
                                                        </MenuItem>
                                                        {expenseAllOptions.map((option: any) => (
                                                            <MenuItem key={option.id} value={option.name}>
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                )}
                                                {errors.expense && touched.expense ? (
                                                    <FormHelperText error>{errors.expense}</FormHelperText>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* start date */}
                                        <div className={classes.FormSection}>

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={isStartDateChecked}
                                                        onChange={() => setIsstartDateChecked(!isStartDateChecked)}
                                                        name="isStartDateChecked"
                                                        color="primary"
                                                    />
                                                }
                                                label="Start Date"
                                                className={`${classes.formLabel} rowLable`}
                                            />
                                            <div className='rowField dateStyle'>
                                                <DatePicker
                                                    selected={values.startDate}
                                                    onChange={(date) => setFieldValue('startDate', date)}
                                                    selectsStart
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control"
                                                    placeholderText="dd-MM-yyy"
                                                    readOnly={!isStartDateChecked}

                                                />
                                                {errors.startDate && touched.startDate ? (
                                                    <FormHelperText error>{errors.startDate}</FormHelperText>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* End Date */}
                                        <div className={classes.FormSection}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={isEndDateChecked}
                                                        onChange={() => setIsEndDateChecked(!isEndDateChecked)}
                                                        name="isEndDateChecked"
                                                        color="primary"
                                                    />
                                                }
                                                label="End Date"
                                                className={`${classes.formLabel} rowLable`}
                                            />
                                            <div className='rowField dateStyle'>
                                                <DatePicker
                                                    selected={values.endDate}
                                                    onChange={(date) => setFieldValue('endDate', date)}
                                                    selectsEnd
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    minDate={startDate}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control"
                                                    placeholder="date"
                                                    placeholderText="dd-MM-yyy"
                                                    readOnly={!isEndDateChecked}
                                                />
                                                {errors.endDate && touched.endDate ? (
                                                    <FormHelperText error>{errors.endDate}</FormHelperText>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* estimation */}
                                        <div className={classes.FormSection}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={isTimeEstimationChecked}
                                                        onChange={() => setIsTimeEstimationChecked(!isTimeEstimationChecked)}
                                                        name="isTimeEstimationChecked"
                                                        color="primary"
                                                    />
                                                }
                                                label="Estimation"
                                                className={`${classes.formLabel} rowLable`}
                                            />

                                            <div className='rowField'>
                                                <Field
                                                    as={TextField}
                                                    name="timeEstimation"
                                                    fullWidth
                                                    placeholder="Enter time estimation (e.g., 3h 30m)"
                                                    onChange={(e) => setFieldValue("timeEstimation", e.target.value)}
                                                    // onChange={(e) => {
                                                    //     const timeString = e.target.value;
                                                    //     const seconds = convertToSeconds(timeString);
                                                    //     setFieldValue("timeEstimation", seconds);
                                                    // }}
                                                    disabled={!isTimeEstimationChecked}

                                                />
                                                {errors.timeEstimation && touched.timeEstimation ? (
                                                    <FormHelperText error>{errors.timeEstimation}</FormHelperText>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* account */}

                                        <div className={classes.FormSection}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={isAccountChecked}
                                                        onChange={() => setIsAccountChecked(!isAccountChecked)}
                                                        name="isAccountChecked"
                                                        color="primary"
                                                    />
                                                }
                                                label="Account"
                                                className={`${classes.formLabel} rowLable`}
                                            />
                                            <div className='rowField'>
                                                {loading ? (
                                                    <div className={classes.loading}>
                                                        <CircularProgress />
                                                    </div>
                                                ) : (
                                                    <Field
                                                        as={Select}
                                                        name="account"
                                                        fullWidth
                                                        onChange={(e) => setFieldValue("account", e.target.value)}
                                                        displayEmpty
                                                        disabled={!isAccountChecked}
                                                    >
                                                        <MenuItem value="" disabled>
                                                            Select Account
                                                        </MenuItem>
                                                        {Array.isArray(accountOptions) && accountOptions.map(option => (
                                                            <MenuItem key={option.id} value={option}>
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                )}
                                                {errors.account && touched.account ? (
                                                    <FormHelperText error>{errors.account}</FormHelperText>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Box sx={{ margin: '30px', display: 'flex', justifyContent: 'end' }}>
                                <Button type="submit" variant="contained" className={classes.button}>
                                    Next
                                </Button>
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default EditOperation;
