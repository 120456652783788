import { Grid, MenuItem, TextField } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { useCallback, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router';
import ReactFlow, {
  Background,
  Controls,
  MarkerType,
  MiniMap,
  ReactFlowProvider,
  addEdge,
  useEdgesState,
  useNodesState,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { casesApi } from 'src/api/casesApi';
import { statusFlowApi } from 'src/api/statusFlowApi';
import AllStatusNodes from 'src/components/flow/AllStatusNodes';
import logger from 'src/utils/logger';
import * as Yup from 'yup';
import ButtonEdge from '../../../../components/flow/edges/ButtonEdge';
import CloseNode from '../../../../components/flow/nodes/CloseNode';
import StartNode from '../../../../components/flow/nodes/StartNode';
import ToDoNode from '../../../../components/flow/nodes/ToDoNode';
import { initialEdges, initialNodes } from './data';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    nodesRoot: {
      width: '100%',
      height: '150px',
      backgroundColor: '#fff',
      border: '1px solid black',
      borderRadius: '5px',
      padding: '10px 15px',
    },
    flowRoot: {
      marginTop: '10px',
      width: '100%',
      height: '100%',
      backgroundColor: '#ffffffcf',
      border: '2px solid rgba(0,0,0,0.2)',
      borderRadius: '5px',
    },
    btnContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '10px',
      marginTop: '10px',
    },
    btn: {
      padding: '10px 15px',
      height: '38px',
      minWidth: '129px',
      borderRadius: '4px',
      border: '2px solid #000',
      textTransform: 'capitalize',
      transition: 'all 0.2s linear',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(35,31,32,0.04)',
      },
    },
    btnRed: {
      borderColor: '#c22027',
      backgroundColor: '#c22027',
      color: '#fff',
      '&:hover': {
        backgroundColor: 'transparent',
        color: '#c22027',
      },
    },
  })
);

const nodeTypes = {
  start: StartNode,
  todo: ToDoNode,
  close: CloseNode,
};
const edgeTypes = {
  custom: ButtonEdge,
};

const StatusFlowEdit = () => {
  const navigate = useNavigate();
  const { flowID } = useParams();
  const [allCaseTypes, setAllCaseTypes] = useState([]);
  const [flowData, setFlowData] = useState<any>({});
  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const classes = useStyles();
  const [nodes, setNodes, onNodesChange] = useNodesState<any>(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const handleConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            type: 'custom',
            label: 'label',
            markerEnd: { type: MarkerType.ArrowClosed },
          },
          eds
        )
      ),
    [setEdges]
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      let nodeData = event.dataTransfer.getData('application/reactflow');
      nodeData = JSON.parse(nodeData);

      // check if the dropped element is valid
      if (typeof nodeData === 'undefined' || !nodeData) {
        return;
      }
      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      const newNode = {
        id: nodeData?.id,
        type: 'todo',
        position,
        data: { label: 'label', isAny: false, name: nodeData?.name },
      };

      // setNodes((nds) => nds.concat(newNode));
      setNodes((nds) => [...nds, newNode]);
    },
    [reactFlowInstance]
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      identifier: flowData?.identifier || '',
      name: flowData?.name || '',
      caseType: flowData?.caseTypeId || '',
    },
    validationSchema: Yup.object().shape({
      identifier: Yup.string().required('Identifier required'),
      name: Yup.string()
        .required('Name required')
        .min(2, 'Minimum 2 characters allowed')
        .max(255, 'Maximum 255 characters allowed'),
      caseType: Yup.string().required('Case type required'),
    }),
    onSubmit: async (values) => {
      let flowStructure = nodes?.map((nod) => ({
        id: nod?.id,
        allowAny: nod?.data?.isAny || false,
        to: [],
      }));
      edges?.forEach((edg) => {
        /* eslint-disable-next-line array-callback-return */
        flowStructure = flowStructure?.map((itm) => {
          if (itm?.id === edg?.source) {
            return { ...itm, to: [...itm.to, edg?.target] };
          }
          return itm;
        });
      });
      const data = {
        identifier: values?.identifier,
        caseTypeId: values?.caseType,
        name: values?.name,
        edges,
        nodes,
        flowStructure,
      };

      try {
        const res = await statusFlowApi.update(flowID, { data });
        if (res?.status === 200) {
          toast.success(res?.data || 'Flow Updated Successfully');
          navigate('/erp/settings/status-flow');
        }
      } catch (error) {
        logger(error);
        toast.error(error?.response?.data?.message);
      }
    },
  });

  const getAllCaseTypes = async () => {
    try {
      const res = await casesApi.getCaseTypes();
      setAllCaseTypes(res?.data || []);
    } catch (error) {
      logger(error);
      setAllCaseTypes([]);
    }
  };

  const getById = async () => {
    try {
      const res = await statusFlowApi.getById(flowID);
      setFlowData(res?.data);
      setNodes(res?.data?.nodes);
      setEdges(res?.data?.edges);
    } catch (error) {
      logger(error);
      setFlowData({});
    }
  };

  useEffect(() => {
    getById();
    getAllCaseTypes();
  }, []);
  return (
    <>
      <ReactFlowProvider>
        <Grid container sx={{ height: '100%' }} spacing={2}>
          <Grid item xs={8} className={classes.flowRoot} ref={reactFlowWrapper}>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={handleConnect}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              onInit={setReactFlowInstance}
              onDrop={onDrop}
              onDragOver={onDragOver}>
              <Controls />
              <MiniMap />
              <Background gap={12} size={1} />
            </ReactFlow>
          </Grid>
          <Grid item xs={4}>
            <AllStatusNodes />
            <TextField
              label="Identifier"
              placeholder="Enter identifier"
              name="identifier"
              value={formik?.values?.identifier}
              onChange={formik?.handleChange}
              fullWidth
              sx={{ mt: 2 }}
              error={
                Boolean(formik?.touched?.identifier) &&
                Boolean(formik?.errors?.identifier)
              }
              helperText={
                formik?.touched?.identifier && formik?.errors?.identifier
              }
            />
            <TextField
              label="Name"
              placeholder="Enter name"
              name="name"
              value={formik?.values?.name}
              onChange={formik?.handleChange}
              fullWidth
              sx={{ mt: 2 }}
              error={
                Boolean(formik?.touched?.name) && Boolean(formik?.errors?.name)
              }
              helperText={formik?.touched?.name && formik?.errors?.name}
            />
            <TextField
              id="outlined-select-currency"
              select
              label="Select"
              value={formik?.values?.caseType}
              onChange={(e) =>
                formik?.setFieldValue('caseType', e.target.value)
              }
              variant="outlined"
              fullWidth
              sx={{ mt: 2 }}
              error={
                Boolean(formik?.touched?.caseType) &&
                Boolean(formik?.errors?.caseType)
              }
              helperText={
                formik?.touched?.caseType && formik?.errors?.caseType
              }>
              {allCaseTypes.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <div className={classes.btnContainer}>
              <button
                type="button"
                className={classes.btn}
                onClick={() => navigate('/erp/settings/status-flow')}>
                Cancel
              </button>
              <button
                type="button"
                className={`${classes.btn} ${classes.btnRed}`}
                onClick={() => formik.handleSubmit()}>
                Update
              </button>
            </div>
          </Grid>
        </Grid>
      </ReactFlowProvider>
    </>
  );
};

export default StatusFlowEdit;
