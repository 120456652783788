/* eslint-disable react/no-array-index-key */
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    btn: {
      width: '47px',
      height: '40px',
      padding: 0,
      minWidth: 'initial',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '4px',
      color: '#fff',
      marginLeft: '13px',
      '& .MuiButton-iconSizeMedium': {
        margin: '0',
      },
    },
    clip: {
      display: 'inline-block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '10ch',
    },
    textFieldSelect: {
      width: '230px',
      marginLeft: '13px',
    },
    textFieldSelectRoot: {
      borderRadius: '4px',
      height: '38px',
    },
    tabButton: {
      width: '181px',
    },
    cardRoot: {
      borderRadius: '4px',
    },
    tabs: {
      '& .MuiTab-root': {
        backgroundColor: 'transparent',
        minWidth: '120px',
      },
      '& .Mui-selected': {
        backgroundColor: '#231F20',
        color: '#fff',
      },
    },
    tableHead: {
      '& .MuiTableCell-root': {
        textTransform: 'capitalize',
      },
    },
    tableRow: {
      textDecoration: 'none',
      '& :hover': {
        cursor: 'pointer',
      },
      '& .ticketStatusTrue': {
        backgroundColor: 'rgba(7, 83, 0, 0.16)',
        color: '#1D7A15',
        padding: '5px',
        fontWeight: 'Bold',
      },
      '& .ticketStatusFalse': {
        background: '#DEEBFF',
        color: '#3375D7',
        padding: '5px',
        fontWeight: 'Bold',
      },
    },
    priorityComponent: {
      height: '30px',
      background: 'rgba(189,189,189,0.1)',
      color: '#000000aa',
      width: '100px',
      display: 'flex',
      alignItems: 'center',
      gap: '6px',
      textTransform: 'capitalize',
      justifyContent: 'center',
    },
    loaderTable: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: '2222',
    },
  })
);

type CustomBodyProps = {
  rows: any;
  columns: any;
  editBaseRoute?: string;
  onRowClick?: any;
};
const CustomTableBody = ({
  rows,
  columns,
  editBaseRoute,
  onRowClick = () => {},
}: CustomBodyProps) => {
  const classes = useStyles();
  const location = useLocation();
  return (
    <TableBody style={{ position: 'relative' }}>
      {rows?.length ? (
        rows?.map((row: any) => (
          <TableRow
            {...(editBaseRoute !== undefined
              ? {
                  component: Link,
                  to: `${editBaseRoute || location?.pathname}/${row?.caseKey}`,
                }
              : {
                  onClick: () => {
                    onRowClick(row);
                  },
                })}
            hover
            className={classes.tableRow}
            key={row?.id}>
            {columns?.map((column: any, index: any) => (
              <TableCell
                key={`${row?.id}-${row[column.id]}-${index}`}
                align="left"
                size="small">
                {column?.Cell
                  ? column?.Cell(row[column.id], row)
                  : row[column?.id] || '-'}
              </TableCell>
            ))}
          </TableRow>
        ))
      ) : (
        <TableRow selected>
          <TableCell
            colSpan={columns?.length || 12}
            sx={{ textAlign: 'center' }}>
            No Data Found
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

export default CustomTableBody;
