import { isUndefined } from 'lodash';
import axios, { getRequest, patchRequest } from 'src/utils/axios';
import logger from 'src/utils/logger';
import parse from 'src/utils/parse';

class EmployeesApi {
  async create(
    payload: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_CASES}/user`, payload)
        .then((response) => {
          logger(response, 'inside');
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          logger(error, 'errors');
          reject(error);
        });
    });
  }

  async getById(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_CASES}/user/${id}`,
        successFunction,
        errorFunction,
      };
      getRequest(data);
    });
  }

  async updateById(id: string, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_CASES}/user/${id}`,
        payload,
        successFunction,
        errorFunction,
      };
      patchRequest(data);
    });
  }

  async getAll(
    page: number,
    limit: number,
    search:any,
    isActive: boolean,
    columns?: any,
    sort?: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const payload = {
        page,
        pageSize: limit,
        search,
        isActive,
        column: columns,
        sort: sort === 'asc' ? '1' : '-1'
      };
      if (columns === '' || columns === undefined) {
        delete payload.column;
        delete payload.sort;
      }
      if (sort === '' || sort === undefined) {
        delete payload.sort;
      }
      if (search === '') {
        delete payload.search;
      }
      if (isActive === null) {
        delete payload.isActive;
      }
      axios
        .get(`${process.env.REACT_APP_CASES}/user`, { params: payload })
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const res = response.data;
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async bulkDeactivate(checkedIds: Array<number>): Promise<any> {
    return new Promise((resolve, reject) => {
      const payload: any = {
        id: checkedIds,
      };

      axios
        .patch(
          `${process.env.REACT_APP_INTERNAL_USER_URL}/users/bulk-deactivate?isExternal=false`,
          payload
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            // const res = response.data;
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error('internal Server Error'));
        });
    });
  }

  async updateStatusById(id: number, status: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${process.env.REACT_APP_CASES}/user/${id}/${status}`,
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async changePassword(newPass: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const payload = {
        password: newPass,
      };
      axios
        .patch(
          `${process.env.REACT_APP_INTERNAL_USER_URL}/password/change`,
          payload
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export const employeesApi = new EmployeesApi();
