import React, { useState, useEffect, useRef } from 'react';
import { TextField, Box, IconButton, Link } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import clsx from 'clsx';

interface Input {
  id: number;
  value: string;
  label: string;
}

interface MultiFileLinkInputCaseProps {
  inputs: Input[];
  onAddInput: () => void;
  onRemoveInput: (id: number) => void;
  onInputChange: (
    id: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onLabelChange: (
    id: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  placeholder?: string;
  labelPlaceholder?: string;
  mainClassName?: string;
  inputClassName?: string;
  labelClassName?: string;
  disabled?: boolean;
}

const MultiFileLinkInputCase: React.FC<MultiFileLinkInputCaseProps> = ({
  inputs,
  onAddInput,
  onRemoveInput,
  onInputChange,
  onLabelChange,
  placeholder,
  labelPlaceholder,
  mainClassName,
  inputClassName,
  labelClassName,
  disabled,
}) => {
  const [isValid, setIsValid] = useState<{ [key: number]: boolean }>({});
  const containerRef = useRef<HTMLDivElement>(null);
  const [isStacked, setIsStacked] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setIsStacked(containerRef.current.offsetWidth < 600);
      }
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleInputChange = (
    id: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.target;
    const isEntryValid = value.startsWith('https://');

    setIsValid((prevIsValid) => ({
      ...prevIsValid,
      [id]: isEntryValid,
    }));

    onInputChange(id, event);
  };

  const renderInputRow = (input: Input, isLast: boolean) => (
    <Box
      key={input.id}
      display="flex"
      flexDirection={isStacked ? 'row' : 'row'}
      alignItems={isStacked ? 'flex-start' : 'center'}
      marginBottom={2}
      style={{
        marginTop: '8px',
        padding: !isLast && '6px 10px 1px',
        backgroundColor: !isLast && 'rgba(237, 237, 237, 0.2)',
        borderRadius: '5px',
        marginBottom: '0px',
      }}>
      {isLast && (
        <TextField
          className={clsx('labelClassName', labelClassName)}
          variant="outlined"
          value={input.label}
          onChange={(e) => onLabelChange(input.id, e)}
          style={{
            marginRight: isStacked ? 0 : 10,
            marginBottom: isStacked ? 5 : 0,
            flex: 1,
          }}
          placeholder={labelPlaceholder}
          disabled={disabled}
          InputProps={{
            sx: {
              height: '25px !important',
              '& input': {
                fontSize: '12px',
                height: 'auto !important',
              },
            },
          }}
          // fullWidth={isStacked}
        />
      )}
      {isLast ? (
        <TextField
          className={clsx('inputClassName', inputClassName)}
          variant="outlined"
          value={input.value}
          onChange={(e) => handleInputChange(input.id, e)}
          style={{
            marginRight: isStacked ? 0 : 10,
            marginBottom: isStacked ? 10 : 0,
            flex: 1,
          }}
          placeholder={placeholder}
          error={isValid[input.id] === false && input.value !== ''}
          helperText={
            isValid[input.id] === false && input.value !== ''
              ? 'Please enter a valid and secure URL'
              : ''
          }
          disabled={disabled}
          InputProps={{
            sx: {
              height: '25px !important',
              '& input': {
                fontSize: '12px',
                height: 'auto !important',
              },
            },
          }}
          // fullWidth={isStacked}
        />
      ) : (
        <Link
          href={input.value}
          target="_blank"
          rel="noopener noreferrer"
          style={{ marginRight: 10, flex: 1, fontSize: '14px' }}>
          {input.label ? `${input.label}` : input.value}
        </Link>
      )}
      <Box display="flex" flexDirection={isStacked ? 'row' : 'column'}>
        {isLast && (
          <IconButton
            color="primary"
            onClick={onAddInput}
            disabled={!isValid[input.id]}
            title="Add New"
            style={{
              marginRight: isStacked ? 10 : 0,
              marginTop: isStacked ? 3 : 0,
              height: '25px',
            }}>
            <AddIcon sx={{ fontSize: 14 }} />
          </IconButton>
        )}
        {!isLast && inputs.length > 1 && (
          <IconButton
            color="secondary"
            title="Remove Attachment"
            onClick={() => onRemoveInput(input.id)}
            style={{
              height: '25px',
            }}>
            <ClearIcon sx={{ fontSize: 14 }} />
          </IconButton>
        )}
      </Box>
    </Box>
  );

  return (
    <Box className={mainClassName} ref={containerRef}>
      {inputs.map((input, index) =>
        renderInputRow(input, index === inputs.length - 1)
      )}
    </Box>
  );
};

export default MultiFileLinkInputCase;
