import { Box } from '@material-ui/core';
import { Outlet } from 'react-router';

const RolesLayout = () => (
  <Box padding={3}>
    <Outlet />
  </Box>
);

export default RolesLayout;
