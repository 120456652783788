import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { createContext, useEffect, useReducer } from 'react';
import { authApi } from '../api/authApi';
import { userApi } from '../api/userApi';
import type { User } from '../types/user';
import { permissionsApi } from 'src/api/permissions';
import toast from 'react-hot-toast';

interface State {
  isFirstTime:boolean;
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

interface AuthContextValue extends State {
  platform: 'JWT';
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: () => Promise<void>;
  resetIsFirstTime: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type IsFirstTimeAction = {
  type: 'IS_FIRST_TIME';
  payload: {
    isFirstTime: boolean;
  };
};

type Action = InitializeAction | LoginAction | LogoutAction | RegisterAction | IsFirstTimeAction;

const initialState: State = {
  isFirstTime: false,
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  IS_FIRST_TIME: (state:State, action:IsFirstTimeAction):State => {
    const { isFirstTime } = action.payload;
    return { ...state, isFirstTime };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state: State, action: RegisterAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state: State, action: Action): State => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  resetIsFirstTime: () => Promise.resolve(),
});

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const currentTime = Date.now() / 1000;
  const decoded: any = jwtDecode(accessToken);

  if (decoded?.exp <= currentTime) {
    // Token has expired
    localStorage.removeItem('accessToken');
    return false;
  }

  return true;
};

const setSession = (data: any) => {
  if (!data) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  } else {
    const accessToken = data?.accessToken;
    const refreshToken = data?.refreshToken;
    if (accessToken || refreshToken) {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
    }
  }
};

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    dispatch({
      type: 'INITIALIZE',
      payload: {
        isAuthenticated: false,
        user: null,
      },
    });
    const initialize = async (): Promise<void> => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const refreshToken = window.localStorage.getItem('refreshToken');

        if (isValidToken(accessToken)) {
          const user = await userApi.getByKeycloakId();
          const rolesArr = user?.role?.map((roleObj) => roleObj?.id);
          const arrayIdsString = rolesArr.join(',');
          const permissions = await permissionsApi.getPermissionsByRoleId(arrayIdsString);
          const userObj = await authApi.me(accessToken, permissions?.data);
          await dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: userObj,
            },
          });
        } else if (refreshToken && !isValidToken(accessToken)) {
          try {
            const keycloakData = await authApi.refreshSession(refreshToken);
            if (keycloakData) {
              setSession(keycloakData);
            }
          } catch (error) {
            toast.error('Session expired, Please login again!');
            localStorage.removeItem('refreshToken');
            window.location.href = '/erp/login';
          }
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    setTimeout(() => {
      initialize();
    }, 800);
  }, []);

  const login = async (email: string, password: string) => {
    let keycloakData;
    try {
      // Step 1: Login with email and password
      keycloakData = await authApi.login({ email, password });

      // Step 2: Set session if login is successful
      if (keycloakData) {
        setSession(keycloakData);

        // Step 3: Get user information
        const user = await userApi.getByKeycloakId();
        const rolesArr = user?.role?.map((roleObj) => roleObj?.id);
        const arrayIdsString = rolesArr.join(',');
        // Step 4: Get user permissions
        const permissions = await permissionsApi.getPermissionsByRoleId(arrayIdsString);

        // Step 5: Get logged-in user information
        const loggedUser = await authApi.me(keycloakData?.accessToken, permissions?.data);

        // Step 6: Store user data in local storage
        localStorage.setItem('user', JSON.stringify(user));

        // Step 7: Dispatch user data after successful login
        dispatch({
          type: 'LOGIN',
          payload: { user: loggedUser },
        });
      }
    } catch (error) {
      if (error?.response?.status === 403) {
        // Handle unauthorized access
        // window.location.href = '/reset-password';
        localStorage.clear();
        localStorage.setItem('accessTokenForReset', keycloakData?.accessToken);
        dispatch({
          type: 'IS_FIRST_TIME',
          payload: { isFirstTime: true },
        });
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const logout = async (): Promise<void> => {
    const keysToRemove = ['accessToken', 'refreshToken'];
    keysToRemove.forEach((key) => {
      localStorage.removeItem(key);
    });
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (): Promise<void> => {
    await authApi.register();
  };
  const resetIsFirstTime = async (): Promise<void> => {
    dispatch({
      type: 'IS_FIRST_TIME',
      payload: {
        isFirstTime: false,
      }
    });
  };
  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        register,
        resetIsFirstTime
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
