/* eslint-disable prefer-rest-params */
import { isUndefined } from 'lodash';
import axios from 'src/utils/axios';
import parse from 'src/utils/parse';

type CommentPayloadType = {
  itsmId: string,
  description: string,
  isExternal?: boolean,
  attachmentPath: Array<string>,
};

class RequestApi {
  async getAllRequests(args: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_ITSM_URL}/request/all?${args}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getByKeyOrId(keyOrId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_ITSM_URL}/request/${keyOrId}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async updateStatus(id: string, status: number): Promise<any> {
    return new Promise((resolve, reject) => {
      const payload = {
        status
      };
      axios
        .patch(`${process.env.REACT_APP_ITSM_URL}/request/${id}`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('Internal Server Error'));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async createComment(payload: CommentPayloadType): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_ITSM_URL}/comment`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('Internal Server Errorr'));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async editComment(commentId: string, payload: CommentPayloadType): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${process.env.REACT_APP_ITSM_URL}/comment?commentId=${commentId}`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async deleteComment(itsmId: string, commentId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${process.env.REACT_APP_ITSM_URL}/comment/${itsmId}/delete/${commentId}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async advanceSearch(args: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_ITSM_URL}/request/advance-search?${args}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getAllCategories(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_ITSM_URL}/auto-complete/categories`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getAllSubCategories(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_ITSM_URL}/auto-complete/sub-category`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export const requestApi = new RequestApi();
