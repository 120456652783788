import { useEffect, useState } from 'react';
import ConsultingUnits from './units/ListViewUnits';
import ConsultingLogs from './ViewLogs';
import SearchBar from 'src/components/widgets/searchBar/SearchBar';
import RestartAltIcon from '@material-ui/icons/RestartAlt';
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import useSettings from 'src/hooks/useSettings';
import { consultingApi } from 'src/api/consultingApi';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import moment from 'moment';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    btn: {
      width: '47px',
      height: '30px',
      padding: 0,
      minWidth: 'initial',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '4px',
      color: '#fff',
      marginLeft: 5,
      '& .MuiButton-iconSizeMedium': {
        margin: '0',
      },
    },
    card: {
      height: '100%',
      padding: 14,
      background: 'white',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      borderRadius: 4,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    cardInner: {
      textAlign: 'center',
      borderRight: '1px solid #E0E0E0',
    },
    cardTitle: {
      fontSize: 47,
      textAlign: 'right',
      fontWeight: 700,
      margin: 0,
      lineHeight: 1,
      '& span': {
        fontSize: 12,
        fontWeight: 500,
        display: 'block',
        color: '#000000',
      },
    },
    cardBottom: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'end',
    },
    cardDuration: {
      margin: 0,
      fontSize: 12,
      lineHeight: 1.1,
      fontWeight: 500,
    },

    cardDescription: {
      color: '#000',
      fontSize: 14,
      textAlign: 'left',
      fontWeight: 600,
      margin: 0,
    },
  })
);
function Consulting(props: any) {
  const { hiddenColumns } = props;
  const classes = useStyles();
  const [search, setSearch] = useState<any>(
    JSON.parse(localStorage.getItem('consultingUnitsPg'))?.consultingSearch ||
      ''
  );
  const [reset, setReset] = useState<any>();
  const [counters, setCounters] = useState<any>({});
  const [updateFlag, setUpdateFlag] = useState<boolean>(false);
  const [projectCounters, setProjectCounters] = useState<any>([]);

  const { organizationID }: any = useParams();
  const { settings } = useSettings();
  const handleSearch = (val: any) => {
    setSearch(val);
  };
  const handleReset = () => {
    setSearch('');
    setReset(!reset);
  };

  const getCuCounters = async () => {
    try {
      let response = await consultingApi.getCuCounters(
        `orgId=${organizationID}`
      );
      response = response?.data;
      setCounters(response);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const getProjectCounters = async () => {
    try {
      let response = await consultingApi.getProjectCounters(
        `orgId=${organizationID}`
      );
      response = response?.data;
      setProjectCounters(response);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const triggerUpdate = () => {
    setUpdateFlag(!updateFlag);
  };
  useEffect(() => {
    getCuCounters();
    getProjectCounters();
  }, [updateFlag]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 4,
        }}>
        <Container
          maxWidth={settings.compact ? 'xl' : false}
          className={
            hiddenColumns?.customCss === 'customCss' ? 'customCss' : ''
          }>
          <Grid container alignItems="flex-start">
            <Grid item xs={12} md={6}>
              <Typography color="textPrimary" sx={{ mb: 1 }} variant="h3">
                Consulting Units
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
                <SearchBar
                  onSearch={handleSearch}
                  value={search}
                  // anyState={autoRefresh !== 0}
                />
              <Button
                variant="contained"
                color="secondary"
                startIcon={<RestartAltIcon />}
                className={classes.btn}
                onClick={handleReset}
              />
            </Grid>
          </Grid>
          <div>
            <Grid container>
              <Grid item xs={12} md={6} lg={3} xl={2}>
                <div className={classes.card}>
                  <p className={classes.cardDescription}>Available CU</p>
                  <h1
                    className={classes.cardTitle}
                    style={{ color: '#50CD89' }}>
                    {counters.totalCuQuantity}
                  </h1>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={2} pl={2}>
                <div className={classes.card}>
                  <p className={classes.cardDescription}>Consumed CU</p>
                  <div className={classes.cardBottom}>
                     <p className={classes.cardDuration}>
                      Duration: <br />
                      {`${moment(counters?.startDate).format('MM-DD')} - ${moment(counters?.endDates).format('MM-DD')}`}
                    </p>
                  <h1
                    className={classes.cardTitle}
                    style={{ color: '#3E97FF' }}>
                    {counters.totalCuConsumed}
                  </h1>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={6} xl={8} pl={2}>
                <div className={classes.card}>
                  <p className={classes.cardDescription}>Available CUs</p>
                  <div
                    className={classes.cardBottom}
                    style={{ justifyContent: 'space-around' }}>
                    {/* <h1
                      className={classes.cardTitle}
                      style={{ color: '#BBBD53' }}>
                      <span>{null}</span>
                      {null}
                    </h1> */}

                    {projectCounters &&
                      projectCounters.map((value: any) => (
                        <h1
                          className={classes.cardTitle}
                          style={{ color: '#BBBD53', textAlign: 'center' }}>
                          <span>{value?.project}</span>
                          {value?.availableHours}
                        </h1>
                      ))}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </Box>
      <ConsultingUnits
        searchQuery={search}
        updateReset={reset}
        updateFlag={updateFlag}
        triggerUpdate={triggerUpdate} // this function recall all the recall all apis
      />

      <ConsultingLogs
        searchQuery={search}
        updateReset={reset}
        updateFlag={updateFlag}
        triggerUpdate={triggerUpdate} // this function recall all the recall all apis
      />
    </>
  );
}

export default Consulting;
