import React, {
  useEffect,
  useState,
  CSSProperties,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  useDropzone,
  DropzoneRootProps,
  DropzoneInputProps,
} from 'react-dropzone';
// import axios from 'axios'; // Import Axios
import axiosInstance from 'src/utils/axios';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import toast from 'react-hot-toast';
import CancelIcon from '@material-ui/icons/Cancel';

interface FileUploadDragDropProps {
  // Define any props your component might accept here
  setFilePath?: any;
  filePath?: any;
  key?: number;
}

// Define types for styles as CSSProperties
const thumbsContainer: CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: 16,
};
const crossBtn: CSSProperties = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  cursor: 'pointer',
};
const thumb: CSSProperties = {
  display: 'flex',
  borderRadius: 4,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  height: '190px',
  width: '190px',
  padding: 10,
  margin: 5,
  boxSizing: 'border-box',
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
};

const thumbInner: CSSProperties = {
  // display: 'flex',
  // minWidth: 0,
  // overflow: 'hidden',
};

const img: CSSProperties = {
  display: 'block',
  maxWidth: '190px',
  height: '190px',
  objectFit: 'contain',
};

const FileUploadDragDrop = forwardRef(
  (props: FileUploadDragDropProps, ref: any) => {
    const { setFilePath, filePath } = props;

    const [files, setFiles] = useState<any>([]);
    const isPdfTxtDocFile = (fileName) =>
      fileName.toLowerCase().endsWith('.pdf') ||
      fileName.toLowerCase().endsWith('.txt') ||
      fileName.toLowerCase().endsWith('.doc') ||
      fileName.toLowerCase().endsWith('.docx') ||
      fileName.toLowerCase().endsWith('.xls') ||
      fileName.toLowerCase().endsWith('.xlsx') ||
      fileName.toLowerCase().endsWith('.tsv') ||
      fileName.toLowerCase().endsWith('.csv');
    const uploadImage = (file) => {
      const formData = new FormData();
      formData.append('file', file);

      axiosInstance
        .post(`${process.env.REACT_APP_IMAGE_UPLOAD_URL}/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
          },
        })
        .then((response) => {
          if (response?.status === 201) {
            toast.success('Attachments uploaded successfully');
            setFilePath((prev) => [...prev, `${response?.data?.blob_url}`]);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    };
    function customValidator(file) {
      if (file.size >= 32097152) {
        return {
          code: 'file_size_too_large',
          message: 'File size is greater than 32MB',
        };
      }
      if (
        files.find(
          (itm) =>
            itm?.name === file?.name &&
            itm?.path === file?.path &&
            itm?.type === file?.type &&
            itm?.size === file?.size
        )
      ) {
        return {
          code: 'file_already_present',
          message: 'File Already Present',
        };
      }
      return null;
    }
    const { getRootProps, getInputProps, fileRejections } = useDropzone({
      accept:
        'image/jpeg, image/jpg, image/png, .pdf, .doc, .txt, .docx, .gif, .bmp, .tiff, .webp, .svg, .ico, .txt, .xls, .xlsx, .csv, .tsv',
      onDrop: (acceptedFiles) => {
        const filesWithPreviews = acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            id: file?.size,
          })
        );
        setFiles([...files, ...filesWithPreviews]);
        acceptedFiles.forEach((file) => {
          uploadImage(file);
        });
      },
      validator: customValidator,
    });
    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
      <>
        <div key={file.name}>
          <h6 style={{ margin: '10px 0px 0px 0px' }}>
            Rejected files : {file?.name}
          </h6>
          {errors.map((e) => (
            <p key={e.code} style={{ margin: '0px' }}>
              {e.message}
            </p>
          ))}
        </div>
      </>
    ));
    useEffect(
      () =>
        // eslint-disable-next-line implicit-arrow-linebreak
        () =>
          files.forEach((file) => URL.revokeObjectURL(file.preview)),
      [files, filePath]
    );

    const removeImage = (file: any, indexToRemove: number) => {
      const newFiles = files.filter((f) => f !== file);
      setFiles(newFiles);
      const newFilePath = [
        ...filePath.slice(0, indexToRemove),
        ...filePath.slice(indexToRemove + 1),
      ];

      setFilePath(newFilePath);
      // URL.revokeObjectURL(file.preview);
    };

    // Clear files function - will be called from the parent
    const clearFiles = () => {
      setFiles([]);
      setFilePath([]);
    };

    // Expose clearFiles method to parent via the ref
    useImperativeHandle(ref, () => ({
      clearFiles,
    }));

    return (
      <section className="fileDropZoneRoot">
        <div
          {...(getRootProps({ className: 'dropzone' }) as DropzoneRootProps)}
          style={{ cursor: 'pointer' }}>
          <input {...(getInputProps() as DropzoneInputProps)} />
          <p style={{ fontSize: 14 }}>
            Drag & Drop, paste, or browse to upload a file
          </p>
        </div>
        <aside style={thumbsContainer}>
          {fileRejections?.length !== 0 && (
            <>
              <div style={{ display: 'block', marginBottom: '12px' }}>
                <h4 style={{ margin: '0px', color: '#c22027' }}>
                  Rejected files
                </h4>
                {fileRejectionItems}
              </div>
            </>
          )}
          {files.map((file, index) => (
            <div style={thumb} key={file?.path}>
              <div style={thumbInner}>
                {isPdfTxtDocFile(file.name) ? (
                  <InsertDriveFileIcon sx={{ fontSize: '50px' }} />
                ) : (
                  <img
                    src={file.preview}
                    alt=""
                    style={img}
                    // Revoke data URI after the image is loaded
                    onLoad={() => {
                      URL.revokeObjectURL(file.preview);
                    }}
                  />
                )}
              </div>
              <CancelIcon
                onClick={() => removeImage(file, index)}
                style={crossBtn}
              />
            </div>
          ))}
        </aside>
      </section>
    );
  }
);

export default FileUploadDragDrop;
