/* eslint-disable prefer-rest-params */
import { isUndefined } from 'lodash';
import axios from 'src/utils/axios';
import parse from 'src/utils/parse';

class ConsultingRoleApi {
  async getAll(queryParams: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CU_URL}/cu-role/findAll?${queryParams}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async create(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_CU_URL}/cu-role/create`, payload)
        .then((response) => {
          resolve(parse(response));
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async bulkUpdate(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_CU_URL}/cu-role/bulkInsert`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // async getOrganizationId(): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(`${process.env.REACT_APP_CU_URL}/cu/orgAutoComplete`)
  //       .then((response) => {
  //         response = parse(response);
  //         if (!isUndefined(response?.data)) {
  //           resolve(response);
  //         }
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // }

  // async getClients(): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(`${process.env.REACT_APP_CU_URL}/cu/clients`)
  //       .then((response) => {
  //         response = parse(response);
  //         if (!isUndefined(response?.data)) {
  //           resolve(response);
  //         }
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // }

  async getAllRoles(
    organizationId: string,
    page: number,
    limit: number
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CU_URL}/cu-role/findAll`, {
          params: {
            orgId: organizationId,
            page,
            limit,
          },
        })
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getCURolesAutoComplete(organizationId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CU_URL}/cu-role/auto-complete`, {
          params: {
            orgId: organizationId,
          },
        })
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async cuProjectsAutocomplete(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.REACT_APP_CU_URL}/cu-role/autoCompleteProjectFromCULog`
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export const consultingRoleApi = new ConsultingRoleApi();
