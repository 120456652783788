/* eslint-disable prefer-rest-params */
import { isUndefined } from 'lodash';
import axios from 'src/utils/axios';
import parse from 'src/utils/parse';

class ConsultingApi {
  // this funciton will fetch consulting units and worklogs with same apis on the base of eventType
  async getAllCunsulting(args: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CU_URL}/cu-log/findAll?${args}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getCuCounters(args: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CU_URL}/cu-log/getCuCounters?${args}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getProjectCounters(args: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CU_URL}/cu-log/allProjectCounter?${args}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async getAllCunsultingUnits(args: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CU_URL}/cu?${args}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getById(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CU_URL}/cu/${id}`)
        .then((response) => {
          response = parse(response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
    })
  }

  async edit(id: string, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${process.env.REACT_APP_CU_URL}/cu/${id}`, payload)
        .then((response) => {
          response = parse(response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
    })
  }

  async getAllCunsultingUnitLogs(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CU_URL}/cu-log/findAll`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getOrganizationId(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CU_URL}/cu/orgAutoComplete`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getClients(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CU_URL}/cu/clients`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getCURolesAutoComplete(organizationId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CU_URL}/cu-role/auto-complete`, {
          params: {
            orgId: organizationId
          }
        })
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getCUEmployees(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CU_URL}/cu/employees`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async createConsultingUnit(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_CU_URL}/cu`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async createWorkLog(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_CU_URL}/cu-log`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
  }

  async createUnitOrder(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_CU_URL}/cu-log/list`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  // https://be-consulting-unit-it22-support-dev.apps.alpha.it22.cloud/cu-log/list/byId

  async editUnitOrder(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${process.env.REACT_APP_CU_URL}/cu-log/list/byId`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getCuProjectsAutoComplete(orgId): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CU_URL}/cu-log/projectAutoComplete`, {
          params: {
            orgId
          }
        })
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getOrderNumbersAutoComplete(projectName: string | null, organizationId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CU_URL}/cu-log/orderNumberAutoComplete`, {
          params: {
            project: projectName,
            orgId: organizationId
          }
        })
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async editWorkLog(payload: any, workLogId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${process.env.REACT_APP_CU_URL}/cu-log/${workLogId}`, payload)
        .then((response) => {
          response = parse(response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
    })
  }


}

export const consultingApi = new ConsultingApi();
