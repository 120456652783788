import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import SubDepartmentGraph from './SubDepartmentGraph';
import DepartmentGraph from './DepartmentGraph';

const useStyles = makeStyles<Theme>(
  () =>
    createStyles({
      btn: {
        padding: 0,
      },
      graphBox: {
        padding: 16,
        marginTop: '10px',
        background: 'white',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
      },
    }),
  {
    name: 'HourlyGraph',
  }
);
function HourlyGraph() {
  const classes = useStyles();

  // const getDepartmentData = async () => {
  //   // setDepartmentData(myData.DEPARTMENTDATA);
  //   try {
  //     const response = await okrApi.getOverallTaskSummary();
  //     setDepartmentData(response.data);
  //   } catch (error) {
  //     toast.error(error.message);
  //   }
  // };
  // useEffect(() => {
  //   getDepartmentData();
  // }, []);
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
      }}
      className={classes.table}>
      <Accordion>
        <AccordionSummary>Department Task Summary</AccordionSummary>
        <AccordionDetails>
          <DepartmentGraph />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>Sub-department Task Summary</AccordionSummary>
        <AccordionDetails>
          <SubDepartmentGraph />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default HourlyGraph;
