import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Formik } from 'formik';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { REGEX } from 'src/utils/constants';
import { userApi } from 'src/api/userApi';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import jwtDecode from 'jwt-decode';
import useAuth from 'src/hooks/useAuth';

// --------------------> START <--------------------
const PasswordResetAmplify: FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { resetIsFirstTime } = useAuth();
  const navigate = useNavigate();
  const itemsRef = useRef([]);
  const [show, setShow] = useState({
    newPassword: false,
    reEnterPassword: false,
  });

  const handleNewPassword = () => {
    setShow({ ...show, newPassword: !show.newPassword });
  };
  const handleReEnterPassword = () => {
    setShow({ ...show, reEnterPassword: !show.reEnterPassword });
  };

  const useStyles = makeStyles<Theme>(() => createStyles({
    formLogin: {
      display: 'flex',
      flexDirection: 'column',
      '& .MuiOutlinedInput-root': {
        borderRadius: '9px',
      },
      '& .MuiFormControl-root': {
        marginBottom: '18px',
      },
    },
    btn: {
      width: '180px',
      margin: '11px auto',
      display: 'block',
      borderRadius: '4px',
      height: '49px',
      border: 'none',
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, 6);
  }, []);

  return (
    <Formik
      initialValues={{
        newPassword: '',
        reEnterPassword: ''
      }}
      validationSchema={Yup.object().shape({
        newPassword: Yup.string().trim()
          .min(8, 'Minium 8 characters required')
          .max(20, 'Maximum 20 characters allowed')
          .matches(REGEX.password, 'At least one uppercase, one lowercase, one number & one special character allowed')
          .required('New Password is required'),
        reEnterPassword: Yup.string().trim().required('New Password Required')
          .test('Password match', 'Password not match', function (value) {
            // eslint-disable-next-line react/no-this-in-sfc
            return value === this.parent.newPassword;
          })
      })}
      onSubmit={async (
        values
      ): Promise<void> => {
        try {
          const accessToken = localStorage.getItem('accessTokenForReset');

          const handleRequest = async (id:string) => {
            const response = await userApi.resetPassword(id, values?.reEnterPassword);
            if (response?.status === 200) {
              toast.success('Password Reset Successfully');
              localStorage.removeItem('accessTokenForReset');
            }
          };
          if (accessToken) {
            const decode: any = jwtDecode(accessToken);
            handleRequest(decode?.sub);
            await resetIsFirstTime();
          } else if (searchParams.get('id')) {
            handleRequest(searchParams.get('id'));
            navigate('/erp/login');
          } else {
            toast.error('Reset Password Link Expired');
          }
        } catch (err) {
          console.error(err);
          if (err?.response?.status === 400) {
            toast.error(err?.response?.data?.message);
          }
        }
      }}
    >
      {({
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
        values,
      }): JSX.Element => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className={classes.formLogin}
        >
          <FormControl
            variant="outlined"
            sx={{ width: '500px' }}
          >
            <OutlinedInput
              id="outlined-adornment-password"
              type={show.newPassword ? 'text' : 'password'}
              value={values.newPassword}
              onChange={handleChange('newPassword')}
              placeholder="New password"
              error={Boolean(touched.newPassword && errors.newPassword)}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleNewPassword}
                    onMouseDown={handleNewPassword}
                    edge="end"
                  >
                    {show.newPassword ? (
                      <Visibility color="primary" />
                    ) : (
                      <VisibilityOff color="primary" />
                    )}
                  </IconButton>
                </InputAdornment>
              )}
            />
            <FormHelperText error={Boolean(touched.newPassword && errors.newPassword)}>{errors?.newPassword}</FormHelperText>
          </FormControl>
          <FormControl
            variant="outlined"
            sx={{ width: '500px' }}
          >
            <OutlinedInput
              id="outlined-adornment-password"
              type={show.reEnterPassword ? 'text' : 'password'}
              value={values.reEnterPassword}
              onChange={handleChange('reEnterPassword')}
              placeholder="Re-enter new password"
              error={Boolean(touched.reEnterPassword && errors.reEnterPassword)}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleReEnterPassword}
                    onMouseDown={handleReEnterPassword}
                    edge="end"
                  >
                    {show.reEnterPassword ? (
                      <Visibility color="primary" />
                    ) : (
                      <VisibilityOff color="primary" />
                    )}
                  </IconButton>
                </InputAdornment>
              )}
            />
            <FormHelperText error={Boolean(touched.reEnterPassword && errors.reEnterPassword)}>{errors?.reEnterPassword}</FormHelperText>
          </FormControl>
          <Button
            color="secondary"
            disabled={isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            className={classes.btn}
          >
            Confirm
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default PasswordResetAmplify;
