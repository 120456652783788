import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';
import toast from 'react-hot-toast'; // For notifications
import { leavesApi } from 'src/api/leavesApi'; // Assuming this is the API call to delete leave

interface RemoveLeaveDialogProps {
  open: boolean;
  onClose: () => void;
  leaveId: string | null; // ID of the leave to be removed
  fetchLeaveData: () => void; // Function to refetch leave data after removal
}

const RemoveLeaveDialog: React.FC<RemoveLeaveDialogProps> = ({
  open,
  onClose,
  leaveId,
  fetchLeaveData,
}) => {
  // Function to handle leave removal
  const handleRemoveLeave = async () => {
    try {
      if (leaveId) {
        const response = await leavesApi.deleteLeave(leaveId); // Assuming deleteLeave API exists
        if (response.status === 200) {
          toast.success('Leave successfully removed!');
          fetchLeaveData(); // Refetch leave data after successful removal
          onClose(); // Close the dialog after success
        } else {
          toast.error('Failed to remove leave. Please try again.');
        }
      }
    } catch (error) {
      console.error('Error removing leave:', error);
      toast.error('Error removing leave. Please try again.');
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Remove Leave</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to remove this leave? This action cannot be
          undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleRemoveLeave}
          color="secondary"
          variant="contained">
          Remove Leave
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveLeaveDialog;
