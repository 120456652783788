import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  Typography,
  Paper,
  Button,
  InputAdornment,
} from '@mui/material';
import { leavesApi } from 'src/api/leavesApi';
import toast from 'react-hot-toast';
import ConfirmationDialog from './ConfirmationDialog'; // Import the dialog

interface LeaveQuota {
  id: string;
  name: string;
  key: string;
  isActive: boolean;
  quotaInHours: number;
  unitInHours: number;
}

const GlobalLeavesQuota: React.FC = () => {
  const [leaveData, setLeaveData] = useState<LeaveQuota[]>([]);
  const [pto, setPto] = useState<number | string>('');
  const [uto, setUto] = useState<number | string>('');
  const [medical, setMedical] = useState<number | string>('');
  const [holidays, setHolidays] = useState<number | string>('');
  const [open, setOpen] = useState(false); // Dialog open state

  useEffect(() => {
    const fetchLeaveData = async () => {
      try {
        const response = await leavesApi.getLeaveTypes();
        const { data } = response;

        data.forEach((leave: LeaveQuota) => {
          const days = leave.quotaInHours / 8; // Convert hours to days

          switch (leave.key) {
            case 'paid':
              setPto(days); // Show days in the field
              break;
            case 'unpaid':
              setUto(days); // Show days in the field
              break;
            case 'medical':
              setMedical(days); // Show days in the field
              break;
            case 'holidays':
              setHolidays(days); // Show days in the field
              break;
            default:
              break;
          }
        });

        setLeaveData(data);
      } catch (error) {
        console.error('Error fetching leave data:', error);
        toast.error('Error fetching leave data');
      }
    };

    fetchLeaveData();
  }, []);

  const handleSave = async (updateLeaveQuota: boolean) => {
    const payload = leaveData.map((leave) => {
      let quotaInHours = 0;

      switch (leave.key) {
        case 'paid':
          quotaInHours = Number(pto) * 8; // Convert days back to hours
          break;
        case 'unpaid':
          quotaInHours = Number(uto) * 8; // Convert days back to hours
          break;
        case 'medical':
          quotaInHours = Number(medical) * 8; // Convert days back to hours
          break;
        case 'holidays':
          quotaInHours = Number(holidays) * 8; // Convert days back to hours
          break;
        default:
          break;
      }

      return {
        quotaInHours,
        id: leave.id,
      };
    });

    try {
      await leavesApi.updateLeaveTypes(updateLeaveQuota, payload);
      toast.success('Leave data successfully updated');
    } catch (error) {
      console.error('Error updating leave data:', error);
      toast.error('Error updating leave data');
    } finally {
      setOpen(false);
    }
  };

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  return (
    <>
      <Paper elevation={1} sx={{ padding: 4 }}>
        <Typography variant="subtitle1" gutterBottom>
          Add leaves quota for each type of leave
        </Typography>
        <Box component="form">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Leaves (PTO)"
                variant="outlined"
                value={pto}
                onChange={(e) => setPto(e.target.value)}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">days</InputAdornment>
                  ),
                  inputProps: { min: 0 },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Leaves (UTO)"
                variant="outlined"
                value={uto}
                onChange={(e) => setUto(e.target.value)}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">days</InputAdornment>
                  ),
                  inputProps: { min: 0 },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Medical Leaves"
                variant="outlined"
                value={medical}
                onChange={(e) => setMedical(e.target.value)}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">days</InputAdornment>
                  ),
                  inputProps: { min: 0 },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Public Holidays"
                variant="outlined"
                value={holidays}
                type="number"
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">days</InputAdornment>
                  ),
                  inputProps: { min: 0 },
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClickOpen}
          sx={{
            padding: '5px 50px',
          }}>
          Save
        </Button>
      </Box>

      <ConfirmationDialog
        open={open}
        onClose={handleClose}
        onSaveWithoutUpdating={() => handleSave(false)}
        onSaveAndUpdate={() => handleSave(true)}
      />
    </>
  );
};

export default GlobalLeavesQuota;
