import { Button } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import {
  fetchAllProducts,
  uploadCsvData,
} from 'src/store/slices/pimModuleSlice';
import { AppDispatch } from 'src/store/store';

interface FilePreview {
  file: File;
  preview: string;
}
interface CsvDropzoneProps {
  closeDialog: () => void;
}
function CsvDropzone({ closeDialog }: CsvDropzoneProps) {
  const [filePreviews, setFilePreviews] = useState<FilePreview[]>([]);
  const dispatch = useDispatch<AppDispatch>();
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFilePreviews: FilePreview[] = acceptedFiles.map((file) => ({
        file,
        preview: URL.createObjectURL(file),
      }));
      setFilePreviews([...filePreviews, ...newFilePreviews]);
    },
    [filePreviews]
  );
  const onDropRejected = useCallback(() => {
    // Show error toast for rejected files
    toast.error(`Cannot upload this file type`);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    accept: '.xlsx, .xls',
    onDrop,
    onDropRejected,
    multiple: false,
  });

  const removeFilePreview = (file: File) => {
    const updatedPreviews = filePreviews.filter((item) => item.file !== file);
    setFilePreviews(updatedPreviews);
  };

  const handleUpload = async () => {
    filePreviews.forEach(async (filePreview) => {
      const formData = new FormData();
      formData.append('file', filePreview.file);
      dispatch(uploadCsvData(formData)).then((res) => {
        if (res.payload.status === 202 || res.payload.status === 200) {
          toast.success('File uploaded successfully!');
          const PimPage = localStorage.getItem('pimPagePg');
          const jsonPimPage = JSON.parse(PimPage);
          const queryString = `?page=${jsonPimPage.pageNo}&pageSize=${jsonPimPage.pageLimit}`;
          dispatch(fetchAllProducts(queryString)).then(() => {
            closeDialog()
          })
        } else {
          const errorMessage = res?.payload?.response?.data?.message
          toast.error(errorMessage || 'Something went Wrong ');
          closeDialog()
        }
      });
      try {
        console.log('Form Data', filePreviews);

        // Handle response
      } catch (error) {
        console.log('Form Data ERROR', error);
      }
    });
  };

  return (
    <div>
      <div
        {...getRootProps()}
        style={{
          border: '2px solid #eee',
          borderRadius: '6px',
          padding: '20px'
        }}>
        <input {...getInputProps()} />
        <p>Drag & drop files here, or click to select files</p>
      </div>
      <small>Accepted Types: xls, xlsx</small>
      <div>
        {filePreviews.map(({ file }) => (
          <div key={file.name} style={{ gap: '10px' }}>
            <span>{file.name}</span>
            <Button
              variant="outlined"
              color="primary"
              type="button"
              onClick={() => removeFilePreview(file)}
              sx={{ height: '28px', marginLeft: '10px', marginTop: '10px' }}>
              Remove
            </Button>
          </div>
        ))}
      </div>
      <Button
        variant="contained"
        color="secondary"
        type="button"
        onClick={handleUpload}
        sx={{ height: '38px', marginTop: '10px' }}
        disabled={filePreviews.length === 0}>
        Upload
      </Button>
    </div>
  );
};

export default CsvDropzone;
