/* eslint-disable jsx-a11y/label-has-associated-control */
import {
    Autocomplete,
    Box,
    Button,
    Drawer,
    TextField,
    Typography,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import * as Yup from 'yup';
import { releasegApi } from 'src/api/release';
import { casesApi } from 'src/api/casesApi';
import dayjs from 'dayjs';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const useStyles = makeStyles<Theme>(() =>
    createStyles({
        CreateCase: {
            width: '40%',
            padding: '5px 20px ',
            zIndex: 1300,
            border: 'none',
            '& .setDateLabel': {
                'position': 'relative',
                '& label': {
                    'position': 'absolute',
                    'zIndex': '11',
                    'fontSize': '12px',
                    'left': '10px',
                    'top': '-10px',
                    'background': 'white'
                }
            },
            '& errorDate': {
                color: '#000',
                fontFamily: 'Quicksand, sans- serif',
                fontWeight: '400',
                fontSize: '0.75rem',
                lineHeight: '1.66',
                textAlign: 'left',
                marginTop: '3px',
                marginRight: '14px',
                marginBottom: '0',
                marginLeft: '14px',
            },
            '& .react-datepicker-wrapper': {
                width: '100%',
                '& input': {
                    border: 'solid 1px #b8bec8',
                    borderRadius: '5px',
                    fontFamily: 'Quicksand,sans-serif'
                },
            },
            '& .ticketBody': {
                height: '90vh',
                overflowY: 'auto',
                padding: '0 20px',
                '& input': {
                    borderRadius: '2px',
                },
            },
            '& h4': {
                background: '#C22027',
                color: '#fff',
                padding: '0 40px',
                margin: '-5px -20px 30px',
                height: '64px',
                display: 'flex',
                alignItems: 'center',
            },
            '& label': {
                letterSpacing: ' 0.15px',
                color: '#231F20',
                fontWeight: '600',
                display: 'block',
            },
            '& select, & .MuiAutocomplete-inputRoot, & input': {
                height: '38px',
                padding: '0 10px',
                lineHeight: '116.7%',
                color: '#696969',
                width: '100%',
                '& .MuiAutocomplete-input': {
                    padding: 0,
                },
            },
            '& .customField': {
                height: '38px',
                padding: '0 10px',
                lineHeight: '116.7%',
                color: '#696969',
                width: '100%',
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                '& .MuiChip-root': {
                    border: 0,
                    borderRadius: '4px',
                    margin: '2px 0',
                    background: 'rgb(241 240 240)',
                },
            },
            '& .labelsDataCss .MuiAutocomplete-inputRoot': {
                height: 'initial',
            },
            '& .ticketDropDown+.ticketDropDown': {
                marginTop: '15px',
            },
            '& .submitTicket ': {
                margin: '35px 0',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            },
            '& .submitTicket button+button': {
                marginLeft: '15px',
            },
        },
        datePicker: {
            '&:hover': {
                cursor: 'pointer',
            },
        },
    })
);
const CreateReleaseVersionModal: FC<any> = ({
    isOpen,
    addRelease,
    closeDrawer,
    getAllData,
    rowData
}: any) => {
    const { projectID }: any = useParams();
    const classes = useStyles();
    const [allProjects, setAllProjects] = useState<any>([]);
    const [allExternalUsers, setAllExternalUsers] = useState<any[]>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [duration, setDuration] = useState<number>(1); // Initialize with 1 week

    const getAllProjects = async () => {
        try {
            const response = await casesApi.getAllProjects();
            if (response?.status === 200) {
                setAllProjects(response?.data);
            }
        } catch (error) {
            toast.error(error.message);
            setAllProjects([]);
        }
    };

    const getAllExternalUsersAutoComplete = async () => {
        try {
            const res = await casesApi?.getAutoCompleteUser(false);
            setAllExternalUsers(res?.data);
        } catch (error) {
            if (error?.response?.status !== 401) {
                toast.error(error?.response?.data?.message);
                setAllExternalUsers([]);
            }
        }
    };

    const formik = useFormik({
        initialValues: {
            name: Object.keys(rowData).length > 0 ? rowData.name : '',
            startDate: Object.keys(rowData).length > 0 ? rowData.startDate.split('T')[0] : '',
            releaseDate: Object.keys(rowData).length > 0 ? rowData.releaseDate.split('T')[0] : '',
            user: Object.keys(rowData).length > 0 ? rowData.user : '',
            project: Object.keys(rowData).length > 0 ? rowData.project : '',
        },
        onSubmit: async (values, actions) => {

            try {

                if (Object.keys(rowData).length > 0) {
                    // Update existing data
                    const payload = {
                        ...values,
                        startDate: moment(values.startDate).format('YYYY-MM-DD'),
                        releaseDate: moment(values.releaseDate).format('YYYY-MM-DD'),
                    };
                    const response = await releasegApi.updateReleaseByProject(rowData.id, payload);
                    setIsSubmitting(true);

                    toast.success('Update Successfully');
                    closeDrawer();
                    actions.resetForm();
                    getAllData();

                } else {
                    // Create new data
                    const payload = {
                        ...values,

                        startDate: moment(values.startDate).format('YYYY-MM-DD'),
                        releaseDate: moment(values.releaseDate).format('YYYY-MM-DD'),

                    };
                    const response = await releasegApi.createReleasrByProject(payload);
                    setIsSubmitting(true);

                    toast.success('Created Successfully');
                    closeDrawer();
                    actions.resetForm();
                    getAllData();

                }
            } catch (error) {
                toast.error(error?.response?.data?.message);
            } finally {
                setIsSubmitting(false);
            }
        },
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .trim()
                .required('Value is required'),
            startDate: Yup.string()
                .trim()
                .required('Value is required'),
            releaseDate: Yup.string()
                .trim()
                .required('Value is required'),
            user: Yup.mixed()
                .required('Value is required')
                .test(
                    'is-string',
                    'Name is required',
                    value => typeof value === 'string' || (value && typeof value.name === 'string')
                ),
            project: Yup.mixed()
                .required('Value is required')
                .test(
                    'is-string',
                    'Project is required',
                    value => typeof value === 'string' || (value && typeof value.name === 'string')
                ),
        }),
    });



    useEffect(() => {
        getAllProjects();
        getAllExternalUsersAutoComplete();
    }, []);

    const handleCloseDrawer = (e: any) => {
        addRelease(false, e);
        formik.resetForm();
    };



    return (
        <div className="createTicket">
            <Drawer
                open={isOpen}
                anchor="right"
                onClose={handleCloseDrawer}
                classes={{ paper: classes.CreateCase }}>
                <Typography variant="h4" component="h4">
                    {Object.keys(rowData).length > 0 ? 'Update' : 'Create'} Release
                </Typography>
                <form noValidate onSubmit={formik.handleSubmit}>
                    <div className="ticketBody">
                        <div className="ticketDropDown">
                            <TextField
                                value={formik?.values?.name}
                                variant="outlined"
                                name="name"
                                fullWidth
                                onChange={formik?.handleChange}
                                inputProps={{ maxLength: 250 }}
                                placeholder="Name"
                                error={Boolean(formik?.touched?.name && formik?.errors?.name)}
                                helperText={formik?.touched?.name && formik?.errors?.name}
                            />
                        </div>
                        <div className="ticketDropDown">
                            <Autocomplete
                                id="user-name"
                                options={allExternalUsers || []}
                                value={formik?.values?.user || null}
                                onChange={(event: any, value: any) => {
                                    formik?.setFieldValue('user', value);
                                }}
                                getOptionLabel={(option) => option.name}
                                style={{ minWidth: 300 }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder={formik?.values?.user ? '' : 'user'}
                                        error={Boolean(formik?.touched?.user && formik?.errors?.user)}
                                        helperText={formik?.touched?.user && formik?.errors?.user}
                                    />
                                )}

                            />
                        </div>
                        <div className="ticketDropDown">


                            <div className='setDateLabel'>
                                <label className=''>Start Date</label>
                                <DatePicker
                                    label="Start Date"
                                    selected={formik.values.startDate ? new Date(formik.values.startDate) : null}
                                    onChange={(date) => formik.setFieldValue('startDate', date ? dayjs(date).format('YYYY-MM-DD') : '')}
                                    dateFormat="dd-MM-yyy"
                                    placeholderText="dd-MM-yyy"


                                />
                                {formik.errors.startDate && formik.touched.startDate ? (
                                    <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-10nl1tw-MuiFormHelperText-root">Value is required</p>
                                ) : null}
                            </div>
                        </div>
                        <div className="ticketDropDown">

                            <div className='setDateLabel'>
                                <label className=''>Release Date</label>

                                <DatePicker
                                    label="Start Date"
                                    selected={formik.values.releaseDate ? new Date(formik.values.releaseDate) : null}
                                    onChange={(date) => formik.setFieldValue('releaseDate', date ? dayjs(date).format('YYYY-MM-DD') : '')}
                                    dateFormat="dd-MM-yyy"
                                    placeholderText="dd-MM-yyy"


                                />
                                {formik.errors.releaseDate && formik.touched.releaseDate ? (
                                    <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-10nl1tw-MuiFormHelperText-root">Value is required</p>
                                ) : null}
                            </div>

                        </div>
                        <div className="ticketDropDown">
                            {/* <Autocomplete
                                id="project-name"
                                options={allProjects || []}
                                value={formik?.values?.project || null}
                                onChange={(event: any, value: any) => {
                                    formik?.setFieldValue('project', value);
                                }}
                                getOptionLabel={(option) => option.name}
                                style={{ minWidth: 300 }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder={formik?.values?.project ? '' : 'Project'}
                                        error={Boolean(formik?.touched?.project && formik?.errors?.project)}
                                        helperText={formik?.touched?.project && formik?.errors?.project}
                                    />
                                )}
                            /> */}


                            <Autocomplete
                                id="project-name"
                                options={allProjects || []}
                                value={formik?.values?.project || null}
                                onChange={(event: any, value: any) => {
                                    formik?.setFieldValue('project', value);
                                }}

                                getOptionLabel={(option) => typeof option === 'string' ? option : option.name}
                                style={{ minWidth: 300, maxHeight: '38px' }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder={formik?.values?.project ? '' : 'Project'}
                                        error={Boolean(
                                            formik.errors.project && formik.touched.project
                                        )}
                                        helperText={
                                            formik.touched.project && formik.errors.project
                                        }
                                    />
                                )}
                            />
                        </div>
                        <div className="submitTicket">
                            <Box display="flex">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => closeDrawer()}
                                    sx={{ marginLeft: 'auto', height: '38px' }}>
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    type="submit"
                                    sx={{ height: '38px' }}
                                    disabled={isSubmitting}>
                                    {Object.keys(rowData).length > 0 ? 'Update' : 'Create'}
                                </Button>
                            </Box>
                        </div>
                    </div>
                </form>
            </Drawer>
        </div>
    );
};

export default CreateReleaseVersionModal;
