import { Box, Card, CardContent, Container, Grid } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import setTitle from 'src/utils/setTitle';
import SimpleBreadCrumb from 'src/components/widgets/BreadCrumb/SimpleBreadCrumb';
import useSettings from 'src/hooks/useSettings';
import EditConsultingUnits from './EditUnits';
// import ConsultingUnits from './ListViewUnits';
import ConsultingLogs from '../ViewLogs';

const smallShadow = '0px 0px 4px 0px rgba(0, 0, 0, 0.25)';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    btn: {
      width: '47px',
      height: '30px',
      padding: 0,
      minWidth: 'initial',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '4px',
      color: '#fff',
      marginLeft: '13px',
      '& .MuiButton-iconSizeMedium': {
        margin: '0',
      },
    },
    box: {
      '& .MuiAutocomplete-root': {
        '& .Mui-disabled': {
          cursor: 'not-allowed',
        },
      },
      '& .orgEditCase': {
        padding: 0,
        '& .MuiAccordion-root': {
          '& .MuiAccordionDetails-root': {},
        },
        '& .MuiAccordionDetails-root': {
          padding: '16px',
          background: '#fff!important',
          borderRadius: '0 0 4px 4px',
        },
        '& .MuiAccordionSummary-root': {
          background: '#fff !important',
          color: '#231F20 !important',
          borderRadius: '4px 4px 0 0',
          padding: '0 16px',
          flexDirection: 'initial',
        },
      },
      '& .MuiAccordionSummary-expandIconWrapper ': {
        color: '#000 !important',
      },
      '& .boxSub': {
        background: '#fff',
        padding: '16px !important',
        boxShadow: smallShadow,
        borderRadius: '4px',
        '& label': {
          color: '#231f20',
          fontSize: '14px',
          fontWeight: 600,
        },
        '& .boxHeader': {
          background: '#fff',
          color: '#231F20',
          padding: '7px 24px',
          borderRadius: '4px 4px  0  0',
          height: '39px',
        },

        '& p': {
          margin: '25px 0 10px',
        },
        '& .editorMarkup': {
          '& .wmde-markdown-var': {
            border: '1px solid transparent',
          },
        },
        '& .editorMarkupError': {
          '& .wmde-markdown-var': {
            border: '1px solid #c22027',
          },
        },
        '& .btnComments': {
          margin: '16px 0 0',
          '& button+button': {
            marginLeft: '12px',
          },
        },
        '& .MuiFormHelperText-root': {
          margin: 0,
        },
      },
      '& .commentBoxRoot': {
        marginTop: '25px',
      },
      '& .commentBox': {
        marginTop: '10px',
        padding: '10px 25px',
        border: '1px solid #eceaea',
        borderRadius: '5px',
        '& .commentBoxInner': {
          display: 'flex',
          alignItems: 'start',

          '& h4': {
            fontSize: '14px',
            fontWeight: 'bold',
            margin: 0,
          },
          '& span': {
            fontSize: '14px',
            marginLeft: '10px',
          },
        },
        '& p': {
          margin: '0px 0px 0px 0px !important',
        },
        '& span': {
          fontSize: '14px',
        },
        '& span+span': {
          marginLeft: '10px',
        },
      },
      '& .commentBox.current': {
        border: '1px solid #231f20',
      },
      '& select, & .MuiAutocomplete-inputRoot, & input': {
        height: '38px',
        padding: '0 10px',
        lineHeight: '116.7%',
        color: '#696969',
        width: '100%',
        '& .MuiAutocomplete-input': {
          padding: 0,
        },
      },
      '& .customField': {
        height: '38px',
        padding: '0 10px',
        lineHeight: '116.7%',
        color: '#696969',
        width: '100%',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        '& .MuiChip-root': {
          border: 0,
          borderRadius: '4px',
          margin: '2px 0',
          background: 'rgb(241 240 240)',
        },
      },
      '& .labelsDataCss .MuiAutocomplete-inputRoot': {
        height: 'initial',
      },

      '& .labelField': {
        // width: '50%',
      },
      '& .caseRightInner+.caseRightInner': {
        marginTop: '8px',
      },
      '& .sLa': {
        display: 'flex',
        fontSize: '14px',
        alignItems: 'center',
        '& .btnTime': {
          width: '179px',
          height: '36px',
          borderRadius: '43px',
          background: '#B0B0B0',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& svg': {
            marginLeft: '10px',
          },
        },
        '& .btnTime.bgRed': {
          background: '#CC4D53',
        },
        '& .btnTimeFirst': {
          width: '179px',
          height: '36px',
          borderRadius: '43px',
          background: '#B0B0B0',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& svg': {
            marginLeft: '10px',
          },
        },
        '& .btnTimeFirst.bgRed': {
          background: '#CC4D53',
        },
        '& .resolveTime': {
          fontSize: '14px',
          fontWeight: 'bold',
          marginLeft: '10px',
          '& p': {
            fontSize: '12px',
            fontWeight: 'normal',
            margin: '2px 0 0',
          },
          '& svg': {
            marginLeft: '10px',
          },
        },
      },
      '& .jiraSync': {
        marginTop: '0px',
        '& b': {
          fontSize: '14px',
        },
        fontSize: '12px',
      },
      '& .escalations': {
        display: 'flex',
        '& span': {
          fontSize: '14px',
        },
      },
      '& .rightSide': {
        boxShadow: smallShadow,
        '& hr': {
          border: 0,
          borderTop: '0.8px solid #E0E0E0',
          margin: '15px 0',
          borderWidth: '0.8px',
        },
      },
      '& .rightAccordion': {
        '& .MuiTypography-h4': {
          color: '#231f20',
          fontSize: '14px',
          fontWeight: 600,
        },
        '& label': {
          color: '#231f20',
          fontSize: '12px',
          fontWeight: 600,
        },
      },
      '& .createdAt': {
        fontSize: '14px',
        color: '#777',
        '& p': {
          margin: '0',
        },
        '& span': {
          fontWeight: 500,
        },
      },
      '& .btnCommentInner': {
        marginTop: 2,
        display: 'flex',
        position: 'absolute',
        top: 10,
        right: 10,
        gap: '8px',
        '& button': {
          display: 'flex',
          cursor: 'pointer',
          border: '1px solid transparent',
          borderRadius: '3px',
          color: '#696969',
          backgroundColor: 'rgba(0 0 0/0)',
          transition: 'all 300ms',
          '&:hover': {
            backgroundColor: 'rgba(0 0 0/0.1)',
          },
        },
      },
      '& .attachmentsDiv': {
        marginTop: '30px',
        '& .editCommentFileParent': {
          display: 'flex',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
          gap: '15px',
          '& a': {
            position: 'relative',
            display: 'inline-flex',
            borderRadius: 4,
            marginBottom: 8,
            width: 200,
            height: 200,
            padding: 4,
            color: ' #757575',
            boxSizing: 'border-box',
            justifyContent: 'center',
            alignItems: 'center',
            border: `1px solid ${theme.palette.grey[300]}`,
            cursor: 'pointer',
            '&  .editCommentFileEdit': {
              position: 'absolute',
              top: '-10px',
              right: '-10px',
              cursor: 'pointer',
              color: '#C22027',
            },
            '&  span': {
              position: 'absolute',
              background: ' #f7f7f7',
              fontSize: '10px',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%)',
              padding: '2px',
              fontWeight: 'bold',
            },
          },
        },
        '& a img': {
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        },
        '& img': {
          width: ' 60px',
          height: '67px',
          objectFit: 'contain',
        },
      },
    },
  })
);
const BreadCrumbsData = {
  title: 'Order Detail',
  links: [
    {
      name: 'Cunsulting Units',
      to: 'returnUrl',
    },
    {
      name: `Order Detail`,
      to: 'returnUrl',
      allowCopy: true,
    },
  ],
};
function CuDetails() {
  setTitle('Consulting | Units');
  const classes = useStyles();
  const { settings } = useSettings();

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 4,
        }}
        className={classes.box}>
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            // spacing={3}
            mb={1}>
            <Grid item md={12}>
              <SimpleBreadCrumb data={BreadCrumbsData} />
            </Grid>
          </Grid>

          <Grid container gap={2} sx={{ flexWrap: 'nowrap', marginTop: '8px' }}>
            {/** Left Side Bar */}
            <Grid
              item
              xs={8}
              sx={{
                flexDirection: 'column',
                gap: 4,
              }}>
              <Box className="boxSub">
                <ConsultingLogs
                  searchQuery=""
                  updateReset=""
                  handleResetTable={() => {}}
                  orderWorkLog="65ae36713c75236373793fec"
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Card className="rightSide">
                <CardContent>
                  <EditConsultingUnits />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default CuDetails;
