import {
  Box,
  Chip,
  Theme,
  createStyles,
  makeStyles,
  Typography,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import logger from 'src/utils/logger';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    root: {
      display: 'inline-block',
      fontSize: '13px',
      marginLeft: '5px',
      marginTop: '5px',
      background: '#FFF',
      padding: '5px 5px',
      borderRadius: '5px',
      '& .MuiChip-label': {
        marginLeft: '2px',
        fontSize: '13px',
        padding: '0px 5px',
      },
    },
  })
);

type PropsType = {
  id?: any;
  setFiltersApplied?: any;
  filtersApplied?: any;
  updateAutocompleteState?: any;
  setIsSubmit?: any;
};

export const Filter: React.FC<PropsType> = ({
  filtersApplied,
  id,
  setFiltersApplied,
  updateAutocompleteState,
  setIsSubmit,
}: PropsType) => {
  const classes = useStyles();
  const removeFilterFromLocalStorage = (filterName: string) => {
    const filterKeyMapping = {
      search: 'casesSearch',
      caseType: 'casesCaseType',
      status: 'casesStatus',
      priority: 'casesPriority',
      project: 'casesProject',
      organization: 'casesOrganization',
      escalationManager: 'casesEscalationManager',
      stakeholder: 'casesStakeholder',
      projectSponser: 'casesProjectSponser',
      assignee: 'casesAssignee',
      date: 'casesDate',
      thirdPartySync: 'casesIsSynced',
      escalatedCases: 'casesIsEscalated',
      hasAttachments: 'casesHasAttachments',
      isFirstResponseTimeViolated: 'casesIsFirstResponseTimeViolated',
      isResolveTimeViolated: 'casesIsResolvedTimeViolated',
    };
    const localStorageKey = filterKeyMapping[filterName];
    if (localStorageKey) {
      const storedFilters = JSON.parse(
        localStorage.getItem('casesPagePg') || '{}'
      );
      // Handle nested structure for "date" filter
      if (localStorageKey === 'casesDate') {
        storedFilters[localStorageKey] = {
          to: null,
          from: null,
        };
      } else {
        storedFilters[localStorageKey] = null;
      }

      localStorage.setItem('casesPagePg', JSON.stringify(storedFilters));
    } else {
      logger(`Unknown filterName: ${filterName}`);
    }
  };

  console.log('filtersApplied:', filtersApplied);
  const convertToConsistentFormat = (data) => {
    if (Array.isArray(data) && data.length === 0) {
      return null; // Convert empty arrays to null or any other desired format
    }
    if (typeof data === 'boolean') {
      return data; // Boolean values can remain as they are
    }
    // Handle other data types or leave as is based on your specific needs
    return data;
  };
  return (
    <>
      {console.log('filters aplied', filtersApplied)}
      {filtersApplied[id]?.data.length > 0 && (
        <Box className={classes.root}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '5px',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Typography
              style={{
                fontWeight: '700',
                fontSize: '13px',
                textAlign: 'center',
              }}>
              {filtersApplied[id]?.name} :{' '}
            </Typography>
            {typeof filtersApplied[id]?.data === 'object'
              ? filtersApplied[id]?.data?.map((obj: any) => (
                  <div key={obj}>
                    <Chip
                      variant="filled"
                      label={obj || ''}
                      size="small"
                      sx={{ fontSize: '8px' }}
                      onDelete={() => {
                        const newArr = filtersApplied[id].data.filter(
                          (x) => x !== obj
                        );
                        removeFilterFromLocalStorage(id);
                        setFiltersApplied({
                          ...filtersApplied,
                          [id]: {
                            ...filtersApplied[id],
                            data: newArr,
                            clicked: true,
                          },
                        });
                        setIsSubmit(true);
                        const convertedNewData =
                          convertToConsistentFormat(newArr);
                        updateAutocompleteState(id, convertedNewData);
                      }}
                      deleteIcon={<CancelIcon />}
                    />
                  </div>
                ))
              : ''}
          </Box>
        </Box>
      )}
    </>
  );
};
