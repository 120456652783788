import { Box } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { Handle, Position, useReactFlow } from 'reactflow';
import logger from 'src/utils/logger';

const useStyles = makeStyles<Theme>(() => createStyles({
  root: {
    minWidth: '150px',
    paddingLeft: '10px',
    paddingRight: '10px',
    height: '40px',
    backgroundColor: 'rgba(184, 0, 0, 1)',
    border: '1px solid #fff',
    color: '#fff',
    borderRadius: '5px',
    fontSize: '12px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    boxShadow: '0 0 0 3px rgba(184, 0, 0, 0.5)',
    cursor: 'grab',
    textTransform: 'uppercase',

    '& div': {
      position: 'absolute',
      top: '-7px',
      right: '-7px',
      backgroundColor: '#B80000 ',
      cursor: 'pointer',
      width: '10px',
      height: '10px',
      borderRadius: '50%',

      fontSize: '5px',
      lineHeight: '10px',
      textAlign: 'center',
    }
  },
}));
const CloseNode = (props: any) => {
  const { id, isConnectable } = props;
  const classes = useStyles();
  const { getEdges, getNodes, setEdges, setNodes } = useReactFlow();
  const [isAny, setIsAny] = useState(false);

  const handleDeleteNode = () => {
    try {
      const edges = getEdges();
      const nodes = getNodes();

      const filteredNodes = nodes.filter((node) => (node.id !== id));
      let filteredEdges = edges.filter((edge) => edge.target !== id);
      filteredEdges = filteredEdges.filter((edge) => edge.source !== id);
      setEdges(filteredEdges);
      setNodes(filteredNodes);
    } catch (error) {
      logger(error);
    }
  };

  const handleCheckbox = () => {
    const tempNodes = getNodes()?.map((nd) => {
      if (nd?.id === id) {
        nd.data = { ...nd.data, isAny: !isAny };
      }
      return nd;
    });
    setIsAny(!isAny);
    setNodes(tempNodes);
  };

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        id="close-node-target-1"
        style={{ top: '-6px', background: '#ff0000' }}
        isConnectable={isConnectable}
      />
      <div className={classes.root}>
        close
        <Box onClick={handleDeleteNode}>x</Box>
        <input type="checkbox" name="isAny" checked={isAny} onClick={handleCheckbox} />
      </div>
    </>
  );
};

export default React.memo(CloseNode);
