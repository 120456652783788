import {
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Checkbox,
    Button
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import moment from 'moment';
import type { FC } from 'react';
import { useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TableProps } from 'src/types/components/tableList.types';
import Scrollbar from '../../Scrollbar';
// import logger from 'src/utils/logger';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { Background } from 'reactflow';

const useStyles = makeStyles<Theme>(() => createStyles({
    btn: {
        width: '47px',
        height: '40px',
        padding: 0,
        minWidth: 'initial',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        color: '#fff',
        marginLeft: '13px',
        '& .MuiButton-iconSizeMedium': {
            margin: '0',
        },
    },
    clip: {
        display: 'inline-block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '10ch'
    },
    textFieldSelect: {
        width: '230px',
        marginLeft: '13px',
    },
    textFieldSelectRoot: {
        borderRadius: '4px',
        height: '38px',
    },
    tabButton: {
        width: '181px',
    },
    cardRoot: {
        borderRadius: '4px',
        '& td': {
            cursor: 'pointer'
        }
    },
    tabs: {
        '& .MuiTab-root': {
            backgroundColor: 'transparent',
            minWidth: '120px',
        },
        '& .Mui-selected': {
            backgroundColor: '#231F20',
            color: '#fff',
        },
    },
    tableHead: {
        '& .MuiTableCell-root': {
            textTransform: 'capitalize',
        },
    },
    tableRow: {
        '& :hover': {
            cursor: 'pointer',
        },
        '& .ticketStatusTrue': {
            backgroundColor: 'rgba(7, 83, 0, 0.16)',
            color: '#1D7A15',
            padding: '5px',
            fontWeight: 'Bold',
        },
        '& .ticketStatusFalse': {
            background: '#DEEBFF',
            color: '#3375D7',
            padding: '5px',
            fontWeight: 'Bold',
        },
    },
    priorityComponent: {
        height: '30px',
        background: 'rgba(189,189,189,0.1)',
        color: '#000000aa',
        width: '100px',
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        textTransform: 'capitalize',
        justifyContent: 'center'
    },
    buttonNext: {
        backgroundColor: '#c22027',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#d43f40',
        },
        alignSelf: 'flex-end',

    },
    loaderTable: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '2222'
    },
    fixedRow: {
        position: 'fixed',
        bottom: 0,
        width: '84%',
        // width: 'calc(100% - 45px)',
        backgroundColor: 'white',
        zIndex: 1,
        padding: '10px',
        // Optional: Add box-shadow for better visibility
        // boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.1)',
    },
    highlightRow: {
        background: '#ff000029'
    }
}));

const TableHeadComponent: FC<any> = ({ data,
    selectedSort,
    setSelectedSort,
    handleSelectAllClick,
    numSelected,
    rowCount }: any) => (
    <TableHead sx={{ marginBottom: '1rem' }}>
        <TableRow>

            {data?.map((col: any) => (
                <TableCell
                    align="center"
                    className="textBold"
                    sortDirection={selectedSort?.key === col?._id ? selectedSort?.value : false}
                    title={col?.toolTip ? col?.summary : null}
                >
                    {
                        col.isSort
                            ? (
                                <>
                                    <TableSortLabel


                                    >
                                        {col?.label}
                                    </TableSortLabel>
                                </>
                            )
                            : col?.label
                    }
                </TableCell>
            ))}
        </TableRow>
    </TableHead>
);

const PriorityComponent: FC<any> = ({ label }: any) => {
    const classes = useStyles();
    const styling: any = {
        icon: <RadioButtonUncheckedIcon />
    };

    switch (label?.toLowerCase()) {
        case 'highest':
            styling.icon = <DoubleArrowIcon sx={{ fontSize: '16px', color: '#f55033', transform: 'rotate(270deg)' }} />;
            break;
        case 'medium':
            styling.icon = <DragHandleIcon sx={{ fontSize: '24px', width: '18px', color: '#f79d28' }} />;
            break;
        case 'minor':
            styling.icon = <RadioButtonUncheckedIcon sx={{ fontSize: '14px' }} />;
            break;
        case 'blocker':
            styling.icon = <RemoveCircleIcon sx={{ fontSize: '16px', color: '#f55033' }} />;
            break;
        case 'high':
            styling.icon = <ExpandLessIcon sx={{ fontSize: '24px', color: '#f55033' }} />;
            break;
        case 'low':
            styling.icon = <ExpandMoreIcon sx={{ fontSize: '24px', color: '#3384f5' }} />;
            break;
        case 'lowest':
            styling.icon = <DoubleArrowIcon sx={{ fontSize: '16px', color: '#3384f5', transform: 'rotate(90deg)' }} />;
            break;
        default:
            styling.icon = label?.toLowerCase()?.indexOf('back') === -1 ? 'N/A' : '';
            break;
    }
    return (
        <>
            <Box
                className={classes.priorityComponent}
            >
                {styling.icon}
                {label?.toLowerCase()}
            </Box>
        </>
    );
};
const TableBodyComponent: FC<any> = ({
    data,
    status

}) => {
    const classes = useStyles();

    return (
        <TableBody style={{ position: 'relative' }}>
            {
                data.length > 0 ? data.map((row) => (
                    <TableRow
                        hover={!row.caseKey.includes("AFR")}
                        className={row.caseKey.includes('AFR') && status.name === "Declined" ? classes.highlightRow : ''}
                    >
                        <TableCell align="left" size="small" style={{ padding: '8px 0' }}>
                            {row?.attachmentId?.length ? <AttachFileIcon style={{ fontSize: '18px' }} /> : null}
                        </TableCell>
                        <TableCell align="center">
                            <span className={classes.clip}>
                                {row.caseKey}
                            </span>
                        </TableCell>
                        <TableCell align="center" title={row.summary || 'N/A'} style={{ width: '22vw', maxWidth: '22vw', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            <span>
                                {row.summary || 'N/A'}
                            </span>
                        </TableCell>
                        <TableCell align="center">
                            <PriorityComponent label={row.priority?.name || 'N/A'} />
                        </TableCell>
                        <TableCell align="center">
                            {row.status?.name || 'N/A'}
                        </TableCell>

                        <TableCell align="center">
                            <strong>{row.createdBy?.name || 'N/A'}</strong>
                        </TableCell>
                        <TableCell align="center">
                            {row.assignee?.name ? <strong>{row.assignee?.name}</strong> : 'N/A'}
                        </TableCell>
                        <TableCell align="center" title={row.caseType?.name}>
                            {row.caseKey?.substring(0, 3)}
                        </TableCell>
                        <TableCell align="center">
                            {moment(row.createdAt).format('DD MMM, YYYY HH:mm:ss')}
                        </TableCell>
                    </TableRow>
                )) : (
                    <TableRow>
                        <TableCell colSpan={12} style={{ textAlign: 'center' }}>
                            No Data Found
                        </TableCell>
                    </TableRow>
                )
            }
        </TableBody>
    );
};

// im sending selected value to parent but it is always one value behing in parent


const ListSelectedCases: FC<any> = ({
    data, status
}: any) => {

    const classes = useStyles();

    const Columns = [
        {
            id: 'attachment',
            label: '',
        },
        {
            id: 'id',
            label: 'Case #',

        },
        {
            id: 'title',
            label: 'Title',

        },
        {
            id: 'priority',
            label: 'Priority',
        },
        {
            id: 'status',
            label: 'Status',

        },

        {
            id: 'reporter',
            label: 'Reporter',

        },
        {
            id: 'assignee',
            label: 'Assignee',

        },
        {
            id: 'type',
            label: 'Type',

        },
        {
            id: 'createdAt',
            label: 'Created At',
        }
    ];


    return (
        <Box
            sx={{
                backgroundColor: 'background.default',
                py: 1,
            }}
        >
            {' '}
            {/* move button to the right end of the table */}
            <Card className={classes.cardRoot}>
                <Box sx={{ minWidth: 700, }}>
                    <Table>
                        <TableHeadComponent
                            // data={Columns}
                            // selectedSort={selectedSort}
                            // setSelectedSort={setSelectedSort}
                            data={Columns}


                        />
                        <TableBodyComponent
                            data={data}
                            status={status}

                        />
                    </Table>


                </Box>

            </Card>
        </Box>
    );
};

export default ListSelectedCases;
