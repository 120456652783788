import { isUndefined } from 'lodash';
import axios from 'src/utils/axios';
import parse from 'src/utils/parse';

class NotesApi {
  async getNotesByOrganizationId(organizationId: string, projectId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/api/note`, {
          params: {
            organizationId,
            projectId
          }
        })
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async createNote(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_CASES}/api/note`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch((error) => {
          reject(error.response.data.title);
        });
    });
  }

  async deleteNote(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${process.env.REACT_APP_CASES}/api/note/${id}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async getNoteById(id: string, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_NOTES_USER_URL}/${id}`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response?.data);
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async updateNote(id: string, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${process.env.REACT_APP_CASES}/api/note/${id}`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }
}

export const notesApi = new NotesApi();
