import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { okrApi } from 'src/api/okrApi';
import toast from 'react-hot-toast';
import DoughnutChart from 'src/components/barChart/doughnutChart';
import CircularProgress from 'src/components/barChart/progressBar';

const useStyles = makeStyles<Theme>(
  () =>
    createStyles({
      btn: {
        padding: 0,
      },
      graphBox: {
        padding: 16,
        marginTop: '10px',
        height: '100%',
        background: 'white',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
      },
    }),
  {
    name: 'OverallGraph',
  }
);
function OverallGraph() {
  const classes = useStyles();
  // const [departmentData, setDepartmentData] = useState([]);
  const [data, setData] = useState({});
  const [hourlyData, setHourlyData] = useState({});
  const [progress, setProgress] = useState(0);

  const keys = [
    { key: 'totalCount', title: 'Total', color: '#4285f4' },
    { key: 'Done', title: 'Done', color: '#34a853' },
    { key: 'In Progress', title: 'In Progress', color: '#fbbc04' },
    { key: 'Pending', title: 'Pending', color: '#ea4335' },
  ];

  const hourlykeys = [
    { key: 'totalHours', title: 'Total', color: '#4285f4' },
    { key: 'consumedHours', title: 'Consumed Hours', color: '#34a853' },
    { key: 'extraHours', title: 'Extra Hours', color: '#fbbc04' },
  ];
  const getCircumference = (totalValue, value) => (value / totalValue) * 360;
  function parseData(jsonData: any, graphKeys: any, circumferenceKey: string) {
    const datasets = [
      // {
      //   label: '',
      //   data: [],
      //   backgroundColor: [],
      //   // circumference: null,
      // },
    ];
    const labels = [];
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    // for (const key in jsonData) {
    //   const keyData = graphKeys.filter((x) => x.key === key)[0];
    //   if (keyData) {
    //     labels.push(keyData.title);
    //     datasets.push({
    //       label: keyData.title,
    //       data: [jsonData[key]],
    //       backgroundColor: [keyData.color],
    //       //  circumference: () => getCircumference(jsonData[circumferenceKey], jsonData[key]),
    //     });
    //   }
    // }

    graphKeys.forEach((item) => {
      labels.push(item.title);
      // datasets[0].data.push(jsonData[item.key]);
      // datasets[0].backgroundColor.push(item.color);
      datasets.push({
        label: item.title,
        data: [jsonData[item.key]],
        backgroundColor: [item.color],
        borderRadius: 50,
        circumference: () =>
          getCircumference(jsonData[circumferenceKey], jsonData[item.key]),
      });
    });
    return { labels, datasets };
  }
  const getOverallData = async () => {
    try {
      const response = await okrApi.getOverallSummary();
      setData(response.data);
      const { Done, totalCount } = response.data;
      setProgress((Done / totalCount) * 100 || 0);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const getOverallHourly = async () => {
    try {
      const response = await okrApi.getOverallHourlySummary();
      setHourlyData(response.data);
    } catch (error) {
      toast.error(error.message);
    }
  };
  useEffect(() => {
    getOverallData();
    getOverallHourly();
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
      }}
      className={classes.table}>
      {Object.entries(data).length ? (
        <Grid container spacing={2} className={classes.root}>
          <Grid item md={12} lg={4}>
            <Box className={classes.graphBox}>
              <h1
                style={{
                  color: '#000',
                  margin: 0,
                  fontSize: 20,
                  fontWeight: 700,
                  lineHeight: '116.7%',
                }}>
                Overall Summary
              </h1>

              <DoughnutChart data={parseData(data, keys, 'totalCount') || {}} />
            </Box>
          </Grid>
          <Grid item md={12} lg={4} sx={{ marginRight: 'auto' }}>
            <Box className={classes.graphBox}>
              <h1
                style={{
                  color: '#000',
                  margin: 0,
                  fontSize: 20,
                  fontWeight: 700,
                  lineHeight: '116.7%',
                }}>
                Overall Performance
              </h1>
              {progress ? (
                <CircularProgress value={progress} />
              ) : (
                <CircularProgress value={0} />
              )}
            </Box>
          </Grid>
          <Grid item md={12} lg={4} sx={{ marginRight: 'auto' }}>
            <Box className={classes.graphBox}>
              <h1
                style={{
                  color: '#000',
                  margin: 0,
                  fontSize: 20,
                  fontWeight: 700,
                  lineHeight: '116.7%',
                }}>
                Overall Employee Performance
              </h1>
              {progress ? (
                <CircularProgress value={68} />
              ) : (
                <CircularProgress value={0} />
              )}
            </Box>
          </Grid>
          <Grid item md={12} lg={4}>
            <Box className={classes.graphBox}>
              <h1
                style={{
                  color: '#000',
                  margin: 0,
                  fontSize: 20,
                  fontWeight: 700,
                  lineHeight: '116.7%',
                }}>
                Overall Hourly Summary
              </h1>

              <DoughnutChart
                data={parseData(hourlyData, hourlykeys, 'totalHours') || {}}
              />
            </Box>
          </Grid>
        </Grid>
      ) : null}
    </Box>
  );
}

export default OverallGraph;
