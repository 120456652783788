/* eslint-disable no-underscore-dangle */
import {
  Autocomplete,
  TextField,
  Box,
  Button,
  MenuItem,
  Select,
  FormHelperText,
  FormControl,
  // InputLabel,
  makeStyles,
  createStyles,
  Theme,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

type CreateProductFormProps = {
  closeDrawer: any;
  formData?: any;
  onSubmit: any;
  editForm?: boolean;
  schema: any;
};

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    formRoot: {
      '& .MuiInputLabel-root': {
        top: '-8px',
      },
      '& .MuiInputLabel-shrink': {
        top: '0px',
      },
      '& label': {
        fontWeight: '550'
      }
    }

  }))

const CreateProductForm = ({
  closeDrawer,
  onSubmit,
  schema,
  formData,
  editForm,
}: CreateProductFormProps) => {
  const classes = useStyles();
  const categories: any[] = useSelector(
    (state: any) => state.pim.allCategories
  );
  const distributors: any[] = useSelector(
    (state: any) => state.pim.allDistributors
  );
  const measurement: any[] = useSelector(
    (state: any) => state.pim.allMeasurement
  );
  const { categoryID } = useParams();

  const formik = useFormik({
    initialValues: {
      name: formData?.name || '',
      category: categories.find(
        (category) => category._id === categoryID
      )
      ,
      distributor: formData?.distributorId
        ? distributors.find(
          (distributor) => distributor._id === formData?.distributorId
        )
        : null,
      sku: formData?.productSKU || '',
      supplier: formData?.supplier || null,
      currency: formData?.productCurrency || 'Euro',
      cores: formData?.productCores || 0,
      nodes: formData?.productNodes || 0,
      sockets: formData?.productSockets || 0,
      supportType: formData?.productSupportType || null,
      vGuests: formData?.productVirtualGuest || 0,
      // unitOfMeasure: formData?.productMeasurement || null,
      unitOfMeasure: formData?.productMeasurement
        ? measurement.find(
          (item) => item.name === formData?.productMeasurement
        )
        : null,
      status: formData ? formData?.isActive && 'active' || 'inActive' : 'active',
      priceDuration: formData?.productPrice1M && 'monthly' || formData?.productPrice1Y && 'yearly' || formData?.productPrice3Y && 'threeYearly' || 'monthly',
      price: formData?.productPrice1M || formData?.productPrice1Y || formData?.productPrice3Y || null,
    },
    onSubmit,
    validationSchema: schema,
  });

  return (
    <form noValidate onSubmit={formik.handleSubmit} className={classes.formRoot}>
      <div className="ticketBody">
        <div className="ticketDropDown">
          <TextField
            id="product-name"
            type="text"
            name="name"
            label='Name'
            value={formik?.values?.name}
            placeholder="Name"
            onChange={formik?.handleChange}
            fullWidth
            error={Boolean(formik.touched?.name && formik?.errors?.name)}
            helperText={formik.touched?.name && formik?.errors?.name}
          />
        </div>

        <div className="ticketDropDown">
          <Autocomplete
            id="product-category"
            options={categories}
            value={formik?.values?.category}
            disabled={!editForm}
            getOptionLabel={(option) => option.name}
            onChange={(e: any, newValue: any) => {
              formik.setFieldValue('category', newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Category"
                label="Category"
                error={Boolean(
                  formik?.touched?.category && formik?.errors?.category
                )}
                helperText={
                  formik?.touched?.category && formik?.errors?.category
                }
              />
            )}
          />
        </div>

        <div className="ticketDropDown">
          <Autocomplete
            id="product-distributor"
            options={distributors}
            value={formik?.values?.distributor}
            getOptionLabel={(option) => option.name}
            onChange={(e: any, newValue: any) => {
              formik.setFieldValue('distributor', newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Distributor"
                label="Distributor"
                error={Boolean(
                  formik?.touched?.distributor && formik?.errors?.distributor
                )}
                helperText={
                  formik?.touched?.distributor && formik?.errors?.distributor
                }
              />
            )}
          />
        </div>

        <div className="ticketDropDown">
          <TextField
            id="product-sku"
            type="text"
            name="sku"
            value={formik?.values?.sku}
            placeholder="SKU"
            label="SKU"
            onChange={formik?.handleChange}
            fullWidth
            error={Boolean(formik.touched?.sku && formik?.errors?.sku)}
            helperText={formik.touched?.sku && formik?.errors?.sku}
          />
        </div>

        <div className="ticketDropDown">
          <TextField
            id="product-currency"
            type="text"
            name="currency"
            label="Currency"
            value={formik?.values?.currency}
            placeholder="Currency"
            fullWidth
            disabled
          />
        </div>

        <div className="ticketDropDown">
          <TextField
            id="product-cores"
            type="number"
            name="cores"
            label="Cores"
            value={formik?.values?.cores}
            placeholder="No. of Cores"
            fullWidth
            InputProps={{ inputProps: { min: 0 } }}
            onChange={formik.handleChange}
            error={Boolean(formik.touched?.cores && formik?.errors?.cores)}
            helperText={formik.touched?.cores && formik?.errors?.cores}
          />
        </div>

        <div className="ticketDropDown">
          <TextField
            id="product-nodes"
            type="number"
            name="nodes"
            label="Nodes"
            value={formik?.values?.nodes}
            placeholder="No. of Nodes"
            fullWidth
            InputProps={{ inputProps: { min: 0 } }}
            onChange={formik.handleChange}
            error={Boolean(formik.touched?.nodes && formik?.errors?.nodes)}
            helperText={formik.touched?.nodes && formik?.errors?.nodes}
          />
        </div>

        <div className="ticketDropDown">
          <TextField
            id="product-sockets"
            type="number"
            name="sockets"
            label="Sockets"
            value={formik?.values?.sockets}
            placeholder="No. of Sockets"
            fullWidth
            InputProps={{ inputProps: { min: 0 } }}
            onChange={formik.handleChange}
            error={Boolean(formik.touched?.sockets && formik?.errors?.sockets)}
            helperText={formik.touched?.sockets && formik?.errors?.sockets}
          />
        </div>


        <div className="ticketDropDown">
          <FormControl component="fieldset">
            <FormLabel component="legend">Status</FormLabel>
            <RadioGroup
              style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: 'space-around' }}
              aria-label="status"
              name="status"
              value={formik.values.status}
              onChange={formik.handleChange}
            >
              <FormControlLabel value="active" control={<Radio color="primary" />} label="Active" />
              <FormControlLabel value="inActive" control={<Radio color="primary" />} label="In-Active" />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="ticketDropDown">
          <Autocomplete
            id="product-supportType"
            options={['STANDARD', 'LAYERED', 'PREMIUM']}
            value={formik?.values?.supportType}
            // getOptionLabel={(option) => option.name}
            onChange={(e: any, newValue: any) => {
              formik.setFieldValue('supportType', newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Support Type"
                label="Support Type"
                error={Boolean(
                  formik?.touched?.supportType && formik?.errors?.supportType
                )}
                helperText={
                  formik?.touched?.supportType && formik?.errors?.supportType
                }
              />
            )}
          />
        </div>

        <div className="ticketDropDown">
          <TextField
            id="product-vGuests"
            type="number"
            name="vGuests"
            value={formik?.values?.vGuests}
            label="Virtual Guests"
            placeholder="No. of Virtual Guests"
            fullWidth
            onChange={formik.handleChange}
            error={Boolean(formik.touched?.vGuests && formik?.errors?.vGuests)}
            helperText={formik.touched?.vGuests && formik?.errors?.vGuests}
          />
        </div>

        <div className="ticketDropDown">
          <Autocomplete
            id="product-unitOfMeasure"
            options={measurement}
            value={formik?.values?.unitOfMeasure}
            getOptionLabel={(option) => option.name}
            onChange={(e: any, newValue: any) => {
              formik.setFieldValue('unitOfMeasure', newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="System Unit of Measure"
                label="System Unit of Measure"
                error={Boolean(
                  formik?.touched?.unitOfMeasure &&
                  formik?.errors?.unitOfMeasure
                )}
                helperText={
                  formik?.touched?.unitOfMeasure &&
                  formik?.errors?.unitOfMeasure
                }
              />
            )}
          />
        </div>

        <div className="ticketDropDown">
          <Box display="flex" gap="10px">
            <Select
              id="pricing"
              value={formik.values.priceDuration}
              variant="outlined"
              disableUnderline
              name="priceDuration"
              onChange={formik.handleChange}>
              <MenuItem value="monthly">Monthly</MenuItem>
              <MenuItem value="yearly">Yearly</MenuItem>
              <MenuItem value="threeYearly">3 years</MenuItem>
            </Select>

            <TextField
              id="product-pricing"
              type="number"
              name="price"
              label="Price"
              value={formik?.values?.price}
              placeholder="Price"
              fullWidth
              onChange={formik.handleChange}

            />
          </Box>
          <FormHelperText className="Mui-error">
            <span
              style={{
                color: '#C22027',
                margin: '3px 14px 0',
              }}>
              {formik.touched?.price && formik?.errors?.price}
            </span>
          </FormHelperText>
        </div>

        <div className="submitTicket">
          <Box display="flex" sx={{ marginLeft: 'auto' }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => closeDrawer()}
              sx={{ marginLeft: 'auto', height: '38px' }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              sx={{ height: '38px' }}>
              {editForm ? 'Save' : 'Create'}
            </Button>
          </Box>
        </div>
      </div>
    </form>
  );
};

export default CreateProductForm;
