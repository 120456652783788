import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Typography,
  IconButton,
  MenuItem,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { Autocomplete } from '@mui/material';
import { leavesApi } from 'src/api/leavesApi';
import { userApi } from 'src/api/userApi';

interface AddLeaveDialogProps {
  open: boolean;
  onClose: () => void;
  caseKey: string;
  caseId: string;
  fetchLeaveData: () => void;
  requestData: any;
}

// Validation Schema
const validationSchema = Yup.object({
  leaveType: Yup.string().required('Leave type is required'),
  startDate: Yup.string().required('Start date is required'),
  endDate: Yup.string().required('End date is required'),
  employee: Yup.object().nullable().required('Employee is required'),
  description: Yup.string(),
});

const AddLeaveDialog: React.FC<AddLeaveDialogProps> = ({
  open,
  onClose,
  caseKey,
  caseId,
  fetchLeaveData,
  requestData,
}) => {
  const [employees, setEmployees] = useState([]);
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await userApi.getUsersByOrganizationId(
          null,
          null,
          25000,
          null
        );
        setEmployees(response?.data?.data);
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

    const fetchLeaveTypes = async () => {
      try {
        const response = await leavesApi.getLeaveTypes();
        setLeaveTypes(response.data);
      } catch (error) {
        console.error('Error fetching leave type data:', error);
      }
    };

    fetchEmployees();
    fetchLeaveTypes();
  }, []);

  const calculateDuration = (start: string, end: string) => {
    if (start && end) {
      const startDate = new Date(start);
      const endDate = new Date(end);

      if (endDate < startDate) {
        return 0;
      }

      let totalDays = 0;

      for (
        let date = new Date(startDate);
        date <= endDate;
        date.setDate(date.getDate() + 1)
      ) {
        const dayOfWeek = date.getDay();
        if (dayOfWeek !== 0 && dayOfWeek !== 6) {
          totalDays++;
        }
      }

      return totalDays;
    }
    return 0;
  };

  const handleSubmit = async (values: any) => {
    const payload = {
      user: {
        id: values.employee.id,
        name: values.employee.name,
      },
      leaveType: values.leaveType,
      caseId,
      caseKey,
      status: 'PLANNED',
      startDate: values.startDate,
      endDate: values.endDate,
    };

    try {
      const response = await leavesApi.postLeave(payload);

      if (response.status === 201) {
        toast.success('Leave successfully logged!');
        fetchLeaveData();
        onClose();
      } else {
        toast.error('Failed to log leave. Please try again.');
      }
    } catch (error) {
      console.error('Error logging leave:', error);
      toast.error('Error logging leave. Please try again.');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Formik
        initialValues={{
          leaveType: '',
          startDate: '',
          endDate: '',
          employee: requestData?.createdBy || null,
          description: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}>
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <DialogContent>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center">
                <Typography variant="h4">Add Leave</Typography>
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Box>

              <Grid container spacing={2} marginTop="16px">
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    fullWidth
                    label="Leave type"
                    name="leaveType"
                    select
                    variant="outlined"
                    error={touched.leaveType && Boolean(errors.leaveType)}
                    helperText={touched.leaveType && errors.leaveType}>
                    {leaveTypes.map((type) => (
                      <MenuItem key={type.id} value={type.name}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>

                <Grid item xs={12}>
                  <Box display="flex" justifyContent="space-between">
                    <Box flexGrow={1} marginRight="16px">
                      <Field
                        as={TextField}
                        fullWidth
                        label="Start date"
                        name="startDate"
                        type="date"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={touched.startDate && Boolean(errors.startDate)}
                        helperText={touched.startDate && errors.startDate}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue('startDate', e.target.value);
                          setDuration(
                            calculateDuration(e.target.value, values.endDate)
                          );
                        }}
                      />
                    </Box>

                    <Box flexGrow={1} marginRight="16px">
                      <Field
                        as={TextField}
                        fullWidth
                        label="End date"
                        name="endDate"
                        type="date"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={touched.endDate && Boolean(errors.endDate)}
                        helperText={touched.endDate && errors.endDate}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue('endDate', e.target.value);
                          setDuration(
                            calculateDuration(values.startDate, e.target.value)
                          );
                        }}
                      />
                    </Box>

                    <Box width="80px">
                      <TextField
                        fullWidth
                        label="Duration"
                        name="duration"
                        value={duration}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    </Box>
                  </Box>
                </Grid>

                {/* Autocomplete for Employee */}
                <Grid item xs={12}>
                  <Autocomplete
                    options={employees}
                    getOptionLabel={(option) => option.name || 'No name'}
                    value={values.employee} // Use Formik's value for Autocomplete
                    onChange={(event, value) =>
                      setFieldValue('employee', value)
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Employee"
                        variant="outlined"
                        error={touched.employee && Boolean(errors.employee)}
                        helperText={touched.employee && errors.employee}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    fullWidth
                    label="Description"
                    name="description"
                    variant="outlined"
                    multiline
                    rows={3}
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary" variant="outlined">
                Cancel
              </Button>
              <Button color="secondary" variant="contained" type="submit">
                Log time
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddLeaveDialog;
