import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { casesApi } from 'src/api/casesApi';
import logger from 'src/utils/logger';

const useStyles = makeStyles<Theme>(() => createStyles({
  container: {
    // display: 'flex',
    // flexDirection: 'row',
    // flexWrap: 'wrap',
    // gap: '15px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '10px',
    width: '100%',
  },
  node: ({ bgColor, borderColor }) => ({
    minWidth: '120px',
    width: '100%',
    paddingLeft: '10px',
    paddingRight: '10px',
    height: '40px',
    backgroundColor: `${bgColor ?? 'rgba(168, 161, 150, 0.5)'}`,
    border: '1px solid #fff',
    color: '#000',
    boxShadow: `0 0 0 3px ${borderColor ?? 'rgba(168, 161, 150, 1)'}`,
    borderRadius: '5px',
    fontSize: '12px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    cursor: 'grab',
  }),
  heading: {
    margin: '0px 0px 10px 0px',
    textTransform: 'capitalize',
  }
}));
const NodeShape = ({ onDragStart, draggable, label, bgColor, borderColor }: any) => {
  const classes = useStyles({ bgColor, borderColor });
  return (
    <div onDragStart={onDragStart} draggable={draggable} className={classes.node}>{label}</div>
  );
};

const AllStatusNodes = () => {
  const classes = useStyles();
  const [allStatuses, setAllStatuses] = useState([]);
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', JSON.stringify(nodeType));
    event.dataTransfer.effectAllowed = 'move';
  };

  const getStatuses = async () => {
    try {
      const res = await casesApi.getStatuses();
      setAllStatuses(res?.data);
    } catch (error) {
      logger(error);
      setAllStatuses([]);
    }
  };

  useEffect(() => {
    getStatuses();
  }, []);

  return (
    <>
      <h4 className={classes.heading}>Choose Node</h4>
      <div className={classes.container}>
        {
          allStatuses.map((itm) => (
            <NodeShape
              id={itm.id}
              draggable
              onDragStart={(e) => onDragStart(e, { id: itm?.id, name: itm?.name })}
              label={itm.name}
            />
          ))
        }
      </div>
    </>
  );
};

export default AllStatusNodes;
