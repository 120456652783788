/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormik } from 'formik';

import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { casesApi } from 'src/api/casesApi';
import { CasesTypeProps } from 'src/types/components/cases.type';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// import MDEditor, { selectWord } from '@uiw/react-md-editor';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import useAuth from 'src/hooks/useAuth';
import {
  organizationDetailData,
  projectDataByOrg,
  reRenderComponents,
} from 'src/store/slices/organizationDetailsSlice';
import { openSnackbar } from 'src/store/slices/snackBarSlice';
import logger from 'src/utils/logger';
import * as Yup from 'yup';
import FileUploadDragDrop from '../widgets/fileUpload/FileUploadDragDrop';
import { userApi } from 'src/api/userApi';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    CreateCase: {
      width: '40%',
      padding: '5px 20px ',
      zIndex: 1300,
      border: 'none',
      '& .ticketBody': {
        height: '90vh',
        overflowY: 'auto',
        padding: '0 20px',
        '& input': {
          borderRadius: '2px',
        },
      },
      '& h4': {
        background: '#C22027',
        color: '#fff',
        padding: '0 40px',
        margin: '-5px -20px 30px',
        height: '64px',
        display: 'flex',
        alignItems: 'center',
      },
      '& label': {
        letterSpacing: ' 0.15px',
        color: '#231F20',
        fontWeight: '600',
        display: 'block',
      },

      '& select, & .MuiAutocomplete-inputRoot, & input': {
        height: '38px',
        padding: '0 10px',
        lineHeight: '116.7%',
        color: '#696969',
        width: '100%',
        '& .MuiAutocomplete-input': {
          padding: 0,
        },
      },
      '& .customField': {
        height: '38px',
        padding: '0 10px',
        lineHeight: '116.7%',
        color: '#696969',
        width: '100%',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        '& .MuiChip-root': {
          border: 0,
          borderRadius: '4px',
          margin: '2px 0',
          background: 'rgb(241 240 240)',
        },
      },
      '& .labelsDataCss .MuiAutocomplete-inputRoot': {
        height: 'initial',
      },
      '& .ticketDropDown+.ticketDropDown': {
        marginTop: '15px',
      },
      '& .submitTicket ': {
        margin: '35px 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      '& .submitTicket button+button': {
        marginLeft: '15px',
      },
    },
    datePicker: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  })
);

const CreateCase: FC<any> = ({
  isOpen,
  addCases,
  closeDrawer,
  getAllData,
  nested,
}: CasesTypeProps) => {
  const { projectID }: any = useParams();
  const classes = useStyles();
  const { user } = useAuth();
  const [allProjects, setAllProjects] = useState<any>();
  const [allOrganization, setorganizations] = useState<any>([]);
  const [labelsOptions, setLabelsOptions] = useState<any>([]);
  // const [allUsersExternal, setAllUsersExternal] = useState<any>('');
  // const [allUsersInternal, setAllUsersInternal] = useState<any>('');
  const [projectInputValue, setProjectInputValue] = useState<any>('');
  const [caseTypeInput, setCaseTypeInput] = useState<any>('');
  const [priorityInput, setPriorityInput] = useState<any>('HIGHEST');
  const [orgInputValue, setOrgInputValue] = useState<any>('');
  const [caseType, setCaseType] = useState<any>(null);
  const [priority, setPriority] = useState<any>('');
  // const [description, setDescription] = useState<any>('');
  const [isJiraSync, setIsJiraSync] = useState<boolean>(false);
  const [allSeverities, setAllSeverities] = useState<any>([]);
  const getOrgDetails = useSelector((state: any) => state?.orgInfo);
  const dispatch = useDispatch();
  const locatiion = useLocation();
  const [filePath, setFilePath] = useState<Array<string>>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [dropzoneKey, setDropzoneKey] = useState<number>(0);
  const [employees, setEmployees] = useState<any>([]);

  const fetchEmployees = async () => {
    try {
      const response = await userApi.getUsersByOrganizationId(
        null,
        null,
        25000,
        null
      );
      setEmployees(response?.data?.data);
    } catch (error) {
      console.error('Error fetching employee data:', error);
    }
  };

  const getAllOrganizations = async () => {
    try {
      const response = await casesApi.getOrganizationId();

      if (response?.status === 200) {
        setorganizations(response?.data);
      }
    } catch (error) {
      logger(error, 'error');
      setorganizations([]);
    }
  };
  const convertToSeconds = (timeString) => {
    const timeUnits = timeString.match(/\d+\s*[hm]/g);
    let totalSeconds = 0;

    timeUnits.forEach((unit) => {
      const number = parseInt(unit, 10);
      const timeUnit = unit.replace(/\d+\s*/g, ''); // Remove digits and spaces to get the unit

      if (timeUnit === 'h') {
        totalSeconds += number * 3600; // 1 hour = 3600 seconds
      } else if (timeUnit === 'm') {
        totalSeconds += number * 60; // 1 minute = 60 seconds
      }
    });

    return totalSeconds;
  };
  const getAllProjects = async (orgId: any) => {
    try {
      const response = await casesApi.getProjectsById(orgId);
      if (response?.status === 200) {
        setAllProjects(response?.data);
      }
    } catch (error) {
      setAllProjects([]);
      logger(error, 'error');
    }
  };

  const getCaseType = async () => {
    try {
      const response = await casesApi.getCaseTypes();
      setCaseType(response?.data);
    } catch (error) {
      logger(error, 'error');
      setCaseType([]);
    }
  };
  const getPriority = async () => {
    try {
      const response = await casesApi.getPriorities();
      setPriority(response?.data);
    } catch (error) {
      logger(error, 'error');
      setPriority([]);
    }
  };

  const getAllSeverities = async () => {
    try {
      const response = await casesApi.getAutoCompleteSeverity();
      setAllSeverities(response?.data);
    } catch (error) {
      logger(error, 'error');
      setAllSeverities([]);
    }
  };

  const formik = useFormik({
    initialValues: {
      labelData: [],
      description: '',
      titleMain: '',
      isJiraSync: false,
      severity: null,
      bugOccur: '',
      bugExpect: '',
      orgId: null,
      projectId: null,
      priorities: null,
      caseType: null,
      timeEstimate: '',
      attachmentId: filePath,
      employee: null,
      expense: null,
    },
    onSubmit: async (values, actions) => {
      const timeInSeconds = convertToSeconds(values.timeEstimate);
      try {
        const payload = {
          labels: values?.labelData,
          description: values?.description,
          summary: values?.titleMain,
          isSynced: isJiraSync,
          organization: values?.orgId,
          project: values?.projectId,
          priority: values?.priorities,
          caseType: values?.caseType,
          attachmentId: filePath,
          severity: values?.severity,
          timeEstimate: timeInSeconds,
          assignee: {
            id: values?.employee?.id,
            name: values?.employee?.name,
          },
          expense: values?.expense?.name,
          bugQuestions: [
            {
              questionKey: 'How did it occur and how to reproduce the bug?',
              description: values?.bugOccur,
            },
            {
              questionKey: 'What did you expect to happen?',
              description: values?.bugExpect,
            },
          ],
        };
        const response = await casesApi.createCase(payload);
        if (response?.status === 201) {
          // Prevent multiple submissions
          if (isSubmitting) {
            return;
          }

          // Set submitting state to true
          setIsSubmitting(true);
          localStorage.removeItem('formikValues');
          closeDrawer();
          dispatch(reRenderComponents());
          actions.resetForm();
          dispatch(
            openSnackbar({
              message: `You have created ${response.data} case`,
              caseKey: response.data,
            })
          );
          if (projectID === undefined) {
            getAllData();
          }
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
      } finally {
        // Set submitting state back to false
        setIsSubmitting(false);
      }
    },
    validationSchema: Yup.object().shape({
      labelData: Yup.array().test(
        'is-nonempty',
        'Labels required',
        (value) => value && value.length > 0
      ),
      orgId: Yup.mixed().nullable().required('Organization required'),
      projectId: Yup.mixed().nullable().required('Project required'),
      titleMain: Yup.string()
        .trim()
        .required('Summary required')
        .max(256, 'Maximum 256 characters allowed'),
      description: Yup.string().trim().required('Description required'),
      expense: Yup.mixed().nullable().required('Expense required'),
      priorities: Yup.mixed().nullable().required('Priority required'),
      caseType: Yup.mixed().nullable().required('Case Type required'),
      severity: Yup.mixed().when('caseType.id', {
        is: (value) => value === '65005f2c0f96e012c1a9b1eb',
        then: Yup.mixed().nullable().required('Severity required'),
        otherwise: Yup.mixed(),
      }),
      bugOccur: Yup.string().when('caseType.id', {
        is: (value) => value === '65005f2c0f96e012c1a9b1eb',
        then: Yup.string()
          .trim()
          .required('Answer required')
          .max(1000, 'Maximum 1000 characters allowed'),
        otherwise: Yup.string(),
      }),
      bugExpect: Yup.string().when('caseType.id', {
        is: (value) => value === '65005f2c0f96e012c1a9b1eb',
        then: Yup.string()
          .trim()
          .required('Answer required')
          .max(1000, 'Maximum 1000 characters allowed'),
        otherwise: Yup.string(),
      }),
      timeEstimate: Yup.string()
        .matches(
          /^(?:\d+\s*[hm]\s*)+$/,
          'Time Estimate must be in the format "number h" or "number m" or a combination like "2h 20m"'
        )
        .required('Time is required'),
    }),
  });

  const addDataToLocalStorage = (column, data) => {
    const obj = JSON.parse(localStorage?.getItem('formikValues'));
    localStorage.setItem(
      'formikValues',
      JSON.stringify({
        ...obj,
        [column]: data,
      })
    );
  };
  const handleReset = () => {
    localStorage.removeItem('formikValues');
    formik?.setValues({
      labelData: [],
      description: '',
      titleMain: '',
      isJiraSync: false,
      severity: null,
      bugOccur: '',
      bugExpect: '',
      orgId:
        projectID === undefined
          ? null
          : {
              id: getOrgDetails?.orgInfo?.id,
              name: getOrgDetails?.orgInfo?.name,
            },
      projectId:
        projectID === undefined
          ? null
          : {
              id: getOrgDetails?.projInfo?.id,
              name: getOrgDetails?.projInfo?.name,
            },
      priorities: null,
      caseType: null,
      attachmentId: filePath,
      timeEstimate: '',
      employee: null,
      expense: null,
    });

    setDropzoneKey((prevKey) => prevKey + 1);
    setFilePath([]);
  };

  const getLabels = async () => {
    try {
      const res = await casesApi?.getAutoCompleteLabels();
      const uniqueArray = res?.data?.filter(
        (value, index, self) => self.indexOf(value) === index
      );
      setLabelsOptions(uniqueArray);
    } catch (error) {
      setLabelsOptions([]);
      logger(error, 'error');
    }
  };

  function uploadAdapter(loader) {
    return {
      upload: () =>
        new Promise((resolve) => {
          loader.file.then(async (file) => {
            const formData = new FormData();
            formData.append('file', file);
            const image = await userApi.uploadAttachments(formData);
            resolve({
              default: `${image?.data?.blob_url}`,
            });
          });
        }),
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
      uploadAdapter(loader);
  }

  useEffect(() => {
    if (
      (getOrgDetails?.orgInfo?.name !== undefined ||
        getOrgDetails?.projInfo?.name !== undefined) &&
      (getOrgDetails?.orgInfo?.name !== formik?.values?.orgId ||
        getOrgDetails?.projInfo?.name !== formik?.values?.projectId)
    ) {
      formik.setFieldValue('orgId', {
        id: getOrgDetails?.orgInfo?.id,
        name: getOrgDetails?.orgInfo?.name,
      });
      formik.setFieldValue('projectId', {
        id: getOrgDetails?.projInfo?.id,
        name: getOrgDetails?.projInfo?.name,
      });
    }
  }, [getOrgDetails]);

  useEffect(() => {
    const isClearRoute = locatiion.pathname === '/erp/cases'; // Adjust the route path as needed
    const stored = JSON.parse(localStorage?.getItem('formikValues'));
    if (isClearRoute) {
      if (stored === null) {
        dispatch(projectDataByOrg(formik.initialValues.projectId));
        dispatch(organizationDetailData(formik.initialValues.orgId));
      } else {
        dispatch(projectDataByOrg(stored.projectId));
        dispatch(organizationDetailData(stored.orgId));
      }
    }
  }, []);

  useEffect(() => {
    getAllOrganizations();
    getLabels();
    getCaseType();
    getAllSeverities();
    getPriority();
    // Check if values exist in localStorage and set initial values accordingly
    const storedFromikValues = JSON.parse(
      localStorage?.getItem('formikValues')
    );

    if (storedFromikValues && !projectID) {
      // formik?.setValues(storedFromikValues);
      formik?.setValues({
        labelData: storedFromikValues?.labelData || [],
        description: storedFromikValues?.description || '',
        titleMain: storedFromikValues?.titleMain || '',
        isJiraSync: storedFromikValues?.isJiraSync || false,
        severity: storedFromikValues?.severity || null,
        bugOccur: storedFromikValues?.bugOccur || '',
        bugExpect: storedFromikValues?.bugExpect || '',
        orgId: storedFromikValues?.orgId || null,
        projectId: storedFromikValues?.projectId || null,
        priorities: storedFromikValues?.priorities || null,
        caseType: storedFromikValues?.caseType || null,
        attachmentId: storedFromikValues?.attachmentId || filePath,
        timeEstimate: '',
        employee: storedFromikValues?.employee || null,
        expense: storedFromikValues?.expense || null,
      });
    }
    getAllProjects(storedFromikValues?.orgId?.id);
    fetchEmployees();
  }, []);

  return (
    <div className="createTicket">
      <Drawer
        open={isOpen}
        anchor="right"
        onClose={(e) => addCases(false, e)}
        classes={{ paper: classes.CreateCase }}>
        <Typography variant="h4" component="h4">
          Create Case
        </Typography>
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="ticketBody">
            <div className="ticketDropDown">
              <Autocomplete
                id="Organization-name"
                options={allOrganization || []}
                value={formik?.values?.orgId}
                onChange={(event: any, value: any) => {
                  formik?.setFieldValue('orgId', value);
                  formik?.setFieldValue('projectId', null);
                  // setOrgID(value?.id);
                  getAllProjects(value?.id);
                  if (value === null || value === undefined) {
                    // Handle the clear button click event
                    // setOrgID(0);
                  }
                  addDataToLocalStorage('orgId', value);
                }}
                getOptionLabel={(option) => option.name}
                inputValue={orgInputValue}
                onInputChange={(event: any, newInputValue: any) => {
                  setOrgInputValue(newInputValue);
                }}
                style={{ minWidth: 300 }}
                disabled={projectID !== undefined}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    // onChange={(value: any) => setorganizations(value?.id)}
                    placeholder="Organization*"
                    error={Boolean(
                      formik?.touched?.orgId && formik?.errors?.orgId
                    )}
                    helperText={formik?.touched?.orgId && formik?.errors?.orgId}
                    // disabled={projectID !== undefined}
                  />
                )}
              />
            </div>
            <div className="ticketDropDown">
              <Autocomplete
                id="project-name"
                options={allProjects || []}
                value={formik?.values?.projectId}
                onChange={(event: any, value: any) => {
                  formik?.setFieldValue('projectId', value);
                  addDataToLocalStorage('projectId', value);
                }}
                getOptionLabel={(option) => option.name}
                inputValue={projectInputValue}
                onInputChange={(event: any, newInputValue: any) => {
                  setProjectInputValue(newInputValue);
                }}
                style={{ minWidth: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Project*"
                    error={Boolean(
                      formik?.touched?.projectId && formik?.errors?.projectId
                    )}
                    helperText={
                      formik?.touched?.projectId && formik?.errors?.projectId
                    }
                  />
                )}
                disabled={
                  formik?.values?.orgId?.name === undefined ||
                  projectID !== undefined
                }
              />
            </div>
            <div className="ticketDropDown">
              <TextField
                id="outlined-select-status-native"
                value={formik?.values?.titleMain}
                variant="outlined"
                name="titleMain"
                fullWidth
                onChange={(e) => {
                  formik?.handleChange(e);
                  addDataToLocalStorage('titleMain', e.target.value);
                }}
                inputProps={{ maxLength: 250 }}
                placeholder="Summary"
                error={Boolean(
                  formik?.touched?.titleMain && formik?.errors?.titleMain
                )}
                helperText={
                  formik?.touched?.titleMain && formik?.errors?.titleMain
                }
              />
            </div>
            <div className="ticketDropDown">
              <div data-color-mode="light">
                <CKEditor
                  editor={ClassicEditor}
                  data={formik?.values?.description}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    formik?.setFieldValue('description', data);
                    // setDescription(data);
                    addDataToLocalStorage('description', data);
                  }}
                  config={{
                    placeholder: 'Enter your Description here',
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      'blockQuote',
                      'uploadimage',
                    ],
                    extraPlugins: [uploadPlugin],
                  }}
                />
                {formik.touched.description && formik.errors.description && (
                  <div
                    style={{
                      color: '#C22027',
                      fontFamily: 'Quicksand,sans-serif',
                      fontWeight: '400',
                      fontSize: '0.75rem',
                      lineHeight: '1.66',
                      textAlign: 'left',
                      marginTop: '3px',
                      marginRight: '14px',
                      marginBottom: '0',
                      marginLeft: '14px',
                    }}>
                    {formik.errors.description}
                  </div>
                )}
              </div>
            </div>
            <div className="ticketDropDown">
              <Autocomplete
                id="case-type"
                options={caseType || []}
                value={formik?.values?.caseType}
                onChange={(event: any, value: any) => {
                  formik?.setFieldValue('caseType', value);
                  addDataToLocalStorage('caseType', value);
                }}
                getOptionLabel={(option) => option.name}
                inputValue={caseTypeInput}
                onInputChange={(event: any, newInputValue: any) => {
                  setCaseTypeInput(newInputValue);
                }}
                style={{ minWidth: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Case Type*"
                    error={Boolean(
                      formik?.touched?.caseType && formik?.errors?.caseType
                    )}
                    helperText={
                      formik?.touched?.caseType && formik?.errors?.caseType
                    }
                  />
                )}
              />
            </div>
            {formik?.values?.caseType?.name === 'Application Bug' && (
              <>
                <div className="ticketDropDown">
                  <Autocomplete
                    id="severities"
                    options={allSeverities || []}
                    value={formik?.values?.severity}
                    onChange={(event: any, value: any) => {
                      formik?.setFieldValue('severity', value);
                      addDataToLocalStorage('severity', value);
                    }}
                    getOptionLabel={(option) => option.name}
                    style={{ minWidth: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Severity*"
                        error={Boolean(
                          formik?.touched?.severity && formik?.errors?.severity
                        )}
                        helperText={
                          formik?.touched?.severity && formik?.errors?.severity
                        }
                      />
                    )}
                  />
                </div>
                <div className="ticketDropDown">
                  <TextField
                    id="one-textarea"
                    fullWidth
                    size="small"
                    multiline
                    rows={4}
                    name="bugOccur"
                    placeholder="How did it occur and how to reproduce the bug?"
                    value={formik?.values?.bugOccur}
                    onChange={(e) => {
                      formik?.handleChange(e);
                      addDataToLocalStorage('bugOccur', e.target.value);
                    }}
                    error={Boolean(
                      formik?.touched?.bugOccur && formik?.errors?.bugOccur
                    )}
                    helperText={
                      formik?.touched?.bugOccur && formik?.errors?.bugOccur
                    }
                  />
                </div>
                <div className="ticketDropDown">
                  <TextField
                    id="two-textarea"
                    fullWidth
                    size="small"
                    multiline
                    rows={4}
                    placeholder="What did you expect to happen?"
                    name="bugExpect"
                    value={formik?.values?.bugExpect}
                    onChange={(e) => {
                      formik?.handleChange(e);
                      addDataToLocalStorage('bugExpect', e.target.value);
                    }}
                    error={Boolean(
                      formik?.touched?.bugExpect && formik?.errors?.bugExpect
                    )}
                    helperText={
                      formik?.touched?.bugExpect && formik?.errors?.bugExpect
                    }
                  />
                </div>
              </>
            )}
            <div className="ticketDropDown">
              <Autocomplete
                id="priority-932839"
                options={priority || []}
                value={formik?.values?.priorities}
                onChange={(event: any, value: any) => {
                  formik?.setFieldValue('priorities', value);
                  addDataToLocalStorage('priorities', value);
                }}
                getOptionLabel={(option) => option.name}
                inputValue={priorityInput}
                onInputChange={(event: any, newInputValue: any) => {
                  setPriorityInput(newInputValue);
                }}
                style={{ minWidth: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Priority*"
                    error={Boolean(
                      formik?.touched?.priorities && formik?.errors?.priorities
                    )}
                    helperText={
                      formik?.touched?.priorities && formik?.errors?.priorities
                    }
                  />
                )}
              />
            </div>
            <div className="ticketDropDown">
              <Autocomplete
                options={employees}
                getOptionLabel={(option) => option.name || 'No name'}
                value={formik.values.employee}
                onChange={(event, value) =>
                  formik.setFieldValue('employee', value)
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Assignee"
                    error={
                      formik.touched.employee && Boolean(formik.errors.employee)
                    }
                    helperText={
                      formik.touched.employee && formik.errors.employee
                    }
                  />
                )}
              />
            </div>
            <div className="ticketDropDown labelsDataCss">
              <Autocomplete
                multiple
                options={labelsOptions}
                limitTags={1}
                value={formik?.values?.labelData}
                onChange={(e: any, newValue: any) => {
                  formik?.setFieldValue('labelData', newValue);
                  addDataToLocalStorage('labelData', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    name="labelsId"
                    placeholder={
                      formik?.values?.labelData?.length ? '' : 'Labels'
                    }
                    error={Boolean(
                      formik?.touched?.labelData && formik?.errors?.labelData
                    )}
                    helperText={
                      formik?.touched?.labelData && formik?.errors?.labelData
                    }
                  />
                )}
              />
            </div>
            <div className="ticketDropDown">
              <Autocomplete
                id="caseAccountBilling-type"
                options={[
                  {
                    id: 'Opex',
                    name: 'Opex',
                  },
                  {
                    id: 'Capex',
                    name: 'Capex',
                  },
                ]}
                value={formik.values.expense}
                onChange={(event: any, value: any) => {
                  formik.setFieldValue('expense', value);
                }}
                getOptionLabel={(option) => option?.name}
                // style={{ minWidth: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="expense"
                    placeholder="Expense"
                    error={
                      formik.touched.expense && Boolean(formik.errors.expense)
                    }
                    helperText={formik.touched.expense && formik.errors.expense}
                  />
                )}
              />
            </div>
            <div className="ticketDropDown">
              <TextField
                value={formik.values.timeEstimate}
                onChange={formik.handleChange}
                variant="outlined"
                name="timeEstimate"
                type="text"
                fullWidth
                placeholder="Time Estimate"
                error={Boolean(
                  formik?.touched?.timeEstimate && formik?.errors?.timeEstimate
                )}
                helperText={
                  formik?.touched?.timeEstimate && formik?.errors?.timeEstimate
                }
              />
            </div>
            <div className="ticketDropDown">
              <FileUploadDragDrop
                setFilePath={setFilePath}
                filePath={filePath}
                key={dropzoneKey}
              />
            </div>

            <div className="submitTicket">
              <Box display="flex" alignItems="center">
                {(nested
                  ? user?.permissions?.includes(
                      'organization-case-jira-sync-read'
                    )
                  : user?.permissions?.includes('case-jira-sync-read')) && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedB"
                        color="primary"
                        checked={isJiraSync}
                        onChange={() => setIsJiraSync(!isJiraSync)}
                      />
                    }
                    label="Sync with Jira"
                  />
                )}
                <Button
                  color="primary"
                  onClick={() => handleReset()}
                  sx={{ height: '38px' }}>
                  Reset
                </Button>
              </Box>
              <Box display="flex">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => closeDrawer()}
                  sx={{ marginLeft: 'auto', height: '38px' }}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  sx={{ height: '38px' }}
                  disabled={isSubmitting}>
                  Create
                </Button>
              </Box>
            </div>
          </div>
        </form>
      </Drawer>
    </div>
  );
};

export default CreateCase;
