/* eslint-disable no-useless-escape */
export const REGEX = {
  password: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&* ,.;:'"/()\[\]{}_+\-=])[\w!@#$%^&* ,.;:'"/()\[\]{}_+\-=]{8,20}$/,
};

//        'At least one uppercase, one lowercase, one number & one special character allowed'
//        .max(20, 'Maximum 20 characters allowed')

export const STATUS = {
  0: 'Waiting',
  1: 'In-Progress',
  2: 'Resolved',
  3: 'Canceled',
  4: 'Escalated',
};
export const STATUSENUMS = {
  'Waiting': 0,
  'In-Progress': 1,
  'Resolved': 2,
  'Canceled': 3,
  'Escalated': 4,
};
