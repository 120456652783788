import { isUndefined } from 'lodash';
import axios from 'src/utils/axios';
import parse from 'src/utils/parse';

class ReleaseApi {
    async getAllRelease(projectId: string, filters: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                // eslint-disable-next-line no-nested-ternary
                .get(`${process.env.REACT_APP_CASES}/releaseVersion/project/${projectId}`, {
                    params: {
                        ...filters
                    }
                })
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    } else {
                        reject(new Error('internal Server Error'));
                    }
                })
                .catch(() => {
                    reject(new Error('internal Server Error'));
                });
        });
    }

    async createReleasrByProject(payload: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .post(`${process.env.REACT_APP_CASES}/releaseVersion`, payload)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async updateReleaseStatus(Id: string, status: any): Promise<any> {
        // https://erp-it22-support-dev.apps.alpha.it22.cloud/releaseVersion/3424244232342344
        return new Promise((resolve, reject) => {
            const payload = {
                'isReleased': status
            }
            axios
                .patch(`${process.env.REACT_APP_CASES}/releaseVersion/${Id}`, payload)
                .then((response) => {
                    response = parse(response);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }

    async updateReleaseByProject(id: string, payload: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${process.env.REACT_APP_CASES}/releaseVersion/${id}`, payload)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch(() => {
                    reject(new Error('internal Server Error'));
                });
        });
    }
}

export const releasegApi = new ReleaseApi();
