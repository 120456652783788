/* eslint-disable no-underscore-dangle */
import CustomListView from 'src/components/customTable/CustomListView';
import CreateFormSideBar from 'src/components/widgets/CreateFormSideBar/CreateFormSideBar';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  Grid,
  Button,
  Container,
  // Box,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import useSettings from 'src/hooks/useSettings';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  createOrder,
  editOrder,
  fetchAllOrders,
  getAllClients,
  // getAllClients,
  // getAllProducts,
} from 'src/store/slices/omsSlice';
import { AppDispatch } from 'src/store/store';
import SearchBar from 'src/components/widgets/searchBar/SearchBar';
import setTitle from 'src/utils/setTitle';
import RestartAltIcon from '@material-ui/icons/RestartAlt';
import SimpleBreadCrumb from 'src/components/widgets/BreadCrumb/SimpleBreadCrumb';
import OrderForm from './OrderForm';
import { formatDate } from 'src/utils/formatDate';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    btn: {
      width: '47px',
      height: '30px',
      padding: 0,
      minWidth: 'initial',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '4px',
      color: '#fff',
      marginLeft: '13px',
      '& .MuiButton-iconSizeMedium': {
        margin: '0',
      },
    },
    activeButton: {
      background: 'rgba(7, 83, 0, 0.1)',
      color: 'rgba(29, 122, 21, 1)',
      width: '100px',
    },
    inActiveButton: {
      background: 'rgba(255, 161, 161, 0.3)',
      color: 'red',
      width: '100px',
    },
    tabs: {
      '& .MuiTab-root': {
        backgroundColor: 'transparent',
        minWidth: '120px',
      },
      '& .Mui-selected': {
        backgroundColor: '#231F20',
        color: '#fff',
      },
    },
  })
);

interface OrderUser {
  _id: string;
  name: string;
}

interface OrderProduct {
  _id: string;
  name: string;
}

interface OrderClient {
  _id: string;
  name: string;
}
interface Order {
  _id: string;
  startDate: string;
  endDate: string;
  isActive: boolean;
  createdBy: OrderUser;
  updatedBy: OrderUser;
  createdAt: string;
  updatedAt: string;
  product: OrderProduct;
  client: OrderClient;
}

const OrderListing = () => {
  setTitle('ERP | Order Management System');
  const classes = useStyles();
  const [createFormOpened, setCreateFormOpened] = useState(false);
  const [editFormOpened, setEditFormOpened] = useState(false);
  const [rowData, setRowData] = useState<Order>({
    _id: "",
    startDate: "",
    endDate: "",
    isActive: null,
    createdBy: null,
    updatedBy: null,
    createdAt: "",
    updatedAt: "",
    product: null,
    client: null,
  });


  const dispatch = useDispatch<AppDispatch>();
  const { settings } = useSettings();
  const location = useLocation();

  const allOrders = useSelector((state: any) => state.oms.allOrders);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [page, setPage] = useState<any>(
    JSON.parse(localStorage.getItem('omsOrderPg'))?.pageNo || 0
  );
  const [pageSize, setPageSize] = useState<any>(
    JSON.parse(localStorage.getItem('omsOrderPg'))?.pageLimit || 25
  );
  const [search, setSearch] = useState<string>(
    JSON.parse(localStorage.getItem('omsOrderPg'))?.search || ''
  );
  const [selectedSort, setSelectedSort] = useState<any>(
    JSON.parse(localStorage.getItem('omsOrderPg'))?.sortColumn || {
      id: '',
      value: 'asc',
    });

  const handleCloseCreateDrawer = () => {
    setCreateFormOpened(false);
  };

  const handleOpenCreateDrawer = () => {
    setCreateFormOpened(true);
  };

  const handleCloseEditDrawer = () => {
    setEditFormOpened(false);
  };

  const handleOpenEditDrawer = (data) => {
    setRowData(data);
    setEditFormOpened(true);
  };

  const handleReset = () => {
    localStorage.removeItem('omsOrderPg');
    setSearch('');
    setPageSize(25);
    setPage(0);
    setSelectedSort({
      id: '',
      value: 'asc',
    });
  };
  // const catagoryCellRenderer = (id, row) => {
  //   const catagory = allCategories?.find((x) => x._id === row.productCategory);
  //   return catagory ? catagory.name : '-';
  // };

  // const orderStatusList = [
  //   { name: 'Quote', id: 'Quote' , color: #6C6C6C},
  //   { name: 'Invoice', id: 'Invoice' color: #3EAB2C},
  //   { name: 'Active', id: 'Active' },
  // ];
  // const StatusCellRenderer = (label: string): void => {
  //   const obj = {

  //     active: (
  //       <Box
  //         className={classes.statueBox}
  //         style={{
  //           background: '#E9FFE5',
  //           color: '#3EAB2C',
  //           borderColor: '#3EAB2C',
  //         }}>
  //         {' '}
  //         {label}{' '}
  //       </Box>
  //     ),
  //     closed: (
  //       <Box
  //         className={classes.statueBox}
  //         style={{
  //           background: '#FFF1E4',
  //           color: '#A76A32',
  //           borderColor: '#A76A32',
  //         }}>
  //         {' '}
  //         {label}{' '}
  //       </Box>
  //     ),
  //     expired: (
  //       <Box
  //         className={classes.statueBox}
  //         style={{
  //           background: '#FFE4E4',
  //           color: '#A73232',
  //           borderColor: '#A73232',
  //         }}>
  //         {' '}
  //         {label}{' '}
  //       </Box>
  //     ),
  //   };
  //   return (<Box
  //     className={classes.statueBox}
  //     style={{
  //       background: '#F1F1F1',
  //       color: '#6C6C6C',
  //       borderColor: '#6C6C6C',
  //     }}>
  //     {' '}
  //     {label}{' '}
  //   </Box>);
  // };

  const columns = [
    // { id: 'productSKU', label: 'SKU' },
    { id: 'name', label: 'Product Name', Cell: (val, row) => row.product.name },
    { id: 'client', label: 'Client', Cell: (val, row) => row.client.name },
    { id: 'startDate', label: 'Start Date', Cell: (val, row) => formatDate(row.startDate) },
    { id: 'endDate', label: 'End Date', Cell: (val, row) => formatDate(row.endDate) },
    {
      id: 'status', label: 'Status',
      // Cell: (val, row) => StatusCellRenderer(val, row),

    },
  ];

  const orderFormSchema = Yup.object().shape({
    product: Yup.object().nullable().required('Product is required'),
    client: Yup.object().nullable().required('Client is required'),
    startDate: Yup.string().required('Start Date is required'),
    endDate: Yup.string().required('End Date is required'),
    status: Yup.string().required('Status is required')
  });

  const handleGetAllOrders = () => {
    const apiPayload = {
      page,
      pageSize,
      search,
      column: selectedSort?.id || 'defaultSort',
      sort: selectedSort?.value === 'asc' ? '1' : '-1',
    };
    const omsOrderPg = {
      pageLimit: pageSize,
      pageNo: page,
      search,
      sortColumn: selectedSort,
    };
    localStorage.setItem('omsOrderPg', JSON.stringify(omsOrderPg));
    const keys = Object.keys(apiPayload);
    const values = Object.values(apiPayload);
    // Create an array to store the non-empty key-value pairs
    const queryParams = [];
    for (let i = 0; i < values.length; i++) {
      if (values[i] !== '' && values[i] !== null && values[i] !== undefined) {
        queryParams.push(`${encodeURIComponent(keys[i])}=${values[i]}`);
      }
    }
    const queryString = queryParams.join('&');
    dispatch(fetchAllOrders(`${queryString}`)).then((res) => {
      setTotalCount(res?.payload?.totalRecords || 0);
    });
  };

  const createOrderFunc = (values: any) => {

    const payload = {
      // name: "string",
      startDate: values.startDate,
      endDate: values.endDate,
      clientId: values.client._id,
      productId: values.product._id,
      status: values.status
    }

    dispatch(createOrder(payload)).then(() => {
      handleGetAllOrders();
      setCreateFormOpened(false);
    });
  };
  const editOrderFunc = (values: any) => {

    const myData = {
      startDate: values.startDate,
      endDate: values.endDate,
      // clientId: values.client,
      // productId: values.product,
      status: values.status
    };
    const changedFields = {};
    Object.keys(myData).forEach(key => {
      if (myData[key] !== rowData[key]) {
        changedFields[key] = myData[key];
      }
    });
    const payload = {
      id: rowData._id,
      data: {
        ...changedFields,
        clientId: values.client._id,
        productId: values.product._id
      },
    };
    dispatch(editOrder(payload)).then(() => {
      handleGetAllOrders()
    });
    handleCloseEditDrawer();
  };
  const handleSearch = (val) => {
    setSearch(val);
  };
  // Open create drawer if #create is in the URL
  useEffect(() => {
    if (location.hash === '#create') {
      handleOpenCreateDrawer();
    }
  }, [location]);
  useEffect(() => {
    dispatch(getAllClients());
    // dispatch(getAllProducts());
  }, []);
  useEffect(() => {
    handleGetAllOrders();
  }, [page, pageSize, search, selectedSort]);
  const BreadCrumbsData = {
    title: 'Orders',
    // links: [
    //   {
    //     name: 'catalogues',
    //     to: '/pim/catalogues',
    //   },
    //   {
    //     name: `${catalogueName}`,
    //     to: `/pim/catalogues/${catalogueName}`,
    //   },
    //   {
    //     name: `${categoryName?.name}`,
    //     to: `/pim/catalogues/${catalogueName}`,
    //   },
    // ],
  };
  return (
    <Container
      maxWidth={settings.compact ? 'xl' : false}
      sx={{
        py: 4,
      }}>
      <Grid container justifyContent="space-between" spacing={3}>
        <Grid
          item
          sx={{
            mb: 1,
          }}>
          <Grid item md={12}>
            <SimpleBreadCrumb data={BreadCrumbsData} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            // justifyContent: 'flex-end',
            gap: '10px',
          }}>
          <SearchBar onSearch={handleSearch} value={search} placeholderData="Search" />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: '10px',
          }}>
          <Button
            variant="contained"
            color="secondary"
            title="Reset"
            startIcon={<RestartAltIcon />}
            className={classes.btn}
            onClick={() => {
              handleReset();
            }}
          />
          <Button
            variant="contained"
            color="secondary"
            endIcon={<AddCircleIcon />}
            // className={classes.btn}
            sx={{ height: 30 }}
            onClick={handleOpenCreateDrawer}>
            Create Order
          </Button>
        </Grid>
      </Grid>
      <CustomListView
        onRowClick={handleOpenEditDrawer}
        columns={columns}
        data={allOrders?.data || []}
        page={page}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setPage={setPage}
        count={totalCount}
        selectedSort={selectedSort}
        setSelectedSort={setSelectedSort}
      />
      {createFormOpened && (
        <CreateFormSideBar
          isOpen={createFormOpened}
          closeDrawer={handleCloseCreateDrawer}
          title="Create Order">
          <OrderForm
            closeDrawer={handleCloseCreateDrawer}
            onSubmit={createOrderFunc}
            schema={orderFormSchema}

          />
        </CreateFormSideBar>
      )}

      {editFormOpened && (
        <CreateFormSideBar
          isOpen={editFormOpened}
          closeDrawer={handleCloseEditDrawer}
          title="Edit Order">
          <OrderForm
            closeDrawer={handleCloseEditDrawer}
            onSubmit={editOrderFunc}
            schema={orderFormSchema}
            formData={rowData}
            editForm
          />
        </CreateFormSideBar>
      )}

    </Container>
  );
};

export default OrderListing;
